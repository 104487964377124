import { Component, OnInit, ElementRef} from '@angular/core';
import { NgModel } from '@angular/forms';
import { CommonService } from '../../services/common.service';
import { OcInfraModule, ResourceService, APICallerService, ResourceDirectoryService, IAlias } from '@diaas/ux-web';
import * as _ from 'lodash';
import { KeyValue } from '@angular/common';
import { AdvGrowlService } from 'primeng-advanced-growl';
import { Label } from 'ionic-angular';
import { PartySearchFactory } from '../../factories/partySearchFactory';

@Component({
  selector: 'app-callDetails',
  templateUrl: './customCallDetail.component.html',
  styleUrls: ['./customCallDetail.component.scss']
})

export class CallDetailComponent implements OnInit {
  selectedCallHistoryDetails : any = {};
  selectedCallHistoryDetailsFunds : any = {};
  workRequestList : string = '';
  callNotes : string = '';
  objArray = [];
  fundObj  = {};
  objLength :any = 0;
  phoneNumber : string = '';
  isGroupPolicy: boolean = false;
  isRetailPolicy: boolean = false;
  keysList = [];
  loankeyList = [];
  loanQuoteKeysList =[];
  fundKeysList = [];
  checkedFundsObj = {};
  isGroupFunds: boolean = false;
  isGroupValues: boolean = false;
  isGroupLoans: boolean = false;
  isGroupLoanQuote: boolean = false;
  mycallHistory: boolean = false;
  callHistory: boolean = false;
  currFutFunds : any = {"FUNDS":[]};

  isRetailFundCheck : boolean = false;
  public partySearchFactory = new PartySearchFactory();
  isFutureFunds: boolean = false;
  isCurrentFunds: boolean = false;
  showContentStyle: boolean = false;
  showWidthMFA: boolean = false;
  isHostContract: any;
  isMrpsContract: any;
  isCv15Contract: any;
  //selectedCallHistoryDetailsArray: any = [];
  constructor(private resourceService: ResourceService, public elementRef: ElementRef) {
      
  }

  ngOnInit() {
    if(this.elementRef.nativeElement.id == 'myCallhtrydetails'){
      this.mycallHistory = true;
    } else if(this.elementRef.nativeElement.id == 'callhtrydetails'){
      this.callHistory = true;
    }else {
      this.mycallHistory = false;
      this.callHistory = false;
    }
    OcInfraModule.AppInjector.get(AdvGrowlService).clearMessages();
    let params = OcInfraModule.AppInjector.get(CommonService).paramDetails;
    const resourceService = OcInfraModule.AppInjector.get(ResourceService);
    const resourceDirectoryService = OcInfraModule.AppInjector.get(ResourceDirectoryService);
    let details = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyDetails');
    if(details){
      this.isHostContract = details.response.isHostContract;
      this.isMrpsContract = details.response.isMrpsContract;
      this.isCv15Contract = details.response.isCv15Contract;
    }
    if(details){
      let product_type = details.data.product_type;
      this.isGroupPolicy =  (product_type == "A3" || product_type == "A4") ? true : false;
      this.isRetailPolicy = (product_type == "A1" || product_type == "A2") ? true : false;
    }
    const optionParams: Object = {
      'alias': "selectedCallHistory",
      'headers': OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("selectedCallHistory")
    }
    OcInfraModule.AppInjector.get(APICallerService).refresh(params.href, optionParams,"RESET").subscribe(response => {
      var workRequestList = [];
      let responseText = response.data.text;
      (response.data.phoneNumber)?this.phoneNumber = response.data.phoneNumber.replace(/(\d{3}(?!\d?$))\-?/g, '$1-'):'';
      const options: Object = {
        'headers': OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("selectedCallHistory")
      }
      OcInfraModule.AppInjector.get(CommonService).getCallback(response._links.transaction.href, optionParams["headers"]).subscribe(transactionResponse => {
        if (transactionResponse.length !== 0) {
          transactionResponse.forEach(key => {
            workRequestList.push(key.type);
          });
          OcInfraModule.AppInjector.get(CommonService).workRequestList = workRequestList.join(',');
        }
        else {
          OcInfraModule.AppInjector.get(CommonService).workRequestList = 'No Transactions Found';
        }
        if(!details && responseText.includes('FUNDS') && this.elementRef.nativeElement.id == 'myCallhtrydetails'){
          var formattedText = responseText.slice(responseText.indexOf('{'), responseText.lastIndexOf('}') + 1);
              formattedText = JSON.parse(formattedText);
          //formattedText = JSON.parse(formattedText);
          this.callNotes = formattedText.notes;
          this.workRequestList = OcInfraModule.AppInjector.get(CommonService).workRequestList;
        }
        if (responseText !== undefined && responseText !== '') {
          
          if(responseText.includes('FUNDS')  && (this.isOnlyGroupAnn() || this.isOnlyRetailAnn())&& (responseText.includes("Name") || responseText.includes("notes"))) 
          {
              if(this.isGroupPolicy) {
                var formattedText = responseText.slice(responseText.indexOf('{'), responseText.lastIndexOf('}') + 1);
                formattedText = JSON.parse(formattedText);
                OcInfraModule.AppInjector.get(CommonService).selectedCallHistoryDetailsFunds = formattedText.FUNDS;
                this.selectedDetailsFunds();
              }
              else {
                var formattedText = responseText.slice(responseText.indexOf('{'), responseText.lastIndexOf('}') + 1);
                formattedText = JSON.parse(formattedText);
                OcInfraModule.AppInjector.get(CommonService).selectedCallHistoryDetailsFunds = formattedText.FUNDS;
                this.selectedDetailsFunds();
              }
          }
          
          if(!(responseText.includes('FUNDS'))) 
          {
              var formattedText = responseText.slice(responseText.indexOf('{'), responseText.lastIndexOf('}') + 1);
              formattedText = JSON.parse(formattedText);
              if (formattedText.RIDERS) {
                (formattedText.RIDERS['IN'] && Object.keys(formattedText.RIDERS['IN']).length) ? formattedText.RIDERS['IN'] = { " Insured and Coverage Information": "", ...formattedText.RIDERS['IN'] } : "";
                (formattedText.RIDERS['ST'] && Object.keys(formattedText.RIDERS['ST']).length) ? formattedText.RIDERS['ST'] = { " Step Up information": "", ...formattedText.RIDERS['ST'] } : "";
                (formattedText.RIDERS['RB'] && Object.keys(formattedText.RIDERS['RB']).length) ? formattedText.RIDERS['RB'] = { " Rider/Benefit Rules": "", ...formattedText.RIDERS['RB'] } : "";
                (formattedText.RIDERS['TR'] && Object.keys(formattedText.RIDERS['TR']).length) ? formattedText.RIDERS['TR'] = { " Termination Information": "", ...formattedText.RIDERS['TR'] } : "";
                (formattedText.RIDERS['DA'] && Object.keys(formattedText.RIDERS['DA']).length) ? formattedText.RIDERS['DA'] = { " Date and Age Information": "", ...formattedText.RIDERS['DA'] } : "";
              }
              OcInfraModule.AppInjector.get(CommonService).selectedCallHistoryDetails = formattedText;
              this.selectedDetails();
          } else if(!this.isOnlyGroupAnn() && !this.isOnlyRetailAnn()){
            var formattedText = responseText.slice(responseText.indexOf('{'), responseText.lastIndexOf('}') + 1);
            formattedText = JSON.parse(formattedText);
            if (formattedText.RIDERS) {
              (formattedText.RIDERS['IN'] && Object.keys(formattedText.RIDERS['IN']).length) ? formattedText.RIDERS['IN'] = { " Insured and Coverage Information": "", ...formattedText.RIDERS['IN'] } : "";
              (formattedText.RIDERS['ST'] && Object.keys(formattedText.RIDERS['ST']).length) ? formattedText.RIDERS['ST'] = { " Step Up information": "", ...formattedText.RIDERS['ST'] } : "";
              (formattedText.RIDERS['RB'] && Object.keys(formattedText.RIDERS['RB']).length) ? formattedText.RIDERS['RB'] = { " Rider/Benefit Rules": "", ...formattedText.RIDERS['RB'] } : "";
              (formattedText.RIDERS['TR'] && Object.keys(formattedText.RIDERS['TR']).length) ? formattedText.RIDERS['TR'] = { " Termination Information": "", ...formattedText.RIDERS['TR'] } : "";
              (formattedText.RIDERS['DA'] && Object.keys(formattedText.RIDERS['DA']).length) ? formattedText.RIDERS['DA'] = { " Date and Age Information": "", ...formattedText.RIDERS['DA'] } : "";
            }
            OcInfraModule.AppInjector.get(CommonService).selectedCallHistoryDetails = formattedText;
            this.selectedDetails();
          } else {
            var formattedText = responseText.slice(responseText.indexOf('{'), responseText.lastIndexOf('}') + 1);
            formattedText = JSON.parse(formattedText);
            delete formattedText['FUNDS'];
            if (formattedText.RIDERS) {
              (formattedText.RIDERS['IN'] && Object.keys(formattedText.RIDERS['IN']).length) ? formattedText.RIDERS['IN'] = { " Insured and Coverage Information": "", ...formattedText.RIDERS['IN'] } : "";
              (formattedText.RIDERS['ST'] && Object.keys(formattedText.RIDERS['ST']).length) ? formattedText.RIDERS['ST'] = { " Step Up information": "", ...formattedText.RIDERS['ST'] } : "";
              (formattedText.RIDERS['RB'] && Object.keys(formattedText.RIDERS['RB']).length) ? formattedText.RIDERS['RB'] = { " Rider/Benefit Rules": "", ...formattedText.RIDERS['RB'] } : "";
              (formattedText.RIDERS['TR'] && Object.keys(formattedText.RIDERS['TR']).length) ? formattedText.RIDERS['TR'] = { " Termination Information": "", ...formattedText.RIDERS['TR'] } : "";
              (formattedText.RIDERS['DA'] && Object.keys(formattedText.RIDERS['DA']).length) ? formattedText.RIDERS['DA'] = { " Date and Age Information": "", ...formattedText.RIDERS['DA'] } : "";
            }
            OcInfraModule.AppInjector.get(CommonService).selectedCallHistoryDetails = formattedText;
            this.selectedDetails();
          } 
          
        }
        else {
          OcInfraModule.AppInjector.get(CommonService).selectedCallHistoryDetails = undefined;
          this.selectedDetails();
        }
      });
    });
  }

  reverseKeyOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => {
    return a.key > b.key ? -1 : (b.key > a.key ? 1 : 0);
  }

  // To keep the orignal order of call history details - DAY2
  originalOrder = (a, b) => { 
    return a.key; 
  }

  selectedDetailsFunds () {
  if(this.isGroupPolicy)  {
    this.isGroupFunds = true;
      this.selectedCallHistoryDetailsFunds = OcInfraModule.AppInjector.get(CommonService).selectedCallHistoryDetailsFunds;
      this.isFutureFunds = this.selectedCallHistoryDetailsFunds[0].futureFunds?this.selectedCallHistoryDetailsFunds[0].futureFunds:false;
      this.callNotes = this.selectedCallHistoryDetails['notes'];
      this.workRequestList = OcInfraModule.AppInjector.get(CommonService).workRequestList
      if(this.selectedCallHistoryDetailsFunds['FUNDS']) {
        this.selectedCallHistoryDetailsFunds = this.selectedCallHistoryDetailsFunds;
      } else {
        if(this.selectedCallHistoryDetailsFunds.length>1){
        this.currFutFunds.FUNDS[0] = this.selectedCallHistoryDetailsFunds[0];
        this.currFutFunds.FUNDS[1] = this.selectedCallHistoryDetailsFunds[1];
        this.selectedCallHistoryDetailsFunds = this.currFutFunds;
        } else {
          if(this.selectedCallHistoryDetailsFunds[0].futureFunds){
            this.currFutFunds.FUNDS[0] = this.selectedCallHistoryDetailsFunds[0];
            
          } else {
            this.currFutFunds.FUNDS[1] = this.selectedCallHistoryDetailsFunds[0];
          }
          this.selectedCallHistoryDetailsFunds = this.currFutFunds;
        }
        this.selectedCallHistoryDetailsFunds.FUNDS[0].futureFunds?delete this.selectedCallHistoryDetailsFunds.FUNDS[0].futureFunds:"";
      }
    } 
  else 
    {
      this.selectedCallHistoryDetailsFunds = OcInfraModule.AppInjector.get(CommonService).selectedCallHistoryDetailsFunds;
      let size = Object.keys(this.selectedCallHistoryDetailsFunds).length;
      if(size == 2 &&  this.selectedCallHistoryDetailsFunds['FUNDS']) {
        this.showContentStyle = true;
      }
      this.isFutureFunds = this.selectedCallHistoryDetailsFunds[0].futureFunds?this.selectedCallHistoryDetailsFunds[0].futureFunds:false;
      //this.isCurrentFunds = this.selectedCallHistoryDetailsFunds[0].currentFunds?this.selectedCallHistoryDetailsFunds[0].currentFunds:false;
      this.callNotes = this.selectedCallHistoryDetails['notes'];
      this.workRequestList = OcInfraModule.AppInjector.get(CommonService).workRequestList
      if(this.selectedCallHistoryDetailsFunds['FUNDS']) {
        this.selectedCallHistoryDetailsFunds = this.selectedCallHistoryDetailsFunds;
      } else {
        if(this.selectedCallHistoryDetailsFunds.length>1){
        this.currFutFunds.FUNDS[0] = this.selectedCallHistoryDetailsFunds[0];
        this.currFutFunds.FUNDS[1] = this.selectedCallHistoryDetailsFunds[1];
        this.selectedCallHistoryDetailsFunds = this.currFutFunds;
        } else {
          if(this.selectedCallHistoryDetailsFunds[0].futureFunds){
            this.currFutFunds.FUNDS[0] = this.selectedCallHistoryDetailsFunds[0];
          } else {
            this.currFutFunds.FUNDS[1] = this.selectedCallHistoryDetailsFunds[0];
          }
          this.selectedCallHistoryDetailsFunds = this.currFutFunds;
        }
      }

    }
    this.workRequestList = OcInfraModule.AppInjector.get(CommonService).workRequestList;
  }
  selectedDetails() {
    this.selectedCallHistoryDetails = OcInfraModule.AppInjector.get(CommonService).selectedCallHistoryDetails;
    let size = Object.keys(this.selectedCallHistoryDetails).length;
    if(size == 2 && (this.selectedCallHistoryDetails['LOAN'] || this.selectedCallHistoryDetails['VALUES'] || this.selectedCallHistoryDetails['FUNDS'] || this.selectedCallHistoryDetails['RIDERS'] )) {
      this.showContentStyle = true;
    }
    if(size == 2 && (this.selectedCallHistoryDetails['MFA'] || this.selectedCallHistoryDetails['NOTES ADDITION'])) {
      this.showWidthMFA = true;
    } else {
      this.showWidthMFA = false; 
    }
    if(this.selectedCallHistoryDetails['ASSET ACCOUNT INFO'] || this.selectedCallHistoryDetails['CONTRACT HOLDER INFO'] || this.selectedCallHistoryDetails['DIRECTORY ID'] || this.selectedCallHistoryDetails['GENERAL LOAN RULES'] || this.selectedCallHistoryDetails['H-CLIENT INFO'] || this.selectedCallHistoryDetails['NAME OR ADDRESS'] || this.selectedCallHistoryDetails['PLAN ADMIN']) {
      this.selectedCallHistoryDetails = this.addSponserPrefix(this.selectedCallHistoryDetails)
    }
    if (this.selectedCallHistoryDetails !== undefined) {
      this.callNotes = this.selectedCallHistoryDetails['notes'];
      delete this.selectedCallHistoryDetails['notes'];
      if (this.isGroupPolicy) {
        if (this.selectedCallHistoryDetails['VALUES']) {
          this.isGroupValues = true;
          let keys = Object.keys(this.selectedCallHistoryDetails['VALUES']);
          for (let x = 0; x < keys.length; x++) {
            this.keysList[x] =  keys[x].split('_');
          }
        }
        if (this.selectedCallHistoryDetails['LOAN QUOTE']) {
          this.isGroupLoanQuote = true;
          let keys = Object.keys(this.selectedCallHistoryDetails['LOAN QUOTE']);
          for (let x = 0; x < keys.length; x++) {
            this.loanQuoteKeysList[x] =  keys[x].split('_');
          }
        }
        if (this.selectedCallHistoryDetails['LOAN']) {
          this.isGroupLoans = true;
          let keys = Object.keys(this.selectedCallHistoryDetails['LOAN']);
          for (let x = 0; x < keys.length; x++) {
            this.loankeyList[x] =  keys[x].split('_');
          }
        }
        if (this.selectedCallHistoryDetails['FUNDS']) {
          this.isGroupFunds = true;
          this.checkedFundsObj['FUNDS'] = {};
          let keys = Object.keys(this.selectedCallHistoryDetails['FUNDS']);
          for (let x = 0; x < keys.length; x++) {
            this.fundKeysList[x] =  keys[x].split('_');
          }
          for (const [planCodeAssetKey, selectedValues] of Object.entries(this.selectedCallHistoryDetails['FUNDS'])) {
            const tempKeys=Object.keys(selectedValues);
            let fundObj = {};
            if ( tempKeys && tempKeys.length > 0) {
              tempKeys.forEach(tempKey => {
                const selectedObj= selectedValues[tempKey];
                Object.keys(selectedObj).forEach(key => {
                  let selectedfundObj = {};
                  selectedObj[key].forEach(element => {
                    selectedfundObj[element.split(":")[0]] = element.split(":")[1];
                  });
                  fundObj[key] = selectedfundObj;
                });
              });
            }
            this.checkedFundsObj['FUNDS'][planCodeAssetKey] = fundObj;
          }
        }
      }     
    }
    this.workRequestList = OcInfraModule.AppInjector.get(CommonService).workRequestList;
  }

  // Function to add "sponser-" prefix to selected keys
addSponserPrefix(list) {
  const updatedList = {};

  for (let key in list) {
    if (list.hasOwnProperty(key)) {

      if (key === 'ASSET ACCOUNT INFO' || key === 'CONTRACT HOLDER INFO' || key === 'DIRECTORY ID' || key ==='GENERAL LOAN RULES' || key === 'H-CLIENT INFO' || key === 'NAME OR ADDRESS' || key === 'PLAN ADMIN' ) {
        updatedList[`SPONSOR-${key}`] = list[key];
      } else {
        updatedList[key] = list[key];
      }
    }
  }

  return updatedList;
}

  checkType(item){
    if(typeof item.value === 'object'){
      if(this.objArray.indexOf(item.value) == -1){
        this.objArray.push(item.value);
      }
      return true;
    }
    else{
      return false;
    }

  }

  checkLabel(item) {
    let val;
    let namesObj = { "loanInfo": "LOAN", "fundInfo": "FUNDS", 'policySponsor':'SPONSOR', 'Hcontractsdetails':'SPONSOR-H-CLIENT INFO', 'directory_id':'SPONSOR-DIRECTORY ID' , 'contract_holder':'SPONSOR-CONTRACT HOLDER INFO' , 'nameor_address':'SPONSOR-NAME OR ADDRESS' ,  'asset_account_sponsor':'SPONSOR-ASSET ACCOUNT INFO' , 'loan_rules':'SPONSOR-GENERAL LOAN RULES' , 'plan_admin':'SPONSOR-PLAN ADMIN' , 'policySuspense':'SUSPENSE',"selectedCoverage": "RIDERS", "policyValues": "VALUES", "policyRevolvingError":"REVOLVING","selectedNote":"NOTES", "quote":"QUOTE" }
    for (let key in namesObj) {
      if (item == namesObj[key]) {
        val = true;
        break;
      }
      else {
        val = false;
      }
    }
    return val;
  }

  getFund(item) {
    this.fundObj = {};
    item.key == "Message" ? this.fundObj[item.key] =  item.value : this.fundObj[item.value.substring(0, item.value.indexOf(':'))] = item.value.split(':')[1];
    return true;
  }


  isObject(item) {
    return typeof item === 'object';
  }

  isUL() {
    let details = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyDetails');
              if (details.data.product_type.includes('U')) {
                  return true;
              }
              else {
                  return false;
            }
          }

  isOnlyGroupAnn() {
    
    let details = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyDetails');
    let product_type = details.data.product_type;
    if (product_type == "A3" || product_type == "A4") {
        return true;
    }
    else {
        return false;
    }
  }
  isOnlyRetailAnn() {
    
    let details = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyDetails');
    let product_type = details.data.product_type;
    if (product_type == "A1" || product_type == "A2") {
        return true;
    }
    else {
        return false;
    }
  }

  checkEffectiveDate(effectiveDate, item, key) {
    if (effectiveDate === 'Effective Date' && key != "RIDERS") {
      this.objLength = Object.keys(item).length;
      return  key == "SUSPENSE" || key == "REVOLVING" ? false : true;
    } else {
      return false;
    }
  }
  // validitating Number or not
  validateNumberOrnot(validateNumberOrnot){
    if(Number.isInteger(Number(validateNumberOrnot))){
      return true
    }else{
      return false;
    }
  }

  displayQuoteValues = (temp) =>{
    return Object.entries(temp).map(([key, value]) => `<div><label class="oc-label-1 callHisLabel callHisLabelBold"> ${key} </label> : <label class="oc-label-2 callHisLabel"> ${value} <label/></div>`).join('');
  }
  getQuoteProps = (data) => {
    const output = Object.values(data).map((dateData: any) => {
        const transformedObject = {};
        for (const key in dateData) {
          transformedObject[key] = dateData[key];
        }
        return transformedObject;
      });
    return output;
  }
}
