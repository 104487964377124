import { AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NextPrevAction, Pagination, TableColumn } from './custom-table.constant';
import * as _ from 'lodash';

@Component({
    selector: 'app-custom-table',
    templateUrl: './custom-table.component.html',
    styleUrls: ['./custom-table.component.scss']
})
export class CustomTableComponent implements AfterViewInit, OnChanges {

    @Input() columnsData: TableColumn[] = [{
        cell: '',
        columnDef: '',
        header: '',
        isSortable: false,
    }];

    @Input() pageSize: number = 5;
    @Input() pageSizeOptions: number[] = [5, 10, 20];
    @Input() showFirstLastButtons: boolean = true;
    @Input() noRecordsMsg: string = 'No records found';

    @Input()
    get data(): any[] {
        return this._data;
    }
    set data(value: any[]) {
            this.setTableDataSource(value);
    }
    private _data: any;
    @Input() class: string;
    @Input() nextprev?: NextPrevAction = {
        nextBlock: false,
        prevBlock: false
    };
    @Input() pagination?: Pagination = {
        disabled: true,
    };
    @Input() defaultSort: string = '';
    @Input() sortDirection: string = 'desc';
    @Input() sortFormat: string = 'DATE'; // DATE , STRING,

    @Output() pageChange: EventEmitter<any> = new EventEmitter<any>();
    @Output() nextprevEvent: EventEmitter<string> = new EventEmitter();

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    dataSource = new MatTableDataSource<any[]>();

    @Output() sortEvent: EventEmitter<Sort> = new EventEmitter();
    @Output() rowClickEvent: EventEmitter<any> = new EventEmitter();

    @Input() initCall: boolean = false;

    constructor() { }

    ngOnChanges(changes: SimpleChanges) {
        console.log('🚀 ~ file: custom-table.component.ts:62 ~ CustomTableComponent ~ ngOnChanges ~ changes:', changes);
        const { nextprev, pagination, initCall } = changes;
        if (nextprev) this.nextprev = changes['nextprev']['currentValue'];
        if (pagination) this.pagination = changes['pagination']['currentValue'];
        if (initCall) this.initCall = changes['initCall']['currentValue'];
    }

    get columns() {
        return this.columnsData;
    }

    get displayedColumns(): string[] {
        return this.columnsData.map((c) => c.columnDef);
    }

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;       
    }

    setTableDataSource(data: any[]) {
        if(data.length > 0) {
            this.dataSource = new MatTableDataSource<any[]>(data);
            this.dataSource.paginator = this.paginator;
            this.dataSource.paginator.firstPage(); 
            // let defaultSort = (this.defaultSort != '') ? this.defaultSort: this.columns[0].columnDef;
            // this.sortTable({ active: defaultSort, direction: 'desc' });
        } else {
            this.dataSource = new MatTableDataSource<any[]>([]);
        }     
    }

    sortTable = (sortParameters: Sort) => {
        const keyName = sortParameters.active;
        const isAsc = sortParameters.direction === 'asc';
        const data = this.dataSource.data.slice();
        let sortedData = [];
        if (sortParameters.direction === 'asc' || sortParameters.direction === 'desc'  ) {
            if(this.sortFormat != 'DATE') {
                sortedData = data.sort((a: any, b: any) => this.compare(a[keyName], b[keyName], isAsc));   
            } else {
                sortedData = data.sort((a: any, b: any) => this.compare(new Date(a[keyName]).getTime(), new Date(b[keyName]).getTime(), isAsc));   
            }
        } else {
            sortedData = data;
        }
        this.dataSource.data = sortedData;
    }

    pageChanged = (event: PageEvent) => {
        this.pageSize = event.pageSize;
        this.pageChange.emit(event);
    }

    pageAction(action: string) {
        this.nextprevEvent.emit(action);
    }

    compare = (a: number | Date | string, b: number | Date | string, isAsc: boolean) => {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }


    rowClickAction = (row: any) => {
        this.rowClickEvent.emit(row);
    }

}
