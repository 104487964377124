import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { MatPaginator, MatTableDataSource, MatTable, MatSort } from '@angular/material';
import { OcInfraModule, APICallerService, Configuration, ResourceService, IAlias, ResourceDirectoryService, OptionParams } from '@diaas/ux-web';
import { CommonFactory } from './../../factories/commonFactory';
import * as _ from 'lodash';
import { Router } from '@angular/router';

export interface cpDetails {
  contraceNumber: number;
  contractValue: number;
  productType: string;
  productName: string;
  effectiveDate: number;
  productStatus: string;
}

@Component({
  selector: 'app-custom-policy-portfolio',
  templateUrl: './custom-policy-portfolio.component.html',
  styleUrls: ['./custom-policy-portfolio.component.scss']
})

export class CustomPolicyPortfolioComponent extends CommonFactory implements OnInit {
  displayedColumns: string[] = ['contractNumber', 'contractValue', 'productType', 'productName', 'effectiveDate', 'policyStatus'];
  dataSourceList: any;
  cpAnnuityDataSource: MatTableDataSource<cpDetails>;
  cpAnnuityCount: any = 0;
  cpLifeDetailsCount: any = 0;
  lifeDataObj: any = {};
  headers = OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("search");
  value: any;
  applyNavigate: boolean = false;
  customer_details: boolean = false;
  policy_details: boolean = false;
  porfolioTotalLength: number = 0;
  paginator: MatPaginator;
  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }
  @ViewChild(MatSort) sort: MatSort;

  constructor(private commonService: CommonService, public elementRef: ElementRef, private router: Router) {
    super();
  }

  ngOnInit() {
    OcInfraModule.AppInjector.get(CommonService).agentPolicyDetailAlert = false;
    let customerDetails = OcInfraModule.AppInjector.get(CommonService).customerDetails;
    let customerPortfolioUrl = _.filter(customerDetails.summary.links, function (o) { return o.rel == "customer_portfolio" });
    OcInfraModule.AppInjector.get(CommonService).getCallback(customerPortfolioUrl[0].href, this.headers).subscribe(responce => {
      this.lifeDataObj = responce;
      let portfolioList = [];
      this.cpLifeDetailsCount = responce._links.item.length;
      for (let i = 0; i < responce._links.item.length; i++) {
        portfolioList.push(responce._links.item[i].summary)
      }
      this.porfolioTotalLength = this.cpLifeDetailsCount;
      this.cpAnnuityDataSource = new MatTableDataSource(responce._links.item);
      this.setDataSourceAttributes();
      let workHref = responce._links.instances.href;
      this.getWorkRequestHistory(workHref);
    })
    this.customer_details = this.elementRef.nativeElement.id == 'customerPolicyPortfolio'?true:false;
    this.policy_details = this.elementRef.nativeElement.id == 'olsCustomerPolicyPortfolio'?true:false;
  }

  getWorkRequestHistory(workHref) { 

     // let workHref = 'https://sit.csr-portal.hub-1.dev.us.insurance.dxc.com/casemanagement/instances?type=customer&id=AKZ4XgeFpO4JFkn58hoAOw'
     // let optionParams = new OptionParams();
     // optionParams.alias =  'workRequestHistory';
    //  optionParams.headers= OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("reqDetails");
    //  OcInfraModule.AppInjector.get(APICallerService).refresh(workHref, optionParams, 'Reset').subscribe(response => {
      // OcInfraModule.AppInjector.get(CommonService).agentRoleDetails = response;
    //  }, error =>{
    //      console.log("error",error)
    //  });

    let optionParams = new OptionParams();
    optionParams.alias = 'workRequestHistory';
    let url = workHref.split('?')[0];
    let paramsObj = [];
    let params = workHref.split('?')[1].split('&');
    for (let i = 0; params.length > i; i++) {
      paramsObj.push({ 'key': params[i].split('=')[0], 'value': params[i].split('=')[1] })
    }
    OcInfraModule.AppInjector.get(CommonService).instanceParams = paramsObj;
    optionParams.params = paramsObj;
    optionParams.headers = OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("reqDetails");
    OcInfraModule.AppInjector.get(APICallerService).refresh(url, optionParams, 'Reset').subscribe(response => {
    }, error => {
      console.log("error", error)
    });
  }


  setDataSourceAttributes() {
    this.cpAnnuityDataSource.paginator = this.paginator;
  }

  onRowClick(data, params) {
    //OcInfraModule.AppInjector.get(CommonService).newBannerAlertAarray = [];
    //OcInfraModule.AppInjector.get(CommonService).alertMessaggeHideShow.next();

    params.summary && params.summary.notSupportedProductMSg && params.summary.notSupportedProductMSg != '' ? this.customerAlertbanner(params.summary.notSupportedProductMSg) : '';
    document.getElementById("modal__ok") && document.getElementById("modal__ok").click();
    let currentPage = OcInfraModule.AppInjector.get(CommonService).currentRoute;        
    OcInfraModule.AppInjector.get(CommonService).selectedBOBPolicy = params;
    const otherRoleSucess= OcInfraModule.AppInjector.get(CommonService).otherRoleAuthsucess;
    const authSucess = OcInfraModule.AppInjector.get(CommonService).authsucess;
    const optionParams = new OptionParams();
    optionParams.alias = "policyDetails";
    const resourceService = OcInfraModule.AppInjector.get(ResourceService);
    OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false });
    OcInfraModule.AppInjector.get(APICallerService).refresh(params.href, optionParams, 'Reset').subscribe(response => {
      OcInfraModule.AppInjector.get(CommonService).customerBanner = false;
      ( OcInfraModule.AppInjector.get(CommonService).authsucess || OcInfraModule.AppInjector.get(CommonService).otherRoleAuthsucess) ? document.getElementById("bob-open-modal-icon-open-bob-agent-banner-anchor").click() : document.getElementById("bob-open-modal-icon-open-bob-agent-anchor").click();
      this.expandOnauth();
  }, error => (console.log("error",error)));
    const selectedBOBSchema = resourceService.getAliasByAliasName("policyDetails");
  }
  expandOnauth(){
    setTimeout(()=>{
      if((OcInfraModule.AppInjector.get(CommonService).authsucess || OcInfraModule.AppInjector.get(CommonService).otherRoleAuthsucess)){
          var element =  document.getElementById('bob-open-modal-icon-open-bob-agent-anchor');
            if (typeof(element) != 'undefined' && element != null)
            {
              Array.from(document.querySelectorAll(".expandOnAuthorize mat-expansion-panel-header")).forEach(accordian => {
                if(!accordian["classList"].contains("mat-expanded")){
                    document.getElementById(accordian.id).click();
                }
            });
          }
        }
    },6000);
  }

  customerAlertbanner(msg) {
    let status_report = {
      "showAll":true,
      "consistent": true,
      "messages": [
        {
          "message": msg,
          "severity": "ALERT"
        }
      ],
      url: this.lifeDataObj._links.instances.href,
      alias: "CustomerDetais",
    }
    let customerAlert = setInterval(function () {
      if (OcInfraModule.AppInjector.get(CommonService).agentPolicyDetailAlert) {
        OcInfraModule.AppInjector.get(CommonService).pushBannerAlertMessage(status_report);
          clearInterval(customerAlert);
      }
      }, 500);
    }
}
