import { Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import { Configuration, OcInfraModule, ResourceService } from '@diaas/ux-web';
import * as _ from 'lodash';
import { CommonService } from '../../services/common.service';
import { MenuComponent } from "../customMenu.component/customMenu.component";
import { AdvGrowlService } from 'primeng-advanced-growl';
import { FormControl, Validators } from '@angular/forms';
@Component({
    selector: 'app-mfa',
    templateUrl: './MFA.component.html',
    styleUrls: ['./MFA.component.scss']
})
export class MFAComponent implements OnInit {
    isPinSent: boolean = false;

    inputControl = new FormControl({ value: '', disabled: !this.isPinSent }, [
        Validators.required,
        Validators.pattern('^[0-9]*$'),
        Validators.minLength(6),
        Validators.maxLength(6)
    ]);
    /*reasons = [
        { value: 'Contract Information no longer valid', viewValue: 'Contract Information no longer valid' },
        { value: 'No contract information available for PIN send', viewValue: 'No contract information available for PIN send' },
        { value: 'System issue and/or error message', viewValue: 'System issue and/or error message' },
        { value: 'Manager request to bypass', viewValue: 'Manager request to bypass' },
        { value: 'Multiple policy call, validated on previous contract.', viewValue: 'Multiple policy call, validated on previous contract.' }
    ];*/
    reasons: any = [];
    showOverride: boolean = false;
    phoneNumberRes: any;
    emailResponse: any;
    personResponse: any;
    phoneNumbersList: any = [];
    emailIdsList: any = [];
    pinType: any = "";
    emailValue: any = "";
    phoneValue: any = "";
    pinValue: any = "";
    isTextPinSelected: boolean = false;
    isEmailPinSelected: boolean = false;
    isCallBackPinSelected: boolean = false;
    disableResend: any = true;
    timeRemaining: number = 0;
    clickCount: number = 0;
    sendClicked: boolean = false;
    maxClicks: number = 2;
    private timeoutId: any;
    selectedReason: string;
    reasonSelected: boolean = false;
    isChecked: boolean = false;
    disableSendPin: boolean = true;
    id: any;
    selectedScreen: any = OcInfraModule.AppInjector.get(CommonService).selectedScreen;
    items: any = ["phonenumberupdate", "emailupdate", "addressupdate","billingupdate"];
    isScriptChecked: boolean = false;
    scriptMessage: string = "For security purposes, we will send you a one-time PIN code, via a method in our options. This helps us to confirm your identity before proceeding with this transaction. Once you receive the PIN, please read it back to me."
    isPinLengthValidated: boolean = false;
    isAnnuity: any;
    constructor(private commonService: CommonService, public elementRef: ElementRef) {
        this.inputControl.valueChanges.subscribe(value => {
            this.isPinLengthValidated = this.isValid(value);
        });
    }
    isValid(value: string): boolean {
        return /^[0-9]{6}$/.test(value);
    }
    ngOnInit() {
        /*if (this.items.includes(this.selectedScreen)) {
            this.phoneNumberRes = OcInfraModule.AppInjector.get(CommonService).partyPhoneNumberInformation;
            this.emailResponse = OcInfraModule.AppInjector.get(CommonService).partyEmailInformation;
            this.dropDownValues();
        }
        else {*/
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        const headers = OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("partyRoles");
        let selectedIdentity = resourceService.getResourceFromAliasName('selectedRoles');
        let authorizedRole = selectedIdentity.data.role;
        this.isAnnuity = OcInfraModule.AppInjector.get(CommonService).isAnnuity;
        //role is different from backend and csr so made the below condition
        if(this.isAnnuity && authorizedRole == 'Annuitant') {
            authorizedRole = "Participant";
        }
        let partyRoles = resourceService.getResourceFromAliasName('partyRoles');
        let selectRoleSummary = _.filter(partyRoles.response._links.item, function (o) { return o.summary.role_name_desc == authorizedRole; });
        if (selectRoleSummary.length > 0) {
            OcInfraModule.AppInjector.get(CommonService).getCallback(selectRoleSummary[0].href, headers).subscribe(partyResponse => {
                let infoUrl = partyResponse._links.hasOwnProperty('person') ? partyResponse._links.person.href : partyResponse._links.organization.href;
                OcInfraModule.AppInjector.get(CommonService).partyResponse = partyResponse;
                OcInfraModule.AppInjector.get(CommonService).getCallback(infoUrl, headers).subscribe(personResponse => {
                    this.personResponse = personResponse;
                    OcInfraModule.AppInjector.get(CommonService).emailHref = personResponse._links["email"].href;
                    OcInfraModule.AppInjector.get(CommonService).phoneNumberHref = personResponse._links["phonenumber"].href;
                    OcInfraModule.AppInjector.get(CommonService).getCallback(personResponse._links["phonenumber"].href, headers).subscribe(phoneNumberResponse => {
                        this.phoneNumberRes = phoneNumberResponse;
                        this.dropDownValues("P");
                    });
                    OcInfraModule.AppInjector.get(CommonService).getCallback(personResponse._links["email"].href, headers).subscribe(emailResponse => {
                        this.emailResponse = emailResponse;
                        this.dropDownValues("E");
                    });
                });
            });
        }
        let mfaOverrideData = _.filter(Configuration.config.hostURL.multiHostUrl, function (o) { return o.alias == 'mfaOverrideDropDownValues' });
        var mfaOverrideUrl = mfaOverrideData[0].url;
        var headers1 = OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("mfaOverride");
        let policyDetails = resourceService.getResourceFromAliasName('policyDetails');
        headers.role = headers1.caller_role;
        headers.product_type = policyDetails.data.product_type;
        
        var body = {
            "tenantName":"MFA"
        }
        OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false });
        OcInfraModule.AppInjector.get(CommonService).postCallback(mfaOverrideUrl, body, headers).subscribe(response => {
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: true });
            if(response && response.hasOwnProperty('mfaOverrides')){
                var mfaOverrideData = response.mfaOverrides;
                mfaOverrideData.forEach(item => {
                    if (item) {
                       this.reasons.push ({ value: item, viewValue : item});
                    }
                });
            }
        });
        //}
    }
    dropDownValues(val : String) {
        if(val == "P"){
            var summaryObj = (this.phoneNumberRes && this.phoneNumberRes._embedded && this.phoneNumberRes._embedded.communicationNumberList) ? this.phoneNumberRes._embedded.communicationNumberList : [];
            summaryObj.forEach(item => {
                if (item.number) {
                    this.phoneNumbersList.push({ "vewValue": item.number.replaceAll("-", "") });
                }
            });
        }
        else if (val == "E"){
            var summaryObj = (this.emailResponse && this.emailResponse._embedded && this.emailResponse._embedded.communicationNumberList) ? this.emailResponse._embedded.communicationNumberList : [];
            summaryObj.forEach(item => {
                if (item.number) {
                    this.emailIdsList.push({ "vewValue": item.number });
                }
            });
        } 
    }
    openOverrideMFA = () => {
        this.showOverride = true;
    }

    submitPin = () => {
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let policyDetails = resourceService.getResourceFromAliasName('policyDetails');
        let mfaSendToken = _.filter(Configuration.config.hostURL.multiHostUrl, function (o) { return o.alias == 'mfaValidateToken' });
        var mfaValidateUrl = mfaSendToken[0].url;
        var headers = OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("mfaVerificationvalidate");
        headers.role = headers.caller_role;
        console.log("Input Value", this.inputControl.value);
        var body = {
            "Id": this.id,
            "Code": this.inputControl.value
        }
        this.disableSendPin = true;
        this.disableResend = false;
        this.isPinSent = true;
        this.inputControl.reset({ value: '', disabled: false })
        OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false });
        OcInfraModule.AppInjector.get(CommonService).postCallback(mfaValidateUrl, body, headers).subscribe(response => {
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: true });
            if (response && response.hasOwnProperty("Authenticated")) {
                if (response.Authenticated == true) {
                    OcInfraModule.AppInjector.get(CommonService).mfaAuthorized = true;
                    OcInfraModule.AppInjector.get(AdvGrowlService).createSuccessMessage("Valid PIN entered", 'Success');
                    //---- call notes ----
                    //document.getElementById('close-call-notes-textarea_')["value"] = document.getElementById('close-call-notes-textarea_')["value"] + '\n <b>MFA Validation Success.</b>';
                    let mfaMessageHtml = '<dl class="pad-lAr-10px">' + '<dt class="pull-left">MFA Pin Provided</dt><dd><br></dd>' + '</dl>';
                    OcInfraModule.AppInjector.get(CommonService).mfaCallNotesMessage = "MFA Pin Provided";
                    OcInfraModule.AppInjector.get(CommonService).mfaMessageHtml = mfaMessageHtml;
                    OcInfraModule.AppInjector.get(CommonService).showRequestedDetails();
                    this.clearBannerMessages();
                    this.navigatingBackToSelectedScreen();
                }
                else {
                    OcInfraModule.AppInjector.get(AdvGrowlService).createErrorMessage("Invalid PIN", 'Failed');
                    this.clearBannerMessages();
                }
            }
            else {
                OcInfraModule.AppInjector.get(AdvGrowlService).createErrorMessage("PIN validation failed", 'Failed');
                this.clearBannerMessages();
            }
        });
        error => {
            error && error.message ? OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error.message, 'Error', 0) :
                OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage('Something went wrong please try again', 'Error', 0);
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: true });
            this.clearBannerMessages();
        }
    }
    navigatingBackToSelectedScreen = () => {
        if (this.items.includes(this.selectedScreen)) {
            var modalCross = document.getElementsByClassName("mfa-modal");
            if (modalCross.length > 0) {
                if (modalCross[0].children && modalCross[0].children[0].id == 'modal__cross') {
                    document.getElementsByClassName("mfa-modal")[0].children[0].id = "modal_mfa_cross";
                    document.getElementById("modal_mfa_cross").click();
                    if (this.selectedScreen == "addressupdate")
                        document.getElementById("addressButton").click();
                    else if (this.selectedScreen == "phonenumberupdate")
                        document.getElementById("phoneButton").click();
                    else if (this.selectedScreen == "emailupdate")
                        document.getElementById("emailButton").click();
                    else if (this.selectedScreen == "billingupdate")
                        document.getElementById("address-edit-billing-anchor").click();
                }
            }
        }
        else {
            document.getElementById("modal__cross").click();
             let data = OcInfraModule.AppInjector.get(CommonService).selectedScreenAction; 
            OcInfraModule.AppInjector.get(CommonService).onActionItemClick(data);
        }
    }
    sendPin = () => {
        this.disableResend = true;
        this.sendClicked = true;
        this.timeRemaining = 30;
        this.countdown();
        this.generateMfaToken();
    }

    countdown() {
        const interval = 1000; // 1 second
        this.timeoutId = setInterval(() => {
            this.timeRemaining--;

            if (this.timeRemaining <= 0) {
                this.resetButton();
                clearInterval(this.timeoutId);
            }
        }, interval);
    }

    radioButtonEvent = (event) => {
        if (this.pinType == "textpin") {
            this.isTextPinSelected = true;
            this.isCallBackPinSelected = false;
            this.isEmailPinSelected = false;
            this.emailValue = "";
            if (this.phoneNumbersList.length > 0) {
                this.phoneValue = this.phoneNumbersList[this.phoneNumbersList.length-1].vewValue;
                this.disableSendPin = false;
            }
            else {
                this.disableSendPin = true;
                OcInfraModule.AppInjector.get(AdvGrowlService).createErrorMessage("Phone Number not available", 'Success');
                setTimeout(() => {
                    OcInfraModule.AppInjector.get(AdvGrowlService).clearMessages();
                }, 5000);
            }
        }
        /*else if(this.pinType == "callbackpin"){
            this.isTextPinSelected = false;
            this.isCallBackPinSelected = true;
            this.isEmailPinSelected = false;
            if(this.emailValue){
                this.emailValue = "";
                this.disableSendPin = false;
            }
        }*/
        else if (this.pinType == "emailpin") {
            this.isTextPinSelected = false;
            this.isCallBackPinSelected = false;
            this.isEmailPinSelected = true;
            this.phoneValue = "";
            if (this.emailIdsList.length > 0) {
                this.emailValue = this.emailIdsList[this.emailIdsList.length-1].vewValue;
                this.disableSendPin = false;
            }
            else {
                this.disableSendPin = true;
                OcInfraModule.AppInjector.get(AdvGrowlService).createErrorMessage("Email Id not available", 'Success');
                setTimeout(() => {
                    OcInfraModule.AppInjector.get(AdvGrowlService).clearMessages();
                }, 5000);
            }
        }
    }
    phoneOrEmailSelection = (event) => {
        if (this.phoneValue || this.emailValue) {
            this.disableSendPin = false;
            this.disableResend = true;
        }
        else {
            this.disableSendPin = true;
            this.disableResend = true;
        }
    }

    resetButton = () => {
        this.disableResend = false;
        this.sendClicked = false;
    }

    reSendPin = (event: any) => {
        if (this.clickCount >= this.maxClicks) {
            return;
        }
        this.clickCount++;
        this.disableResend = true;
        this.timeRemaining = 30;
        this.countdown();
        this.generateMfaToken();
    }

    onValueChange = (value: string) => {
        this.selectedReason = value;
        if (this.selectedReason) {
            this.reasonSelected = true;
        }
    }

    onCheckboxChange = (event: any) => {
        this.isChecked = event.checked;
    }

    scriptChecked = (e) => {
        this.isScriptChecked = e.checked;
    }

    handleInput = (event: Event) => {
        const input = event.target as HTMLInputElement;
        const filteredValue = input.value.replace(/[^0-9]/g, '');
        this.inputControl.setValue(filteredValue, { emitEvent: false });
        this.isPinLengthValidated = this.isValid(filteredValue);
    }
    pinNumberValidation = (event) => {
        this.pinValue = this.pinValue.replace(/[^0-9]/g, '');
    }
    ngOnDestroy = () => {
        if (this.timeoutId) {
            clearInterval(this.timeoutId);
        }
        this.disableResend = false;
        this.timeRemaining = 0;
        this.clickCount = 0;
        this.maxClicks = 2;
        this.selectedReason = '';
        this.reasonSelected = false;
        this.isChecked = false;
        this.showOverride = false;
    }
    generateMfaToken = () => {
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let policyDetails = resourceService.getResourceFromAliasName('policyDetails');
        let mfaSendToken = _.filter(Configuration.config.hostURL.multiHostUrl, function (o) { return o.alias == 'mfaSendToken' });
        var mfaUrl = mfaSendToken[0].url;
        var headers = OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("mfaVerification");
        headers.role = headers.caller_role;
        var body = {
            "Option": this.pinType && this.pinType == "emailpin" ? "Email" : "SMS",
            "Address": this.pinType && this.pinType == "emailpin" ? this.emailValue : this.phoneValue
        }
        this.disableSendPin = true;
        this.isPinSent = true;
        this.inputControl.reset({ value: '', disabled: false })
        OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false });
        OcInfraModule.AppInjector.get(CommonService).postCallback(mfaUrl, body, headers).subscribe(response => {
            console.log("response", response);
            console.log("response", response.Id);
            this.id = response.Id;
            let deliveryMethod = this.emailValue ? "Mail Id (" + this.emailValue + ")." : "Phone Number(" + this.phoneValue + ").";
            let message = "One Time PIN Code has been sent to your " + deliveryMethod;
            OcInfraModule.AppInjector.get(AdvGrowlService).createSuccessMessage(message, 'Success');
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: true });
            this.clearBannerMessages();
        }, error => {
            error && error.message ? OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error.message, 'Error', 0) :
                OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage('Something went wrong please try again', 'Error', 0);
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: true });
            this.clearBannerMessages();
        });
    }
    clearBannerMessages = () => {
        setTimeout(() => {
            OcInfraModule.AppInjector.get(AdvGrowlService).clearMessages();
        }, 5000);
    }
    overrideMFA = () => {
        OcInfraModule.AppInjector.get(CommonService).mfaAuthorized = true;
        //---- call notes ----
        //document.getElementById('close-call-notes-textarea_')["value"] = document.getElementById('close-call-notes-textarea_')["value"] + '\n <b>MFA Validation Success.</b>';
        let mfaMessageHtml = '<dl class="pad-lAr-10px">' + '<dt class="pull-left">Passed Enhanced Security</dt><dd><br></dd>' + '</dl>';
        OcInfraModule.AppInjector.get(CommonService).mfaCallNotesMessage = "Passed Enhanced Security";
        OcInfraModule.AppInjector.get(CommonService).mfaMessageHtml = mfaMessageHtml;
        OcInfraModule.AppInjector.get(CommonService).showRequestedDetails();
        this.navigatingBackToSelectedScreen();
    }
}