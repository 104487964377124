import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { OcInfraModule, ResourceService, APICallerService } from '@diaas/ux-web';
import * as _ from 'lodash';
import { AdvGrowlService } from 'primeng-advanced-growl';

@Component({
  selector: 'correspondence-mail',
  templateUrl: './correspondence-mail.component.html',
  styleUrls: ['./correspondence-mail.component.scss']
})
export class CorrespondenceMailComponent implements OnInit {
  mailToAOR: boolean = false;
  emailAddress:any;
  authsucess: any = OcInfraModule.AppInjector.get(CommonService).authsucess;
  otherRoleSucess: any = OcInfraModule.AppInjector.get(CommonService).otherRoleAuthsucess;
  hoverMessage: string = '';
  emailClass: boolean = true;
  city: any = '';
  zip: any = '';
  addressRes: any;
  addUpdateFormObj: any;
  addressProperties: any;
  state: any;
  disableSend: boolean = true;

  constructor(private commonService: CommonService) { }

  ngOnInit() {
    this.hoverMessage = 'Enable button by completing required items';
    const resourceService = OcInfraModule.AppInjector.get(ResourceService);
            const headers = OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("partyRoles");
            let selectedIdentity = resourceService.getResourceFromAliasName('selectedRoles');
            let authorizedRole = selectedIdentity.data.role;
            let partyRoles = resourceService.getResourceFromAliasName('partyRoles');
            let selectRoleSummary = _.filter(partyRoles.response._links.item, function (o) { return o.summary.role_name_desc == authorizedRole; });
            if (selectRoleSummary.length > 0) {
                OcInfraModule.AppInjector.get(CommonService).getCallback(selectRoleSummary[0].href, headers).subscribe(partyResponse => {
                    let infoUrl = partyResponse._links.hasOwnProperty('person') ? partyResponse._links.person.href : partyResponse._links.organization.href;
                    OcInfraModule.AppInjector.get(CommonService).partyResponse = partyResponse;
                    OcInfraModule.AppInjector.get(CommonService).getCallback(infoUrl, headers).subscribe(response => {
                        let personResponse = response;
                        OcInfraModule.AppInjector.get(CommonService).emailHref = personResponse._links["email"].href;
                        OcInfraModule.AppInjector.get(CommonService).addressHref = personResponse._links["address"].href;
                      OcInfraModule.AppInjector.get(CommonService).getCallback(personResponse._links["address"].href, headers).subscribe(addressResponse => {
                        this.addressRes = addressResponse;
                        this.addUpdateFormObj = this.addressRes._embedded.addressList[0];
                        this.addressProperties = this.addressRes._options.properties;
                    });
                    });
                  });
  }
}

checkboxChecked(e){
    if(e.checked == true) {
      this.mailToAOR = true;
      this.emailAddress=this.addressRes._embedded.addressList[0].address_line1;
      this.city = this.addressRes._embedded.addressList[0].city;
      this.zip = this.addressRes._embedded.addressList[0].zip5+(this.addressRes._embedded.addressList[0].zip4 && this.addressRes._embedded.addressList[0].zip4 !== "" ? '-' + this.addressRes._embedded.addressList[0].zip4 : '');
      this.state = this.addressRes._embedded.addressList[0].state;
      this.disableSendEmail();
    } else {
      this.mailToAOR = false;
      this.emailAddress='';
      this.city = '';
      this.zip = '';
      this.state = '';
      this.disableSendEmail();
    }
  }

  emailAddressChange(e) {
    let isEmailAddressValid = false;
    if( e.target.value.trim() != '' && 
      /^(([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25}(($)|( *;+ *$)|( *;+ *(?=[a-zA-Z0-9_\-\.]))))*$/.test(e.target.value.trim())){
        isEmailAddressValid = true;
    }
    //isEmailAddressValid && (this.authsucess || this.otherRoleSucess) ? this.disableSendEmail = false : this.disableSendEmail = true;
    isEmailAddressValid ? this.emailClass = false : this.emailClass = true;
    this.disableSendEmail();
    //(this.authsucess || this.otherRoleSucess ) && this.disableSendEmail ? this.hoverMessage = 'Enable button by entering semi-colon separated valid email address(es)':  this.hoverMessage = '';
  }

  formatValidPostalCode(e){
    e.target["value"] = e.target["value"].replace(/[^0-9]/gi, '');
    e.target["value"] = e.target["value"].replace(/(\d{5}(?!\d?$))\-?/g, '$1-');
    if ((e.target["value"].length > 5 && e.target["value"].length < 10) || (e.target["value"].length < 5)) {
      this.disableSend = true;
    } else {
      this.disableSendEmail();
    }
  }

  formatPostalCode(e){
  if (e.keyCode > 31 && ((e.keyCode < 48 || e.keyCode > 57) && (e.keyCode < 96 || e.keyCode > 105)) && e.keyCode != 46) {
    e.preventDefault();
  } else {
      this.zip =  e.target["value"].replace(/(\d{5}(?!\d?$))\-?/g, '$1-');
  }
}

formatPostalOnPaste(e){
  this.formatValidPostalCode(e);
  this.formatPostalCode(e)
}

disableSendEmail() {
  if(this.emailAddress && this.city && this.zip && this.state && this.state != ' ') {
    this.disableSend =  false;
    this.hoverMessage = '';
  } else {
    this.hoverMessage = 'Enable button by completing required items';
    this.disableSend = true;
  }
}

onValueChange(e,state){
  this.state = e;
  this.disableSendEmail();
}

sendEmail(e) {
  OcInfraModule.AppInjector.get(AdvGrowlService).clearMessages();
    const resourceService = OcInfraModule.AppInjector.get(ResourceService);
    let policyDetails = resourceService.getResourceFromAliasName('policyDetails');
    const selectedRoleSummary = resourceService.getResourceFromAliasName('selectedRoles'); 
    let workRequestDetails = resourceService.getResourceFromAliasName('workRequestHistory');
    let headers = this.commonService.getHeaderByAliasName("selectedWorkRequest");
    const workRequestHref = resourceService.getHrefByAliasName("workRequestHistory").split('?')[0];
    headers.statuaory_company_code = policyDetails.data.statutory_company_code;
    headers.company_code = policyDetails.data.company_code;
    delete headers.faxType;
    headers.mailType = "correspondence"; 
    //headers.emailType = "correspondence";
    headers.role = OcInfraModule.AppInjector.get(CommonService).selectedRole;
    headers.product_type = policyDetails.data.product_type;
    let payload =
    {
      "CLTY": "REPRINT",
      "POLN":(this.authsucess || this.otherRoleSucess ) ? policyDetails.data.policy_number : selectedRoleSummary.data.policyNumber,
      "attachedForms": [
        "CSRP-REPRINT"
      ],
      "letterType": "EFORMS",
      "batchValue": this.emailAddress+','+this.city+','+this.state+','+this.zip,
      "departmentName": "EFORMS",
      "lob": policyDetails.data.line_of_business_code,
      "additionalInfo": "AWDAutoClaim",
      "documentKeys": OcInfraModule.AppInjector.get(CommonService).correspondenceDoc,
    }
    OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false });
    OcInfraModule.AppInjector.get(CommonService).postCallback(workRequestDetails._links.email.href, payload, headers).subscribe(response => {
      if (response && response.message) {
        OcInfraModule.AppInjector.get(AdvGrowlService).createSuccessMessage(response.message, 'Success');
        let correspondenceCheckWrapper = document.querySelectorAll('#correspondenceTable .mat-checkbox input[type=checkbox]:checked');
        let correspondenceBtn = document.querySelectorAll('.correspondence-btn');
       
        Array.from(correspondenceBtn).forEach(btn => {
          btn['classList'].add('disabled')
          btn['disabled'] = true;
          btn[' ng-reflect-disabled'] = true;
        });
        document.getElementById("modal__cross").click();
        OcInfraModule.AppInjector.get(CommonService).correspondenceDoc = [];
        OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false });
        setTimeout(() => {
          OcInfraModule.AppInjector.get(APICallerService).refresh(workRequestHref, { 'alias': "workRequestHistory", 'headers': headers }).subscribe(refreshResponse => {
            OcInfraModule.AppInjector.get(CommonService).wrkReqHistoryRes = refreshResponse;
            OcInfraModule.AppInjector.get(CommonService).sendFilterEvent();
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
          });
        }, 5000);
        Array.from(correspondenceCheckWrapper).forEach(corres => {
          corres.dispatchEvent( new Event('click'));
        });
      } else {
        console.log('Error occurred while sending to Mailing Address',response);
        OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage("Something went wrong", 'Error', 0);
        OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
        document.getElementById("modal__cross").click();
      }
    }, error => {
      OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
      error && error.message ? OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error.message, 'Error', 0) : OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage("Email was not sent successfully to Email Address(es)", 'Error', 0);
    })
  }

  onInput(event: Event) {
    const input = event.target as HTMLInputElement;
    input.value = input.value.replace(/[^0-9]/g, '');
  }

  cityChange(event: Event) {
    this.disableSendEmail();
    this.city = event.target['value'];
  }
}


