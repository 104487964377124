import { Component, ComponentFactoryResolver, ComponentRef, Inject, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

export type CustomDialog = {
 component: any
 title: string   
}

@Component({
  selector: 'app-custom-dialog',
  templateUrl: './custom-dialog.component.html',
  styleUrls: ['./custom-dialog.component.scss']
})
export class CustomDialogComponent implements OnInit {

    @ViewChild('target', { read: ViewContainerRef }) vcRef: ViewContainerRef;

    componentRef: ComponentRef<any>;

    constructor(
        public dialogRef: MatDialogRef<CustomDialogComponent>,
        private resolver: ComponentFactoryResolver,
        @Inject(MAT_DIALOG_DATA) public data: CustomDialog) { }

    ngOnInit() {
        const factory = this.resolver.resolveComponentFactory(this.data.component);
        this.componentRef = this.vcRef.createComponent(factory);
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    ngOnDestroy() {
        if (this.componentRef) {
            this.componentRef.destroy();
        }
    }

}
