import { Component, OnInit , Input, Output, EventEmitter} from '@angular/core';
import * as _ from 'lodash';
import { initialPartialSurrender,initialFullSurrender, partialMockResponse, fullMockResponse} from './constants';
import { DatePipe, CurrencyPipe } from '@angular/common';
import { CommonService } from '../../services/common.service';
import { OcInfraModule,ResourceService  } from '@diaas/ux-web';
import { HttpParams } from '@angular/common/http';
import { Item } from 'ionic-angular';

@Component({
  selector: 'withdrawl-surrender-quote',
  templateUrl: './quoteComponent.html',
  styleUrls: ['./quoteComponent.scss']
})
export class WithdrawlSurrenderQuote implements OnInit {
  
  @Input() withdrawlSurrenderType: any;
  @Input() currentPolicyResourceURL: String;
  @Input() headers: Object;
  @Output() quoteValuesToEmit = new EventEmitter<Object>();

  @Input() surrenderDetails: any;

  quotesAPISource: Object = { "PARTIAL_SURRENDER": "/values/partialSurrender", "FULL_SURRENDER": "/values/fullSurrender","DIVIDEND_WITHDRAWAL":"/values/diviendWithdrawal"}

  effectiveDateCheck: boolean = true;
  amountRequestedCheck: boolean = true;
  amountRequested: any;

  maxwithholdNoGain: any;
  maxwithholdNoGainCheck: boolean;

  baseFaceAmount: any;
  baseFaceAmountCheck: boolean=true;

  maxwithdrawalamt:any;
  maxwithdrawalamtCheck:boolean;

  aIBalance:any;
  aIBalanceCheck:boolean;

  riderCashValue:any;
  riderCashValueCheck:boolean;

  dwiBalance:any;
  dwiBalanceCheck:boolean;

  grossWithholdAmount:any;
  grossWithholdamtCheck:boolean=true;

  cvOfAI:any;
  cvOfAICheck:boolean;  

  effectivedate: any;
  surrenderQuoteValues: any;
  dividendWithdrawalValue:any;
  effectiveMaxDate: any;
  netGrossIndicator: any
  checkedValues: any = [];
  initialSurrenderValues: any = {
    PARTIAL_SURRENDER: initialPartialSurrender,
    FULL_SURRENDER: initialFullSurrender   
  }
   
  mockResponseValues: any = {
    PARTIAL_SURRENDER: partialMockResponse,
    FULL_SURRENDER: fullMockResponse
  }

  constructor(private datePipe: DatePipe, private currencyPipe: CurrencyPipe) { }

  ngOnInit() {
    this.resetData();
   }

  resetData = () => {
    if(this.surrenderDetails) {
        this.effectivedate = new Date(this.surrenderDetails.quote_effective_date);
        this.effectiveMaxDate = new Date(this.surrenderDetails.quote_effective_date);
    }

    // Only for selected checkbox data
    if(this.surrenderDetails) {
      this.baseFaceAmount = this.surrenderDetails.base_face_amt;
      this.grossWithholdAmount = this.surrenderDetails.gross_withdrawal_amt;     
    }

    this.amountRequested = "";
    this.netGrossIndicator = "";
    this.surrenderQuoteValues = [];
    this.dividendWithdrawalValue=[];
    this.checkedValues.push({ label: "Effective Date", value: this.datePipe.transform(this.effectivedate, "MM/dd/yyyy") });
    this.checkedValues.push({ label: "Base Face Amount", value: this.currencyPipe.transform(this.baseFaceAmount) });
    this.checkedValues.push({ label: "Gross Withdrawal Amount Available", value: this.currencyPipe.transform(this.grossWithholdAmount) });
    this.populateRequestedDetails();
    this.withdrawlSurrenderType == "PARTIAL_SURRENDER" ? this.checkedValues.push({ label: "Amount Requested", value: this.amountRequested }) : null;
  }
  
  onDateChange() {
    if(this.effectivedate != null)   
    this.effectiveDateCheck = true;
  }

  onCheckboxChange(item) {
    this.surrenderQuoteValues.forEach((element) =>
      element.id == item.id ? element.checked = item.checked : null);
    this.populateRequestedDetails();
  }

  fieldChecked(fieldName, event) {
    this.maxwithholdNoGain = document.getElementById("maxwithhold_no_gain").innerText;
    this.baseFaceAmount = document.getElementById("base_face_amt").innerText;
    this.maxwithdrawalamt = document.getElementById("max_withdrawal_amt").innerText;
    this.aIBalance = document.getElementById("additional_ins_bal").innerText;
    this.riderCashValue = document.getElementById("rider_cash_value").innerText;
    this.dwiBalance = document.getElementById("dwi_balance").innerText;
    this.grossWithholdAmount = document.getElementById("gross_withdrawal_amt").innerText;
    this.cvOfAI = document.getElementById("cashval_paidup_add").innerText;
        
    fieldName == "Effective Date" ? event.checked ? this.checkedValues.push({ label: "Effective Date", value: this.datePipe.transform(this.effectivedate, "MM/dd/yyyy") }) : this.checkedValues = this.checkedValues.filter(e => { return e.label !== fieldName }) : null;

    fieldName == "Amount Requested" ? event.checked ? this.checkedValues.push({ label: "Amount Requested", value: this.amountRequested }) : this.checkedValues = this.checkedValues.filter(e => { return e.label !== fieldName }) : null;

    fieldName == "Maximum Withdrawal without Causing Taxable Gain" ? event.checked ? this.checkedValues.push({ label: "Maximum Withdrawal without Causing Taxable Gain", value: this.maxwithholdNoGain}) : this.checkedValues = this.checkedValues.filter(e => { return e.label !== fieldName }) : null;

    fieldName == "Base Face Amount" ? event.checked ? this.checkedValues.push({ label: "Base Face Amount", value: this.baseFaceAmount}) : this.checkedValues = this.checkedValues.filter(e => { return e.label !== fieldName }) : null;

    fieldName == "Max Withdrawal Amount" ? event.checked ? this.checkedValues.push({ label: "Max Withdrawal Amount", value: this.maxwithdrawalamt}) : this.checkedValues = this.checkedValues.filter(e => { return e.label !== fieldName }) : null;

    fieldName == "Al Balance" ? event.checked ? this.checkedValues.push({ label: "Al Balance", value: this.aIBalance}) : this.checkedValues = this.checkedValues.filter(e => { return e.label !== fieldName }) : null;

    fieldName == "Rider Cash Values" ? event.checked ? this.checkedValues.push({ label: "Rider Cash Values", value: this.riderCashValue}) : this.checkedValues = this.checkedValues.filter(e => { return e.label !== fieldName }) : null;

    fieldName == "DWI Balance" ? event.checked ? this.checkedValues.push({ label: "DWI Balance", value: this.dwiBalance}) : this.checkedValues = this.checkedValues.filter(e => { return e.label !== fieldName }) : null;

    fieldName == "Gross Withdrawal Amount Available" ? event.checked ? this.checkedValues.push({ label: "Gross Withdrawal Amount Available", value: this.grossWithholdAmount}) : this.checkedValues = this.checkedValues.filter(e => { return e.label !== fieldName }) : null;    

    fieldName == "CV of Al" ? event.checked ? this.checkedValues.push({ label: "CV of Al", value: this.cvOfAI}) : this.checkedValues = this.checkedValues.filter(e => { return e.label !== fieldName }) : null;    
    
    this.populateRequestedDetails();
  }

  populateRequestedDetails() {
    const checkedItems = this.surrenderQuoteValues.filter((item) => item.checked);    
    
    if (this.withdrawlSurrenderType == "DIVIDEND_WITHDRAWAL") {      
    }

    //Base Checked Values
    if(this.checkedValues.length) {
      this.checkedValues.forEach(e => {
        checkedItems.push(e);
      })
    }
    let requestedDetails = {};
    let fullSurrenderDetails = {};    
    
    var html = "";
    /*
    *  Effective Date and Ammount requred should always stay on top on call notes.
    *  remaining all should follow ..
    */
    const effectiveDateItem =  checkedItems.find(item => item.label == "Effective Date");
    const amountRequested =  checkedItems.find(item => item.label == "Amount Requested");
    const maxwithholdNoGain = checkedItems.find(item =>item.label== "Maximum Withdrawal without Causing Taxable Gain");    
    const baseFaceAmount = checkedItems.find(item =>item.label== "Base Face Amount");
    const maxwithdrawalamt = checkedItems.find(item =>item.label== "Max Withdrawal Amount");
    const alBalance = checkedItems.find(item =>item.label== "Al Balance");
    const riderCashValue = checkedItems.find(item =>item.label== "Rider Cash Values");
    const dwiBalance = checkedItems.find(item =>item.label== "DWI Balance");
    const grossWithholdAmount = checkedItems.find(item =>item.label== "Gross Withdrawal Amount Available");
    const cvOfAl = checkedItems.find(item =>item.label== "CV of Al");    

    if (effectiveDateItem){
      html += '<dt class="pull-left">' + effectiveDateItem.label + ':</dt><dd>&nbsp;' + effectiveDateItem.value + '</dd>';
      requestedDetails[effectiveDateItem.label] = effectiveDateItem.value;
    }
    if (amountRequested){
      html += '<dt class="pull-left">' + amountRequested.label + ':</dt><dd>&nbsp;' + amountRequested.value + '</dd>';
      requestedDetails[amountRequested.label] = amountRequested.value;
    }
    if (maxwithholdNoGain){
      html += `<div class="mb-2"><b class="mr-1">${maxwithholdNoGain.label}:</b><span>${maxwithholdNoGain.value}</span></div>`;
      requestedDetails[maxwithholdNoGain.label] = maxwithholdNoGain.value;
    }    
    if (baseFaceAmount){
      html += '<dt class="pull-left">' + baseFaceAmount.label + ':</dt><dd>&nbsp;' + baseFaceAmount.value + '</dd>';
      requestedDetails[baseFaceAmount.label] = baseFaceAmount.value;
    }
    if (maxwithdrawalamt){
      html += '<dt class="pull-left">' + maxwithdrawalamt .label + ':</dt><dd>&nbsp;' + maxwithdrawalamt .value + '</dd>';
      requestedDetails[maxwithdrawalamt .label] = maxwithdrawalamt .value;
    }
    if (alBalance){
      html += '<dt class="pull-left">' + alBalance.label + ':</dt><dd>&nbsp;' + alBalance.value + '</dd>';
      requestedDetails[alBalance.label] = alBalance.value;
    }
    if (riderCashValue){
      html += `<div class="mb-2"><b class="mr-1">${riderCashValue.label}:</b><span>${riderCashValue.value}</span></div>`;
      requestedDetails[riderCashValue.label] = riderCashValue.value;
    }
    if (dwiBalance){
      html += `<div class="mb-2"><b class="mr-1">${dwiBalance.label}:</b><span>${dwiBalance.value}</span></div>`;
      requestedDetails[dwiBalance.label] = dwiBalance.value;
    }
    if (grossWithholdAmount){
      html += `<div class="mb-2"><b class="mr-1">${grossWithholdAmount.label}:</b><span>${grossWithholdAmount.value}</span></div>`;
      requestedDetails[grossWithholdAmount.label] = grossWithholdAmount.value;
    }    
    if (cvOfAl){
      html += '<dt class="pull-left">' + cvOfAl.label + ':</dt><dd>&nbsp;' + cvOfAl.value + '</dd>';
      requestedDetails[cvOfAl.label] = cvOfAl.value;
    }
   
    checkedItems.filter(item => (item.label != "Effective Date" && item.label != "Amount Requested" && item.label != "Maximum Withdrawal without Causing Taxable Gain" && item.label != "Base Face Amount" && item.label != "Max Withdrawal Amount" && item.label != "Al Balance" && item.label != "Rider Cash Values" && item.label != "DWI Balance" && item.label != "Gross Withdrawal Amount Available" && item.label != "CV of Al")).map(item => {
      html += '<dt class="pull-left">' + item.label + ':</dt><dd>&nbsp;' + item.value + '</dd>';
      requestedDetails[item.label] = item.value;
    });
    OcInfraModule.AppInjector.get(CommonService).quoteDetailsHtml = '<span style="font-size:15px" class="pad-lAr-10px">QUOTE</span><dl class="pad-lAr-10px">' + html + '</dl>'
    OcInfraModule.AppInjector.get(CommonService).showRequestedDetails();
    OcInfraModule.AppInjector.get(CommonService).quoteValues = { QUOTE: requestedDetails };  
    checkedItems.filter(item => (item.label == "Admin Fee" || item.label == "GMIB Fee" ||item.label == "MVA" || item.label == "Surrender Penalty " || item.label == "EDB Fee" ||item.label == "GWB/LWG Fee" || item.label =="ODB Fee" || item.label =="GMAB Fee"||item.label=="Base Face Amount"||item.label=="Gross Withdrawal Amount Available")).map(item => {
      html += '<dt class="pull-left">' + item.label + ':</dt><dd>&nbsp;' + item.value + '</dd>';
     fullSurrenderDetails[item.label] = item.value;
     OcInfraModule.AppInjector.get(CommonService).fullSurrenderFields   = fullSurrenderDetails;
    });
  }

  bindDataFromAPI() {
    this.surrenderQuoteValues = this.initialSurrenderValues[this.withdrawlSurrenderType];
    const response = this.mockResponseValues[this.withdrawlSurrenderType];
    const schema = response._options.links[0].schema.properties;
    this.surrenderQuoteValues.forEach((item) => {
      if (response[item.id] && typeof response[item.id] !== 'object') {
        item.value = (schema[item.id] && schema[item.id].format === 'currency')
          ? `$${Number(response[item.id]).toLocaleString()}`
          : response[item.id]
      }
    })
  }
  
  onQuoteValuesClick(event) {
    if(this.validateQuoteForm()) {
      let quoteEndpoint = this.currentPolicyResourceURL + this.quotesAPISource[this.withdrawlSurrenderType] + "?" + this.getQueryParams();
      OcInfraModule.AppInjector.get(CommonService).getCallback(quoteEndpoint, this.headers).subscribe(response => {
        this.sendQuoteValuesToParent(response);
        this.surrenderQuoteValues = this.initialSurrenderValues[this.withdrawlSurrenderType];
        const schema = response._options.links[0].schema.properties;
        this.surrenderQuoteValues.forEach((item) => {
          if (response[item.id] && typeof response[item.id] !== 'object') {
            item.id == "gross_cash_value_amount" ? item.checked = true : item.checked = false;
            item.value = (schema[item.id] && schema[item.id].format === 'currency')
            ? Number(response[item.id]) < 0 
            ? Number(Math.abs(response[item.id])).toLocaleString('USD', { style: 'currency', currency: "USD", minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/(\d{0})(\d)/, "$1-$2")
            : Number(response[item.id]).toLocaleString('USD', { style: 'currency', currency: "USD", minimumFractionDigits: 2, maximumFractionDigits: 2 })
            : response[item.id]
            if (schema[item.id] && schema[item.id].format === 'currency' && Number(response[item.id]) == 0) {item.disabled = true; }
          }
        })
        this.populateRequestedDetails();
      }, error => {
        if (error._embedded && error._embedded.status_report) {
          let alertMsg = [];
          error._embedded.status_report.messages.map(msg => alertMsg.push(msg.message))
          let dialogRef = OcInfraModule.AppInjector.get(CommonService).showConfirmation({ content: alertMsg, custom: true }, 'CLOSE', '', 'ALERT', '393px');
          dialogRef.afterClosed().subscribe(res => {
            res;
          });
        }
      });
    }
  }

  validateQuoteForm() {
    return this.effectivedate && this.effectivedate <= this.effectiveMaxDate
  }

  isStepComplete(){
    if(this.withdrawlSurrenderType == 'DIVIDEND_WITHDRAWAL') {
      return this.effectivedate ? true : false;
    } else {
      return this.effectivedate && this.amountRequested && this.netGrossIndicator && this.baseFaceAmount && this.grossWithholdAmount? true : false;
    }
  }

  /** AMOUNT AND PERCENT FORMATING  */
  formatNumber(n, type) {
    if (type == 'currency') {
      // format number 1000000 to 1,234,567
      return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return n.replace(/\D/g, "");
    }
  }

  formatCurrency(input, blur, respValue, isAmountRequested = false) {
    var input_val = "";
    if (respValue == '' || respValue == undefined) {
      input_val = input.target.value;
    } else {
      input_val = respValue;
    }
    if (input_val === "") { return; }
    var original_len = input_val.length;
    if (input_val.indexOf(".") >= 0) {
      var decimal_pos = input_val.indexOf(".");
      decimal_pos = decimal_pos > 15 ? 15 : decimal_pos;
      var left_side = input_val.substring(0, decimal_pos);
      var right_side = input_val.substring(decimal_pos);
      left_side = this.formatNumber(left_side, 'currency');
      right_side = this.formatNumber(right_side, 'currency');
      if (blur === "blur") {
        right_side += "00";
      }
      // Limit decimal to only 2 digits
      right_side = right_side.substring(0, 2);
      // join number by .
      input_val = "$" + left_side + "." + right_side;

    } else {
      // no decimal entered
      // add commas to number
      // remove all non-digits
      input_val = input_val.substring(0, 15);
      input_val = this.formatNumber(input_val, 'currency');
      input_val = "$" + input_val;
      // final formatting
      if (blur === "blur") {
        input_val += ".00";
      }
    }
    if (blur === 'blur' && isAmountRequested) {
      this.amountRequested = input_val;
      this.quoteValuesToEmit.emit({ amountRequested : this.amountRequested });
    }    
    if (respValue == '' || respValue == undefined) {
      input.target["value"] = input_val;
    } else {
      return input_val;
    }    
  }

  getQueryParams(){
    let params = new HttpParams();
    
    if (this.withdrawlSurrenderType == "PARTIAL_SURRENDER"){
      let amount: number = Number(this.amountRequested.replace(/[^0-9.-]+/g,""));
      params =  params.set('effectiveDate', this.datePipe.transform(this.effectivedate,"MM/dd/yyyy"));
      params = params.set('amount', amount.toString());
      params =  params.set('netGrossInd', this.netGrossIndicator);
    }else {
      params = params.set('effectiveDate', this.datePipe.transform(this.effectivedate,"MM/dd/yyyy"));
    }
    return params.toString();
  }

  sendQuoteValuesToParent(res) {
    let quoteDetails = {amountRequested:this.amountRequested,netGrossIndicator:this.netGrossIndicator};
    Object.assign(quoteDetails,res)
    this.quoteValuesToEmit.emit(quoteDetails)
  }
}
