import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { AuthenticationService } from './../../services/authentication.service';

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',

})
export class TimerComponent implements OnInit {

  public countDown: string;
  public showTimer: boolean;
  public timerWebWorker;

  constructor(private authenticationService: AuthenticationService, private commonService: CommonService) {
  }
  ngOnInit() {
    this.commonService.change
      .subscribe(response => {
        // Enable timer section in HTML and start the timer count
        this.showTimer = true;
        this.startTimer();
      });
  }

//  Creating web worker to get count the timer, when browser tab is in-active
  public startTimer() {
    var self = this;
    if (typeof (Worker) !== "undefined") {
      if (typeof (this.timerWebWorker) == "undefined") {
        this.timerWebWorker = new Worker("assets/js/timerWorker.js");
      }
      this.timerWebWorker.onmessage = function (event) {
        // console.log(event.data);
        self.countDown = event.data;
      };
    } else {
      // document.getElementById("result").innerHTML = "Sorry, your browser does not support Web Workers...";
    }
  }

  ngOnDestroy() {
    if (this.showTimer) {
      // unsubscribe to ensure no memory leaks
      this.timerWebWorker.terminate();
    }
  }

}