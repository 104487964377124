import { Component, OnInit, Input } from '@angular/core';
import { OcInfraModule, APICallerService, Configuration, ResourceService } from '@diaas/ux-web';
import { CommonService } from '../../services/common.service';
import { MatDialogRef, MatDialog } from '@angular/material';
import { AdvGrowlService } from 'primeng-advanced-growl';
import { MatTooltipModule } from '@angular/material/tooltip';
import { cloneDeep } from 'lodash';
import * as _ from 'lodash';
export interface dropdown {
  id: string;
  vewValue: string;
}
@Component({
  selector: 'party-info',
  templateUrl: './partyInformation.component.html',
  styleUrls: ['./partyInformation.component.scss']
})

export class PartyInformationComponent implements OnInit {

  constructor(private dialog: MatDialog, private commonService: CommonService) { };

  type: dropdown[];
  emailDisplayedColumns = ['name', 'type', 'actions'];
  phoneNumberDisplayedColumns = ['number', 'type', 'actions'];
  partyInformation: any;
  dateOfBirth: string;
  fullName: string;
  ssn: string;
  agentAddress: string;
  agentEmailAddress: string;
  agentPhoneNumber: string;
  communicationPreference: string;
  emailList: any[] = [];
  phoneNumbersList: any[] = [];
  dataSource: any[] = [];
  disableAdd: boolean = false;
  disableAddPhoneNumber: boolean = false;
  phoneNumberOtherPolicyInfo: boolean = false;
  emailOtherPolicyInfo: boolean = false;
  requiredClass: boolean = true;
  header: any;
  phonumberTypes: any = [];
  personResponse: any;
  changedNumber: any;
  additionalPolicies: any;
  otherPolicyColumns: string[] = ['blank', 'name', 'policyNumber', 'productCodeDesc', 'policyStatusDesc', 'roleDesc'];
  selectedAdditionalPolicies: any[] = [];
  value: string = '';
  phonevalue: string = '';
  isAnnuity: boolean = OcInfraModule.AppInjector.get(CommonService).isAnnuity;
  addressResponse: any;
  policyDetails: any;
  cloneList: any;
  label: string = 'Save';
  emailLabel: string = 'Save';
  cancelPhonenumberInfo: boolean = false;
  cancelEmailInfo: boolean = false;
  isAgent: boolean = false;
  deletePayload: any;
  deleteUrl: any;
  emailDeletePayLoad: any;
  emailDeleteUrl: any;
  headers: any;
  additionalHeaders = _.filter(Configuration.config.headers["multiHostUrlHeader"], function (o) { return o.alias == 'workitemsSchema' });
  phoneNumberRes: any;
  emailResponse: any;
  cloneEmailList: any;
  disablePhoneNumSaveBtn: boolean = true;
  disableEmailSaveBtn: boolean = true;
  authsucess = OcInfraModule.AppInjector.get(CommonService).authsucess;
  otherRoleSucess = OcInfraModule.AppInjector.get(CommonService).otherRoleAuthsucess;
  resourceService = OcInfraModule.AppInjector.get(ResourceService);
  workRequestHref = this.resourceService.getHrefByAliasName("workRequestHistory");
  disablePhoneEmail: boolean = false;
  reginCode = _.filter(Configuration.config.headers["multiHostUrlHeader"], function (o) { return o.alias == 'workRequestHistory' });
   authResponse = this.resourceService.getResourceFromAliasName('authRes');
   selectedRoleSummary = this.resourceService.getResourceFromAliasName('selectedRoles');   
   roleList = this.resourceService.getResourceFromAliasName('roles');
   otherRoleAuthorize =  _.isEmpty(this.selectedRoleSummary.data) ? _.filter(this.roleList._links.item, function (o) { return o.summary.role == 'Owner'; }) : false ;

  ngOnInit() {
    OcInfraModule.AppInjector.get(AdvGrowlService).clearMessages();
    OcInfraModule.AppInjector.get(CommonService).roleEmailChanged = false;
    OcInfraModule.AppInjector.get(CommonService).rolePhoneChanged = false;
    this.headers = OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("partyRoles");
    const selectedRole = OcInfraModule.AppInjector.get(CommonService).selectedRole;
    const selectedPartyInfoRole = OcInfraModule.AppInjector.get(CommonService).selectedPartyInfoRole;
    const headers = OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("partyRoles");
    //let authsucess = OcInfraModule.AppInjector.get(CommonService).authsucess;
    this.policyDetails = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyDetails');
    this.disablePhoneEmail = OcInfraModule.AppInjector.get(CommonService).disableTermandFia;
    this.isAgent = selectedPartyInfoRole.toLowerCase() === 'agent';
 //  let otherRoleSucess = OcInfraModule.AppInjector.get(CommonService).otherRoleAuthsucess;
    headers['caller_role'] = selectedRole;
    let roles = ["Owner", "POA", "Agent Assistant"];
    if (this.otherRoleSucess || this.authsucess) {
      if (roles.includes(OcInfraModule.AppInjector.get(CommonService).authorizedRole)) {

      }
      headers['role'] = OcInfraModule.AppInjector.get(CommonService).selectedRole;
      headers['caller_role'] =OcInfraModule.AppInjector.get(CommonService).authorizedRole;
    }
    const personURL = OcInfraModule.AppInjector.get(CommonService).personURL;
    OcInfraModule.AppInjector.get(CommonService).getCallback(personURL, headers).subscribe(partyResponse => {
      if(this.isAgent){
        this.dateOfBirth = partyResponse && partyResponse.agentDob  ? partyResponse.agentDob : '';
        this.fullName = partyResponse && partyResponse.fullName || partyResponse.agentFullName ? partyResponse.fullName || partyResponse.agentFullName : '';
        this.communicationPreference = "";
        this.ssn = partyResponse && partyResponse.agentSsn || partyResponse.agentSsnTin ? partyResponse.agentSsn || partyResponse.agentSsnTin : '';
        this.agentAddress = partyResponse && partyResponse.agentAddress ? partyResponse.agentAddress:'';
        this.agentEmailAddress = partyResponse && partyResponse.agentEmailAddress ? partyResponse.agentEmailAddress:'';
        this.agentPhoneNumber = partyResponse && partyResponse.phone || partyResponse.agentPhone ? partyResponse.phone || partyResponse.agentPhone : '';
        return
      }
      let infoUrl = partyResponse._links.hasOwnProperty('person') ? partyResponse._links.person.href : partyResponse._links.organization.href;
      OcInfraModule.AppInjector.get(CommonService).partyResponse = partyResponse;
      OcInfraModule.AppInjector.get(CommonService).getCallback(infoUrl, headers).subscribe(personResponse => {
        this.personResponse = personResponse;
        if (partyResponse._links.hasOwnProperty('person')) {
          this.dateOfBirth = personResponse && personResponse.date_of_birth ? personResponse.date_of_birth : '';
          let firstName = personResponse && personResponse.first_name ? personResponse.first_name : '';
          let lastName = personResponse && personResponse.last_name ? personResponse.last_name : '';
          this.fullName = lastName + ',' + ' ' + firstName;
          this.communicationPreference = personResponse && personResponse.commn_preference ? personResponse.commn_preference : '';
          this.ssn = personResponse && personResponse.ssn ? personResponse.ssn.replace(/(\d{3})(\d{2})(\d{3})/g, '$1-$2-$3') : '';
        } else {
          this.dateOfBirth = personResponse && personResponse.date_of_birth ? personResponse.date_of_birth : '';
          this.fullName = personResponse && personResponse.company_name ? personResponse.company_name : '';
          this.communicationPreference = personResponse && personResponse.commn_preference ? personResponse.commn_preference : '';
          this.ssn = personResponse && personResponse.ssn ? personResponse.ssn.substr(0, 2) + '-' + personResponse.ssn.substr(2, personResponse.ssn.length) : '';
        }

        OcInfraModule.AppInjector.get(CommonService).personResponse = personResponse;
        OcInfraModule.AppInjector.get(CommonService).emailHref = personResponse._links["email"].href;
        OcInfraModule.AppInjector.get(CommonService).phoneNumberHref = personResponse._links["phonenumber"].href;
        OcInfraModule.AppInjector.get(CommonService).getCallback(personResponse._links["other-policy-info"].href, headers).subscribe(otherPolicyInfo => {
          let isOtherPolicyInfoList = OcInfraModule.AppInjector.get(CommonService).getNested(otherPolicyInfo, '_embedded', 'otherPolicyInfoList');
          this.additionalPolicies = !_.isEmpty(isOtherPolicyInfoList) ? _.sortBy(isOtherPolicyInfoList, 'directoryId') : false;
          OcInfraModule.AppInjector.get(CommonService).additionalPolicies = this.additionalPolicies;
        })
        OcInfraModule.AppInjector.get(CommonService).getCallback(personResponse._links["email"].href, headers).subscribe(emailResponse => {
          OcInfraModule.AppInjector.get(CommonService).partyEmailInformation = emailResponse;
          this.emailResponse = emailResponse;
          let postUrl = _.filter(emailResponse._options.links, function (o) { return o.method == 'POST' })
          if (postUrl.length) {
            this.disableAdd = true;
          }
          else {
            if (this.authsucess == true || this.otherRoleSucess == true) {
              this.value = "Authenticated party does not have authorization to make changes";
            }
            else {
              this.value = "Caller was not authorized";
            }
          }
          OcInfraModule.AppInjector.get(CommonService).getCallback(personResponse._links["phonenumber"].href, headers).subscribe(phoneNumberResponse => {
            this.phoneNumberRes = phoneNumberResponse;
            OcInfraModule.AppInjector.get(CommonService).partyPhoneNumberInformation = phoneNumberResponse;
            let postUrl = _.filter(phoneNumberResponse._options.links, function (o) { return o.method == 'POST' })
            if (postUrl.length) {
              this.disableAddPhoneNumber = true;
            }
            else {
              if (this.authsucess == true || this.otherRoleSucess == true) {
                this.phonevalue = "Authenticated party does not have authorization to make changes";
              }
              else {
                this.phonevalue = "Caller was not authorized";
              }
            }

            this.type = phoneNumberResponse._options.properties.type.oneOf;
            this.type.forEach(typeOBj => {
              this.phonumberTypes.push(typeOBj["title"])
            }
            );
            if (emailResponse && phoneNumberResponse) {
              let list: any = [];
              list.push(emailResponse, phoneNumberResponse)
              for (let i = 0; i < list.length; i++) {
                if (list[i]._embedded.communicationNumberList) {
                  list[i]._embedded.communicationNumberList.forEach(summaryObj => {
                    Object.assign(summaryObj, { disabled: true, patch: false, delete: false })
                    summaryObj._options.links.forEach(linksObj => {
                      if (linksObj.method == 'PATCH') {
                        Object.assign(summaryObj, { patch: true })
                      }
                      if (linksObj.rel == 'delete') {
                        Object.assign(summaryObj, { delete: true })
                      }

                    });
                    i == 0 ? '' : summaryObj.number = summaryObj.number.replace(/(\d{3})(\d{3})(\d{3})/g, '$1-$2-$3');
                    i == 0 ? this.emailList.push(summaryObj) : this.phoneNumbersList.push(summaryObj);
                    i !== 0 ? this.phoneNumbersList = [...this.phoneNumbersList] : this.emailList = [...this.emailList];
                  });
                }
              }
            }
          });
        });
      });
    });
    // PANOCSR-3491 changes
    let productInfo =OcInfraModule.AppInjector.get(CommonService).productInfo;
    if(productInfo.company_code == 'MET' || productInfo.company_code == 'MTL'){
        OcInfraModule.AppInjector.get(CommonService).mfaApplicable= true;
    }else{
        OcInfraModule.AppInjector.get(CommonService).mfaApplicable= false;
        OcInfraModule.AppInjector.get(CommonService).mfaAuthorized = true;
    }
  }

  setPhnBtnDisable( flag){
    this.disablePhoneNumSaveBtn = flag;
    OcInfraModule.AppInjector.get(CommonService).rolePhoneChanged =!flag;
  }

  setEmailBtnDisable( flag){
    this.disableEmailSaveBtn = flag;
    OcInfraModule.AppInjector.get(CommonService).roleEmailChanged =!flag;
  }

  logPhoneNumber(event) {
    OcInfraModule.AppInjector.get(AdvGrowlService).clearMessages();
    let phoneNumberHeaders = {};
    Object.assign(phoneNumberHeaders, this.headers, { statuaory_company_code: this.additionalHeaders[0].headers["statuaory_company_code"],product_type: this.policyDetails.data.product_type });

  let transationDetails = {
    "hostKey":  this.otherRoleAuthorize ? this.otherRoleAuthorize[0] && this.otherRoleAuthorize[0].summary.hostKey ? this.otherRoleAuthorize[0].summary.hostKey : '' : this.selectedRoleSummary && this.selectedRoleSummary.data.hostKey ? this.selectedRoleSummary.data.hostKey : '',
    "sessionId": this.authResponse && this.authResponse.data.contactSessionID ? this.authResponse.data.contactSessionID : '',
    "callStart": this.authResponse && this.authResponse.data.callStart ? this.authResponse.data.callStart : '',      
    "clientId": this.otherRoleAuthorize ? this.otherRoleAuthorize[0] && this.otherRoleAuthorize[0].summary.clientId ? this.otherRoleAuthorize[0].summary.clientId : '' : this.selectedRoleSummary && this.selectedRoleSummary.data.clientId ? this.selectedRoleSummary.data.clientId : '',
    "userId": OcInfraModule.AppInjector.get(CommonService).incontactId,
    "policyNumber": this.policyDetails.data.policy_number,
    'company_code':this.policyDetails.data.company_code,
    'region_code' : this.reginCode && this.reginCode[0].headers ? this.reginCode[0].headers.region_code : '',
    'remote_user' : this.reginCode && this.reginCode[0].headers ? this.reginCode[0].headers.remote_user : '',
    "origin_app":"csr_portal",
  } 
    if (this.label == 'Delete') {
      let dialogRef = OcInfraModule.AppInjector.get(CommonService).showConfirmationPopup('Are you sure you want to delete?', 'NO', 'YES','alert','340px');
      dialogRef.afterClosed().subscribe(res => {
        if (res) {
          this.cancelPhonenumberInfo = false;
          Object.assign(this.deletePayload,transationDetails);
          this.deletePayload['other_policy_info'] = this.selectedAdditionalPolicies;
          this.deletePayload['policyNumber'] = this.policyDetails.data.policy_number,
          this.phoneNumberRes._options.properties.type.oneOf.forEach(typeOBj => {
            typeOBj.enum[0] == this.deletePayload.type ? this.deletePayload.description = typeOBj.title :'';
          });
          OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false })
          OcInfraModule.AppInjector.get(CommonService).postCallback(this.deleteUrl[0].href, this.deletePayload, phoneNumberHeaders).subscribe(response => {
           this.refreshInstances();
              OcInfraModule.AppInjector.get(CommonService).getCallback(OcInfraModule.AppInjector.get(CommonService).phoneNumberHref, this.headers).subscribe(refreshResponse => {
              this.refreshData(refreshResponse, 'phoneNumber');
              this.additionalPolicies = OcInfraModule.AppInjector.get(CommonService).additionalPolicies;
              this.phoneNumberOtherPolicyInfo = false;
              this.cancelDelete(event);
              OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false })
              OcInfraModule.AppInjector.get(AdvGrowlService).createSuccessMessage('Phone Number successfully Deleted', 'Success');
            });
          },
            error => {
              if (error._embedded) {
                OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false })
                error._embedded.status_report && error._embedded.status_report.messages && error._embedded.status_report.messages.length ? OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error._embedded.status_report.messages[0].message, 'Error', 0) : OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage("Something went wrong please try again", 'Error', 0) ;
              }
              else {
                OcInfraModule.AppInjector.get(CommonService).getCallback(OcInfraModule.AppInjector.get(CommonService).phoneNumberHref, this.headers).subscribe(refreshResponse => {
                  this.refreshData(refreshResponse, 'phoneNumber');
                  this.additionalPolicies = OcInfraModule.AppInjector.get(CommonService).additionalPolicies;
                  this.phoneNumberOtherPolicyInfo = false;
                  this.cancelDelete(event);
                  OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false })
                  OcInfraModule.AppInjector.get(AdvGrowlService).createSuccessMessage('Phone Number successfully Deleted', 'Success');
                });
              }

            });
        } else {
          this.cancelDelete(event);
        }
      })

    } else {
      let method = '';
      let upadateChange = _.filter(this.phoneNumbersList, function (o) { return o.disabled == false });
      let num = upadateChange[0].number.replace(/[^a-zA-Z0-9]/g, '');
      upadateChange[0].number = num;
      upadateChange[0].id !== "" ? method = 'patch' : method = 'post';
      let patch: any;
      let url = '';
      if (num.length == 10) {
        if (method == 'post' || method == 'patch') {
          OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: true })
        }
        if (method == 'post') {
          let phoneNumberInfo = OcInfraModule.AppInjector.get(CommonService).partyPhoneNumberInformation;
          let postUrl = _.filter(phoneNumberInfo._options.links, function (o) { return o.method == 'POST' });
          url = postUrl[0].href;
        } else {
          patch = _.filter(upadateChange[0]['_options'].links, function (o) { return o.method == 'PATCH' })
          url = patch[0].href;
        }
        let payLoad = _.pick(upadateChange[0], _.keys({ number: null, id: null, type: null }));
        // this.selectedAdditionalPolicies.forEach(e => { e.emailIdChange = true, e.addressChange = false });
        this.phoneNumberRes._options.properties.type.oneOf.forEach(typeOBj => {
          typeOBj.enum[0] == payLoad.type ? payLoad.description = typeOBj.title :'';
        });
        Object.assign(payLoad,transationDetails);
        payLoad['companyCode'] = this.policyDetails.data.company_code;
        payLoad['other_policy_info'] = this.selectedAdditionalPolicies;
        payLoad['policyNumber'] = this.policyDetails.data.policy_number;
        let apiMethod = method == 'post' ? 'postCallback' : 'patchCallback';

        OcInfraModule.AppInjector.get(CommonService)[apiMethod](url, payLoad, phoneNumberHeaders).subscribe(response => {
          this.disableAddPhoneNumber = true;
          this.phoneNumberOtherPolicyInfo = false;
          this.selectedAdditionalPolicies = [];
          this.setPhnBtnDisable(true);
          this.refreshInstances();
          OcInfraModule.AppInjector.get(CommonService).getCallback(OcInfraModule.AppInjector.get(CommonService).phoneNumberHref, this.headers).subscribe(refreshResponse => {
            this.refreshData(refreshResponse, 'phoneNumber');
          });
          OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
          if (method == 'patch') {
            OcInfraModule.AppInjector.get(AdvGrowlService).createSuccessMessage('Phone Number successfully Updated', 'Success');
          } else {
            OcInfraModule.AppInjector.get(AdvGrowlService).createSuccessMessage('Phone Number successfully Added', 'Success');
          }
        },
          error => {
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false })
            error._embedded && error._embedded.status_report && error._embedded.status_report.messages && error._embedded.status_report.messages.length ?  OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error._embedded.status_report.messages[0].message, 'Error', 0) : OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage("Something went wrong, please try again ", 'Error', 0);
          });
      }
      else {
        OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage("Phone Number should have atleast 10 digits", 'Error', 0);
      }
    }
  }

  logEmail(event) {
    OcInfraModule.AppInjector.get(AdvGrowlService).clearMessages();
    let emailHeaders = {};
    Object.assign(emailHeaders, this.headers, { statuaory_company_code: this.additionalHeaders[0].headers["statuaory_company_code"],product_type: this.policyDetails.data.product_type });
    let transationDetails = {
      "hostKey":  this.otherRoleAuthorize ? this.otherRoleAuthorize[0] && this.otherRoleAuthorize[0].summary.hostKey ? this.otherRoleAuthorize[0].summary.hostKey : '' : this.selectedRoleSummary && this.selectedRoleSummary.data.hostKey ? this.selectedRoleSummary.data.hostKey : '',
      "sessionId": this.authResponse && this.authResponse.data.contactSessionID ? this.authResponse.data.contactSessionID : '',
      "callStart": this.authResponse && this.authResponse.data.callStart ? this.authResponse.data.callStart : '',      
      "clientId": this.otherRoleAuthorize ? this.otherRoleAuthorize[0] && this.otherRoleAuthorize[0].summary.clientId ? this.otherRoleAuthorize[0].summary.clientId : '' : this.selectedRoleSummary && this.selectedRoleSummary.data.clientId ? this.selectedRoleSummary.data.clientId : '',
      "userId": OcInfraModule.AppInjector.get(CommonService).incontactId,
      "policyNumber": this.policyDetails.data.policy_number,
      'company_code': this.policyDetails.data.company_code,
      'region_code' : this.reginCode && this.reginCode[0].headers ? this.reginCode[0].headers.region_code : '',
      'remote_user' : this.reginCode && this.reginCode[0].headers ? this.reginCode[0].headers.remote_user : '',
      "origin_app":"csr_portal",
    } 
    if (this.emailLabel == 'Delete') {
      let dialogRef = OcInfraModule.AppInjector.get(CommonService).showConfirmationPopup('Are you sure you want to delete?', 'NO', 'YES','alert','340px');
      dialogRef.afterClosed().subscribe(res => {
        if (res) {
          this.cancelEmailInfo =false;
          Object.assign(this.emailDeletePayLoad,transationDetails);
          this.emailDeletePayLoad['other_policy_info'] = this.selectedAdditionalPolicies;
          this.emailDeletePayLoad['policyNumber'] = this.policyDetails.data.policy_number;
          this.emailResponse._options.properties.type.oneOf.forEach(typeOBj => {
            typeOBj.enum[0] == this.emailDeletePayLoad.type ? this.emailDeletePayLoad.description = typeOBj.title :'';
          });
          OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false });
          OcInfraModule.AppInjector.get(CommonService).postCallback(this.emailDeleteUrl[0].href, this.emailDeletePayLoad, emailHeaders).subscribe(response => {
            this.refreshInstances();
            OcInfraModule.AppInjector.get(CommonService).getCallback(OcInfraModule.AppInjector.get(CommonService).emailHref, this.headers).subscribe(refreshResponse => {
              this.refreshData(refreshResponse, 'email');
              this.cancelEmail(event);
              OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
              OcInfraModule.AppInjector.get(AdvGrowlService).createSuccessMessage('Email Address successfully Deleted', 'Success');
            });
            },
            error => {
              if (error._embedded) {
                OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
                error._embedded.status_report && error._embedded.status_report.messages && error._embedded.status_report.messages.length ? OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error._embedded.status_report.messages[0].message, 'Error', 0) : OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage("Something went wrong, please try again ", 'Error', 0);
              } else {
                OcInfraModule.AppInjector.get(CommonService).getCallback(OcInfraModule.AppInjector.get(CommonService).emailHref, this.headers).subscribe(refreshResponse => {
                  this.refreshData(refreshResponse, 'email');
                  this.cancelEmail(event);
                  OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
                  OcInfraModule.AppInjector.get(AdvGrowlService).createSuccessMessage('Email Address successfully Deleted', 'Success');
                })
              }
            });
        } else {
          this.cancelEmail(event);
        }
      })
    } else {
      let method = '';
      let upadateChange = _.filter(this.emailList, function (o) { return o.disabled == false });
      upadateChange[0].id !== "" ? method = 'patch' : method = 'post';
      let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (upadateChange[0].number.match(mailformat)) {
        let patch: any;
        let url = '';
        if (method == 'post') {
          let emailInfo = OcInfraModule.AppInjector.get(CommonService).partyEmailInformation
          let postUrl = _.filter(emailInfo._options.links, function (o) { return o.method == 'POST' });
          url = postUrl[0].href;
        } else {
          patch = _.filter(upadateChange[0]['_options'].links, function (o) { return o.method == 'PATCH' })
          url = patch[0].href;
        }
        let payLoad = _.pick(upadateChange[0], _.keys({ number: null, id: null, type: null }));
        // this.selectedAdditionalPolicies.forEach(e => { e.emailIdChange = true, e.addressChange = false });
        this.emailResponse._options.properties.type.oneOf.forEach(typeOBj => {
          typeOBj.enum[0] == payLoad.type ? payLoad.description = typeOBj.title :'';
        });
        Object.assign(payLoad,transationDetails);
        payLoad['companyCode'] = this.policyDetails.data.company_code;
        payLoad['other_policy_info'] = this.selectedAdditionalPolicies;
        OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false });
        let apiMethod = method == 'post' ? 'postCallback' : 'patchCallback';
        OcInfraModule.AppInjector.get(CommonService)[apiMethod](url, payLoad, emailHeaders).subscribe(response => {
          this.disableAdd = true;
          this.emailOtherPolicyInfo = false;
          this.selectedAdditionalPolicies = [];
          this.setEmailBtnDisable(true);
          this.refreshInstances();
          OcInfraModule.AppInjector.get(CommonService).getCallback(OcInfraModule.AppInjector.get(CommonService).emailHref, this.headers).subscribe(refreshResponse => {
            this.refreshData(refreshResponse, 'email');
          });
          OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
          if (method == 'patch') {
            OcInfraModule.AppInjector.get(AdvGrowlService).createSuccessMessage('Email Address successfully Updated', 'Success');
          } else {
            OcInfraModule.AppInjector.get(AdvGrowlService).createSuccessMessage('Email Address successfully Added', 'Success');
          }
        },
          error => {
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false })
            error._embedded && error._embedded.status_report && error._embedded.status_report.messages && error._embedded.status_report.messages.length ?  OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error._embedded.status_report.messages[0].message, 'Error', 0) : OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage('Please enter valid email', 'Error', 0);
          });
      } else {
        OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage("Something went wrong, please try again ", 'Error', 0);
      }
    }
  }

  refreshData(responce, type) {
    let list: any = [];
    type == 'email' ? this.emailList = [] : this.phoneNumbersList = [];
    if (responce._embedded && responce._embedded.communicationNumberList) {
      list.push(responce._embedded.communicationNumberList)
      for (let i = 0; i < list.length; i++) {
        list[i].forEach(summaryObj => {
          Object.assign(summaryObj, { disabled: true, patch: false, delete: false })
          summaryObj._options.links.forEach(linksObj => {
            switch (linksObj.rel) {
              case 'patch':
                Object.assign(summaryObj, { patch: true })
                break;
              case 'delete':
                Object.assign(summaryObj, { delete: true })
                break;
            }
          });
          type == 'phoneNumber' ? summaryObj.number = summaryObj.number.replace(/(\d{3})(\d{3})(\d{3})/g, '$1-$2-$3') : '';
          type == 'email' ? this.emailList.push(summaryObj) : this.phoneNumbersList.push(summaryObj);
          type !== 'email' ? this.phoneNumbersList = [...this.phoneNumbersList] : this.emailList = [...this.emailList];
        });
      }
    }
  }

  editElement(element: any) {
    var isMFADone = OcInfraModule.AppInjector.get(CommonService).mfaAuthorized;
    if(!isMFADone){
      document.getElementById("mfa-mfa-modal-anchor").click();
      OcInfraModule.AppInjector.get(CommonService).selectedScreen = "emailupdate";
    }
    else{
    this.setPhnBtnDisable(true);
    this.emailOtherPolicyInfo = true;
    element.disabled = false;
    this.cloneEmailList = cloneDeep(this.emailList);
    for (let item of this.emailList) {
      if (item.number !== element.number) {
        item.disabled = true;
        if (item.id == "") {
          item.patch = true;
        }
      }
    }
  }
  }

  closeElement(element: any) {
    this.setEmailBtnDisable(true);
    this.cancelEmailInfo = false;
    this.emailLabel = 'Save';
    this.emailOtherPolicyInfo = false;
    if (element.id) {
      element.disabled = false;
      for (let item of this.emailList) {
        if (item.number == element.number) {
          item.disabled = true;
        }
      }
      const a = new Set([this.emailList, this.cloneEmailList]);
      if (a.size > 0) {
        for (let item of this.cloneEmailList) {
          item.disabled = true;
        }
        this.emailList = this.cloneEmailList;
      }
    } else {
      this.emailList = _.filter(this.emailList, function (o) {
        if (o.disabled == false && o.id) {
          o.disabled = true;
        }
        return o.type !== 'EWE';
      });
      this.emailList = _.remove(this.emailList, item => item.disabled === true);
      this.emailList = [...this.emailList];
      this.disableAdd = true;
    }
  }

  deleteEmail(element: any) {
    this.emailOtherPolicyInfo = true;
    this.cancelEmailInfo = true;
    this.setEmailBtnDisable(true);
    OcInfraModule.AppInjector.get(CommonService).roleEmailChanged = true;
    this.emailLabel = 'Delete';
    this.emailDeletePayLoad = _.pick(element, _.keys({ number: null, id: null, type: null }));
    this.emailDeletePayLoad['companyCode'] = this.policyDetails.data.company_code;
    this.emailDeleteUrl = _.filter(element._options.links, function (o) { return o.rel == 'delete' });
  }

  createNew() {
    OcInfraModule.AppInjector.get(AdvGrowlService).clearMessages();
    this.cancelEmailInfo = false;
    this.emailList = _.filter(this.emailList, function (o) {
      if (o.disabled == false && o.id) {
        o.disabled = true;
      }
      return o.type !== 'EWE';
    });
    this.emailList.push({ number: '', type: '', id: '', disabled: false, patch: false })
    this.emailList = [...this.emailList];
    this.disableAdd = false;
    this.emailOtherPolicyInfo = true;
  }

  editPhoneNumber(element: any) {
    var isMFADone = OcInfraModule.AppInjector.get(CommonService).mfaAuthorized;
    if(!isMFADone){
      document.getElementById("mfa-mfa-modal-anchor").click();
      OcInfraModule.AppInjector.get(CommonService).selectedScreen = "phonenumberupdate";
    }
    else{
    this.setPhnBtnDisable(true);
    OcInfraModule.AppInjector.get(AdvGrowlService).clearMessages();
    this.phoneNumberOtherPolicyInfo = true;
    this.cancelPhonenumberInfo = false;
    this.label = 'Save';
    this.cloneList = cloneDeep(this.phoneNumbersList);
    element.disabled = false;
    for (let item of this.phoneNumbersList) {
      if (element.number !== item.number)
        if (item.id !== element.id) {
          if (!item.disable) {
            this.changedNumber = item;
          }
          item.disabled = true;
          if (item.id == "") {
            item.patch = true;
          }
        }
    }
    if (this.changedNumber && this.changedNumber.length) {
      for (let item of this.cloneList) {
        if (item.id == this.changedNumber.id) {
          item.disable = true
        }
      }
      this.phoneNumbersList = this.cloneList;
    }
  }
  }

  closePhoneNumber(element: any) {
    this.setPhnBtnDisable(true);
    this.phoneNumberOtherPolicyInfo = false;
    this.cancelPhonenumberInfo = false;
    this.label = 'Save';
    if (element.id) {
      element.disabled = false;
      for (let item of this.phoneNumbersList) {
        if (item.id == element.id) {
          item.disabled = true;
        }
      }
    } else {
      this.phoneNumbersList = _.filter(this.phoneNumbersList, function (o) {
        if (o.disabled == false && o.id) {
          o.disabled = true;
        }
        return o.type !== 'EWE';
      });
      this.phoneNumbersList = _.remove(this.phoneNumbersList, item => item.disabled === true);
      this.phoneNumbersList = [...this.phoneNumbersList];
      this.disableAddPhoneNumber = true;
    }
    const a = new Set([this.phoneNumbersList, this.cloneList]);
    if (a.size > 0) {
      for (let item of this.cloneList) {
        item.disabled = true;
      }
      this.phoneNumbersList = this.cloneList;
    }
  }

  cancelEmail(event) {
    this.emailOtherPolicyInfo = false;
    this.cancelEmailInfo = false;
    this.emailLabel = 'Save';
    this.setEmailBtnDisable(true);
  }

  cancelDelete(event) {
    this.phoneNumberOtherPolicyInfo = false;
    this.cancelPhonenumberInfo = false;
    this.label = 'Save';
    this.setPhnBtnDisable(true);
  }

  deletePhoneNumber(element: any) {
    OcInfraModule.AppInjector.get(AdvGrowlService).clearMessages();
    this.phoneNumberOtherPolicyInfo = true;
    this.cancelPhonenumberInfo = true;
    OcInfraModule.AppInjector.get(CommonService).rolePhoneChanged =true;
    this.label = 'Delete';
    let payLoad = _.pick(element, _.keys({ number: null, id: null, type: null }));
    payLoad.number = payLoad.number.replace(/\-/g, "");
    payLoad['companyCode'] = this.policyDetails.data.company_code;
    payLoad['other_policy_info'] = this.selectedAdditionalPolicies;
    this.deletePayload = payLoad;
    this.deleteUrl = _.filter(element._options.links, function (o) { return o.rel == 'delete' });
  }

  numberOnly(e, changeReq,element) {
    {
      if(e.keyCode > 31 && ((e.keyCode < 48 || e.keyCode > 57) && (e.keyCode < 96 || e.keyCode > 105)) && e.keyCode != 46){
        e.preventDefault();
      } else {
          e.target["value"] = e.target["value"].replace(/[^0-9]/gi, '');
          e.target["value"] = e.target["value"].replace(/(\d{3}(?!\d?$))\-?/g, '$1-');
          if(changeReq == 'change'){
            element.number = e.target["value"];
            this.onPhoneNumberChange(element);
          }
          return true;
      }
    }
  }

  createNewPhoneNumber() {
    OcInfraModule.AppInjector.get(AdvGrowlService).clearMessages();
    this.additionalPolicies = OcInfraModule.AppInjector.get(CommonService).additionalPolicies;
    this.cancelPhonenumberInfo = false;
    this.label = 'Save';
    this.phoneNumbersList = _.filter(this.phoneNumbersList, function (o) {
      if (o.disabled == false && o.id) {
        o.disabled = true;
      }
      return o.type !== 'EWE';
    });
    this.phoneNumbersList.push({ number: '', type: '', id: '', disabled: false, patch: false })
    this.phoneNumbersList = [...this.phoneNumbersList];
    this.disableAddPhoneNumber = false;
    this.phoneNumberOtherPolicyInfo = true;
  }

  tooltip(key) {
    let returnvalue = '';
    if (key === true) {
      let authsucess = OcInfraModule.AppInjector.get(CommonService).authsucess;
      let otherRoleSucess = OcInfraModule.AppInjector.get(CommonService).otherRoleAuthsucess;
      if (authsucess == true || otherRoleSucess == true) {
        returnvalue = "Authenticated party does not have authorization to make changes";
      }
      else {
        returnvalue = "Caller was not authorized";
      }
    }
    return returnvalue;
  }

  otherPolicyClick(e, row) {
    const resourceService = OcInfraModule.AppInjector.get(ResourceService);
    const selectedRoleSummary = resourceService.getResourceFromAliasName('selectedRoles');
    if (e.checked) {
      let policiesToAdd = this.additionalPolicies.filter(e => {
        return e.directoryId === row.directoryId && e.policyNumber !== selectedRoleSummary.data.policyNumber
      });
      this.selectedAdditionalPolicies.push(...policiesToAdd);
    } else {
      let remainingPolicies = this.selectedAdditionalPolicies.filter(e => {
        return e.directoryId !== row.directoryId;
      })
      this.selectedAdditionalPolicies = remainingPolicies;
    }
  }

  getRowSpan(index, element) {
    let polIndex = _.findIndex(this.additionalPolicies, ['directoryId', element.directoryId]);
    let numOfPolicies = this.commonService.additionalPolicies.filter(e => {
      return e.directoryId === element.directoryId
    }).length

    if (polIndex === index && numOfPolicies > 0) {
      return numOfPolicies;
    } else {
      return false;
    }
  }

  onPhoneNumberChange(fieldInfo) {
    if (fieldInfo.id != '') {
      this.cloneList.forEach(item => {
        if (fieldInfo.id == item.id) {
          this.setPhnBtnDisable( fieldInfo.number.length == 12 ? fieldInfo.number == item.number && fieldInfo.type == item.type && (this.otherRoleSucess || this.authsucess) ? true : false : true);
        }
      })
    } else {
      this.setPhnBtnDisable( fieldInfo.number.length == 12 && fieldInfo.type != '' && (this.otherRoleSucess || this.authsucess) ? false : true);
    }
    OcInfraModule.AppInjector.get(CommonService).rolePhoneChanged =true;
  }

  onEmailChange(fieldInfo) {
    let emailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (fieldInfo.id != '') {
      this.cloneEmailList.forEach(item => {
        if (fieldInfo.id == item.id) {
          this.disableEmailSaveBtn = fieldInfo.number.match(emailFormat)  ? fieldInfo.number.toUpperCase() == item.number && fieldInfo.type == item.type && (this.otherRoleSucess || this.authsucess) ? true : false : true;
          //this.setEmailBtnDisable( fieldInfo.number.match(emailFormat)  ? fieldInfo.number.toUpperCase() == item.number && fieldInfo.type == item.type && (this.otherRoleSucess || this.authsucess) ? true : false : true);
        }
      })
    }
    else {
      this.disableEmailSaveBtn = fieldInfo.number.match(emailFormat) && fieldInfo.type != '' && (this.otherRoleSucess || this.authsucess) ? false : true;
      //this.setEmailBtnDisable( fieldInfo.number.match(emailFormat) && fieldInfo.type != '' && (this.otherRoleSucess || this.authsucess) ? false : true);
    }
    OcInfraModule.AppInjector.get(CommonService).roleEmailChanged =true;
  }

  refreshInstances() {
  setTimeout(() => {
        let header = this.additionalHeaders;
        header[0].headers['lob'] = this.policyDetails && this.policyDetails.data && this.policyDetails.data.line_of_business_code;
        OcInfraModule.AppInjector.get(APICallerService).changeHeaders(header[0].headers);
        OcInfraModule.AppInjector.get(APICallerService).refresh(this.workRequestHref, { 'alias': "workRequestHistory", 'headers': header[0].headers}).subscribe(refreshResponse => {
          OcInfraModule.AppInjector.get(CommonService).wrkReqHistoryRes = refreshResponse;
          OcInfraModule.AppInjector.get(CommonService).sendFilterEvent();
        });
      }, 5000);
    }
}