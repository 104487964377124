import { Component, OnInit, ElementRef } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { OcInfraModule, APICallerService, OptionParams, ResourceService } from '@diaas/ux-web';
import { AdvGrowlService } from 'primeng-advanced-growl';
import * as _ from 'lodash';

@Component({
  selector: 'app-sponsor-general-loan-rules-tab',
  templateUrl: './sponsor-general-loan-rules-tab.component.html',
  styleUrls: ['./sponsor-general-loan-rules-tab.component.scss']
})
export class SponsorGeneralLoanRulesTabComponent implements OnInit {
  loanRulesList: any;
  noRecords: boolean = true;
  statusMsg: string = "";
  message: boolean = false;
  checkedData: Object = {};
  generalLoanObj: any = {};
  generalLoanRulesList: any[] = [];
  currentChecked: {};
  checkedProperties: any = {};
  savedProperties: string;
  html = '';
  notesObj: any = {};
  selectdValues: any;

  constructor() { }

  ngOnInit() {
    let policySponsor = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('Hcontractsdetails');
    this.loanRulesList = policySponsor.data.hClientLoanRulesInfoList;
    this.noRecords = this.loanRulesList && !this.loanRulesList.length;
    if(this.loanRulesList) {
        this.loanRulesList.forEach((obj, index) => {
            obj.id = index + 1;
          });
        }
    if (policySponsor.response._embedded && policySponsor.response._embedded.status_report.messages) {
      policySponsor.response._embedded.status_report.messages.forEach(msgObj => {
        this.statusMsg += msgObj.message;
        this.message = true;
      });
    }
  }

  testChange(event, data, selectedValue, selectedKey, columnName, valueType, flag) {
      let selectedVal: any;
      if (this.checkedData[data.id] && columnName !== 'name') {
        selectedVal = (selectedValue === '' || selectedValue === undefined)  ? 'No data in admin system.' : selectedValue;
        event.checked ? this.checkedData[data.id].push(selectedKey + ':' + selectedVal) : this.checkedData[data.id].splice(this.checkedData[data.id].indexOf(selectedKey + ':' + selectedVal), 1)
      } else {
        if (!this.checkedData[data.id]) {
          selectedVal = (selectedValue === '' || selectedValue === undefined)  ? 'No data in admin system.' : selectedValue;
          this.checkedData[data.id] = new Array(selectedKey + ':' + selectedVal);
        }
      }
      if (this.checkedData[data.id].length == 0) {
        delete this.checkedData[data.id];
      }
      let html = '';
      if (this.checkedData[data.id]) {
        for (var i = 0; i < this.checkedData[data.id].length; i++) {
          var split = this.checkedData[data.id][i].split(':');
          this.notesObj[split[0].trim()] = split[1].trim();
        }
      }
      if (Object.keys(this.checkedData).length > 0) {
        Object.keys(this.checkedData).forEach(key => {
          this.checkedData[key].forEach(element => {
            html += '<dt class="pull-left">' + element.split(":")[0] + ':</dt><dd style = "font-weight: 200;"  >&nbsp;' + element.split(":")[1] + (element.split(":")[2] ? element.split(":")[2] : "") + (element.split(":")[3] ? element.split(":")[3] : "") + (element.split(":")[4] ? element.split(":")[4] : "") + '</dd>';
          });
        });
      }
      let selectedData = [];
      if (this.loanRulesList.length > 0) {
        this.loanRulesList.forEach(element => {
          if (this.checkedData.hasOwnProperty(element.id)) {
            let currentField = {};
            currentField[data.id] = this.checkedData[element.id];
            selectedData.push(currentField);
          }
        });
      }
      if (Object.keys(this.checkedData).length) {
        OcInfraModule.AppInjector.get(CommonService).generalLoanHtml = '<span style="font-size:15px" class="pad-lAr-10px">SPONSOR-GENERAL LOAN RULES</span><dl class="pad-lAr-10px">' + html + '</dl>'
      }
      else {
        OcInfraModule.AppInjector.get(CommonService).generalLoanHtml = '';
      }
      OcInfraModule.AppInjector.get(CommonService).showRequestedDetails();
      this.covrageChecked(event, data, selectedValue, selectedKey, columnName, valueType, flag);
      this.selectdValues = OcInfraModule.AppInjector.get(CommonService).checkedloanRulesDetails;
      let keys = Object.keys(this.selectdValues[0]["loan_rules"]);
      if (keys.length > 0) {
        this.selectdValues = OcInfraModule.AppInjector.get(CommonService).checkedloanRulesDetails;
        for (let i = 0; i < this.selectdValues.length; i++) {
          localStorage.setItem("loan_rules", JSON.stringify(Object.assign(this.checkedProperties, this.selectdValues[0].loan_rules)));
        }
      }
      else {
        OcInfraModule.AppInjector.get(CommonService).generalLoanHtml = '';
        OcInfraModule.AppInjector.get(CommonService).showRequestedDetails();
      }
  
    }
  
    covrageChecked(event, data, selectedValue, selectedKey, columnName, valueType, flag) {
        let selctedCoverage = [];
        selctedCoverage = OcInfraModule.AppInjector.get(CommonService).checkedloanRulesDetails;
        if (event.checked) {
          let coverageChecked = []
          this.currentChecked = {}
          selectedValue = (selectedValue === '' || selectedValue === undefined)  ? 'No data in admin system.' : selectedValue;
          if (selectedValue) {
            this.currentChecked[selectedKey] = selectedValue;
            if (selctedCoverage.length > 0) {
              if (selctedCoverage[0].hasOwnProperty(["loan_rules"])) {
                for (let i = 0; i < selctedCoverage.length; i++) {
                  let subKeys = Object.keys(selctedCoverage[0]["loan_rules"]);
                  let existed: boolean = true;
                  if (subKeys.length > 0) {
                    for (let j = 0; j < subKeys.length; j++) {
                      if (subKeys[j] == data["loan_int_rate_opt"] + data["id"]) {
                        Object.assign(selctedCoverage[0]["loan_rules"][data["loan_int_rate_opt"] + data["id"]], this.currentChecked);
                        OcInfraModule.AppInjector.get(CommonService).checkedloanRulesDetails = selctedCoverage;
                        existed = false;
                      } else if (j + 1 >= subKeys.length && existed == true) {
                        // this.checkedProperties["selectedNote"] = []
                        this.checkedProperties["loan_rules"][data["loan_int_rate_opt"] + data["id"]] = this.currentChecked;
                        Object.assign(selctedCoverage[0]["loan_rules"], this.checkedProperties["loan_rules"]);
                        OcInfraModule.AppInjector.get(CommonService).checkedloanRulesDetails = selctedCoverage;
                      }
                    }
                  } else {
                    this.checkedProperties["loan_rules"] = []
                    this.checkedProperties["loan_rules"][data["loan_int_rate_opt"] + data["id"]] = this.currentChecked;
                    Object.assign(selctedCoverage[0]["loan_rules"], this.checkedProperties["loan_rules"]);
                    OcInfraModule.AppInjector.get(CommonService).checkedloanRulesDetails = selctedCoverage;
                  }
                }
              } else {
                this.checkedProperties["loan_rules"] = []
                this.checkedProperties["loan_rules"][data["loan_int_rate_opt"] + data["id"]] = this.currentChecked;
                Object.assign(selctedCoverage[0], this.checkedProperties)
                OcInfraModule.AppInjector.get(CommonService).checkedloanRulesDetails = selctedCoverage;
              }
            } else {
              this.checkedProperties["loan_rules"] = []
              this.checkedProperties["loan_rules"][data["loan_int_rate_opt"] + data["id"]] = this.currentChecked;
              selctedCoverage.push(this.checkedProperties);
              OcInfraModule.AppInjector.get(CommonService).checkedloanRulesDetails = selctedCoverage;
            }
          }
        }
        else {
          let unCheckedId = selectedKey;
          delete selctedCoverage[0]["loan_rules"][data["loan_int_rate_opt"] + data["id"]][unCheckedId]
          let subKeys = Object.keys(selctedCoverage[0]["loan_rules"]);
          let checked = [];
          for (let i = 0; i < subKeys.length; i++) {
            if (_.isEmpty(selctedCoverage[0]["loan_rules"][subKeys[i]])) {
              delete selctedCoverage[0]["loan_rules"][data["loan_int_rate_opt"] + data["id"]]
              checked.push(false);
              delete this.checkedProperties[data["loan_int_rate_opt"] + data["id"]];
            if(subKeys.length == 1) {
              this.checkedProperties = {};
            }
            } else {
              checked.push(true)
            }
          }
          localStorage.setItem("loan_rules", JSON.stringify(this.checkedProperties));
          OcInfraModule.AppInjector.get(CommonService).checkedloanRulesDetails = selctedCoverage;
        }
      }

}
