﻿import { OcInfraConfig } from '@diaas/ux-web';
import { FactoryLoader } from '@diaas/ux-web';
import { CustomFactoryLoader } from './../app/factories/custom-factory.loader';
import { hostURL } from "./host-url";

export function CustomFactoryLoaderFunction() {
  return new CustomFactoryLoader();
}
export function GetCognitoDetails() {
  return {
    userPoolId: 'us-east-1_N4WToZs7L',
    clientId: '6vlntsoq5ut68s98o0k5qtipf9'
  }
}
export const ocInfraConfig: OcInfraConfig = {

  hostURL: hostURL,
  debug: true,
  metamodelBaseURL: window.location.origin + '/assets/metamodel/',
  modifiedHeaderTag: 'X-GraphTalk-Modified',
  deletedHeaderTag: 'X-Delete',
  apiDateFormat: 'yyyy-mm-dd',
  dateFormat: 'mm/dd/yyyy',
  apiDateSeparator: '-',
  custom: {
    provide: FactoryLoader,
    useFactory: CustomFactoryLoaderFunction
  },
  headers: {
    defaultHeader: {
      'content-type': 'application/json',
      'accept': 'application/hal+json',
      "accept-language": 'en'
    },
    multiHostUrlHeader: [
      {
        'alias': 'selectedNote',
        'headers': {
          'user_role': '',
          "caller_role": '',
          'accept': 'application/hal+json',
          'Authorization': ""
        }
      },
     {
        'alias': 'authRes',
        'headers': {
          'incontact_user': '',
          'accept': 'application/hal+json',
          'Authorization': ""
        }
      },
      {
        'alias': 'reqDetails',
        'headers': {
          'accept': 'application/hal+json',
          'statuaory_company_code': '106',
          'region_code': 'BHF1',
          'remote_user': '',
          'Authorization': ""
        }
      },
      {
        'alias': 'authorize',
        'headers': {
          'incontact_user': '',
          'region_code': null,
          'role_name': null,
          'product_type': null,
          'manreview': null,
          'user_id': null,
          'remote_user': null,
          'statuaory_company_code': null,
          'accept': 'application/hal+json',
          'Authorization': ""
        }
      },
      {
        'alias': 'closeCall',
        'headers': {
          'remote_user': '',
          'incontact_user': '',
          'region_code': null,
          'role_name': null,
          'product_type': null,
          'manreview': null,
          'statuaory_company_code': null,
          'accept': 'application/hal+json',
          'Authorization': ""
        }
      },
      {
        'alias': 'roles',
        'headers': {
          'user_role': '',
          "caller_role": '',
          'accept': 'application/hal+json',
          'Authorization': ""
        }
      },
      {
        'alias': 'selectedRoles',
        'headers': {
          'user_role': '',
          "caller_role": '',
          'accept': 'application/hal+json',
          'Authorization': ""
        }
      }, 
      { 
        'alias': 'selectedGroup',
       'headers': {
       'user_role': '',
       "caller_role": '',
       'accept': 'application/hal+json',
       'Authorization': ""
        }
      },
      { 
         'alias': 'selectedGroup',
        'headers': {
        'user_role': '',
        "caller_role": '',
        'accept': 'application/hal+json',
        'Authorization': ""
         }
       },
      {
        'alias': 'callHistory',
        'headers': {
          'accept': 'application/hal+json',
          'skill_id': '',
          'Authorization': ""
        }
      },
      {
        'alias': 'types1',
        'headers': {
          'statuaory_company_code': '',
          'region_code': '',
          'role_name': 'owner',
          'remote_user': '',
          'product_type': "",
          'manreview': "false",
          'accept': 'application/hal+json',
          'Authorization': "",
          'company_code':""
        }
      },
      {
        'alias': 'types',
        'headers': {
          "role_name": '',
          "statuaory_company_code": '',
          "product_type": '',
          'region_code': '',
          'user_role': '',
          "manreview": "false",
          'accept': 'application/hal+json',
          'Authorization': "",
          "duplicate_policy_ind": "false"
        }
      },
      {
        'alias': 'workitemsSchema',
        'headers': {
          "role_name": '',
          "statuaory_company_code": '',
          'remote_user': '',
          'wma_user': '',
          "product_type": '',
          'region_code': '',
          "manreview": "false",
          'accept': 'application/hal+json',
          'Authorization': "",
          'company_code':""
        }
      },
      {
        'alias': 'createClaim',
        'headers': {
          'accept': 'application/hal+json',
          'statuaory_company_code': '',
          'region_code': '',
          'remote_user': '',
          'Authorization': "",
          'client_id': '',
          'wma_user': '',
        }
      },
      {
        'alias': 'claimList',
        'headers': {
          'accept': 'application/hal+json',
          'remote_user': '',
          'Authorization': "",
          'region_code': "PANO",
          'wma_user': '',
        }
      },
      {
        'alias': 'instances',
        'headers': {
          'accept': 'application/hal+json',
          'statuaory_company_code': '',
          'region_code': '',
          'remote_user': '',
          'Authorization': ""
        }
      },
      {
        'alias': 'workRequestHistory',
        'headers': {
          'accept': 'application/hal+json',
          'wma_user': '',
          'statuaory_company_code': '',
          'region_code': '',
          'role':'',
          'remote_user': '',
          'Authorization': "",
          'client_id':"",
          "product_type": ''
        }
      },
      {
        'alias': 'selectedWorkRequest',
        'headers': {
          'accept': 'application/hal+json',
          'statuaory_company_code': '',
          'region_code': '',
          'remote_user': '',
          'Authorization': "",
          'company_code': ''
        }
      },
      {
        'alias': 'selectedWorkRequestDetailsHistory',
        'headers': {
          'accept': 'application/hal+json',
          'statuaory_company_code': '',
          'region_code': '',
          'remote_user': '',
          'Authorization': ""
        }
      },
      {
        'alias': 'addComments',
        'headers': {
          'accept': 'application/hal+json',
          'remote_user': '',
          'statuaory_company_code': '',
          'region_code': '',
          'role': null,
          'product_type': null,
          'manreview': null,
          'role_name': null,
          'incontact_user': null,
          'Authorization': ""
        }
      },
      {
        'alias': 'selectedWorkRequestComments',
        'headers': {
          'accept': 'application/hal+json',
          'statuaory_company_code': '',
          'region_code': '',
          'remote_user': '',
          'Authorization': ""
        }
      },
      {
        'alias': 'selectedWorkRequestSource',
        'headers': {
          'accept': 'application/hal+json',
          'statuaory_company_code': '',
          'region_code': '',
          'remote_user': '',
          'Authorization': ""
        }
      },
      {
        'alias': 'payments',
        'headers': {
          'accept': 'application/hal+json',
          'statutory_company_code': '',
          'remote_user': '',
          'Authorization': ""
        }
      },
      {
        'alias': 'refreshPolicyDetails',
        'headers': {
          'accept': 'application/hal+json',
          'remote_user': '',
          'role': null,
          'region_code': null,
          'incontact_user': null,
          'Authorization': ""
        }
      },
      {
        'alias': 'userProfile',
        'headers': {
          'accept': 'application/hal+json',
          'Authorization': ""
        }
      },
      {
        'alias': 'person',
        'headers': {
          'accept': 'application/hal+json',
          'role': null,
          'remote_user': null,
          'region_code': null,
          'product_type': null,
          'manreview': null,
          'role_name': null,
          'incontact_user': null,
          'Authorization': ""
        }
      },
      {
        'alias': 'selectedCallHistory',
        'headers': {
          'accept': 'application/hal+json',
          'Authorization': ""
        }
      },
      {
        'alias': 'transactions',
        'headers': {
          'remote_user': '',
          'accept': 'application/hal+json',
          'content-type': 'application/json',
          'Authorization': ""
        }
      },
      {
        'alias': 'callHistory',
        'headers': {
          'accept': 'application/hal+json',
          'Authorization': ""
        }
      },
      {
        'alias': 'selectedRoles',
        'headers': {
          'accept': 'application/hal+json',
          'Authorization': ""
        }
      },
      {
        'alias': 'policyCallHistory',
        'headers': {
          'accept': 'application/hal+json',
          'Authorization': ""
        }
      },
      {
        'alias': 'selectedRoles',
        'headers': {
          'accept': 'application/hal+json',
          'statuaory_company_code': null,
          'Authorization': ""
        }
      },
      {
        'alias': 'search',
        'headers': {
          'accept': 'application/hal+json',
          'Authorization': ""
        }
      },
      {
        'alias': 'policyDetails',
        'headers': {
          'accept': 'application/hal+json',
          "wma_user": "",
          'Authorization': "",
          'client_id':"",
          'product_type':"", 
          "duplicate_policy_ind": "false"
        }
      },
      {
        'alias': 'Hcontracts',
        'headers': {
          'accept': 'application/hal+json',
          'Authorization': "",
        }
      },
      {
        'alias': 'billingInfo',
        'headers': {
          'accept': 'application/hal+json',
          "wma_user": "",
          'Authorization': "",
          'client_id':""
        }
      },
      {
        'alias': 'reinstatementquote',
        'headers': {
          'accept': 'application/hal+json',
          "wma_user": "",
          'Authorization': "",
          'client_id':""
        }
      },
      {
        'alias': 'policySuspense',
        'headers': {
          'accept': 'application/hal+json',
          "wma_user": "",
          'Authorization': "",
          'client_id':""
        }
      },
      {
        'alias': 'policySponsor',
        'headers': {
          'accept': 'application/hal+json',
          "wma_user": "",
          'Authorization': "",
          'client_id':""
        }
      },
      {
        'alias': 'policyRevolvingError',
        'headers': {
          'accept': 'application/hal+json',
          "wma_user": "",
          'Authorization': "",
          'client_id':""
        }
      },
      {
        'alias': 'policyNotes',
        'headers': {
          'accept': 'application/hal+json',
          "wma_user": "",
          'Authorization': "",
          'client_id':""
        }
      },
      {
        'alias': 'policyCoverage',
        'headers': {
          'accept': 'application/hal+json',
          "wma_user": "",
          'Authorization': "",
          'client_id':""
        }
      },
      {
        'alias': 'selectedCoverage',
        'headers': {
          'accept': 'application/hal+json',
          "wma_user": "",
          'Authorization': "",
          'client_id':""
        }
      },
      {
        'alias': 'policyFunds',
        'headers': {
          'accept': 'application/hal+json',
          "wma_user": "",
          'Authorization': "",
          'client_id':""
        }
      },
      {
        'alias': 'loanValues',
        'headers': {
          'accept': 'application/hal+json',
          "wma_user": "",
          'Authorization': "",
          'client_id':""
        }
      },
      {
        'alias': 'loanHistory',
        'headers': {
          'accept': 'application/hal+json',
          "wma_user": "",
          'Authorization': "",
          'client_id': ""
        }
      },
      {
        'alias': 'policyValues',
        'headers': {
          'accept': 'application/hal+json',
          "wma_user": "",
          'Authorization': "",
          'client_id':""
        }
      },
      {
        'alias': 'loanTransactionHistory',
        'headers': {
          'accept': 'application/hal+json',
          "wma_user": "",
          'Authorization': "",
          'client_id':""
        }
      },
      {
        'alias': 'annuityListing',
        'headers': {
          'accept': 'application/hal+json',
          "wma_user": "",
          'Authorization': "",
          'client_id':""
        }
      },
      {
        'alias': 'partyRoles',
        'headers': {
          'accept': 'application/hal+json',
          "wma_user": "",
          'Authorization': "",
          'client_id':""
        }
      },
      {
        'alias': 'historicalcoi',
        'headers': {
          'accept': 'application/hal+json',
          "wma_user": "",
          'Authorization': "",
          'client_id':""
        }
      },
      {
        'alias': 'paymenthistory',
        'headers': {
          'accept': 'application/hal+json',
          "wma_user": "",
          'Authorization': "",
          'client_id':""
        }
      },
      {
        'alias': 'incomingmoney',
        'headers': {
          'accept': 'application/hal+json',
          "wma_user": "",
          'Authorization': "",
          'client_id':""
        }
      },
      {
        'alias': 'programenrollment',
        'headers': {
          'accept': 'application/hal+json',
          "wma_user": "",
          'Authorization': "",
          'client_id':""
        }
      },
      {
        'alias': 'historyFunds',
        'headers': {
          'accept': 'application/hal+json',
          "wma_user": "",
          'Authorization': "",
          'client_id':""
        }
      },
      {
        'alias': 'outgoingmoney',
        'headers': {
          'accept': 'application/hal+json',
          "wma_user": "",
          'Authorization': "",
          'client_id':""
        }
      },
      { 
        'alias': 'checkhistory',
        'headers': {
          'accept': 'application/hal+json',
          "wma_user": "",
          'Authorization': "",
          'client_id':""
        }
      },
      {
        'alias': 'contract_management',
        'headers': {
          'accept': 'application/hal+json',
          "wma_user": "",
          'Authorization': "",
          'client_id':""
        }
      },
      {
        'alias': 'contract_management',
        'headers': {
          'accept': 'application/hal+json',
          "wma_user": "",
          'Authorization': "",
          'client_id':""
        }
      },
      {
        'alias': 'pendingTransactions',
        'headers': {
          'accept': 'application/hal+json',
          "wma_user": "",
          'Authorization': "",
          'client_id':"",
          'region_code': "",
          'statuaory_company_code': ""
        },
      },
      {
        'alias': 'policyAssetAccounts',
        'headers': {
          'accept': 'application/hal+json',
          "wma_user": "",
          'Authorization': "",
          'client_id':"",
          'region_code': "",
          'statuaory_company_code': ""
        },
      },
      {
        'alias': 'refreshtoken',
        'headers': {
          'Content-Type': 'application/x-amz-json-1.1',
          'X-AMZ-TARGET': 'AWSCognitoIdentityProviderService.InitiateAuth',
        }
      },
      {
        'alias': 'groupAssetValue',
        'headers': {
          'accept': 'application/hal+json',
          "wma_user": "",
          'Authorization': "",
          'client_id':"",
          'region_code': "",
          'statuaory_company_code': ""
        }      
      },
      {
        'alias': 'beneficiaryRoles',
        'headers': {
          'accept': 'application/hal+json',
          "wma_user": "",
          'Authorization': "",
          'client_id':"",
          'region_code': "",
          'statuaory_company_code': ""
        }
      },
      {
        'alias': 'loanInitiation',
        'headers': {
          'accept': 'application/hal+json',
          'statuaory_company_code': '',
          'region_code': '',
          'remote_user': '',
          'Authorization': "",
          'client_id': '',
          "wma_user": "",
          'user_role': '',
          'role_name': ''
        }
      },
      {
        'alias': 'loanConfirmation',
        'headers': {
          'accept': 'application/json',
          'role': 'Owner',
          'Authorization': "",
          'user_role': '',
          'remote_user': '',
          "wma_user": "",
          "product_type": '',
          'Content-Type': 'application/json'

        }
      },
      {
        'alias': 'planReporting',
        'headers': {
          'accept': 'application/json',
          'Authorization': "",
          'role': '',
          'user_role': '',
          'remote_user': '',
          'Content-Type': 'application/json'

        },
      },
      {
        'alias': 'groupLoanQuote',
        'headers': {
          'accept': 'application/json',
          'role': 'Owner',
          'Authorization': "",
          'user_role': '',
          'remote_user': '',
          'Content-Type': 'application/json',
          "wma_user": "",
          'client_id':""
        },
      },
      {
        'alias': 'financialHistory',
        'headers': {
          'accept': 'application/json',
          'user_role': '',
          'role_name': 'Owner',
          'wma_user': '',
          'Authorization': "",
          'Content-Type': 'application/json'
        },
      },
      {
        'alias': 'rmdDetails',
        'headers': {
          'Authorization': "",
          'Content-Type': 'application/json',
          'accept': 'application/json',
          'client_id': '',
          'wma_user': '',
          'role': '',
          'user_role': '',
          'product_type': '',
          'remote_user': ''
        },
      },
      {
        'alias': 'partialSurrender',
        'headers': {
          'Authorization': "",
          'accept': 'application/hal+json',
          'wma_user': '',
          'role':'',
          'role_name': '',
          'client_id': '',
          'asset_account': '',
          'plan_id': ''
        },
      },
      {
        'alias': 'mfaVerification',
        'headers': {
          'Authorization': "",
          'accept': 'application/hal+json',
          'wma_user': '',
          'role':'',
          'user_role': '',
          "caller_role": '',
          'company_code':"",
          'Content-Type': 'application/json'
        },
      },
      {
        'alias': 'mfaVerificationvalidate',
        'headers': {
          'Authorization': "",
          'accept': 'application/hal+json',
          'wma_user': '',
          'role':'',
          'user_role': '',
          "caller_role": '',
          'Content-Type': 'application/json'
        },
      },
      {
        'alias': 'mfaOverride',
        'headers': {
          'Authorization': "",
          'accept': 'application/hal+json',
          'wma_user': '',
          'role':'',
          'user_role': '',
          "caller_role": '',
          'Content-Type': 'application/json',
          'product_type' :''
        },
      }
    ]
  },
  typeAheadMinChar: 2,
  printErrors: false,
  defaultLanguage: 'en',
  recaptchaSiteKey: '',
  loginPage: '/',
  logoutPage: '/logout',
  landingPage: 'home',
  dateOptions: {
    dateFormat: 'dd/mm/yyyy',
  }
};
