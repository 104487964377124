
export interface TableData {
    eff_date: string;
    processed_date: string;
    payment_type: string;
    status: string;
    amount: string;
}


export const initDisplayedFields = [
    'eff_date',
    'processed_date',
    'payment_type',
    'status',
    'amount'
]


export const initAnnDisplayedFields = [
    'eff_date',
    'payment_type',
    'status',
    'amount'   
]


