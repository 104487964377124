import { Component, ElementRef, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { ResourceService, OcInfraModule, APICallerService, Configuration, OptionParams } from '@diaas/ux-web';
import { CommonService } from '../../services/common.service';
import { AdvGrowlService } from 'primeng-advanced-growl';
import { DatePipe } from '@angular/common';
import { AppDateAdapter, APP_DATE_FORMATS } from '../format-datepicker';
import {DateAdapter, MAT_DATE_FORMATS} from '@angular/material/core';

@Component({
    selector: 'app-grpDropdown',
    templateUrl: './groupPolicyDropdown.component.html',
    styleUrls: ['./groupPolicyDropdown.component.scss'],
    providers: [
        { provide: DateAdapter, useClass: AppDateAdapter },
        { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
    ]
})
export class GroupPolicyDropdownComponent implements OnInit {
    elementData: any;
    assetAccountTypes: any[];
    assetAccount: any;
    accountType: any;
    accTypeItems: any[];
    productTypes: any[];
    productType: any;
    resourceService: any;
    policyDetails: any;
    getPlanCodeUrl: any;
    getAssetAccountUrl: any;
    effectiveMaxDate = new Date();
    effectiveDate = new Date();
    headers = { ...{wma_user: window.sessionStorage.getItem('username'), client_id: OcInfraModule.AppInjector.get(CommonService).productInfo.client}, ...OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("search") };
    planCode: any;
    valuesAsset: boolean = false;
    planCodeGroup: boolean = false;
    planCodeStatusUrl: any;
    planCodeStatus: any[];
    planCodeItems: any;
    loan_status: any;
    selectedPlanCode: any;
    isFundsTab: boolean = false;
    loansinfoRes: any;
    html: string;
    selectionChange: boolean = false;
    selectedPlanCodeStatus: any;
    loan_status_desc: any;
    isOnlyRetailAnn: boolean = false;
    loan_initiate_date: any;
    loan_amount: any;

    constructor(public element: ElementRef, public commonService: CommonService, public datePipe: DatePipe) {
        this.elementData = this.element.nativeElement
    }

    ngOnInit() {
        this.planCodeGroup = this.elementData.id == 'planCode' ? true : false;
        this.resourceService = OcInfraModule.AppInjector.get(ResourceService);
        this.policyDetails = this.resourceService.getResourceFromAliasName('policyDetails') ? this.resourceService.getResourceFromAliasName('policyDetails').response : null;
        let product_type = this.policyDetails && this.policyDetails.product_type;
        this.isOnlyRetailAnn = (product_type == "A1" || product_type == "A2") ? true: false;               
        this.planCodeStatusUrl = this.policyDetails && this.policyDetails._links && this.policyDetails._links.clientloanstatus ? this.policyDetails._links.clientloanstatus.href : null;
        OcInfraModule.AppInjector.get(CommonService).getCallback(this.planCodeStatusUrl, this.headers).subscribe(response => {
            this.planCodeItems = response._links.item;
            if (this.planCodeItems) {
                this.planCodeStatus = [];
                OcInfraModule.AppInjector.get(CommonService).canPerformGroupLoanQuote = this.planCodeItems.length >0;
                this.effectiveMaxDate = new Date(this.planCodeItems[0].summary.loan_effective_date);
                for (let i = 0; i < this.planCodeItems.length; i++) {
                    this.planCodeStatus.push({ value: this.planCodeItems[i].summary.loan_status_desc, viewValue: this.planCodeItems[i].summary.loan_status_desc + '-' + this.planCodeItems[i].summary.original_loan_amt});
                }
            }
        }, error => {
            if (error && error._embedded && error._embedded.status_report && error._embedded.status_report.consistent === false) {
                OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error._embedded.status_report.messages[0].message, 'Error', 0);
            }
        });
    }

    onPlanCodeChange(e) {
        let drpdwnObj;
        this.selectionChange = true;
        if (this.planCodeItems) {
            for (let i = 0; i < this.planCodeItems.length; i++) {
                let initialLoanAmount = this.planCodeItems[i].summary.loan_status_desc + '-' + this.planCodeItems[i].summary.original_loan_amt;
                if (initialLoanAmount === e.value) {
                    this.effectiveMaxDate = new Date(this.planCodeItems[i].summary.loan_effective_date);
                    this.loan_status = this.planCodeItems[i].summary.loan_status;
                    this.selectedPlanCode = this.planCodeItems[i].summary.plan_code;
                    this.selectedPlanCodeStatus = this.planCodeItems[i].summary.plan_code_status;
                    var loan_status_description = this.planCodeItems[i].summary.loan_status_desc.split('-');
                    this.loan_status_desc = loan_status_description[loan_status_description.length - 1];
                    this.loan_initiate_date = this.planCodeItems[i].summary.loan_initiate_date;
                    this.loan_amount = this.planCodeItems[i].summary.original_loan_amt;
                }
            }
            drpdwnObj = { 'loanStatus': this.loan_status_desc, 'planCode': this.selectedPlanCodeStatus, 'loanInitiateDate': this.loan_initiate_date, 'loanAmount': this.loan_amount};
            if (this.planCodeGroup) {
                Object.assign(OcInfraModule.AppInjector.get(CommonService).selectedGroupDropdownValues, { 'LOANS': drpdwnObj });
            }
        }
    }

    getLoansInfo() {
        this.selectionChange = false;
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let obj = OcInfraModule.AppInjector.get(CommonService).selectedGroupDropdownValues;
        let headers = { ...{ wma_user: window.sessionStorage.getItem('username'), client_id: OcInfraModule.AppInjector.get(CommonService).productInfo.client, inputloanDate: obj.LOANS.loanInitiateDate, loanAmount: obj.LOANS.loanAmount}, ...OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("search") };
        let policyDetails = resourceService.getResourceFromAliasName('policyDetails');
        let loanInfoUrl = policyDetails && policyDetails._links && policyDetails._links["loans/info"] ? policyDetails._links["loans/info"].href : null;
       // let loanHistoryUrl = policyDetails && policyDetails._links && policyDetails._links["loans/loanhistoryAnnuites"] ? policyDetails._links["loans/loanhistoryAnnuites"].href : null;
        Object.assign(headers, { planCode: this.selectedPlanCode ? this.selectedPlanCode : "", loanStatus: this.loan_status ? this.loan_status : "" });
        let optionParams = new OptionParams();
        optionParams.headers = OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("loanHistory");
        optionParams.alias = "loanTransactionHistory";
        Object.assign(optionParams.headers, { client_id: OcInfraModule.AppInjector.get(CommonService).productInfo.client, planCode: this.selectedPlanCode ? this.selectedPlanCode : "", loanStatus: this.loan_status ? this.loan_status : "" });
        OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false });
        OcInfraModule.AppInjector.get(CommonService).getCallback(loanInfoUrl, headers).subscribe(response => {
            this.loansinfoRes = response;
            Array.from(document.querySelectorAll('.loanschckbx input[type="checkbox"]:checked')).forEach(loansCheckBox => {
                const checkBox = loansCheckBox as HTMLInputElement;
                checkBox.click();
            });
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
        }, error => {
            if (error && error._embedded && error._embedded.status_report && error._embedded.status_report.consistent === false) {
                OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error._embedded.status_report.messages[0].message, 'Error', 0);
            }
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
        });
        this.commonService.sendClickEvent();
       // OcInfraModule.AppInjector.get(APICallerService).refresh(loanHistoryUrl, { 'alias': "loanHistory", 'headers': headers }).subscribe(refreshResponse => { });
    }

    testChange(e, selectedValue, selectedKey, columnName, valueType) {
        let checkedLoansObj = OcInfraModule.AppInjector.get(CommonService).checkedLoansObject;
        selectedValue = selectedValue === undefined ? "No data in admin system." : selectedValue;
        selectedValue = valueType == 'currency' ? '$' + selectedValue.replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,') : selectedValue;
        selectedValue = valueType == 'percentage' ? selectedValue + '%' : selectedValue;
        let groupLoansHtml = '', plancodeHtml = '', msgFields = ['Message'];
        let grpLoansObj = OcInfraModule.AppInjector.get(CommonService).selectedGroupDropdownValues;
        grpLoansObj['LOANS']['effectiveDate'] = this.datePipe.transform(this.effectiveMaxDate, "MM/dd/yyyy");;
        OcInfraModule.AppInjector.get(CommonService).selectedGroupDropdownValues = grpLoansObj;
        let selectedDrpdwnObj = OcInfraModule.AppInjector.get(CommonService).selectedGroupDropdownValues;
        let selectedPlanCode: any, loanStatus: any, effectiveDate: any, loanAmount: any;
        if (selectedDrpdwnObj['LOANS']) {
            selectedPlanCode = selectedDrpdwnObj['LOANS']['planCode'];
            loanStatus = selectedDrpdwnObj['LOANS']['loanStatus'];
            effectiveDate = selectedDrpdwnObj['LOANS']['effectiveDate'];
            loanAmount = selectedDrpdwnObj['LOANS']['loanAmount']
        }
        loanStatus = loanStatus == ''?'No data in admin system.' : loanStatus;
        let keyString = selectedPlanCode + '_' + loanStatus + '_' + effectiveDate + '_' + loanAmount;
        if (e.checked) {
            let quoteLoansObj = {};
            if (this.loansinfoRes && (this.loansinfoRes[columnName] || this.loansinfoRes[columnName] == 0) && this.loansinfoRes[columnName] !== "") {
                if (!msgFields.includes(selectedKey)) {
                    quoteLoansObj[selectedKey] = selectedValue;
                }
                else {
                    quoteLoansObj[selectedKey] = 'No data in admin system.';
                }
            }
            else {
                quoteLoansObj[selectedKey] = 'No data in admin system.';
            }
            if (_.isEmpty(checkedLoansObj)) {
                checkedLoansObj['LOANS'] = {};
                checkedLoansObj['LOANS'][keyString] = quoteLoansObj;
            }
            else {
                if (checkedLoansObj['LOANS'].hasOwnProperty(keyString)) {
                    checkedLoansObj['LOANS'][keyString] = { ...checkedLoansObj['LOANS'][keyString], ...quoteLoansObj };
                } else {
                    let newPlanCodeObj = {};
                    newPlanCodeObj[keyString] = quoteLoansObj;
                    checkedLoansObj['LOANS'] = { ...checkedLoansObj['LOANS'], ...newPlanCodeObj };
                }
            }
        }
        else {
            if (Object.keys(checkedLoansObj['LOANS']).length) {
                if (checkedLoansObj['LOANS'][keyString] && Object.keys(checkedLoansObj['LOANS'][keyString]).length > 1) {
                    delete checkedLoansObj['LOANS'][keyString][selectedKey];
                } else {
                    delete checkedLoansObj['LOANS'][keyString];
                    (Object.keys(checkedLoansObj['LOANS']).length) ? null : delete checkedLoansObj['LOANS'];
                }
            }
        }
        OcInfraModule.AppInjector.get(CommonService).checkedLoansObject = checkedLoansObj;
        if (checkedLoansObj['LOANS'] && Object.keys(checkedLoansObj).length) {
            let subKeysList = [];
            Object.keys(checkedLoansObj['LOANS']).forEach(key => {
                plancodeHtml += '<dt class="pull-left"> Plan Code:</dt><dd>&nbsp;' + key.split('_')[0] + '</dd>' + '<dt class="pull-left"> Loan Status:</dt><dd>&nbsp;' + key.split('_')[1] + '</dd>' + '<dt class="pull-left"> Effective Date:</dt><dd>&nbsp;' + key.split('_')[2] + '</dd>';
                Object.keys(checkedLoansObj['LOANS'][key]).forEach(subKey => {
                    if (subKeysList.includes(key)) {
                        groupLoansHtml += '<dl class="pad-lAr-10px mb-0"><dt class="pull-left">' + subKey + ':</dt><dd>&nbsp;' + checkedLoansObj['LOANS'][key][subKey] + '</dd></dl>';
                    } else {
                        groupLoansHtml = '';
                        groupLoansHtml += '<dl class="pad-lAr-10px mb-0"><dt class="pull-left">' + subKey + ':</dt><dd>&nbsp;' + checkedLoansObj['LOANS'][key][subKey] + '</dd></dl>';
                        subKeysList.push(key);
                    }
                })
                plancodeHtml += groupLoansHtml;
            });
        }
        this.html = plancodeHtml;
        let finalHtml = '';
        Object.keys(checkedLoansObj).length ? finalHtml = ' <span style="font-size:15px" class="pad-lAr-10px">POLICY LOANS</span><dl class="pad-lAr-10px">' + this.html + '</dl>' : finalHtml = '';
        OcInfraModule.AppInjector.get(CommonService).groupLoansHtml = finalHtml;
        OcInfraModule.AppInjector.get(CommonService).showRequestedDetails();
        localStorage.setItem("loanInfo", JSON.stringify(checkedLoansObj['LOANS']));

    }

    
    // isOnlyGroupAnn() {
    //     if (this.policyDetails) {
    //         let details = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyDetails');
    //         let product_type = details.data.product_type;
    //         if (product_type == "A3" || product_type == "A4") {
    //             return true;
    //         }
    //         else {
    //             return false;
    //         }
    //     } else return false;
    // }

}