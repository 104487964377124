import { Component, OnInit, ViewChild } from '@angular/core';
import { OcInfraModule, APICallerService, Configuration, ResourceService  } from '@diaas/ux-web';
import { CommonService } from '../../services/common.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, MatSort } from '@angular/material';
import * as _ from 'lodash';

export interface DeathClaim {
  id: number;
  claimNumber: string;
  deceasedFirstName: string;
  deceasedInsuredType: string;
}

@Component({
  selector: 'app-death-claim',
  templateUrl: './death-claim.component.html',
  styleUrls: ['./death-claim.component.scss']
})
export class DeathClaimComponent implements OnInit {
  displayedColumns: string[] = ['id', 'claimNumber', 'deceasedFirstName','deceasedInsuredType'];
  dataSource = new MatTableDataSource<DeathClaim>([]);
  

  constructor() { }

  ngOnInit() {
    this.dataSource.paginator=this.paginator;
    this.getPolicyClaims();
  }

  @ViewChild(MatPaginator) public paginator: MatPaginator;

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.dataSource.paginator = mp;
    
  }

  // Get Policy Claims
  getPolicyClaims() {
    var tempArray =[];
    let header: any = _.filter(Configuration.config.headers["multiHostUrlHeader"], function (o) { return o.alias == 'createClaim' });
    const resourceService = OcInfraModule.AppInjector.get(ResourceService);
    let claimList = resourceService.getResourceFromAliasName('claimList');
    OcInfraModule.AppInjector.get(APICallerService).changeHeaders(header[0].headers);
    // Iterate through all the object to get claim list
    for (let i = 0; i < (claimList._links.item).length; i++) {
      let customHeader = header[0].headers ;
      delete customHeader.statuaory_company_code;
      OcInfraModule.AppInjector.get(CommonService).getCallback(claimList._links.item[i].href, customHeader).subscribe(response => {
        response.claimNumber = claimList._links.item[i].summary.claimNumber;
        tempArray.push(response);
        this.dataSource.data = tempArray;
      });
    }
  
  }
 
  onCloseClick(){
    document.getElementById("modal__cross").click();
    
  }
  onCountinueClick(){
    OcInfraModule.AppInjector.get(CommonService).createClaim = false;
      OcInfraModule.AppInjector.get(CommonService).claimAlert = true;
      let header = _.filter(Configuration.config.headers["multiHostUrlHeader"], function (o) { return o.alias == 'createClaim' });
      const resourceService = OcInfraModule.AppInjector.get(ResourceService);
      let policyDetails = resourceService.getResourceFromAliasName('policyDetails');
      OcInfraModule.AppInjector.get(APICallerService).changeHeaders(header[0].headers);
      let customHeader = header[0].headers ;
      delete customHeader.statuaory_company_code;
      OcInfraModule.AppInjector.get(CommonService).optionCallback(policyDetails._links.claims.href, customHeader).subscribe(response => {
        OcInfraModule.AppInjector.get(CommonService).deathClaimProperties = response._options.properties;
        OcInfraModule.AppInjector.get(CommonService).deathClaimRequiredProperties = response._options.required;
        OcInfraModule.AppInjector.get(CommonService).claimsTableRes = response;
        OcInfraModule.AppInjector.get(CommonService).sendClaimsFilterEvent();
        document.getElementById("modal__cross").click();
        setTimeout(()=>{
          document.getElementById("create-claim-create-claim-item-anchor").click();      
        },500);      
      });
  }

}
