import { TableColumn } from "../custom-table/custom-table.constant";

export interface IncomingMoneyData {
    effective_date: string;
    transaction_type_description: string;
    status: string;
    amount: string;
}

export const incomingMoneycolumnsDef = (currencyPipe): TableColumn[] => {

    return [
        {
            columnDef: 'effective_date',
            header: 'Effective Date',
            cell: (element: any) => `${element.effective_date}`,
            show: true,
            isSortable: true
        },
        {
            columnDef: 'transaction_type_description',
            header: 'Payment Type',
            cell: (element: any) => `${element.transaction_type_description}`,
            show: true,
        },
        {
            columnDef: 'status',
            header: 'Status',
            cell: (element: any) => `${element.status}`,
            show: true
        },
        {
            columnDef: 'amount',
            header: 'Amount',
            cell: (element: any) => `${currencyPipe.transform(element.amount)}`,
            show: true,
            
        }
    ]
}


