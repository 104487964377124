import { Component, OnInit, EventEmitter, Output, ElementRef } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { PartySearchFactory } from '../../factories/partySearchFactory';
import { CommonService } from '../../services/common.service';
import { ResourceService, OcInfraModule, APICallerService, OptionParams } from '@diaas/ux-web';
import { AdvGrowlService } from 'primeng-advanced-growl';

@Component({
  selector: 'fund-table',
  templateUrl: './fundTable.component.html',
  styleUrls: ['./fundTable.component.scss']
})

export class FundTableComponent implements OnInit {

  displayedColumns: string[] = ['name', 'fund_type_desc', 'allocation_percent', 'interest_rate', 'fund_value'];
  selectedDate: Object = {};
  fundList: any[] = [];
  noRecords: boolean = true;
  statusMsg: string = "";
  message: boolean = false;
  isAnnuity: boolean = false;
  fundsObj: any = {};
  olsPolicyfund: boolean = false;
  csrPolicyfund: boolean = false;
  isOlsAnnuity: boolean = false;
  isGroupAnnuity: boolean = false;
  groupAnnuityFund: boolean;
  policyDetails: any;
  resourceService: any;
  getPlanCodeUrl: any;
  getAssetAccountUrl: any;
  headers = { ...{ wma_user: window.sessionStorage.getItem('username'), client_id: OcInfraModule.AppInjector.get(CommonService).productInfo.client }, ...OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("search") };
  productTypes: any[];
  accTypeItems: any;
  productType: any;
  accountType: any;
  assetAccountTypes: any[];
  fundsData: boolean;
  noFundsData: boolean = false;
  isAnnuityOrUL: boolean = false;
  isUL: boolean = false;
  constructor(public elementRef: ElementRef) {

  }

  ngOnInit() {
    setTimeout(() => {
      let selectedPolicyInfo = OcInfraModule.AppInjector.get(CommonService).productInfo;
      if (selectedPolicyInfo && (selectedPolicyInfo.product_type == 'A1' || selectedPolicyInfo.product_type == 'A2')) {
        this.isAnnuity = true;
      }
      if (selectedPolicyInfo && (selectedPolicyInfo.product_type == 'A1' || selectedPolicyInfo.product_type == 'A2' || selectedPolicyInfo.product_type.includes('U'))) {
        this.isAnnuityOrUL = true;
      }
      if (selectedPolicyInfo.product_type.includes('U')) {
        this.isUL = true;
      }
      if (selectedPolicyInfo && (selectedPolicyInfo.product_type == 'A3' || selectedPolicyInfo.product_type == 'A4')) {
        this.isGroupAnnuity = true;
      }
      this.isOlsAnnuity = OcInfraModule.AppInjector.get(CommonService).isOlsAnnuity;
      var loanHistoryURL = OcInfraModule.AppInjector.get(CommonService).getAllUrlOfPolicyDetails();
      if (!this.isGroupAnnuity && loanHistoryURL["funds"] && loanHistoryURL["funds"].href) {
        let optionParams = new OptionParams();
        optionParams.headers = OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("partyRoles");
        OcInfraModule.AppInjector.get(APICallerService).get(loanHistoryURL["funds"].href, optionParams).subscribe(response => {
          this.fundList = response.response._links.item;
          this.fundList.splice(0, 0, JSON.parse(JSON.stringify(response.response._links.item[0])));
          let fundIndex = 0;
          if (this.fundList.length > 0) {
            response.response._links.item.forEach(element => {
              element.summary.nameFlag = false;
              element.summary.id = fundIndex;
              fundIndex++;
            });
          }
          this.noRecords = this.fundList && !this.fundList.length;
          if (response.response._embedded && response.response._embedded.status_report.messages) {
            response.response._embedded.status_report.messages.forEach(msgObj => {
              this.statusMsg += msgObj.message;
              this.message = true;
            });
          }
        });
      }
      if (this.isGroupAnnuity) {
        this.resourceService = OcInfraModule.AppInjector.get(ResourceService);
        this.policyDetails = this.resourceService.getResourceFromAliasName('policyDetails') ? this.resourceService.getResourceFromAliasName('policyDetails').response : null;
        this.getPlanCodeUrl = this.policyDetails && this.policyDetails._links && this.policyDetails._links.clientproducts ? this.policyDetails._links.clientproducts.href : null;
        this.getAssetAccountUrl = this.policyDetails && this.policyDetails._links && this.policyDetails._links.clientassetaccounts ? this.policyDetails._links.clientassetaccounts.href : null;
        
        if(  OcInfraModule.AppInjector.get(CommonService).getClientProducts() ==null ){
          OcInfraModule.AppInjector.get(CommonService).getCallback(this.getPlanCodeUrl, this.headers).subscribe(response => {
            this.processClientProducts(response._links.item);
          }, error => {
            if (error && error._embedded && error._embedded.status_report && error._embedded.status_report.consistent === false) {
              OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error._embedded.status_report.messages[0].message, 'Error', 0);
            }
          });
        }else{
          this.processClientProducts(OcInfraModule.AppInjector.get(CommonService).getClientProducts());
        }
        if(  OcInfraModule.AppInjector.get(CommonService).getClientAssetAccounts() ==null ){
          OcInfraModule.AppInjector.get(CommonService).getCallback(this.getAssetAccountUrl, this.headers).subscribe(response => {
            this.accTypeItems = response._links.item;
            OcInfraModule.AppInjector.get(CommonService).clientAssetAccounts = response._links.item;
          }, error => {
            if (error && error._embedded && error._embedded.status_report && error._embedded.status_report.consistent === false) {
              OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error._embedded.status_report.messages[0].message, 'Error', 0);
            }
          });
        }else{
          this.accTypeItems =  OcInfraModule.AppInjector.get(CommonService).getClientAssetAccounts();
        }
      

      
      }
      this.olsPolicyfund = this.elementRef.nativeElement.id == 'olsFundTable' ? true : false;
      this.csrPolicyfund = this.elementRef.nativeElement.id == 'fundTable' ? true : false;
      this.groupAnnuityFund = this.elementRef.nativeElement.id == 'fundTableGroupAnnuity' ? true : false;

  },0);

  }


  
  processClientProducts(prodTypeItems){
    if (prodTypeItems) {
      this.productTypes = [];
      for (let i = 0; i < prodTypeItems.length; i++) {
        this.productTypes.push({ value: prodTypeItems[i].summary.plan_code, viewValue: prodTypeItems[i].summary.plan_code });
      }
    }
  }
  testChange(event, indexValue, data, selectedValue, selectedKey, columnName, valueType, flag) {
    data.id = indexValue;
    let apiName = 'funds';
    let currencyFields = OcInfraModule.AppInjector.get(CommonService).selectdCurrencyFields;
    selectedValue = valueType == 'currency' ? '$' + selectedValue.replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,') : selectedValue;

    let selectedVal: any;
    if (this.selectedDate[data.id] && columnName !== 'name') {
      selectedVal = selectedValue ? selectedValue : 'No data in admin system';
      event.checked ? this.selectedDate[data.id].push(selectedKey + ':' + selectedVal) : this.selectedDate[data.id].splice(this.selectedDate[data.id].indexOf(selectedKey + ':' + selectedVal), 1)
    } else {
      if (!this.selectedDate[data.id]) {
        selectedVal = selectedValue ? selectedValue : 'No data in admin system';
        this.selectedDate[data.id] = new Array(selectedKey + ':' + selectedVal);
      }
    }

    //Added a flag to check whether "Name" is checked or not.
    if (this.selectedDate[data.id] && columnName == 'name' && event.checked) {
      data.nameFlag = true;
    } else if ((this.selectedDate[data.id] && columnName == 'name' && !event.checked)) {
      data.nameFlag = false;
    }


    if ((this.selectedDate[data.id].length == 1 && this.selectedDate[data.id][0].indexOf('Name') !== -1 && selectedKey == "Name" && !event.checked && flag == "true") || (this.selectedDate[data.id].length == 1 && this.selectedDate[data.id][0].indexOf('Name') !== -1 && selectedKey != "Name" && !event.checked && flag != "true" && data.nameFlag == false && flag != "true")) {
      delete this.selectedDate[data.id];
    } else if(!this.selectedDate[data.id].length){
      delete this.selectedDate[data.id];
    } 
    else {
      if (event.checked ? this.selectedDate[data.id][0].indexOf('Name') === -1 && indexValue != 0 : false) {
        this.selectedDate[data.id].unshift("Name:" + data.name);
      }
    }

    let html = '';
    if (this.selectedDate[data.id]) {
      for (var i = 0; i < this.selectedDate[data.id].length; i++) {
        var split = this.selectedDate[data.id][i].split(':');
        this.fundsObj[split[0].trim()] = split[1].trim();
      }
    }

    if (Object.keys(this.selectedDate).length > 0) {
      html += '<dl class="pad-lAr-10px">';
      Object.keys(this.selectedDate).forEach(key => {
        this.selectedDate[key].forEach(element => {
          if (currencyFields.includes(element.split(":")[0])) {
            html += '<dt class="pull-left">' + element.split(":")[0] + ':</dt><dd style = "font-weight: 200;"  >&nbsp;' + OcInfraModule.AppInjector.get(CommonService).convertToCurrency(element.split(":")[1]) + '</dd>';
          } else {
            html += '<dt class="pull-left">' + element.split(":")[0] + ':</dt><dd style = "font-weight: 200;"  >&nbsp;' + element.split(":")[1] + '</dd>';
          }
        });
      });
      html += '</dl>';
    }
    let selectedData = [];
    if (this.fundList.length > 0) {
      this.fundList.forEach(element => {
        if (this.selectedDate.hasOwnProperty(element.summary.id)) {
          let currentField = {};
          currentField[data.fund_type_desc] = this.selectedDate[element.summary.id];
          selectedData.push(currentField);
        }
      });
    }

    localStorage.setItem('fundInfo', JSON.stringify(selectedData));
    OcInfraModule.AppInjector.get(CommonService).fundHtml = html;
    OcInfraModule.AppInjector.get(CommonService).showfundFinalDetails();
    let finalHtml = OcInfraModule.AppInjector.get(CommonService).getFinalHtml();
    let requestedDetailSectionBilling = document.getElementsByClassName("requested_details_section_billing")[0];
    requestedDetailSectionBilling.innerHTML = finalHtml;
  }

  groupAnnuityCheckboxChange(event, data, selectedValue, selectedKey, columnName, valueType, flag) {
    let currencyFields = OcInfraModule.AppInjector.get(CommonService).selectdCurrencyFields;
    selectedValue = valueType == 'currency' ? '$' + selectedValue.replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,') : selectedValue;

    let selectedVal: any;
    const key =this.productType+'_'+this.accountType;

    if(!this.selectedDate || !this.selectedDate[key])
      this.selectedDate[key]={};
    
    let planCodeAssetAccountCheckData = this.selectedDate[key];

    if (planCodeAssetAccountCheckData[data.id]&& columnName !== 'name') {
      selectedVal = selectedValue ? selectedValue : 'No data in admin system';
      event.checked ?planCodeAssetAccountCheckData[data.id].push(selectedKey + ':' + selectedVal) : planCodeAssetAccountCheckData[data.id].splice(planCodeAssetAccountCheckData[data.id].indexOf(selectedKey + ':' + selectedVal), 1)
    } else {
      if (!planCodeAssetAccountCheckData[data.id]) {
        selectedVal = selectedValue ? selectedValue : 'No data in admin system';
        planCodeAssetAccountCheckData[data.id] = new Array(selectedKey + ':' + selectedVal);
      }
    }

    //Added a flag to check whether "Name" is checked or not.
    if   (planCodeAssetAccountCheckData[data.id] && columnName == 'name' && event.checked) {
      data.nameFlag = true;
    } else if ((planCodeAssetAccountCheckData[data.id] && columnName == 'name' && !event.checked)) {
      data.nameFlag = false;
    }

    // check to remove the object if only "Name" is present in it.
    if ((planCodeAssetAccountCheckData[data.id].length == 1 && planCodeAssetAccountCheckData[data.id][0].indexOf('Name') !== -1 && selectedKey == "Name" && !event.checked && flag == "true") || (planCodeAssetAccountCheckData[data.id].length == 1 && planCodeAssetAccountCheckData[data.id][0].indexOf('Name') !== -1 && selectedKey != "Name" && !event.checked && flag != "true" && data.nameFlag == false && flag != "true")){
      delete planCodeAssetAccountCheckData[data.id];
    }
    else {
      if (planCodeAssetAccountCheckData[data.id][0].indexOf('Name') === -1) {
        planCodeAssetAccountCheckData[data.id].unshift("Name:" + data.name);
      }
    }

    let html = '';
    if (planCodeAssetAccountCheckData[data.id]) {
      for (var i = 0; i < planCodeAssetAccountCheckData[data.id].length; i++) {
        var split = planCodeAssetAccountCheckData[data.id][i].split(':');
        this.fundsObj[split[0].trim()] = split[1].trim();//TODO
      }
    }

    let selectedData = [];
    if (this.fundList.length > 0) {
      this.fundList.forEach(element => {
        if (planCodeAssetAccountCheckData.hasOwnProperty(element.summary.id)) {
          let currentField = {};
          currentField[element.summary.id] = planCodeAssetAccountCheckData[element.summary.id];
          selectedData.push(currentField);
        }
      });
    }
    this.selectedDate[key]=planCodeAssetAccountCheckData;
    if(Object.entries(planCodeAssetAccountCheckData).length==0){
      delete this.selectedDate[key];
    }
    let currentSelectedState= {};
    if('fundInfo' in localStorage)
      currentSelectedState=JSON.parse( localStorage.getItem('fundInfo'));
    delete currentSelectedState[key]
    currentSelectedState[key] = selectedData;
    localStorage.setItem('fundInfo', JSON.stringify(currentSelectedState));

    for (const [planCodeAssetKey, selectedValues] of Object.entries(currentSelectedState)) {
      const keySplit=planCodeAssetKey.split("_")
      const tempKeys=Object.keys(selectedValues);
      if ( tempKeys && tempKeys.length> 0) {
        html += '<dl class="pad-lAr-10px"><dt class="pull-left"><span style="font-weight:600">&nbsp;Product:</span><span style="font-weight:400">&nbsp;'+keySplit[0] +'&nbsp;</span> <span style="font-weight:600">Asset Account:&nbsp;</span><span style="font-weight:400">' +keySplit[1] +"</span></dt><dd><dl>";
        tempKeys.forEach(tempKey => {
          const slecedtObj= selectedValues[tempKey];
          Object.keys(slecedtObj).forEach(key => {
            slecedtObj[key].forEach(element => {
              if (currencyFields.includes(element.split(":")[0])) {
                html += '<dt class="pull-left">' + element.split(":")[0] + ':</dt><dd style = "font-weight: 200;"  >&nbsp;' + OcInfraModule.AppInjector.get(CommonService).convertToCurrency(element.split(":")[1]) + '</dd>';
              } else {
                html += '<dt class="pull-left">' + element.split(":")[0] + ':</dt><dd style = "font-weight: 200;"  >&nbsp;' + element.split(":")[1] + '</dd>';
              }
            });
          });
        });
        html += '</dl></dd></dl>';
      }
      
    }
    OcInfraModule.AppInjector.get(CommonService).fundHtml = html;
    OcInfraModule.AppInjector.get(CommonService).showfundFinalDetails();
    let finalHtml = OcInfraModule.AppInjector.get(CommonService).getFinalHtml();
    let requestedDetailSectionBilling = document.getElementsByClassName("requested_details_section_billing")[0];
    requestedDetailSectionBilling.innerHTML = finalHtml;
  }

  

  checkedMesseage(event, selectedValue, selectedKey) {
    let html = '';
    if (event.checked) {
      this.selectedDate["annuity"] = new Array(selectedKey + ':' + selectedValue);
      localStorage.setItem('fundInfo', JSON.stringify(this.selectedDate));
      html = '<dl class=""> <dt class="pull-left">' + selectedKey + ':</dt><dd style="font-weight: 200;">&nbsp;' + selectedValue + '</dd>' + '</dl>';
      OcInfraModule.AppInjector.get(CommonService).fundMessageHtml = html;
    } else {
      localStorage.removeItem('fundInfo');
      OcInfraModule.AppInjector.get(CommonService).fundMessageHtml = '';
    }
    OcInfraModule.AppInjector.get(CommonService).showfundFinalDetails();
    OcInfraModule.AppInjector.get(CommonService).showRequestedDetails();
  }
  
  onProductTypeChange(e) {
    if (this.accTypeItems) {
      this.assetAccountTypes = [];
      for (let i = 0; i < this.accTypeItems.length; i++) {
        if (this.accTypeItems[i].summary.plan_code === e.value) {
          if (this.accTypeItems[i].summary.asset_account_names) {
            for (let j = 0; j < this.accTypeItems[i].summary.asset_account_names.length; j++) {
              this.assetAccountTypes.push({ value: this.accTypeItems[i].summary.asset_account_names[j], viewValue: this.accTypeItems[i].summary.asset_account_names[j] });
            }
          }
        }
      }
    }
  }
  
  getFundsInfo() {
    let optionParams = new OptionParams();
    var loanHistoryURL = OcInfraModule.AppInjector.get(CommonService).getAllUrlOfPolicyDetails();
    let selectedAssetAccount;
    if (this.accountType == "ALL ASSET ACCOUNTS") {
      selectedAssetAccount = '**';
    } else {
      selectedAssetAccount = this.accountType.substring(0, this.accountType.indexOf(' '));
    }

    optionParams.headers = OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("partyRoles");
    Object.assign(optionParams.headers, { client_id: OcInfraModule.AppInjector.get(CommonService).productInfo.client, plan_id: this.productType, asset_account: selectedAssetAccount });
    OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false });
    OcInfraModule.AppInjector.get(APICallerService).get(loanHistoryURL["funds"].href, optionParams).subscribe(response => {
      this.fundList = response.response._links.item;
      let fundIndex = 0;
      if (this.fundList.length > 0) {
        response.response._links.item.forEach(element => {
          element.summary.nameFlag = false;
          element.summary.id = fundIndex + 'F';
          fundIndex++;
        });
      }
      this.noFundsData = this.fundList && !this.fundList.length;
      OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
    }, error => {
      OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
      if (error && error._embedded && error._embedded.status_report && error._embedded.status_report.consistent === false) {
        OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error._embedded.status_report.messages[0].message, 'Error', 0);
      }
    });
  }

}