import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { Configuration, OcInfraModule, OptionParams, ResourceService } from '@diaas/ux-web';
import * as _ from 'lodash';
import { CommonService } from '../../services/common.service';
import { AdvGrowlService } from 'primeng-advanced-growl';
import { CurrencyPipe } from '@angular/common';
import { HistoryData, HistoryResponse, Item } from './financial-history-display.constant';

export type FinancialHistoryParams = {
    historyId: string, 
    history_type: string
}

export type FinancialHistoryHeaders = {
    client_id: string
    display_value: string,
    effective_date: string
}
@Component({
    selector: 'app-financial-history-display',
    templateUrl: './financial-history-display.component.html',
    styleUrls: ['./financial-history-display.component.scss']
})
export class FinancialHistoryDisplayComponent implements OnInit {

    optionParams = new OptionParams();
    historyResponse: HistoryResponse;
    historyData: HistoryData[];
    financialParams: FinancialHistoryParams
    financialHeaders: FinancialHistoryHeaders
    constructor(
        public dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private resourceService: ResourceService,
        private commonService: CommonService,
        private advGrowlService: AdvGrowlService,
        private currencyPipe: CurrencyPipe
    ) { }

    ngOnInit() {
        this.financialParams = <FinancialHistoryParams>this.data.params;
        this.financialHeaders = <FinancialHistoryHeaders>this.data.headers;
        this.getHistoryDetails(this.financialParams, this.financialHeaders);
    }

    getHistoryDetails = async (params: FinancialHistoryParams, headers: FinancialHistoryHeaders) => {
        this.optionParams.headers = OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("financialHistory");
        this.commonService.showHideSpinner({ showSpinner: false, edit: false });
        let policyDetails = this.resourceService.getResourceFromAliasName('policyDetails');
        let hrefUrl = policyDetails._links['financialhistory/transactionDetails'].href.split('?')[0];
        this.optionParams.headers = {...this.optionParams.headers, ...headers};

        this.commonService.getCallback(hrefUrl,  this.optionParams.headers, params).subscribe(async (response) => {
            this.historyResponse = response;
            this.transformData(this.historyResponse._links.item[0]);
            this.commonService.showHideSpinner({ showSpinner: false, edit: false });
        }, async (error) => {
            if (error && error._embedded && error._embedded.status_report && error._embedded.status_report.consistent === false) {
                this.advGrowlService.createTimedErrorMessage(error._embedded.status_report.messages[0].message, 'Error', 0);
            } else {
                this.advGrowlService.createTimedErrorMessage("Something went wrong please try again", 'Error', 0);
            }
            this.commonService.showHideSpinner({ showSpinner: false, edit: false });
        });
    }

    transformData = (data: Item) => {
        let excludeProp = ['_links'];
        this.historyData = Object.entries(data.summary.transaction_Details).filter((item) => !excludeProp.includes(item[0])).map((item) => this.transformMap(item));
    }

    transformMap = (item: any[]): HistoryData => {
        let [key, value] = item;
        return { id: item[0], label: this.snakeToPascal(key), value: this.toTransformValue(item[0], item[1], this.historyResponse._options.properties) }
    }

    snakeToCamel = (str: string) => str.replace(/([-_]\w)/g, g => g[1].toUpperCase());
    snakeToPascal = (str: string) => {
        let camelCase = this.snakeToCamel(str);
        let pascalCase = camelCase[0].toUpperCase() + camelCase.substr(1);
        return pascalCase;
    }
    PascalToCamel = (str: string) => str.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) { return str.toUpperCase(); })

    isArray = (val: any): boolean => Array.isArray(val);

    getTableHeaders = (item: HistoryData) => {
        if (item && item.value && item.value.length > 0) {
            return this.getTableHeadersFromItem(item);
        } else {
            return this.getTableHeadersFromProperties(item);
        }
    };

    getTableHeadersFromItem = (item: HistoryData) => {
        let headers: any[];
        let excludeProp = ['_links'];
        if (item && item.value && item.value.length > 0) {
            headers = Object.keys(item.value[0])
                .filter((element) => !excludeProp.includes(element)).map((ele) => this.snakeToPascal(ele));
        }
        return headers;
    };

    getTableHeadersFromProperties = (item: HistoryData) => {
        let headers: any[];
        let excludeProp = ['links', '_embedded', '_options'];
        let properties = Object.keys(this.historyResponse._options.properties[item.id].items.properties);
        if (properties && properties.length > 0) {
            headers = properties.filter((ele) => (!excludeProp.includes(ele))).map((ele) => this.snakeToPascal(ele));
        }
        return headers;
    };

    getTableData = (item: any, propId: any) => {
        let data: any[];
        let excludeProp = ['_links'];
        let properties = this.historyResponse._options.properties[propId].items.properties;
        if (item && Object.keys(item).length > 0) {
            data = Object.entries(item).filter((element) => (!excludeProp.includes(element[0]) && element)).map((ele) => this.toTransformValue(ele[0], ele[1], properties));
        }
        return data;
    };

    getTableHeadersLength = (item: HistoryData) => {
        let excludeProp = ['links', '_embedded', '_options'];
        let properties = Object.keys(this.historyResponse._options.properties[item.id].items.properties);
        if (properties && properties.length > 0) {
            properties = properties.filter(ele => !excludeProp.includes(ele));
            return properties.length;
        }
    }

    toTransformValue = (key: any, value: any, properties: any) => {
        let format = '';
        let type = '';
        if (key in properties && properties[key] && 'format' in properties[key]) {
            format = properties[key]['format'];
        }

        if (key in properties && properties[key] && 'type' in properties[key]) {
            type = properties[key]['type'];
        }

        let formattedValue: any;
        if (format == 'currency') {
            formattedValue = this.currencyPipe.transform(value, '$');
        } else if (format == 'percent') {
            formattedValue = value + '%';
        } else if (type == 'boolean') {
            formattedValue = value === true ? 'Yes' : 'No';
        } else {
            formattedValue = value;
        }
        return formattedValue
    }

}
