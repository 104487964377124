import { Injectable, EventEmitter, Output } from '@angular/core';
import { Http, Headers, Response } from '@angular/http';
import { Observable, Subscription } from 'rxjs';
import { CanActivate } from '@angular/router/src/interfaces';
import * as _ from 'lodash';
import { CommonService } from './../services/common.service';
import { OcInfraModule, APICallerService, ResourceSchemaService, Configuration, NavigationService } from '@diaas/ux-web';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router/src/router_state';
import { MatDialog } from '@angular/material';
import { ConfirmdialogComponent, confirmDialogConfig } from './../components/confirmdialog/confirmdialog.component';
import { GetCognitoDetails } from '../../ocInfraConfig/ocinfra-config';

import { tap } from 'rxjs/operators';
import { UserIdleService } from 'angular-user-idle';

@Injectable()
export class AuthenticationService implements CanActivate {

    public logoutURL = _.filter(Configuration.config.hostURL.multiHostUrl, function (o) { return o.alias == 'logout' })[0].url;

    private header = _.filter(Configuration.config.headers["multiHostUrlHeader"], function (o) { return o.alias == 'authentication' });

    public config: any = {};

    public httpHeaders: Headers;

    public sessionCounter: number;

    public timeCounter: number;

    public logoutBody: any = {};

    public userPool: any;

    @Output()
    getHeaderProperties: EventEmitter<any> = new EventEmitter();

    // Idle time variables(User inactivity 45 minutes)
    idle: number;
    timeout: number;
    isTimer: boolean;
    timerCount: number;  
    private timerStartSubscription: Subscription;
    private timeoutSubscription: Subscription;      

    constructor(private http: Http, private dialog: MatDialog, private userIdle: UserIdleService) {
        this.resetHeaders();

        // Create the idle time dialog element and append to the body
        let idleDiv = document.createElement("div");
        let hostUrlValue = location.host;
        // Add your content to the DIV
        idleDiv.innerHTML = `<div id="idleModal" class="idle-modal">
            <div class="modal-content">
                <div>
                    <p class="mb-2 dialog-head">${hostUrlValue} says</p>
                    <p class="dialog-content">Session has expired due to inactivity. Please login again</p>
                    <button id="idleBtnHide" class="btn idle-close-btn">Ok</button>
                </div>
            </div>
        </div>`;
        // Finally, append the element to the HTML body
        document.body.appendChild(idleDiv);           
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        const user = window.sessionStorage.getItem('username');
        const tokenId = "Bearer " + window.sessionStorage.getItem('tokenId');
        const localHeaders = OcInfraModule.AppInjector.get(APICallerService).headers;
        if (user && tokenId) {
            this.getHeaderProperties.emit(true);
            if (!localHeaders.Authorization || !this.httpHeaders.get('Authorization')) {
                this.httpHeaders.set('Authorization', tokenId);
                this.changeApiHeaders();
            }
            if (!this.sessionCounter) {
                // Session timeout tracker started. Page reloading.
                this.sessionCounter = 1;
                this.sessionTimeOut();                
            }
            if (!this.timeCounter) {
                this.refreshtoken();
            }
            return true;
        } else {
            this.getHeaderProperties.emit(false);
            window.location.href = window.location.href;
            return false;
        }
    }

    public resetHeaders() {
        this.httpHeaders = new Headers;
        // this.httpHeaders.set('X-IBM-Client-ID', this.header[0].headers.clientId);
        // this.httpHeaders.set('X-IBM-Client-Secret', this.header[0].headers.clientSecret);
        this.changeApiHeaders();
    }

    public changeApiHeaders() {
        OcInfraModule.AppInjector.get(APICallerService).changeHeaders(this.httpHeaders.toJSON());
        OcInfraModule.AppInjector.get(ResourceSchemaService).changeHeaders(this.httpHeaders.toJSON());
    }
     
    public sessionTimeOut() {
        
        // Idle time initialize methods
        this.configValues();
        this.onStartWatching();
        // Reset idle time for mouse move, key press and key up
        window.addEventListener('mousemove', e => {
            this.onResetTimer();
            this.onStopWatching();
            this.onStartWatching();
        });
        // window.addEventListener('keypress', e => {
        //     this.onResetTimer();
        //     this.onStopWatching();
        //     this.onStartWatching();
        // }); 
        // window.addEventListener('keyup', e => {
        //     this.onResetTimer();
        //     this.onStopWatching();
        //     this.onStartWatching();
        // });                           

        var self = this;
        var minutes = true;
        var interval = minutes ? 60000 : 1000;
        var sessionTimeout = 720;

        var sessionInterval = setInterval(function () {
            if (++self.sessionCounter == sessionTimeout-5) {
              this.dialogRef = OcInfraModule.AppInjector.get(CommonService).showConfirmationPopup('Your current session will end in 5 minutes due to the maximum time allowed.  If you are still working, select Continue to extend the session.  Select End to end your session and return to the Login page. Any unsaved work will be lost.', 'End', 'Continue','alert','340px')
                this.dialogRef.afterClosed().subscribe(res => {
                    if(res) {
                    }
                    else {                       
                      window.sessionStorage.clear();
                      clearInterval(sessionInterval)
                      window.location.reload();
                    }
                })
                
            }
            if(self.sessionCounter==sessionTimeout){
                this.dialogRef.close();
                alert("You will now be logged out and re-directed to the login page due to maximum time reached.");
                   window.sessionStorage.clear();
                   clearInterval(sessionInterval)
                   window.location.reload();
               }   
        }, interval);
    }
    
    /* Idle time methods start(User inactive till 45 mint then show alert message) */    
    configValues() {
        this.idle = this.userIdle.getConfigValue().idle / 1000;
        this.timeout = 2700; // Seconds
    }  
    
    onStartWatching() {
        this.userIdle.setConfigValues({
            idle: this.idle,
            timeout: this.timeout
        });

        // Start watching for user inactivity.
        this.userIdle.startWatching();

        // Start watching when user idle is starting.
        this.timerStartSubscription = this.userIdle.onTimerStart()
            .pipe(tap(() => this.isTimer = true))
            .subscribe(count => {
        });

        // Start watch when time is up.
        this.timeoutSubscription = this.userIdle.onTimeout().subscribe(() => {        
            // Get idle time modal
            let idleTimemodal = document.getElementById("idleModal");
            let idleBtnHide = document.getElementById("idleBtnHide");

            // Open idle time modal 
            idleTimemodal.style.display = "block";
        
            // Close idle time modal
            idleBtnHide.onclick = function() {
                idleTimemodal.style.display = "none";
                OcInfraModule.AppInjector.get(NavigationService).navigateTo('**');       
                setTimeout(() => {
                    window.sessionStorage.clear();
                    window.location.reload();         
                }, 100);                
            }             
        });
    }
    
    onStopWatching() {
        this.userIdle.stopWatching();
        this.timerStartSubscription.unsubscribe();
        this.timeoutSubscription.unsubscribe();
        this.isTimer = false;
    }
    
    onResetTimer() {
        this.userIdle.resetTimer();
        this.isTimer = false;
    }    
    /* Idle time methods end */    
    
    public executeLogout() {
        var cognitoUser = this.userPool && this.userPool.getCurrentUser();
        if (cognitoUser != null) {
            cognitoUser.signOut();
            this.getHeaderProperties.emit(false);
            this.resetHeaders();
            OcInfraModule.AppInjector.get(APICallerService).changeHeaders({ remote_user: null });
            window.sessionStorage.clear();
            window.location.href = "/";
            OcInfraModule.AppInjector.get(NavigationService).navigateTo(Configuration.config.loginPage);
        } else {
            window.sessionStorage.clear();
            window.location.href = "/";
            OcInfraModule.AppInjector.get(NavigationService).navigateTo(Configuration.config.loginPage);
        }
    }
    public refreshtoken() {
        var minutes = true;
        var interval = minutes ? 3479999 : 1000;
        var sessionInterval = setInterval(function () {
            const URL = _.filter(Configuration.config.hostURL.multiHostUrl, function (o) { return o.alias === 'refreshtoken' });
            const headers = OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("refreshtoken");
            let AuthParameters = {
                "REFRESH_TOKEN": window.sessionStorage.getItem('refreshToken')
            }
            let payload = {
                "AuthFlow": "REFRESH_TOKEN_AUTH",
                "ClientId": GetCognitoDetails().clientId,
                "AuthParameters": AuthParameters
            }
            OcInfraModule.AppInjector.get(CommonService).postCallback(URL[0].url, payload, headers).subscribe(response => {
                if (response.AuthenticationResult) {
                    window.sessionStorage.setItem('tokenId', response.AuthenticationResult.AccessToken);
                    Configuration.config.headers.defaultHeader.Authorization = "Bearer " + response.AuthenticationResult.AccessToken;
                    Configuration.config.headers["multiHostUrlHeader"].forEach(headerObj => {
                        if (headerObj.headers.hasOwnProperty("Authorization")) {
                            headerObj.headers.Authorization = "Bearer " + response.AuthenticationResult.AccessToken;
                        }
                    });
                }
            });
        }, interval);
    }
}
