import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { OcInfraModule, ResourceService, APICallerService } from '@diaas/ux-web';
import * as _ from 'lodash';
import { AdvGrowlService } from 'primeng-advanced-growl';

@Component({
  selector: 'correspondence-fax',
  templateUrl: './correspondenceFax.component.html',
})

export class CorrespondenceFaxComponent implements OnInit {

  constructor(private commonService: CommonService) { }
  faxNumber: string = '';
  authsucess: any = OcInfraModule.AppInjector.get(CommonService).authsucess;
  otherRoleSucess: any = OcInfraModule.AppInjector.get(CommonService).otherRoleAuthsucess;
  disableSendFax: boolean = true;
  hoverMessage: string = '';
  length : string;
  faxClass: boolean = true;
  ngOnInit() {
    this.authsucess || this.otherRoleSucess ? this.hoverMessage = 'Enable button by completing required items' : this.hoverMessage = 'Caller was not authorized';
   setTimeout(() =>{
    OcInfraModule.AppInjector.get(CommonService).showHideSpinner({showSpinner: false, edit: false});
      }, 1000);
  }

  numberOnly(e): boolean {
    {if(e.key == 'Control' || e.key == 'v'){
      e.target["value"] = e.target["value"].replace(/(\d{3}(?!\d?$))\-?/g, '$1-');
      this.faxNumberChange(e);
    }
     else if (e.keyCode > 31 && ((e.keyCode < 48 || e.keyCode > 57) && (e.keyCode < 96 || e.keyCode > 105))) {
       if((e.keyCode > 64 && e.keyCode < 123) && (e.key == 'Control' && e.keyCode != 67)){
        e.target["value"].length > 13 ? '' : e.target["value"] = e.target["value"].substring(0, e.target["value"].length-1);
        this.faxNumberChange(e);
       }
      e.preventDefault();  
      } else {
        e.target["value"] = e.target["value"].replace(/(\d{3}(?!\d?$))\-?/g, '$1-');
        if(e.target["value"].length > 13 ){
          e.target["value"] = e.target["value"].substring(0, e.target["value"].length-1);
          e.preventDefault();    
        }  
        this.faxNumberChange(e);
         e.target["value"].length < 13  ?  this.length = '12' : this.length = '0';
        return true;
      }
    }
  }

  faxNumberChange(e) {
    e.target.value != '' && e.target.value.length == 12 && (this.authsucess || this.otherRoleSucess) ? this.disableSendFax = false : this.disableSendFax = true;
    e.target.value != '' && e.target.value.length == 12 ? this.faxClass = false : this.faxClass = true;
    this.faxNumber = this.faxNumber.indexOf("-") == -1 ? this.faxNumber.replace(/(\d{3}(?!\d?$))\-?/g, '$1-') : this.faxNumber;
    (this.authsucess || this.otherRoleSucess ) && this.disableSendFax ? this.hoverMessage = 'Enable button by completing required items':  this.hoverMessage = '';
  }

  sendFax(e) {
    OcInfraModule.AppInjector.get(AdvGrowlService).clearMessages();
    const resourceService = OcInfraModule.AppInjector.get(ResourceService);
    let policyDetails = resourceService.getResourceFromAliasName('policyDetails');
    const selectedRoleSummary = resourceService.getResourceFromAliasName('selectedRoles'); 
    let workRequestDetails = resourceService.getResourceFromAliasName('workRequestHistory');
    let headers = this.commonService.getHeaderByAliasName("selectedWorkRequest");
    const workRequestHref = resourceService.getHrefByAliasName("workRequestHistory").split('?')[0];
    headers.statuaory_company_code = policyDetails.data.statutory_company_code;
    headers.company_code = policyDetails.data.company_code;
    delete headers.emailType;
    headers.faxType = "correspondence"; 
    headers.role = OcInfraModule.AppInjector.get(CommonService).selectedRole;
    headers.product_type = policyDetails.data.product_type;
    let payload =
    {
      "CLTY": "REPRINT",
      "POLN": (this.authsucess || this.otherRoleSucess ) ? policyDetails.data.policy_number : selectedRoleSummary.data.policyNumber,
      "attachedForms": [
        "CSRP-REPRINT"
      ],
      "letterType": "EFORMS",
      "batchValue":  this.faxNumber.replace(/[^a-zA-Z0-9]/g, ''),
      "departmentName": "EFORMS",
      "lob": policyDetails.data.line_of_business_code,
      "additionalInfo": "AWDAutoClaim",
      "documentKeys": OcInfraModule.AppInjector.get(CommonService).correspondenceDoc,
    }
    OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false });
    OcInfraModule.AppInjector.get(CommonService).postCallback(workRequestDetails._links.fax.href, payload, headers).subscribe(response => {
      if (response && response.message) {
        OcInfraModule.AppInjector.get(AdvGrowlService).createSuccessMessage(response.message, 'Success');
        let correspondenceCheckWrapper = document.querySelectorAll('#correspondenceTable .mat-checkbox input[type=checkbox]:checked');
        let correspondenceBtn = document.querySelectorAll('.correspondence-btn');
        Array.from(correspondenceBtn).forEach(btn => {
          btn['classList'].add('disabled')
          btn['disabled'] = true;
          btn[' ng-reflect-disabled'] = true;
        });
        document.getElementById("modal__cross").click();
        OcInfraModule.AppInjector.get(CommonService).correspondenceDoc = [];
        OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false });
        setTimeout(() => {
          OcInfraModule.AppInjector.get(APICallerService).refresh(workRequestHref, { 'alias': "workRequestHistory", 'headers': headers }).subscribe(refreshResponse => {
            OcInfraModule.AppInjector.get(CommonService).wrkReqHistoryRes = refreshResponse;
            OcInfraModule.AppInjector.get(CommonService).sendFilterEvent();
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
          });
        }, 5000);
        Array.from(correspondenceCheckWrapper).forEach(corres => {
          corres.dispatchEvent( new Event('click'));
        });
      } else {
        console.log('Error occurred while sending Fax to Fax Utility',response);
        OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage("Something went wrong", 'Error', 0);
        OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
        document.getElementById("modal__cross").click();
      }
    }, error => {
      OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
      error && error.message ? OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error.message, 'Error', 0) : OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage("Fax was not sent successfully to Fax Utility", 'Error', 0);
    })
  }
}
