import { Component, OnInit, ElementRef } from '@angular/core';
import { OcInfraModule, APICallerService, Configuration, NavigationService, IAlias, ResourceService, ResourceSchemaService, OptionParams, ResourceDirectoryService } from '@diaas/ux-web';
import { CommonService } from '../../services/common.service';
import { AdvGrowlService } from 'primeng-advanced-growl';
import * as _ from 'lodash';

@Component({
  selector: 'app-workrequest-refresh',
  templateUrl: './workrequest-refresh.component.html',
  styleUrls: ['./workrequest-refresh.component.scss']
})
export class WorkrequestRefreshComponent implements OnInit {
  resourceService = OcInfraModule.AppInjector.get(ResourceService);
  response: any;
  constructor(public elementRef: ElementRef, public commonService: CommonService) { }

  ngOnInit() {
    this.getType();
  }

  refreshWorkrequests() {
    if(this.elementRef.nativeElement.id == 'workRequestHistoryRefresh'){
      const workRequestHref = this.resourceService.getHrefByAliasName("workRequestHistory").split('?')[0];
      const workRequestHeaders = OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("workRequestHistory");
      OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false });
      OcInfraModule.AppInjector.get(APICallerService).refresh(workRequestHref, { 'alias': "workRequestHistory", 'headers': workRequestHeaders }).subscribe(refreshResponse => {
        this.response=refreshResponse;
        OcInfraModule.AppInjector.get(CommonService).wrkReqHistoryRes = refreshResponse;
        OcInfraModule.AppInjector.get(CommonService).sendFilterEvent();
        OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
      });
      error => {
        OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
        this.response._embedded.status_report.messages ?OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage("Something went wrong", 'Error', 0):"";
      }
    } else {
      this.getType();
    }
  }
  getType() {
    if (this.elementRef.nativeElement.id == 'workRequestRefresh') {
      this.getWorkItemTypes();
    }
  }

  // Get work types
  getWorkItemTypes() {
    const urlIndex = _.findLastIndex(Configuration.config.hostURL.multiHostUrl, ['alias', "types"]);
    let workrequesttHref: any = Configuration.config.hostURL.multiHostUrl[urlIndex]['url']+ 'types';
    // const workrequesttHref = this.resourceService.getHrefByAliasName("types");
    let header = _.filter(Configuration.config.headers["multiHostUrlHeader"], function (o) { return o.alias == 'types' });   
    OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false });

    let selectedRoleSummary  = this.commonService.productInfo;
    this.setHeader(selectedRoleSummary, header, true);
    
    OcInfraModule.AppInjector.get(APICallerService).refresh(workrequesttHref, { 'headers': header[0].headers }).subscribe(refreshResponse => {
      this.resourceService.updateCreateAliasName('types', workrequesttHref, true, header[0].headers);
      this.setHeader(selectedRoleSummary, header, false);
      this.response = refreshResponse;
      OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
    });
    error => {
      OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
      this.setHeader(selectedRoleSummary, header, false);
      this.response._embedded.status_report.messages ? OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage("Something went wrong", 'Error', 0) : "";
    }
  }


  // Set "duplicate_policy_ind" header based Duplicate policies 
  setHeader(selectedRoleSummary, header, status){
    if (selectedRoleSummary && this.commonService.isDuplicatePoliciesAction() && status) {
      header[0].headers.duplicate_policy_ind = (selectedRoleSummary.duplicate_policy_ind).toString();
      OcInfraModule.AppInjector.get(CommonService).changeHeaders(header[0].headers);
    } else {
      header[0].headers.duplicate_policy_ind = "false";
      OcInfraModule.AppInjector.get(CommonService).changeHeaders(header[0].headers);
    }
  }

}
