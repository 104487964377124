import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonService } from '../../../services/common.service';
import { OcInfraModule, ResourceService, APICallerService } from '@diaas/ux-web';
import * as moment from 'moment';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit {

  @Input() fundTransactions: any;
  @Input() stepCompletionIndicator: any;
  @Output() scrollTop: EventEmitter<string> = new EventEmitter<string>();
  confirmationNumber: any;
  requestedDate = new Date();
  confirmationDate: Date;
  disableConfirmBtn: boolean = true;

  constructor(private dialog: MatDialog) { }

  ngOnInit() {
    this.confirmationNumber = OcInfraModule.AppInjector.get(CommonService).authResponse.updateResponse.data.confirmationNumber;
  }

  scriptChecked(e) {
    this.disableConfirmBtn = e.checked ? false : true;
  }

  onConfirmClick() {
    this.dialog.closeAll();
    this.refreshPendingTransactions();
    this.updateWorkRequestHistory();
    this.populateCallHistoryDetails();
    document.getElementById('close-call-confirmation-checkbox-input').click();
    let fundTransferList = OcInfraModule.AppInjector.get(CommonService).allTransactions['FUND TRANSFER'];
    if (fundTransferList && fundTransferList.length > 1) {
      document.getElementById('close-call-confirmation-checkbox-input').click();
    }
    OcInfraModule.AppInjector.get(CommonService).confirmationNo = true;
    document.getElementById('close-call-confirmation-checkbox_label').innerHTML = 'Confirmation #' + this.confirmationNumber;
  }

  updateWorkRequestHistory() {
    const resourceService = OcInfraModule.AppInjector.get(ResourceService);
    const workRequestHref = resourceService.getHrefByAliasName("workRequestHistory").split('?')[0];
    const headers = OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("workRequestHistory")
    OcInfraModule.AppInjector.get(APICallerService).refresh(workRequestHref, { 'alias': "workRequestHistory", 'headers': headers }).subscribe(refreshResponse => {
      OcInfraModule.AppInjector.get(CommonService).wrkReqHistoryRes = refreshResponse;
      OcInfraModule.AppInjector.get(CommonService).sendFilterEvent();
     });    
  }

  refreshPendingTransactions() {
    const resourceService = OcInfraModule.AppInjector.get(ResourceService);
    let pendingTransactions = resourceService.getResourceFromAliasName('pendingTransactions');
    let pendingTransactionGETURL = "";
    let headers;
    if (!pendingTransactions) {
      let policyDetails = resourceService.getResourceFromAliasName('policyDetails') ? resourceService.getResourceFromAliasName('policyDetails').response : null;
      pendingTransactionGETURL = policyDetails._links.self.href + "/pendingTransactions";
      headers = OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("policyDetails");
    } else {
      pendingTransactionGETURL = pendingTransactions._links.self.href;
      headers = OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("pendingTransactions");
    }
    OcInfraModule.AppInjector.get(APICallerService).refresh(pendingTransactionGETURL, { 'alias': "pendingTransactions", 'headers': headers }).subscribe(refreshResponse => {
    });
  }

  populateCallHistoryDetails() {
    let requestedDateTime = moment(this.requestedDate).tz('America/New_York').format('MM/DD/YYYY HH:mm');
    let confirmationDateTime = moment(this.confirmationDate).tz('America/New_York').format('MM/DD/YYYY HH:mm');
    if (this.fundTransactions.fundTransfer) {
      let fundTransferCallNoteObj = {};
      fundTransferCallNoteObj['Confirmation Number'] = this.confirmationNumber;
      fundTransferCallNoteObj['Requested Date And Time'] = requestedDateTime;
      fundTransferCallNoteObj['Confirmation Date And Time'] = confirmationDateTime;
      fundTransferCallNoteObj['Effective Date Of Transaction'] = moment(this.fundTransactions.fundTransfer.effectiveDate).format('MM/DD/YYYY');
      fundTransferCallNoteObj['Type Of Transaction'] = 'Fund Transfer';
      fundTransferCallNoteObj['Transfer Type'] = this.fundTransactions.fundTransfer.transferType;
      if (this.fundTransactions.fundTransfer.isPortfolioPlatform) {
        let groupedFundValues = this.fundTransactions.fundTransfer.selectedGroupedFunds;
        let groupedFundDetails = [];
        if (groupedFundValues && groupedFundValues.length > 0) {
          groupedFundValues.forEach(groupedFund => {
            let groupedFundDetail = {};
            let fundDetails = [];
            let fundValues = groupedFund.funds;
            if (fundValues && fundValues.length > 0) {
              fundValues.forEach(fund => {
                const fundValue = {};
                fundValue['Fund Number'] = fund['number'];
                fundValue['Fund Name'] = fund['name'];
                if (this.fundTransactions.fundTransfer.transferType == 'Dollar') {
                  (fund['from_to_indicator'] == 'F') ? fundValue['From Amount'] = this.formatCurrency(fund['amount']) : fundValue['To Amount'] = this.formatCurrency(fund['amount']);
                } else if (this.fundTransactions.fundTransfer.transferType == 'Percent') {
                  (fund['from_to_indicator'] == 'F') ? fundValue['From Percent'] = fund['percent'] + '%' : fundValue['To Percent'] = fund['percent'] + '%';
                } else if (this.fundTransactions.fundTransfer.transferType == 'Amount to Percent') {
                  (fund['from_to_indicator'] == 'F') ? fundValue['From Amount'] = this.formatCurrency(fund['amount']) : fundValue['To Percent'] = fund['percent'] + '%';
                }
                fundDetails.push(fundValue);
              });
            }
            groupedFundDetail['Group Code'] = groupedFund.groupCode;
            groupedFundDetail['Funds'] = fundDetails;
            groupedFundDetails.push(groupedFundDetail);
          });
        }
        fundTransferCallNoteObj['Fund Details'] = groupedFundDetails;
      } else {
        let fundValues = this.fundTransactions.fundTransfer.selectedFunds;
        let fundDetails = [];
        if (fundValues && fundValues.length > 0) {
          let globalAllTransactions = OcInfraModule.AppInjector.get(CommonService).allTransactions['FUND TRANSFER'] ?
            OcInfraModule.AppInjector.get(CommonService).allTransactions['FUND TRANSFER']['Fund Details'] : undefined;
          fundValues.forEach(fund => {
            const fundValue = {};
            fundValue['Fund Number'] = fund['number'];
            fundValue['Fund Name'] = fund['name'];
            if (this.fundTransactions.fundTransfer.transferType == 'Dollar' || this.fundTransactions.fundTransfer.transferType == 'Dollar Transfer') {
              (fund['from_to_indicator'] == 'F') ? fundValue['From Amount'] = this.formatCurrency(fund['amount']) : fundValue['To Amount'] = this.formatCurrency(fund['amount']);
            } else if (this.fundTransactions.fundTransfer.transferType == 'Percent') {
              (fund['from_to_indicator'] == 'F') ? fundValue['From Percent'] = fund['percent'] + '%' : fundValue['To Percent'] = fund['percent'] + '%';
            } else if (this.fundTransactions.fundTransfer.transferType == 'Amount to Percent') {
              (fund['from_to_indicator'] == 'F') ? fundValue['From Amount'] = this.formatCurrency(fund['amount']) : fundValue['To Percent'] = fund['percent'] + '%';
            } 

            if (globalAllTransactions != undefined && globalAllTransactions) {
              globalAllTransactions.push(fundValue);
              fundDetails = globalAllTransactions;
            } else
              fundDetails.push(fundValue);
          });
        }
        fundTransferCallNoteObj['Fund Details'] = fundDetails;
      }
      this.fundTransactions.fundTransfer.DateTime ? fundTransferCallNoteObj['Date And Time'] = this.fundTransactions.fundTransfer.DateTime : null;
      let fundTransferList = OcInfraModule.AppInjector.get(CommonService).allTransactions['FUND TRANSFER'];
      if (fundTransferList) {
        fundTransferList.push(fundTransferCallNoteObj);
      } else {
        fundTransferList = fundTransferCallNoteObj;
      }
      Object.assign(OcInfraModule.AppInjector.get(CommonService).allTransactions, { 'FUND TRANSFER': fundTransferList });
    }
    if (this.fundTransactions.allocationChanges) {
      let allocationChangeCallNoteObj = {};
      allocationChangeCallNoteObj['Confirmation Number'] = this.confirmationNumber;
      allocationChangeCallNoteObj['Requested Date And Time'] = requestedDateTime;
      allocationChangeCallNoteObj['Confirmation Date And Time'] = confirmationDateTime;
      allocationChangeCallNoteObj['Effective Date Of Transaction'] = moment(this.fundTransactions.allocationChanges.effectiveDate).format('MM/DD/YYYY');
      allocationChangeCallNoteObj['Type Of Transaction'] = 'Future Allocation Change';
      allocationChangeCallNoteObj['Transfer Type'] = this.fundTransactions.allocationChanges.transferType;
      if (this.fundTransactions.allocationChanges.isPortfolioPlatform) {
        let groupedFundValues = this.fundTransactions.allocationChanges.selectedFunds;
        let groupedFundDetails = [];
        if (groupedFundValues && groupedFundValues.length > 0) {
          groupedFundValues.forEach(groupedFund => {
            let groupedFundDetail = {};
            let fundDetails = [];
            let fundValues = groupedFund.funds;
            if (fundValues && fundValues.length > 0) {
              fundValues.forEach(fund => {
                const fundValue = {};
                fundValue['Fund Number'] = fund['number'];
                fundValue['Fund Name'] = fund['name'];
                fundValue['Percentage'] = fund['allocation_percent'] + '%';
                fundDetails.push(fundValue);
              });
            }
            groupedFundDetail['Group Code'] = groupedFund.groupCode;
            groupedFundDetail['Funds'] = fundDetails;
            groupedFundDetails.push(groupedFundDetail);
          });
        }
        allocationChangeCallNoteObj['Fund Details'] = groupedFundDetails;
      } else {
        let fundValues = this.fundTransactions.allocationChanges.selectedFunds;
        let fundDetails = [];
        if (fundValues && fundValues.length > 0) {
          fundValues.forEach(fund => {
            const fundValue = {};
            fundValue['Fund Number'] = fund['number'];
            fundValue['Fund Name'] = fund['name'];
            fundValue['Percentage'] = fund['allocation_percent'] + '%';
            fundDetails.push(fundValue);
          });
        }
        allocationChangeCallNoteObj['Fund Details'] = fundDetails;
      }
      this.fundTransactions.allocationChanges.dateTime ? allocationChangeCallNoteObj['Date And Time'] = this.fundTransactions.allocationChanges.dateTime : null;
      Object.assign(OcInfraModule.AppInjector.get(CommonService).allTransactions, { 'FUTURE ALLOCATION CHANGE': allocationChangeCallNoteObj });
    }
    if (this.fundTransactions.rebalancing) {
      let rebalancingCallNoteObj = {};
      rebalancingCallNoteObj['Confirmation Number'] = this.confirmationNumber;
      rebalancingCallNoteObj['Requested Date And Time'] = requestedDateTime;
      rebalancingCallNoteObj['Confirmation Date And Time'] = confirmationDateTime;
      rebalancingCallNoteObj['Effective Date Of Transaction'] = moment(this.fundTransactions.rebalancing.effectiveDate).format('MM/DD/YYYY');
      rebalancingCallNoteObj['Type Of Transaction'] = 'Rebalancing Change';
      rebalancingCallNoteObj['Transfer Frequency'] = this.fundTransactions.rebalancing.transferType;
      rebalancingCallNoteObj['Start Date'] = this.fundTransactions.rebalancing.rebalanceStartDate;
      this.fundTransactions.rebalancing.rebalanceNextDate && this.fundTransactions.rebalancing.rebalanceNextDate !== '' ? rebalancingCallNoteObj['Next Rebalance Date'] = this.fundTransactions.rebalancing.rebalanceNextDate : null;
      let fundValues = this.fundTransactions.rebalancing.funds;
      let fundDetails = [];
      if (fundValues && fundValues.length > 0) {
        fundValues.forEach(fund => {
          const fundValue = {};
          fundValue['Fund Number'] = fund['number'];
          fundValue['Fund Name'] = fund['name'];
          fundValue['Percentage'] = fund['allocation_percent'] + '%';
          fundDetails.push(fundValue);
        });
      }
      this.fundTransactions.rebalancing.selectAction !== 'Terminate' ? rebalancingCallNoteObj['Fund Details'] = fundDetails : null;
      this.fundTransactions.rebalancing.dateTime ? rebalancingCallNoteObj['Date And Time'] = this.fundTransactions.rebalancing.dateTime : null;
      Object.assign(OcInfraModule.AppInjector.get(CommonService).allTransactions, { 'REBALANCING': rebalancingCallNoteObj });
    }
    if (this.fundTransactions.dcaValues) {
      let dcaCallNoteObj = {};
      dcaCallNoteObj['Confirmation Number'] = this.confirmationNumber;
      dcaCallNoteObj['Requested Date And Time'] = requestedDateTime;
      dcaCallNoteObj['Confirmation Date And Time'] = confirmationDateTime;
      dcaCallNoteObj['Effective Date Of Transaction'] = moment(this.fundTransactions.dcaValues.effectiveDate).format('MM/DD/YYYY');
      dcaCallNoteObj['Type Of Transaction'] = 'Dollar Cost Averaging Change';
      dcaCallNoteObj['Transfer Frequency'] = this.fundTransactions.dcaValues.transferFrequency;
      this.fundTransactions.dcaValues.noOfTransfers && this.fundTransactions.dcaValues.noOfTransfers !== '' ? dcaCallNoteObj['Number Of Transfers'] = this.fundTransactions.dcaValues.noOfTransfers : null;
      dcaCallNoteObj['Begin Date'] = this.fundTransactions.dcaValues.beginDate;
      this.fundTransactions.dcaValues.endDate && this.fundTransactions.dcaValues.endDate !== '' ? dcaCallNoteObj['End Date'] = this.fundTransactions.dcaValues.endDate : null;
      dcaCallNoteObj['Transfer Type'] = this.fundTransactions.dcaValues.transferType;
      let fundValues = this.fundTransactions.dcaValues.selectedFunds;
      let fundDetails = [];
      if (fundValues && fundValues.length > 0) {
        fundValues.forEach(fund => {
          const fundValue = {};
          fundValue['Fund Number'] = fund['number'];
          fundValue['Fund Name'] = fund['name'];
          if (this.fundTransactions.dcaValues.transferType == 'Dollar Transfer') {
            (fund['from_to_indicator'] == 'F') ? fundValue['From Amount'] = this.formatCurrency(fund['amount']) : fundValue['To Amount'] = this.formatCurrency(fund['amount']);
          } else if (this.fundTransactions.dcaValues.transferType == 'Percent') {
            (fund['from_to_indicator'] == 'F') ? fundValue['From Percent'] = fund['percent'] + '%' : fundValue['To Percent'] = fund['percent'] + '%';
          } else if (this.fundTransactions.dcaValues.transferType == 'Units') {
            (fund['from_to_indicator'] == 'F') ? fundValue['Units'] = fund['fromUnits'] : fundValue['To Percent'] = fund['toPercent'];
          }
          fundDetails.push(fundValue);
        });
      }
      dcaCallNoteObj['Fund Details'] = fundDetails;
      this.fundTransactions.dcaValues.dateTime ? dcaCallNoteObj['Date And Time'] = this.fundTransactions.rebalancing.dateTime : null;
      Object.assign(OcInfraModule.AppInjector.get(CommonService).allTransactions, { 'DOLLAR COST AVERAGING': dcaCallNoteObj });
    }
    if (this.fundTransactions.performanceLock) { // Performance Lock
      let performanceLockObj = {};
      performanceLockObj['Confirmation Number'] = this.confirmationNumber;
      performanceLockObj['Requested Date And Time'] = requestedDateTime;
      performanceLockObj['Confirmation Date And Time'] = confirmationDateTime;
      performanceLockObj['Effective Date Of Transaction'] = moment(this.fundTransactions.performanceLock.effectiveDate).format('MM/DD/YYYY');
      performanceLockObj['Type Of Transaction'] = this.fundTransactions.performanceLock.transferType;

      let fundValues = this.fundTransactions.performanceLock.selectedPerformance;
      let fundDetails = [];
      if (fundValues && fundValues.length > 0) {
        fundValues.forEach(fund => {
          const fundValue = {};
          fundValue['Fund Number'] = fund['number'];
          fundValue['Fund Name'] = fund['name'];
          fundValue['Initial Index Value'] = (fund['initial_index_factor'] * 10000);
          fundValue['Status'] = fund['status'];
          fundValue['Termination End Date'] = fund['termination_end_date'];
          fundValue['Target Index Value'] = fund['target_index_value'];
          fundValue['Index Value Lock Date'] = fund['index_val_lock_date'];
          fundDetails.push(fundValue);
        });
      }
      performanceLockObj['Fund Details'] = fundDetails;
      let performanceLockObjList = OcInfraModule.AppInjector.get(CommonService).allTransactions['PERFORMANCE LOCK'];
      if (performanceLockObjList) {
        performanceLockObjList.push(performanceLockObj);
      } else {
        performanceLockObjList = [performanceLockObj];
      }
      // performanceLockObj['Fund Details'] = fundDetails;
      Object.assign(OcInfraModule.AppInjector.get(CommonService).allTransactions, { 'PERFORMANCE LOCK': performanceLockObjList });
    }
  }

  formatCurrency = function (amount) {
    amount = "$" + amount.replace(/(\d)(?=(\d{3})+(\.(\d){0,2})*$)/g, '$1,');
    if (amount.indexOf('.') === -1)
      return amount + '.00';
    var decimals = amount.split('.')[1];
    return decimals.length < 2 ? amount + '0' : amount;
  };

  formatNumber(value, isAmount) {
    return isAmount ? '$' + value : value + '%';
  }

  onCloseClick() {
    this.scrollTop.emit('scroll');
    if (this.stepCompletionIndicator["fundTransferInformation"] || this.stepCompletionIndicator["futureAllocationChange"] || this.stepCompletionIndicator["rebalancing"] || this.stepCompletionIndicator["dollarCostAveraging"] || this.stepCompletionIndicator["shieldperformancelock"]) {
      let content = 'Make sure to delete any submitted transactions from the Pending transactions tab if no longer wanted.';
      let title = 'Alert';
      let dialogRef = OcInfraModule.AppInjector.get(CommonService).showConfirmationPopup(content, 'Return', 'Continue', title, '340px')
      dialogRef.afterClosed().subscribe(dialogResponse => {
        if (dialogResponse) {
          this.dialog.closeAll();
        }
      })
    }
    else {
      this.dialog.closeAll();
    }
  }


  // target_index_value
  target_index_value = false;
  target_index_value_Method(performance) {
    if (performance.hasOwnProperty('target_index_value')) {
      this.target_index_value = true;
      return true;
    } else false;
  }

  // index_val_lock_date
  index_val_lock_date = false;
  index_val_lock_date_Method(performance) {
    if (performance.hasOwnProperty('index_val_lock_date')) {
      this.index_val_lock_date = true;
      return true;
    } else false;
  }

}
