// Surrender tab constants  start


export type OneOf = {
    title: string
    enum: string[]
}

export interface OneOfData {
    value: string,
    label: string
}

export interface SurrenderValues {
    effective_date: string
    option: string
    optionLabel: string
    mode: string
    modeLabel: string
    no_federal_withholding: string
    federal_withholding_amount: string
    federal_withholding_percentage: string
    federal_withholding_override: boolean
    no_state_withholding: string
    state_withholding_amount: string
    state_withholding_percentage: string
    state_withholding_override: boolean

    payout_amount: string
    payout_percent: string

    distribution: string
    distributionLabel: string
    transaction_level: string
    transactionLevelLabel: string
    end_date: string
    start_next_date: string
    alt_pay1_rem_ind: string
    statePctOrBasis: string
  }

  // Surrender tab constants end



  export interface SurrenderDetails {
    payee_zip: string
    no_federal_withholding: string
    _links: Links
    payee_firstname: string
    next_business_day: string
    mva_amt: number
    policyNumber: string
    business_closehour: number
    surrender_penalty: number
    outstanding_loan_bal: number
    payee_city: string
    redemption_fee: number
    effective_date: string
    free_withdrawal_amount: string
    federal_withholding_override: string
    premiumTax: number
    payment_method: string[]
    loan_payoff_value: number
    no_state_withholding: string
    gross_cash_value: string
    distribution_code: string
    transaction_type: string
    statePctOrBasis: string
    gross_check_amount: string
    surrender_percent: string
    surrender_free_amount: number
    surrender_amount: string
    total_ann_value: number
    fundValues: FundValue[]
    transaction_level: string
    payee_country: string
    admin_fee: string
    _options: Options
    payee_address1: string
    payee_address2: string
    aba_fund_val: number
    payeeName: string
    remaining_GMWB_amount: string
    _embedded: any
    payee_state: string
    max_partial_surrender_value: string
    municipal_withholding_amount: string
    firm_id: string
    taxable_amount: string
    state_withholding_override: string
    net_check_amount: string
    minimum_surrender_benefit: number
    transactionStatus: boolean
    state_withholding_amount: string
    payee_lastname: string
    federal_withholding_amount: string
    accumulated_cashvalue: string
    federal_withholding_percentage: string
    state_withholding_percentage: string
  }
  
  export interface Links {
    self: any
    up: any
    scripts_url: any
    status_report: any
  }
  
  export interface FundValue {
    surrender_unit: string
    fund_value: number
    _links: any
    surrender_percent: string
    fund_number: number
    surrender_amt: string
    fund_name: string
  }
    
  export interface Options {
    properties: any
    required: string[]
    links: any[]
  }

  export const distributionIndicator = {
    'G': () => 'Gross',
    'N': () => 'Net',
    'A': () => 'NetNet'
  };
  
export interface ItemProducts {
    summary: SummaryProducts
    href: string
}

export interface SummaryProducts {
    plan_code: string
    product_code: string
    _links: any
    quote_effective_date: string
    product_type: string
}


export interface ItemAssetAccount {
    summary: SummaryAsset
    href: string
}

export interface SummaryAsset {
    asset_account: string[]
    plan_code: string
    _links: any
    asset_account_names: string[]
}
