import { Component, Input, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { OcInfraModule, ResourceService } from '@diaas/ux-web';
import { CommonService } from '../../../services/common.service';
import { DeathClaimInformationComponent } from './deathClaimInformation/death-claim-information.component';
import { AppDateAdapter, APP_DATE_FORMATS } from '../../format-datepicker';
import {DateAdapter, MAT_DATE_FORMATS} from '@angular/material/core';
export interface dropdown {
  tooltip: any;
  id: string;
  vewValue: string;
}

@Component({
  selector: 'policy-portfolio',
  templateUrl: './policy-portfolio.component.html',
  styleUrls: ['policy-portfolio.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: AppDateAdapter},
    {provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS}
  ]
})

export class PolicyPortfolioComponent implements OnInit {
  // Policy portfolio data getting from parent component
  @Input() portfolioDeathClaimInfo: any;
  @Input() tcaInterestRate: any;
  @Input() stepCompletionIndicator: any;
  @Input() associateClicked: boolean;
  @Input() benefitQuoteClicked: boolean;
  @Output() onSubmitSuccess: EventEmitter<any> = new EventEmitter<any>();
  @Output() fetchClaimPartyDetails: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild(DeathClaimInformationComponent) deathClaimInformationComponent: DeathClaimInformationComponent;

  // Form
  policyPortfolioForm: FormGroup;

  dateOfDeathValue: any;
  currentRate: any;
  guaranteedRate:any;
  teaserRate:any;

  mannerOfDeath: dropdown[];
  countryOfDeath: dropdown[];
  claimType: dropdown[];

  effectiveMaxDate = new Date();
  effectiveMinDate = new Date(1900, 1, 1);
  isDateChange: boolean = false;
  submitButtonDisable: boolean;
  claimSummary: any;
  properties: any;
  showHideAdditionalPolicyAssociate: boolean = false;
  showHideAdditionalPolicyBenefitQuote: boolean = false;
  refreshClaims: boolean = false;
  isNotAnnuity:boolean = false;

  constructor(private _formBuilder: FormBuilder, private datePipe: DatePipe,
    private commonService: CommonService) {
  }

  ngOnInit() {
    // stored data in as an array for showing data in MAT Table
    this.claimSummary = this.portfolioDeathClaimInfo._links.item.claimSummary;
    this.currentRate= this.tcaInterestRate.currentRate ?this.tcaInterestRate.currentRate:'2.00';
    this.guaranteedRate= this.tcaInterestRate.guaranteedRate ? this.tcaInterestRate.guaranteedRate:"";
    this.teaserRate= this.tcaInterestRate.teaserRate ?this.tcaInterestRate.teaserRate:"";
    this.properties = this.portfolioDeathClaimInfo._options.links[0].schema.properties;
    // Transform date
    this.dateOfDeathValue = this.datePipe.transform(this.claimSummary.dateOfDeath, "MM/dd/yyyy");
    this.formDropdownValues();
    this.formInit();
    this.policyPortfolioForm.valueChanges.subscribe(selectedValue => {
      OcInfraModule.AppInjector.get(CommonService).updateSaveButtonStatus(false);
    });
    OcInfraModule.AppInjector.get(CommonService).updateSaveButtonStatus(true);
    // Save and Reset Button Status
    this.commonService.saveButtonStatus.subscribe((isSaveButtonStatus: boolean) => {
      this.submitButtonDisable = isSaveButtonStatus;
    });
  }

  formDropdownValues() {
    // Form select option list
    this.mannerOfDeath = this.properties.mannerOfDeath.oneOf;
    this.countryOfDeath = this.properties.countryOfDeath.oneOf;
    this.claimType = this.properties.claimCategory.oneOf;
  }

  formInit() {
    this.policyPortfolioForm = this._formBuilder.group({
      claimStatus: new FormControl({ value: this.claimSummary.claimStatus, disabled: true }),
      dateOfDeath: new FormControl(new Date(this.dateOfDeathValue)),
      mannerOfDeath: new FormControl(this.claimSummary.mannerOfDeath),
      countryOfDeath: new FormControl(this.claimSummary.countryOfDeath),
      claimType: new FormControl(this.claimSummary.claimCategory)
    })
  }

  dateOfDeathChanged(event) {
    let actualDate = this.datePipe.transform(this.claimSummary.dateOfDeath, "MM/dd/yyyy");
    let changedDate = this.datePipe.transform(event.value, "MM/dd/yyyy");
    if (actualDate == changedDate) {
      this.isDateChange = false;
    }
    else {
      this.isDateChange = true;
    }
  }

  reset() {
    this.policyPortfolioForm.patchValue({
      claimStatus: this.claimSummary.claimStatus,
      dateOfDeath: new Date(this.dateOfDeathValue),
      mannerOfDeath: this.claimSummary.mannerOfDeath,
      countryOfDeath: this.claimSummary.countryOfDeath,
      claimType: this.claimSummary.claimCategory
    });
    this.isDateChange = false;
    OcInfraModule.AppInjector.get(CommonService).updateSaveButtonStatus(true);
  }

  saveButton() {
    let details = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyDetails');
    details.data.product_type.includes('A') ? this.isNotAnnuity= false: this.isNotAnnuity = true;
    if (this.isDateChange && this.isNotAnnuity) {     
      if(new Date(details.data.issue_date) >= this.policyPortfolioForm.controls['dateOfDeath'].value){
        OcInfraModule.AppInjector.get(CommonService).showConfirmationPopup('Date Of Death must be greater than Policy Effective Date ', ' ', 'ok', 'alert', '300px');
      }else{  
      let dialogRef = OcInfraModule.AppInjector.get(CommonService).showConfirmationPopup('Death Benefit Quote Request may have already been processed for this Policy. All prior adjustments will be lost. Do you want to continue?', 'NO', 'YES', 'Benefit Quote Warning', '400px');
      dialogRef.afterClosed().subscribe(dialogResponse => {
        if (!dialogResponse) {
          this.reset();
        }
        else {
          this.onSubmitSuccess.emit();
        }
      });
     }    
    }
    else {
      this.onSubmitSuccess.emit();
    }
  }

  // Method for Confirmation Popup
  confirmationPopup() {
    let dialogRef = OcInfraModule.AppInjector.get(CommonService).showConfirmationPopup('Please make sure you save your changes or the information will be lost', 'Cancel', 'Continue', 'alert', '340px');
    dialogRef.afterClosed().subscribe(res => {

    })
  }

  onSetAssociatedPolicy(e: AssociatedPolicy) {
    if (e.showHideAdditionalPolicyAssociate != undefined) {
      this.showHideAdditionalPolicyAssociate = e.showHideAdditionalPolicyAssociate;
    }

    if (e.showHideAdditionalPolicyBenefitQuote != undefined) {
      this.showHideAdditionalPolicyBenefitQuote = e.showHideAdditionalPolicyBenefitQuote;
    }

    if (e.associateClicked) {
      this.deathClaimInformationComponent.onAssociate();
    }
    if (e.benefitQuoteClicked) {
      this.deathClaimInformationComponent.onBenefitQuote();
    }

    if (e.refreshClaims) {
      this.deathClaimInformationComponent.refreshPolicyClaims();
    }

  }

  getClaimPartylist() {
    this.fetchClaimPartyDetails.emit();
  }
}
