import { Component, OnInit, Inject,ViewChild  } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { OcInfraModule, Configuration } from '@diaas/ux-web';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA,MatPaginator,MatTableDataSource } from '@angular/material';
import { AdvGrowlService } from 'primeng-advanced-growl';
import * as _ from 'lodash';
import { NgxXml2jsonService } from 'ngx-xml2json';
export interface DialogData {
  fileSize: string;
  fileName: string;
}

@Component({
  selector: 'knowledgeCenter-table',
  templateUrl: './knowledgeCenterTable.component.html',
})

export class KnowledgeCenterTableComponent implements OnInit {

  displayedColumns: string[] = ['Key'];
  documentList=new MatTableDataSource([]);
  fileSize: string;
  fileName: string;
  documentRes: Blob;
  s3URL :string;

  @ViewChild(MatPaginator) public paginator: MatPaginator;

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.documentList.paginator = mp;
    
  }

  constructor(private http: HttpClient, private dialog: MatDialog, private ngxXml2jsonService: NgxXml2jsonService) { };

  ngOnInit() {
   let userDocuments = OcInfraModule.AppInjector.get(CommonService).csrUser ? 'csrDocuments' : 'olsCsrDocuments' 
    const URL = _.filter(Configuration.config.hostURL.multiHostUrl, function (o) { return o.alias === userDocuments });
    this.s3URL=URL[0].url;
    this.getcorrespondence(URL[0].url);
    this.documentList.paginator=this.paginator;
  }

  private getcorrespondence(documentURL) {
    const headers = Configuration.config.headers.defaultHeader;
    this.http.get(documentURL, {headers, responseType: 'text' }).subscribe(response => {
      var xmlString = response;
      var parser = new DOMParser();
      var xml = parser.parseFromString(xmlString, "text/xml");
      const obj = this.ngxXml2jsonService.xmlToJson(xml);
      if(obj['ListBucketResult'].Contents.length > 0){
        this.documentList.data = obj['ListBucketResult'].Contents;
      }else{
        // Commented this code because response got changed now documents/ is not coming in key attribute.
        // var key = "documents/";
        // obj['ListBucketResult'].Contents.Key= key.concat(obj['ListBucketResult'].Contents.Key);        

        var tempDocArray =[];
        tempDocArray.push(obj['ListBucketResult'].Contents);
        this.documentList.data = tempDocArray;
      }
    });
 }

  public onRowClick(data, rowData) {
    var documentURL =this.s3URL+rowData.Key;
    const headers = Configuration.config.headers.defaultHeader;
    this.http.get(documentURL, { headers, responseType: 'blob' }).subscribe(response => {        
      this.documentRes = response;
      this.fileSize =  (this.documentRes.size/1024).toFixed(2) +' KB';   
      var a = document.createElement("a");
      var a = document.createElement("a");
      a.href = URL.createObjectURL(this.documentRes);
      a.target = 'blank';
      a.click();
      a.remove();
    });
  }
}