import { DatePipe, CurrencyPipe } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { OcInfraModule, ResourceService } from '@diaas/ux-web';
import * as moment from 'moment';
import { CommonService, retryOperation } from '../../../../services/common.service';

import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { AppDateAdapter, APP_DATE_FORMATS } from '../../../format-datepicker';
import { ItemAssetAccount, ItemProducts, QuoteFormFieldModel, SelectedFormValue, defaultFormFields, quoteSectionOneId, sectionOneFormFields, sectionTwoFormFields } from './partial-surrender-quote.constant';

import { MatCheckboxChange } from '@angular/material/checkbox';
import { AdvGrowlService } from 'primeng-advanced-growl';



@Component({
    selector: 'app-partial-surrender-quote',
    templateUrl: './partial-surrender-quote.component.html',
    styleUrls: ['./partial-surrender-quote.component.scss'],
    providers: [
        { provide: DateAdapter, useClass: AppDateAdapter },
        { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
    ]
})
export class PartialSurrenderQuoteComponent implements OnInit, OnChanges {

    @Input() withdrawlSurrenderType: any;
    @Input() currentPolicyResourceURL: String;
    @Input() headers: Object;
    @Output() quoteValuesToEmit = new EventEmitter<Object>();
    @Output() quoteValuesEvent = new EventEmitter<Object>();

    @Input() surrenderDetails: any;
    @Input() isLife: boolean;

    quotesAPISource: Object = { "PARTIAL_SURRENDER": "/surrender/partial", "FULL_SURRENDER": "/surrender/full", "DIVIDEND_WITHDRAWAL": "/values/diviendWithdrawal" }

    effectivedate: any;
    effectiveMaxDate: any;

    quoteForm: FormGroup;
    quoteInfoFields!: QuoteFormFieldModel[];
    quoteValueFields!: QuoteFormFieldModel[];
    allFormValues: QuoteFormFieldModel[] = [];
    selectedFormValues: SelectedFormValue[] = [];
    formattedInfoFields: any = [];
    formattedValueFields: any = [];
    defaultFormFields!: QuoteFormFieldModel[];

    policyDetails: any;

    clientProducts: ItemProducts[];
    assetAccounts: ItemAssetAccount[];

    clientProductItems: any;
    assetAccountItems: any;

    clientProductsUrl: string = '';
    assetAccountsUrl: string = '';

    retryDelay = 3000;
    retryAttempts = 3;
    isGroupProduct: boolean = false;

    isMrpsContract: boolean = false;
    isHostContract: boolean = false;
    isCv15Contract: boolean = false;
    isGroupMrpsContract: boolean = false;
    effectiveMinDate = new Date(1900, 1, 1);



    constructor(private datePipe: DatePipe, private currencyPipe: CurrencyPipe, private formBuilder: FormBuilder, private commonService: CommonService) { }

    ngOnInit() {
        localStorage.removeItem('surrender-quote-values');

        this.policyDetails = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyDetails');

        this.isGroupMrpsContract = (this.policyDetails.response.product_type == 'A3' || this.policyDetails.response.product_type == 'A4') ? true :false;
        this.isCv15Contract = this.policyDetails.response.isCv15Contract ? true : false;
        this.isMrpsContract = this.policyDetails.response.isMrpsContract ? true : false;
        this.isHostContract = this.policyDetails.response.isHostContract ? true : false;

        this.quoteFormInit();
        if (this.isCv15Contract || this.isMrpsContract || this.isHostContract) { // CV15 
            if (this.isGroupMrpsContract) {
                this.processA4Product();
                this.quoteForm.addControl("productType", new FormControl("", [Validators.required]));
                this.quoteForm.addControl("accountType", new FormControl("", [Validators.required]));
            }

            this.quoteForm.get('effective_date').setValue(new Date(this.policyDetails.response.aart_date));
            this.effectivedate = this.quoteForm.get('effective_date').value;
            this.effectiveMaxDate = new Date();

            this.quoteInfoFields = sectionOneFormFields();
            this.quoteValueFields = sectionTwoFormFields();
            this.defaultFormFields = defaultFormFields();
            this.allFormValues = [...this.quoteInfoFields, ...this.quoteValueFields];
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.surrenderDetails.currentValue) {

            if (this.isMrpsContract || this.isCv15Contract || this.isHostContract) { // MRPS
                this.surrenderDetails = changes.surrenderDetails.currentValue;
                this.effectivedate = this.quoteForm.get('effective_date').value;
                this.prepareInfoFormControls();
                this.prepareValueFormControls();
                this.allFormValues = [...this.quoteInfoFields, ...this.quoteValueFields];
                this.showRequestedDataDefault();
            }
        }
    }

    showRequestedDataDefault = () => {
        this.defaultFormFields.map((formItem: any) => {
            if (formItem.id == 'withdraw_amount') {
                formItem.value = this.formatCurrency('', 'blur', this.quoteForm.get('withdraw_amount').value)
            }
            return formItem;
        })
      this.allFormValues = [...this.quoteInfoFields, ...this.quoteValueFields, ...this.defaultFormFields];
      this.defaultFormFields.map((formItem: any) => {
            let checkbox = new MatCheckboxChange();
            checkbox.checked = true;
            this.changeEvent(checkbox, formItem.id)
        })
        this.showRequestedData();
    }


    quoteFormInit() {
        this.quoteForm = this.formBuilder.group({
            effective_date: ['', [Validators.required]],
            net_gross_indicator: ['G', Validators.required],
            withdraw_amount: ['', Validators.required],
            federal_withholding_override: [false],
            state_withholding_override: [false],

            effective_date_check: [''],
            withdraw_amount_check: [''],
            federal_withholding_override_check: [false],
            state_withholding_override_check: [false]
        });
        this.effectivedate = this.quoteForm.get('effective_date').value;
        this.effectiveMaxDate = new Date();



    }

    loadFormData = () => {

        if (this.surrenderDetails.effective_date != undefined) {
            this.quoteForm.get('effective_date').setValue(new Date(this.surrenderDetails.effective_date));
        }

        this.effectivedate = this.quoteForm.get('effective_date').value;
        this.effectiveMaxDate = new Date();

        if (this.surrenderDetails.federal_withholding_override != undefined && this.surrenderDetails.federal_withholding_override != '') {
            let federal_withholding_override = this.surrenderDetails.federal_withholding_override == 'N' ? false : true;
            this.quoteForm.get('federal_withholding_override').setValue(federal_withholding_override);
        }

        if (this.surrenderDetails.state_withholding_override != undefined && this.surrenderDetails.state_withholding_override != '') {
            let state_withholding_override = this.surrenderDetails.state_withholding_override == 'N' ? false : true;
            this.quoteForm.get('state_withholding_override').setValue(state_withholding_override);
        }

    }

    changeEvent($event: MatCheckboxChange, id: string) {
        let effectiveDate = moment(this.effectivedate).format('MM/DD/YYYY');

        const quoteData = this.allFormValues.filter((item) => item.id === id)[0];

        let unique = `${effectiveDate}-${quoteData.id}`;
        quoteData['unique'] = unique;
        quoteData['key'] = `${effectiveDate}`;

        let existsFormValues = JSON.parse(localStorage.getItem('surrender-quote-values'));

        existsFormValues =
            existsFormValues != null && existsFormValues != undefined
                ? existsFormValues
                : [];
        this.selectedFormValues = existsFormValues;

        if ($event.checked) {
            let selectedFormValueExists = this.selectedFormValues.find(item => item.unique == unique);
            if (!selectedFormValueExists) {
                const indexToInsert = this.selectedFormValues.map((item) => item.key).lastIndexOf(effectiveDate);
                if (indexToInsert !== -1) {
                    this.selectedFormValues.splice(indexToInsert + 1, 0, quoteData);
                } else {
                    const effectiveDateData = {
                        id: 'effective_date',
                        label: 'Effective Date',
                        value: `${effectiveDate}`,
                        checked: '',
                        pipe: 'DATE',
                        unique: `${effectiveDate}-effective_date`,
                        key: `${effectiveDate}`,
                    };
                    this.selectedFormValues.push(effectiveDateData);
                    this.selectedFormValues.push(quoteData);
                }
            } else {
                this.selectedFormValues = this.selectedFormValues.map(item => (item.unique === quoteData['unique'] ? { ...item, value: quoteData.value } : item));

            }
        } else {
            this.selectedFormValues = this.selectedFormValues.filter(
                (item) => item.unique !== unique
            );
            const count = this.selectedFormValues.filter((item) => item.key == effectiveDate).length;

            if (count == 1) {
                unique = `${effectiveDate}-effective_date`;
                this.selectedFormValues = this.selectedFormValues.filter((item) => item.unique !== unique);
            }
        }

        localStorage.setItem(
            'surrender-quote-values',
            JSON.stringify(this.selectedFormValues)
        );
        this.showRequestedData();
    }

    prepareInfoFormControls() {
        this.formattedInfoFields = [];
        this.quoteInfoFields.map((formItem, index) => {
            formItem.value = this.surrenderDetails[formItem.id];

            'pipe' in formItem &&
                formItem.pipe == 'CURRENCY' &&
                (formItem.value = this.currencyPipe.transform(this.surrenderDetails[formItem.id], 'USD'));

            'pipe' in formItem && formItem.pipe == 'PERCENTAGE' &&
                (formItem.value = this.surrenderDetails[formItem.id] + '%');

            this.quoteForm.addControl(formItem.id, this.formBuilder.control(this.surrenderDetails[formItem.id]));
        });

        if (this.isCv15Contract) {
            for (var i = 0; i < this.quoteInfoFields.length; i += 3) {
                let part_slice = this.quoteInfoFields.slice(i, 3 + i);
                this.formattedInfoFields.push(part_slice);
            }
        } else if (this.isMrpsContract) {
            for (var i = 0; i < this.quoteInfoFields.length; i += 3) {
                let part_slice = this.quoteInfoFields.slice(i, 3 + i);
                this.formattedInfoFields.push(part_slice);
            }
        } else if (this.isHostContract) {
            for (var i = 0; i < this.quoteInfoFields.length; i += 3) {
                let part_slice = this.quoteInfoFields.slice(i, 3 + i);
                this.formattedInfoFields.push(part_slice);
            }
        }
    }
    prepareValueFormControls() {
        this.formattedValueFields = [];
        this.quoteValueFields.map((formItem, index) => {
            formItem.value = this.surrenderDetails[formItem.id];

            'pipe' in formItem &&
                formItem.pipe == 'CURRENCY' &&
                (formItem.value = this.currencyPipe.transform(this.surrenderDetails[formItem.id], 'USD'));

            'pipe' in formItem && formItem.pipe == 'PERCENTAGE' &&
                (formItem.value = this.surrenderDetails[formItem.id] + '%');

            this.quoteForm.addControl(formItem.id, this.formBuilder.control(this.surrenderDetails[formItem.id]));
        });
        if (this.isCv15Contract) {
            for (var i = 0; i < this.quoteValueFields.length; i += 2) {
                let part_slice = this.quoteValueFields.slice(i, 2 + i);
                this.formattedValueFields.push(part_slice);
            }
        } else if (this.isMrpsContract) {
            for (var i = 0; i < this.quoteValueFields.length; i += 3) {
                let part_slice = this.quoteValueFields.slice(i, 3 + i);
                this.formattedValueFields.push(part_slice);
            }
        } else if (this.isHostContract) {
            for (var i = 0; i < this.quoteValueFields.length; i += 3) {
                let part_slice = this.quoteValueFields.slice(i, 3 + i);
                this.formattedValueFields.push(part_slice);
            }
        }
    }


    showRequestedData() {
        let html = '';

        const requestedDetails = this.selectedFormValues.reduce((acc, { key, label, value }) => {
            acc[key] = { ...acc[key], [label]: value };
            html += `<dt class="pull-left">` + label + `:</dt><dd>&nbsp; ` + value + `</dd>`
            return acc;
        }, {});

        let quoteDetailsHeadHtml = (this.selectedFormValues.length > 0) ? `<span style="font-size:15px" class="pad-lAr-10px">QUOTE</span><dl class="pad-lAr-10px">` + html + `</dl>` : '';
        OcInfraModule.AppInjector.get(CommonService).quoteDetailsHtml = quoteDetailsHeadHtml;
        OcInfraModule.AppInjector.get(CommonService).showRequestedDetails();
        OcInfraModule.AppInjector.get(CommonService).quoteValues = (this.selectedFormValues.length > 0) ? { ['QUOTE WITHDRAWAL']: requestedDetails } : {};
    }

    onQuoteValuesClick(event?: any) {

        if (this.isStepComplete()) {
            let quoteEndpoint = this.currentPolicyResourceURL + this.quotesAPISource[this.withdrawlSurrenderType] + "?" + this.getQueryParams();
            if (this.isCv15Contract || this.isMrpsContract || this.isHostContract) {
                if(this.isGroupProductType()){
                let plan_code = this.quoteForm.controls.productType.value;
                let asset_account = this.quoteForm.get('accountType').value?this.quoteForm.get('accountType').value:"**";
                if (asset_account == "**") {
                    asset_account = '**';
                }  else {
                    asset_account = (asset_account == '**')?'**':asset_account.substring(0, asset_account.indexOf(' '));
                }
                this.headers['asset_account'] = asset_account;
                this.headers['plan_id'] = plan_code;
                this.headers['plan_code'] = plan_code;
            }
                let params = { url: quoteEndpoint, headers: this.headers }
                this.quoteValuesEvent.emit(params)
            }
        }
    }

    getPartialSurrenderQuote = (url: string) => {
        OcInfraModule.AppInjector.get(CommonService).getCallback(url, this.headers).subscribe(response => {
            this.surrenderDetails = response;
            this.effectivedate = this.quoteForm.get('effective_date').value;

            this.prepareInfoFormControls();
            this.prepareValueFormControls();

                this.defaultFormFields.map((formItem: any) => {
                    if (formItem.id == 'withdraw_amount') {
                        formItem.value = this.formatCurrency('', 'blur', this.quoteForm.get('withdraw_amount').value)
                    }
                    return formItem;
                })
              this.allFormValues = [...this.quoteInfoFields, ...this.quoteValueFields, ...this.defaultFormFields];
              this.defaultFormFields.map((formItem: any) => {
                    let checkbox = new MatCheckboxChange();
                    checkbox.checked = true;
                    this.changeEvent(checkbox, formItem.id)
                })
            this.showRequestedData();
            this.sendQuoteValuesToParent(response);

        }, error => {
             if (error && error._embedded && error._embedded.status_report && error._embedded.status_report.consistent === false) {
                        OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error._embedded.status_report.messages[0].message, 'Error', 0);
                    }
        });

    }

    isStepComplete() {
        return this.quoteForm.valid;
    }

    getQueryParams() {
        let params = new HttpParams();
        let effectiveDate = this.quoteForm.get('effective_date').value;
        let withdraw_amount = this.quoteForm.get('withdraw_amount').value;
        withdraw_amount = withdraw_amount.replaceAll(',', '');

        // withdraw_amount = withdraw_amount != '0' ? withdraw_amount.slice(1) : '0'
        withdraw_amount = withdraw_amount[0]=="$" ? withdraw_amount.slice(1) : withdraw_amount;
        withdraw_amount = withdraw_amount != '0' ? withdraw_amount : '0';
        
        let net_gross_indicator = this.quoteForm.get('net_gross_indicator').value;
        let federal_withholding_override = this.quoteForm.get('federal_withholding_override').value;
        let state_withholding_override = this.quoteForm.get('state_withholding_override').value;
        params = params.set('quoteEffectiveDate', this.datePipe.transform(effectiveDate, "MM/dd/yyyy"))
            .set('quote', 'Y')
            .set('amount', withdraw_amount)
            .set('gNetInd', net_gross_indicator)

        if (federal_withholding_override) {
            params = params.append('fedInd', federal_withholding_override)
        }
        if (state_withholding_override) {
            params = params.append('stInd', state_withholding_override)
        }

        return params.toString();
    }

    sendQuoteValuesToParent(res) {
        let quoteDetails = {};
        Object.assign(quoteDetails, res)
        this.quoteValuesToEmit.emit(quoteDetails)
    }



    isGroupProductType = () => {
        let product_type = this.getNested(this.policyDetails, 'response', 'product_type');
        if (product_type && (product_type == "A3" || product_type == "A4")) {
            return true;
        } else {
            return false;
        }
    }


    //formats the value with commas and $ allowing 2 decimal values
    formatCurrency(input, blur, respValue) {
        var input_val = "";
        if (respValue == '' || respValue == undefined) {
            input_val = input.target.value;
        } else {
            input_val = respValue;
        }
        if (input_val === "") { return; }
        var original_len = input_val.length;
        if (input_val.indexOf(".") >= 0) {
            var decimal_pos = input_val.indexOf(".");
            decimal_pos = decimal_pos > 15 ? 15 : decimal_pos;
            var left_side = input_val.substring(0, decimal_pos);
            var right_side = input_val.substring(decimal_pos);
            left_side = this.formatNumber(left_side, 'currency', true);
            right_side = this.formatNumber(right_side, 'currency', false);
            if (blur === "blur") {
                right_side += "00";
            }
            // Limit decimal to only 2 digits
            right_side = right_side.substring(0, 2);
            // join number by .
            input_val = "$" + left_side + "." + right_side;

        } else {
            // no decimal entered
            // add commas to number
            // remove all non-digits
            input_val = input_val.substring(0, 15);
            input_val = this.formatNumber(input_val, 'currency', true);
            input_val = "$" + input_val;
            // final formatting
            if (blur === "blur") {
                input_val += ".00";
            }
        }
        if (input_val.charAt(input_val.indexOf('$') + 1) == '.') {
            input_val = input_val.replace(input_val.charAt(input_val.indexOf('$') + 1), '0.');
        }
        if (respValue == '' || respValue == undefined) {
            input.target["value"] = input_val;
        } else {
            return input_val;
        }
    }

    // formats the value with commas and percentage allowing 1 decimal value
    formatPercent(input, blur, respValue) {
        var input_val = "";
        if (respValue == '' || respValue == undefined) {
            input_val = input.target.value;
        } else {
            input_val = respValue;
        } if (input_val === "") { return; }
        var original_len = input_val.length;
        if (input_val.indexOf(".") >= 0) {
            var decimal_pos = input_val.indexOf(".");
            var left_side = input_val.substring(0, decimal_pos);
            var right_side = input_val.substring(decimal_pos);
            left_side = this.formatNumber(left_side, 'percent', true);
            right_side = this.formatNumber(right_side, 'percent', false);
            if (blur === "blur") {
                right_side += "00";
            }
            // Limit decimal to only 2 digits
            right_side = right_side.substring(0, 2);
            // join number by .
            input_val = left_side + "." + right_side + "%";
        } else {
            // no decimal entered
            // add commas to number
            // remove all non-digits
            input_val = this.formatNumber(input_val, 'percent', true);
            if (blur === "blur") {
                input_val += ".00";
            }
            input_val = input_val + "%";
        }
        if (input_val.charAt(input_val.indexOf('$') + 1) == '.') {
            input_val = input_val.replace(input_val.charAt(input_val.indexOf('$') + 1), "0.")
        }
        if (respValue == '' || respValue == undefined) {
            input.target["value"] = input_val;
        } else {
            return input_val;
        }
    }

    /** AMOUNT AND PERCENT FORMATING  */
    formatNumber(n, type, flag) {
        if (flag) {
            n = n.charAt(0) == '0' ? n.replace(n.charAt(0), "") : n;
        }
        if (type == 'currency') {
            return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } else {
            return n.replace(/\D/g, "");
        }
    }

    getNested(obj, ...args) {
        return args.reduce((obj, level) => obj && obj[level], obj)
    }

    processA4Product = async () => {
        this.clientProductsUrl = this.policyDetails && this.policyDetails._links && this.policyDetails._links.clientproducts ? this.policyDetails._links.clientproducts.href : null;
        this.assetAccountsUrl = this.policyDetails && this.policyDetails._links && this.policyDetails._links.clientassetaccounts ? this.policyDetails._links.clientassetaccounts.href : null;

        try {
            let assetAccounts = await this.commonService.getClientAssetAccountsAll();
            if (!assetAccounts) {
                this.assetAccounts = assetAccounts;
            } else {
                assetAccounts = await this.commonService.getAssetAccountsApi(this.assetAccountsUrl, this.headers)
                this.assetAccounts = assetAccounts;
            }

            let clientProducts = await this.commonService.getClientProducts();
            if (!clientProducts) {
                this.commonService.clientProducts = clientProducts;
                this.clientProducts = clientProducts;
                this.processProductTypes(this.clientProducts);
            } else {
                let clientProducts = await this.commonService.getClientProductsApi(this.clientProductsUrl, this.headers)
                this.clientProducts = clientProducts;
                this.processProductTypes(this.clientProducts);
            }

        } catch (error) {
        }
    }

    processProductTypes = (clientProducts: ItemProducts[]) => {
        if (clientProducts.length > 0) {
            this.clientProductItems = clientProducts.map((item: any) => {
                return { label: item.summary.plan_code, value: item.summary.plan_code }
            });
        }
        this.clientProductItems.unshift({ label: 'ALL PRODUCTS', value: 'AP' });
        this.quoteForm.get('productType').setValue('AP');

        if (this.clientProductItems.length === 1) {
            this.productTypeChange(this.clientProductItems[0].value)
        }
        this.quoteForm.controls['accountType'].disable();
    }

    productTypeChange = (value: any) => {
        this.quoteForm.controls['accountType'].enable();
        this.quoteForm.get('productType').setValue(value);
        let assetAccounts = this.assetAccounts.find((item: ItemAssetAccount) => item.summary.plan_code == value);
        this.assetAccountItems = assetAccounts.summary.asset_account_names.filter((item: any) => {
            return (item != '- null') && { value: item, label: item }
        }).map((item: any) => ({
            value: item === 'ALL ASSET ACCOUNTS' ? '**' : item,
            label: item
        }));
    }

    accountTypeChange = (value: any) => {
        this.quoteForm.get('accountType').setValue(value);
        let plan_code = this.quoteForm.controls.productType.value;

        this.headers = { ...this.headers, ...{ plan_id: plan_code, client_id: this.commonService.productInfo.client, asset_account: value.split('-')[0].trim() } }

    }


}
