import { Component, OnInit } from '@angular/core';
import { OcInfraModule, APICallerService, Configuration, ResourceService } from '@diaas/ux-web';
import { CommonService } from '../../services/common.service';
import { MatDialog } from '@angular/material';
import { FormControl, FormBuilder, FormGroup } from '@angular/forms';
import { AdvGrowlService } from 'primeng-advanced-growl';
import { DecimalPipe } from '@angular/common';
import { CurrencyPipe } from '@angular/common';
import * as _ from 'lodash';
import { AppDateAdapter, APP_DATE_FORMATS } from '../format-datepicker';
import {DateAdapter, MAT_DATE_FORMATS} from '@angular/material/core';

@Component({
   selector: 'ace-formrequest',
   templateUrl: './aceFormRequest.component.html',
   styleUrls: ['./aceFormRequest.component.scss'],
   providers: [
      {provide: DateAdapter, useClass: AppDateAdapter},
      {provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS}
    ]
})

export class AceFormRequestComponent implements OnInit {
   stopReissueCheck: FormGroup;
   stopReissueCheckUL: FormGroup;
   stopReissueCheckAnn: FormGroup;
   StopPaymentPreConvCheck: FormGroup;
   StopPaymentPreConvCheckUL: FormGroup;
   voidPaymentRequestCheckReissue: FormGroup;
   stopPaymentRequestSuspenceFunds: FormGroup;
   voidPaymentRequestSuspenceFunds: FormGroup;
   checkCopy: FormGroup;
   complexCorrespondenceF: FormGroup;
   complexCorrespondenceV: FormGroup;
   fundTransfer: FormGroup;
   minDisEnrollment: FormGroup;
   minDisWithdrawal: FormGroup;
   partialWithdrawal: FormGroup;
   surrender: FormGroup;
   systematicWithdrawal: FormGroup;
   GrpFundtransfer: FormGroup;
   grpMindistEnrollment: FormGroup;
   grpminiDisWithdrawal: FormGroup;
   grpPartialwithdrawal:FormGroup;
   grpSurrender: FormGroup;
   grpSystematicwithdrawal: FormGroup;
   emailAddress: string = '';
   batchOrEmail: string = 'Batch';
   disableButton: boolean = false;
   isRetailAnnuity: boolean = false;
   isGroupAnnuity: boolean = false;
   fullName: any;
   constructor(private dialog: MatDialog, private growlService: AdvGrowlService, private formBuilder: FormBuilder, private decimalPipe: DecimalPipe, private currencyPipe: CurrencyPipe) { };
   formChekProperties: any = OcInfraModule.AppInjector.get(CommonService).formsList;
   checkProperties: any = this.formChekProperties;
   selectedFormRequest = [];
   selectedFormRequestDesc = [];
   checked: boolean = false;
   selectedRoleSummary: any;
   addComment: string = '';
   hoverMessage: string = '';
   disableCreate: boolean = true;
   authsucess: any = OcInfraModule.AppInjector.get(CommonService).authsucess;
   otherRoleSucess: any = OcInfraModule.AppInjector.get(CommonService).otherRoleAuthsucess;
   registerForm: any;
   disableException: boolean = true;
   disableEmailAdressField:boolean = true;
   exceptionProcessing: boolean = false;
   emailIdCheckbox: boolean = false;
   role: boolean = false;
   commentRequired: boolean = true;
   emailClass: boolean = true;
   disableField: boolean = false;
   selectedWorkItemType: any = OcInfraModule.AppInjector.get(CommonService).selectedWorkItemType;
   authorizationCheck: boolean = false;
   message: string;
   check: boolean = false;
   schema: any;
   myValue: string;
   maxlengthValue: string = "13";
   maxDateNew = new Date();
   isAttachDoc: boolean = false;
   showAttach: boolean = true;
   isWorkNotCompleted: boolean = false;
   isAnnuity: boolean = false;
   isLife:boolean=false;
   comments: any;

   faxNumber: string = '';
   faxClass: boolean = true;
   disableFaxNumberField:boolean = true;
   faxNumberCheckbox: boolean = false;
   isFaxNumberValid = false;

   emailAddressChecked: boolean = false;
   faxNumberChecked: boolean = false;

   formatPostalOnPaste(e, selectedForm){
      this.formatPostalCode(e);
      this.formatActualPostalCode(e, selectedForm);
   }

   formatZipOnPaste(e, selectedForm){
      this.formatPostalCode(e);
      this.formatActualZipCode(e, selectedForm);
   }

   formatPostalCode(e) {
      if(e.keyCode > 31 && ((e.keyCode < 48 || e.keyCode > 57) && (e.keyCode < 96 || e.keyCode > 105)) && e.keyCode != 46){
         e.preventDefault();
      }else{
         e.target["value"] = e.target["value"].replace(/[^0-9]/gi, '');
         e.target['value'] = e.target["value"].replace(/(\d{5}(?!\d?$))\-?/g, '$1-');
      }     
    }

   numbersOnly(e) {
      e.keyCode > 31 && (e.keyCode < 48 || e.keyCode > 57) ? e.preventDefault() : '';
   }

   formatAmount(e) {      
      if(e.keyCode == 46 && e.target.value.includes(".")){
         e.preventDefault();
         return false;
      }
      var character = String.fromCharCode(e.keyCode)
      var newValue = e.target.value + character;
        if (isNaN(newValue as any) || this.hasDecimalPlace(newValue, 3)) {
            e.preventDefault();
            return false;
        }
      e.keyCode != 46 ? e.keyCode > 31 && (e.keyCode < 48 || e.keyCode > 57) ? e.preventDefault() : '' : '';
   }
   hasDecimalPlace(value, x) {
      var pointIndex = value.indexOf('.');
      return  pointIndex >= 0 && pointIndex < value.length - x;
   }

   restrictPaste(event: ClipboardEvent) {
      let clipboardData = event.clipboardData;
      let pastedText = clipboardData.getData('text');
      return /^\d+(\.\d+)?$/.test(pastedText);
   }

   currencyFormat(e) {
      e.keyCode != 46 ? e.keyCode > 31 && (e.keyCode < 48 || e.keyCode > 57) ? e.preventDefault() : '' : '';
      let finalNum = e.target.value + e.key;
      this.maxlengthValue = finalNum.indexOf(".") != -1 ? "16" : "13";
   }

   format(element, obj, fieldName) {
      let num = obj.value[fieldName] && obj.value[fieldName].replace(/\,/g, '').replace(/\$/g, '');
      element.target.value = num ? num : '';
      this.maxlengthValue = obj.value[fieldName] && obj.value[fieldName].indexOf(".") != -1 ? "16" : "13";
   }

   transformAmount(element, obj, fieldName) {
      let num = obj.value[fieldName].replace(/\,/g, '').replace(/\$/g, '');
      let newnum = this.currencyPipe.transform(num, '$');
      element.target.value = newnum;
   }

   cannotLocateCheckInfo(e, name) {
      let event = e.checked ? 'disable' : 'enable';
      this[name].get('OCMP') ? this[name].get('OCMP').reset() ? '' : this[name].get('OCMP')[event]({ emitEvent: true }) : '';
      this[name].get('OSRC') ? this[name].get('OSRC').reset() ? '' : this[name].get('OSRC')[event]({ emitEvent: true }) : '';
      this[name].get('OAMT') ? this[name].get('OAMT').reset() ? '' : this[name].get('OAMT')[event]({ emitEvent: true }) : '';
      this[name].get('OCDT') ? this[name].get('OCDT').reset() ? '' : this[name].get('OCDT')[event]({ emitEvent: true }) : '';
      this[name].get('OPAY') ? this[name].get('OPAY').reset() ? '' : this[name].get('OPAY')[event]({ emitEvent: true }) : '';
      this[name].get('CHK1') ? this[name].get('CHK1').reset() ? '' : this[name].get('CHK1')[event]({ emitEvent: true }) : '';
      this[name].get('ADTY') ? this[name].get('ADTY').reset() ? '' : this[name].get('ADTY')[event]({ emitEvent: true }) : '';
      this[name].get('CAM2') ? this[name].get('CAM2').reset() ? '' : this[name].get('CAM2')[event]({ emitEvent: true }) : '';
      this[name].get('NAM1') ? this[name].get('NAM1').reset() ? '' : this[name].get('NAM1')[event]({ emitEvent: true }) : '';
      this[name].get('ADD1') ? this[name].get('ADD1').reset() ? '' : this[name].get('ADD1')[event]({ emitEvent: true }) : '';
      this[name].get('ADD2') ? this[name].get('ADD2').reset() ? '' : this[name].get('ADD2')[event]({ emitEvent: true }) : '';
      this[name].get('ADD3') ? this[name].get('ADD3').reset() ? '' : this[name].get('ADD3')[event]({ emitEvent: true }) : '';
      this[name].get('CITY') ? this[name].get('CITY').reset() ? '' : this[name].get('CITY')[event]({ emitEvent: true }) : '';
      this[name].get('STC1') ? this[name].get('STC1').reset() ? '' : this[name].get('STC1')[event]({ emitEvent: true }) : '';
      this[name].get('deliveryMethod') ? (e.checked ?this[name].get('deliveryMethod').reset():this[name].get('deliveryMethod').setValue(this.schema.properties.deliveryMethod.oneOf[1].enum)) ?  '' : this[name].get('deliveryMethod')[event]({ emitEvent: true }) : '';
      this[name].get('phone') ? this[name].get('phone').reset() ? '' : this[name].get('phone')[event]({ emitEvent: true }) : '';
      this[name].get('draftDay') ? this[name].get('draftDay').reset() ? '' : this[name].get('draftDay')[event]({ emitEvent: true }) : '';
      this[name].get('billingAmt') ? this[name].get('billingAmt').reset() ? '' : this[name].get('billingAmt')[event]({ emitEvent: true }) : '';
      this[name].get('fax') ? this[name].get('fax').reset() ? '' : this[name].get('fax')[event]({ emitEvent: true }) : '';
      this[name].get('ZIP1') ? this[name].get('ZIP1').reset() ? '' : this[name].get('ZIP1')[event]({ emitEvent: true }) : '';
   }

   onProcess(formObj, status) {
      this.onSubmit(formObj, status);
   }

   onWrkComplete(status){
      OcInfraModule.AppInjector.get(AdvGrowlService).clearMessages();
      const resourceService = OcInfraModule.AppInjector.get(ResourceService);
      const workRequestHref = resourceService.getHrefByAliasName("workRequestHistory").split('?')[0];
      const selectedRoleSummary = resourceService.getResourceFromAliasName('selectedRoles');
      const authResponse = resourceService.getResourceFromAliasName('authRes');
      const headers = OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("workRequestHistory")
      headers.role = OcInfraModule.AppInjector.get(CommonService).selectedRole;
      let policyDetails = resourceService.getResourceFromAliasName('policyDetails');
      let groupAnuity = (policyDetails.data.product_type == "A3" || policyDetails.data.product_type == "A4") ? true : false;
      const payload = {
         "status": status,
         "CLTY": OcInfraModule.AppInjector.get(CommonService).selectedWorkItemType,
         "POLN": groupAnuity ? selectedRoleSummary && selectedRoleSummary.data.policyNumber ? selectedRoleSummary.data.policyNumber: policyDetails.data.policy_number : (selectedRoleSummary && selectedRoleSummary.data.policyNumber ? selectedRoleSummary.data.policyNumber : policyDetails.data.policy_number),
         "hostKey": selectedRoleSummary && selectedRoleSummary.data.hostKey ? selectedRoleSummary.data.hostKey : '',
         "type": OcInfraModule.AppInjector.get(CommonService).selectedWorkItemValue,
         "sessionId": authResponse && authResponse.data.contactSessionID ? authResponse.data.contactSessionID : '',
         "callStart": authResponse && authResponse.data.callStart ? authResponse.data.callStart : '',
         "clientId": selectedRoleSummary && selectedRoleSummary.data.clientId ? selectedRoleSummary.data.clientId : '',
         "userId": OcInfraModule.AppInjector.get(CommonService).incontactId,
         "origin_app": "csr_portal",
         "lob": policyDetails.data.product_type.charAt(0).toUpperCase(),
         "comments": document.getElementById('#workrequestComment')["value"]
      };
      this.createWorkRequest(workRequestHref, payload, headers);
   }

   onSubmit(formObj, workReqStatus) {
      this.isWorkNotCompleted = workReqStatus == 'CREATED' ? true : false;
      OcInfraModule.AppInjector.get(AdvGrowlService).clearMessages();
      const resourceService = OcInfraModule.AppInjector.get(ResourceService);
      const workRequestHref = resourceService.getHrefByAliasName("workRequestHistory").split('?')[0];
      const selectedRoleSummary = resourceService.getResourceFromAliasName('selectedRoles');
      const authResponse = resourceService.getResourceFromAliasName('authRes');
      const headers = OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("workRequestHistory")
      headers.role = OcInfraModule.AppInjector.get(CommonService).selectedRole;
      let policyDetails = resourceService.getResourceFromAliasName('policyDetails');
      let groupAnuity = (policyDetails.data.product_type == "A3" || policyDetails.data.product_type == "A4") ? true : false;
      const payload = {
         "status": workReqStatus,
         "CLTY": OcInfraModule.AppInjector.get(CommonService).selectedWorkItemType,
         "POLN": groupAnuity ? selectedRoleSummary && selectedRoleSummary.data.policyNumber ? selectedRoleSummary.data.policyNumber : policyDetails.data.policy_number : (selectedRoleSummary && selectedRoleSummary.data.policyNumber ? selectedRoleSummary.data.policyNumber : policyDetails.data.policy_number),
         "hostKey": selectedRoleSummary && selectedRoleSummary.data.hostKey ? selectedRoleSummary.data.hostKey : '',
         "type": OcInfraModule.AppInjector.get(CommonService).selectedWorkItemValue,
         "sessionId": authResponse && authResponse.data.contactSessionID ? authResponse.data.contactSessionID : '',
         "callStart": authResponse && authResponse.data.callStart ? authResponse.data.callStart : '',
         "clientId": selectedRoleSummary && selectedRoleSummary.data.clientId ? selectedRoleSummary.data.clientId : '',
         "userId": OcInfraModule.AppInjector.get(CommonService).incontactId,
         "origin_app": "csr_portal",
         "lob": policyDetails.data.product_type.charAt(0).toUpperCase(),
         "ICID": authResponse && authResponse.data.contactID ? authResponse.data.contactID : '',
      };
      let newOAMT = formObj.value.OAMT ? formObj.value.OAMT.replace('$', '') : '';
      let dateofInjury = new Date(formObj.value.dateofInjury);
      let newDateofInjury = dateofInjury.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' });
      let dateReported = new Date(formObj.value.dateReported);
      let newDateReported = dateReported.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' });
      Object.entries(formObj.value).forEach(([key, val]) => {
         key == 'YN01' ? val == '' || val == false ? payload[key] = "YES" : payload[key] = "NO" : val == '' ? '' : payload[key] = val;
         key == "FNAR" ? val == '' ? '' : payload[key] = payload[key][0] : '';
         key == "ADTY" ? val == '' ? '' : payload[key] = payload[key][0].toUpperCase() : '';
         // key == "OAMT" ? val == '' ? '' : OcInfraModule.AppInjector.get(CommonService).otherKey ? '' : payload[key] = OcInfraModule.AppInjector.get(CommonService).oAmount : '';
         key == "dateReported" ? val == '' ? '' : payload[key] = newDateReported : '';
         key == "dateofInjury" ? val == '' ? '' : payload[key] = newDateofInjury : '';
         key == 'OAMT' ? val == '' ? '' : payload[key] = newOAMT : '';
      });
      this.createWorkRequest(workRequestHref, payload, headers)
   }

   selectedform(event, checkedform) {
      if (event.checked) {
         this.selectedFormRequest.push(checkedform.enum[0]);
         this.selectedFormRequestDesc.push(checkedform.title);
      } else {
         this.selectedFormRequest.splice(this.selectedFormRequest.indexOf(checkedform.enum[0]), 1);
         this.selectedFormRequestDesc.splice(this.selectedFormRequestDesc.indexOf(checkedform.title), 1);
      }
      this.validateFormRequestChecked();
      
   }


//    validateFormRequestCheckedNew() {
//     // Hover message
//     if (this.authsucess || this.otherRoleSucess) {
//       if (
//         this.selectedFormRequest.length ||
//         this.exceptionProcessing ||
//         this.emailIdCheckbox ||
//         this.faxNumberCheckbox
//       ) {
//         // both checkbox checked if true
//         if (this.exceptionProcessing && (this.emailIdCheckbox || this.faxNumberCheckbox)) {
//           if (this.addComment && (this.isEmailAddressValid || this.isFaxNumberValid) && this.selectedFormRequest.length > 0) {
//             this.enableCreateButtonBybasedOnResult('', false);
//           } else {
//             this.enableCreateButtonBybasedOnResult(
//               'Enable button by completing required items',
//               true
//             );
//           }
//         } else {
//           // expression if true
//           if (this.exceptionProcessing) {
//             if (this.addComment) {
//               this.enableCreateButtonBybasedOnResult('', false);
//             } else {
//               this.enableCreateButtonBybasedOnResult(
//                 'Enable button by completing required items',
//                 true
//               );
//             }
//           } else {
//             // email address is true and form selected
//             if (this.emailIdCheckbox) {
//               if (
//                 this.isEmailAddressValid &&
//                 this.selectedFormRequest.length > 0
//               ) {
//                 this.enableCreateButtonBybasedOnResult('', false);
//               } else {
//                 this.enableCreateButtonBybasedOnResult(
//                   'Enable button by completing required items',
//                   true
//                 );
//               }
//             } else if (this.faxNumberCheckbox) {
//                 if (
//                   this.isFaxNumberValid &&
//                   this.selectedFormRequest.length > 0
//                 ) {
//                   this.enableCreateButtonBybasedOnResult('', false);
//                 } else {
//                   this.enableCreateButtonBybasedOnResult(
//                     'Enable button by completing required items',
//                     true
//                   );
//                 }
//               } else {
//               this.enableCreateButtonBybasedOnResult('', false);
//             }
//           }
//         }
//       } else {
//         this.enableCreateButtonBybasedOnResult(
//           "Enable by making a Form selection or checking the 'Exception Processing' check box",
//           true
//         );
//       }
//     } else {
//       this.enableCreateButtonBybasedOnResult('Caller was not authorized', true);
//     }
//   }

   // Validate hover message and Create button enable/disable
   validateFormRequestChecked(){
      // Hover message
      this.authsucess || this.otherRoleSucess 
         ? this.selectedFormRequest.length || this.exceptionProcessing || this.emailIdCheckbox || this.faxNumberCheckbox
            // both checkbox checked if true
            ? (this.exceptionProcessing && (this.emailIdCheckbox || this.faxNumberCheckbox)) 
               ? 
                  (this.addComment && (this.isEmailAddressValid || this.isFaxNumberValid) && this.selectedFormRequest.length > 0) ? this.enableCreateButtonBybasedOnResult('',false) 
                  :  this.enableCreateButtonBybasedOnResult('Enable button by completing required items', true)
               // expression if true
               : this.exceptionProcessing ? this.addComment ? this.enableCreateButtonBybasedOnResult('',false) : this.enableCreateButtonBybasedOnResult('Enable button by completing required items', true) 
                // email address is true and form selected
               : this.emailIdCheckbox ? (this.isEmailAddressValid && this.selectedFormRequest.length > 0) ? this.enableCreateButtonBybasedOnResult('',false) : this.enableCreateButtonBybasedOnResult('Enable button by completing required items', true)
               : this.faxNumberCheckbox ? (this.isFaxNumberValid && this.selectedFormRequest.length > 0) ? this.enableCreateButtonBybasedOnResult('',false) : this.enableCreateButtonBybasedOnResult('Enable button by completing required items', true)
               : this.enableCreateButtonBybasedOnResult('',false)
            : this.enableCreateButtonBybasedOnResult("Enable by making a Form selection or checking the 'Exception Processing' check box", true) 
         : this.enableCreateButtonBybasedOnResult('Caller was not authorized', true);
   }
   
   // Enable/Disable and hover meesage -  Create button
   enableCreateButtonBybasedOnResult(hoverMessage, createButtonVisibleStatus){
      this.hoverMessage = hoverMessage;
      this.disableCreate = createButtonVisibleStatus;    
   }


   addCommentChange(event) {
      this.addComment != '' && event.target.value != '' ? this.commentRequired = false : this.commentRequired = true;
      this.validateFormRequestChecked();
   }

   formatComment(selectedForm) {
      selectedForm.controls['comments'].touched = true;
   }

   ngAfterViewInit() {
      this.surrender.controls["comments"].setErrors({ 'required': true });
      this.fundTransfer.controls["comments"].setErrors({ 'required': true });
      this.grpSurrender.controls["comments"].setErrors({ 'required': true });
      this.minDisEnrollment.controls["comments"].setErrors({ 'required': true });
      this.minDisWithdrawal.controls["comments"].setErrors({ 'required': true });
      this.partialWithdrawal.controls["comments"].setErrors({ 'required': true });
      this.systematicWithdrawal.controls["comments"].setErrors({ 'required': true });
      this.GrpFundtransfer.controls["comments"].setErrors({ 'required': true });
      this.grpMindistEnrollment.controls["comments"].setErrors({ 'required': true });
      this.grpminiDisWithdrawal.controls["comments"].setErrors({ 'required': true });
      this.grpPartialwithdrawal.controls["comments"].setErrors({ 'required': true });
      this.grpSystematicwithdrawal.controls["comments"].setErrors({ 'required': true });
      this.StopPaymentPreConvCheck.controls["comments"].setErrors({ 'required': true });
      this.StopPaymentPreConvCheckUL.controls["comments"].setErrors({ 'required': true });
      this.isGroupAnnuity ?this.checkCopy.controls["comments"].setErrors({ 'required': true }) : "" ;
      this.isRetailAnnuity ? this.stopReissueCheckAnn.controls["comments"].setErrors({ 'required': true }) : "";
   }

   formatActualPostalCode(e, selectedForm) {
      selectedForm.controls['ZIP1'].setValue(e.target["value"]);
      if (e.target.value.length > 5 && e.target.value.length < 10) {
         selectedForm.controls["ZIP1"].setErrors({ 'incorrect': true });
      }
      else {
         selectedForm.controls["ZIP1"].setErrors(null);
         selectedForm.controls['ZIP1'].updateValueAndValidity();
      }
   }

   formatActualZipCode(e, selectedForm) {
      selectedForm.controls['zip'].setValue(e.target["value"]);
      if (e.target.value.length > 5 && e.target.value.length < 10) {
         selectedForm.controls["zip"].setErrors({ 'incorrect': true });
      }
      else {
         selectedForm.controls["zip"].setErrors(null);
         selectedForm.controls['zip'].updateValueAndValidity();
      }
   }

   areaCheckChange(e, selectedForm) {
      if (e == 0) {
         selectedForm.controls["FNAR"].setErrors({ 'incorrect': true });
      }
      else {
         selectedForm.controls["FNAR"].setErrors(null);
         selectedForm.controls['FNAR'].updateValueAndValidity();
      }
   }

   deliveryMethodChange(e, selectedForm) {
      if (e == 0) {
         selectedForm.controls["deliveryMethod"].setErrors({ 'incorrect': true });
      }
      else {
         selectedForm.controls["deliveryMethod"].setErrors(null);
         selectedForm.controls['deliveryMethod'].updateValueAndValidity();
      }
   }

   stateChange(e, selectedForm){
      if (e == 0) {
         selectedForm.controls["STC1"].setErrors({ 'incorrect': true });
      }
      else {
         selectedForm.controls["STC1"].setErrors(null);
         selectedForm.controls['STC1'].updateValueAndValidity();
      }
   }

   stateSelectChange(e, selectedForm){
      if (e == 0) {
         selectedForm.controls["state"].setErrors({ 'incorrect': true });
      }
      else {
         selectedForm.controls["state"].setErrors(null);
         selectedForm.controls['state'].updateValueAndValidity();
      }
   }

   formatCommentAnn() {
         this.disableButton = true;
   }

   formatCommAnn(){
      if(document.getElementById('#workrequestComment')["value"] == ''){
         this.disableButton = false;
      }
      else{
         this.disableButton = true;
      }
   }

   disableProcessButton(schema){
      if(schema.processed){
         return false;
      }else return true;
   }
  
   createFormRequestWorkItem(selectedWorkItemType) {
      OcInfraModule.AppInjector.get(AdvGrowlService).clearMessages();
      const resourceService = OcInfraModule.AppInjector.get(ResourceService);
      let policyDetails = resourceService.getResourceFromAliasName('policyDetails');
      let groupAnuity = (policyDetails.data.product_type == "A3" || policyDetails.data.product_type == "A4") ? true : false;
      const selectedRoleSummary = resourceService.getResourceFromAliasName('selectedRoles');
      const authResponse = resourceService.getResourceFromAliasName('authRes');
      const workRequestHref = resourceService.getHrefByAliasName("workRequestHistory").split('?')[0];
      const headers = OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("workRequestHistory")
      headers.statuaory_company_code = policyDetails.data.statutory_company_code;
      headers.company_code = policyDetails.data.company_code;
      headers.product_type = policyDetails.data.product_type;
      headers.user_role = OcInfraModule.AppInjector.get(CommonService).selectedRole;
      let loanInterest = {
         "loan_interest_rate": OcInfraModule.AppInjector.get(CommonService).loanInterestRate
      }
      let payload = {
         "status": this.exceptionProcessing ? "CREATED" : "COMPLETED",
         "CLTY": selectedWorkItemType,
         "additionalInfo": "CSRAutoCorr",
         "POLN": groupAnuity ? selectedRoleSummary && selectedRoleSummary.data.policyNumber ? selectedRoleSummary.data.policyNumber: policyDetails.data.policy_number: (selectedRoleSummary && selectedRoleSummary.data.policyNumber ? selectedRoleSummary.data.policyNumber : policyDetails.data.policy_number),
         "comments": this.exceptionProcessing ? this.addComment + " Forms for exception processing " + this.selectedFormRequestDesc : this.addComment,
         "attachedForms": this.selectedFormRequest,
         "attachedFormsDesc": this.selectedFormRequestDesc,
         "exception_processing": this.exceptionProcessing.toString(),
         "hostKey": selectedRoleSummary && selectedRoleSummary.data.hostKey ? selectedRoleSummary.data.hostKey : '',
         "type": OcInfraModule.AppInjector.get(CommonService).selectedWorkItemValue,
         "sessionId": authResponse && authResponse.data.contactSessionID ? authResponse.data.contactSessionID : '',
         "callStart": authResponse && authResponse.data.callStart ? authResponse.data.callStart : '',
         "clientId": selectedRoleSummary && selectedRoleSummary.data.clientId ? selectedRoleSummary.data.clientId : '',
         "userId": OcInfraModule.AppInjector.get(CommonService).incontactId,
         "origin_app": "csr_portal",
         "letterType": "EFORMS",
         "batchingType": this.isEmailAddressValid ? "Email" : this.batchOrEmail,
         "batchValue": this.emailAddress,
         "departmentName": "EFORMS",
         "addresseeCopy": "OwnerCopyInd",
         "lob": policyDetails && policyDetails.data && policyDetails.data.line_of_business_code,
         "ICID": authResponse && authResponse.data.contactID ? authResponse.data.contactID : '',
      };

      if(this.emailAddressChecked) {
        payload = {...payload, ...{batchingType: 'Email', "batchValue": this.emailAddress}}
      } else if(this.faxNumberChecked) {
        payload = {...payload, ...{batchingType: 'Fax', "batchValue": this.faxNumber}}
      } else {
        payload = {...payload, ...{batchingType: this.batchOrEmail, "batchValue": this.emailAddress}}
      }
      
      if (payload.batchingType == 'Batch') {
         delete payload.batchValue;
      }

      payload.attachedFormsDesc[0] == "Loan Form Request" ? Object.assign(payload, loanInterest) : ''
      this.createWorkRequest(workRequestHref, payload, headers);

   }
   createWorkRequest(url, payload, headers) {
      if (payload.hasOwnProperty('OCDT')) {
         if (typeof payload.OCDT == "object" && payload.OCDT != null) {
            var OCDT = payload.OCDT.toLocaleString("en-US", {
               day: '2-digit',
               year: 'numeric',
               month: '2-digit',
               hour: 'numeric',
               minute: 'numeric',
               second: 'numeric',
            });
            payload["OCDT"] = OCDT;
         }
         else {
            delete payload.OCDT
         }
      }
      const resourceService = OcInfraModule.AppInjector.get(ResourceService);
      let policyDetails = resourceService.getResourceFromAliasName('policyDetails');
      let correspondenceURL = OcInfraModule.AppInjector.get(CommonService).getAllUrlOfPolicyDetails();
      const corresHeaders = Configuration.config.headers.defaultHeader;
      OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false });
      var modalButtons = document.querySelectorAll(".formRequest .btn-primary");
      if (modalButtons && modalButtons.length > 0) {
         Array.from(modalButtons).forEach(button => {
            var btn = button as HTMLInputElement
            btn.disabled = true;
         });
      }
      OcInfraModule.AppInjector.get(CommonService).postCallback(url, payload, headers).subscribe(response => {
         if (response.code == '200') {
            if (OcInfraModule.AppInjector.get(CommonService).attachDocWorkReq) {
               this.isAttachDoc = true;
               OcInfraModule.AppInjector.get(CommonService).attachSourcesUrl = url + '/' + response.instanceid +'/sources';
            }
            OcInfraModule.AppInjector.get(AdvGrowlService).createSuccessMessage(response.message, 'Success');
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false });
            setTimeout(() => {
               // Commenting thi Api call its not actually refreshing correspondence table and creating another issues like unable to close call.
               // OcInfraModule.AppInjector.get(APICallerService).refresh(correspondenceURL["correspondence"].href, corresHeaders,"RESET").subscribe(res => {
               // });
               OcInfraModule.AppInjector.get(APICallerService).refresh(url, { 'alias': "workRequestHistory", 'headers': headers }).subscribe(refreshResponse => {
                  OcInfraModule.AppInjector.get(CommonService).wrkReqHistoryRes = refreshResponse;
                  OcInfraModule.AppInjector.get(CommonService).sendFilterEvent();
                  OcInfraModule.AppInjector.get(CommonService).attachDocWorkReq = false;
                  if(!this.isAttachDoc) {
                     document.getElementById("modal__cross").click();
                  } else {
                     if (!this.isWorkNotCompleted) {
                        document.getElementById("modal__cross").click();
                     }
                  }
                  OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
               });
            }, 5000);
         } else {
            this.isAttachDoc = false;
            response.message ? OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(response.message, 'Error', 0) : OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage("Something went wrong", 'Error', 0);
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
            if (modalButtons && modalButtons.length > 0) {
               Array.from(modalButtons).forEach(button => {
                  var btn = button as HTMLInputElement
                  btn.disabled = false;
               });
            }
            setTimeout(() => {
               // Commenting thi Api call its not actually refreshing correspondence table and creating another issues like unable to close call.
               // OcInfraModule.AppInjector.get(APICallerService).refresh(correspondenceURL["correspondence"].href, corresHeaders,"RESET").subscribe(res => {
               // });
               OcInfraModule.AppInjector.get(APICallerService).refresh(url, { 'alias': "workRequestHistory", 'headers': headers }).subscribe(refreshResponse => {
                  OcInfraModule.AppInjector.get(CommonService).wrkReqHistoryRes = refreshResponse;
                  OcInfraModule.AppInjector.get(CommonService).sendFilterEvent();
                  OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
                  document.getElementById("modal__cross").click();
               });
            }, 5000);
         }
      },
         error => {
            this.isAttachDoc = false;
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
            error.error && error.error.message ? OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error.error.message, 'Error', 0) : OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage("Something went wrong", 'Error', 0);
            OcInfraModule.AppInjector.get(APICallerService).refresh(url, { 'alias': "workRequestHistory", 'headers': headers }).subscribe(refreshResponse => {
               OcInfraModule.AppInjector.get(CommonService).wrkReqHistoryRes = refreshResponse;
               OcInfraModule.AppInjector.get(CommonService).sendFilterEvent();
            });
            if (modalButtons && modalButtons.length > 0) {
               Array.from(modalButtons).forEach(button => {
                  var btn = button as HTMLInputElement
                  btn.disabled = false;
               });
            }
         });
   }

   exceptionChecked(e) {
      this.exceptionProcessing = e.checked;
      e.checked ? '' : this.addComment = '';
      e.checked ? this.disableException = false : this.disableException = true;
      e.checked ? this.commentRequired = true : this.commentRequired = false;
      this.validateFormRequestChecked();

   }

   // On click on email
   emailIdCheckboxChecked(e) {
      this.emailIdCheckbox = e.checked;
      e.checked ? '' : this.emailAddress = '';
      e.checked ? this.disableEmailAdressField = false : this.disableEmailAdressField = true;
      e.checked ? '' : this.isEmailAddressValid = false;

      e.checked ? (this.emailAddressChecked = true) : (this.emailAddressChecked = false);
      e.checked ? this.faxNumberCheckboxChecked({ ...e, ...{ checked: false } }) : '';
      this.validateFormRequestChecked();
   }

   // On click on fax
   faxNumberCheckboxChecked(e) {
    this.faxNumberCheckbox = e.checked;
    e.checked ? '' : this.faxNumber = '';
    e.checked ? this.disableFaxNumberField = false : this.disableFaxNumberField = true;
    e.checked ? '' : this.isFaxNumberValid = false;
    e.checked ? this.faxClass = true : this.faxClass = false; 

    e.checked ? (this.faxNumberChecked = true) : (this.faxNumberChecked = false);
    e.checked ? this.emailIdCheckboxChecked({ ...e, ...{ checked: false } }): '';

    this.validateFormRequestChecked();
 }



   ngOnInit() {
      OcInfraModule.AppInjector.get(AdvGrowlService).clearMessages();
      let details = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyDetails');
      if (details.data.product_type == "A1" || details.data.product_type == "A2") {
         this.isRetailAnnuity = true;
      }
      if (details.data.product_type == "A3" || details.data.product_type == "A4") {
         this.isGroupAnnuity = true;
      }
      if (details.data.product_type.includes('A')) {
         this.isAnnuity = true;
      }
      else {
         this.isAnnuity = false;
      }
      if (_.includes(details.data.product_type,"U") || _.includes(details.data.product_type,"T")) {
         this.isLife = true;
      }
      else {
         this.isLife = false;
      }

      if ((this.selectedWorkItemType == 'FORMS' || this.selectedWorkItemType == 'GRPFORMS')) {
         for (let i = 0; i < this.checkProperties.oneOf.length; i++) {
            this.checkProperties.oneOf[i]['disable'] = false;
            this.checkProperties.oneOf[i]['isChecked'] = false;
         }
      }
      const resourceService = OcInfraModule.AppInjector.get(ResourceService);
      if (OcInfraModule.AppInjector.get(CommonService).authsucess || OcInfraModule.AppInjector.get(CommonService).otherRoleAuthsucess) {
         this.message = "Enable button by completing required items."
         this.authorizationCheck = true;
      } else {
         this.authorizationCheck = false;
         this.message = "Caller not authorized."
      }
      let params = OcInfraModule.AppInjector.get(CommonService).selectedWorkItemParams;
      let selectedRoleSummary = resourceService.getResourceFromAliasName('selectedRoles');
      this.fullName = selectedRoleSummary.data.fullName ? selectedRoleSummary.data.fullName : selectedRoleSummary.data.ownerFullName ? selectedRoleSummary.data.ownerFullName : selectedRoleSummary.data.annuitantFullName ? selectedRoleSummary.data.annuitantFullName :
         selectedRoleSummary.data.beneficiaryName ? selectedRoleSummary.data.beneficiaryName : selectedRoleSummary.data.jointOwnerFullName ? selectedRoleSummary.data.jointOwnerFullName : selectedRoleSummary.data.agentFullName ? selectedRoleSummary.data.agentFullName : "";
      if (!(this.selectedWorkItemType == 'FORMS' || this.selectedWorkItemType == 'GRPFORMS')) {
         let form = {
            "ANNSTPREIS": "stopReissueCheckAnn",
            "STOPREISSU": "stopReissueCheck",
            "STOPREISS": "stopReissueCheckUL",
            "VOIDREISSU": "voidPaymentRequestCheckReissue",
            "STOPSUSP": "stopPaymentRequestSuspenceFunds",
            "VOIDSUSP": "voidPaymentRequestSuspenceFunds",
            "STOPPRECNV": "StopPaymentPreConvCheck",
            "STOPPRECONV": "StopPaymentPreConvCheckUL",
            "CDSCHKCOPY": "checkCopy",
            "CORRESFCOM": "complexCorrespondenceF",
            "CORRESVCOM": "complexCorrespondenceV",
            "INVSTRAT": "fundTransfer",
            "ANNMRDAUT": "minDisEnrollment",
            "ANNMRD": "minDisWithdrawal",
            "ANNWTHDRW": "partialWithdrawal",
            "ANNSURR": "surrender",
            "ANNSWP": "systematicWithdrawal",
            "GRPINVSTRT": "GrpFundtransfer",
            "GRPMRDAUT": "grpMindistEnrollment",
            "GRPMRD": "grpminiDisWithdrawal",
            "GRPWTHDRAW": "grpPartialwithdrawal",
            "GRPSURR": "grpSurrender",
            "GRPSWP": "grpSystematicwithdrawal"
         }
         let header = _.filter(Configuration.config.headers["multiHostUrlHeader"], function (o) { return o.alias == 'workitemsSchema' });
         let formsUrl = params.href.slice(0, -5) + 'schemas/types/' + this.selectedWorkItemType;
         OcInfraModule.AppInjector.get(CommonService).getCallback(formsUrl, header[0].headers).subscribe(response => {
            this.schema = response[this.selectedWorkItemType];
            this.createForm();
            let commentsValue;
            let deliveryMethodWorkTypes = ["CDSCHKCOPY", "ANNWTHDRW", "GRPSURR", "GRPWTHDRAW"];
            if (deliveryMethodWorkTypes.includes(this.selectedWorkItemType)) {
               this[form[this.selectedWorkItemType]].get('deliveryMethod').setValue(this.schema.properties.deliveryMethod.oneOf[1].enum);
            }
            const resourceService = OcInfraModule.AppInjector.get(ResourceService);
            let selectedRoleSummary = resourceService.getResourceFromAliasName('selectedRoles');
            let fullName = selectedRoleSummary.data.fullName ? selectedRoleSummary.data.fullName : selectedRoleSummary.data.ownerFullName ? selectedRoleSummary.data.ownerFullName : selectedRoleSummary.data.annuitantFullName ? selectedRoleSummary.data.annuitantFullName : selectedRoleSummary.data.beneficiaryName ? selectedRoleSummary.data.beneficiaryName : selectedRoleSummary.data.jointOwnerFullName ? selectedRoleSummary.data.jointOwnerFullName : selectedRoleSummary.data.agentFullName ? selectedRoleSummary.data.agentFullName : selectedRoleSummary.data.coOwnerFullName ? selectedRoleSummary.data.coOwnerFullName : "";

            if (document.getElementById('#workrequestComment') && this.authorizationCheck) {
               let fullName = selectedRoleSummary.data.fullName ? selectedRoleSummary.data.fullName : selectedRoleSummary.data.ownerFullName ? selectedRoleSummary.data.ownerFullName : selectedRoleSummary.data.annuitantFullName ? selectedRoleSummary.data.annuitantFullName :
                  selectedRoleSummary.data.beneficiaryName ? selectedRoleSummary.data.beneficiaryName : selectedRoleSummary.data.jointOwnerFullName ? selectedRoleSummary.data.jointOwnerFullName : selectedRoleSummary.data.agentFullName ? selectedRoleSummary.data.agentFullName : selectedRoleSummary.data.coOwnerFullName ? selectedRoleSummary.data.coOwnerFullName : "";
               if (selectedRoleSummary.data.role != undefined) {
                  if (selectedRoleSummary.data.role == 'Payor') {
                     commentsValue = 'Role: ' + selectedRoleSummary.data.role + ', Full Name: ' + selectedRoleSummary.data.payorFullName;
                  } else {
                     commentsValue = 'Role: ' + selectedRoleSummary.data.role + ', Full Name: ' + fullName;
                  }
                     this[form[this.selectedWorkItemType]].get('comments').setValue(commentsValue);
                  // document.getElementById('#workrequestComment')["value"] = commentsValue;
               }
               else {
                  let name = document.getElementById("selected-roles-name-text")["value"];
                  let number = document.getElementById("selected-roles-phone-number-text")["value"];
                  if (document.getElementById("selected-roles-other-role-name-text")) {
                     commentsValue = 'Role: ' + document.getElementById("selected-roles-other-role-name-text")["value"] + ', Full Name: ' + name + ', Phone Number: ' + number;
                  }
                  else {
                     commentsValue = 'Role: ' + OcInfraModule.AppInjector.get(CommonService).otherRole + ', Full Name: ' + name + ', Phone Number: ' + number;
                  }
                  this[form[this.selectedWorkItemType]].get('comments').setValue(commentsValue);
               }
            }
         })
      }
      this.authsucess || this.otherRoleSucess ? this.selectedFormRequest.length ? this.exceptionProcessing ? this.addComment ? this.hoverMessage = '' : this.hoverMessage = 'Enable button by completing required items' : this.hoverMessage = '' : this.hoverMessage = "Enable by making a Form selection or checking the 'Exception Processing' check box" : this.hoverMessage = 'Caller was not authorized';
      //  OcInfraModule.AppInjector.get(CommonService).role == 'OtherRole' ? this.role = true : this.role = false;
      let roleList = resourceService.getResourceFromAliasName('roles');
      this.selectedRoleSummary = _.filter(roleList._links.item, function (o) { return o.summary.role == 'Owner' });
   }


   numberOnly(e, changeReq, obj): boolean {
      {
         if (e.keyCode > 31 && ((e.keyCode < 48 || e.keyCode > 57) && (e.keyCode < 96 || e.keyCode > 105)) && e.keyCode != 46) {
            e.preventDefault();
         } else {
            e.target["value"] = e.target["value"].replace(/[^0-9]/gi, '');
            e.target["value"] = e.target["value"].replace(/(\d{3}(?!\d?$))\-?/g, '$1-');
            if (changeReq == 'change') {
               obj.controls['phone'].setValue(e.target["value"]);
            } 
            return true;
         }
      }
   }

   faxNumberOnly(e, changeReq, obj): boolean {
      {
         if (e.keyCode > 31 && ((e.keyCode < 48 || e.keyCode > 57) && (e.keyCode < 96 || e.keyCode > 105)) && e.keyCode != 46) {
            e.preventDefault();
         } else {
            e.target["value"] = e.target["value"].replace(/[^0-9]/gi, '');
            e.target["value"] = e.target["value"].replace(/(\d{3}(?!\d?$))\-?/g, '$1-');
            if (changeReq == 'change') {
               obj.controls['fax'].setValue(e.target["value"]);
            }
            return true;
         }
      }
   }

   createForm(): void {
      this.stopReissueCheck = this.formBuilder.group({
         FNAR: new FormControl(''),
         YN01: new FormControl(''),
         OCMP: new FormControl(''),
         OAMT: new FormControl(''),
         OCDT: new FormControl(''),
         OPAY: new FormControl(''),
         CHK1: new FormControl(''),
         ADTY: new FormControl(''),
         CAM2: new FormControl(''),
         ADD1: new FormControl(''),
         ADD2: new FormControl(''),
         ADD3: new FormControl(''),
         CITY: new FormControl(''),
         STC1: new FormControl(''),
         ZIP1: new FormControl(''),
         phone: new FormControl(''),
         fax: new FormControl(''),
         comments: new FormControl(''),
         OSRC: new FormControl('')
      });
      this.stopReissueCheckUL = this.formBuilder.group({
         FNAR: new FormControl(''),
         YN01: new FormControl(''),
         OCMP: new FormControl(''),
         OAMT: new FormControl(''),
         OCDT: new FormControl(''),
         OPAY: new FormControl(''),
         CHK1: new FormControl(''),
         ADTY: new FormControl(''),
         CAM2: new FormControl(''),
         ADD1: new FormControl(''),
         ADD2: new FormControl(''),
         ADD3: new FormControl(''),
         CITY: new FormControl(''),
         STC1: new FormControl(''),
         ZIP1: new FormControl(''),
         phone: new FormControl(''),
         fax: new FormControl(''),
         comments: new FormControl(''),
         OSRC: new FormControl('')
      });
      this.stopReissueCheckAnn = this.formBuilder.group({
         FNAR: new FormControl(''),
         YN01: new FormControl(''),
         OCMP: new FormControl(''),
         OAMT: new FormControl(''),
         OCDT: new FormControl(''),
         OPAY: new FormControl(''),
         CHK1: new FormControl(''),
         ADTY: new FormControl(''),
         CAM2: new FormControl(''),
         ADD1: new FormControl(''),
         ADD2: new FormControl(''),
         CITY: new FormControl(''),
         STC1: new FormControl(''),
         ZIP1: new FormControl(''),
         phone: new FormControl(''),
         fax: new FormControl(''),
         comments: new FormControl('')
      });
      this.complexCorrespondenceV = this.formBuilder.group({
         FNAR: new FormControl(''),
         YN01: new FormControl(''),
         OCMP: new FormControl(''),
         OAMT: new FormControl(''),
         OCDT: new FormControl(''),
         OPAY: new FormControl(''),
         CHK1: new FormControl(''),
         ADTY: new FormControl(''),
         CAM2: new FormControl(''),
         ADD1: new FormControl(''),
         ADD2: new FormControl(''),
         ADD3: new FormControl(''),
         CITY: new FormControl(''),
         STC1: new FormControl(''),
         ZIP1: new FormControl(''),
         phone: new FormControl(''),
         fax: new FormControl(''),
         comments: new FormControl('')
      });
      this.complexCorrespondenceF = this.formBuilder.group({
         FNAR: new FormControl(''),
         YN01: new FormControl(''),
         OCMP: new FormControl(''),
         OAMT: new FormControl(''),
         OCDT: new FormControl(''),
         OPAY: new FormControl(''),
         CHK1: new FormControl(''),
         ADTY: new FormControl(''),
         CAM2: new FormControl(''),
         ADD1: new FormControl(''),
         ADD2: new FormControl(''),
         ADD3: new FormControl(''),
         CITY: new FormControl(''),
         STC1: new FormControl(''),
         ZIP1: new FormControl(''),
         phone: new FormControl(''),
         fax: new FormControl(''),
         comments: new FormControl('')

      });
      this.checkCopy = this.formBuilder.group({
         FNAR: new FormControl(''),
         YN01: new FormControl(''),
         OCMP: new FormControl(''),
         OAMT: new FormControl(''),
         OCDT: new FormControl(''),
         OPAY: new FormControl(''),
         CHK1: new FormControl(''),
         ADTY: new FormControl(''),
         CAM2: new FormControl(''),
         ADD1: new FormControl(''),
         ADD2: new FormControl(''),
         CITY: new FormControl(''),
         STC1: new FormControl(''),
         ZIP1: new FormControl(''),
         phone: new FormControl(''),
         fax: new FormControl(''),
         comments: new FormControl(''),
         deliveryMethod: new FormControl(''),
      });
      this.StopPaymentPreConvCheck = this.formBuilder.group({
         FNAR: new FormControl(''),
         OCMP: new FormControl(''),
         OAMT: new FormControl(''),
         OCDT: new FormControl(''),
         OPAY: new FormControl(''),
         CHK1: new FormControl(''),
         ADTY: new FormControl(''),
         CAM2: new FormControl(''),
         ADD1: new FormControl(''),
         ADD2: new FormControl(''),
         CITY: new FormControl(''),
         STC1: new FormControl(''),
         ZIP1: new FormControl(''),
         phone: new FormControl(''),
         draftDay: new FormControl(''),
         billingAmt: new FormControl(''),
         comments: new FormControl('')
      });
      this.StopPaymentPreConvCheckUL = this.formBuilder.group({
         FNAR: new FormControl(''),
         OCMP: new FormControl(''),
         OAMT: new FormControl(''),
         OCDT: new FormControl(''),
         OPAY: new FormControl(''),
         CHK1: new FormControl(''),
         ADTY: new FormControl(''),
         CAM2: new FormControl(''),
         ADD1: new FormControl(''),
         ADD2: new FormControl(''),
         CITY: new FormControl(''),
         STC1: new FormControl(''),
         ZIP1: new FormControl(''),
         phone: new FormControl(''),
         draftDay: new FormControl(''),
         billingAmt: new FormControl(''),
         comments: new FormControl('')
      });
      this.voidPaymentRequestCheckReissue = new FormGroup({
         FNAR: new FormControl(''),
         OCMP: new FormControl(''),
         OAMT: new FormControl(''),
         OCDT: new FormControl(''),
         OPAY: new FormControl(''),
         CHK1: new FormControl(''),
         ADTY: new FormControl(''),
         CAM2: new FormControl(''),
         ADD1: new FormControl(''),
         ADD2: new FormControl(''),
         CITY: new FormControl(''),
         STC1: new FormControl(''),
         ZIP1: new FormControl(''),
         phone: new FormControl(''),
         draftDay: new FormControl(''),
         billingAmt: new FormControl(''),
         comments: new FormControl(''),
         OSRC: new FormControl('')
      });
      this.stopPaymentRequestSuspenceFunds = new FormGroup({
         FNAR: new FormControl(''),
         OCMP: new FormControl(''),
         OAMT: new FormControl(''),
         OCDT: new FormControl(''),
         OPAY: new FormControl(''),
         CHK1: new FormControl(''),
         ADTY: new FormControl(''),
         CAM2: new FormControl(''),
         ADD1: new FormControl(''),
         ADD2: new FormControl(''),
         CITY: new FormControl(''),
         STC1: new FormControl(''),
         ZIP1: new FormControl(''),
         phone: new FormControl(''),
         draftDay: new FormControl(''),
         billingAmt: new FormControl(''),
         comments: new FormControl(''),
         OSRC: new FormControl('')
      });
      this.voidPaymentRequestSuspenceFunds = new FormGroup({
         FNAR: new FormControl(''),
         OCMP: new FormControl(''),
         OAMT: new FormControl(''),
         OCDT: new FormControl(''),
         OPAY: new FormControl(''),
         CHK1: new FormControl(''),
         ADTY: new FormControl(''),
         CAM2: new FormControl(''),
         ADD1: new FormControl(''),
         ADD2: new FormControl(''),
         CITY: new FormControl(''),
         STC1: new FormControl(''),
         ZIP1: new FormControl(''),
         phone: new FormControl(''),
         draftDay: new FormControl(''),
         billingAmt: new FormControl(''),
         comments: new FormControl(''),
         OSRC: new FormControl('')
      });
      this.fundTransfer = this.formBuilder.group({
         address1: new FormControl(''),
         address2: new FormControl(''),
         city: new FormControl(''),
         state: new FormControl(''),
         zip: new FormControl(''),
         phone: new FormControl(''),
         fax: new FormControl(''),
         comments: new FormControl('')
      });
      this.minDisEnrollment = this.formBuilder.group({
         phone: new FormControl(''),
         fax: new FormControl(''),
         address1: new FormControl(''),
         address2: new FormControl(''),
         city: new FormControl(''),
         state: new FormControl(''),
         zip: new FormControl(''),
         comments: new FormControl('')
      });
      this.minDisWithdrawal = this.formBuilder.group({
         phone: new FormControl(''),
         fax: new FormControl(''),
         address1: new FormControl(''),
         address2: new FormControl(''),
         city: new FormControl(''),
         state: new FormControl(''),
         zip: new FormControl(''),
         comments: new FormControl('')  
      });
      this.partialWithdrawal = this.formBuilder.group({
         phone: new FormControl(''),
         fax: new FormControl(''),
         address1: new FormControl(''),
         address2: new FormControl(''),
         city: new FormControl(''),
         state: new FormControl(''),
         zip: new FormControl(''),
         deliveryMethod: new FormControl(''),
         comments: new FormControl('')   
      });
      this.surrender = this.formBuilder.group({
         phone: new FormControl(''),
         comments: new FormControl('')
      });
      this.systematicWithdrawal = this.formBuilder.group({
         phone: new FormControl(''),
         fax: new FormControl(''),
         address1: new FormControl(''),
         address2: new FormControl(''),
         city: new FormControl(''),
         state: new FormControl(''),
         zip: new FormControl(''),
         comments: new FormControl('')  
      });
      this.GrpFundtransfer =this.formBuilder.group({
         phone: new FormControl(''),
         fax: new FormControl(''),
         address1: new FormControl(''),
         address2: new FormControl(''),
         city: new FormControl(''),
         state: new FormControl(''),
         zip: new FormControl(''),
         comments: new FormControl('')  
      });
      this.grpMindistEnrollment = this.formBuilder.group({
         phone: new FormControl(''),
         fax: new FormControl(''),
         address1: new FormControl(''),
         address2: new FormControl(''),
         city: new FormControl(''),
         state: new FormControl(''),
         zip: new FormControl(''),
         comments: new FormControl('') 
      });
      this.grpminiDisWithdrawal = this.formBuilder.group({
         phone: new FormControl(''),
         fax: new FormControl(''),
         address1: new FormControl(''),
         address2: new FormControl(''),
         city: new FormControl(''),
         state: new FormControl(''),
         zip: new FormControl(''),
         comments: new FormControl('')  
      });
      this.grpPartialwithdrawal = this.formBuilder.group({
         phone: new FormControl(''),
         fax: new FormControl(''),
         address1: new FormControl(''),
         address2: new FormControl(''),
         city: new FormControl(''),
         state: new FormControl(''),
         zip: new FormControl(''),
         deliveryMethod: new FormControl(''),
         comments: new FormControl('')    
      });
      this.grpSurrender = this.formBuilder.group({
         phone: new FormControl(''),
         fax: new FormControl(''),
         address1: new FormControl(''),
         address2: new FormControl(''),
         city: new FormControl(''),
         state: new FormControl(''),
         zip: new FormControl(''),
         deliveryMethod: new FormControl(''),
         comments: new FormControl('') 
      });
      this.grpSystematicwithdrawal = this.formBuilder.group({
         phone: new FormControl(''),
         fax: new FormControl(''),
         address1: new FormControl(''),
         address2: new FormControl(''),
         city: new FormControl(''),
         state: new FormControl(''),
         zip: new FormControl(''),
         comments: new FormControl('')    
      })
   }


   

   //Validate email address
   isEmailAddressValid = false;

   onEmailPaste(e, email){
      e.target.value = email;
      this.addEmailAddress(e);
   }

   addEmailAddress(e) {
      this.isEmailAddressValid = false;
      if(e.target.value.length){
         if( e.target.value.trim() != '' &&  e.target.value.trim() >5 ||
            /^(([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25}(($)|( *;+ *$)|( *;+ *(?=[a-zA-Z0-9_\-\.]))))*$/.test(e.target.value.trim())){
            this.isEmailAddressValid = true;
         }
      }      
      (this.isEmailAddressValid && this.emailAddress != '' && e.target.value != '') ? this.emailClass = false : this.emailClass = true;
      this.validateFormRequestChecked();
    }

    checkFaxNumberKeyUp(e, faxModel){
      faxModel = faxModel.replace(/[^0-9]/gi, '');
      e.target['value'] = faxModel.replace(/(\d{3}(?!\d?$))\-?/g,'$1-');
       this.checkFaxNumber(e);
    }

    checkFaxNumber(e) {
         this.isFaxNumberValid = false;
         {
          if (e.keyCode > 31 && ((e.keyCode < 48 || e.keyCode > 57) && (e.keyCode < 96 || e.keyCode > 105)) && e.keyCode != 46) {
                 e.preventDefault();
             } else {
                 const value = e.target.value.replace('-', '');
                 if (e.target.value.trim() != '' && value.trim().length == 11) {
                     this.isFaxNumberValid = true;
                     this.faxNumber = e.target.value;
                 }
                 this.isFaxNumberValid && this.faxNumber != '' && e.target.value != ''
                     ? (this.faxClass = false)
                     : (this.faxClass = true);
 
                 this.validateFormRequestChecked();
 
                 e.target['value'] = e.target['value'].replace(
                     /(\d{3}(?!\d?$))\-?/g,
                     '$1-'
                 );
                 return true;
             }
         }
    }

}
