import { DatePipe, CurrencyPipe } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { OcInfraModule, ResourceService } from '@diaas/ux-web';
import * as moment from 'moment';
import * as _ from 'lodash';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { AppDateAdapter, APP_DATE_FORMATS } from '../../../format-datepicker';
import { ItemAssetAccount, ItemProducts, QuoteFormFieldModel, SelectedFormValue, quoteSectionOneId, sectionOneFormFields, sectionTwoFormFields } from '../partial-surrender-quote/partial-surrender-quote.constant';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { PartySearchFactory } from '../../../../factories/partySearchFactory';
import { AdvGrowlService } from 'primeng-advanced-growl';
import { CommonService, retryOperation } from '../../../../services/common.service';
import { SurrenderDetails, OneOf, OneOfData, SurrenderValues, FundValue } from './partial-surrender-surrender-mrps.constant';

@Component({
    selector: 'app-partial-surrender-surrender-mrps',
    templateUrl: './partial-surrender-surrender-mrps.component.html',
    styleUrls: ['./partial-surrender-surrender-mrps.component.scss'],providers: [
        { provide: DateAdapter, useClass: AppDateAdapter },
        { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
    ]
})
export class PartialSurrenderSurrenderMRPSComponent implements OnInit {

    @Input() withdrawlSurrenderType: any;
    @Input() currentPolicyResourceURL: String;
    @Input() surrenderDetails: SurrenderDetails;
    @Input() isLife: boolean;
    @Input() headers: Object;
    @Output() passDistributionData: EventEmitter<any> = new EventEmitter<any>();
    @Output() surrenderDetailsToEmit = new EventEmitter<Object>();
    @Output() planIDToEmit = new EventEmitter<Object>();

    alertMsg = [];
    surrenderForm: FormGroup;
    selectedAssetAccount : any;
    responseOptions: any;
    requiredFields: any;

    statePctOrBasis: any = [];
    transactionTypes: any = [];
    transactionLevels: any = [];

    federal_withholding_override: boolean = false;
    state_withholding_override: boolean = false;

    client_recommend_or_authorized: boolean = false;

    maskSeparator = 'separator.2';
    separatorLimit = '9999999999';
    prefix = '$';
    decimalPlaces = 2;

    minLoanAmount = '';
    maxLoanAmount = '';

    fundsInfo: boolean = false;
    funds: FundValue[] = [];
    maxfunds: FundValue[] = [];
    totalFunds: string = ''
    isGroupProduct: boolean = false;
    effectivedate: any;
    effectiveMaxDate: any;
    isMrpsContract: boolean = false;
    policyDetails: any;
    clientProducts: ItemProducts[];
    assetAccounts: ItemAssetAccount[];

    clientProductItems: any;
    assetAccountItems: any;

    clientProductsUrl: string = '';
    assetAccountsUrl: string = '';

    retryDelay = 3000;
    retryAttempts = 3;
    tableSortStates: any;
    quotesAPISource: Object = { "PARTIAL_SURRENDER": "/surrender/group/partial"}
    allFunds: FundValue[] = [];

    totalFundValue:any = '';
    displayFundsTable: boolean = false;
    free_out_ind: any;
    totalFundscalculated: string;   
    isHostContract: boolean = false;
    effectiveMinDate = new Date(1900, 1, 1);

    constructor(private datePipe: DatePipe, private currencyPipe: CurrencyPipe, private formBuilder: FormBuilder, private commonService: CommonService) { }

    ngOnInit() {

        this.surrenderFormInit();
        this.policyDetails = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyDetails');
        this.isGroupProductType() ? this.processA4Product():"";      
        this.isMrpsContract = this.policyDetails.response.isMrpsContract ? true : false;
        this.isHostContract = this.policyDetails.response.isHostContract ? true : false;
        this.surrenderForm.get('effective_date').setValue(new Date(this.policyDetails.response.aart_date));
        this.effectivedate = this.surrenderForm.get('effective_date').value;
        this.effectiveMaxDate = new Date();
        this.surrenderForm.get('federal_withholding_amount').disable();
        this.surrenderForm.get('federal_withholding_percentage').disable();
        this.surrenderForm.get('state_withholding_amount').disable();
        this.surrenderForm.get('state_withholding_percentage').disable();
        this.surrenderForm.get('statePctOrBasis').disable();
        this.isGroupProduct ? "" : this.getPartialSurrenderMRPS(this.currentPolicyResourceURL + this.quotesAPISource[this.withdrawlSurrenderType]+ "?" + this.getQueryParams());
    }

    isGroupProductType = () => {
        let product_type = this.getNested(this.policyDetails, 'response', 'product_type');
        if (product_type && (product_type == "A3" || product_type == "A4")) {
            this.isGroupProduct = true;
            this.surrenderForm.addControl("productType", new FormControl("", [Validators.required]));
            this.surrenderForm.addControl("accountType", new FormControl("", [Validators.required]));
            return true;
        } else {
            this.isGroupProduct = false;
            return false;
        }
    }

    surrenderFormInit() {
        let percentValue = this.formatPercent('', 'blur', '0.00');
        let currencyValue = this.formatCurrency('', 'blur', '0.00');

        this.surrenderForm = this.formBuilder.group({
            effective_date: [, [Validators.required]],
            statePctOrBasis: [''],

            no_federal_withholding: [false],
            federal_withholding_override: [false],
            federal_withholding_amount: [currencyValue],
            federal_withholding_percentage: [percentValue],

            no_state_withholding: [false],
            state_withholding_override: [false],
            state_withholding_amount: [currencyValue],
            state_withholding_percentage: [percentValue],

            transaction_type: ['', [Validators.required]],
            surrender_amount: ['', [Validators.required]],
            surrender_percent: ['', []],
            surrender_free_amount: ['', []],
            surrender_cap_amount: ['', []],
            cumulative_surrender_charge: ['', []],
            transaction_level: ['', [Validators.required]],
            net_gross_indicator: ['Gross', Validators.required],

        });
        const authorizedRole = OcInfraModule.AppInjector.get(CommonService).authorizedRole; 

            if (authorizedRole === 'Agent' || authorizedRole === 'BOA Back Office Assistant (Advisor Assistant)') {
                this.surrenderForm.addControl("client_recommend_or_authorized", new FormControl(false, Validators.requiredTrue));
                this.client_recommend_or_authorized = true;
            }
    }


    getQueryParams() {
        let params = new HttpParams();
        let effectiveDate = this.surrenderForm.get('effective_date').value;
        params = params.set('quoteEffectiveDate', this.datePipe.transform(effectiveDate, "MM/dd/yyyy"))
        return params.toString();
    }

    getPartialSurrenderMRPS = (url: string) => {
        console.log('🚀 ~ file: partial-surrender-surrender-mrps.component.ts:164 ~ PartialSurrenderSurrenderMRPSComponent ~ url:', url);
        if(this.isHostContract && this.isGroupProduct) {
            this.planIDToEmit.emit({'msg' :url, 'partialHostHeaders': this.headers});
        } else {
            OcInfraModule.AppInjector.get(CommonService).getCallback(url, this.headers).subscribe(response => {
                console.log('🚀 ~ file: partial-surrender-surrender-mrps.component.ts:165 ~ PartialSurrenderSurrenderMRPSComponent ~ OcInfraModule.AppInjector.get ~ response:', response);
                this.free_out_ind = response.Free_out_ind;
                (this.free_out_ind == 'N') ?(this.displayFundsTable = false):(this.displayFundsTable = true);
                this.surrenderDetails = response;
                this.surrenderDetailsToEmit.emit(this.surrenderDetails);
                this.effectivedate = this.surrenderForm.get('effective_date').value;
                this.getSurrenderDetails();
                // this.prepareInfoFormControls();
                // this.prepareValueFormControls();
    
                // this.allFormValues = [...this.quoteInfoFields, ...this.quoteValueFields];
    
                // this.showRequestedData();
    
                // this.sendQuoteValuesToParent(response);
    
            }, error => {
                if (error._embedded && error._embedded.status_report) {
                    let alertMsg = [];
                    error._embedded.status_report.messages.map(msg => alertMsg.push(msg.message))
                    let dialogRef = OcInfraModule.AppInjector.get(CommonService).showConfirmation({ content: alertMsg, custom: true }, 'CLOSE', '', 'ALERT', '393px');
                    dialogRef.afterClosed().subscribe(res => {
                        res;
                    });
                }
            });
        }
    }


    get federal_withholding_amount() {
        return this.surrenderForm.get('federal_withholding_amount').value;
    }

    set federal_withholding_amount(val) {
        this.surrenderForm.get('federal_withholding_amount').setValue(val);
    }

    get federal_withholding_percentage() {
        return this.surrenderForm.get('federal_withholding_percentage').value;
    }

    set federal_withholding_percentage(val) {
        this.surrenderForm.get('federal_withholding_percentage').setValue(val);
    }

    get state_withholding_amount() {
        return this.surrenderForm.get('state_withholding_amount').value;
    }

    set state_withholding_amount(val) {
        this.surrenderForm.get('state_withholding_amount').setValue(val);
    }

    get state_withholding_percentage() {
        return this.surrenderForm.get('state_withholding_percentage').value;
    }

    set state_withholding_percentage(val) {
        this.surrenderForm.get('state_withholding_percentage').setValue(val);
    }

    get surrender_amount() {
        return this.surrenderForm.get('surrender_amount').value;
    }

    set surrender_amount(val) {
        this.surrenderForm.get('surrender_amount').setValue(val);
    }

    get surrender_percent() {
        return this.surrenderForm.get('surrender_percent').value;
    }

    set surrender_percent(val) {
        this.surrenderForm.get('surrender_percent').setValue(val);
    }

    get transaction_type() {
        return this.surrenderForm.get('transaction_type').value;
    }

    set transaction_type(val) {
        this.surrenderForm.get('transaction_type').setValue(val);
    }

    get transaction_level() {
        return this.surrenderForm.get('transaction_level').value;
    }

    set transaction_level(val) {
        this.surrenderForm.get('transaction_level').setValue(val);
    }

    // integrates the API response 
    getSurrenderDetails() {
        let response = this.surrenderDetails;
        this.responseOptions = response._options;
        this.requiredFields = response._options && response._options.required || [];

        this.allFunds = JSON.parse(JSON.stringify(this.surrenderDetails.fundValues));
        for(let i=0;i<this.allFunds.length;i++){
            this.allFunds[i]['surrender_amount_cal'] = "0.00";
        }
        if(this.isGroupProduct){
        this.maxfunds = this.surrenderDetails.max_surrender_list ? JSON.parse(JSON.stringify(this.surrenderDetails.max_surrender_list)) : [];
        }

        this.totalFundValue = this.calculateTotalFundValue(this.allFunds);
        if(this.surrenderDetails && this.surrenderDetails != undefined) {
            this.filterFunds()
        }

        this.loadPermanentBasics();
        this.loadTransactionTypes();
        this.loadTransactionLevels();
        this.loadTableSortStates();

        if (this.surrenderDetails.surrender_amount != undefined) {
            //commenting because they need by default surrender amount as $0.00
            //let surrenderAmount =this.formatCurrency('', 'blur', this.surrenderDetails.surrender_amount)
            let surrenderAmount = this.formatCurrency('','blur','0.00');
            this.surrenderForm.get('surrender_amount').setValue(surrenderAmount);
          }
        if (this.surrenderDetails.surrender_cap_amount != undefined) {
            let surrenderCapAmount =this.formatCurrency('', 'blur', this.surrenderDetails.surrender_cap_amount)
            this.surrenderForm.get('surrender_cap_amount').setValue(surrenderCapAmount);
            this.surrenderForm.get('surrender_cap_amount').disable();
         }
        if (this.surrenderDetails.surrender_amount != undefined) {
            let surrenderFreeAmount =this.formatCurrency('', 'blur', this.surrenderDetails.surrender_amount)
            this.surrenderForm.get('surrender_free_amount').setValue(surrenderFreeAmount);
            this.surrenderForm.get('surrender_free_amount').disable();
         }
         if (this.surrenderDetails.cumulative_surrender_charge != undefined) {
            let surrenderCharge =this.formatCurrency('', 'blur', this.surrenderDetails.cumulative_surrender_charge)
            this.surrenderForm.get('cumulative_surrender_charge').setValue(surrenderCharge);
            this.surrenderForm.get('cumulative_surrender_charge').disable();
         }

        if (this.surrenderDetails.effective_date != undefined) {
            this.surrenderForm.get('effective_date').setValue(new Date(this.surrenderDetails.effective_date));
            this.surrenderForm.get('effective_date').disable();
        }

        if (this.surrenderDetails.no_federal_withholding != undefined) {
            let federalWithHoldValue = this.surrenderDetails.no_federal_withholding == 'N' ? false : true;
            this.surrenderForm.get('no_federal_withholding').setValue(federalWithHoldValue);
        }

        if (this.surrenderDetails.federal_withholding_amount != undefined) {
                let federal_withholding_amount = this.formatCurrency('', 'blur', '0.00')
                this.federal_withholding_amount = federal_withholding_amount;
        }
        if (this.surrenderDetails.federal_withholding_percentage != undefined) {
                let federal_withholding_percentage = this.formatPercent('', 'blur', '0.00')
                this.federal_withholding_percentage = federal_withholding_percentage
        }

        if (this.surrenderDetails.no_state_withholding != undefined) {
            let stateWithHoldValue = this.surrenderDetails.no_state_withholding == 'N' ? false : true;
            this.surrenderForm.get('no_state_withholding').setValue(stateWithHoldValue);
        }

        if (this.surrenderDetails.state_withholding_amount != undefined) {
                let state_withholding_amount = this.formatCurrency('', 'blur', '0.00');
                this.state_withholding_amount = state_withholding_amount;
        }
        if (this.surrenderDetails.state_withholding_percentage != undefined) {
                let state_withholding_percentage = this.formatPercent('', 'blur', '0.00');
                this.state_withholding_percentage = state_withholding_percentage;
        }

        this.surrenderForm.get('transaction_type').setValue('A');
        this.surrenderForm.get('transaction_level').setValue('P');
        this.surrenderForm.get('surrender_percent').disable();

        this.totalFunds = this.funds ? this.getTotalofFunds(this.funds, 'CURRENCY') : '$0.0';

        this.transactionTypeAndLevelValidators(true);
    }

    calculateTotalFundValue(funds: any) {
        return funds.reduce((total: any, fund: any) => {
            return total + parseFloat(fund.fund_value);
        }, 0);
    }

    filterFunds = () => {
        if (this.selectedAssetAccount != '**') {
            this.funds = this.surrenderDetails.fundValues.filter((item: any) => item.asset_account == this.selectedAssetAccount);
        } else {
            this.funds = JSON.parse(JSON.stringify(this.surrenderDetails.fundValues));
        }
    }

    loadPermanentBasics = () => {
        let statePctOrBasis = this.getNested(this.surrenderDetails._options.properties, 'statePctOrBasis');
        if (statePctOrBasis && statePctOrBasis.oneOf.length > 0) {
            this.statePctOrBasis = statePctOrBasis.oneOf.map((ele: OneOf): OneOfData => {
                return <OneOfData>{ value: ele.enum[0], label: ele.title };
            });
        }
    }

    loadTransactionTypes = () => {
        if(this.isGroupProduct){
            var transaction_type = this.getNested(this.surrenderDetails._options.properties, 'group_transaction_type');
        } else{
            var transaction_type = this.getNested(this.surrenderDetails._options.properties, 'transaction_type');
        }
        if (transaction_type && transaction_type.oneOf.length > 0) {
            this.transactionTypes = transaction_type.oneOf.map((ele: OneOf): OneOfData => {
                return <OneOfData>{ value: ele.enum[0], label: ele.title };
            });
        }
    }

    loadTransactionLevels = () => {
        const transactionLevels = ['P', 'F']; 
        if(this.isGroupProduct){
            var transaction_level = this.getNested(this.surrenderDetails._options.properties, 'group_transaction_level');
        } else {
            var transaction_level = this.getNested(this.surrenderDetails._options.properties, 'transaction_level');
        }
        if (transaction_level && transaction_level.oneOf.length > 0) {
            this.transactionLevels = transaction_level.oneOf.filter((item: OneOf) => transactionLevels.includes(item.enum[0])).map((ele: OneOf): OneOfData => {
                return <OneOfData>{ value: ele.enum[0], label: ele.title };
            });
        }
    }

    onTransactionTypeEvent = ($event: any) => {
        let transactionType = $event.value;
        if(this.isGroupProduct) {
            this.headers['group_transaction_type'] = transactionType;
            this.fundsIndicatorOnTypeChange();
        }
        //to reset fund amounts and percentages when we change trasaction type
        this.funds = this.allFunds;
        for(let i=0;i<this.funds.length;i++){
            this.funds[i].surrender_amt = this.surrenderDetails.fundValues[i].surrender_amt;
            this.funds[i].surrender_percent = this.surrenderDetails.fundValues[i].surrender_percent;
            if(this.funds[i].surrender_amount_cal) {
                this.funds[i].surrender_amount_cal = '0.0';
            }
        }
        this.transactionTypeAndLevelValidators();
    }

    fundsIndicatorOnTypeChange(){
        let url = this.currentPolicyResourceURL + this.quotesAPISource[this.withdrawlSurrenderType]+ "?" + this.getQueryParams();
            OcInfraModule.AppInjector.get(CommonService).getCallback(url, this.headers).subscribe(response => {
                //console.log('🚀 ~ file: partial-surrender-surrender-mrps.component.ts:165 ~ PartialSurrenderSurrenderMRPSComponent ~ OcInfraModule.AppInjector.get ~ response:', response);
                this.surrenderDetails = response;
                this.free_out_ind = response.Free_out_ind;
                if(this.free_out_ind == 'N'){
                    this.displayFundsTable = false;
                    if(response && response._embedded.status_report && response._embedded.status_report.messages.length) { 
                    let filteredError = response._embedded.status_report.messages.filter(item => item.message != 'No mapping rule matched')
                    OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(filteredError[0].message, 'alert', 0);
                    }
                } else {
                    this.displayFundsTable = true;
                    OcInfraModule.AppInjector.get(AdvGrowlService).clearMessages();
                }
                this.surrenderDetailsToEmit.emit(this.surrenderDetails);
            }, error => {
                if (error._embedded && error._embedded.status_report) {
                    let alertMsg = [];
                    error._embedded.status_report.messages.map(msg => alertMsg.push(msg.message));
                    OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error._embedded.status_report.messages[0].message, 'Error', 5000);
                }
            });
    }

    onTransactionLevelEvent = ($event: any) => {
        (this.free_out_ind == 'N') ? (this.displayFundsTable = false) : this.displayFundsTable = true;
            this.transactionTypeAndLevelValidators()
    }

    transactionTypeAndLevelValidators = (existing?: boolean) => {
        if(!existing) {
            let percentValue = this.formatPercent('', 'blur', '0.00');
            let currencyValue = this.formatCurrency('', 'blur', '0.00')
            this.surrenderForm.get('surrender_amount').setValue(currencyValue);
            this.surrenderForm.get('surrender_percent').setValue(percentValue);
        }

        if (this.transaction_type == 'A' && this.transaction_level == 'P') {
            this.fundsInfo = false;
            this.surrenderForm.get('surrender_amount').enable();
            this.surrenderForm.get('surrender_percent').disable();
            this.surrenderForm.get('surrender_amount').setValidators(Validators.required);
            this.surrenderForm.get('surrender_percent').setValidators(null);
            this.surrenderForm.controls['net_gross_indicator'].enable();
            this.surrenderForm.controls['net_gross_indicator'].setValidators(Validators.required);
        } else if ((this.transaction_type == 'P'|| this.transaction_type == 'F') && this.transaction_level == 'P') {
            this.fundsInfo = false;
            this.surrenderForm.get('surrender_amount').disable();
            this.surrenderForm.get('surrender_percent').enable();
            this.surrenderForm.get('surrender_amount').setValidators(null);
            this.surrenderForm.get('surrender_percent').setValidators(Validators.required);
            this.surrenderForm.controls['net_gross_indicator'].enable();
            this.surrenderForm.controls['net_gross_indicator'].setValidators(Validators.required);
        } else if ((this.transaction_type == 'A' || this.transaction_type == 'P'|| this.transaction_type == 'F') && this.transaction_level == 'F') {
            this.fundsInfo = true;
            this.surrenderForm.get('surrender_amount').disable();
            this.surrenderForm.get('surrender_percent').disable();
            this.surrenderForm.get('surrender_amount').setValidators(null);
            this.surrenderForm.get('surrender_percent').setValidators(null);
            this.surrenderForm.controls['net_gross_indicator'].disable();
            this.surrenderForm.controls['net_gross_indicator'].setValidators(null);
            
            if (this.transaction_type == 'A') {
                this.totalFunds = this.funds ? this.getTotalofFunds(this.funds, 'CURRENCY') : '$0.0';
            } else if (this.transaction_type == 'P'|| this.transaction_type == 'F') {
                this.totalFunds = this.funds ? this.getTotalofFunds(this.funds, 'PERCENT') : '0.0%';
            }
        }

        this.surrenderForm.get('surrender_amount').updateValueAndValidity();
        this.surrenderForm.get('surrender_percent').updateValueAndValidity();
    }


    loadTableSortStates = () => {
        this.tableSortStates = [
            { "column": "fund_number", "isAsc": false },
            { "column": "fund_name", "isAsc": false },
            { "column": "fund_value", "isAsc": true }
        ]
    }

    transactionTypeValidators = () => {
        if (this.transaction_type == 'A') {
            this.surrenderForm.get('surrender_amount').setValidators(Validators.required);
            this.surrenderForm.get('surrender_percent').setValidators(null);
        } else if (this.transaction_type == 'P'|| this.transaction_type == 'F') {
            this.surrenderForm.get('surrender_amount').setValidators(null);
            this.surrenderForm.get('surrender_percent').setValidators(Validators.required);
        }
        this.surrenderForm.get('surrender_amount').updateValueAndValidity();
        this.surrenderForm.get('surrender_percent').updateValueAndValidity();
    }

    checkLoanEligibleEvent = (value: any) => {
        this.surrender_amount = this.appendZerosIfNeeded(value);
    };

    appendZerosIfNeeded = (value) => {
        if (
            this.decimalPlaces != null &&
            value != null &&
            value !== '' &&
            value !== '0.00'
        ) {
            value = (+value).toFixed(this.decimalPlaces);
            return value;
        }
    }


    getNested(obj, ...args) {
        return args.reduce((obj, level) => obj && obj[level], obj)
    }

    isStepComplete = () => {
        return this.surrenderForm.valid;
    }


    noFederalEvent = ($event) => {
        if ($event.checked) {
            this.surrenderForm.get('federal_withholding_override').disable();
        } else {
            this.surrenderForm.get('federal_withholding_override').enable();
        }
    };

    noStateEvent = ($event) => {
        if ($event.checked) {
            this.surrenderForm.get('state_withholding_override').disable();
        } else {
            this.surrenderForm.get('state_withholding_override').enable();
        }
    };

    federalOverrideEvent = ($event: any) => {
        if ($event.checked) {
            this.surrenderForm.get('federal_withholding_amount').enable();
            this.surrenderForm.get('federal_withholding_percentage').enable();
            this.surrenderForm.get('no_federal_withholding').disable();

        } else if ($event.checked) {
            this.surrenderForm.get('federal_withholding_amount').disable();
            this.surrenderForm.get('federal_withholding_percentage').disable();
            this.surrenderForm.get('no_federal_withholding').enable();
            let federalWithHoldAValue = this.formatCurrency('', 'blur', '0.00')
            this.surrenderForm.get('federal_withholding_amount').setValue(federalWithHoldAValue);
            let federalWithHoldValue = this.formatPercent('', 'blur', '0.00')
            this.surrenderForm.get('federal_withholding_percentage').setValue(federalWithHoldValue);
        } else {
            this.surrenderForm.get('federal_withholding_amount').disable();
            this.surrenderForm.get('federal_withholding_percentage').disable();
            this.surrenderForm.get('no_federal_withholding').enable();
            let federalWithHoldAValue = this.formatCurrency('', 'blur', '0.00')
            this.surrenderForm.get('federal_withholding_amount').setValue(federalWithHoldAValue);
            let federalWithHoldValue = this.formatPercent('', 'blur', '0.00')
            this.surrenderForm.get('federal_withholding_percentage').setValue(federalWithHoldValue);
            this.passDistributionData.emit(federalWithHoldValue);
        }
    }

    stateOverrideEvent = ($event: any) => {
        if ($event.checked) {
            this.surrenderForm.get('state_withholding_amount').enable();
            this.surrenderForm.get('state_withholding_percentage').enable();
            this.surrenderForm.get('no_state_withholding').disable();
            this.surrenderForm.get('statePctOrBasis').enable();
            this.surrenderForm.get('statePctOrBasis').setValue('A');
        } else if (!this.isLife) {
            this.surrenderForm.get('state_withholding_amount').disable();
            this.surrenderForm.get('state_withholding_percentage').disable();
            this.surrenderForm.get('no_state_withholding').enable();
            let stateWithHoldAValue = this.formatCurrency('', 'blur', '0.00')
            this.surrenderForm.get('state_withholding_amount').setValue(stateWithHoldAValue);
            let stateWithHoldValue = this.formatPercent('', 'blur', '0.00')
            this.surrenderForm.get('state_withholding_percentage').setValue(stateWithHoldValue);
            this.surrenderForm.get('statePctOrBasis').disable();
            this.surrenderForm.get('statePctOrBasis').setValue('');
            
        } else {
            this.surrenderForm.get('state_withholding_amount').disable();
            this.surrenderForm.get('state_withholding_percentage').disable();
            this.surrenderForm.get('no_state_withholding').enable();
        }
    }


    federalWithholdingEvent = ($event: any, type: string) => {
        if (type == 'AMOUNT') {
            this.federal_withholding_amount = this.formatCurrency('', 'blur', this.federal_withholding_amount);
            // this.surrenderForm.get('state_withholding_percentage').disable();
            this.surrenderForm.get('federal_withholding_percentage').disable();
        }

        if (type == 'PERCENT') {
            this.federal_withholding_percentage = this.formatPercent('', 'blur', this.federal_withholding_percentage);
            this.passDistributionData.emit(this.federal_withholding_percentage);
            this.surrenderForm.get('federal_withholding_amount').disable();
            this.percentageCheck(this.federal_withholding_percentage);
        }
    };

    stateWithholdingEvent = ($event: any, type: string) => {
        if (type == 'AMOUNT') {
            this.state_withholding_amount = this.formatCurrency('', 'blur', this.state_withholding_amount);
            this.surrenderForm.get('state_withholding_percentage').disable();
            this.surrenderForm.get('statePctOrBasis').setValue('');
        }

        if (type == 'PERCENT') {
            this.state_withholding_percentage = this.formatPercent('', 'blur', this.state_withholding_percentage);
            //this.surrenderForm.get('federal_withholding_amount').disable(); //As per request in 3469 they requested to change this to partial as well
            this.surrenderForm.get('state_withholding_amount').disable();
            this.percentageCheck(this.state_withholding_percentage);
        }
    };


    getSurrenderValues = (): SurrenderValues => {

        const { federal_withholding_amount, federal_withholding_percentage, state_withholding_amount, state_withholding_percentage, calculated_surrender_amount } = this.surrenderForm.getRawValue();

        let surrenderValues: SurrenderValues = {
            effective_date: this.surrenderDetails.effective_date,
            withdrawal_type: this.withdrawlSurrenderType,

            statePctOrBasis: this.surrenderForm.get('statePctOrBasis').value,

            no_federal_withholding: this.surrenderForm.get('no_federal_withholding').value ? 'Y' : 'N',
            federal_withholding_amount: federal_withholding_amount,
            federal_withholding_percentage: federal_withholding_percentage,
            federal_withholding_override: this.surrenderForm.get('federal_withholding_override').value,

            no_state_withholding: this.surrenderForm.get('no_state_withholding').value ? 'Y' : 'N',
            state_withholding_amount: state_withholding_amount,
            state_withholding_percentage: state_withholding_percentage,
            state_withholding_override: this.surrenderForm.get('state_withholding_override').value,

            transaction_type: this.surrenderForm.get('transaction_type').value,
            transaction_level: this.surrenderForm.get('transaction_level').value,
            surrender_amount: this.surrenderForm.get('surrender_amount').value?this.surrenderForm.get('surrender_amount').value:"$0.00",
            surrender_percent: this.surrenderForm.get('surrender_percent').value?this.formatPercent('', 'blur', this.surrenderForm.get('surrender_percent').value):"0.00%",

            transaction_amount: this.totalFunds,
            calculated_surrender_amount: this.totalFundscalculated ? this.formatCurrency('', 'blur',this.totalFundscalculated):"$0.00"
        };
        return surrenderValues;
    }

    percentageCheck(value){
        let totalWithPer = value.includes('%') ? value.slice(0,-1) : value;
        let totalInt = Math.trunc(totalWithPer);
        let finalVal = totalInt.toString();
        if(finalVal.length>2){
            let alertMsg = [];
            alertMsg.push("You can not enter more than 2 digits");
            let dialogRef = OcInfraModule.AppInjector.get(CommonService).showConfirmation({ content: alertMsg, custom: true }, 'CLOSE', '', 'ALERT', '393px');
            dialogRef.afterClosed().subscribe(res => {
                res;
            });
        }
    }


    ngAfterViewInit() {
        setTimeout(() => {
            this.getSurrenderDetails();
            this.getSurrenderValues();
        }, 0);
    }

    ngOnDestroy() {
        setTimeout(() => {
            // this.activeTab$.unsubscribe();
        }, 0);
    }

  // Sorts the fund table based on the column
  sortTable = (column: any) => {
    let isAsc: any;
    let _self = this;
    _.forEach(_self.tableSortStates, function (obj, index) {
        if (obj['column'] == column) {
            isAsc = !obj['isAsc'];
            _self.tableSortStates[index]['isAsc'] = isAsc;
        }
    })
    this.funds = isAsc ? _.orderBy(this.funds, this.byKey(column), 'asc') : _.orderBy(this.funds, this.byKey(column), 'desc');
}

// checks if it is a Number String
byKey = (key: any) => {
    return function (o: any) {
        if (typeof o[key] === 'string') {
            return isNaN(+o[key].replace(/[^a-zA-Z0-9]/g, '')) ? o[key] : +o[key];
        }
        return o[key];
    };
}



    //formats the value with commas and $ allowing 2 decimal values
    formatCurrency(input, blur, respValue) {
        var input_val = "";
        if (respValue == '' || respValue == undefined) {
            input_val = input.target.value;
        } else {
            input_val = respValue;
        }
        if (input_val === "") { return; }
        var original_len = input_val.length;
        if (input_val.indexOf(".") >= 0) {
            var decimal_pos = input_val.indexOf(".");
            decimal_pos = decimal_pos > 15 ? 15 : decimal_pos;
            var left_side = input_val.substring(0, decimal_pos);
            var right_side = input_val.substring(decimal_pos);
            left_side = this.formatNumber(left_side, 'currency', true);
            right_side = this.formatNumber(right_side, 'currency', false);
            if (blur === "blur") {
                right_side += "00";
            }
            // Limit decimal to only 2 digits
            right_side = right_side.substring(0, 2);
            // join number by .
            input_val = "$" + left_side + "." + right_side;

        } else {
            // no decimal entered
            // add commas to number
            // remove all non-digits
            input_val = input_val.substring(0, 15);
            input_val = this.formatNumber(input_val, 'currency', true);
            input_val = "$" + input_val;
            // final formatting
            if (blur === "blur") {
                input_val += ".00";
            }
        }
        if (input_val.charAt(input_val.indexOf('$') + 1) == '.') {
            input_val = input_val.replace(input_val.charAt(input_val.indexOf('$') + 1), '0.');
        }
        if (respValue == '' || respValue == undefined) {
            input.target["value"] = input_val;
        } else {
            return input_val;
        }
    }

    // formats the value with commas and percentage allowing 1 decimal value
    formatPercent(input, blur, respValue) {
        var input_val = "";
        if (respValue == '' || respValue == undefined) {
            input_val = input.target.value;
        } else {
            input_val = respValue;
        } if (input_val === "") { return; }
        var original_len = input_val.length;
        if (input_val.indexOf(".") >= 0) {
            var decimal_pos = input_val.indexOf(".");
            var left_side = input_val.substring(0, decimal_pos);
            var right_side = input_val.substring(decimal_pos);
            left_side = this.formatNumber(left_side, 'percent', true);
            right_side = this.formatNumber(right_side, 'percent', false);
            if (blur === "blur") {
                right_side += "00";
            }
            // Limit decimal to only 2 digits
            right_side = right_side.substring(0, 2);
            // join number by .
            input_val = left_side + "." + right_side + "%";
        } else {
            // no decimal entered
            // add commas to number
            // remove all non-digits
            input_val = this.formatNumber(input_val, 'percent', true);
            if (blur === "blur") {
                input_val += ".00";
            }
            input_val = input_val + "%";
        }
        if (input_val.charAt(input_val.indexOf('$') + 1) == '.') {
            input_val = input_val.replace(input_val.charAt(input_val.indexOf('$') + 1), "0.")
        }
        if (respValue == '' || respValue == undefined) {
            input.target["value"] = input_val;
        } else {
            return input_val;
        }
    }

    // input_val = this.formatNumber(input_val, 'currency', true);
    percentChange($event: any, key: any, index: any) {
        let input_val = $event.target.value.replace(/,|\s/g, "").replace("%", "").replace("$", "");
        if (key == 'surrender_percent') {
            this.surrender_percent = this.formatPercent('', 'blur', input_val);
            this.SurrPercentageCheck($event,this.surrender_percent);
        }
    }

    SurrPercentageCheck (event,inputVal) {
        let totalFundsWithPer = inputVal.includes('%') ? inputVal.slice(0,-1) : inputVal;
        let totalFundsInt = Number(totalFundsWithPer);
        if(totalFundsInt>100) {
            let alertMsg = [];
            alertMsg.push("Total Percentage can not be more than 100");
            let dialogRef = OcInfraModule.AppInjector.get(CommonService).showConfirmation({ content: alertMsg, custom: true }, 'CLOSE', '', 'ALERT', '393px');
            dialogRef.afterClosed().subscribe(res => {
                event.target.value = this.formatPercent('', 'blur', '0.0');
                this.surrender_percent = event.target.value;
                res;
            });
        } else {
            let grossCashValue = this.surrenderDetails.gross_cash_value;
            this.calculatePerToAmount(grossCashValue,inputVal,event);
        }
    }

    inputChange($event: any, key: any, index: any) {
        let input_val = $event.target.value.replace(/,|\s/g, "").replace("%", "").replace("$", "");
        if (key == 'surrender_percent') {
            this.surrender_percent = this.formatPercent('', 'blur', input_val);
        } else if (key == 'surrender_amount') {
            this.surrender_amount = this.formatCurrency('', 'blur', input_val);
            if (this.isAmountAllowableAlertFull(input_val)) {
                let content = 'Can not withdraw more than $29,999.99';
                input_val = "0.0";
                let dialogRef = OcInfraModule.AppInjector.get(CommonService).showConfirmation(content, 'ok', '', 'ALERT', '393px');
                dialogRef.afterClosed().subscribe(res => {
                    $event.target.value = this.formatCurrency('', 'blur', '0.0');
                    this.surrenderForm.controls.surrender_amount.setValue($event.target.value);
                    res;
                });
            }
        } else if (key == 'surrender_amt' || key == 'surrender_pct') {
            
            if (key == 'surrender_amt') {
                // let surrender_amt = this.formatCurrency('', 'blur', input_val);
                //this.surrenderDetails.transa
                this.funds[index].surrender_amt = input_val;
                this.totalFunds = this.funds ? this.getTotalofFunds(this.funds, 'CURRENCY') : '$0.0';
                let total = this.totalFunds.replace('$','').replace(',','');
                if (this.isAmountAllowableAlertFull(total)) {
                    let content = 'Can not withdraw more than $29,999.99';
                    input_val = "0.0";
                    this.funds[index].surrender_amt = input_val;
                    this.totalFunds = this.funds ? this.getTotalofFunds(this.funds, 'CURRENCY') : '$0.0';
                    let total = this.totalFunds.replace('$','').replace(',','');
                    let dialogRef = OcInfraModule.AppInjector.get(CommonService).showConfirmation(content, 'ok', '', 'ALERT', '393px');
                    dialogRef.afterClosed().subscribe(res => {
                        $event.target.value = this.formatCurrency('', 'blur', '0.0');
                        res;
                    });
                }
            } else if (key == 'surrender_pct') {

                this.funds[index].surrender_percent = input_val;
                let currentFundValue= this.funds[index];
                //this.SurrPercentageCheck(this.funds[index].surrender_percent);
                let totalFundsWithPer = input_val.includes('%') ? input_val.slice(0,-1) : input_val;
                let totalFundsInt = Number(totalFundsWithPer);
                if(totalFundsInt>100) {
                let alertMsg = [];
                input_val = '0.0';
                this.funds[index].surrender_percent = input_val;
                alertMsg.push("Total Percentage can not be more than 100");
                let dialogRef = OcInfraModule.AppInjector.get(CommonService).showConfirmation({ content: alertMsg, custom: true }, 'CLOSE', '', 'ALERT', '393px');
                dialogRef.afterClosed().subscribe(res => {
                    $event.target.value = this.formatPercent('', 'blur', '0.0');
                    this.surrender_percent = $event.target.value;
                    res;
               });
            } else {
                this.calculatePerToAmount(currentFundValue,input_val,$event);
            }
                this.totalFunds = this.funds ? this.getTotalofFunds(this.funds, 'PERCENT') : '0.0%';
            }
        }
    }

    calculatePerToAmount(funds,perEntered,event){
        if(this.surrenderForm.get('transaction_level').value == 'F') {
        let fundValuee = funds.fund_value; 
        this.totalFundscalculated = '';
        let perToAmountCal = ((fundValuee * perEntered)/100);
        perToAmountCal = parseFloat(perToAmountCal.toFixed(2))
        funds.surrender_amount_cal = perToAmountCal.toString();
        this.totalFundscalculated = this.funds ? this.getTotalofFunds(this.funds,'AmountCal') : '$0.0';
        //total = this.getTotalofFunds(, 'CURRENCY');
        this.totalFundscalculated = this.totalFundscalculated.replace('$','').replace(',','');
        if(this.isAmountAllowableAlertFull(this.totalFundscalculated)){
            perEntered = '0.0';
            funds.surrender_percent = perEntered;
            funds.surrender_amount_cal = '0.00'
            this.totalFundscalculated = this.funds ? this.getTotalofFunds(this.funds,'AmountCal') : '$0.0';
            let content = 'Can not withdraw more than $29,999.99';
            let dialogRef = OcInfraModule.AppInjector.get(CommonService).showConfirmation(content, 'ok', '', 'ALERT', '393px');
                    dialogRef.afterClosed().subscribe(res => {
                        event.target.value = this.formatPercent('', 'blur', '0.0');
                        res;
                    });
            }
        } else if(this.surrenderForm.get('transaction_level').value == 'P') {
            perEntered = perEntered.replace('%','');
            this.totalFundscalculated = ''; //using same variable used for fundspecific
            let perToAmountCal: any;
            let totalFund: any;
            if(this.isGroupProduct) {
                if(this.surrenderForm.controls.transaction_type.value == 'P' && this.surrenderForm.controls.accountType.value != '**'){
                let selectedAssetAccount = this.surrenderForm.controls.accountType.value.split(' -')[0];
                let maxSurrenderList = this.surrenderDetails.max_surrender_list.filter(e => e.asset_account == selectedAssetAccount);
                totalFund = maxSurrenderList[0].max_surrender_amount;
                } else if(this.surrenderForm.controls.transaction_type.value == 'P' && this.surrenderForm.controls.accountType.value == '**'){
                    totalFund = this.totalFundValue;
                } else { //this is for transaction type F
                    totalFund = this.surrenderDetails.surrender_amount;
                }
                perToAmountCal = (totalFund * perEntered)/100;
            } else {
                perToAmountCal = ((this.totalFundValue * perEntered)/100);
            }
            perToAmountCal = parseFloat(perToAmountCal.toFixed(2));
            if(this.isAmountAllowableAlertFull(perToAmountCal)){
                //funds.surrender_percent = perEntered;
                //funds.surrender_amount_cal = '$0.00'
                //this.totalFundscalculated = this.funds ? this.getTotalofFunds(this.funds,'AmountCal') : '$0.0';
                let content = 'Can not withdraw more than $29,999.99';
                let dialogRef = OcInfraModule.AppInjector.get(CommonService).showConfirmation(content, 'ok', '', 'ALERT', '393px'); 
                        dialogRef.afterClosed().subscribe(res => {
                            this.surrenderForm.controls['surrender_percent'].setValue(this.formatPercent('','blur','0.0'));
                            this.surrenderForm.controls['surremder_amount'].setValue(this.formatCurrency('','blur','0.0'));
                            res;
                        });
                } else{
                    //let formatedamount = this.formatCurrency('','blur',perToAmountCal);
                    //this.surrenderForm.controls['surrender_amount'].setValue(perToAmountCal);
                    let formattedCurrency = this.formatCurrency('','blur',perToAmountCal.toString());
                    this.surrenderForm.controls['surrender_amount'].setValue(formattedCurrency);

                }
        }
    }

    getTotalofFunds(funds: any, type: string) {
        let total = 0.0;
        let tot = '';
        if (type == 'PERCENT') {
            total = funds.reduce((n, { surrender_percent }) => n + Number(surrender_percent), 0);
            tot = this.formatPercent('', 'blur', total.toString());
        } else if (type == 'CURRENCY') {
            total = funds.reduce((n, { surrender_amt }) => n + Number(surrender_amt), 0);
            tot = this.formatCurrency('', 'blur', total.toString());
        } else if (type == 'AmountCal') {
            total = funds.reduce((n, { surrender_amount_cal }) => n + Number(surrender_amount_cal), 0);
            tot = this.formatCurrency('', 'blur', total.toString());
        }

        return tot;
    }

    /** AMOUNT AND PERCENT FORMATING  */
    formatNumber(n, type, flag) {
        if (flag) {
            n = n.charAt(0) == '0' ? n.replace(n.charAt(0), "") : n;
        }
        if (type == 'currency') {
            return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } else {
            return n.replace(/\D/g, "");
        }
    }

    isAmountAllowableAlertFull(input_val: any) {
        if (Number(input_val) > 29999.99) {
            return true
        } else {
            return false;
        }
    }


    processA4Product = async () => {
        this.clientProductsUrl = this.policyDetails && this.policyDetails._links && this.policyDetails._links.clientproducts ? this.policyDetails._links.clientproducts.href : null;
        this.assetAccountsUrl = this.policyDetails && this.policyDetails._links && this.policyDetails._links.clientassetaccounts ? this.policyDetails._links.clientassetaccounts.href : null;

        try {
            let assetAccounts = await this.commonService.getClientAssetAccountsAll();
            if (!assetAccounts) {
                this.assetAccounts = assetAccounts;
            } else {
                assetAccounts = await this.commonService.getAssetAccountsApi(this.assetAccountsUrl, this.headers)
                this.assetAccounts = assetAccounts;
            }

            let clientProducts = await this.commonService.getClientProducts();
            if (!clientProducts) {
                this.commonService.clientProducts = clientProducts;
                this.clientProducts = clientProducts;
                this.processProductTypes(this.clientProducts);
            } else {
                let clientProducts = await this.commonService.getClientProductsApi(this.clientProductsUrl, this.headers)
                this.clientProducts = clientProducts;
                this.processProductTypes(this.clientProducts);
            }

        } catch (error) {
        }
    }

    processProductTypes = (clientProducts: ItemProducts[]) => {
        if (clientProducts.length > 0) {
            this.clientProductItems = clientProducts.map((item: any) => {
                return { label: item.summary.plan_code, value: item.summary.plan_code }
            });
        }

        if (this.clientProductItems.length === 1) {
            this.productTypeChange(this.clientProductItems[0].value)
        }
    }

    productTypeChange = (value: any) => {
        this.surrenderForm.get('productType').setValue(value);
        let assetAccounts = this.assetAccounts.find((item: ItemAssetAccount) => item.summary.plan_code == value);
        this.assetAccountItems = assetAccounts.summary.asset_account_names.filter((item: any) => {
            return (item != '- null') && { value: item, label: item }
        }).map((item: any) => ({
            value: item === 'ALL ASSET ACCOUNTS' ? '**' : item,
            label: item
        }));
        this.accountTypeChange(this.assetAccountItems[0].value);
    }

    accountTypeChange = (value: any, changeFlag?: boolean) => {
        if (value == "**") {
            this.selectedAssetAccount = '**';
          } else {
            this.selectedAssetAccount = value.substring(0, value.indexOf(' '));
        }
        this.surrenderForm.get('accountType').setValue(value);
        let plan_code = this.surrenderForm.controls.productType.value;
        this.headers = { ...this.headers, ...{ plan_code: plan_code, client_id: this.commonService.productInfo.client, asset_account: this.selectedAssetAccount } }
        let quoteEndpoint = this.currentPolicyResourceURL + this.quotesAPISource[this.withdrawlSurrenderType] + "?" + this.getQueryParams();
        if(changeFlag) {
            this.filterFunds()
        } else {
            this.getPartialSurrenderMRPS(quoteEndpoint);
        }
        if(this.surrenderForm.controls.transaction_level.value == 'P' && this.isGroupProduct){
        let input_val = this.surrenderForm.controls.surrender_percent.value;
        this.calculatePerToAmount('',input_val,'');
        }
    }


}
