import { ValidatorFn, Validators } from "@angular/forms";


interface MenuItems {
    label: string;
    value: string;
}

export interface DynamicFormFieldModel {
    id: string;
    type: 'text' | 'select' | 'checkbox' | 'hr' | 'label';
    value?: any;
    label?: string;
    validators?: ValidatorFn[];
    change?: boolean;
    keypress?: string;
    selectMenuOptions?: MenuItems[];
    hide?: boolean;
    disable?: boolean;
    required?: boolean;
    class?: string
}

export const confirmFormFields: DynamicFormFieldModel[] = [
    {
        id: 'effective_date',
        label: 'Transaction Effective Date',
        value: '',
        type: 'label',
    },
    {
        id: 'mrd_initial_start_date',
        label: 'Start Date',
        value: '',
        type: 'label',
    },
    {
        id: 'next_payout_date',
        label: 'Next RMD Date',
        value: '',
        type: 'label',
    },
    {
        id: 'end_date',
        label: 'End Date',
        value: '',
        type: 'label',
    },
    {
        id: 'trx_level_ind_label',
        label: 'Transaction Level',
        value: '',
        type: 'label',
    },
    {
        id: 'rmd_mode_label',
        label: 'RMD Mode',
        value: '',
        type: 'label',
        class: '',
    },
    {
        id: 'rmd_day_label',
        label: 'RMD Day',
        value: '',
        type: 'label',
        class: '',
    },
    {
        id: 'rmd_rules_label',
        label: 'RMD Rule',
        value: '',
        type: 'label',
        class: '',
    },
    {
        id: 'rmd_type_label',
        label: 'RMD Type',
        value: '',
        type: 'label',
        class: '',
    },
    {
        id: 'distribution_code_label',
        label: 'Distribution Code',
        value: '',
        type: 'label',
        class: '',
    },
    {
        id: 'confirmation_number',
        label: 'Confirmation Number',
        value: '',
        type: 'label',
        class: '',
    },
    {
        id: 'wthldPctSt',
        label: 'State Withholding Percent',
        value: '',
        type: 'label',
        class: '',
    },
    {
        id: 'wthldPctFed',
        label: 'Federal Withholding Percent',
        value: '',
        type: 'label',
        class: '',
    },
    {
        id: 'wthldAmtSt',
        label: 'State Withholding Amount',
        value: '',
        type: 'label',
        class: '',
    },
    {
        id: 'wthldAmtFed',
        label: 'Federal Withholding Amount',
        value: '',
        type: 'label',
        class: '',
    }
];

export interface ConfirmPayload {
    mrd_initial_start_date: string
    next_payout_date: string
    trx_level_ind: string
    effective_date: string
    rmd_mode: string
    business_closehour: number
    rmd_type: string
    eft_id : string
    rmd_day: string
    rmd_rules: string
    fed_Wthor_ind: boolean
    state_wthor_ind: boolean
    state_pctOr_basis: string
    wthldPctSt: string
    wthldPctFed: string
    wthldAmtSt: string
    wthldAmtFed: string
    disbursement_ind: string
    distribution_code: string
    bene_relation_code: string
    bene_status_indicator: string
    mrd_bene_ssn: string
    mrd_bene_dob: string
    policyNumber: string
    incontactId: string
  }
  
