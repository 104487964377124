
export interface QuoteFormFieldModel {
    id: string;
    label: string;
    value: string;
    checked?: string;
    pipe?: string
}

export enum quoteSectionOneId {
    GrossCashValue = 'gross_cash_value',
    GrossCheckAmount = 'gross_check_amount',
    FreeWithdrawalAmount = 'free_withdrawal_amount',
    MaxPartialSurrenderValue = 'max_partial_surrender_value',
    SurrenderPenalty = 'surrender_penalty',
    AdminFee = 'admin_fee',
    TotalMVAAmount = 'mva_amt',
    RedemptionFee = 'redemption_fee',
    TaxableAmount = 'taxable_amount',
    NetCheckAmount = 'net_check_amount',
    RemainingGMWBAmount = 'remaining_GMWB_amount',
}


export enum quoteSectionTwoId {
    FederalWithholding = 'federal_withholding_amount',
    StateWithholding = 'state_withholding_amount',
    MunicipalWithholding = 'municipal_withholding_amount',
    PremiumTax = 'premiumTax'
}


export enum quoteSectionOneLabel {
    GrossCashValue = 'Gross Cash Value',
    GrossCheckAmount = 'Gross Check Amount',
    FreeWithdrawalAmount = 'Free Withdrawal Amount',
    MaxPartialSurrenderValue = 'Max Partial Surrender Value',
    SurrenderPenalty = 'Surrender Penalty',
    AdminFee = 'Admin Fee',
    TotalMVAAmount = 'Total MVA Amount',
    RedemptionFee = 'Redemption Fee',
    TaxableAmount = 'Taxable Amount',
    NetCheckAmount = 'Net Check Amount',
    RemainingGMWBAmount = 'Remaining GMWB Amount',
}


export enum quoteSectionTwoLabel {
    FederalWithholding = 'Federal Withholding',
    StateWithholding = 'State Withholding',
    MunicipalWithholding = 'Municipal Withholding',
    PremiumTax = 'Premium Tax',}



export const sectionOneFormFields = () => {

    return [
        {
            id: quoteSectionOneId.GrossCashValue,
            label: quoteSectionOneLabel.GrossCashValue,
            value: '',
            checked: 'checked',
            pipe: 'CURRENCY'
        },
        {
            id: quoteSectionOneId.GrossCheckAmount,
            label: quoteSectionOneLabel.GrossCheckAmount,
            value: '',
            checked: '',
            pipe: 'CURRENCY'
        },
        {
            id: quoteSectionOneId.FreeWithdrawalAmount,
            label: quoteSectionOneLabel.FreeWithdrawalAmount,
            value: '',
            checked: '',
            pipe: 'CURRENCY'
        },
        {
            id: quoteSectionOneId.MaxPartialSurrenderValue,
            label: quoteSectionOneLabel.MaxPartialSurrenderValue,
            value: '',
            checked: '',
            pipe: 'CURRENCY'
        },
        {
            id: quoteSectionOneId.SurrenderPenalty,
            label: quoteSectionOneLabel.SurrenderPenalty,
            value: '',
            checked: '',
            pipe: 'CURRENCY'
        },
        {
            id: quoteSectionOneId.AdminFee,
            label: quoteSectionOneLabel.AdminFee,
            value: '',
            checked: '',
            pipe: 'CURRENCY'
        },
        {
            id: quoteSectionOneId.TotalMVAAmount,
            label: quoteSectionOneLabel.TotalMVAAmount,
            value: '',
            checked: '',
            pipe: 'CURRENCY'
        },
        {
            id: quoteSectionOneId.RedemptionFee,
            label: quoteSectionOneLabel.RedemptionFee,
            value: '',
            checked: '',
            pipe: 'CURRENCY'
        },
        {
            id: quoteSectionOneId.TaxableAmount,
            label: quoteSectionOneLabel.TaxableAmount,
            value: '',
            checked: '',
            pipe: 'CURRENCY'
        },
        {
            id: quoteSectionOneId.NetCheckAmount,
            label: quoteSectionOneLabel.NetCheckAmount,
            value: '',
            checked: '',
            pipe: 'CURRENCY'
        },
        {
            id: quoteSectionOneId.RemainingGMWBAmount,
            label: quoteSectionOneLabel.RemainingGMWBAmount,
            value: '',
            checked: '',
            pipe: 'CURRENCY'
        }
    ];

}

export const sectionTwoFormFields = () => {

    return [
        {
            id: quoteSectionTwoId.FederalWithholding,
            label: quoteSectionTwoLabel.FederalWithholding,
            value: '',
            checked: '',
            pipe: 'CURRENCY'
        },
        {
            id: quoteSectionTwoId.StateWithholding,
            label: quoteSectionTwoLabel.StateWithholding,
            value: '',
            checked: '',
            pipe: 'CURRENCY'
        },
        {
            id: quoteSectionTwoId.MunicipalWithholding,
            label: quoteSectionTwoLabel.MunicipalWithholding,
            value: '',
            checked: '',
            pipe: 'CURRENCY'
        },
        {
            id: quoteSectionTwoId.PremiumTax,
            label: quoteSectionTwoLabel.PremiumTax,
            value: '',
            checked: '',
            pipe: 'CURRENCY'
        }
    ];

}



export interface ItemProducts {
    summary: SummaryProducts
    href: string
}

export interface SummaryProducts {
    plan_code: string
    product_code: string
    _links: any
    quote_effective_date: string
    product_type: string
}

export interface SelectedFormValue {
    id: string
    label: string
    value: string
    checked?: string
    pipe?: string
    unique?: string
    key?: string
}

export interface ItemAssetAccount {
    summary: SummaryAsset
    href: string
}

export interface SummaryAsset {
    asset_account: string[]
    plan_code: string
    _links: any
    asset_account_names: string[]
}
export const defaultFormFields = () => {
    return [
        // {
        //     id: 'effective_date',
        //     label: 'Effective Date',
        //     value: '',
        //     checked: '',
        //     pipe: ''
        // },
        {
            id: quoteSectionOneId.GrossCashValue,
            label: quoteSectionOneLabel.GrossCashValue,
            value: '',
            checked: 'checked',
            pipe: 'CURRENCY'
        },
        {
            id: 'withdraw_amount',
            label: 'Amount Requested',
            value: '',
            checked: 'checked',
            pipe: 'CURRENCY'
        }
    ]
}