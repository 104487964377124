import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ResourceService } from '@diaas/ux-web';
import { CommonService } from '../../services/common.service';
import { OcInfraModule } from '@diaas/ux-web';
import { AdvGrowlService } from 'primeng-advanced-growl';
import { DatePipe } from '@angular/common';
import { systematicWithdralFormModal } from './constants';
import { WithdrawalSurrenderAction } from './withdrawalSurrenderAction'
import { cloneDeep } from 'lodash';

@Component({
  selector: 'systematic-withdrawl',
  templateUrl: './systematicWithdrawlComponent.html',
  styleUrls: ['./systematicWithdrawlComponent.scss']
})

export class SystematicWithdrawlComponent implements OnInit {
  fundTableData: any;
  systematicWithdralFormModal: any;
  withdrawalSurrenderAction: WithdrawalSurrenderAction;
  showFundValue: boolean = false;
  isAmountInput: boolean = false;
  fundUnit: string = 'Amount';
  fundValue: any;

  @Input() policyDetails: any;
  @Input() surrenderDetails: any;
  @Input() headers: any;
  @Input() disableFields: boolean;

  @Output() withdrawlSurrenderModel = new EventEmitter<any>();

  constructor(private datePipe: DatePipe) { }

  ngOnInit() {
    //Assigning default values to form modal.
    this.systematicWithdralFormModal = cloneDeep(systematicWithdralFormModal);  

   // Fetching data from Api for systematic withdrawal
   this.getPolicySystematicWithdrawl();

   const federalWithholdingOverride = this.systematicWithdralFormModal.find((model) => model.id === 'federal_withholding_override');
   const stateWithholdingOverride = this.systematicWithdralFormModal.find((model) => model.id === 'state_withholding_override');
   const noStateWithholding = this.systematicWithdralFormModal.find((model) => model.id === 'no_state_withholding');
   const noFederalWithholding = this.systematicWithdralFormModal.find((model) => model.id === 'no_federal_withholding');

   noStateWithholding.disabled = federalWithholdingOverride.value === 'Y' ||  stateWithholdingOverride.value === 'Y';
   noFederalWithholding.disabled = federalWithholdingOverride.value === 'Y' ||  stateWithholdingOverride.value === 'Y';
   stateWithholdingOverride.disabled = noStateWithholding.value === 'Y' || noFederalWithholding.value === 'Y';
   federalWithholdingOverride.disabled = noStateWithholding.value === 'Y' || noFederalWithholding.value === 'Y';
   //setting default value to form  
   this.getFundTableValidation();
  }

  isStepComplete() {
    return !(this.systematicWithdralFormModal.findIndex(field => field.required 
        && (!field.value || (field.type== "checkbox" && field.value == "N")) 
        && !field.disabled) > -1)
  }

  isRequiredCallNoteField(field) {
    return this.disableFields || ["effective_date", "end_date", "next_date"].includes(field.id);
  }
  
  getPolicySystematicWithdrawl() {
    const schema = this.surrenderDetails._options.links[0].schema.properties;

    this.systematicWithdralFormModal.forEach((item) => {
      if (this.surrenderDetails[item.id] && typeof this.surrenderDetails[item.id] !== 'object') {     
        item.value = (schema[item.id] && schema[item.id].format === 'currency')
        ? `$${parseFloat(this.surrenderDetails[item.id]).toFixed(2).toLocaleString()}`
          : this.surrenderDetails[item.id];
          if (schema[item.id] && schema[item.id].format === 'date') {
            item.value = new Date(item.value);
          };
      } 

      if (item.id == 'fundValues') {
        item.values = this.surrenderDetails[item.id].length > 0 ? this.surrenderDetails[item.id]: [];
        if (item.values && item.values.length > 0) {
          item.values.forEach(value => {
            value.fund_value = Number(value.fund_value).toLocaleString('USD',{style:'currency',currency:"USD",minimumFractionDigits:2,maximumFractionDigits:2});
            value.surrender_amt = Number(value.surrender_amt).toLocaleString('USD',{style:'currency',currency:"USD", minimumFractionDigits:2,maximumFractionDigits:2});
            value.surrender_unit = Number(value.surrender_unit).toFixed(2);
          });
        }
      }

      if (item.id == 'transaction_level') {
        item.value == 'F' ? this.showFundValue = true : this.showFundValue = false;
      }

      // populate Select values
      if (item.type == 'select' && schema[item.id] && schema[item.id].oneOf ) {
        let selectOptions = [];
        schema[item.id].oneOf.map(option =>{           
            selectOptions.push({"label":option.title, "value":option.enum.join(",")}) 
        });
        item.values = selectOptions;
      }  
          
      // populate checkbox values
      if (item.type == 'checkbox') {        
        if (item.value !== '' 
         || item.value !== undefined) {
          this.checkboxChange(item, item.value == 'Y'? true: false);
        }
        
        this.checkIfSelected(item.id);       
      }
      if (['state_withholding_percentage', 'federal_withholding_percentage', 'payout_percent'].includes(item.id)) {
        item.value = item.value ? `${item.value}%`: '0.0%';
      }
    })

    this.WithdrawalAmountPayoutPercentValication()
  }

  WithdrawalAmountPayoutPercentValication() {
    this.systematicWithdralFormModal[this.getIndexOfField('payout_percent')].required = true;
    this.systematicWithdralFormModal[this.getIndexOfField('payout_percent')].disabled = false;
    this.systematicWithdralFormModal[this.getIndexOfField('withdrawal_amount')].required = true;
    this.systematicWithdralFormModal[this.getIndexOfField('withdrawal_amount')].disabled = false;

    var switchIndicator = this.systematicWithdralFormModal[this.getIndexOfField('option')].value
                        + this.systematicWithdralFormModal[this.getIndexOfField('transaction_level')].value;
    switch (switchIndicator) {
      case "AF":
      case "CF":
      case "CP":
      case "FF":
      case "GP":
      case "IF":
      case "LF":
      case "LP":
      case "NF":
      case "NP":
      case "PF":
      case "TF":
      case "TP":
      case "UF":
        this.systematicWithdralFormModal[this.getIndexOfField('payout_percent')].value = '';
        this.systematicWithdralFormModal[this.getIndexOfField('payout_percent')].required = false;
        this.systematicWithdralFormModal[this.getIndexOfField('payout_percent')].disabled = true;
        this.systematicWithdralFormModal[this.getIndexOfField('withdrawal_amount')].value = '';
        this.systematicWithdralFormModal[this.getIndexOfField('withdrawal_amount')].required = false;
        this.systematicWithdralFormModal[this.getIndexOfField('withdrawal_amount')].disabled = true;
        break;
      case "AP":
      case "FP":
      case "IP":
        this.systematicWithdralFormModal[this.getIndexOfField('payout_percent')].value = '';
        this.systematicWithdralFormModal[this.getIndexOfField('payout_percent')].required = false;
        this.systematicWithdralFormModal[this.getIndexOfField('payout_percent')].disabled = true;
        this.systematicWithdralFormModal[this.getIndexOfField('withdrawal_amount')].required = true;
        this.systematicWithdralFormModal[this.getIndexOfField('withdrawal_amount')].disabled = false;
        break;
      case "PP":
      case "SP":
        this.systematicWithdralFormModal[this.getIndexOfField('withdrawal_amount')].value = '';
        this.systematicWithdralFormModal[this.getIndexOfField('withdrawal_amount')].required = false;
        this.systematicWithdralFormModal[this.getIndexOfField('withdrawal_amount')].disabled = true;
        this.systematicWithdralFormModal[this.getIndexOfField('payout_percent')].required = true;
        this.systematicWithdralFormModal[this.getIndexOfField('payout_percent')].disabled = false;
    }

    if (this.systematicWithdralFormModal[this.getIndexOfField('withdrawal_amount')].value !== '') {
      this.systematicWithdralFormModal[this.getIndexOfField('payout_percent')].value = '';
      this.systematicWithdralFormModal[this.getIndexOfField('payout_percent')].required = false;
      this.systematicWithdralFormModal[this.getIndexOfField('payout_percent')].disabled = true;
    }
    else if (this.systematicWithdralFormModal[this.getIndexOfField('payout_percent')].value !== '') {
      this.systematicWithdralFormModal[this.getIndexOfField('withdrawal_amount')].value = '';
      this.systematicWithdralFormModal[this.getIndexOfField('withdrawal_amount')].required = false;
      this.systematicWithdralFormModal[this.getIndexOfField('withdrawal_amount')].disabled = true;
    }
  }
 
   /** AMOUNT AND PERCENT FORMATING  */
  formatNumber(n, type) {
    if (type == 'currency') {
      // format number 1000000 to 1,234,567
      return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    else if (type == 'percent'){
      return n.replace(/\D/g, "");
    }
    else return n;
  }

  formatCurrency(input, blur, respValue, field=undefined) {
    var input_val = "";
    if (respValue == '' || respValue == undefined) {
      input_val = input.target.value;
    } else {
      input_val = respValue;
    }
  
    if (input_val === "") { 
      return; 
    }
    
    var original_len = input_val.length;
    if (input_val.indexOf(".") >= 0) {
      var decimal_pos = input_val.indexOf(".");
      decimal_pos = decimal_pos > 15 ? 15 : decimal_pos;
      var left_side = input_val.substring(0, decimal_pos);
      var right_side = input_val.substring(decimal_pos);
      left_side = this.formatNumber(left_side, 'currency');
      right_side = this.formatNumber(right_side, 'currency');
      if (blur === "blur") {
        right_side += "00";
      }
    
      // Limit decimal to only 2 digits
      right_side = right_side.substring(0, 2);
      // join number by .
      input_val = "$" + left_side + "." + right_side;
    } else {
      // no decimal entered
      // add commas to number
      // remove all non-digits
      input_val = input_val.substring(0, 15);
      input_val = this.formatNumber(input_val, 'currency');
      input_val = "$" + input_val;
    
      // final formatting
      if (blur === "blur") {
        input_val += ".00";
      }
    }
    
    if (blur === 'blur' && field) {
      if (field.indexValue >= 0 && field.model) {
        const model = this.systematicWithdralFormModal.find((item) => item.id === field.model.id);
        if (model && model.values) {
          model.values[field.indexValue][field.id] = input_val;
        }
      } else {
        const model = this.systematicWithdralFormModal.find((item) => item.id === field.id);
        model.value = input_val;
      }
    }

    if (respValue == '' || respValue == undefined) {
      input.target["value"] = input_val;
    } else {
      return input_val;
    }
  
    this.withdrawlSurrenderModel.emit(this.systematicWithdralFormModal);
  }

  formatPercent(input, blur, respValue, field=undefined) {
    var input_val = "";
    if (respValue == '' || respValue == undefined) {
      input_val = input.target.value;
    } else {
      input_val = respValue;
    } 
    
    if (input_val === "") { 
      return; 
    }

    var original_len = input_val.length;
    if (input_val.indexOf(".") >= 0) {
      var decimal_pos = input_val.indexOf(".");
      var left_side = input_val.substring(0, decimal_pos);
      var right_side = input_val.substring(decimal_pos);
      left_side = this.formatNumber(left_side, 'percent');
      right_side = this.formatNumber(right_side, 'percent');
      if (blur === "blur") {
        right_side += "0";
      }

      // Limit decimal to only 1 digit
      right_side = right_side.substring(0, 1);
      // join number by .
      input_val = left_side + "." + right_side + "%";
    } else {
      // no decimal entered
      // add commas to number
      // remove all non-digits
      input_val = this.formatNumber(input_val, 'percent');
      if (blur === "blur") {
        input_val += ".0";
      }
    
      input_val = input_val + "%";
    }
    
    if (blur === 'blur' && field) {
      if (field.indexValue >= 0 && field.model) {
        const model = this.systematicWithdralFormModal.find((item) => item.id === field.model.id);
        if (model && model.values) {
          model.values[field.indexValue][field.id] = input_val;
        }
      } else {
        const model = this.systematicWithdralFormModal.find((item) => item.id === field.id);
        model.value = input_val;
      }
    }

    if (respValue == '' || respValue == undefined) {
      input.target["value"] = input_val;
    } else {
      return input_val;
    }

    this.withdrawlSurrenderModel.emit(this.systematicWithdralFormModal);
  }

  formatUnit(input, blur, respValue, field=undefined) {
    var input_val = "";
    if (respValue == '' || respValue == undefined) {
      input_val = input.target.value;
    } else {
      input_val = respValue;
    }
  
    if (input_val === "") { 
      return; 
    }

    const inputNum = this.formatNumber(input_val, '');
    if (!isNaN(Number(inputNum))) {
      input_val = Number(inputNum).toFixed(2);
    }
    
    if (blur === 'blur' && field) {
      if (field.model && field.indexValue >= 0) {
        const model = this.systematicWithdralFormModal.find((item) => item.id === field.model.id);
        if (model && model.values) {
          model.values[field.indexValue][field.id] = input_val;
        }
      } else {
        const model = this.systematicWithdralFormModal.find((item) => item.id === field.id);
        model.value = input_val;
      }
    }

    if (respValue == '' || respValue == undefined) {
      input.target["value"] = input_val;
    } else {
      return input_val;
    }

    this.withdrawlSurrenderModel.emit(this.systematicWithdralFormModal);
  }

  //To get index of object using id property
  getIndexOfField(fieldId) {
    return this.systematicWithdralFormModal.findIndex(item => item.id == fieldId);
  }

  getFundTableValidation() {
    this.isAmountInput = false;
    if (['U', 'V'].includes(this.systematicWithdralFormModal[this.getIndexOfField('option')].value)) {
      this.isAmountInput = false;
    } 
    else {
      this.isAmountInput = true;
    }  
  }

  onCheckboxChange(item) {
    item.checked = !item.checked;  
    this.withdrawlSurrenderModel.emit(this.systematicWithdralFormModal);
  }

  onTextChange(item) {
    //Disable either Amount or Percent when the other is entered
    if (item.id == 'withdrawal_amount'
     && item.value !== '') {
      this.systematicWithdralFormModal[this.getIndexOfField('payout_percent')].value = '';
      this.systematicWithdralFormModal[this.getIndexOfField('payout_percent')].required = false;
      this.systematicWithdralFormModal[this.getIndexOfField('payout_percent')].disabled = true;
    }
    else if (item.id == 'payout_percent'
          && item.value !== '') {
      this.systematicWithdralFormModal[this.getIndexOfField('withdrawal_amount')].value = '';
      this.systematicWithdralFormModal[this.getIndexOfField('withdrawal_amount')].required = false;
      this.systematicWithdralFormModal[this.getIndexOfField('withdrawal_amount')].disabled = false;
    }

    this.withdrawlSurrenderModel.emit(this.systematicWithdralFormModal);
  }

  onRadioChange(item) {
    if (item.id == 'transaction_level') {
        this.WithdrawalAmountPayoutPercentValication();
        item.value == 'F' ? this.showFundValue = true : this.showFundValue = false;
    }

    this.withdrawlSurrenderModel.emit(this.systematicWithdralFormModal);
  }

  onSelectChange(item) {
    if (item.id == 'option') {
      if (['U', 'V'].includes(item.value)) {
        this.fundUnit = 'Unit';
      } 
      else {
        this.fundUnit = 'Amount';
      }

      if (item.value == 'G') {
        this.systematicWithdralFormModal[this.getIndexOfField('transaction_level')].value = 'P';
        this.systematicWithdralFormModal[this.getIndexOfField('transaction_level')].disable = true;
      }
      else if (item.value == 'U') {
        this.systematicWithdralFormModal[this.getIndexOfField('transaction_level')].value = 'F';
        this.systematicWithdralFormModal[this.getIndexOfField('transaction_level')].disable = true;
      }
      else {        
        this.systematicWithdralFormModal[this.getIndexOfField('transaction_level')].disable = false;
      }

      this.WithdrawalAmountPayoutPercentValication()
    }

    this.getFundTableValidation();

    this.withdrawlSurrenderModel.emit(this.systematicWithdralFormModal);
  }

  checkIfSelected(itemId) {
    if (this.systematicWithdralFormModal[this.getIndexOfField(itemId)].isChecked == true) {
      // setting validation for text field
      if (itemId == 'federal_withholding_override') {
        this.systematicWithdralFormModal[this.getIndexOfField('federal_withholding_percentage')].disabled = false;
      }
      else if (itemId == 'state_withholding_override') {
        this.systematicWithdralFormModal[this.getIndexOfField('state_withholding_percentage')].disabled = false;
      }
    } else {
        if (itemId == 'federal_withholding_override') {
        this.systematicWithdralFormModal[this.getIndexOfField('federal_withholding_percentage')].disabled = true;
        this.systematicWithdralFormModal[this.getIndexOfField('federal_withholding_percentage')].value = "";
        }
        else if(itemId == 'state_withholding_override'){
        this.systematicWithdralFormModal[this.getIndexOfField('state_withholding_percentage')].disabled = true;      
        this.systematicWithdralFormModal[this.getIndexOfField('state_withholding_percentage')].value = "";
      }
    }

    this.withdrawlSurrenderModel.emit(this.systematicWithdralFormModal);
  }

  onDateChange() {
    this.withdrawlSurrenderModel.emit(this.systematicWithdralFormModal);
  }

  checkboxChange(checkbox, checked: boolean) {
    checkbox.value = checked ? 'Y' : 'N';
    checkbox.isChecked = checked ? true : false;
    if (checkbox.id === 'no_state_withholding') {
      const stateWithholdingOverride = this.systematicWithdralFormModal.find((model) => model.id === 'state_withholding_override');
      const federalWithholdingOverride = this.systematicWithdralFormModal.find((model) => model.id === 'federal_withholding_override');
      const noFederalWithholding = this.systematicWithdralFormModal.find((model) => model.id === 'no_federal_withholding');
      stateWithholdingOverride.disabled = checkbox.isChecked ? true : noFederalWithholding.value !== 'N';
      federalWithholdingOverride.disabled = checkbox.isChecked ? true : noFederalWithholding.value !== 'N';
      stateWithholdingOverride.value = checked ? 'N' : stateWithholdingOverride.value;    
      federalWithholdingOverride.value = checked ? 'N' : federalWithholdingOverride.value;
    } 
    
    if (checkbox.id === 'state_withholding_override') {
      const noStateWithholding = this.systematicWithdralFormModal.find((model) => model.id === 'no_state_withholding');
      const noFederalWithholding = this.systematicWithdralFormModal.find((model) => model.id === 'no_federal_withholding');
      const federalWithholdingOverride = this.systematicWithdralFormModal.find((model) => model.id === 'federal_withholding_override');
      noFederalWithholding.disabled = checkbox.isChecked ? true : federalWithholdingOverride.value !== 'N';
      noFederalWithholding.value = checked ? 'N' : noFederalWithholding.value;   
      noStateWithholding.disabled = checkbox.isChecked ? true : federalWithholdingOverride.value !== 'N';
      noStateWithholding.value = checked ? 'N' : noStateWithholding.value;     
    } 
    
    if (checkbox.id === 'no_federal_withholding') {
      const noStateWithholding = this.systematicWithdralFormModal.find((model) => model.id === 'no_state_withholding');
      const stateWithholdingOverride = this.systematicWithdralFormModal.find((model) => model.id === 'state_withholding_override');
      const federalWithholdingOverride = this.systematicWithdralFormModal.find((model) => model.id === 'federal_withholding_override');
      stateWithholdingOverride.disabled = checkbox.isChecked ? true : noStateWithholding.value !== 'N';
      federalWithholdingOverride.disabled = checkbox.isChecked ? true : noStateWithholding.value !== 'N';
      stateWithholdingOverride.value = checked ? 'N' : stateWithholdingOverride.value;    
      federalWithholdingOverride.value = checked ? 'N' : federalWithholdingOverride.value;
    } 
    
    if (checkbox.id === 'federal_withholding_override') {
      const stateWithholdingOverride = this.systematicWithdralFormModal.find((model) => model.id === 'state_withholding_override');
      const noStateWithholding = this.systematicWithdralFormModal.find((model) => model.id === 'no_state_withholding');
      const noFederalWithholding = this.systematicWithdralFormModal.find((model) => model.id === 'no_federal_withholding');
      noFederalWithholding.disabled = checkbox.isChecked ? true : stateWithholdingOverride.value !== 'N';
      noFederalWithholding.value = checked ? 'N' : noFederalWithholding.value;   
      noStateWithholding.disabled = checkbox.isChecked ? true : stateWithholdingOverride.value !== 'N';
      noStateWithholding.value = checked ? 'N' : noStateWithholding.value;  
    } 
  }

  //next date validation
  allowedDates = (d: Date): boolean => {
    const date = d.getDate(); 
    if (date >= 1 && date <= 28 ) {
       return true;
    }
    
    return false;
  }

}