import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'highlightSearch'
})
export class HighlightSearchPipe implements PipeTransform {
  constructor(public datepipe: DatePipe  ) { };


  transform(value: string, ...search: any[]): string {
    let valueStr = value + '';
    for (const iterator of search) {
      if(value && iterator){
        valueStr =  valueStr.replace(new RegExp('(?![^&;]+;)(?!<[^<>]*)(' + iterator + ')(?![^<>]*>)(?![^&;]+;)', 'gi'), '<span class="text-primary">$1</span>')
      }
      if(value && iterator){ 
        let replaceStr;
        if(iterator instanceof Date){
          replaceStr =this.datepipe.transform(iterator, 'MM/dd/yyyy');
        }
        else {
          replaceStr = iterator
        }
        valueStr =  valueStr.replace(new RegExp('(?![^&;]+;)(?!<[^<>]*)(' + replaceStr + ')(?![^<>]*>)(?![^&;]+;)', 'gi'), '<span class="text-primary">$1</span>')
      }

    }
    // Ensure numeric values are converted to strings
    return valueStr
  }
  

}