import { Component, ElementRef, OnInit, AfterViewInit, EventEmitter, Output, ViewChild } from '@angular/core';
import { ResourceService, Configuration, ServerResponse, UpdateMethodResponse, OcInfraModule, APICallerService } from '@diaas/ux-web';
import * as _ from 'lodash';

const MENU: String = 'menu'
const PENDING_TRANSACTION: String = 'pendingTransaction'

const DIVIDEND_WITHDRAWAL: String = 'DIVIDEND_WITHDRAWAL'
const FULL_SURRENDER: String = 'FULL_SURRENDER'
const SYSTEMATIC_WITHDRAWAL: String = 'SYSTEMATIC_WITHDRAWL'
const PARTIAL_SURRENDER: String = 'PARTIAL_SURRENDER'

export class WithdrawalSurrenderAction {
  private userAction: String = MENU;
  private withdrawalType: String;  
  private trxKey: String;

  constructor() {
    this.userAction = MENU;
    this.withdrawalType = '';
    this.trxKey = '';
  }

  createPartialSurrenderMenuAction() {
    this.userAction = MENU;
    this.withdrawalType = DIVIDEND_WITHDRAWAL;
  }

  createFullSurrenderMenuAction() {
    this.userAction = MENU;
    this.withdrawalType = FULL_SURRENDER;
  }

  createSystematicWithdrawalMenuAction() {
    this.userAction = MENU;
    this.withdrawalType = SYSTEMATIC_WITHDRAWAL;
  }
  setWithdrawlType(type:String){
    this.withdrawalType = type;
  }

  createPartialSurrenderPendingTransactionAction(trxKey) {
    this.userAction = PENDING_TRANSACTION;
    this.withdrawalType = DIVIDEND_WITHDRAWAL;
    this.trxKey = trxKey;
  }

  createFullSurrenderPendingTransactionAction(trxKey) {
    this.userAction = PENDING_TRANSACTION;
    this.withdrawalType = FULL_SURRENDER;
    this.trxKey = trxKey;
  }

  createSystematicWithdrawalPendingTransactionAction(trxKey) {
    this.userAction = PENDING_TRANSACTION;
    this.withdrawalType = SYSTEMATIC_WITHDRAWAL;
    this.trxKey = trxKey;
  }

  resetData() {
    this.userAction = MENU;
    this.withdrawalType = '';
    this.trxKey = '';
  }

  getTrxKey() {
    return this.trxKey;
  }

  isUserMenuAction() {
    return this.userAction == MENU;
  }

  isPendingTransactionAction() {
    return this.userAction == PENDING_TRANSACTION;
  }

  isDividendWithdrawal() {
    return this.withdrawalType == DIVIDEND_WITHDRAWAL;
  }
  
  isFullSurrender() {
    return this.withdrawalType == FULL_SURRENDER;
  }

  isPartialSurrender() {
    return this.withdrawalType == PARTIAL_SURRENDER;
  }

  isSystematicWithdrawal() {
    return this.withdrawalType == SYSTEMATIC_WITHDRAWAL;
  }

  getWithdrawalSurrenderType() {
    if (this.isDividendWithdrawal())
      return DIVIDEND_WITHDRAWAL;
    
    if (this.isFullSurrender())
      return FULL_SURRENDER;
    
    if (this.isSystematicWithdrawal())
      return SYSTEMATIC_WITHDRAWAL;

    if (this.isPartialSurrender())
        return PARTIAL_SURRENDER;
    
    return '';
  }

  getWithdrawalSurrenderAction() {
    if (this.isDividendWithdrawal())
      return 'DIVIDEND WITHDRAWAL';
    
    if (this.isFullSurrender())
      return 'FULL SURRENDER';
    
    if (this.isSystematicWithdrawal())
      return 'SYSTEMATIC WITHDRAWAL';
    
    if (this.isPartialSurrender())
        return 'PARTIAL SURRENDER';  

    return '';
  }
}
