import { Injectable } from '@angular/core';  
import { Observable, Subject } from 'rxjs';
  
@Injectable({
    providedIn:'root'
})  
export class CreateDeathClaimSharedService {  
  
 private subject = new Subject<any>();

 private data: any = {};
 public associatedPolicies = [];
  
 setOption(option, value) {      
    this.data[option] = value;  
  }  
  
  getOption() {
    return this.data;  
  }

  sendData(wrapper: any) {
    this.subject.next(wrapper);
  }
  getData(): Observable<any> {
    return this.subject.asObservable();
  }
}