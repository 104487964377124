import { Component, OnInit, ElementRef } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { OcInfraModule, APICallerService, OptionParams, ResourceService } from '@diaas/ux-web';
import { AdvGrowlService } from 'primeng-advanced-growl';
import * as _ from 'lodash';

@Component({
  selector: 'app-sponser-plan-admin-info-tab',
  templateUrl: './sponser-plan-admin-info-tab.component.html',
  styleUrls: ['./sponser-plan-admin-info-tab.component.scss']
})
export class SponserPlanAdminInfoTabComponent implements OnInit {
  planAdminList: any;
  noRecords: boolean = true;
  statusMsg: string = "";
  message: boolean = false;
  checkedData: Object = {};
  planAdminObj: any = {};
  planAdminInfoList: any[] = [];
  currentChecked: {};
  checkedProperties: any = {};
  savedProperties: string;
  html = '';
  notesObj: any = {};
  selectdValues: any;

  constructor() { }

  ngOnInit() {
    let policySponsor = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('Hcontractsdetails');
    this.planAdminList = policySponsor.data.hClientPlanAdminInfoList;
    if(this.planAdminList) {
    this.planAdminList.forEach((obj, index) => {
        obj.id = index + 1;
      });
    }
    this.noRecords = this.planAdminList && !this.planAdminList.length;
    if (policySponsor.response._embedded && policySponsor.response._embedded.status_report.messages) {
      policySponsor.response._embedded.status_report.messages.forEach(msgObj => {
        this.statusMsg += msgObj.message;
        this.message = true;
      });
    }
  }

  testChange(event, data, selectedValue, selectedKey, columnName, valueType, flag) {
    let selectedVal: any;
    if (this.checkedData[data.id] && columnName !== 'name') {
      selectedVal = (selectedValue === '' || selectedValue === undefined || selectedValue === ' ')  ? 'No data in admin system.' : selectedValue;
      event.checked ? this.checkedData[data.id].push(selectedKey + ':' + selectedVal) : this.checkedData[data.id].splice(this.checkedData[data.id].indexOf(selectedKey + ':' + selectedVal), 1)
    } else {
      if (!this.checkedData[data.id]) {
        selectedVal = (selectedValue === '' || selectedValue === undefined || selectedValue === ' ')  ? 'No data in admin system.' : selectedValue;
        this.checkedData[data.id] = new Array(selectedKey + ':' + selectedVal);
      }
    }
    if (this.checkedData[data.id].length == 0) {
      delete this.checkedData[data.id];
    }
    let html = '';
    if (this.checkedData[data.id]) {
      for (var i = 0; i < this.checkedData[data.id].length; i++) {
        var split = this.checkedData[data.id][i].split(':');
        this.notesObj[split[0].trim()] = split[1].trim();
      }
    }
    if (Object.keys(this.checkedData).length > 0) {
      Object.keys(this.checkedData).forEach(key => {
        this.checkedData[key].forEach(element => {
          html += '<dt class="pull-left">' + element.split(":")[0] + ':</dt><dd style = "font-weight: 200;"  >&nbsp;' + element.split(":")[1] + (element.split(":")[2] ? element.split(":")[2] : "") + (element.split(":")[3] ? element.split(":")[3] : "") + (element.split(":")[4] ? element.split(":")[4] : "") + '</dd>';
        });
      });
    }
    let selectedData = [];
    if (this.planAdminList.length > 0) {
      this.planAdminList.forEach(element => {
        if (this.checkedData.hasOwnProperty(element.id)) {
          let currentField = {};
          currentField[data.id] = this.checkedData[element.id];
          selectedData.push(currentField);
        }
      });
    }
    if (Object.keys(this.checkedData).length) {
      OcInfraModule.AppInjector.get(CommonService).planAdminHtml = '<span style="font-size:15px" class="pad-lAr-10px">SPONSOR-PLAN ADMIN</span><dl class="pad-lAr-10px">' + html + '</dl>'
    }
    else {
      OcInfraModule.AppInjector.get(CommonService).planAdminHtml = '';
    }
    OcInfraModule.AppInjector.get(CommonService).showRequestedDetails();
    this.covrageChecked(event, data, selectedValue, selectedKey, columnName, valueType, flag);
    this.selectdValues = OcInfraModule.AppInjector.get(CommonService).checkedPlanAdminDetails;
    let keys = Object.keys(this.selectdValues[0]["plan_admin"]);
    if (keys.length > 0) {
      this.selectdValues = OcInfraModule.AppInjector.get(CommonService).checkedPlanAdminDetails;
      for (let i = 0; i < this.selectdValues.length; i++) {
        localStorage.setItem("plan_admin", JSON.stringify(Object.assign(this.checkedProperties, this.selectdValues[0].plan_admin)));
      }
    }
    else {
      OcInfraModule.AppInjector.get(CommonService).planAdminHtml = '';
      OcInfraModule.AppInjector.get(CommonService).showRequestedDetails();
    }

  }

  covrageChecked(event, data, selectedValue, selectedKey, columnName, valueType, flag) {
      let selctedCoverage = [];
      selctedCoverage = OcInfraModule.AppInjector.get(CommonService).checkedPlanAdminDetails;
      if (event.checked) {
        let coverageChecked = []
        this.currentChecked = {}
        selectedValue = (selectedValue === '' || selectedValue === undefined || selectedValue === ' ')  ? 'No data in admin system.' : selectedValue;
        if (selectedValue) {
          this.currentChecked[selectedKey] = selectedValue;
          if (selctedCoverage.length > 0) {
            if (selctedCoverage[0].hasOwnProperty(["plan_admin"])) {
              for (let i = 0; i < selctedCoverage.length; i++) {
                let subKeys = Object.keys(selctedCoverage[0]["plan_admin"]);
                let existed: boolean = true;
                if (subKeys.length > 0) {
                  for (let j = 0; j < subKeys.length; j++) {
                    if (subKeys[j] == data["sponsor_name"] + data["id"]) {
                      Object.assign(selctedCoverage[0]["plan_admin"][data["sponsor_name"] + data["id"]], this.currentChecked);
                      OcInfraModule.AppInjector.get(CommonService).checkedPlanAdminDetails = selctedCoverage;
                      existed = false;
                    } else if (j + 1 >= subKeys.length && existed == true) {
                      // this.checkedProperties["selectedNote"] = []
                      this.checkedProperties["plan_admin"][data["sponsor_name"] + data["id"]] = this.currentChecked;
                      Object.assign(selctedCoverage[0]["plan_admin"], this.checkedProperties["plan_admin"]);
                      OcInfraModule.AppInjector.get(CommonService).checkedPlanAdminDetails = selctedCoverage;
                    }
                  }
                } else {
                  this.checkedProperties["plan_admin"] = []
                  this.checkedProperties["plan_admin"][data["sponsor_name"] + data["id"]] = this.currentChecked;
                  Object.assign(selctedCoverage[0]["plan_admin"], this.checkedProperties["plan_admin"]);
                  OcInfraModule.AppInjector.get(CommonService).checkedPlanAdminDetails = selctedCoverage;
                }
              }
            } else {
              this.checkedProperties["plan_admin"] = []
              this.checkedProperties["plan_admin"][data["sponsor_name"] + data["id"]] = this.currentChecked;
              Object.assign(selctedCoverage[0], this.checkedProperties)
              OcInfraModule.AppInjector.get(CommonService).checkedPlanAdminDetails = selctedCoverage;
            }
          } else {
            this.checkedProperties["plan_admin"] = []
            this.checkedProperties["plan_admin"][data["sponsor_name"] + data["id"]] = this.currentChecked;
            selctedCoverage.push(this.checkedProperties);
            OcInfraModule.AppInjector.get(CommonService).checkedPlanAdminDetails = selctedCoverage;
          }
        }
      }
      else {
        let unCheckedId = selectedKey;
        delete selctedCoverage[0]["plan_admin"][data["sponsor_name"] + data["id"]][unCheckedId]
        let subKeys = Object.keys(selctedCoverage[0]["plan_admin"]);
        let checked = [];
        for (let i = 0; i < subKeys.length; i++) {
          if (_.isEmpty(selctedCoverage[0]["plan_admin"][subKeys[i]])) {
            delete selctedCoverage[0]["plan_admin"][data["sponsor_name"] + data["id"]]
            checked.push(false);
            delete this.checkedProperties[data["loan_int_rate_opt"] + data["id"]];
            if(subKeys.length == 1) {
              this.checkedProperties = {};
            }
          } else {
            checked.push(true)
          }
        }
        localStorage.setItem("plan_admin", JSON.stringify(this.checkedProperties));
        OcInfraModule.AppInjector.get(CommonService).checkedPlanAdminDetails = selctedCoverage;
      }
    }

}
