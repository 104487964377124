import { SystematicWithdrawalWithdrawalComponent } from './systematic-withdrawal/systematic-withdrawal-withdrawal/systematic-withdrawal-withdrawal.component';
import { Component, OnInit, ViewChild, SimpleChanges, OnChanges } from '@angular/core';
import { ResourceService, OcInfraModule } from '@diaas/ux-web';
import * as _ from 'lodash';
import { isSurrenderProcessingEnabled, initialPartialSurrender, initialFullSurrender, partialMockResponse, fullMockResponse, fundTableDataMock, withdrawalsMockResponse, surrenderConfirmationValues, eftInformation, fullWithdrawalsMockResponse } from './constants';
import { CommonService } from '../../services/common.service';
import { WithdrawlSurrenderSurrender } from './surrenderComponent';
import { WithdrawlSurrenderPayment } from './paymentComponent';
import { WithdrawlSurrenderConfirmation } from './confirmationComponent';
import { AdvGrowlService } from 'primeng-advanced-growl';
import { SystematicWithdrawlComponent } from './systematicWithdrawlComponent'
import { WithdrawalSurrenderAction } from './withdrawalSurrenderAction'
import { WithdrawlSurrenderQuote } from './quoteComponent';
import { HttpParams } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { FullSurrenderSurrenderComponent } from './full-surrender/full-surrender-surrender/full-surrender-surrender.component';
import { FullSurrenderQuoteComponent } from './full-surrender/full-surrender-quote/full-surrender-quote.component';
import { PartySearchFactory } from '../../factories/partySearchFactory';
import { PartialSurrenderSurrenderComponent } from './partial-surrender/partial-surrender-surrender/partial-surrender-surrender.component';
import { PartialSurrenderQuoteComponent } from './partial-surrender/partial-surrender-quote/partial-surrender-quote.component';
import { PartialSurrenderSurrenderMRPSComponent } from './partial-surrender/partial-surrender-surrender-mrps/partial-surrender-surrender-mrps.component';

const ALIAS_KEY: String = 'policyDetails'

@Component({
    selector: 'app-withdrawals',
    templateUrl: './withdrawalsSurrenderComponent.html',
    styleUrls: ['withdrawalsSurrenderComponent.scss'],
    providers: [PartySearchFactory]
})

export class WithdrawalsSurrender implements OnInit {
    net_check_amount: any;
    plan_id: any;
    isFullSurrGroupMrps: boolean=false;

    constructor(private growlService: AdvGrowlService, private datePipe: DatePipe,
        private commonService: CommonService, private partySearchFactory: PartySearchFactory) { };
    @ViewChild(WithdrawlSurrenderQuote) childQuote: WithdrawlSurrenderQuote;
    @ViewChild(WithdrawlSurrenderSurrender) childSurrender: WithdrawlSurrenderSurrender;
    @ViewChild(WithdrawlSurrenderPayment) childPayment: WithdrawlSurrenderPayment;
    @ViewChild(WithdrawlSurrenderConfirmation) childConfirmation: WithdrawlSurrenderConfirmation;
    @ViewChild(SystematicWithdrawlComponent) systematicWithdrawlStep: SystematicWithdrawlComponent;
    @ViewChild(FullSurrenderSurrenderComponent) childSurrenderFull: FullSurrenderSurrenderComponent;
    @ViewChild(FullSurrenderQuoteComponent) childQuoteFull: FullSurrenderQuoteComponent;
    @ViewChild(PartialSurrenderSurrenderComponent) childSurrenderPartial: PartialSurrenderSurrenderComponent;
    @ViewChild(PartialSurrenderQuoteComponent) childQuotePartial: PartialSurrenderQuoteComponent;
    @ViewChild(PartialSurrenderSurrenderMRPSComponent) childSurrenderPartialMRPS: PartialSurrenderSurrenderMRPSComponent;
    stepCompletionIndicator: object = { "systematicWithDrawlTab": false, "paymentTab": false, "surrenderTab": false, "quoteTab": false };

    @ViewChild(SystematicWithdrawalWithdrawalComponent) childWithdrawalSystematic: SystematicWithdrawalWithdrawalComponent;

    /*
      Following are props to popogate to  child components 
    */
    withdrawlSurrenderType: any;
    withdrawalSurrenderAction: WithdrawalSurrenderAction;
    policyDetails: any;
    currentPolicyResourceURL: String;
    receivedQuoteDetails: any = {};
    receivedSurrenderrDetails: any = {};
    surrenderDetails: any;
    canSubmitDividendWithdrawal: any = false;
    confirmMessage: string = '';
    scriptRes: any;
    effectiveDate: any;
    isSurrenderProcessingEnabled: any = isSurrenderProcessingEnabled;
    initialSurrenderValues: any = {
        DIVIDEND_WITHDRAWAL: initialPartialSurrender,
        FULL_SURRENDER: initialFullSurrender
    }
    mockResponseValues: any = {
        DIVIDEND_WITHDRAWAL: partialMockResponse,
        FULL_SURRENDER: fullMockResponse
    }

    headers = { ...{ role: OcInfraModule.AppInjector.get(CommonService).authorizedRole, wma_user: window.sessionStorage.getItem('username') }, user_role: "csr", ...OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("search") };
    surrenderAPISource: Object = { "DIVIDEND_WITHDRAWAL": "/dividendwithdrawal", "FULL_SURRENDER": "/surrender/full", "SYSTEMATIC_WITHDRAWL": "/surrender/systematic", "PARTIAL_SURRENDER": "/surrender/partial" };
    quotesAPISource: Object = { "DIVIDEND_WITHDRAWAL": "/dividendwithdrawal", "FULL_SURRENDER": "/values/fullSurrender" };
    receivedSurrenderDetails: any;
    receivedPaymentDetails: any;
    defaultQuoteDetails: any;
    disableTabs: boolean;
    alertMessags: { severity: string; message: string; }[];

    public withdrawlSurrenderModel: any;

    showSsnFactaVerify = true;
    // For disable amount request field
    amtRequestDisableByWithdrawal: any;
    IsClickedConfirmation = false;

    disableTabFullSurrender: boolean = false;
    isLife = false; // T1, WHOLE LIFE BASE, U1, U2
    isGroupProduct: boolean = false;

    
    isCv15Contract: boolean = false;
    isRetailAnnuity: boolean = false;
    isGroupAnnuity: boolean = false;
    surrenderQuoteDetails: any;

    isExistingSWP: boolean = false;

    isMrpsContract: boolean = false;
    isHostContract: boolean = false;
    isHostContractAlert: boolean = false;
    isMrpsContractAlert: boolean = false;
    systematicConfirmationTab : boolean = true;
    

    ngOnInit() {
        this.policyDetails = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyDetails');


        this.isLife = this.partySearchFactory.isTermProduct() || this.partySearchFactory.isUL();
        this.isRetailAnnuity = this.partySearchFactory.isRetailAnnuity();
        this.isGroupAnnuity = this.partySearchFactory.isOnlyGroupAnn();

        this.isCv15Contract = this.policyDetails.response.isCv15Contract ? true : false;
        this.isMrpsContract = this.policyDetails.response.isMrpsContract ? true : false;
        this.isHostContract = this.policyDetails.response.isHostContract ? true : false;

        this.resetData();

        this.isGroupProduct = this.isGroupProductType();
        this.isFullSurrGroupMrps = (this.withdrawlSurrenderType == 'FULL_SURRENDER' && (this.policyDetails.data.product_type =='A3' || this.policyDetails.data.product_type =='A4') && (this.policyDetails.data.isMrpsContract || this.policyDetails.data.isHostContract));
        if ((this.withdrawlSurrenderType !== 'PARTIAL_SURRENDER' && this.withdrawlSurrenderType !== 'SYSTEMATIC_WITHDRAWL' && this.withdrawlSurrenderType !== 'FULL_SURRENDER') ||
        ((this.withdrawlSurrenderType === 'PARTIAL_SURRENDER' || this.withdrawlSurrenderType === 'SYSTEMATIC_WITHDRAWL' || this.withdrawlSurrenderType === 'FULL_SURRENDER') && (this.isCv15Contract || (this.isMrpsContract && this.isRetailAnnuity) || (this.isHostContract && this.isRetailAnnuity) || this.isLife))) {
            this.getSurrenderDetails();
        } else if (this.withdrawlSurrenderType === 'PARTIAL_SURRENDER' && (this.isMrpsContract && this.isGroupAnnuity) || (this.isHostContract && this.isGroupAnnuity)) {
            this.headers = this.commonService.getHeaderByAliasName("partialSurrender");
            this.headers.role = this.commonService.authorizedRole;
            if(!OcInfraModule.AppInjector.get(CommonService).mfaAuthorized){
                this.disableTabs = true;
            }
            //this.disableTabs = true;
        }  else if (this.withdrawlSurrenderType === 'FULL_SURRENDER' && (this.isMrpsContract && this.isGroupAnnuity) || (this.isHostContract && this.isGroupAnnuity)) {
            this.headers = this.commonService.getHeaderByAliasName("partialSurrender");
            this.headers.role = this.commonService.authorizedRole;
            if(!OcInfraModule.AppInjector.get(CommonService).mfaAuthorized){
                this.disableTabs = true;
            }
            //this.disableTabs = true;
        } else if (this.withdrawlSurrenderType === 'SYSTEMATIC_WITHDRAWL' && (this.isMrpsContract && this.isGroupAnnuity) || (this.isHostContract && this.isGroupAnnuity) ) {
            this.headers = this.commonService.getHeaderByAliasName("partialSurrender");
            this.headers.role = this.commonService.authorizedRole;
            if(!OcInfraModule.AppInjector.get(CommonService).mfaAuthorized){
                this.disableTabs = false;
            }
            //this.disableTabs = true;
        }

        let roles = ["owner", "agent", "agent-assistant", "Agent/Advisor Assistant"];

        const selectedRole = <string>OcInfraModule.AppInjector.get(CommonService).selectedRole;
        this.canSubmitDividendWithdrawal = roles.find((role) => role.toLowerCase() == selectedRole.toLowerCase());

        if (this.withdrawlSurrenderType == 'FULL_SURRENDER') {
            roles = ["Owner", "Co-Owner", "Agent", "Agent Assistant", "BOA Back Office Assistant (Advisor Assistant)"];
            this.canSubmitDividendWithdrawal = "owner";
        }

        if (this.withdrawlSurrenderType == 'PARTIAL_SURRENDER') {
            roles = ["Owner", "Co-Owner", "Agent", "Agent Assistant", "BOA Back Office Assistant (Advisor Assistant)"];
            this.canSubmitDividendWithdrawal = "owner";
        }

        if (this.withdrawlSurrenderType == 'SYSTEMATIC_WITHDRAWL') {
            roles = ["Owner", "Co-Owner", "Agent", "Agent Assistant", "BOA Back Office Assistant (Advisor Assistant)"];
            this.canSubmitDividendWithdrawal = "owner";
        }

        if (this.withdrawlSurrenderType !== 'SYSTEMATIC_WITHDRAWL' && this.withdrawlSurrenderType !== 'FULL_SURRENDER' && this.withdrawlSurrenderType !== 'PARTIAL_SURRENDER') {
            this.stepCompletionIndicator["quoteTab"] = this.childQuote ? this.childQuote.isStepComplete() : false;
            this.stepCompletionIndicator["surrenderTab"] = this.childSurrender ? this.childSurrender.isStepComplete() : false;
            this.stepCompletionIndicator["paymentTab"] = this.childPayment ? this.childPayment.isStepComplete() : false;
        } else if (this.withdrawlSurrenderType == 'FULL_SURRENDER') {
            this.stepCompletionIndicator["quoteTab"] = this.childQuoteFull ? this.childQuoteFull.isStepComplete() : false;
            this.stepCompletionIndicator["surrenderTab"] = this.childSurrenderFull ? this.childSurrenderFull.isStepComplete() : false;
            this.stepCompletionIndicator["paymentTab"] = this.childPayment ? this.childPayment.isStepComplete() : false;
        } else if (this.withdrawlSurrenderType == 'PARTIAL_SURRENDER') {
            this.stepCompletionIndicator["quoteTab"] = this.childQuotePartial ? this.childQuotePartial.isStepComplete() : false;
            this.stepCompletionIndicator["surrenderTab"] = this.childSurrenderPartial ? this.childSurrenderPartial.isStepComplete() : false;
            this.stepCompletionIndicator["paymentTab"] = this.childPayment ? this.childPayment.isStepComplete() : false;
            if(this.isMrpsContract){                
                this.stepCompletionIndicator["surrenderTab"] = this.childSurrenderPartialMRPS ? this.childSurrenderPartialMRPS.isStepComplete() : false;
            }
        } else if(this.withdrawlSurrenderType == 'SYSTEMATIC_WITHDRAWL'){
            this.stepCompletionIndicator["surrenderTab"] = this.childWithdrawalSystematic ? this.childWithdrawalSystematic.isStepComplete() : false;
            this.stepCompletionIndicator["paymentTab"] = this.childPayment ? this.childPayment.isStepComplete() : false;
        } else {
            this.stepCompletionIndicator["systematicWithDrawlTab"] = this.systematicWithdrawlStep ? this.systematicWithdrawlStep.isStepComplete() : false;
            this.stepCompletionIndicator["paymentTab"] = this.childPayment ? this.childPayment.isStepComplete() : false;
        }
        OcInfraModule.AppInjector.get(CommonService).withdrawalSurrenderType = this.withdrawlSurrenderType;

    }

    resetData() {
        // Fetches User's selection Partial vs Full and popogates to child components
        this.withdrawalSurrenderAction = OcInfraModule.AppInjector.get(CommonService).getWithdrawalSurrenderAction();
        this.withdrawlSurrenderType = this.withdrawalSurrenderAction.getWithdrawalSurrenderType();

        // fetches current policy details and propogates to child components.
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        this.policyDetails = resourceService.getResourceFromAliasName('policyDetails');
        this.currentPolicyResourceURL = this.policyDetails ? this.policyDetails.response._links.self.href : null;
    }


    /** API INTEGRATION */
    getSurrenderDetails() {
        if (this.withdrawalSurrenderAction.isUserMenuAction()) {
            let href = this.currentPolicyResourceURL + this.surrenderAPISource[this.withdrawlSurrenderType];
            if(this.isFullSurrGroupMrps){
                this.headers['asset_account']="**";
                this.headers['plan_Code']=this.plan_id;
                this.headers['client_id']=this.policyDetails.data.client_id;
            }
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false });
            OcInfraModule.AppInjector.get(CommonService).getCallback(href, this.headers).subscribe(response => {
                OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
                this.surrenderDetails = response;
                if (response._embedded && response._embedded.status_report) {
                    this.alertMessags = response._embedded.status_report.messages;

                    if (this.withdrawlSurrenderType == 'FULL_SURRENDER' || this.withdrawlSurrenderType == 'PARTIAL_SURRENDER' || this.withdrawlSurrenderType == 'SYSTEMATIC_WITHDRAWL') {
                        this.alertMessags = this.alertMessags.filter(item => item.message != 'No mapping rule matched');
                    }

                    for (let i = 0; i < response._options.links.length; i++) {
                        if ((response._options.links[i].method == 'POST' || response._options.links[i].method == 'PATCH') && OcInfraModule.AppInjector.get(CommonService).mfaAuthorized) {
                            this.disableTabs = false;
                        } else {
                            this.disableTabs = true;
                        }
                    }
                }
                if(!OcInfraModule.AppInjector.get(CommonService).mfaAuthorized){
                    this.disableTabs = true;
                }
                if (this.withdrawlSurrenderType == 'SYSTEMATIC_WITHDRAWL') {
                    this.isExistingSWP = response.existing_record == 'Y' ? true : false;
                    this.isHostContractAlert = this.isHostContract && this.alertMessags.length > 0 && this.isExistingSWP ? true : false;
                    let isMrpsContractMsg = []
                    if (this.alertMessags.length > 0) {
                        isMrpsContractMsg = this.alertMessags.filter(item => item.message == 'RPS Contract - Invalid Tax Market. Submit Work Request');
                    }
                    this.isMrpsContractAlert = this.isMrpsContract && this.isExistingSWP && isMrpsContractMsg.length > 0 ? true : false;
                    this.confirmationCheck();
                    if(this.isHostContractAlert || this.isMrpsContractAlert) {
                        this.disableTabs = false;
                    }
                }

            }, error => {
                OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
                if (error && error._embedded && error._embedded.status_report && error._embedded.status_report.consistent === false) {
                    OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error._embedded.status_report.messages[0].message, 'Error', 0);
                }
            });
        }
        else {
            //get pending transaction details
            const resourceService = OcInfraModule.AppInjector.get(ResourceService);
            let pendingTransactions = resourceService.getResourceFromAliasName('pendingTransactions');
            let pendingTransactionItems = pendingTransactions._links.item;
            let selectedPend = pendingTransactionItems.find(item => item.summary.trx_key == this.withdrawalSurrenderAction.getTrxKey())
            if (selectedPend) {
                let getLink = selectedPend.summary._options.links.find(link => link.method.toLowerCase() == "get");
                if (getLink) {

                    OcInfraModule.AppInjector.get(CommonService).getCallback(getLink.href, { ...this.headers, "status": selectedPend.summary.status ? selectedPend.summary.status : '' }).subscribe(response => {
                        this.surrenderDetails = response;

                        if (response._embedded && response._embedded.status_report) {
                            this.alertMessags = response._embedded.status_report.messages;
                        }
                    }, error => {
                        OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
                        if (error && error._embedded && error._embedded.status_report && error._embedded.status_report.consistent === false) {
                            OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error._embedded.status_report.messages[0].message, 'Error', 0);
                        }
                    });
                }
            }
        }
    }

    getPartialHostSurrenderDetails(msg,headers) { // For Partial surrender Host GroupAnnuity polices, only Quote should be enabled.
        if (this.withdrawalSurrenderAction.isUserMenuAction()) {
            let href = msg;
            this.headers = headers;
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false });
            OcInfraModule.AppInjector.get(CommonService).getCallback(href, this.headers).subscribe(response => {
                OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
                this.surrenderDetails = response;
                if (response._embedded && response._embedded.status_report) {
                    this.alertMessags = response._embedded.status_report.messages;

                    if (this.withdrawlSurrenderType == 'PARTIAL_SURRENDER') {
                        this.alertMessags = this.alertMessags.filter(item => item.message != 'No mapping rule matched');
                    }

                    for (let i = 0; i < response._options.links.length; i++) {
                        if ((response._options.links[i].method == 'POST' || response._options.links[i].method == 'PATCH') && OcInfraModule.AppInjector.get(CommonService).mfaAuthorized) {
                            this.disableTabs = false;
                        } else {
                            this.disableTabs = true;
                        }
                    }
                }
                if(!OcInfraModule.AppInjector.get(CommonService).mfaAuthorized){
                    this.disableTabs = true;
                }

            }, error => {
                OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
                if (error && error._embedded && error._embedded.status_report && error._embedded.status_report.consistent === false) {
                    OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error._embedded.status_report.messages[0].message, 'Error', 0);
                }
            });
        }
        else {
            //get pending transaction details
            const resourceService = OcInfraModule.AppInjector.get(ResourceService);
            let pendingTransactions = resourceService.getResourceFromAliasName('pendingTransactions');
            let pendingTransactionItems = pendingTransactions._links.item;
            let selectedPend = pendingTransactionItems.find(item => item.summary.trx_key == this.withdrawalSurrenderAction.getTrxKey())
            if (selectedPend) {
                let getLink = selectedPend.summary._options.links.find(link => link.method.toLowerCase() == "get");
                if (getLink) {

                    OcInfraModule.AppInjector.get(CommonService).getCallback(getLink.href, { ...this.headers, "status": selectedPend.summary.status ? selectedPend.summary.status : '' }).subscribe(response => {
                        this.surrenderDetails = response;

                        if (response._embedded && response._embedded.status_report) {
                            this.alertMessags = response._embedded.status_report.messages;
                        }
                    }, error => {
                        OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
                        if (error && error._embedded && error._embedded.status_report && error._embedded.status_report.consistent === false) {
                            OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error._embedded.status_report.messages[0].message, 'Error', 0);
                        }
                    });
                }
            }
        }
    }

    confirmationCheck () {
        //to disable confirmation and enable payment for host and rps contract of SWP under PANOCSR-3452
        if(this.withdrawlSurrenderType == 'SYSTEMATIC_WITHDRAWL'){
            if((this.isMrpsContractAlert && this.isGroupAnnuity) || (this.isHostContractAlert && this.isGroupAnnuity)){
                this.systematicConfirmationTab = false;
            } else if(this.isHostContract && this.isRetailAnnuity) {
                this.systematicConfirmationTab = false;
            }
        }
    }

    getPartialSurrenderQuote = (url: string, headers: any) => {
        OcInfraModule.AppInjector.get(CommonService).getCallback(url, headers).subscribe(response => {
            this.surrenderQuoteDetails = response;
            this.surrenderQuoteDetails = Object.assign(this.surrenderQuoteDetails, { ssn_facta_verify: '' });

            if (response._embedded && response._embedded.status_report) {
                this.alertMessags = response._embedded.status_report.messages;
                    this.alertMessags = this.alertMessags.filter(item => item.message != 'No mapping rule matched');
                for (let i = 0; i < response._options.links.length; i++) {
                    if ((response._options.links[i].method == 'POST' || response._options.links[i].method == 'PATCH') && OcInfraModule.AppInjector.get(CommonService).mfaAuthorized) {
                        this.disableTabs = false;
                    } else {
                        this.disableTabs = true;
                    }
                }
            }

        }, error => {
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
            if (error && error._embedded && error._embedded.status_report && error._embedded.status_report.consistent === false) {
                OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error._embedded.status_report.messages[0].message, 'Error', 0);
            }
        });
    }

    isGroupProductType = () => {
        let product_type = this.getNested(this.policyDetails, 'product_type');
        if (product_type && (product_type == "A3" || product_type == "A4")) {
            return true;
        } else {
            return false;
        }
    }

    getNested(obj, ...args) {
        return args.reduce((obj, level) => obj && obj[level], obj)
    }

    getDefaultQuoteDetails() {
        let quoteEndpoint = this.currentPolicyResourceURL + this.quotesAPISource[this.withdrawlSurrenderType];
        OcInfraModule.AppInjector.get(CommonService).getCallback(quoteEndpoint, this.headers).subscribe(response => {
            this.defaultQuoteDetails = response;
        })
    }


    getQueryParams() {
        let params = new HttpParams();
        let effectivedate;
        if (this.surrenderDetails) {
            effectivedate = new Date(this.surrenderDetails.quote_effective_date);
        }
        if (this.withdrawlSurrenderType == "DIVIDEND_WITHDRAWAL") {
            params = params.set('effectiveDate', this.datePipe.transform(effectivedate, "MM/dd/yyyy"));
            params = params.set('netGrossInd', this.receivedSurrenderDetails.net_gross_indicator);
        } else {
            params = params.set('effectiveDate', this.datePipe.transform(effectivedate, "MM/dd/yyyy"));
        }
        return params.toString();
    }

    getAmountRequested(scriptFlag) {
        if (this.withdrawlSurrenderType !== 'SYSTEMATIC_WITHDRAWL') {
            if (this.receivedSurrenderDetails.transaction_level.title == 'Removed Pro Rata basis') {
                return this.receivedSurrenderDetails.transaction_type.title == 'Amount' ? this.receivedSurrenderDetails.surrender_amount : this.receivedSurrenderDetails.surrender_percent;
            } else {
                let isPercent;
                let fundValues = this.receivedSurrenderDetails.fundValues;
                let surrenderAmount: number = 0.0;
                if (this.receivedSurrenderDetails.transaction_type.title == 'Amount') {
                    for (let i = 0; i < fundValues.length; i++) {
                        surrenderAmount += Number(fundValues[i].surrender_amt.replace(/,|\s/g, "").replace("$", ""));
                    }
                } else {
                    isPercent = true;
                    for (let i = 0; i < fundValues.length; i++) {
                        let percent = Number(fundValues[i].surrender_percent.replace(/,|\s/g, "").replace("%", ""));
                        // as per OCCD-3222 percentage shown in Scripts should be sum of percentages entered
                        if (scriptFlag) {
                            surrenderAmount += percent;
                        } else {
                            // to convert percentage entered in to amount and adds up all amounts
                            let fundValue = Number(fundValues[i].fund_value);
                            surrenderAmount += (fundValue * percent) / 100;
                        }
                    }
                }
                return isPercent ? surrenderAmount.toFixed(1) : surrenderAmount.toFixed(2);
            }
        } else {
            const transactionLevel = this.getValueOfField(this.withdrawlSurrenderModel, 'transaction_level');
            if (transactionLevel == 'P') {
                const payoutPercent = this.getValueOfField(this.withdrawlSurrenderModel, 'payout_percent');
                const withdrawalAmount = this.getValueOfField(this.withdrawlSurrenderModel, 'withdrawal_amount');
                return payoutPercent && payoutPercent !== '0.0%' ? (' with ' + payoutPercent + ' of your value ') : (' with an amount of ' + withdrawalAmount);
            } else {
                const option = this.getValueOfField(this.withdrawlSurrenderModel, 'option');
                const fundValues = this.getValueOfField(this.withdrawlSurrenderModel, 'fundValues');
                let SWPFundTotal: number = 0.0;
                if (option === 'U' || option === 'V') {
                    for (let i = 0; i < fundValues.length; i++) {
                        SWPFundTotal += Number(fundValues[i].surrender_unit);
                    }
                    return ' of ' + SWPFundTotal.toFixed(2) + ' units '
                } else {
                    for (let i = 0; i < fundValues.length; i++) {
                        SWPFundTotal += Number(fundValues[i].surrender_amt.replace(/,|\s/g, "").replace("$", ""));
                    }
                    return ' with an amount of ' + '$' + SWPFundTotal.toFixed(2);
                }
            }
        }
    }

    toggleAlertMsg(event) {
        event.target.classList.toggle("alertMsg-overflow");
    }

    getQuoteValues(quoteValues) {
        this.receivedQuoteDetails = Object.assign(this.receivedQuoteDetails, quoteValues);
        if(this.withdrawlSurrenderType == 'FULL_SURRENDER' && this.isLife){
            this.surrenderDetails = Object.assign(this.surrenderDetails);
        }else{
            this.surrenderDetails = Object.assign(this.surrenderDetails, { ssn_facta_verify: '' });
        }
    }

    getPlanId(product) {
        this.plan_id = product;
        if (((this.isGroupAnnuity && this.isMrpsContract)||(this.isGroupAnnuity && this.isHostContract)) && this.withdrawlSurrenderType == 'FULL_SURRENDER') { // GroupMRPS of full surr
            this.getSurrenderDetails();
        }
    }

    getPlanIdPartial(msg, partialHostHeaders) {
        if(this.withdrawlSurrenderType == 'PARTIAL_SURRENDER' && (this.isGroupAnnuity && this.isHostContract)) {
            this.getPartialHostSurrenderDetails(msg.msg, msg.partialHostHeaders);
        }
    }

    getSurrenderDetailsEmit(surrenderDetails) {
        this.receivedSurrenderrDetails = Object.assign(this.receivedSurrenderrDetails, surrenderDetails);
        this.surrenderDetails = this.receivedSurrenderrDetails;
    }

    getQuoteValuesEvent = (params: any) => {
        this.getPartialSurrenderQuote(params.url, params.headers);
    }

    getSystematicWithdrawalsEvent = (params: any) => {
        this.headers = params.headers
        this.getSurrenderDetails()
    }

    getValueOfField(model, fieldId) {
        const item = model.find(item => item.id == fieldId);
        return item ? fieldId === "fundValues" ? item.values : item.value : '';
    }

    onwithdrawlSurrenderModelChange(e) {
        this.withdrawlSurrenderModel = Object.assign([], e);
    }

    openAlertForSsnFactaVerify() {
        OcInfraModule.AppInjector.get(CommonService).showConfirmationPopup('SSN not certified, please review ', ' ', 'ok', 'alert', '300px');
    }

    tabClick(e) {
        if (e.selectedIndex == 3) {
            if (this.withdrawlSurrenderType !== 'FULL_SURRENDER' && this.withdrawlSurrenderType !== 'PARTIAL_SURRENDER') {
                let finalConfirmationObj = {
                    'childQuote': this.childQuote,
                    'childSurrender': this.childSurrender,
                    'childPayment': this.childPayment,
                    'systematicWithdrawlStep': this.systematicWithdrawlStep

                }
                this.commonService.isConfirmationClick(finalConfirmationObj)
            }
            if (this.withdrawlSurrenderType == 'FULL_SURRENDER') {
                let finalConfirmationObj = {
                    'childQuote': this.childQuoteFull,
                    'childSurrender': this.childSurrenderFull,
                    'childPayment': this.childPayment,
                    'systematicWithdrawlStep': this.systematicWithdrawlStep
                }
                this.commonService.isConfirmationClick(finalConfirmationObj)
            }
            if (this.withdrawlSurrenderType == 'PARTIAL_SURRENDER' && !this.isMrpsContract) {
                let finalConfirmationObj = {
                    'childQuote': this.childQuotePartial,
                    'childSurrender': this.childSurrenderPartial,
                    'childPayment': this.childPayment,
                    'systematicWithdrawlStep': this.systematicWithdrawlStep
                }
                this.commonService.isConfirmationClick(finalConfirmationObj)
            }
            if(this.withdrawlSurrenderType == 'PARTIAL_SURRENDER' && this.isMrpsContract) {
                let finalConfirmationObj = {
                    'childQuote': this.childQuotePartial,
                    'childSurrender': this.childSurrenderPartialMRPS,
                    'childPayment': this.childPayment,
                    'systematicWithdrawlStep': this.systematicWithdrawlStep
                }
                this.commonService.isConfirmationClick(finalConfirmationObj)
            }
        }

    if(e.selectedIndex == 2 && this.withdrawlSurrenderType == 'SYSTEMATIC_WITHDRAWL'){
        let finalConfirmationObj = {
            'childSurrender': this.childWithdrawalSystematic,
            'childPayment': this.childPayment,
            'systematicWithdrawlStep': this.systematicWithdrawlStep
              }
              this.commonService.isConfirmationClick(finalConfirmationObj)
      }


        // Open dialog when ssn_facta_verify value not equal to 'Party has certified that the Tax ID is correct'
        if (e.selectedIndex == '1' && this.surrenderDetails.ssn_facta_verify != '8' && this.showSsnFactaVerify && this.withdrawlSurrenderType != 'PARTIAL_SURRENDER' && this.withdrawlSurrenderType != 'SYSTEMATIC_WITHDRAWL' && this.withdrawlSurrenderType != 'FULL_SURRENDER') {
            this.openAlertForSsnFactaVerify();
            this.showSsnFactaVerify = false;
        }
        if(e.selectedIndex == '1' && this.surrenderDetails.ssn_facta_verify != '8' && this.showSsnFactaVerify && this.withdrawlSurrenderType == 'FULL_SURRENDER' && this.isLife) {
            this.openAlertForSsnFactaVerify();
            this.showSsnFactaVerify = false;
        }
        // Open dialog when requested amount is more than 24999.99
        if (this.withdrawlSurrenderType != 'FULL_SURRENDER' && this.withdrawlSurrenderType != 'PARTIAL_SURRENDER' && this.withdrawlSurrenderType != 'SYSTEMATIC_WITHDRAWL') {
            let alertMsg = [];
            alertMsg.push("Amount Exceeds Allowable by Telephone");
            if (e.selectedIndex == '1' && (Number(this.childSurrender.withdrawalAmt.replace("$", "").replace(',', '')) > 24999.99)) {
                let dialogRef = OcInfraModule.AppInjector.get(CommonService).showConfirmation({ content: alertMsg, custom: true }, 'CLOSE', '', 'ALERT', '393px');
                dialogRef.afterClosed().subscribe(res => {
                    res;
                });
            }
        }

        if (this.withdrawlSurrenderType == 'FULL_SURRENDER' && e.selectedIndex == '1') {
            this.stepCompletionIndicator["paymentTab"] = this.childPayment ? this.childPayment.isStepComplete() : false;
        }


        //script API call on load of confirmation tab
        if (e.selectedIndex == '3') {
            let productType = this.policyDetails.data.product_type;
            if (this.withdrawlSurrenderType !== 'FULL_SURRENDER' && this.withdrawlSurrenderType !== 'PARTIAL_SURRENDER' && this.withdrawlSurrenderType !== 'SYSTEMATIC_WITHDRAWL') {

                let roles = OcInfraModule.AppInjector.get(CommonService).selectedRole;
                const resourceService = OcInfraModule.AppInjector.get(ResourceService);
                //due to performance improvement commenting line 315
                //var policyValues = resourceService.getResourceFromAliasName('policyValues').response;
                //sending paramters to GET API call
                let noWithHoldInd = this.childSurrender.zeroWithHolding ? 'Y' : 'N';
                let withHoldOverrideInd = this.childSurrender.overrideChcked ? 'Y' : 'N';
                let withdrawalType = this.childPayment.withdrawToPayPremiumM;
                let taxableGainsValue = parseInt(this.childSurrender.taxableGain.replace("$", "").replace(',', ''));
                let disbursementInd = this.childPayment.disbursementIndM ? this.childPayment.disbursementIndM : 'C';
                let callerRole = (roles).charAt(0).toLocaleUpperCase() + roles.slice(1);
                let href = this.surrenderDetails._links.scripts.href;
                let effectiveDate = (this.surrenderDetails.quote_effective_date) ? (this.surrenderDetails.quote_effective_date) : "";
                let hrefWithParam = `${href}?noWithHoldInd=${noWithHoldInd}&withHoldOverrideInd=${withHoldOverrideInd}&withdrawalType=${withdrawalType}&taxableGainsValue=${taxableGainsValue}&disbursementInd=${disbursementInd}&callerRole=${callerRole}`;
                OcInfraModule.AppInjector.get(CommonService).getCallback(hrefWithParam, this.headers).subscribe(response => {
                    this.scriptRes = response.confirm_message;
                    this.scriptRes = this.childSurrender.withdrawalAmt ? this.scriptRes.replace('________', this.childSurrender.withdrawalAmt).replace("$", "") : " ";
                    this.scriptRes = this.scriptRes.replace('_______', (effectiveDate));
                    if (taxableGainsValue !== 0 && withHoldOverrideInd == 'Y') {
                        this.scriptRes = this.scriptRes.replace('___', this.childSurrender.federalWithHoldingPer).replace("%", "");
                    }
                    this.confirmMessage = this.scriptRes;
                }, error => {
                    OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
                });
            }

            if (this.withdrawlSurrenderType == 'FULL_SURRENDER') {
                let roles = OcInfraModule.AppInjector.get(CommonService).selectedRole;
                //sending paramters to GET API call
                let federal_withholding_percentage = this.childSurrenderFull.getSurrenderValues().federal_withholding_percentage;
                let federal_withholding_override = this.childSurrenderFull.getSurrenderValues().federal_withholding_override;
                let state_withholding_percentage = this.childSurrenderFull.getSurrenderValues().state_withholding_percentage;
                let state_withholding_override = this.childSurrenderFull.getSurrenderValues().state_withholding_override;
                let no_federal_withholding = this.childSurrenderFull.getSurrenderValues().no_federal_withholding;
                let no_state_withholding = this.childSurrenderFull.getSurrenderValues().no_state_withholding;
                let withdrawal_type = this.childSurrenderFull.getSurrenderValues().withdrawal_type;
                let withholdingIndicator = (no_federal_withholding === 'Y' || no_state_withholding === 'Y') ? "Y" : "N";
                let withholdingOverrideInd = (state_withholding_override || federal_withholding_override) ? "Y" : "N";
                let stateWthdAmount = this.childSurrenderFull.surrenderForm.get('state_withholding_amount').value;
                let stateWthdPercent = this.childSurrenderFull.surrenderForm.get('state_withholding_percentage').value;
                let fedaralWthdAmount = this.childSurrenderFull.surrenderForm.get('federal_withholding_amount').value;;
                let fedaralWthdPercent = this.childSurrenderFull.surrenderForm.get('federal_withholding_percentage').value;
                var overrideValueInd:any = "N"
                if(stateWthdAmount == "$0.00" && fedaralWthdAmount == "$0.00" && stateWthdPercent == "0.00%" && fedaralWthdPercent=="0.00%") {
                    overrideValueInd = "N";
                } else if(stateWthdAmount == "0.00" && fedaralWthdAmount == "0.00" && stateWthdPercent == "0.00" && fedaralWthdPercent=="0.00") {
                    overrideValueInd = "N"; // Full surrender Life
                } else {
                     overrideValueInd = "Y";
                }
                let transactionType = this.childPayment.withdrawlSurrenderType == "FULL_SURRENDER" ? "F" : "";

                let disbursementInd = this.childPayment.disbursementIndM ? this.childPayment.disbursementIndM : 'C';
                let paymentValues = this.childPayment.getPaymentValues();
                let disbursement_ind = paymentValues.disbursement_ind[0];
                this.effectiveDate = this.datePipe.transform(this.childSurrenderFull.getSurrenderValues().effective_date, "MM/dd/yyyy");
                let callerRole = (roles).charAt(0).toLocaleUpperCase() + roles.slice(1);
                let href = this.surrenderDetails._links.scripts_url.href;
                let hrefWithParam = `${href}?callerRole=${callerRole}&transactionType=${transactionType}&withholdingIndicator=${withholdingIndicator}&withHoldOverrideInd=${withholdingOverrideInd}&paymentMethod=${disbursement_ind}&method=Y&productCode=${productType}&overrideValueInd=${overrideValueInd}`;
                OcInfraModule.AppInjector.get(CommonService).getCallback(hrefWithParam, this.headers).subscribe(response => {

                  this.scriptRes = response.confirm_message;
                  //var surValues = this.childSurrender.getSurrenderDetails();
                  if(!this.isLife){
                    var surrender_amount = this.surrenderDetails.surrender_amount ? this.surrenderDetails.surrender_amount : '';
                    this.scriptRes = this.scriptRes.replace('_____', surrender_amount);
                  }
                    this.scriptRes = this.scriptRes.replace('___', "Full Surrender");
                    if (disbursement_ind === "E" && (federal_withholding_override === true || federal_withholding_override === false) && (state_withholding_override === true || state_withholding_override === false)) {
                        this.scriptRes = this.scriptRes.replace('_________', this.effectiveDate);
                        this.scriptRes = this.scriptRes.replace('_______', this.childPayment.bank_name);
                        this.scriptRes = this.scriptRes.replace(' &', this.childPayment.transit + " &");
                        this.scriptRes = this.scriptRes.replace('  .', this.childPayment.account_number + ".");
                        this.scriptRes = this.scriptRes.replace('      ', " " + this.surrenderDetails.payeeName);
                    }
                    this.confirmMessage = this.scriptRes;
                }, error => {
                    OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
                });
        }
      if (this.withdrawlSurrenderType == 'PARTIAL_SURRENDER') {
            let roles = OcInfraModule.AppInjector.get(CommonService).selectedRole;
            //sending paramters to GET API call
            let federal_withholding_percentage = this.isMrpsContract?this.childSurrenderPartialMRPS.getSurrenderValues().federal_withholding_percentage:this.childSurrenderPartial.getSurrenderValues().federal_withholding_percentage;
            let federal_withholding_override = this.isMrpsContract?this.childSurrenderPartialMRPS.getSurrenderValues().federal_withholding_override:this.childSurrenderPartial.getSurrenderValues().federal_withholding_override;
            let state_withholding_percentage = this.isMrpsContract?this.childSurrenderPartialMRPS.getSurrenderValues().state_withholding_percentage:this.childSurrenderPartial.getSurrenderValues().state_withholding_percentage;
            let state_withholding_override = this.isMrpsContract?this.childSurrenderPartialMRPS.getSurrenderValues().state_withholding_override:this.childSurrenderPartial.getSurrenderValues().state_withholding_override;
            let no_federal_withholding = this.isMrpsContract?this.childSurrenderPartialMRPS.getSurrenderValues().no_federal_withholding:this.childSurrenderPartial.getSurrenderValues().no_federal_withholding;
            let no_state_withholding = this.isMrpsContract?this.childSurrenderPartialMRPS.getSurrenderValues().no_state_withholding:this.childSurrenderPartial.getSurrenderValues().no_state_withholding;
            //let withdrawal_type = this.childSurrenderPartial.getSurrenderValues().withdrawal_type;
            let withholdingIndicator = (no_federal_withholding === 'Y' || no_state_withholding === 'Y') ? "Y" : "N";
            let withholdingOverrideInd = (state_withholding_override || federal_withholding_override) ? "Y" : "N";
            let stateWthdAmount = this.isMrpsContract?this.childSurrenderPartialMRPS.surrenderForm.get('state_withholding_amount').value : this.childSurrenderPartial.surrenderForm.get('state_withholding_amount').value;
            let stateWthdPercent = this.isMrpsContract?this.childSurrenderPartialMRPS.surrenderForm.get('state_withholding_percentage').value : this.childSurrenderPartial.surrenderForm.get('state_withholding_percentage').value;
            let fedaralWthdAmount = this.isMrpsContract?this.childSurrenderPartialMRPS.surrenderForm.get('federal_withholding_amount').value : this.childSurrenderPartial.surrenderForm.get('federal_withholding_amount').value;
            let fedaralWthdPercent = this.isMrpsContract?this.childSurrenderPartialMRPS.surrenderForm.get('federal_withholding_percentage').value : this.childSurrenderPartial.surrenderForm.get('federal_withholding_percentage').value;
            var overrideValueInd:any = "N"
            if(stateWthdAmount == "$0.00" && fedaralWthdAmount == "$0.00" && stateWthdPercent == "0.00%" && fedaralWthdPercent=="0.00%") {
                overrideValueInd = "N"
            } else {
                overrideValueInd = "Y"
            }
            let transactionType = this.childPayment.withdrawlSurrenderType == "PARTIAL_SURRENDER" ? "P" : "";
            //let taxableGainsValue = parseInt(this.childSurrenderPartial.surrenderDetails.taxable_amount.replace("$", "").replace(',', ''));
            let disbursementInd = this.childPayment.disbursementIndM ? this.childPayment.disbursementIndM : 'C';
            let paymentValues = this.childPayment.getPaymentValues();
            let disbursement_ind = paymentValues.disbursement_ind[0];
            if(!this.isMrpsContract){
                    var surrender_amount = (this.childSurrenderPartial.surrenderForm.get('transaction_level').value == 'P' && this.childSurrenderPartial.surrenderForm.get('transaction_type').value == "A")?this.childSurrenderPartial.getSurrenderValues().surrender_amount:
                    (this.childSurrenderPartial.surrenderForm.get('transaction_level').value == 'P' && this.childSurrenderPartial.surrenderForm.get('transaction_type').value == "P")?this.childSurrenderPartial.getSurrenderValues().surrender_amount:
                    (this.childSurrenderPartial.surrenderForm.get('transaction_level').value == 'F' && this.childSurrenderPartial.surrenderForm.get('transaction_type').value == "P")? this.childSurrenderPartial.getSurrenderValues().calculated_surrender_amount:
                    (this.childSurrenderPartial.surrenderForm.get('transaction_level').value == 'F' || this.childSurrenderPartial.surrenderForm.get('transaction_level').value == 'B')?this.childSurrenderPartial.getSurrenderValues().transaction_amount:"$0.00";
            }
           if(this.isMrpsContract){
                var surrender_amount = (this.childSurrenderPartialMRPS.surrenderForm.get('transaction_level').value == 'P' && this.childSurrenderPartialMRPS.surrenderForm.get('transaction_type').value == "A")?this.childSurrenderPartialMRPS.getSurrenderValues().surrender_amount:
                (this.childSurrenderPartialMRPS.surrenderForm.get('transaction_level').value == 'P' && (this.childSurrenderPartialMRPS.surrenderForm.get('transaction_type').value == "P" || this.childSurrenderPartialMRPS.surrenderForm.get('transaction_type').value == "F"))?this.childSurrenderPartialMRPS.getSurrenderValues().surrender_amount:
                ((this.childSurrenderPartialMRPS.surrenderForm.get('transaction_level').value == 'F' || this.childSurrenderPartialMRPS.surrenderForm.get('transaction_level').value == 'B') && this.childSurrenderPartialMRPS.surrenderForm.get('transaction_type').value == "A")?this.childSurrenderPartialMRPS.getSurrenderValues().transaction_amount:
                ((this.childSurrenderPartialMRPS.surrenderForm.get('transaction_level').value == 'F' || this.childSurrenderPartialMRPS.surrenderForm.get('transaction_level').value == 'B') && (this.childSurrenderPartialMRPS.surrenderForm.get('transaction_type').value == "F" || this.childSurrenderPartialMRPS.surrenderForm.get('transaction_type').value == "P"))?this.childSurrenderPartialMRPS.getSurrenderValues().calculated_surrender_amount:"$0.00";
                }
            let callerRole = (roles).charAt(0).toLocaleUpperCase() + roles.slice(1);
            let href = this.surrenderDetails._links.scripts_url.href;
            let hrefWithParam = `${href}?callerRole=${callerRole}&transactionType=${transactionType}&withholdingIndicator=${withholdingIndicator}&withHoldOverrideInd=${withholdingOverrideInd}&method=Y&paymentMethod=${disbursementInd}&productCode=${productType}&overrideValueInd=${overrideValueInd}`;
            OcInfraModule.AppInjector.get(CommonService).getCallback(hrefWithParam, this.headers).subscribe(response => {
                this.scriptRes = response.confirm_message;
                this.effectiveDate = this.datePipe.transform(this.childQuotePartial.effectivedate, "MM/dd/yyyy");
                this.scriptRes = this.scriptRes.replace('$_____', surrender_amount);
                this.scriptRes = this.scriptRes.replace('___', "Partial Surrender");
                if (disbursement_ind === "E" && (federal_withholding_override === true || federal_withholding_override === false) && (state_withholding_override === true || state_withholding_override === false)) {
                    this.scriptRes = this.scriptRes.replace('_________', this.effectiveDate);
                    this.scriptRes = this.scriptRes.replace('_______', this.childPayment.bank_name);
                    this.scriptRes = this.scriptRes.replace('   &', this.surrenderDetails.transit_number + " &");
                    this.scriptRes = this.scriptRes.replace('    .', this.childPayment.account_number + ".");
                    this.scriptRes = this.scriptRes.replace('       ', " " + this.surrenderDetails.payeeName);
                }
                this.confirmMessage = this.scriptRes;
            }, error => {
                OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
            });
        }
        
    }

        if (e.selectedIndex == 2 && this.withdrawlSurrenderType == 'SYSTEMATIC_WITHDRAWL') {
            let roles = OcInfraModule.AppInjector.get(CommonService).selectedRole;
        }

        this.stepCompletionIndicator["quoteTab"] = this.childQuote ? this.childQuote.isStepComplete() : true;
        this.stepCompletionIndicator["surrenderTab"] = this.childSurrender ? this.childSurrender.isStepComplete() : false;
        if (this.withdrawlSurrenderType == 'FULL_SURRENDER') {
            this.stepCompletionIndicator["surrenderTab"] = this.childSurrenderFull ? this.childSurrenderFull.isStepComplete() : false;
            this.stepCompletionIndicator["quoteTab"] = this.childQuoteFull ? this.childQuoteFull.isStepComplete() : false;
        } else if (this.withdrawlSurrenderType == 'PARTIAL_SURRENDER') {
            this.stepCompletionIndicator["surrenderTab"] = this.childSurrenderPartial ? this.childSurrenderPartial.isStepComplete() : false;
            this.stepCompletionIndicator["quoteTab"] = this.childQuotePartial ? this.childQuotePartial.isStepComplete() : false;
            if(this.isMrpsContract){                
                this.stepCompletionIndicator["surrenderTab"] = this.childSurrenderPartialMRPS ? this.childSurrenderPartialMRPS.isStepComplete() : false;
            }
        } else if(this.withdrawlSurrenderType == 'SYSTEMATIC_WITHDRAWL') {
            this.stepCompletionIndicator["surrenderTab"] = this.childWithdrawalSystematic ? this.childWithdrawalSystematic.isStepComplete() : false;
        }
        this.stepCompletionIndicator["paymentTab"] = this.childPayment ? this.childPayment.isStepComplete() : false;

       let authResponse = OcInfraModule.AppInjector.get(CommonService).authResponse;
        if (this.withdrawlSurrenderType != 'PARTIAL_SURRENDER') {
            this.receivedPaymentDetails = this.childPayment.getPaymentValues();
            this.amtRequestDisableByWithdrawal = this.receivedPaymentDetails.withdrawToPayPremiumM;
        }

        if (e.selectedIndex === 3) {
            // seperating out full, partial and Systematic in order to accomodate any future changes easily.
            if (this.withdrawlSurrenderType !== 'FULL_SURRENDER' && this.withdrawlSurrenderType !== 'PARTIAL_SURRENDER' && this.withdrawlSurrenderType !== 'SYSTEMATIC_WITHDRAWL') {
                this.receivedSurrenderDetails = this.childSurrender.getSurrenderValues();
                this.receivedSurrenderDetails.required_fields == 'Y' ? this.getDefaultQuoteDetails() : null;
                setTimeout(() => {
                    Object.assign(this.receivedPaymentDetails, this.receivedSurrenderDetails, this.defaultQuoteDetails, { confirmationNumber: authResponse && authResponse.updateResponse && authResponse.updateResponse.data.confirmationNumber });
                    //this.surrenderDetails && this.surrenderDetails.firm_id ? this.receivedPaymentDetails.firm_id = this.surrenderDetails.firm_id : null;
                    this.childConfirmation.confirmationValues(this.receivedPaymentDetails, this.surrenderDetails._links.scripts.href);
                }, 7000)
            } else if (this.withdrawlSurrenderType == 'FULL_SURRENDER') {

                if (!this.isLife && !this.isMrpsContract) {
                    this.receivedSurrenderDetails = this.childSurrenderFull.getSurrenderValues();
                    Object.assign(this.receivedPaymentDetails, this.receivedSurrenderDetails, { confirmationNumber: authResponse && authResponse.updateResponse && authResponse.updateResponse.data.confirmationNumber });
                    // this.surrenderDetails && this.surrenderDetails.firm_id ? this.receivedPaymentDetails.firm_id = this.surrenderDetails.firm_id : null;
                    this.childConfirmation.swpConfirmationScript(this.receivedPaymentDetails, this.surrenderDetails._links.scripts.href);
                }

                if (this.isLife) {
                    this.receivedSurrenderDetails = this.childSurrenderFull.getSurrenderValues();
                    this.receivedPaymentDetails = { ...this.receivedPaymentDetails, ...this.receivedSurrenderDetails };
                    this.receivedPaymentDetails = { ...this.receivedPaymentDetails, ...{ confirmationNumber: authResponse && authResponse.updateResponse && authResponse.updateResponse.data.confirmationNumber } };

                    this.childConfirmation.confirmationValuesFullSurrenderLife(this.receivedPaymentDetails);

                }
            }
        }

        if (e.selectedIndex === 2 && this.withdrawlSurrenderType == 'SYSTEMATIC_WITHDRAWL') {
            if (this.isCv15Contract || this.isMrpsContract) {
                this.receivedSurrenderDetails = this.childWithdrawalSystematic.getSurrenderValues();
                Object.assign(this.receivedPaymentDetails, this.receivedSurrenderDetails, { confirmationNumber: authResponse && authResponse.updateResponse && authResponse.updateResponse.data.confirmationNumber });
                this.childConfirmation.confirmationValuesSystematicWithdrawalCV15(this.receivedPaymentDetails);

            }
        }
    }


    isConfirmationTab(index) {
        return this.withdrawlSurrenderType == "SYSTEMATIC_WITHDRAWL" && index == 2 ? true : (index == 3);
    }

    getDistributionData(event) {
        this.childPayment.setDistributionCode(event);
    }

    getNetCheckAmount(event) {
        this.net_check_amount = event;
        this.scriptRes = this.scriptRes.replace('_____', this.net_check_amount);
    }


    updateGainWitholdDtls(event) {
        let receivedPaymentDetails = this.childPayment.getPaymentValues();
        let surrenderDtls = this.childSurrender.getSurrenderValues();
        OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
        let updateQuoteRequestPayload = Object.assign({}, this.surrenderDetails);
        delete updateQuoteRequestPayload._links;
        delete updateQuoteRequestPayload._options;
        delete updateQuoteRequestPayload.withdrawal_types;
        delete updateQuoteRequestPayload.withdraw_to_pay_premium;
        updateQuoteRequestPayload = {
            ...updateQuoteRequestPayload,
            override_withhold: surrenderDtls.federal_withholding_override,
            zero_withhold: surrenderDtls.zero_withhold,
            withdraw_to_pay_premium: receivedPaymentDetails.withdraw_to_pay_premium,
            withdraw_amt: surrenderDtls.withdraw_amt ? surrenderDtls.withdraw_amt.replace('$', '').replace(',', '') : 0,
            withdrawal_types: surrenderDtls.transaction_type ? surrenderDtls.transaction_type.enum : [],
            net_gross_ind: surrenderDtls.net_gross_indicator,
            state_withh_amount: surrenderDtls.federal_withholding_override && surrenderDtls.state_withholding_amount ? surrenderDtls.state_withholding_amount.replace('$', '') : 0,
            fed_withh_amount: surrenderDtls.federal_withholding_override && surrenderDtls.fed_withh_amount ? surrenderDtls.fed_withh_amount.replace('$', '').replace(',', '') : 0,
            div_int_withholding: surrenderDtls.div_int_withholding ? surrenderDtls.div_int_withholding.replace('$', '') : 0,
            disbursement_ind: receivedPaymentDetails.disbursement_ind
        };
        // Api call for updating gain withold
        OcInfraModule.AppInjector.get(CommonService).postCallback(this.surrenderDetails._links['updatequotes'].href, updateQuoteRequestPayload, this.headers).subscribe(response => {
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
            this.surrenderDetails.state_withh_amount = response.state_withh_amount;
            this.surrenderDetails.state_withh_percent = response.state_withh_percent;
            this.surrenderDetails.transaction_gain = response.transaction_gain;
            this.surrenderDetails.trx_gain = response.trx_gain;
            this.surrenderDetails.max_withdrawal_amt = response.max_withdrawal_amt;
            this.surrenderDetails.gross_withdrawal_amt = response.gross_withdrawal_amt;
            this.surrenderDetails.fed_withh_amount = response.fed_withh_amount;
            this.surrenderDetails.fed_withh_percent = response.fed_withh_percent;
            // this.updateGainWitholdValues(this.surrenderDetails);
            this.childSurrender.updateGainWitholdValues(this.surrenderDetails);
            OcInfraModule.AppInjector.get(AdvGrowlService).createTimedSuccessMessage(" Updated Gain/Withhold successfully", 'Success', 5000);

        }, error => {
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
            if (error && error._embedded && error._embedded.status_report && error._embedded.status_report.messages.length > 0) {
                OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error._embedded.status_report.messages[0].message, 'Error', 5000);
            } else
                OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage("Failed to update Gain Withold. Please try again.", 'Error', 5000);
        });
    }
}