import { Component, OnInit, EventEmitter, Output, ElementRef } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { PartySearchFactory } from '../../factories/partySearchFactory';
import { ResourceService, OcInfraModule, APICallerService, OptionParams } from '@diaas/ux-web';
import { CommonService, retryOperation } from '../../services/common.service';

@Component({
  selector: 'iulfund-table',
  templateUrl: './iulfundTable.component.html',
})

export class IulFundTableComponent implements OnInit {

  displayedColumns: string[] = ['name','fund_type_desc','allocation_percent','interest_rate','fund_value'];
  selectedDate: Object = {};
  fundList:  any[] = [];
  noRecords: boolean = true;
  fundsObj: any = {};
  statusMsg: string="";
  message: boolean = false;
  olsPolicyfund: boolean = false;
  csrPolicyfund: boolean = false;
  policyDetails: any;
  retryDelay = 3000;
  retryAttempts = 7;
  constructor(public elementRef: ElementRef) {

  }

    ngOnInit() {
        this.getPolicyDetailsPromise();
    }

    ngAfterViewInit() { }

    getPolicyDetailsPromise = async () => {
        await retryOperation(this.getPolicyDetails, this.retryDelay, this.retryAttempts)
            .then((res) => this.getFundData())
            .catch((err) => err);
    };

    getPolicyDetails = () => {
        this.policyDetails = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyDetails');
        if (this.policyDetails) {
            return Promise.resolve(this.policyDetails);
        } else {
            return Promise.reject('No data');
        }
    }

    getFundData = async () => {
        var loanHistoryURL = OcInfraModule.AppInjector.get(CommonService).getAllUrlOfPolicyDetails();
        //Adding this code because api wa not getting called.
        if (loanHistoryURL["financial_funds"] && loanHistoryURL["financial_funds"].href) {
            let optionParams = new OptionParams();
            optionParams.headers = OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("loanTransactionHistory");
            OcInfraModule.AppInjector.get(APICallerService).get(loanHistoryURL["financial_funds"].href, optionParams).subscribe(response => {
                console.log("funds response", response.response._links.item);
                this.fundList = response.response._links.item;
                let fundIndex = 0;
                if (this.fundList.length > 0) {
                    response.response._links.item.forEach(element => {
                        element.summary.id = fundIndex + 'F';
                        fundIndex++;
                    });
                }
                this.noRecords = this.fundList && !this.fundList.length;
                if (response.response._embedded && response.response._embedded.status_report.messages) {
                    response.response._embedded.status_report.messages.forEach(msgObj => {
                        this.statusMsg += msgObj.message;
                        this.message = true;
                    });
                }
            });
        }
        this.olsPolicyfund = this.elementRef.nativeElement.id == 'iulOlsFundTable' ? true : false;
        this.csrPolicyfund = this.elementRef.nativeElement.id == 'iulfundTable' ? true : false;
    }

  testChange(event, data, selectedValue, selectedKey, columnName,valueType){
    let apiName = 'funds';
    let currencyFields = OcInfraModule.AppInjector.get(CommonService).selectdCurrencyFields;
    selectedValue= valueType=='currency'? '$' + selectedValue.replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,') : selectedValue;
    let selectedVal;
    if(this.selectedDate[data.id] && columnName !== 'Investment'){
        selectedVal = selectedValue ? selectedValue : 'No data in admin system';
        event.checked ? this.selectedDate[data.id].push(selectedKey+':'+ selectedVal) : this.selectedDate[data.id].splice(this.selectedDate[data.id].indexOf(selectedKey+':'+ selectedVal), 1)
    } else { 
      if(!this.selectedDate[data.id]){
        selectedVal = selectedValue ? selectedValue : 'No data in admin system';
        this.selectedDate[data.id] = new Array(selectedKey + ':' + selectedVal);
      }
    }

    if(this.selectedDate[data.id].length == 1 && this.selectedDate[data.id][0].indexOf('Investment') !== -1 && !event.checked){
      delete this.selectedDate[data.id];
    } else {
      if (this.selectedDate[data.id][0].indexOf('Investment') === -1) {
        this.selectedDate[data.id].unshift("Investment:" + data.name);
      }
    }

    let html =  '';
    if(Object.keys(this.selectedDate).length > 0){
      html +=  '<dl class="pad-lAr-10px">';
      Object.keys(this.selectedDate).forEach(key => {
        this.selectedDate[key].forEach(element => {
          if (currencyFields.includes(element.split(":")[0])) {
            html += '<dt class="pull-left">' +element.split(":")[0]  + ':</dt><dd style = "font-weight: 200;">&nbsp;'+ OcInfraModule.AppInjector.get(CommonService).convertToCurrency(element.split(":")[1]) + '</dd>';
          } else {
            html += '<dt class="pull-left">' +element.split(":")[0]  + ':</dt><dd style = "font-weight: 200;">&nbsp;'+element.split(":")[1] + '</dd>';
          }
        });
      });
      html += '</dl>';
    }

    let selectedData = [];
    if(this.fundList.length > 0){
        this.fundList.forEach(element => {
          if(this.selectedDate.hasOwnProperty(element.summary.id)){
              let currentField = {};
              currentField[data.fund_type_desc] = this.selectedDate[element.summary.id];
              selectedData.push(currentField);
          }
        });
    }

    localStorage.setItem('fundInfo', JSON.stringify(selectedData));
    OcInfraModule.AppInjector.get(CommonService).fundHtml = html;
    OcInfraModule.AppInjector.get(CommonService).showfundFinalDetails();
    let finalHtml = OcInfraModule.AppInjector.get(CommonService).getFinalHtml();
    let requestedDetailSectionBilling = document.getElementsByClassName("requested_details_section_billing")[0];
    requestedDetailSectionBilling.innerHTML = finalHtml;
  }

  checkedMesseage(event, selectedValue, selectedKey) {
    let html = '';
    if (event.checked) {
      this.selectedDate["annuity"] = new Array(selectedKey + ':' + selectedValue ? selectedValue : 'No data in admin system.');
      localStorage.setItem('fundInfo', JSON.stringify(this.selectedDate));
      html = '<dl class=""><dt class="pull-left">' + selectedKey + ':</dt><dd style="font-weight: 200;">&nbsp;' + selectedValue + '</dd></d1>';
      OcInfraModule.AppInjector.get(CommonService).fundMessageHtml = html;
    } else {
      localStorage.removeItem('fundInfo');
      OcInfraModule.AppInjector.get(CommonService).fundMessageHtml = '';
    }

    OcInfraModule.AppInjector.get(CommonService).showfundFinalDetails();
    OcInfraModule.AppInjector.get(CommonService).showRequestedDetails();
}

}