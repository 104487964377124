import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Configuration, OcInfraModule, ResourceService } from '@diaas/ux-web';
import * as _ from 'lodash';
import { cloneDeep, filter } from 'lodash';
import { ToasterService } from 'angular2-toaster';
import * as moment from 'moment';
import { AdvGrowlService } from 'primeng-advanced-growl';
import { CommonService, retryOperation } from '../../../services/common.service';
import { OneOf, OneOfData, Rebalancing, RelabancingPayload, ScheduledFund, businessClosedContent, createAlertContent, modifyAlertContent, terminateAlertContent, ItemProducts, ItemAssetAccount, UnscheduledFund } from './rebalancing.constant';

export type MethodIndicator = "post" | "terminate" | "update";
@Component({
    selector: 'app-rebalancing',
    templateUrl: './rebalancing.component.html',
    styleUrls: ['./rebalancing.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RebalancingComponent implements OnInit, AfterViewInit, OnDestroy {

    @Output() onSubmitChange: EventEmitter<any> = new EventEmitter<any>();
    @Output() alertsEvent = new EventEmitter<any>();
    @Output() scrollTop: EventEmitter<string> = new EventEmitter<string>();


    _allocationChanges: any;
    isDisableUtilizeModel: boolean= false;
    get allocationChanges(): any {
        return this._allocationChanges;
    }
    @Input() set allocationChanges(value: any) {
        this._allocationChanges = value;
        //this.isAllocationChangeSubmitted();
    }

    policyDetails: any;
    resourceService = OcInfraModule.AppInjector.get(ResourceService);

    alerts: any;
    alertColor: any;
    isSubmitSuccessful: boolean = false;
    //disableCopyFromFutureAllocation: boolean;
    disableRebalanceTab: boolean = false;
    confirmationOverride: any;
    displayStockMaketDialog = true;
    disableCreate: boolean = false;
    isReadonly: boolean = false;
    isNextBusinessDay: boolean;
    alertMessages: any;

    totalOfFunds: any;
    tableSortStates: any;
    rebalancingFrequency: OneOfData[] = [];
    actionCodes: OneOfData[] = [];


    funds: Rebalancing;
    rebalancingForm: FormGroup;
    authorizedRole: any;
    agentorAssistant: boolean = false;
    headers = { ...{ role: OcInfraModule.AppInjector.get(CommonService).authorizedRole, client_id: OcInfraModule.AppInjector.get(CommonService).productInfo.client, wma_user: window.sessionStorage.getItem('username') }, user_role: "csr", agent_code: OcInfraModule.AppInjector.get(CommonService).agentId, ...OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("search") };
    scheduledFunds: ScheduledFund[];
    unScheduledFunds: ScheduledFund[];
    methodIndicator: MethodIndicator;

    isGroupAnnuity: boolean = false;

    clientProducts: ItemProducts[];
    assetAccounts: ItemAssetAccount[];

    clientProductItems: any;
    assetAccountItems: any;

    clientProductsUrl: string = '';
    assetAccountsUrl: string = '';

    retryDelay = 3000;
    retryAttempts = 3;

    isGroupProduct: boolean = false;
    isMRPS: boolean = false;
    modelCodeOptions = [];
    isMrpsNotApplicable: boolean = false;

    params = {};
    isURDisabled: boolean = false;
    
    constructor(private toasterService: ToasterService, private formBuilder: FormBuilder, private commonService: CommonService, private cdRef: ChangeDetectorRef) { }

    ngOnInit() {
        this.policyDetails = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyDetails') ? OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyDetails') : null;
        if(this.isProductTypeA4()){
            this.isMRPS = this.policyDetails.data.client_id.length == 9 ? true : false;
        } 
        if(this.isProductTypeA2()) {
            this.isMRPS = this.policyDetails.data.policy_number.length == 9 ? true : false;
        }
        this.initRebalancingForm();
    }

    async ngAfterViewInit() {
        this.authorizedRole = this.commonService.authorizedRole;
        this.isGroupProduct = this.isProductTypeA4();
        
        if (this.isGroupProduct) {
            this.processA4Product();
        } else {
            this.getRebalancingDetails();
        }
    }

    initRebalancingForm = () => {
        this.rebalancingForm = this.formBuilder.group({
            agent_or_assistant: [false, [Validators.requiredTrue]],
            effective_date: ["", []],
            trans_type: ["", []],
            rebalancing_frequency: ["", [Validators.required]],
            rebalance_start_date: ["", [Validators.required]],
            next_rebalance_date: ["", []],
            rebalancing_status: ["", []],
            previous_rebalance_date: ["", []],
            final_rebalance_date: ["", [Validators.required]],
            rebalancing_type: ['SR'],
        });

        this.isGroupProduct = this.isProductTypeA4();
        if (this.isGroupProduct) {
            this.rebalancingForm.addControl("productType", new FormControl(""));
            this.rebalancingForm.addControl("accountType", new FormControl(""));
        }

        if (this.isMRPS) {
            this.rebalancingForm.addControl("allocationModel", new FormControl(""));
        }
    }

    get rebalancing_type() {
        return this.rebalancingForm.get('rebalancing_type').value;
    }

    set rebalancing_type(val) {
        this.rebalancingForm.get('rebalancing_type').setValue(val);
    }

    onRebalancingType = ($event: any) => {
       if($event.target.value == 'SR') {
        this.rebalancingForm.get('trans_type').enable();
        this.rebalancingForm.get('rebalancing_frequency').enable();
        this.rebalancingForm.get('rebalance_start_date').enable();
        this.rebalancingForm.get('final_rebalance_date').enable();
        if(this.isMRPS){
        this.rebalancingForm.get('allocationModel').enable();
        }
        this.rebalancingForm.get('rebalancing_frequency').setValidators([Validators.required]);
        this.rebalancingForm.get('rebalance_start_date').setValidators([Validators.required]);
        this.rebalancingForm.get('final_rebalance_date').setValidators([Validators.required]);
        this.scheduledFunds = this.funds.rb_scheduled_funds;
        this.totalOfFunds=this.getTotalofAllocationPercent(this.getFilteredFunds(this.scheduledFunds));
       } else if($event.target.value == 'UR') {
        this.rebalancingForm.get('trans_type').disable();
        this.rebalancingForm.get('rebalancing_frequency').disable();
        this.rebalancingForm.get('rebalance_start_date').disable();
        this.rebalancingForm.get('final_rebalance_date').disable();
        this.rebalancingForm.controls['trans_type'].setValue('');
        this.rebalancingForm.controls['rebalancing_frequency'].setValue('');
        this.rebalancingForm.controls['rebalance_start_date'].setValue('');
        this.rebalancingForm.controls['final_rebalance_date'].setValue('');
        this.rebalancingForm.get('rebalancing_frequency').clearValidators();
        this.rebalancingForm.get('rebalance_start_date').clearValidators();
        this.rebalancingForm.get('final_rebalance_date').clearValidators();
        if(this.isMRPS){
        this.rebalancingForm.controls['allocationModel'].setValue('');
        this.rebalancingForm.get('allocationModel').disable();
        }
        this.unScheduledFunds = this.funds.rb_unscheduled_funds;
        this.isDisableUtilizeModel = false;
        this.totalOfFunds=this.getTotalofAllocationPercent(this.getFilteredFunds(this.unScheduledFunds));
       }
    }

    isProductTypeA4 = () => {
        let product_type = this.getNested(this.policyDetails, 'response', 'product_type');
        if (product_type && (product_type == "A4" || product_type == "A3")) {
            return true;
        } else {
            return false;
        }
    }

    isProductTypeA2 = () => {
        let product_type = this.getNested(this.policyDetails, 'response', 'product_type');
        if (product_type && product_type == "A2") {
            return true;
        } else {
            return false;
        }
    }

    processA4Product = async () => {
        // COMMENTING FOR - PANOCSR-2590
        // if (this.authorizedRole === 'Authorized Third Party' || this.authorizedRole === 'Guardian' || this.authorizedRole === 'POA-Power of Attorney' || this.authorizedRole === 'Power of Attorney') {
        //     this.disableRebalanceTab = true;
        // } else {
        //     this.disableRebalanceTab = false;
        // }

        this.clientProductsUrl = this.policyDetails && this.policyDetails._links && this.policyDetails._links.clientproducts ? this.policyDetails._links.clientproducts.href : null;
        this.assetAccountsUrl = this.policyDetails && this.policyDetails._links && this.policyDetails._links.clientassetaccounts ? this.policyDetails._links.clientassetaccounts.href : null;

        this.getClientProductsPromise();
        this.getAssetAccountsPromise();

    }

    getClientProductsPromise = async () => {
        await retryOperation(this.getClientProducts, this.retryDelay, this.retryAttempts)
            .then((res) => {
                this.commonService.clientProducts = res;
                this.clientProducts = res;
                this.processProductTypes(this.clientProducts);
            })
            .catch((err) => this.getClientProductsCall());
    }

    getAssetAccountsPromise = async () => {
        await retryOperation(this.getAssetAccounts, this.retryDelay, this.retryAttempts)
            .then((res) => {
                this.commonService.clientAssetAccountsAll = res;
                this.assetAccounts = res;
            })
            .catch((err) => this.getAssetAccountsCall());
    }

    getClientProducts = async () => {
        let clientProducts = await this.commonService.getClientProducts();
        if (clientProducts) {
            return Promise.resolve(clientProducts);
        } else {
            return Promise.reject('No data');
        }
    }

    getAssetAccounts = async () => {
        let assetAccounts = await this.commonService.getClientAssetAccountsAll();
        if (assetAccounts) {
            return Promise.resolve(assetAccounts);
        } else {
            return Promise.reject('No data');
        }
    }

    getClientProductsCall = () => {
        OcInfraModule.AppInjector.get(CommonService).getCallback(this.clientProductsUrl, this.headers).subscribe((response: any) => {
            if (response && response._links) {
                OcInfraModule.AppInjector.get(CommonService).clientProducts = response._links.item;
                this.clientProducts = response._links.item;
            }
        }, (error: any) => {
            if (error && error._embedded && error._embedded.status_report && error._embedded.status_report.consistent === false) {
                OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error._embedded.status_report.messages[0].message, 'Error', 0);
            }
        });
    }

    getAssetAccountsCall = () => {
        OcInfraModule.AppInjector.get(CommonService).getCallback(this.assetAccountsUrl, this.headers).subscribe((response: any) => {
            if (response && response._links) {
                OcInfraModule.AppInjector.get(CommonService).clientAssetAccountsAll = response._links.item;
                this.assetAccounts = response._links.item;
            }
        }, (error: any) => {
            if (error && error._embedded && error._embedded.status_report && error._embedded.status_report.consistent === false) {
                OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error._embedded.status_report.messages[0].message, 'Error', 0);
            }
        });
    }

    processProductTypes = (clientProducts: ItemProducts[]) => {      
        if (clientProducts.length > 0) {
          this.clientProductItems = clientProducts.filter((item: any) => {
              return (item.summary.product_type == 'A4') && { label: item.summary.plan_code, value: item.summary.plan_code }
          }).map((item: any) => {
              return { label: item.summary.plan_code, value: item.summary.plan_code}
          });
        }
    }

    productTypeChange = (e: any) => {
        this.rebalancingForm.get('productType').setValue(e.value);
        let assetAccounts = this.assetAccounts.find((item: ItemAssetAccount) => item.summary.plan_code == e.value);
        this.assetAccountItems = assetAccounts.summary.asset_account_names.filter((item: any) => {
            return (item != 'ALL ASSET ACCOUNTS' && item != '- null') && { value: item, label: item }
        }).map((item: any) => {
            return { value: item, label: item }
        });
    }

    accountTypeChange = (e: any) => {
        this.rebalancingForm.get('accountType').setValue(e.value);
        let plan_code = this.rebalancingForm.controls.productType.value;
        this.headers.plan_code = plan_code;
        this.headers.client_id = this.commonService.productInfo.client;
        this.headers.asset_account = e.value.split('-')[0].trim();
        this.getRebalancingDetails();
    }


    getRebalancingDetails = async () => {
        let href = this.policyDetails && this.policyDetails._links['funds/rebalancing'].href;
        if (!href) {
            this.disableRebalanceTab = true;
            return;
        }

        await OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false });
        await OcInfraModule.AppInjector.get(CommonService).getCallback(href, this.headers, this.params).subscribe((response: Rebalancing) => {
            this.funds = <Rebalancing>(response);
            
            let hrefExist = this.funds._options.links.length && this.funds._options.links.find((item) => item.method == 'POST' || item.method == 'PATCH');
            this.isReadonly = !hrefExist ? true : false;
            
            if (this.funds.host_contract) {
                this.isReadonly = true;
                //for host contracts we need to disable scheduled and unscheduled radio buttons along with all
                document.getElementById("unscheduledRebalancing").setAttribute('disabled', 'disabled');
                document.getElementById("scheduledRebalancing").setAttribute('disabled', 'disabled');
            }

            if(this.funds.rebalancing_status && this.funds.rebalancing_status == 'T') {
                this.isMrpsNotApplicable = true
            } 

            if (this.funds._embedded && this.funds._embedded.status_report) {
                this.alertMessages = this.funds._embedded.status_report.messages;
                this.alertsEvent.emit({
                    alertMessages: this.alertMessages,
                    tabName: 'Rebalancing',
                    isDisabled: this.disableRebalanceTab
                });
            }

            this.scheduledFunds = response ? JSON.parse(JSON.stringify(response.rb_scheduled_funds)) : [];
            this.unScheduledFunds = response ? JSON.parse(JSON.stringify(response.rb_unscheduled_funds)) : [];
            this.loadActionCodes();
            this.loadRebalancingFrequency();
            this.isMRPS && this.loadModelCodeOptions();
            this.loadRebalancingForm();
            this.loadTableSortStates();

            if(this.isMRPS && this.funds.model_code) {
                this.utilizeModelAction();
            }
            
            if(this.funds && this.funds.existing_segment) {
                 document.getElementById("unscheduledRebalancing").setAttribute('disabled', 'disabled');
            }

        }, async (error: any) => {
            await OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
            if (error && error._embedded && error._embedded.status_report && error._embedded.status_report.consistent === false) {
                OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error._embedded.status_report.messages[0].message, 'Error', 0);
            }
        });
    }


    getRebalancingDetailsByAllocationModel = async () => {
        let href = this.policyDetails && this.policyDetails._links['funds/rebalancing'].href;
        if (!href) {
            this.disableRebalanceTab = true;
            return;
        }
        href = href.split('=')[0] + '/arrangements/funds/rebalancing/allocationDetails';
        await OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false });
        await OcInfraModule.AppInjector.get(CommonService).getCallback(href, this.headers, this.params).subscribe((response: Rebalancing) => {
            // this.funds = <Rebalancing>(response);
            let funds = <Rebalancing>(response)
            this.scheduledFunds = response ? JSON.parse(JSON.stringify(response.rb_scheduled_funds)) : [];
            this.unScheduledFunds = response ? JSON.parse(JSON.stringify(response.rb_unscheduled_funds)) : [];
            this.funds.rb_scheduled_funds = response ? JSON.parse(JSON.stringify(response.rb_scheduled_funds)) : [];
            this.funds.rb_unscheduled_funds = response ? JSON.parse(JSON.stringify(response.rb_unscheduled_funds)) : [];

            if (funds.rebalancing_frequency) {
                this.rebalancingForm.controls.rebalancing_frequency.setValue(funds.rebalancing_frequency);
                this.rebalancingForm.get('rebalancing_frequency').disable();
            } else {
                this.rebalancingForm.controls.rebalancing_frequency.setValue('');
                this.rebalancingForm.controls.rebalancing_frequency.setValidators([Validators.required]);
            }

            this.totalOfFunds = this.scheduledFunds ? this.getTotalofAllocationPercent(this.scheduledFunds) : '0.0%';
            this.enableSubmit();
            this.disableCreate = (this.totalOfFunds == '0.0%') ? true : false;

            this.cdRef.detectChanges(); 
            this.loadTableSortStates();
        }, async (error: any) => {
            await OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
            if (error && error._embedded && error._embedded.status_report && error._embedded.status_report.consistent === false) {
                OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error._embedded.status_report.messages[0].message, 'Error', 0);
            }
        });
    }

    loadRebalancingForm = () => {
        this.rebalancingForm.patchValue({
            trans_type: this.funds.trans_type ? this.funds.trans_type : '',
            rebalancing_status: this.funds.rebalancing_status ? this.funds.rebalancing_status : '',
            previous_rebalance_date: this.funds.previous_rebalance_date ? moment(this.funds.previous_rebalance_date).format('MM/DD/yyyy') : '',
        });

        this.agentorAssistant = (this.authorizedRole === 'Agent' || this.authorizedRole === 'Agent Assistant' || this.authorizedRole === 'BOA Back Office Assistant (Advisor Assistant)')

        if (!this.agentorAssistant) {
            this.rebalancingForm.controls.agent_or_assistant.setValue(true);
            this.rebalancingForm.get('agent_or_assistant').disable();
            this.rebalancingForm.controls.agent_or_assistant.clearValidators();
        } else {
            this.rebalancingForm.controls.agent_or_assistant.setValue(false);
            this.rebalancingForm.controls.agent_or_assistant.setValidators([Validators.requiredTrue]);
        }
        this.rebalancingForm.controls.agent_or_assistant.updateValueAndValidity();

        if (this.funds.effective_date) {
            this.rebalancingForm.controls.effective_date.setValue(moment(this.funds.effective_date));
            this.rebalancingForm.get('effective_date').disable();
        }

        if (this.funds.rebalance_start_date) {
            this.rebalancingForm.controls.rebalance_start_date.setValue(moment(this.funds.rebalance_start_date));
            this.rebalancingForm.get('rebalance_start_date').disable();
        }

        if (this.funds.final_rebalance_date) {
            this.rebalancingForm.controls.final_rebalance_date.setValue(moment(this.funds.final_rebalance_date));
            this.rebalancingForm.get('final_rebalance_date').disable();
        }

        if (this.funds.next_rebalance_date) {
            this.rebalancingForm.controls.next_rebalance_date.setValue(moment(this.funds.next_rebalance_date));
        }

        if (this.funds.rebalancing_status && this.funds.rebalancing_status == 'A') {
            this.rebalancingForm.controls.rebalancing_status.setValue('Active');
        } else {
            this.rebalancingForm.controls.rebalancing_status.setValue(this.funds.rebalancing_status);
        }

        if (this.funds.rebalancing_frequency) {
            this.rebalancingForm.controls.rebalancing_frequency.setValue(this.funds.rebalancing_frequency);
            this.rebalancingForm.get('rebalancing_frequency').disable();
        } else {
            this.rebalancingForm.controls.rebalancing_frequency.setValue('');
            this.rebalancingForm.controls.rebalancing_frequency.setValidators([Validators.required]);
        }
        this.rebalancingForm.controls.rebalancing_frequency.updateValueAndValidity();

        this.totalOfFunds = this.scheduledFunds ? this.getTotalofAllocationPercent(this.scheduledFunds) : '0.0%';

        if (!this.agentorAssistant || this.isReadonly) {
            this.rebalancingForm.get('agent_or_assistant').disable();
        }

        if (this.isMRPS && this.funds.model_code) {
            this.rebalancingForm.controls.allocationModel.setValue(this.funds.model_code);
            this.isDisableUtilizeModel = true;
        }

        if (this.isReadonly) {
            this.rebalancingForm.get('effective_date').disable();
            this.rebalancingForm.get('trans_type').disable();
            this.rebalancingForm.get('rebalancing_frequency').disable();
            this.rebalancingForm.get('rebalance_start_date').disable();
            this.rebalancingForm.get('final_rebalance_date').disable();
        }

        //.isAllocationChangeSubmitted();

    }

    loadActionCodes = () => {
        let actionCodes = this.getNested(this.funds._options.properties, 'action_code');
        if (actionCodes && actionCodes.oneOf.length > 0) {
            this.actionCodes = actionCodes.oneOf.filter((item: OneOf): OneOf => {
                if (this.funds.rebalancing_status == 'A') {
                    return (item.enum[0] == 'C' || item.enum[0] == 'T') && <OneOf>item;
                } else if (!('rebalancing_status' in this.funds) || this.funds.rebalancing_status == 'T') {
                    return (item.enum[0] == 'A') && <OneOf>item;
                }
            }).map((ele: OneOf): OneOfData => {
                return <OneOfData>{ value: ele.enum[0], label: ele.title };
            });
        }
    }

    loadRebalancingFrequency = () => {
        let rebalancingFrequency = this.getNested(this.funds._options.properties, 'rebalancing_frequency');
        if (rebalancingFrequency && rebalancingFrequency.oneOf.length > 0) {
            this.rebalancingFrequency = rebalancingFrequency.oneOf.map((item: OneOf) => <OneOfData>{ value: item.enum[0], label: item.title });
        }
    }

    loadModelCodeOptions = () => {        
        if (this.funds.model_code_options && this.funds.model_code_options.length > 0) {
            this.modelCodeOptions = this.funds.model_code_options.map((item: any) => <OneOfData>{ value: item.enum, label: item.title });
        }
    }

    utilizeModelAction = () => {
        let allocationModel = this.rebalancingForm.get('allocationModel').value;
        this.params = {"allocationModel": allocationModel};
        this.getRebalancingDetailsByAllocationModel();
    }   

    loadTableSortStates = () => {
        this.tableSortStates = [
            { "column": "number", "isAsc": false },
            { "column": "name", "isAsc": false },
            { "column": "fund_value", "isAsc": true }
        ]
    }

    // Calculates total of allocation percent column
    getTotalofAllocationPercent(fundsList) {
        let total = 0.0;
        total = fundsList.reduce((n, {allocation_percent}) => n + Number(allocation_percent), 0);
        let tot = this.formatPercent('', 'blur', total.toString());
        return tot;
    }

    onActionChange(e) {
        if (e.value == 'T') {
            this.rebalancingForm.controls.final_rebalance_date.setValue(moment('12/31/9999'));
            this.isMrpsNotApplicable = true;
        } else {
            if (this.funds.final_rebalance_date) {
                this.rebalancingForm.controls.final_rebalance_date.setValue(moment(this.funds.final_rebalance_date));
            }
            this.isMrpsNotApplicable = false;
        }
        this.enableSubmit();
    }

    onAllocationModelChange = ($event: any) => {
        let allocationModel = this.rebalancingForm.get('allocationModel').value;
        if(allocationModel) {
            this.isDisableUtilizeModel = true;
            this.rebalancingForm.get('rebalance_start_date').disable();
            this.rebalancingForm.get('rebalance_start_date').clearValidators();
            this.rebalancingForm.get('final_rebalance_date').clearValidators();
            let content = 'In order to update funds to utilize this model you must press the Utilize Model button';
            let dialogRef = OcInfraModule.AppInjector.get(CommonService).showConfirmation(content, 'ok', '', 'ALERT', '393px');

        } else {
            this.isDisableUtilizeModel = false;
            this.rebalancingForm.get('rebalance_start_date').enable();
            this.rebalancingForm.get('rebalance_start_date').setValidators([Validators.required]);
            this.rebalancingForm.get('final_rebalance_date').setValidators([Validators.required]);

            this.rebalancingForm.get('rebalancing_frequency').enable();
            this.rebalancingForm.get('rebalancing_frequency').setValue('', [Validators.required]);
            this.setCustomFunds();
            this.totalOfFunds = this.formatPercent('', 'blur', '0');   

        }
        this.rebalancingForm.get('rebalance_start_date').updateValueAndValidity();
        this.rebalancingForm.get('final_rebalance_date').updateValueAndValidity();
        this.enableSubmit();
    }

    setCustomFunds = () => {
        if(this.funds.rb_scheduled_funds && this.funds.rb_scheduled_funds.length > 0) {
            this.funds.rb_scheduled_funds.map((item: ScheduledFund) => {
                item.allocation_percent = '0.0';
            });
        }
    }

    getFilteredFunds(funds) {
        let filteredFunds = [];
        let ignoreObjects = ['_links', 'changed', 'isChanged', 'current_allocation_percent'];
        filteredFunds = funds.filter((item) => {
            ignoreObjects.forEach(e => e in item && delete item[e]);
            return (item.allocation_percent !== '' && item.allocation_percent !== '0.0') && item
        });
        return filteredFunds;
    }

    getFilteredUnScheduledFunds() {
        let filteredFunds = [];
        let ignoreObjects = ['_links', 'changed', 'isChanged', 'current_allocation_percent'];
        filteredFunds = this.unScheduledFunds.filter((item) => {
            ignoreObjects.forEach(e => e in item && delete item[e]);
            return (item.allocation_percent !== '' && item.allocation_percent !== '0.0') && item
        });
        return filteredFunds;
    }


    onSubmit(e) {
        this.displayStockMaketDialog = (e !== false);
        this.scrollTop.emit('scroll');
        this.checkIsBusinessClosed();
    }

    checkIsBusinessClosed() {
        let curr = moment(new Date()).tz('America/New_York');
        if (curr.hours() >= Number(this.funds.business_closehour) && this.displayStockMaketDialog) {
            this.displayStockMaketDialog = false;
            let content = businessClosedContent;
            let title = 'Alert';
            let dialogRef = OcInfraModule.AppInjector.get(CommonService).showConfirmationPopup(content, 'No', 'Yes', title, '340px');
            dialogRef.afterClosed().subscribe(res => {
                if (res) {
                    this.isNextBusinessDay = true;
                    this.processRebalancing()
                }
            });
        } else {
            this.processRebalancing()
        }
    }

    processRebalancing = () => {
        if (!this.rebalancingForm.valid)
            return;

        let payload: Partial<RelabancingPayload> = this.preparePayload();
        payload = { ...payload, ... this.preparePayloadAWD() };

        let postLink = this.funds && this.funds._options.links.find(link => link.method.toUpperCase() === 'POST');
        let patchLink = this.funds && this.funds._options.links.find(link => link.method.toUpperCase() === 'PATCH' && link.rel.toUpperCase() == "UPDATE");
        let terminateLink = this.funds && this.funds._options.links.find(link => link.method.toUpperCase() === 'PATCH' && link.rel.toUpperCase() == "TERMINATE");

        if (postLink) {
            this.methodIndicator = 'post';
            payload.trans_type = 'ADD';
            payload.rebalance_start_date = this.rebalancingForm.controls.rebalance_start_date.value ? moment(this.rebalancingForm.controls.rebalance_start_date.value).format('MM/DD/yyyy') : null;
            OcInfraModule.AppInjector.get(CommonService).postCallback(postLink.href, payload, this.headers).subscribe((response: any) => {
                (!response.confirm_override) && Object.assign(response, { confirm_override: '' })
                this.processRebalancingResponse(response, payload, 'POST', false);
            }, (error: any) => {
                if(error.confirm_override == "Y"){
                    this.processRebalancingResponse(error, payload, 'POST', false);
                } else {
                    this.toasterServiceForAlerts(error);
                }
            });
        }
        else if (this.funds && this.funds.rebalancing_status === 'A' && this.rebalancingForm.controls.trans_type.value === 'T') {
            let trans_type_value = this.rebalancingForm.get('trans_type').value;

            this.methodIndicator = 'terminate';
            payload.trans_type = 'TERMINATE';
            payload.action_code = trans_type_value != '' ? trans_type_value : '';
            
            payload.rebalance_start_date = this.funds.rebalance_start_date ? this.funds.rebalance_start_date : '';
            payload.final_rebalance_date = moment('12/31/9999').format('MM/DD/yyyy');
            payload.next_rebalance_date = this.funds.next_rebalance_date ? this.funds.next_rebalance_date : null;
            OcInfraModule.AppInjector.get(CommonService).patchCallback(terminateLink.href, payload, this.headers).subscribe((response: any) => {
                (!response.confirm_override) && Object.assign(response, { confirm_override: '' })
                this.processRebalancingResponse(response, payload, 'PATCH', true);
            }, (error: any) => {
                if(error.confirm_override == "Y"){
                    this.processRebalancingResponse(error, payload, 'PATCH', false);
                } else {
                    this.toasterServiceForAlerts(error);
                }
            });
        } else {
            if (!patchLink) return;

            let trans_type_value = this.rebalancingForm.get('trans_type').value;

            this.methodIndicator = 'update';
            payload.trans_type = 'MODIFY';
            payload.action_code = trans_type_value != '' ? trans_type_value : '';

            payload.rebalance_start_date = this.funds.rebalance_start_date ? this.funds.rebalance_start_date : '';
            payload.final_rebalance_date = this.rebalancingForm.controls.final_rebalance_date.value ? moment(this.rebalancingForm.controls.final_rebalance_date.value).format('MM/DD/yyyy') : null;
            payload.next_rebalance_date = this.funds.next_rebalance_date ? this.funds.next_rebalance_date : null;
            OcInfraModule.AppInjector.get(CommonService).patchCallback(patchLink.href, payload, this.headers).subscribe((response: any) => {
                (!response.confirm_override) && Object.assign(response, { confirm_override: '' })
                this.processRebalancingResponse(response, payload, 'PATCH', false);
            }, (error: any) => {
                if(error.confirm_override == "Y"){
                    this.processRebalancingResponse(error, payload, 'PATCH', false);
                } else {
                    this.toasterServiceForAlerts(error);
                }
            });
        }


    }

    preparePayload = (): Partial<RelabancingPayload> => {
        const payload: Partial<RelabancingPayload> = {};
        payload.effective_date = this.rebalancingForm.controls['effective_date'].value ? moment(this.rebalancingForm.controls.effective_date.value).format('MM/DD/yyyy') : null;

        payload.final_rebalance_date = this.rebalancingForm.controls.final_rebalance_date.value ? moment(this.rebalancingForm.controls.final_rebalance_date.value).format('MM/DD/yyyy') : null;

        payload.funding_option = this.funds.funding_option ? this.funds.funding_option : '';
        payload.business_closehour = this.funds.business_closehour ? this.funds.business_closehour : 0;
        payload.rebalancing_frequency = this.rebalancingForm.controls.rebalancing_frequency.value;

        const selectedFrequency = this.rebalancingFrequency.find(
            (ele) => ele.value == this.rebalancingForm.controls.rebalancing_frequency.value
          );
        payload.rebalancing_frequency_desc = selectedFrequency ? selectedFrequency.label : '';
        payload.rebalancing_status = (this.funds.rebalancing_status && this.funds.rebalancing_status == 'A') ? payload.rebalancing_status = "Active" : '';
        payload.rebalancing_strategy = '';
        payload.confirm_override = this.confirmationOverride === true ? 'Y' : '';
        payload.previous_rebalance_date = this.funds.previous_rebalance_date ? this.funds.previous_rebalance_date : '01/01/0001';
        payload.previous_change_date = this.funds.previous_change_date ? this.funds.previous_change_date : '01/01/0001';

        if (this.isMRPS) {
            payload.model_code  = this.rebalancingForm.get('allocationModel').value;
        } else {
            payload.model_code = ''
        }
        const rebalancing_type = this.rebalancingForm.get('rebalancing_type').value;
        if(rebalancing_type == 'SR') { 
            payload.rb_scheduled_funds = this.getFilteredFunds(this.scheduledFunds);
        } else if(rebalancing_type == 'UR') {
            payload.rb_scheduled_funds = [];
            payload.rb_unscheduled_funds = this.getFilteredFunds(this.unScheduledFunds);
        }
        return payload;
    }

    preparePayloadAWD = () => {
        let header = _.filter(Configuration.config.headers["multiHostUrlHeader"], function (o) { return o.alias == 'workRequestHistory' });
        Object.assign(this.headers, { product_type: this.policyDetails.data.product_type});
        const authResponse = this.resourceService.getResourceFromAliasName('authRes');
        const selectedRoleSummary = this.resourceService.getResourceFromAliasName('selectedRoles');
        let roleList = this.resourceService.getResourceFromAliasName('roles');
        let otherRoleAuthorize = _.isEmpty(selectedRoleSummary.data) ? _.filter(roleList._links.item, function (o) { return o.summary.role == 'Owner'; }) : false;
        const payload: Partial<RelabancingPayload> = {};
        payload.sessionId = authResponse && authResponse.data.contactSessionID ? authResponse.data.contactSessionID : '';
        payload.callStart = authResponse && authResponse.data.callStart ? authResponse.data.callStart : '';
        payload.clientId = otherRoleAuthorize ? otherRoleAuthorize[0] && otherRoleAuthorize[0].summary.clientId ? otherRoleAuthorize[0].summary.clientId : '' : selectedRoleSummary && selectedRoleSummary.data.clientId ? selectedRoleSummary.data.clientId : '';
        payload.userId = this.commonService.incontactId;
        payload.policyNumber = this.policyDetails.data.policy_number;
        payload.company_code = this.policyDetails.data.company_code;
        payload.region_code = header[0].headers.region_code;
        payload.remote_user = header[0].headers.remote_user;
        payload.origin_app = "csr_portal";
        payload.incontactId = authResponse && authResponse.data.contactID ? authResponse.data.contactID : '';
      
        return payload;
    }

    processRebalancingResponse = (response: any, payload: Partial<RelabancingPayload>, httpVerb: string, isTerminate: boolean): void => {
        const rebalancing_frequency_value = this.getTitleByEnumFromProperties(this.funds._options.properties, 'rebalancing_frequency', this.rebalancingForm.controls.rebalancing_frequency.value);

        const trans_type_value = this.getTitleByEnumFromProperties(this.funds._options.properties, 'action_code', this.rebalancingForm.controls.trans_type.value)

        const rebalancingCase = {
            'Y': () => this.rebalanceYes(response, payload),
            'N': () => this.rebalanceNo(response),
            '': () => this.rebalanceSuccess(isTerminate, httpVerb, rebalancing_frequency_value, trans_type_value)
        };
        const res = rebalancingCase[response.confirm_override]();
    }

    rebalanceYes = (response, payload) => {
        this.displayAlerts(response);
        this.scrollTop.emit('scroll');
        let content = 'Are you sure?  You want to override ?';
        let title = 'Alert';
        let dialogRef = OcInfraModule.AppInjector.get(CommonService).showConfirmationPopup(content, 'No', 'Yes', title, '340px');
        dialogRef.afterClosed().subscribe(res => {
            if (res) {
                payload.confirm_override = "Y"
                payload.next_business_day = response.next_business_day;
                payload.effective_date = response.effective_date;
                this.confirmationOverride = true;
                this.onSubmit(false);
                this.alerts = [];
                this.alertColor = 'orange';
            }
        });
    }

    rebalanceNo = (response) => {
        this.displayAlerts(response);
        this.alertColor = 'red';
        this.disableCreate = true;
    }

    rebalanceSuccess = (isTerminate: boolean, httpVerb: string, rebalancing_frequency_value: string, trans_type_value: string) => {
        this.disableCreate = true;
        this.isSubmitSuccessful = true;
        this.alerts = [];
        let message = isTerminate ? terminateAlertContent : (httpVerb === 'POST' ? createAlertContent : modifyAlertContent);
        message = createAlertContent;
        this.toasterService.clear();
        OcInfraModule.AppInjector.get(AdvGrowlService).createSuccessMessage(message, 'Success');
        let funds = this.rebalancing_type == 'SR' ? this.scheduledFunds : this.unScheduledFunds
        this.onSubmitChange.emit({
            rebalancing_frequency: rebalancing_frequency_value,
            effective_date: this.rebalancingForm.controls.effective_date.value,
            trans_type: trans_type_value,
            rebalance_start_date: this.rebalancingForm.controls.rebalance_start_date.value,
            next_rebalance_date: this.rebalancingForm.controls.next_rebalance_date.value,
            final_rebalance_date: this.rebalancingForm.controls.final_rebalance_date.value,
            funds: this.getFilteredFunds(funds),
            totalOfFunds: this.getTotalofAllocationPercent(this.getFilteredFunds(funds))
        });
    }

    getTitleByEnumFromProperties = (propertyData: any, propertyName: string, filterValue: string): string => {
        let propertyDetails = this.getNested(propertyData, propertyName);
        let property_value = '';
        if (propertyDetails && propertyDetails.oneOf.length > 0) {
            property_value = propertyDetails.oneOf.find((item) => {
                if (item.enum[0].trim() === filterValue) {
                    return item
                } else {
                    return ''
                }
            });
        }
        return property_value && property_value['title'];
    }



    displayAlerts(response) {
        if (response._embedded && response._embedded.status_report && response._embedded.status_report.messages && response._embedded.status_report.messages.length > 0) {
            this.alerts = response._embedded.status_report.messages;
        }
    }

    toggleAlertMsg(event) {
        event.target.classList.toggle("alertMsg-overflow");
    }

    isStepComplete() {
        return this.isSubmitSuccessful;
    }


    enableSubmit() {
        if (!this.isSubmitSuccessful) {
            this.disableCreate = false;
        }
    }

    // handles input change
    inputChange(input, i, fund, scheduled) {
        let input_val = input.target.value.replace(/,|\s/g, "").replace("%", "");
        if (fund) {
            fund.isChanged = input_val && input_val !== '';
        }
        if(scheduled) {
            this.scheduledFunds[i].allocation_percent = input_val;
            this.totalOfFunds = this.scheduledFunds ? this.getTotalofAllocationPercent(this.scheduledFunds) : '0.0%';
        } else {
            this.unScheduledFunds[i].allocation_percent = input_val;
            this.totalOfFunds = this.unScheduledFunds ? this.getTotalofAllocationPercent(this.unScheduledFunds) : '0.0%';
        }
        
        this.enableSubmit();

        this.disableCreate = (this.totalOfFunds == '0.0%') ? true : false;
    }

    formatPercent(input, blur, respValue) {
        var input_val = "";
        if (respValue == '' || respValue == undefined) {
            input_val = input.target.value;
        } else {
            input_val = respValue;
        } if (input_val === "") { return; }
        var original_len = input_val.length;
        if (input_val.indexOf(".") >= 0) {
            var decimal_pos = input_val.indexOf(".");
            var left_side = input_val.substring(0, decimal_pos);
            var right_side = input_val.substring(decimal_pos);
            left_side = this.formatNumber(left_side, 'percent', true);
            right_side = this.formatNumber(right_side, 'percent', false);
            if (blur === "blur") {
                right_side += "0";
            }
            // Limit decimal to only 2 digits
            right_side = right_side.substring(0, 1);
            // join number by .
            input_val = left_side + "." + right_side + "%";
        } else {
            // no decimal entered
            // add commas to number
            // remove all non-digits
            input_val = this.formatNumber(input_val, 'percent', true);
            if (blur === "blur") {
                input_val += ".0";
            }
            input_val = input_val + "%";
        }
        if (input_val.charAt(input_val.indexOf('$') + 1) == '.') {
            input_val = input_val.replace(input_val.charAt(input_val.indexOf('$') + 1), "0.")
        }
        if (respValue == '' || respValue == undefined) {
            input.target["value"] = input_val;
        } else {
            return input_val;
        }
    }

    formatNumber(n, type, flag) {
        if (flag) {
            n = n.charAt(0) == '0' ? n.replace(n.charAt(0), "") : n;
        }
        if (type == 'currency') {
            return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } else {
            return n.replace(/\D/g, "");
        }
    }



    // Sorts the fund table based on the column
    sortTable(column) {
        let isAsc;
        let _self = this;
        _.forEach(_self.tableSortStates, function (obj, index) {
            if (obj['column'] == column) {
                isAsc = !obj['isAsc'];
                _self.tableSortStates[index]['isAsc'] = isAsc;
            }
        })
        this.scheduledFunds = isAsc ? _.orderBy(this.scheduledFunds, this.byKey(column), 'asc') : _.orderBy(this.scheduledFunds, this.byKey(column), 'desc');
    }

    // Sorts the unscheduled fund table based on the column
    sortTableU(column) {
        let isAsc;
        let _self = this;
        _.forEach(_self.tableSortStates, function (obj, index) {
            if (obj['column'] == column) {
                isAsc = !obj['isAsc'];
                _self.tableSortStates[index]['isAsc'] = isAsc;
            }
        })
        this.unScheduledFunds = isAsc ? _.orderBy(this.unScheduledFunds, this.byKey(column), 'asc') : _.orderBy(this.unScheduledFunds, this.byKey(column), 'desc');
    }



    // checks if it is a Number String
    byKey(key) {
        return function (o) {
            if (typeof o[key] === 'string') {
                return isNaN(+o[key].replace(/[^a-zA-Z0-9]/g, '')) ? o[key] : +o[key];
            }
            return o[key];
        };
    }

    getNested(obj, ...args) {
        return args.reduce((obj, level) => obj && obj[level], obj)
    }

    toasterServiceForAlerts(error) {
    if ( error._embedded && error._embedded.status_report && error._embedded.status_report.messages && error._embedded.status_report.messages.length > 0) {
        let fillteredData =error._embedded.status_report.messages.filter(x=>x.message !== "No mapping rule matched");
        fillteredData.forEach(element => {
          let alertMsg = {
            title: element.severity,
            type: "error",
            body: element.message,
            showCloseButton: true,
            timeout: 0,
            tapToDismiss: false,
          }
          this.toasterService.pop(alertMsg);
        });
        this.disableCreate=true;
        this.cdRef.detectChanges();
      }
    }

    ngOnDestroy() {
        setTimeout(() => {
            this.rebalancingForm.reset();
        }, 0);
            this.toasterService.clear();
    }
}