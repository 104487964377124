import { Component, OnInit, Input, Output, ViewChild, AfterViewInit, EventEmitter } from '@angular/core';
import { surrenderConfirmationValues, Payload, partialCallNoteTransaction, fullSurrenderCallNoteTransaction, systematicWithdrawalNoteTransaction, systematicWithdrawalRemoveTransaction } from './constants';
import { CommonService } from '../../services/common.service';
import { OcInfraModule, Configuration, ResourceService, APICallerService } from '@diaas/ux-web';
import { ToasterService } from 'angular2-toaster';
import { AdvGrowlService } from 'primeng-advanced-growl';
import { HttpParams } from '@angular/common/http';
import * as _ from 'lodash';
import { DatePipe } from '@angular/common';
import { findIndex } from 'lodash';
import { WithdrawalSurrenderAction } from './withdrawalSurrenderAction';
import * as moment from 'moment';
import "moment-timezone";
import { WithdrawlSurrenderQuote } from './quoteComponent';
import { PartySearchFactory } from '../../factories/partySearchFactory';
import { BankAccount, BankAccountSWP, PayloadFullSurrenderMRPS, PayloadFullSurrenderTerm, PayloadFullSurrenderUniversal, PayloadSystematicWithdrawal } from './confirmation.constant';
import { SurrenderDetails } from './full-surrender/full-surrender.constant';



@Component({
  selector: 'withdrawl-surrender-confirmation',
  templateUrl: './confirmationComponent.html',
  styleUrls: ['./confirmationComponent.scss'],
  providers:[PartySearchFactory]
})

export class WithdrawlSurrenderConfirmation implements OnInit, AfterViewInit  {

  @ViewChild(WithdrawlSurrenderQuote) childQuote: WithdrawlSurrenderQuote;

  @Input() withdrawalSurrenderAction: WithdrawalSurrenderAction;
  @Input() withdrawlSurrenderType: any;
  @Input() policyDetails: Object;
  @Input() headers: any;
  @Input() quoteDetails: any;
  @Input() scriptMessage: string;
  @Input() isLife: boolean;
  @Input() currentPolicyResourceURL: String;
  @Output() netCheckAmount = new EventEmitter<string>();
  quotesAPISource: Object = { "PARTIAL_SURRENDER": "/surrender/partial", "FULL_SURRENDER": "/surrender/full", "DIVIDEND_WITHDRAWAL": "/values/diviendWithdrawal" };


  private _swpDataModal: any;
  public confirmationProperty: {};
  workRequestHref: any;
  comments: any;
  enableAWDFlow: boolean = false;
  scriptIsChecked: boolean = false;
  isNextBusinessDay: boolean = false;
  WithdrawalPerDisable:boolean = false;
  withholdingPerDisable: boolean = false;
  surrenderWorkRequestPayload: { status: string; CLTY: string; POLN: any; comments: any; hostKey: any; type: string; sessionId: any; callStart: any; clientId: any; userId: any; origin_app: string; };
  effectivedate: Date;
  distributionCodeSplitted: string;
  transaction_amount: number;
  selectedFunds: any = [];
  distribution_code: string;
  isCv15Contract: boolean;
  updatedCheckAmount: any;

 
 
  @Input() set withdrawlSurrenderModel(value: any) {
    if (value) {
      this._swpDataModal = value
      this.updateTransactionData(value);
    }
  }
  public requestedDate = new Date();
  public confirmationDate: Date;
  public newRequestedDate = this.requestedDate.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' });
  public sysIndicator: string;
  get swpDataModal() {
    return this._swpDataModal;
  }

  @Input() surrenderDetails: SurrenderDetails | any;
  payload: any;
  disableProcessBtn: boolean = true;
  scriptCheckBox: boolean = true;
  confirmMessage:string = '';
  paymentDetails: any;
  alertColor: string;
  partialSurrenderConfirmationValues: any = [];
  type = { DIVIDEND_WITHDRAWAL: 'dividendwithdrawal', FULL_SURRENDER: 'fullSurrender', SYSTEMATIC_WITHDRAWL: 'systematicWithdrawal' }
  ConfirmationObj = { DIVIDEND_WITHDRAWAL: partialCallNoteTransaction, FULL_SURRENDER: fullSurrenderCallNoteTransaction, SYSTEMATIC_WITHDRAWL: systematicWithdrawalNoteTransaction }
  deleteSystematic = { SYSTEMATIC_WITHDRAWL: systematicWithdrawalRemoveTransaction }
  public systematicWithdrawlConfirmations: any;
  authsucess = OcInfraModule.AppInjector.get(CommonService).authsucess;
  otherRoleSucess = OcInfraModule.AppInjector.get(CommonService).otherRoleAuthsucess;
  resourceService = OcInfraModule.AppInjector.get(ResourceService);
  showSWPTerminateButton: boolean = false;
  ownerName = this.resourceService.getResourceFromAliasName('selectedRoles').data.fullName;
  hoverMessage: string = '';
  confirmOverride:boolean = false;  
  isGroupMrpsContract:boolean = false;
  surrenderQuote: any;
  
  isGroupAnnuity: boolean = false;
  isMrpsContract: boolean = false;


  constructor(private toasterService: ToasterService,
  private datePipe: DatePipe, private commonService: CommonService, private partySearchFactory: PartySearchFactory) { 
  }
  confirmationObj:any;

  ConfirmationNumber: string;
  isTermProduct: boolean = false;
  isUniversalProduct: boolean = false;

  ngOnDestroy() {
    this.toasterService.clear();
  }

  ngAfterViewInit(){
   }

   getTotalofAmount(fundsList, propertyName) {
    let total = 0.0;
    for (let i = 0; i < fundsList.length; i++) {
      if (fundsList[i][propertyName]) {
        total += Number(fundsList[i][propertyName].replace(/[^0-9.-]+/g, ''));
      }
    }
    return total;
  }

  getTotalofPercent(fundsList, propertyName) {
    let total = 0.0;
    for (let i = 0; i < fundsList.length; i++) {
      if (fundsList[i][propertyName]) {
        total += Number(fundsList[i][propertyName].replace(/[^0-9.-]+/g, ''));
      }
    }
    return total;
  }
  
  ngOnInit() {

    if(this.isLife) {
        this.isTermProduct = this.partySearchFactory.isTermProduct();
        this.isUniversalProduct = this.partySearchFactory.isUL(); 
    }
    this.isGroupMrpsContract = this.partySearchFactory.isOnlyGroupAnn();
    this.isCv15Contract = this.policyDetails['response'].isCv15Contract ? true : false;
    this.isMrpsContract = this.policyDetails["response"]["isMrpsContract"] ? true : false;

    this.isMrpsContract = this.policyDetails['response'].isMrpsContract ? true : false;
    this.isGroupAnnuity = this.partySearchFactory.isOnlyGroupAnn();

    this.ConfirmationNumber = OcInfraModule.AppInjector.get(CommonService).authResponse.updateResponse.data.confirmationNumber;
    this.commonService.widthDrawSurrender.subscribe(async (isConfirmationStatus) => {
      this.isLife = this.partySearchFactory.isTermProduct() || this.partySearchFactory.isUL();

      this.confirmationObj = isConfirmationStatus;
      if (isConfirmationStatus && (this.withdrawlSurrenderType !== 'FULL_SURRENDER' && this.withdrawlSurrenderType !== 'PARTIAL_SURRENDER' && this.withdrawlSurrenderType !== 'SYSTEMATIC_WITHDRAWL')) {

        this.systematicWithdrawlConfirmations = [
          {
            id: 'effectivedate',
            label: 'Effective Date:',
            value: this.datePipe.transform(this.confirmationObj.childQuote.effectivedate, 'MM/dd/yyyy'),
          },
          {
            id: 'confirmationNumber',
            label: 'Confirmation Number:',
            value: this.ConfirmationNumber,
          },
          {
            id: 'withdrawalTransactionType',
            label: 'Withdrawal Type:',
            value: this.confirmationObj.childSurrender.withdrawalTransactionType == 'P' ? 'P - ADDITIONAL INSURANCE PURCHASED BY DIVIDENDS (AI)' :
              this.confirmationObj.childSurrender.withdrawalTransactionType == 'M' ? 'M - MULTIPLE SOURCES' :
                this.confirmationObj.childSurrender.withdrawalTransactionType == 'A' ? 'A- DIVIDENDS WITH INTEREST (DWI) BALANCE' : " ",
          },
          {
            id: 'disbursementIndM',
            label: 'Disbursement:',
            value: this.confirmationObj.childPayment.disbursementIndM == "C" ? "Cash" :
              this.confirmationObj.childPayment.disbursementIndM == "E" ? "EFT" : "",
          },
          {
            id: 'withdrawlNetGrossIndicator',
            label: 'Net Gross indicator:',
            value: this.confirmationObj.childSurrender.withdrawlNetGrossIndicator == "G" ? "Gross" :
              this.confirmationObj.childSurrender.withdrawlNetGrossIndicator == "N" ? "Net" : " ",
          },
          {
            id: 'surrenderOrRemainingAmount',
            label: 'Amount Requested:',
            value: this.confirmationObj.childSurrender.withdrawalAmt,
          },
          {
            id: 'federalOverrideAmount',
            label: 'Federal Withholding Amount:',
            value: this.confirmationObj.childSurrender.federalOverrideAmount,
          },
          {
            id: 'federalWithHoldingPer',
            label: 'Federal Withholding Percent:',
            value: this.confirmationObj.childSurrender.federalWithHoldingPer,
          },
          {
            id: 'stateOverrideAmount',
            label: 'State Withholding Amount:',
            value: this.confirmationObj.childSurrender.stateOverrideAmount,
          },
          {
            id: 'stateWithHoldingPer',
            label: 'State Withholding Percent:',
            value: this.confirmationObj.childSurrender.stateWithHoldingPer,
          },
        ]
      }
        if (isConfirmationStatus && this.withdrawlSurrenderType == 'FULL_SURRENDER') {
            this.enableProcessBtn();
            let surrValues = this.confirmationObj.childSurrender.getSurrenderValues();
            if (!this.isLife && this.surrenderDetails != undefined) {
                this.systematicWithdrawlConfirmations = [
                    {
                        id: 'effectivedate',
                        label: 'Transaction Effective Date:',
                        value: this.datePipe.transform(this.surrenderDetails.effective_date, 'MM/dd/yyyy'),
                    },
                    {
                        id: 'confirmationNumber',
                        label: 'Confirmation Number:',
                        value: this.ConfirmationNumber,
                    },
                    {
                        id: 'disbursementIndM',
                        label: 'Disbursement:',
                        value: this.confirmationObj.childPayment.disbursementIndM == "C" ? "Cash" :
                            this.confirmationObj.childPayment.disbursementIndM == "E" ? "EFT" : "",
                    },
                    {
                        id: 'transactionAmount',
                        label: 'Transaction Amount/Percent:',
                        value: this.surrenderDetails.surrender_amount ? "$" + this.surrenderDetails.surrender_amount : "$0.00",
                    },
                    {
                        id: 'federalOverrideAmount',
                        label: 'Federal Withholding Amount:',
                        value: surrValues.federal_withholding_override ? surrValues.federal_withholding_amount : "$0.00",
                    },
                    {
                        id: 'federalWithHoldingPer',
                        label: 'Federal Withholding Percent:',
                        value: surrValues.federal_withholding_override ? (surrValues.federal_withholding_percentage) : "0.00%",
                    },
                    {
                        id: 'stateOverrideAmount',
                        label: 'State Withholding Amount:',
                        value: surrValues.state_withholding_override ? surrValues.state_withholding_amount : "$0.00",
                    },
                    {
                        id: 'stateWithHoldingPer',
                        label: 'State Withholding Percent:',
                        value: surrValues.state_withholding_override ? (surrValues.state_withholding_percentage) : "0.00%",
                    },
                    {
                        id: 'surrenderPenality',
                        label: 'Surrender Penality:',
                        value: this.surrenderDetails.surrender_penalty ? "$" + this.surrenderDetails.surrender_penalty : "$0.00",
                    },
                    {
                        id: 'redemptionFee',
                        label: 'Redemption Fee:',
                        value: this.surrenderDetails.redemption_fee ? "$" + this.surrenderDetails.redemption_fee : "$0.00",
                    },
                    {
                        id: 'mvaAmount',
                        label: 'MVA Amount:',
                        value: this.surrenderDetails.mva_amt ? "$" + this.surrenderDetails.mva_amt : "$0.00",
                    },
                    {
                        id: 'premiumTax',
                        label: 'Premium Tax:',
                        value: this.surrenderDetails.premiumTax ? "$" + this.surrenderDetails.premiumTax : "$0.00",
                    },
                ]
            }
            if (this.isLife) {
                await OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false });
                if (this.surrenderDetails != undefined) {
                    this.getFullSurrenderDetailsUpdated(surrValues);
                }
            }
            this.hoverMessage = 'Please fill required fields';
        }


      if (isConfirmationStatus && this.withdrawlSurrenderType == 'PARTIAL_SURRENDER') {
        if(this.isGroupMrpsContract){
          let asset_account = this.confirmationObj.childSurrender.surrenderForm.get('accountType').value?this.confirmationObj.childSurrender.surrenderForm.get('accountType').value:"**";
          if (asset_account == "**") {
            asset_account = '**';
          } else {
            asset_account = (asset_account == '**')?'**':asset_account.substring(0, asset_account.indexOf(' '));
          }
        let plan_code = this.confirmationObj.childSurrender.surrenderForm.get('productType').value;
        this.headers['asset_account'] = asset_account;
        this.headers['plan_id'] = plan_code;
        }
        let params = this.getQueryParams(this.confirmationObj);
        let policyDetails = this.resourceService.getResourceFromAliasName('policyDetails');
        let hrefUrl = policyDetails._links.self.href;
        let quoteEndpoint = hrefUrl + this.quotesAPISource[this.withdrawlSurrenderType] + "?" + params;
        this.getSurrenderDetailsUpdated(quoteEndpoint);

        this.enableProcessBtn();
        let surrValues = this.confirmationObj.childSurrender.getSurrenderValues();
        if(this.isGroupMrpsContract){
        this.systematicWithdrawlConfirmations = this.fieldsForPartialSurrenderGroupMrps(surrValues);
        } else {
        this.systematicWithdrawlConfirmations = [
          {
            id: 'effectivedate',
            label: 'Transaction Effective Date:',
            value: this.datePipe.transform(this.confirmationObj.childSurrender.surrenderDetails.effective_date, 'MM/dd/yyyy'),
          },
          {
            id: 'confirmationNumber',
            label: 'Confirmation Number:',
            value: this.ConfirmationNumber,
          },
          {
            id: 'transactionType',
            label: 'Transaction Type:',
            value: this.confirmationObj.childSurrender.surrenderForm.get('transaction_type').value == "A"?"Amount":
                this.confirmationObj.childSurrender.surrenderForm.get('transaction_type').value == "P"? "Percent" : "",
          },
          {
            id: 'transactionLevel',
            label: 'Transaction Level:',
            value: this.confirmationObj.childSurrender.surrenderForm.get('transaction_level').value == "W"?"Plan Withdrawal Rule":
            this.confirmationObj.childSurrender.surrenderForm.get('transaction_level').value == "F"?"Removed from specified funds":
            this.confirmationObj.childSurrender.surrenderForm.get('transaction_level').value == "P"?"Removed Pro Rata basis":
            this.confirmationObj.childSurrender.surrenderForm.get('transaction_level').value == "B"?"Surrender money/index fund level":"",
          },
          {
            id: 'disbursementIndM',
            label: 'Disbursement:',
            value: this.confirmationObj.childPayment.disbursementIndM == "C" ? "Cash" :
              this.confirmationObj.childPayment.disbursementIndM == "E" ? "EFT" : "",
          },
          {
            id: 'transactionAmount',
            label: 'Transaction Amount:',
            value: (this.confirmationObj.childSurrender.surrenderForm.get('transaction_level').value == 'P' && this.confirmationObj.childSurrender.surrenderForm.get('transaction_type').value == "A")?this.confirmationObj.childSurrender.getSurrenderValues().surrender_amount:
            (this.confirmationObj.childSurrender.surrenderForm.get('transaction_level').value == 'F' && this.confirmationObj.childSurrender.surrenderForm.get('transaction_type').value == "P") ? this.confirmationObj.childSurrender.getSurrenderValues().calculated_surrender_amount:
            (this.confirmationObj.childSurrender.surrenderForm.get('transaction_level').value == 'P' && this.confirmationObj.childSurrender.surrenderForm.get('transaction_type').value == "P") ? this.confirmationObj.childSurrender.getSurrenderValues().surrender_amount:
            ((this.confirmationObj.childSurrender.surrenderForm.get('transaction_level').value == 'F' || this.confirmationObj.childSurrender.surrenderForm.get('transaction_level').value == 'B') && this.confirmationObj.childSurrender.surrenderForm.get('transaction_type').value == "A")?this.confirmationObj.childSurrender.getSurrenderValues().transaction_amount:"$0.00",
          },
          {
          id: 'transactionPercent',
          label: 'Transaction Percent:',
          value: (this.confirmationObj.childSurrender.surrenderForm.get('transaction_level').value == 'P' && this.confirmationObj.childSurrender.surrenderForm.get('transaction_type').value == "P")?this.confirmationObj.childSurrender.getSurrenderValues().surrender_percent:
          ((this.confirmationObj.childSurrender.surrenderForm.get('transaction_level').value == 'F' || this.confirmationObj.childSurrender.surrenderForm.get('transaction_level').value == 'B') && (this.confirmationObj.childSurrender.surrenderForm.get('transaction_type').value == "P"))?this.confirmationObj.childSurrender.getSurrenderValues().transaction_amount:"0.00%",
          },
          {
            id: 'federalOverrideAmount',
            label: 'Federal Withholding Amount:',
            value: surrValues.federal_withholding_override?surrValues.federal_withholding_amount:"$0.00",
          },
          {
            id: 'federalWithHoldingPer',
            label: 'Federal Withholding Percent:',
            value: surrValues.federal_withholding_override?(surrValues.federal_withholding_percentage):"0.00%",
          },
          {
            id: 'stateOverrideAmount',
            label: 'State Withholding Amount:',
            value: surrValues.state_withholding_override?surrValues.state_withholding_amount:"$0.00",
          },
          {
            id: 'stateWithHoldingPer',
            label: 'State Withholding Percent:',
            value: surrValues.state_withholding_override?(surrValues.state_withholding_percentage):"0.00%",
          } 
        ]
      }
        if(this.confirmationObj.childSurrender.surrenderForm.get('transaction_level').value == "P"){
          this.systematicWithdrawlConfirmations.push(
            {
              id: 'netGrossInd',
              label: 'Net Gross Indicator:',
              value: this.confirmationObj.childSurrender.surrenderForm.get('net_gross_indicator').value == 'Gross'?"Gross":
              this.confirmationObj.childSurrender.surrenderForm.get('net_gross_indicator').value == 'Net'?"Net":
              this.confirmationObj.childSurrender.surrenderForm.get('net_gross_indicator').value == 'NetNet'?"Net Net":"",
            },
          )
        }
        if(this.confirmationObj.childPayment.disbursementIndM == 'E'){
          this.systematicWithdrawlConfirmations.push(
            {
              id: 'bankAccountType',
              label: 'Bank Account Type:',
              value: this.confirmationObj.childPayment.getPaymentValues().account_type?this.confirmationObj.childPayment.getPaymentValues().account_type:"",
            },
            {
              id: 'routingNumber',
              label: 'Routing Number:',
              value: this.confirmationObj.childPayment.getPaymentValues().transit?this.confirmationObj.childPayment.getPaymentValues().transit:"",
            },
            {
              id: 'accountNumber',
              label: 'Account Number:',
              value: this.confirmationObj.childPayment.getPaymentValues().account_number?this.confirmationObj.childPayment.getPaymentValues().account_number:"",
            }
          )
        }
        this.hoverMessage = 'Please fill required fields';
      }

      if (isConfirmationStatus && this.withdrawlSurrenderType == 'SYSTEMATIC_WITHDRAWL') {
        this.enableProcessBtn();
        let surrValues = this.confirmationObj.childSurrender.getSurrenderValues();
        if(this.surrenderDetails != undefined) {
            this.systematicWithdrawlConfirmations = this.fieldsForSystematicWithdrawal(surrValues)
        }
        this.withdrawalPerCheck(this.systematicWithdrawlConfirmations);
        this.hoverMessage = 'Please fill required fields';
      }
      if(isConfirmationStatus && this.withdrawlSurrenderType != 'DIVIDEND_WITHDRAWL' && this.withdrawlSurrenderType != 'PARTIAL_SURRENDER'){
        this.percentageLengthCheck(this.systematicWithdrawlConfirmations);
        this.enableProcessBtn();
      }
      if(isConfirmationStatus && this.withdrawlSurrenderType == 'PARTIAL_SURRENDER' ){
        this.percentageLengthCheck(this.systematicWithdrawlConfirmations);
        this.withdrawalPerCheck(this.systematicWithdrawlConfirmations); 
        this.enableProcessBtn();
      }
    });

    if(this.childQuote) {
        this.effectivedate = this.childQuote.effectivedate;
    }
 
    let headerProperties = _.filter(Configuration.config.headers["multiHostUrlHeader"], function (o) { return o.alias == 'workitemsSchema' });
    Object.assign(this.headers, { product_type: headerProperties[0].headers.product_type, region_code: headerProperties[0].headers.region_code, remote_user: headerProperties[0].headers.remote_user, statuaory_company_code: headerProperties[0].headers.statuaory_company_code })
    //delete headerProperties[0].headers.(content-length);
  }
  
  formatCurrency = function (amount) {
    amount = "$" + amount.replace(/(\d)(?=(\d{3})+(\.(\d){0,2})*$)/g, '$1,');
    if (amount.indexOf('.') === -1)
      return amount + '.00';
    var decimals = amount.split('.')[1];
    return decimals.length < 2 ? amount + '0' : amount;
  };

  fieldsForPartialSurrenderGroupMrps = (surrValues:any,) => {
    return [
      {
        id: 'effectivedate',
        label: 'Transaction Effective Date:',
        value: this.datePipe.transform(this.confirmationObj.childSurrender.surrenderDetails.effective_date, 'MM/dd/yyyy'),
      },
      {
        id: 'confirmationNumber',
        label: 'Confirmation Number:',
        value: this.ConfirmationNumber,
      },
      {
        id: 'transactionType',
        label: 'Transaction Type:',
        value: this.confirmationObj.childSurrender.surrenderForm.get('transaction_type').value == "A"?"A-Flat amount":
            this.confirmationObj.childSurrender.surrenderForm.get('transaction_type').value == "P"? "P-Percentage of cash value" :
            this.confirmationObj.childSurrender.surrenderForm.get('transaction_type').value == "F"? "F-Percent of avail free amount": "",
      },
      {
        id: 'transactionLevel',
        label: 'Transaction Level:',
        value: this.confirmationObj.childSurrender.surrenderForm.get('transaction_level').value == "F"?"Fund Specific":
        this.confirmationObj.childSurrender.surrenderForm.get('transaction_level').value == "P"?"Pro Rata":"",
      },
      {
        id: 'disbursementIndM',
        label: 'Disbursement:',
        value: this.confirmationObj.childPayment.disbursementIndM == "C" ? "Cash" :
          this.confirmationObj.childPayment.disbursementIndM == "E" ? "EFT" : "",
      },
      {
        id: 'transactionAmount',
        label: 'Transaction Amount:',
        value: (this.confirmationObj.childSurrender.surrenderForm.get('transaction_level').value == 'P' && this.confirmationObj.childSurrender.surrenderForm.get('transaction_type').value == "A")?this.confirmationObj.childSurrender.getSurrenderValues().surrender_amount:
        (this.confirmationObj.childSurrender.surrenderForm.get('transaction_level').value == 'F' && this.confirmationObj.childSurrender.surrenderForm.get('transaction_type').value == "P") ? this.confirmationObj.childSurrender.getSurrenderValues().calculated_surrender_amount:
        (this.confirmationObj.childSurrender.surrenderForm.get('transaction_level').value == 'P' && ((this.confirmationObj.childSurrender.surrenderForm.get('transaction_type').value == "P") || this.confirmationObj.childSurrender.surrenderForm.get('transaction_type').value == "F")) ? this.confirmationObj.childSurrender.getSurrenderValues().surrender_amount:
        ((this.confirmationObj.childSurrender.surrenderForm.get('transaction_level').value == 'F' || this.confirmationObj.childSurrender.surrenderForm.get('transaction_level').value == 'B') && this.confirmationObj.childSurrender.surrenderForm.get('transaction_type').value == "A")?this.confirmationObj.childSurrender.getSurrenderValues().transaction_amount:
        (this.confirmationObj.childSurrender.surrenderForm.get('transaction_level').value == 'F' && this.confirmationObj.childSurrender.surrenderForm.get('transaction_type').value == 'F')?this.confirmationObj.childSurrender.getSurrenderValues().calculated_surrender_amount:"$0.00",
      },
      {
        id: 'transactionPercent',
        label: 'Transaction Percent:',
        value: (this.confirmationObj.childSurrender.surrenderForm.get('transaction_level').value == 'P' && (this.confirmationObj.childSurrender.surrenderForm.get('transaction_type').value == "P" || this.confirmationObj.childSurrender.surrenderForm.get('transaction_type').value == "F"))?this.confirmationObj.childSurrender.getSurrenderValues().surrender_percent:
        ((this.confirmationObj.childSurrender.surrenderForm.get('transaction_level').value == 'F' || this.confirmationObj.childSurrender.surrenderForm.get('transaction_level').value == 'B' || this.confirmationObj.childSurrender.surrenderForm.get('transaction_level').value == 'F') && (this.confirmationObj.childSurrender.surrenderForm.get('transaction_type').value == "P"))?this.confirmationObj.childSurrender.getSurrenderValues().transaction_amount:
        (this.confirmationObj.childSurrender.surrenderForm.get('transaction_level').value == 'F' && this.confirmationObj.childSurrender.surrenderForm.get('transaction_type').value == 'F')?this.confirmationObj.childSurrender.getSurrenderValues().transaction_amount:"0.00%",
      },
      {
        id: 'federalOverrideAmount',
        label: 'Federal Withholding Amount:',
        value: surrValues.federal_withholding_override?surrValues.federal_withholding_amount:"$0.00",
      },
      {
        id: 'federalWithHoldingPer',
        label: 'Federal Withholding Percent:',
        value: surrValues.federal_withholding_override?(surrValues.federal_withholding_percentage):"0.00%",
      },
      {
        id: 'stateOverrideAmount',
        label: 'State Withholding Amount:',
        value: surrValues.state_withholding_override?surrValues.state_withholding_amount:"$0.00",
      },
      {
        id: 'stateWithHoldingPer',
        label: 'State Withholding Percent:',
        value: surrValues.state_withholding_override?(surrValues.state_withholding_percentage):"0.00%",
      } 
    ]
  }

  fieldsForFullSurrenderLifeProducts = (surrValues: any, ) => {
     return [
        {
          id: 'effectivedate',
          label: 'Transaction Effective Date:',
          value: this.datePipe.transform(this.surrenderDetails.effective_date, 'MM/dd/yyyy'),
        },
        {
          id: 'confirmationNumber',
          label: 'Confirmation Number:',
          value: this.ConfirmationNumber,
        },
        {
          id: 'disbursementIndM',
          label: 'Disbursement:',
          value: this.confirmationObj.childPayment.disbursementIndM == "C" ? "Cash" :
            this.confirmationObj.childPayment.disbursementIndM == "E" ? "EFT" : "",
        },
        {
          id: 'transactionAmount',
          label: 'Transaction Amount/Percent:',
          value: surrValues.net_check_amount?"$"+surrValues.net_check_amount:"$0.00",
        },
        {
          id: 'federalOverrideAmount',
          label: 'Federal Withholding Amount:',
          value: surrValues.no_federal_withholding == 'N' ? surrValues.federal_withholding_amount: "$0.00",
        },
        {
          id: 'federalWithHoldingPer',
          label: 'Federal Withholding Percent:',
          value: surrValues.no_federal_withholding == 'N' ? surrValues.federal_withholding_percentage:"0.00%",
        },
        {
          id: 'stateOverrideAmount',
          label: 'State Withholding Amount:',
          value: surrValues.no_state_withholding == 'N' ? surrValues.state_withholding_amount:"$0.00",
        },
        {
          id: 'stateWithHoldingPer',
          label: 'State Withholding Percent:',
          value: surrValues.no_state_withholding == 'N' ? surrValues.state_withholding_percentage:"0.00%",
        },
        {
          id: 'surrenderPenality',
          label: 'Penalties:',
          value: this.surrenderDetails.surrender_penalty?"$"+this.surrenderDetails.surrender_penalty:"$0.00",
        },
        /*
        'Admin Fee' field is commented out under Jira CSRPANO-2839
        {
          id: 'redemptionFee',
          label: 'Admin Fee:',
          value: this.confirmationObj.childQuote.surrenderDetails.redemption_fee? "$"+this.confirmationObj.childQuote.surrenderDetails.redemption_fee:"$0.00",
        }
        */
      ];
  }

    fieldsForSystematicWithdrawal = (surrValues: any,) => {
        const effectiveDate = this.datePipe.transform(this.surrenderDetails.effective_date, 'MM/dd/yyyy');
        let fedWithholdPer = this.confirmationObj.childSurrender.surrenderForm.controls.federal_withholding_percentage.value;
        fedWithholdPer = fedWithholdPer.includes('%') ? Number(fedWithholdPer.slice(0, -1)) : fedWithholdPer;
        if (!this.isLife && this.surrenderDetails.distribution_code === "2-IRS Exception Distribution") {
            this.distribution_code = fedWithholdPer > 20.00 ? "2-IRS Exception Distribution" : "";
        } else {
            this.distribution_code = this.surrenderDetails.distribution_code;
        }
        this.distributionCodeSplitted = _.split(this.distribution_code, "-")[0];


        const systematicWithdrawlConfirmations = [
            {
                id: 'effectiveDate',
                label: 'Transaction Effective Date:',
                value: effectiveDate,
            },
            {
                id: 'startNextDate',
                label: 'Start/Next Date:',
                value: this.datePipe.transform(surrValues.start_next_date, 'MM/dd/yyyy'),
            },
            {
                id: 'confirmationNumber',
                label: 'Confirmation Number:',
                value: this.ConfirmationNumber,
            },
            {
                id: 'option',
                label: 'Option:',
                value: surrValues.optionLabel,
            },
            {
                id: 'withdrawalAmount',
                label: 'Withdrawal Amount:',
                value: surrValues.payout_amount ? surrValues.payout_amount : "$0.00",
            },
            {
                id: 'withdrawalPercent',
                label: 'Withdrawal Percent:',
                value: surrValues.payout_percent ? surrValues.payout_percent:"0.00%",
            },
            {
                id: 'mode',
                label: 'Mode:',
                value: surrValues.modeLabel,
            },
            {
                id: 'distributionIndicator',
                label: 'Distribution Indicator:',
                value: surrValues.distributionLabel,
            },
            {
                id: 'distributionCode',
                label: 'Distribution Code:',
                value: this.distribution_code,

            },
            {
                id: 'stateWithHoldingAmount',
                label: 'State Withholding Amount:',
                value: surrValues.no_state_withholding == 'N' ? surrValues.state_withholding_amount: "$0.00",
            },
            {
                id: 'stateWithHoldingPer',
                label: 'State Withholding Percent:',
                value: surrValues.no_state_withholding == 'N' ? surrValues.state_withholding_percentage: "0.00%",
            },
            {
                id: 'federalWithHoldingAmount',
                label: 'Federal Withholding Amount:',
                value: surrValues.no_federal_withholding == 'N' ? surrValues.federal_withholding_amount : "$0.00",
            },
            {
                id: 'federalWithHoldingPer',
                label: 'Federal Withholding Percent:',
                value: surrValues.no_federal_withholding == 'N' ? surrValues.federal_withholding_percentage : "0.00%",
            }
        ];

        if (this.confirmationObj.childPayment.disbursementIndM == 'E') {
            systematicWithdrawlConfirmations.push(
                {
                    id: 'bankAccountType',
                    label: 'Bank Account Type:',
                    value: this.confirmationObj.childPayment.getPaymentValues().account_type ? this.confirmationObj.childPayment.getPaymentValues().account_type : "",
                },
                {
                    id: 'routingNumber',
                    label: 'Routing Number:',
                    value: this.confirmationObj.childPayment.getPaymentValues().transit ? this.confirmationObj.childPayment.getPaymentValues().transit : "",
                },
                {
                    id: 'accountNumber',
                    label: 'Account Number:',
                    value: this.confirmationObj.childPayment.getPaymentValues().account_number ? this.confirmationObj.childPayment.getPaymentValues().account_number : "",
                }
            )
        }
        return systematicWithdrawlConfirmations

    }

  updateTransactionData(model) {
    const confirmationNum = OcInfraModule.AppInjector.get(CommonService).authResponse.updateResponse.data.confirmationNumber;
    this.systematicWithdrawlConfirmations = [
      {
        id: 'transactionEffectiveDate',
        label: 'Effective Date:',
        value: "",
      },
      // {
      //   id: 'startNextDate',
      //   label: 'Start/Next Date:',
      //   value: this.datePipe.transform(this.getSWPConfirmationValue(model, 'next_date'), 'MM/dd/yyyy'),
      // },
      // {
      //   id: 'confirmationNumber',
      //   label: 'Confirmation Number:',
      //   value: confirmationNum,
      // },
      // {
      //   id: 'option',
      //   label: 'Option:',
      //   value: this.getSWPConfirmationValue(model, 'option'),
      // },
      // {
      //   id: 'withdrwalAmount',
      //   label: 'Withdrawal Amount:',
      //   value: this.getSWPConfirmationValue(model, 'withdrawal_amount'),
      // },
      // {
      //   id: 'withdrwalPercentage',
      //   label: 'Withdrawal Percentage:',
      //   value: this.getSWPConfirmationValue(model, 'payout_percent'),
      // },
      // {
      //   id: 'frequency',
      //   label: 'Frequency:',
      //   value: this.getSWPConfirmationValue(model, 'frequency'),
      // },
      // {
      //   id: 'distributionIndicator',
      //   label: 'Distribution Indicator:',
      //   value: this.getSWPConfirmationValue(model, 'net_gross_indicator') ? 'Gross' : '',
      // },
      // {
      //   id: 'fundOption',
      //   label: 'Fund Option:',
      //   value: this.getSWPConfirmationValue(model, 'transaction_level'),
      // },
      {
        id: 'stateWithHoldingPer',
        label: 'State Withholding Percent:',
        value: this.getSWPConfirmationValue(model, 'state_withholding_percentage'),
      },
      {
        id: 'federalWithHoldingPer',
        label: 'Federal Withholding Percent:',
        value: this.getSWPConfirmationValue(model, 'federal_withholding_percentage'),
      },
    ];
  }

  getValueOfField(model, fieldId) {
    const item = model.find(item => item.id == fieldId);
    return item ? fieldId === "fundValues" ? item.values : item.value : '';
  }

  getSWPConfirmationValue(model, fieldId) {
    const item = model.find(item => item.id == fieldId);
    switch (item.type) {
      case "select": {
        const res = item.values.find((val) => val.value === item.value);
        return res ? res.label : '';
        break;
      }
      case "radio": {
        var selectedOption = item.values.find(option => option.value == item.value);
        return selectedOption ? selectedOption.label : ""
        break;
      }
      default: {
        if (['state_withholding_percentage', 'federal_withholding_percentage', 'payout_percent'].includes(item.id)) {
          return item.value && item.value !== '' && item.value !== '0.0' ? item.value : '0.0%';
        } else if (item.id === 'withdrawal_amount') {
          return item.value && item.value !== '' && item.value !== '0.0' ? item.value : '$0.00'
        }
        return item ? fieldId === "fundValues" ? item.values : item.value : '';
        break;
      }
    }
  }
  withdrawalPerCheck (arr) {
    var withdrawalPercentage;
    let toggle : boolean =  false;
      if(this.withdrawlSurrenderType == 'SYSTEMATIC_WITHDRAWL') {
        arr.forEach((e)=>{
          if(e.id == 'withdrawalPercent'){
            withdrawalPercentage = e.value;
            let withdrawalPerStr = withdrawalPercentage.includes('%') ? withdrawalPercentage.slice(0,-1) : withdrawalPercentage;
            let withdrawalPerInt = Number(withdrawalPerStr);
            if(withdrawalPerInt>100) {
              toggle = true;
            }
          }
        })
        this.WithdrawalPerDisable = toggle;
      }
      if(this.withdrawlSurrenderType == 'PARTIAL_SURRENDER') {
        arr.forEach((e)=>{
          if(e.id == 'transactionAmount'){
            withdrawalPercentage = e.value;
            if(withdrawalPercentage.includes('%')){
              let withdrawalPerStr = withdrawalPercentage.slice(0,-1);
              let withdrawalPerInt = Number(withdrawalPerStr);
              if(withdrawalPerInt>100) {
                toggle = true;
              }
            }
            this.WithdrawalPerDisable = toggle;
          }
        })
      }
  }

  percentageLengthCheck(arr) {
    var withdrawalPercent;
    let toggle : boolean =  false;
    arr.forEach((e)=>{
      if(e.id == 'federalWithHoldingPer' || e.id == 'stateWithHoldingPer'){
        withdrawalPercent = e.value;
        let withdrawalPerStr = withdrawalPercent.includes('%') ? withdrawalPercent.slice(0,-1) : withdrawalPercent;
        let withdrawalPerInt = Math.trunc(withdrawalPerStr);
        let withdrawalString = withdrawalPerInt.toString();
        if(withdrawalString.length>2) {
          toggle = true;
        }
      }
    })
    this.withholdingPerDisable = toggle;
  } 

  getQueryParams(confirmationObj) {
    let params = new HttpParams();
    let effectiveDate = this.datePipe.transform(this.confirmationObj.childSurrender.surrenderDetails.effective_date, 'MM/dd/yyyy');
    let withdraw_amount = (this.confirmationObj.childSurrender.surrenderForm.get('transaction_level').value == 'P' && (this.confirmationObj.childSurrender.surrenderForm.get('transaction_type').value == "A" || this.confirmationObj.childSurrender.surrenderForm.get('transaction_type').value == 'F'))?this.confirmationObj.childSurrender.getSurrenderValues().surrender_amount:
    (this.confirmationObj.childSurrender.surrenderForm.get('transaction_level').value == 'P' && this.confirmationObj.childSurrender.surrenderForm.get('transaction_type').value == "P")?this.confirmationObj.childSurrender.getSurrenderValues().surrender_amount:
    ((this.confirmationObj.childSurrender.surrenderForm.get('transaction_level').value == 'F' || this.confirmationObj.childSurrender.surrenderForm.get('transaction_level').value == 'B') && this.confirmationObj.childSurrender.surrenderForm.get('transaction_type').value == "A")?this.confirmationObj.childSurrender.getSurrenderValues().transaction_amount:"0.00"
    withdraw_amount = withdraw_amount.replaceAll(',', '').replaceAll('%','');
    withdraw_amount = withdraw_amount[0]=="$" ? withdraw_amount.slice(1) : withdraw_amount;
    withdraw_amount = withdraw_amount != '0' ? withdraw_amount : '0';
    if((this.confirmationObj.childSurrender.surrenderForm.get('transaction_level').value == 'F') && (this.confirmationObj.childSurrender.surrenderForm.get('transaction_type').value == "P" || this.confirmationObj.childSurrender.surrenderForm.get('transaction_type').value == "F")) {
      let fundValues = this.confirmationObj.childSurrender.funds;
      withdraw_amount = this.confirmationObj.childSurrender.getSurrenderValues().calculated_surrender_amount.replace('$','').replace(',','');
    }
    
    let net_gross_indicator = this.confirmationObj.childSurrender.surrenderForm.get('net_gross_indicator').status == 'DISABLED'? "":
    this.confirmationObj.childSurrender.surrenderForm.get('net_gross_indicator').value == 'Gross'?"G":
    this.confirmationObj.childSurrender.surrenderForm.get('net_gross_indicator').value == 'Net'?"N":
    this.confirmationObj.childSurrender.surrenderForm.get('net_gross_indicator').value == 'NetNet'?"A":""

    let federal_withholding_override = this.confirmationObj.childSurrender.surrenderForm.get('federal_withholding_override').value;
    let state_withholding_override = this.confirmationObj.childSurrender.surrenderForm.get('state_withholding_override').value;
    if(withdraw_amount == 'NaN') {
        withdraw_amount = '0.00'
    }
    params = params.set('quoteEffectiveDate', this.datePipe.transform(effectiveDate, "MM/dd/yyyy"))
        .set('quote', 'Y')
        .set('amount', withdraw_amount)
        .set('gNetInd', net_gross_indicator)

    if (federal_withholding_override) {
        federal_withholding_override = federal_withholding_override?'Y':'N';
        params = params.append('fedInd', federal_withholding_override)
    }
    if (state_withholding_override) {
        state_withholding_override = state_withholding_override?'Y':'N';
        params = params.append('stInd', state_withholding_override)
    }

    return params.toString();
}

getSurrenderDetailsUpdated = (quoteEndpoint: string) => {
      OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false });
      OcInfraModule.AppInjector.get(CommonService).getCallback(quoteEndpoint, this.headers).subscribe(response => {        
          OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
          this.systematicWithdrawlConfirmations.push(
            {
              id: 'surrenderPenality',
              label: 'Penalties:',
              value: response.surrender_penalty?"$"+response.surrender_penalty:"$0.00",
            },
            {
              id: 'adminFee',
              label: 'Admin Fee:',
              value: response.admin_fee? "$"+response.admin_fee:"$0.00",
            },
            {
              id: 'mvaFee',
              label: 'MVA Fee:',
              value: response.mva_amt? "$"+response.mva_amt:"$0.00",
            },
            {
              id: 'redemptionFee',
              label: 'Redemption Fee:',
              value: response.redemption_fee? "$"+response.redemption_fee:"$0.00",
            },
            {
              id: 'premiumTax',
              label: 'Premium Tax:',
              value: response.premiumTax? "$"+response.premiumTax:"$0.00",
            }
          );
      }, error => {
          OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
        if (error && error._embedded && error._embedded.status_report && error._embedded.status_report.consistent === false) {
          OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error._embedded.status_report.messages[0].message, 'Error', 0);
      }
      });

}

getFullSurrQueryParams(confirmationObj) {
  let params = new HttpParams();
  let no_federal_withholding = this.confirmationObj.childSurrender.surrenderForm.get('no_federal_withholding').value;
  let no_state_withholding = this.confirmationObj.childSurrender.surrenderForm.get('no_state_withholding').value;
  let federal_withholding_override = this.confirmationObj.childSurrender.surrenderForm.get('federal_withholding_override').value;
  let withdraw_amount = this.confirmationObj.childSurrender.surrenderForm.get('federal_withholding_amount').value;
  let withdraw_percent = this.confirmationObj.childSurrender.surrenderForm.get('federal_withholding_percentage').value;
  withdraw_amount = withdraw_amount[0]=="$" ? withdraw_amount.slice(1) : withdraw_amount;
  withdraw_amount = withdraw_amount != '0' ? withdraw_amount : '0';
  let state_withholding_override = this.confirmationObj.childSurrender.surrenderForm.get('state_withholding_override').value;
  let state_withholding_amount = this.confirmationObj.childSurrender.surrenderForm.get('state_withholding_amount').value;
  let state_withholding_percent = this.confirmationObj.childSurrender.surrenderForm.get('state_withholding_percentage').value;
  state_withholding_amount = state_withholding_amount[0]=="$" ? state_withholding_amount.slice(1) : state_withholding_amount;
  state_withholding_amount = state_withholding_amount != '0' ? state_withholding_amount : '0';
  withdraw_amount = withdraw_amount.replaceAll(',', '');
  state_withholding_amount = state_withholding_amount.replaceAll(',', '');
  withdraw_percent = withdraw_percent.replaceAll('%', '');
  state_withholding_percent = state_withholding_percent.replaceAll('%', '');
  params = params.set('noFederalWithholding', no_federal_withholding?'Y':'N')
      .set('noStateWithholding',  no_state_withholding ?'Y':'N')
  let federalWithholdingAmountStatus = this.confirmationObj.childSurrender.surrenderForm.get('federal_withholding_amount').status == 'DISABLED'? false:true;
  let federalWithholdingPercentStatus = this.confirmationObj.childSurrender.surrenderForm.get('federal_withholding_percentage').status == 'DISABLED'? false:true;
  let stateWithholdingAmountStatus = this.confirmationObj.childSurrender.surrenderForm.get('state_withholding_amount').status == 'DISABLED'? false:true;
  let stateWithholdingPercentStatus = this.confirmationObj.childSurrender.surrenderForm.get('state_withholding_percentage').status == 'DISABLED'? false:true;
  if(federal_withholding_override){
    if(federalWithholdingAmountStatus){
      params = params.append('federalWithholdingAmount', withdraw_amount);
    }
      if(this.isUniversalProduct && federalWithholdingPercentStatus) {
      params = params.append('federalWithholdingPercent', withdraw_percent);  
      }
  } else {
    if(this.isUniversalProduct && this.surrenderDetails.federal_withholding_amount != 0.00){
      params = params.append('federalWithholdingAmount', this.surrenderDetails.federal_withholding_amount);
    }
    if(this.isUniversalProduct && this.surrenderDetails.federal_withholding_percentage != 0.00) {
      params = params.append('federalWithholdingPercent', this.surrenderDetails.federal_withholding_percentage);  
      }
  }
  
  if(state_withholding_override){
    if(stateWithholdingAmountStatus){
      params = params.append('stateWithholdingAmount', state_withholding_amount); 
    }
      if(this.isUniversalProduct && stateWithholdingPercentStatus) {
      params = params.append('stateWithholdingPercent', state_withholding_percent); 
      }
  } else {
    if(this.isUniversalProduct && this.surrenderDetails.state_withholding_amount != 0.00){
      params = params.append('stateWithholdingAmount', this.surrenderDetails.state_withholding_amount);
    }
    if(this.isUniversalProduct && this.surrenderDetails.state_withholding_percentage != 0.00) {
      params = params.append('stateWithholdingPercent', this.surrenderDetails.state_withholding_percentage);  
      }
  }

  return params.toString();
}

getFullSurrenderDetailsUpdated = async (surrValues: any) => {
    let params = this.getFullSurrQueryParams(this.confirmationObj);
    let policyDetails = this.resourceService.getResourceFromAliasName('policyDetails');
    let hrefUrl = policyDetails._links.self.href;
    let quoteEndpoint = hrefUrl + this.quotesAPISource[this.withdrawlSurrenderType] + "?" + params;
    await OcInfraModule.AppInjector.get(CommonService).getCallback(quoteEndpoint, this.headers).subscribe(async response => {
        surrValues.net_check_amount = response.net_check_amount;
        this.updatedCheckAmount = response.net_check_amount;
        this.netCheckAmount.emit(response.net_check_amount);
        surrValues.federal_withholding_amount = response.federal_withholding_amount;
        surrValues.federal_withholding_percentage = response.federal_withholding_percentage;
        surrValues.state_withholding_amount = response.state_withholding_amount;
        surrValues.state_withholding_percentage = response.state_withholding_percentage;
        this.confirmationObj.childSurrender.surrenderForm.controls['federal_withholding_amount'].setValue(response.federal_withholding_amount);
        this.confirmationObj.childSurrender.surrenderForm.controls['federal_withholding_percentage'].setValue(response.federal_withholding_percentage);
        this.confirmationObj.childSurrender.surrenderForm.controls['state_withholding_amount'].setValue(response.state_withholding_amount);
        this.confirmationObj.childSurrender.surrenderForm.controls['state_withholding_percentage'].setValue(response.state_withholding_percentage);
        if (this.surrenderDetails != undefined) {
            this.systematicWithdrawlConfirmations = this.fieldsForFullSurrenderLifeProducts(surrValues);
        }
        await OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });

    }, async error => {
        if (error && error._embedded && error._embedded.status_report && error._embedded.status_report.consistent === false) {
            OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error._embedded.status_report.messages[0].message, 'Error', 0);
            await OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
        }
    });
}

 

  scriptChecked(e) {
    this.scriptCheckBox = !e.checked;
    this.enableProcessBtn();
  }

  onTerminateClick() {
    let callNoteObj = {};
    this.createSWPRequestBody();
    this.sysIndicator = "deleteWithdrawl"
    var link = this.surrenderDetails._options.links.find(link => link.method.toLowerCase() === 'patch' && link.rel.toLowerCase() == "terminate");
    OcInfraModule.AppInjector.get(CommonService).patchCallback(link.href, this.payload, this.headers).subscribe(response => {
      this.processSWPResponse(response, 'PATCH', true);
    }, error => {
      OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error._embedded && error._embedded.status_report && error._embedded.status_report.messages[0].message, 'Error', 0);
    });
  }

  processSWPResponse(response, httpVerb, isTerminate) {
    switch (response.confirm_override) {
      // soft error scenario
      case 'Y': {
        this.displayAlerts(response, "error");
        let content = 'Are you sure?  You want to override ?';
        let title = 'Alert';
        let dialogRef = OcInfraModule.AppInjector.get(CommonService).showConfirmationPopup(content, 'No', 'Yes', title, '340px');
        dialogRef.afterClosed().subscribe(res => {
          if (res) {
            this.payload.confirm_override = "Y"
            this.payload.next_business_day = response.next_business_day;
            this.payload.effective_date = response.effective_date;
            this.processSystematicWithdrawl();
          }
        });
        break;
      }
      // hard error scenario
      case 'N': {
        this.displayAlerts(response, "error");
        this.disableProcessBtn = true;
        break;
      }
      // success scenario
      case '': {
        if ( response._embedded && response._embedded.status_report && response._embedded.status_report.messages && response._embedded.status_report.messages.length > 0) {
          //clear existing alerts and then display the warning
          this.toasterService.clear();
          this.displayAlerts(response, "warning");
        }      
        OcInfraModule.AppInjector.get(CommonService).confirmation = true;
        let message = isTerminate ? 'Success Terminate SWP Processed Successfully' :
          (httpVerb === 'POST' ? 'Success Addition of SWP Processed Successfully' : 'Success Modify SWP Processed Successfully');
        OcInfraModule.AppInjector.get(AdvGrowlService).createSuccessMessage(message, 'Success');
        document.getElementById("modal__cross").click();
        // on sucess refresh pending transactions tab under policyDetails so CSR can view latest datacollector record.
        this.refreshPendingTransactions();
        this.populateSwpCallHistorydetails();
        break;
      }
    }
  }

  processSystematicWithdrawl() {
    let callNoteObj = {};
    console.log("Processing SWP ... Calling API ");
    console.log("req body ", this.payload);
    let isPost = this.surrenderDetails && this.surrenderDetails._options.links.findIndex((link) => link.method.toLowerCase() === 'post') > -1
    if (isPost) {
      this.sysIndicator = "Withdrawl"
      var link = this.surrenderDetails._options.links.find(link => link.method.toLowerCase() === 'post');
      if (link) {
        OcInfraModule.AppInjector.get(CommonService).postCallback(link.href, this.payload, this.headers).subscribe(response => {
          this.processSWPResponse(response, 'POST', false);

        }, error => {
          OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error._embedded && error._embedded.status_report && error._embedded.status_report.messages[0].message, 'Error', 0);
        });
      }
    } else {
      this.sysIndicator = "updateWithdrawl";
      var link = this.surrenderDetails._options.links.find(link => link.method.toLowerCase() === 'patch' && link.rel.toLowerCase() == "update");
      if (link) {
        OcInfraModule.AppInjector.get(CommonService).patchCallback(link.href, this.payload, this.headers).subscribe(response => {
          OcInfraModule.AppInjector.get(CommonService).confirmation = true;
          this.processSWPResponse(response, 'PATCH', false);
        }, error => {
          OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error._embedded && error._embedded.status_report && error._embedded.status_report.messages[0].message, 'Error', 0);
        });
      }
    }
  }
  

  onSubmit() {
    if(this.withdrawlSurrenderType === 'FULL_SURRENDER' && this.isLife){
      this.showRequestedData();
    }
    let childPayment = this.confirmationObj.childPayment;
    let childQuote = this.confirmationObj.childQuote;
    let childSurrender = this.confirmationObj.childSurrender;
    const authResponse = this.resourceService.getResourceFromAliasName('authRes');
    const selectedRoleSummary = this.resourceService.getResourceFromAliasName('selectedRoles');
    let policyDetails = this.resourceService.getResourceFromAliasName('policyDetails');
    let header = _.filter(Configuration.config.headers["multiHostUrlHeader"], function (o) { return o.alias == 'workRequestHistory' });
    header[0].headers['user_role'] = OcInfraModule.AppInjector.get(CommonService).userRole.toLowerCase();
    if(this.withdrawlSurrenderType === 'FULL_SURRENDER' && this.isLife){
      let role = OcInfraModule.AppInjector.get(CommonService).selectedRole;
      let roleSelected = role.charAt(0).toUpperCase() + role.slice(1);
      this.headers['role_name'] = roleSelected;
    }
    if(this.withdrawlSurrenderType == 'PARTIAL_SURRENDER' && this.isGroupMrpsContract){
        let asset_account = this.confirmationObj.childSurrender.surrenderForm.get('accountType').value;
        if (asset_account == "**") {
          asset_account = '**';
        } else {
          asset_account = asset_account.substring(0, asset_account.indexOf(' '));
        }
        let plan_code = this.confirmationObj.childSurrender.surrenderForm.get('productType').value;
        this.headers['asset_account'] = asset_account;
        this.headers['plan_code'] = plan_code;
    }

    if(this.withdrawlSurrenderType !== 'FULL_SURRENDER' && this.withdrawlSurrenderType !== 'PARTIAL_SURRENDER' && this.withdrawlSurrenderType !== 'SYSTEMATIC_WITHDRAWL'){
    this.payload = {
      withdraw_amt: childSurrender.withdrawalAmt? childSurrender.withdrawalAmt.replace('$','').replace(',',''):0,
      state_withh_amount: childSurrender.federalWithHolding && childSurrender.stateOverrideAmount ? childSurrender.stateOverrideAmount.replace('$',''):0,
      fed_withh_amount: childSurrender.federalWithHolding && childSurrender.federalOverrideAmount ? childSurrender.federalOverrideAmount.replace('$','').replace(',',''):0,
      div_int_withholding: childSurrender.dividendIntWith ? childSurrender.dividendIntWith.replace('$',''):0,
      override_withhold :  childSurrender.federalWithHolding ? 'Y' : 'N',
      zero_withhold: childSurrender.zeroWithHolding  ? 'Y' : 'N',
      state_withh_percent: childSurrender.stateWithHoldingPer ? childSurrender.stateWithHoldingPer.replace('%',''):0.0,
      fed_withh_percent: childSurrender.federalWithHoldingPer ? childSurrender.federalWithHoldingPer.replace('%',''):0.0,
      net_gross_ind:  childSurrender.withdrawlNetGrossIndicator,
      "maxwithhold_no_gain": this.surrenderDetails.maxwithhold_no_gain,
      "max_withdrawal_amt": this.surrenderDetails.max_withdrawal_amt,
      "rider_cash_value": this.surrenderDetails.rider_cash_value,
      "gross_withdrawal_amt": childQuote.grossWithholdAmount ? childQuote.grossWithholdAmount.toString().replace(/,|\s/g, "").replace("$", "") : 0,
      "base_face_amt": childQuote.baseFaceAmount ? childQuote.baseFaceAmount.toString().replace(/,|\s/g, "").replace("$", "") : 0,
      "additional_ins_bal": this.surrenderDetails.additional_ins_bal,
      "dwi_balance": this.surrenderDetails.dwi_balance,
      "cashval_paidup_add": this.surrenderDetails.cashval_paidup_add,
      "adj_cost_basis": this.surrenderDetails.adj_cost_basis,
      "loan_boot": this.surrenderDetails.loan_boot,
      "transaction_gain": this.surrenderDetails.transaction_gain,
      "trx_gain": this.surrenderDetails.trx_gain,
      "cost_basis": this.surrenderDetails.cost_basis,
      "withdraw_to_pay_premium": [childPayment.withdrawToPayPremiumM],
     
      "ssn_facta_verify": childSurrender.ssn_facta_verify,
      "withdrawal_types": [childSurrender.withdrawalTransactionType],
      "disbursement_ind": [childPayment.disbursementIndM],
      "role": selectedRoleSummary.data.role,
      "first_name": childPayment.first_name,
      "last_name": childPayment.last_name,
      "address_line1": childPayment.address_line1,
      "address_line2": childPayment.address_line2,
      "address_line3": childPayment.address_line3,
      "address_line4": this.surrenderDetails.address_line4,
      "city": childPayment.city,
      "state": childPayment.state,
      "country": childPayment.country,
      "postal_code": childPayment.postal_code ?  childPayment.postal_code.replace(/-/g, "") : childPayment.postal_code,
      "eft_two_premium_payments_verified": "",
      "bank_account_verified": "",
      "pua_dividend_cv": this.surrenderDetails.pua_dividend_cv,
      "encum_amt": this.surrenderDetails.encum_amt,

      "account_number": childPayment.account_number,
      "account_type": childPayment.account_type?childPayment.account_type.split('-')[0] : '',
      "transit":childPayment.transit,
   
      "amt_paidup_div": this.surrenderDetails.amt_paidup_div,
      "max_withdraw_amt_without_mec": this.surrenderDetails.max_withdraw_amt_without_mec,
      "discount_cash_value": this.surrenderDetails.discount_cash_value,
      "loan_int_type": this.surrenderDetails.loan_int_type,
      "var_int_rate": this.surrenderDetails.var_int_rate,
      "accured_int_on_div": this.surrenderDetails.accured_int_on_div,
      "loan_principal": this.surrenderDetails.loan_principal,
      "loan_int_due": this.surrenderDetails.loan_int_due,
      "type": this.withdrawlSurrenderType == 'DIVIDEND_WITHDRAWAL' ? 'DIVIDEND' : '',
      "hostKey": selectedRoleSummary && selectedRoleSummary.data.hostKey ? selectedRoleSummary.data.hostKey : '',
      "sessionId": authResponse && authResponse.data.contactSessionID ? authResponse.data.contactSessionID : '',
      "callStart": authResponse && authResponse.data.callStart ? authResponse.data.callStart : '',
      "clientId": selectedRoleSummary && selectedRoleSummary.data.clientId ? selectedRoleSummary.data.clientId : '',
      "userId": OcInfraModule.AppInjector.get(CommonService).incontactId,
      "policyNumber": this.otherRoleSucess ? policyDetails.data.policy_number : selectedRoleSummary.data.policyNumber,
      'company_code': policyDetails.data.company_code,
      'region_code': header[0].headers.region_code,
      'remote_user': header[0].headers.remote_user,

     
    }
  }
  if(this.withdrawlSurrenderType === 'FULL_SURRENDER'){
    let distributionCode = this.confirmationObj.childPayment.distribution_code;
    let splitData =_.split(distributionCode,"-");
    this.distributionCodeSplitted = splitData[0];
     this.payload = {
      
      "effective_date": this.surrenderDetails.effective_date,
  
      "payeeName": this.surrenderDetails.payeeName,
  
      "payee_firstname": this.surrenderDetails.payee_firstname,
  
      "payee_lastname": this.surrenderDetails.payee_lastname,
  
      "payee_address1": this.surrenderDetails.payee_address1,
  
      "payee_address2": this.surrenderDetails.payee_address2,
  
      "payee_city": this.surrenderDetails.payee_city,
  
      "payee_state": this.surrenderDetails.payee_state,
  
      "payee_country": this.surrenderDetails.payee_country,
  
      "payee_zip": this.surrenderDetails.payee_zip,
  
      "payment_method": [childPayment.disbursementIndM],
  
      "firm_id": this.surrenderDetails.firm_id,
  
      "passed_enhanced_security": (childSurrender.client_recommend_or_authorized === "Y" || childSurrender.agent_recommend_or_authorized === "Y") ? "Y":"N",
  
      "no_federal_withholding": this.confirmationObj.childSurrender.getSurrenderValues().no_federal_withholding,
  
      "no_state_withholding": this.confirmationObj.childSurrender.getSurrenderValues().no_state_withholding,
  
      "next_business_day": this.surrenderDetails.next_business_day,
  
      "business_closehour": this.surrenderDetails.business_closehour,
  
      "statePctOrBasis": this.confirmationObj.childSurrender.getSurrenderValues().statePctOrBasis,
  
      "accumulated_cashvalue": this.surrenderDetails.accumulated_cashvalue,
  
      "surrender_penalty": this.surrenderDetails.surrender_penalty,
  
      "bank_account_verified": childPayment.isBankAccountVerified,
  
      "bank_account": [childPayment.BankAccount],
  
      "surrender_free_amount": this.surrenderDetails.surrender_free_amount,
  
      "minimum_surrender_benefit": this.surrenderDetails.minimum_surrender_benefit,
  
      "mva_amt": this.surrenderDetails.mva_amt,
  
      "redemption_fee": this.surrenderDetails.redemption_fee,
  
      "total_ann_value": this.surrenderDetails.total_ann_value,
  
      "loan_payoff_value": this.surrenderDetails.loan_payoff_value,
  
      "outstanding_loan_bal": this.surrenderDetails.outstanding_loan_bal,
  
      "premiumTax": this.surrenderDetails.premiumTax,
  
      "aba_fund_val": this.surrenderDetails.aba_fund_val,
  
      "surrender_amount": this.surrenderDetails.surrender_amount,
  
      "state_withholding_override": this.confirmationObj.childSurrender.getSurrenderValues().state_withholding_override?'Y':'N',
  
      "state_withholding_amount": (this.confirmationObj.childSurrender.getSurrenderValues().state_withholding_amount).replace('$',"").replace(',',""),

      "state_withholding_percentage": (this.confirmationObj.childSurrender.getSurrenderValues().state_withholding_percentage).replace('%',""),

      "federal_withholding_percentage": (this.confirmationObj.childSurrender.getSurrenderValues().federal_withholding_percentage).replace('%',""),
  
      "federal_withholding_override": this.confirmationObj.childSurrender.getSurrenderValues().federal_withholding_override?'Y':'N',
  
      "federal_withholding_amount": (this.confirmationObj.childSurrender.getSurrenderValues().federal_withholding_amount).replace('$',"").replace(',',""),
  
      "special_confirm": this.surrenderDetails.special_confirm,
  
      "municipal_withholding_amount": this.surrenderDetails.municipal_withholding_amount,
      
      "incontactId": OcInfraModule.AppInjector.get(CommonService).incontactId,
  
      "policyNumber": this.otherRoleSucess ? policyDetails.data.policy_number : selectedRoleSummary.data.policyNumber,

      "distribution_code" : this.distributionCodeSplitted,

      "origin_app": "csr_portal",

      "confirm_override": "",
  }

      if(this.isCv15Contract){
        this.paymentDetails = this.confirmationObj.childPayment.getPaymentValues();
        this.payload = { ...this.payload, ...{ eft_id: this.paymentDetails.disbursement_ind[0] === 'E' ? this.paymentDetails.eft_id : 0 } }
  }

      if (this.isLife) {
          this.payload = this.payloadFullSurrenderTermProduct(policyDetails, selectedRoleSummary)
          if (this.confirmationObj.childPayment.disbursementIndM == 'E') {
              let bankAccount: BankAccount = {
                  eft_id: this.paymentDetails.disbursement_ind[0] === 'E' ? this.paymentDetails.eft_id : 0,
                  bank_name: this.paymentDetails.bank_name ? this.paymentDetails.bank_name : '',
                  account_type: this.paymentDetails.account_type ?  this.paymentDetails.account_type : '',
                  account_number: this.paymentDetails.account_number ? this.paymentDetails.account_number : '',
                  transit_number: this.paymentDetails.transit ? this.paymentDetails.transit : '',
                  bank_account: this.confirmationObj.childPayment.BankAccount ? [this.confirmationObj.childPayment.BankAccount]: [],
                  eft_status: this.paymentDetails.eft_status ? this.paymentDetails.eft_status : '',
                 
              };
              this.payload = { ...this.payload, ...bankAccount };
          }
      } 
      
      if (this.isGroupMrpsContract) {
          this.payload = this.payloadFullSurrenderMRPSProduct(policyDetails, selectedRoleSummary)
          this.paymentDetails = this.confirmationObj.childPayment.getPaymentValues();
          if (this.confirmationObj.childPayment.disbursementIndM == 'E') {
              let bankAccount: BankAccount = {
                  eft_id: this.paymentDetails.disbursement_ind[0] === 'E' ? this.paymentDetails.eft_id : 0,
                  bank_name: this.paymentDetails.bank_name ? this.paymentDetails.bank_name : '',
                  account_type: this.paymentDetails.account_type ? this.paymentDetails.account_type : '',
                  account_number: this.paymentDetails.account_number ? this.paymentDetails.account_number : '',
                  transit_number: this.paymentDetails.transit ? this.paymentDetails.transit : '',
                  bank_account: this.paymentDetails.bankAccount ? this.paymentDetails.bankAccount : [],
              };
              this.payload = { ...this.payload, ...bankAccount };
          }

          let asset_account = '**';
          let plan_code = this.confirmationObj.childQuote.plan_id;
          this.headers['asset_account'] = asset_account;
          this.headers['plan_id'] = plan_code;
          this.headers['client_id'] = this.policyDetails['data']['client_id'];
          this.headers['user_role'] = OcInfraModule.AppInjector.get(CommonService).userRole.toLowerCase();
      }


      if (this.confirmOverride) {
          this.payload = { ...this.payload, ...{ confirm_override: "Y" } }
      }

} else if(this.withdrawlSurrenderType === 'PARTIAL_SURRENDER'){
  //let distributionCode = this.confirmationObj.childPayment.getPaymentValues().distribution_code;
  let fedWithholdPer = this.confirmationObj.childSurrender.surrenderForm.controls.federal_withholding_percentage.value;
  if(fedWithholdPer.includes('%')){
    fedWithholdPer = fedWithholdPer.slice(0, -1);
    fedWithholdPer = Number(fedWithholdPer);
  } else {
    fedWithholdPer = fedWithholdPer;
  }
  let distributionCode = this.surrenderDetails.distribution_code;
  if(!this.isLife && (distributionCode === "2-IRS Exception Distribution" &&  fedWithholdPer>20.00)){
    this.distribution_code = "2-IRS Exception Distribution";
  } else if(!this.isLife && (distributionCode === "2-IRS Exception Distribution" &&  fedWithholdPer<=20.00)){
    this.distribution_code = "";
  } else{
    this.distribution_code = this.surrenderDetails.distribution_code;
  }
  let distributionnCode = this.distribution_code;
  let splitData =_.split(distributionnCode,"-");

  this.distributionCodeSplitted = splitData[0];
  this.paymentDetails = this.confirmationObj.childPayment.getPaymentValues();
  this.payload = {
    "effective_date": this.surrenderDetails.effective_date,
    "transaction_level": this.confirmationObj.childSurrender.surrenderForm.get('transaction_level').value?this.confirmationObj.childSurrender.surrenderForm.get('transaction_level').value:"",
    "transaction_type": this.confirmationObj.childSurrender.surrenderForm.get('transaction_type').value?this.confirmationObj.childSurrender.surrenderForm.get('transaction_type').value:"",
    "surrender_amount": this.confirmationObj.childSurrender.surrenderForm.get('transaction_type').value == 'A'?this.confirmationObj.childSurrender.surrenderForm.get('surrender_amount').value?this.confirmationObj.childSurrender.surrenderForm.get('surrender_amount').value.replace('$',"").replace(',',""):"0.0":"0.0",
    "surrender_percent": this.confirmationObj.childSurrender.surrenderForm.get('surrender_percent').value?this.confirmationObj.childSurrender.surrenderForm.get('surrender_percent').value.replace('%',""):"0.0",
    "net_gross_indicator": this.confirmationObj.childSurrender.surrenderForm.get('net_gross_indicator').value == 'Gross'?"G":
    this.confirmationObj.childSurrender.surrenderForm.get('net_gross_indicator').value == 'Net'?"N":
    this.confirmationObj.childSurrender.surrenderForm.get('net_gross_indicator').value == 'NetNet'?"A":"",
    "state_withholding_override": this.confirmationObj.childSurrender.getSurrenderValues().state_withholding_override?'Y':'N',
    "state_withholding_amount": (this.confirmationObj.childSurrender.getSurrenderValues().state_withholding_amount).replace('$',"").replace(',',""),
    "federal_withholding_override": this.confirmationObj.childSurrender.getSurrenderValues().federal_withholding_override?'Y':'N',
    "federal_withholding_amount": (this.confirmationObj.childSurrender.getSurrenderValues().federal_withholding_amount).replace('$',"").replace(',',""),
    "federal_withholding_percentage": this.confirmationObj.childSurrender.getSurrenderValues().federal_withholding_percentage.replace('%',""), 
    "no_federal_withholding": this.confirmationObj.childSurrender.getSurrenderValues().no_federal_withholding,
    "no_state_withholding": this.confirmationObj.childSurrender.getSurrenderValues().no_state_withholding,
    "state_withholding_percentage": this.confirmationObj.childSurrender.getSurrenderValues().state_withholding_percentage.replace('%',""),
    "eft_id": this.confirmationObj.childPayment.disbursementIndM == 'E' ? this.paymentDetails.eft_id : 0,
    "distribution_code": this.distributionCodeSplitted,
    "payee_firstname": this.surrenderDetails.payee_firstname?this.surrenderDetails.payee_firstname:"",
    "payee_lastname": this.surrenderDetails.payee_lastname?this.surrenderDetails.payee_lastname:"",
    "payee_address1": this.surrenderDetails.payee_address1?this.surrenderDetails.payee_address1:"",
    "payee_address2": this.surrenderDetails.payee_address2?this.surrenderDetails.payee_address2:"",
    "payee_city": this.surrenderDetails.payee_city?this.surrenderDetails.payee_city:"",
    "payee_state": this.surrenderDetails.payee_state?this.surrenderDetails.payee_state:"",
    "payee_zip": this.surrenderDetails.payee_zip?this.surrenderDetails.payee_zip:"",
    "payee_country": this.surrenderDetails.payee_country?this.surrenderDetails.payee_country:"",
    "next_business_day": this.surrenderDetails.next_business_day?this.surrenderDetails.next_business_day:"",
    "firm_id": this.surrenderDetails.firm_id,
    "payment_method": [childPayment.disbursementIndM],
    "confirm_override": "", 
    "policyNumber": policyDetails.data.policy_number,
    "origin_app": "csr_portal",
    "incontactId": OcInfraModule.AppInjector.get(CommonService).incontactId,
    "statePctOrBasis": this.confirmationObj.childSurrender.surrenderForm.get('statePctOrBasis').value?this.confirmationObj.childSurrender.surrenderForm.get('statePctOrBasis').value:"",
  }
  this.getFilteredFunds();
  let resultedFunds = this.selectedFunds.map(({ surrender_amount_cal, ...rest }) => rest)//removing surrender_amount_cal from payload
  this.payload["fundValues"] = resultedFunds;
  if (this.confirmationObj.childPayment.disbursementIndM == 'E') {
    this.payload["bank_name"] = this.confirmationObj.childPayment.getPaymentValues().bank_name;
    this.payload["account_type"] = this.confirmationObj.childPayment.getPaymentValues().account_type;
    this.payload["account_number"] = this.confirmationObj.childPayment.getPaymentValues().account_number;
    this.payload["transit_number"] = this.confirmationObj.childPayment.getPaymentValues().transit;
  }

  if (this.confirmOverride) {
    this.payload = { ...this.payload, ...{ confirm_override: "Y" } }
  }
  } else if (this.withdrawlSurrenderType === 'SYSTEMATIC_WITHDRAWL') {
      this.payload = this.payloadSystematicWithdrawalCV15(policyDetails, selectedRoleSummary);
      this.getFilteredFunds();
      this.payload["fundValues"] = this.selectedFunds;
      if (this.confirmationObj.childPayment.disbursementIndM == 'E') {
          let bankAccount: BankAccountSWP = {
              eft_id: this.paymentDetails.disbursement_ind[0] === 'E' ? this.paymentDetails.eft_id : 0,
              bank_name: this.paymentDetails.bank_name ? this.paymentDetails.bank_name : '',
              account_type: this.paymentDetails.account_type ? this.paymentDetails.account_type : '',
              account_number: this.paymentDetails.account_number ? this.paymentDetails.account_number : '',
              eft_status: this.paymentDetails.eft_status ? this.paymentDetails.eft_status : '',
          };
          this.payload = { ...this.payload, ...bankAccount };
      }
      if (this.confirmOverride) {
        this.payload = { ...this.payload, ...{ confirm_override: "Y" } }
      }

  }
    if((this.withdrawlSurrenderType == 'FULL_SURRENDER' || this.withdrawlSurrenderType == 'PARTIAL_SURRENDER') && this.isAmountAllowableAlertFull()){
      this.disableProcessBtn = true;
      let alertMsg = [];
      alertMsg.push("Amount Exceeds Allowable by Telephone");
      let dialogRef = OcInfraModule.AppInjector.get(CommonService).showConfirmation({ content: alertMsg, custom: true }, 'CLOSE', '', 'ALERT', '393px');
      dialogRef.afterClosed().subscribe(res => {
        res;
      });
    }
    else {
        const workRequestHref = this.resourceService.getHrefByAliasName("workRequestHistory").split('?')[0];
        const workRequestHeaders = OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("workRequestHistory");

        let postCallExist = this.surrenderDetails._options.links.length && this.surrenderDetails._options.links.find((item) => item.method == 'POST');
        let patchCallExist = this.surrenderDetails._options.links.length && this.surrenderDetails._options.links.find((item) => item.method == 'PATCH');
        if(this.withdrawlSurrenderType == 'SYSTEMATIC_WITHDRAWL' && (postCallExist || patchCallExist)){
          if(this.isGroupAnnuity && this.isMrpsContract){
            this.headers['product_type']= policyDetails.data.product_type;
            this.headers['remote_user']= workRequestHeaders.remote_user;
          }
        }
        if (postCallExist) {
            if(this.withdrawlSurrenderType == 'SYSTEMATIC_WITHDRAWL') {
                this.headers = {...this.headers, ...{User_role: OcInfraModule.AppInjector.get(CommonService).userRole.toLowerCase()}};
            }
            this.submitPOSTCall(workRequestHref, workRequestHeaders);
        } else if (patchCallExist && this.withdrawlSurrenderType == 'SYSTEMATIC_WITHDRAWL') {
            this.submitPATCHCall(workRequestHref, workRequestHeaders)
        }
    }
  }


    submitPOSTCall = (workRequestHref: any, workRequestHeaders: any) => {

        OcInfraModule.AppInjector.get(CommonService).postCallback(this.surrenderDetails._options.links[1].href, this.payload, this.headers).subscribe(response => {
            if (this.withdrawlSurrenderType == 'FULL_SURRENDER' || this.withdrawlSurrenderType == 'PARTIAL_SURRENDER' || this.withdrawlSurrenderType == 'SYSTEMATIC_WITHDRAWL') {
                this.showConfirmOverride(response, workRequestHref, workRequestHeaders)
            }
            if (this.withdrawlSurrenderType == 'SYSTEMATIC_WITHDRAWL') {
                this.showRequestedDataSystematicWithdrawal()
            }
            if (this.withdrawlSurrenderType !== 'FULL_SURRENDER' && this.withdrawlSurrenderType !== 'PARTIAL_SURRENDER' && this.withdrawlSurrenderType !== 'SYSTEMATIC_WITHDRAWL') {
                OcInfraModule.AppInjector.get(AdvGrowlService).createSuccessMessage("Success/Transaction processed successfully", 'Success');
                if (response.code == '200') {
                    setTimeout(() => {
                        OcInfraModule.AppInjector.get(APICallerService).refresh(workRequestHref, { 'alias': "workRequestHistory", 'headers': workRequestHeaders }).subscribe(refreshResponse => {
                            OcInfraModule.AppInjector.get(CommonService).wrkReqHistoryRes = refreshResponse;
                            OcInfraModule.AppInjector.get(CommonService).sendFilterEvent();
                            OcInfraModule.AppInjector.get(CommonService).dividendAlert = false;
                            document.getElementById("modal__cross").click();
                            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
                        });
                    }, 2500);
                }
            }
        }, error => {
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
            if (this.withdrawlSurrenderType == 'FULL_SURRENDER' || this.withdrawlSurrenderType == 'PARTIAL_SURRENDER') {
                let errorMessages = this.getNested(error, '_embedded', 'status_report', 'messages');
                if (errorMessages && errorMessages.length > 0) {
                    //OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error._embedded.status_report.messages[0].message, 'Error', 5000);
                    this.showConfirmOverride(error, workRequestHref, workRequestHeaders)
                } else {
                    OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage("Failed to submit request. Please try again.", 'Error', 5000);
                }
            } else {
                if (error && error._embedded && error._embedded.status_report && error._embedded.status_report.messages
                    && error._embedded.status_report.messages.length > 0) {
                    //OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error._embedded.status_report.messages[0].message, 'Error', 5000);
                    let errorMessages = OcInfraModule.AppInjector.get(CommonService).getNested(error, '_embedded', 'status_report', 'messages');
                    if (errorMessages && errorMessages.length > 0) {
                        //below condition is because we don't have confirm override scenario in dividend withdrawl
                        if(this.withdrawlSurrenderType === 'DIVIDEND_WITHDRAWAL'){
                          this.displayAlerts(error, "error");
                          this.disableProcessBtn = true;
                          this.alertColor = 'red';
                        } else{
                        this.showConfirmOverride(error, workRequestHref, workRequestHeaders);
                        }
                    }
                } else {
                    this.withdrawlSurrenderType === 'DIVIDEND_WITHDRAWAL' ? OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage("Failed to submit Dividend Withdrawal request. Please try again.", 'Error', 5000) : "";
                    this.withdrawlSurrenderType == 'SYSTEMATIC_WITHDRAWL' ? OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage("Failed to submit Systematic Withdrawal request. Please try again.", 'Error', 5000) : "";
                }
            }
        });

    }

    submitPATCHCall = (workRequestHref: any, workRequestHeaders: any) => {
        let headers = this.headers
        if(this.isMrpsContract && this.isGroupAnnuity) {
            if(this.surrenderDetails && this.surrenderDetails.sequence_number) {
                headers = {...this.headers, ...{'sequence_number': this.surrenderDetails.sequence_number}}    
            }        
        }
        OcInfraModule.AppInjector.get(CommonService).patchCallback(this.surrenderDetails._options.links[1].href, this.payload, headers).subscribe(response => {
            this.showConfirmOverride(response, workRequestHref, workRequestHeaders)
            this.showRequestedDataSystematicWithdrawal()
        }, error => {
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
            let errorMessages = this.getNested(error, '_embedded', 'status_report', 'messages');
            if (errorMessages && errorMessages.length > 0) {
                if(error.statusCode == '500' && this.withdrawlSurrenderType == 'SYSTEMATIC_WITHDRAWL')
                    error = { ...error, confirm_override: 'N' }
                
                this.showConfirmOverride(error, workRequestHref, workRequestHeaders)
            } else {
                OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage("Failed to submit request. Please try again.", 'Error', 5000);
            }
        });

    }


  getFilteredFunds() {
    const filteredFunds = this.confirmationObj.childSurrender.funds.filter((item) =>
      (item.surrender_amt != 0.00 ? this.validatefundValue(item.surrender_amt) : false || item.surrender_percent != 0.0? this.validatefundValue(item.surrender_percent) : false));
    filteredFunds.forEach((fund,i)=> {
      fund.surrender_amt = (fund.surrender_amt && this.validatefundValue(fund.surrender_amt)) ? fund.surrender_amt.replace(/[^0-9.-]+/g, '') : '0.00';
      fund.surrender_percent = (fund.surrender_percent && this.validatefundValue(fund.surrender_percent)) ? fund.surrender_percent.replace(/[^0-9.-]+/g, '') : '0.0';
      delete fund._links;
    });
    this.selectedFunds = filteredFunds;
  }

  validatefundValue(value) {
    let fundValue = value.replace(/,|\s/g, "").replace("%", "").replace("$", "");
    return Number(fundValue) > 0 ? true : false;
  }

  showRequestedData() {
        let html = '';
        let requestedDetails = {};
        let disbursement = this.confirmationObj.childPayment.disbursementIndM == "C" ? "Cash" : this.confirmationObj.childPayment.disbursementIndM == "E" ? "EFT" : "";
        let transactionAmount = this.updatedCheckAmount?"$"+this.updatedCheckAmount:"$0.00";
        let federalAmount = this.confirmationObj.childSurrender.getSurrenderValues().no_federal_withholding == 'N' ? this.confirmationObj.childSurrender.getSurrenderValues().federal_withholding_amount: "$0.00";
        let federalPercent = this.confirmationObj.childSurrender.getSurrenderValues().no_federal_withholding == 'N' ? this.confirmationObj.childSurrender.getSurrenderValues().federal_withholding_percentage:"0.00%";
        let stateAmount = this.confirmationObj.childSurrender.getSurrenderValues().no_state_withholding == 'N' ? this.confirmationObj.childSurrender.getSurrenderValues().state_withholding_amount:"$0.00";
        let statePercent = this.confirmationObj.childSurrender.getSurrenderValues().no_state_withholding == 'N' ? this.confirmationObj.childSurrender.getSurrenderValues().state_withholding_percentage:"0.00%";
        let penalties = this.surrenderDetails.surrender_penalty?"$"+this.surrenderDetails.surrender_penalty:"$0.00";

        requestedDetails['Transaction Effective Date'] = this.datePipe.transform(this.surrenderDetails.effective_date, 'MM/dd/yyyy');
        html += `<dt class="pull-left"> Transaction Effective Date : </dt><dd>&nbsp; ` + this.datePipe.transform(this.surrenderDetails.effective_date, 'MM/dd/yyyy') + `</dd>`
        
        requestedDetails['Confirmation Number'] = this.ConfirmationNumber;
        html += `<dt class="pull-left"> Confirmation Number : </dt><dd>&nbsp; ` + this.ConfirmationNumber + `</dd>`
        
        requestedDetails['Disbursement'] = disbursement;
        html += `<dt class="pull-left"> Disbursement : </dt><dd>&nbsp; ` + disbursement + `</dd>`
        
        requestedDetails['Transaction Amount/Percent'] = transactionAmount;
        html += `<dt class="pull-left"> Transaction Amount/Percent : </dt><dd>&nbsp; ` + transactionAmount + `</dd>`
        
        requestedDetails['Federal Withholding Amount'] = federalAmount;
        html += `<dt class="pull-left"> Federal Withholding Amount : </dt><dd>&nbsp; `+ federalAmount + `</dd>`

        requestedDetails['Federal Withholding Percent'] = federalPercent;
        html += `<dt class="pull-left"> Federal Withholding Percent : </dt><dd>&nbsp;` + federalPercent + `</dd>`

        requestedDetails['State Withholding Amount'] = stateAmount;
        html += `<dt class="pull-left"> State Withholding Amount : </dt><dd>&nbsp;` + stateAmount + `</dd>`

        requestedDetails['State Withholding Percent'] = statePercent;
        html += `<dt class="pull-left"> State Withholding Percent : </dt><dd>&nbsp; ` + statePercent + `</dd>`

        requestedDetails['Penalties'] = penalties;
        html += `<dt class="pull-left"> Penalties : </dt><dd>&nbsp; ` + penalties + `</dd>`
        
        let fullSurrHtml = `<span style="font-size:15px" class="pad-lAr-10px">Full Surrender</span><dl class="pad-lAr-10px">` + html + `</dl>`;
        OcInfraModule.AppInjector.get(CommonService).fullSurrHtml = fullSurrHtml;
        OcInfraModule.AppInjector.get(CommonService).showRequestedDetails();
        OcInfraModule.AppInjector.get(CommonService).fullSurrDetails = { ['Full Surrender']: requestedDetails };
  }

  showConfirmOverride(response: any, workRequestHref: any, workRequestHeaders: any) {
    if(response.confirm_override === undefined && response.code == "200"){
      response.confirm_override = '';
    }  
    switch (response.confirm_override) {
      // AWD scenario
      case 'W': {
        this.displayAlerts(response, "error");
        for (let i = 0; i < response._options.links.length; i++) {
          if (response._options.links[i].method == 'POST') {
            this.enableAWDFlow = true;
            this.workRequestHref = response._options.links[i].href;
            this.surrenderWorkRequestPayload = this.createAWDPayload();
          }
        }
        break;
      }
      // soft error scenario
      case 'Y': {
        this.displayAlerts(response, "error");
        let content = 'Are you sure?  You want to override ?';
        let title = 'Alert';
        let dialogRef = OcInfraModule.AppInjector.get(CommonService).showConfirmationPopup(content, 'No', 'Yes', title, '340px');
        dialogRef.afterClosed().subscribe(res => {
          if (res) {    
            this.confirmOverride = true;
            this.onSubmit();
          }
        });
        break;
      }
      // hard error scenario
      case 'N': {
        this.displayAlerts(response, "error");
        this.disableProcessBtn = true;
        this.alertColor = 'red';
        break;
      }
      // success scenario
      case '': {
        //if (response._embedded && response._embedded.status_report && response._embedded.status_report.messages && response._embedded.status_report.messages.length > 0) {
          //clear existing alerts and then display the warning
          this.toasterService.clear();
          //this.displayAlerts(response, "warning");
          if(this.withdrawlSurrenderType === "FULL_SURRENDER"){
          OcInfraModule.AppInjector.get(AdvGrowlService).createSuccessMessage("Full Surrender Processed Successfully", 'Success');
          } else if(this.withdrawlSurrenderType === "PARTIAL_SURRENDER"){
            OcInfraModule.AppInjector.get(AdvGrowlService).createSuccessMessage("Partial Surrender Processed Successfully", 'Success');
          } else if(this.withdrawlSurrenderType === "SYSTEMATIC_WITHDRAWL"){
            OcInfraModule.AppInjector.get(AdvGrowlService).createSuccessMessage("Systematic Withdrawal Processed Successfully", 'Success');
          }
          if (response) {
            setTimeout(() => {
              OcInfraModule.AppInjector.get(APICallerService).refresh(workRequestHref, { 'alias': "workRequestHistory", 'headers': workRequestHeaders }).subscribe(refreshResponse => {
              OcInfraModule.AppInjector.get(CommonService).wrkReqHistoryRes = refreshResponse;
              OcInfraModule.AppInjector.get(CommonService).sendFilterEvent();
              OcInfraModule.AppInjector.get(CommonService).dividendAlert = false;    
              if(this.confirmOverride || response){
                document.getElementById("modal__cross").click();
              }
              OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
            });
          }, 1000);
        } 
      }
    }
  }

  showRequestedDataSystematicWithdrawal = () => {
    const { effective_date } = this.confirmationObj.childSurrender.getSurrenderValues();
    const confirmationDate = moment().tz('America/New_York').format('MM/DD/YYYY HH:mm');
    
    let html = '';
    let requestedDetails = {};
    let requestedDate = moment().tz('America/New_York').format('MM/DD/YYYY HH:mm');

    requestedDetails['Confirmation Number'] = this.ConfirmationNumber;
    html += `<dt class="pull-left"> Confirmation Number : </dt><dd>&nbsp; ` + this.ConfirmationNumber  + `</dd>`
    
    requestedDetails['Requested Date and Time'] = requestedDate;
    html += `<dt class="pull-left"> Requested Date and Time : </dt><dd>&nbsp; ` + requestedDate  + `</dd>`
    
    requestedDetails['Confirmation date and time'] = confirmationDate;
    html += `<dt class="pull-left"> Confirmation date and time : </dt><dd>&nbsp; ` + confirmationDate  + `</dd>`
    
    requestedDetails['Effective Date of Transaction'] = effective_date;
    html += `<dt class="pull-left"> Effective Date of Transaction : </dt><dd>&nbsp; ` + effective_date  + `</dd>`
    
    requestedDetails['Type of Transaction'] = 'Pending';
    html += `<dt class="pull-left"> Type of Transaction : </dt><dd>&nbsp; Pending </dd>`
    
    let systematicWithdrawalHtml = `<span style="font-size:15px" class="pad-lAr-10px">Systematic Withdrawal</span><dl class="pad-lAr-10px">` + html + `</dl>`;

    OcInfraModule.AppInjector.get(CommonService).systematicWithdrawalHtml = systematicWithdrawalHtml;
    OcInfraModule.AppInjector.get(CommonService).showRequestedDetails();
    OcInfraModule.AppInjector.get(CommonService).systematicWithdrawalDetails = { ['Systematic Withdrawal']: requestedDetails };

  }
  
  payloadFullSurrenderTermProduct = (policyDetails: any, selectedRoleSummary: any): PayloadFullSurrenderTerm =>  {
    //PANOCSR-3449 For UL's required to send values into payload based on field enabled so written below condition
    let fAmount,fPercent,sAmount,sPercent;
    if(this.isUniversalProduct){
      fAmount = this.confirmationObj.childSurrender.surrenderForm.controls.federal_withholding_amount.status == "VALID"?(this.confirmationObj.childSurrender.getSurrenderValues().federal_withholding_amount).replace('$', "").replace(',', ""):"0.00";
      fPercent = this.confirmationObj.childSurrender.surrenderForm.controls.federal_withholding_percentage.status == "VALID"?(this.confirmationObj.childSurrender.getSurrenderValues().federal_withholding_percentage).replace('%', "").replace(',', ""):"0.00";
      sAmount = this.confirmationObj.childSurrender.surrenderForm.controls.state_withholding_amount.status == "VALID"?(this.confirmationObj.childSurrender.getSurrenderValues().state_withholding_amount).replace('$', "").replace(',', ""):"0.00";
      sPercent = this.confirmationObj.childSurrender.surrenderForm.controls.state_withholding_percentage.status == "VALID"?(this.confirmationObj.childSurrender.getSurrenderValues().state_withholding_percentage).replace('%', "").replace(',', ""):"0.00";
    } else {
      fAmount = (this.confirmationObj.childSurrender.getSurrenderValues().federal_withholding_amount).replace('$', "").replace(',', "");
      fPercent = (this.confirmationObj.childSurrender.getSurrenderValues().federal_withholding_percentage).replace('%', "").replace(',', "");
      sAmount = (this.confirmationObj.childSurrender.getSurrenderValues().state_withholding_amount).replace('$', "").replace(',', "");
      sPercent = (this.confirmationObj.childSurrender.getSurrenderValues().state_withholding_percentage).replace('%', "").replace(',', "");
    }
    return {
        effective_date: this.surrenderDetails.effective_date,
        payeeName: this.surrenderDetails.payeeName,
        payee_firstname: this.surrenderDetails.payee_firstname,
        payee_lastname: this.surrenderDetails.payee_lastname,
        payee_address1: this.surrenderDetails.payee_address1,
        payee_address2: this.surrenderDetails.payee_address2,
        payee_address3: this.surrenderDetails.payee_address3,
        payee_city: this.surrenderDetails.payee_city,
        payee_state: this.surrenderDetails.payee_state,
        payee_country: this.surrenderDetails.payee_country,
        payee_zip: this.surrenderDetails.payee_zip,
        payment_method: [this.confirmationObj.childPayment.disbursementIndM],
        firm_id: this.surrenderDetails.firm_id,
        passed_enhanced_security: (this.confirmationObj.childSurrender.client_recommend_or_authorized === "Y" || this.confirmationObj.childSurrender.agent_recommend_or_authorized === "Y") ? "Y" : "N",
        no_federal_withholding: this.confirmationObj.childSurrender.getSurrenderValues().no_federal_withholding,
        no_state_withholding: this.confirmationObj.childSurrender.getSurrenderValues().no_state_withholding,
        next_business_day: this.surrenderDetails.next_business_day,
        business_closehour: this.surrenderDetails.business_closehour,
        statePctOrBasis: this.surrenderDetails.statePctOrBasis ? this.surrenderDetails.statePctOrBasis : '',
        accumulated_cashvalue: this.surrenderDetails.accumulated_cashvalue,
        surrender_penalty: this.surrenderDetails.surrender_penalty ? this.surrenderDetails.surrender_penalty : 0,
        bank_account_verified: this.confirmationObj.childPayment.isBankAccountVerified ? 'Y' : 'N',
        surrender_free_amount: this.surrenderDetails.surrender_free_amount ? this.surrenderDetails.surrender_free_amount : '0.00',
        minimum_surrender_benefit: this.surrenderDetails.minimum_surrender_benefit ? this.surrenderDetails.minimum_surrender_benefit : 0,
        mva_amt:  this.surrenderDetails.mva_amt ? this.surrenderDetails.mva_amt : 0,
        redemption_fee: this.surrenderDetails.redemption_fee ? this.surrenderDetails.redemption_fee : 0,
        total_ann_value: this.surrenderDetails.total_ann_value ? this.surrenderDetails.total_ann_value : 0,
        loan_payoff_value: this.surrenderDetails.loan_payoff_value ? this.surrenderDetails.loan_payoff_value : 0,
        outstanding_loan_bal: this.surrenderDetails.outstanding_loan_bal ? this.surrenderDetails.outstanding_loan_bal : 0,
        premiumTax: this.surrenderDetails.premiumTax ? this.surrenderDetails.premiumTax : 0,
        aba_fund_val: this.surrenderDetails.aba_fund_val ? this.surrenderDetails.aba_fund_val : 0,
        surrender_amount: this.surrenderDetails.surrender_amount ? this.surrenderDetails.surrender_amount : 0,
        state_withholding_override: this.confirmationObj.childSurrender.getSurrenderValues().state_withholding_override ? 'Y' : 'N',
        state_withholding_amount: sAmount,
        federal_withholding_override: this.confirmationObj.childSurrender.getSurrenderValues().federal_withholding_override ? 'Y' : 'N',
        federal_withholding_amount: fAmount,
        special_confirm: this.surrenderDetails.special_confirm,
        municipal_withholding_amount: this.surrenderDetails.municipal_withholding_amount ? this.surrenderDetails.municipal_withholding_amount : '0.00',
        incontactId: OcInfraModule.AppInjector.get(CommonService).incontactId,
        policyNumber: this.otherRoleSucess ? policyDetails.data.policy_number : selectedRoleSummary.data.policyNumber,
        distribution_code: this.surrenderDetails.distribution_code,
        origin_app: 'csr_portal',
        confirm_override: '',

        federal_withholding_percentage:  fPercent,
        net_check_amount: this.updatedCheckAmount ? this.updatedCheckAmount : 0,
        roles:  this.surrenderDetails.roles,
        state_withholding_percentage: sPercent,
        taxable_gain: this.surrenderDetails.taxable_gain,
        cost_basis:this.surrenderDetails.cost_basis,   
        additional_ins_bal: this.surrenderDetails.additional_ins_bal,
        guaranteed_cash_value: this.surrenderDetails.guaranteed_cash_value,
        discounted_cash_value: this.surrenderDetails.discounted_cash_value,
        pua_dividend_cv: this.surrenderDetails.pua_dividend_cv,
        terminal_dividend: this.surrenderDetails.terminal_dividend,
        adj_cost_basis: this.surrenderDetails.adj_cost_basis,  
        ssn_facta_verify: this.surrenderDetails.ssn_facta_verify,
    }
  }

  payloadSystematicWithdrawalCV15 = (policyDetails: any, selectedRoleSummary: any): PayloadSystematicWithdrawal => {
    let { start_next_date, end_date, mode, option, transaction_level, distribution, payout_amount, payout_percent,  no_federal_withholding, no_state_withholding, federal_withholding_override, state_withholding_override, federal_withholding_percentage, state_withholding_percentage, federal_withholding_amount, state_withholding_amount, alt_pay1_rem_ind, statePctOrBasis  } = this.confirmationObj.childSurrender.getSurrenderValues();
    
    let endDate = moment(end_date).format('MM/DD/yyyy');
    const startNextDate = moment(start_next_date).format('MM/DD/yyyy');
    let policyNumber = policyDetails.data.policy_number;

    const distributionLabel = this.confirmationObj.childPayment.getPaymentValues().distribution_code
    let distributionCode =_.split(distributionLabel,"-");
    return {
        end_date: endDate,
        next_date: startNextDate,
        effective_date: this.surrenderDetails.effective_date,
        transaction_level: transaction_level,
        frequency: mode,
        option: option,
        no_federal_withholding: no_federal_withholding,
        no_state_withholding: no_state_withholding,
        federal_withholding_override: federal_withholding_override ? 'Y' : 'N',
        state_withholding_override: state_withholding_override ? 'Y' : 'N',
        federal_withholding_amount: federal_withholding_amount.replace('$',"").replace(',',""),
        federal_withholding_percentage: federal_withholding_percentage.replace(/[^0-9.-]+/g, ''),
        state_withholding_amount: state_withholding_amount.replace('$',"").replace(',',""),
        state_withholding_percentage: state_withholding_percentage.replace(/[^0-9.-]+/g, ''),
        net_gross_indicator: distribution,
        payment_method: [this.confirmationObj.childPayment.disbursementIndM],
        payout_amount: payout_amount.replace('$',"").replace(',',""),
        payout_percent: payout_percent.replace(/[^0-9.-]+/g, ''),
        income_payout_percent: "0.0",
        policyNumber: policyNumber,
        distribution_code: this.distributionCodeSplitted,

        alt_pay1_rem_ind: alt_pay1_rem_ind ? alt_pay1_rem_ind : 'N',
        statePctOrBasis: statePctOrBasis
    }

}


    payloadFullSurrenderMRPSProduct = (policyDetails: any, selectedRoleSummary: any): PayloadFullSurrenderMRPS => {
        let distributionCode = this.surrenderDetails.distribution_code;
        let splitData =_.split(distributionCode,"-");
        let distributionCodeSplitted = splitData[0];

        return {
            effective_date: this.surrenderDetails.effective_date,
            payeeName: this.surrenderDetails.payeeName,
            payee_firstname: this.surrenderDetails.payee_firstname,
            payee_lastname: this.surrenderDetails.payee_lastname,
            payee_address1: this.surrenderDetails.payee_address1,
            payee_address2: this.surrenderDetails.payee_address2,
            payee_city: this.surrenderDetails.payee_city,
            payee_state: this.surrenderDetails.payee_state,
            payee_country: this.surrenderDetails.payee_country,
            payee_zip: this.surrenderDetails.payee_zip,
            payment_method: [this.confirmationObj.childPayment.disbursementIndM],
            firm_id: this.surrenderDetails.firm_id,
            passed_enhanced_security: (this.confirmationObj.childSurrender.client_recommend_or_authorized === "Y" || this.confirmationObj.childSurrender.agent_recommend_or_authorized === "Y") ? "Y" : "N",
            no_federal_withholding: this.confirmationObj.childSurrender.getSurrenderValues().no_federal_withholding,
            no_state_withholding: this.confirmationObj.childSurrender.getSurrenderValues().no_state_withholding,
            next_business_day: this.surrenderDetails.next_business_day,
            business_closehour: this.surrenderDetails.business_closehour,
            statePctOrBasis: this.surrenderDetails.statePctOrBasis ? this.surrenderDetails.statePctOrBasis : '',
            accumulated_cashvalue: this.surrenderDetails.accumulated_cashvalue ? this.surrenderDetails.accumulated_cashvalue : '',
            surrender_penalty: this.surrenderDetails.surrender_penalty ? this.surrenderDetails.surrender_penalty : 0,
            bank_account_verified: this.confirmationObj.childPayment.isBankAccountVerified,
            surrender_free_amount: this.surrenderDetails.surrender_free_amount ? this.surrenderDetails.surrender_free_amount : '0.00',
            minimum_surrender_benefit: this.surrenderDetails.minimum_surrender_benefit ? this.surrenderDetails.minimum_surrender_benefit : 0,
            mva_amt: this.surrenderDetails.mva_amt ? this.surrenderDetails.mva_amt : 0,
            redemption_fee: this.surrenderDetails.redemption_fee ? this.surrenderDetails.redemption_fee : 0,
            total_ann_value: this.surrenderDetails.total_ann_value ? this.surrenderDetails.total_ann_value : 0,
            loan_payoff_value: this.surrenderDetails.loan_payoff_value ? this.surrenderDetails.loan_payoff_value : 0,
            outstanding_loan_bal: this.surrenderDetails.outstanding_loan_bal ? this.surrenderDetails.outstanding_loan_bal : 0,
            premiumTax: this.surrenderDetails.premiumTax ? this.surrenderDetails.premiumTax : 0,
            aba_fund_val: this.surrenderDetails.aba_fund_val ? this.surrenderDetails.aba_fund_val : 0,
            surrender_amount: this.surrenderDetails.surrender_amount ? this.surrenderDetails.surrender_amount : 0,
            state_withholding_override: this.confirmationObj.childSurrender.getSurrenderValues().state_withholding_override ? 'Y' : 'N',
            state_withholding_amount: (this.confirmationObj.childSurrender.getSurrenderValues().state_withholding_amount).replace('$', "").replace(',', ""),
            federal_withholding_override: this.confirmationObj.childSurrender.getSurrenderValues().federal_withholding_override ? 'Y' : 'N',
            federal_withholding_amount: (this.confirmationObj.childSurrender.getSurrenderValues().federal_withholding_amount).replace('$', "").replace(',', ""),
            special_confirm: this.surrenderDetails.special_confirm,
            municipal_withholding_amount: this.surrenderDetails.municipal_withholding_amount ? this.surrenderDetails.municipal_withholding_amount : '0.00',
            incontactId: OcInfraModule.AppInjector.get(CommonService).incontactId,
            policyNumber: policyDetails.data.policy_number,
            distribution_code: distributionCodeSplitted,
            origin_app: 'csr_portal',
            confirm_override: '',

            federal_withholding_percentage: this.confirmationObj.childSurrender.getSurrenderValues().federal_withholding_percentage? (this.confirmationObj.childSurrender.getSurrenderValues().federal_withholding_percentage).replace('%',""):"0.00",
            state_withholding_percentage: this.confirmationObj.childSurrender.getSurrenderValues().state_withholding_percentage? (this.confirmationObj.childSurrender.getSurrenderValues().state_withholding_percentage).replace('%',""):"0.00",
        }
    }

  dividendAlertbanner(msg) {
    let status_report = {
      "showAll":true,
      "consistent": true,
      "messages": [
        {
          "message": msg,
          "severity": "ALERT"
        }
      ],
      url: this.surrenderDetails._options.links[1].href,
      alias: "dividentDetails",
    }

    let dividendAlert = setInterval(function () {
            
      if (OcInfraModule.AppInjector.get(CommonService).dividendAlertbanner) {
        OcInfraModule.AppInjector.get(CommonService).pushBannerAlertMessage(status_report);
          clearInterval(dividendAlert);
      }
  }, 500);
    
  }


  onSWPSubmitClick() {
    this.createSWPRequestBody();
    this.processSystematicWithdrawl();
  }

  createSWPRequestBody() {
    this.payload = {
      "passed_enhanced_security": this.getValueOfField(this.swpDataModal, 'passed_enhanced_security'),
      "end_date": this.datePipe.transform(this.getValueOfField(this.swpDataModal, 'end_date'), 'MM/dd/yyyy'),
      "next_date": this.datePipe.transform(this.getValueOfField(this.swpDataModal, 'next_date'), 'MM/dd/yyyy'),
      "effective_date": this.datePipe.transform(this.getValueOfField(this.swpDataModal, 'effective_date'), 'MM/dd/yyyy'),
      "transaction_level": this.getValueOfField(this.swpDataModal, 'transaction_level'),
      "frequency": this.getValueOfField(this.swpDataModal, 'frequency'),
      "option": this.getValueOfField(this.swpDataModal, 'option'),
      "no_federal_withholding": this.getValueOfField(this.swpDataModal, 'no_federal_withholding'),
      "no_state_withholding": this.getValueOfField(this.swpDataModal, 'no_state_withholding'),
      "federal_withholding_override": this.getValueOfField(this.swpDataModal, 'federal_withholding_override'),
      "state_withholding_override": this.getValueOfField(this.swpDataModal, 'state_withholding_override'),
      "federal_withholding_percentage": this.getValueOfField(this.swpDataModal, 'federal_withholding_percentage').replace(/[^0-9.-]+/g, ''),
      "state_withholding_percentage": this.getValueOfField(this.swpDataModal, 'state_withholding_percentage').replace(/[^0-9.-]+/g, ''),
      "net_gross_indicator": this.getValueOfField(this.swpDataModal, 'net_gross_indicator') ? 'G' : '',
      "wma_transaction_code": this.surrenderDetails.wma_transaction_code ? this.surrenderDetails.wma_transaction_code : '',
      "distribution_code": this.surrenderDetails.distribution_code ? this.surrenderDetails.distribution_code.split("-")[0] : ' ',
      "firm_id": this.surrenderDetails.firm_id ? this.surrenderDetails.firm_id : '',
      "eft_id": this.paymentDetails.disbursement.enum === 'E' ? this.surrenderDetails.eft_id : 0,
      "confirm_override": '',
      "next_business_day": this.isNextBusinessDay === false ? '' : 'y',
    };
    this.payload.fundValues = [];
    this.payload.payment_method = [];

    this.paymentDetails && this.paymentDetails.disbursement && this.paymentDetails.disbursement.enum ? this.payload.payment_method.push(this.paymentDetails.disbursement.enum) : null;

    const fundValues = this.getValueOfField(this.swpDataModal, 'fundValues');
    if (this.paymentDetails && this.payload.transaction_level === 'F' && fundValues && fundValues.length > 0) {
      fundValues.forEach(fundValue => {
        const fund = {
          "surrender_unit": ((fundValue['surrender_unit'] || '').replace(/[^0-9.-]+/g, '') || ''),
          "fund_value": ((fundValue['fund_value'] || '').replace(/[^0-9.-]+/g, '') || ''),
          "surrender_percent": ((fundValue['surrender_percent'] || '').replace(/[^0-9.-]+/g, '') || ''),
          "fund_number": fundValue['fund_number'],
          "surrender_amt": ((fundValue['surrender_amt'] || '').replace(/[^0-9.-]+/g, '') || '0.0'),
          "fund_name": fundValue['fund_name']
        };
        this.payload.fundValues.push(fund);
      });
    } else {
      this.payload.fundValues = null;
    }
    const withdrawalAmount = this.getValueOfField(this.swpDataModal, 'withdrawal_amount').replace(/[^0-9.-]+/g, '');
    this.payload['withdrawal_amount'] = this.payload["option"] === 'u' ? "0.0" : withdrawalAmount && withdrawalAmount !== '' ? withdrawalAmount[0] === '$' ? withdrawalAmount.substr(1) : withdrawalAmount : "0.0";

    const payoutPercent = this.getValueOfField(this.swpDataModal, 'payout_percent').replace(/[^0-9.-]+/g, '');
    this.payload['payout_percent'] = this.payload["option"] === 'u' ? "0.0" : payoutPercent && payoutPercent !== '' ? payoutPercent : "0.0";
  }

  enableProcessBtn() {
    if(this.withdrawlSurrenderType !== 'FULL_SURRENDER' && this.withdrawlSurrenderType !== 'PARTIAL_SURRENDER' && this.withdrawlSurrenderType !== 'SYSTEMATIC_WITHDRAWL') {
    // Seperating the isUserMenuAction and pending transaction to accomodate any future changes
    // IF user's flow is from ellipsis menu action 
    let paymentRequiredField =this.confirmationObj.childPayment.getPaymentValues();
    let surrenderRequiredField =this.confirmationObj.childSurrender.getSurrenderValues();
    if (this.withdrawalSurrenderAction.isUserMenuAction()) {
      this.disableProcessBtn = (this.authsucess || this.otherRoleSucess)
        && (surrenderRequiredField.required_fields && surrenderRequiredField.required_fields == 'Y')
        && (paymentRequiredField.required_payment_fields && paymentRequiredField.required_payment_fields == 'Y') ? this.scriptCheckBox : true;
    } else {
      // IF user's flow is from pending transaction tab edit (pencil) click
      this.disableProcessBtn = (this.authsucess || this.otherRoleSucess)
        && (surrenderRequiredField.required_fields && surrenderRequiredField.required_fields == 'Y')
        && (paymentRequiredField.required_payment_fields && paymentRequiredField.required_payment_fields == 'Y') ? this.scriptCheckBox : true;
    
      // If user's flow is from pending transaction, only allow enable if Patch link exists
      if (this.withdrawalSurrenderAction.isPendingTransactionAction()
      && !(this.surrenderDetails._options.links.findIndex(link => link.method.toLowerCase() == "patch") > -1))
        this.disableProcessBtn = true;
    }
  }
  if(this.withdrawlSurrenderType == 'FULL_SURRENDER' || this.withdrawlSurrenderType == 'PARTIAL_SURRENDER' || this.withdrawlSurrenderType == 'SYSTEMATIC_WITHDRAWL') {
    // Seperating the isUserMenuAction and pending transaction to accomodate any future changes
    // IF user's flow is from ellipsis menu action 
    let paymentRequiredField =this.confirmationObj.childPayment.getPaymentValues();
    let surrenderRequiredField =this.confirmationObj.childSurrender;
    if (this.withdrawalSurrenderAction.isUserMenuAction()) {
      this.disableProcessBtn = (this.authsucess || this.otherRoleSucess)
        && (surrenderRequiredField.isStepComplete())
        && (paymentRequiredField.required_payment_fields && paymentRequiredField.required_payment_fields == 'Y') ? this.scriptCheckBox : true;
    } else {
      // IF user's flow is from pending transaction tab edit (pencil) click
      this.disableProcessBtn = (this.authsucess || this.otherRoleSucess)
        && (surrenderRequiredField.isStepComplete())
        && (paymentRequiredField.required_payment_fields && paymentRequiredField.required_payment_fields == 'Y') ? this.scriptCheckBox : true;
    
      // If user's flow is from pending transaction, only allow enable if Patch link exists
      if (this.withdrawalSurrenderAction.isPendingTransactionAction()
      && !(this.surrenderDetails._options.links.findIndex(link => link.method.toLowerCase() == "patch") > -1))
        this.disableProcessBtn = true;
    }
    if(this.withdrawlSurrenderType == 'PARTIAL_SURRENDER' && this.WithdrawalPerDisable ){
      if((this.confirmationObj.childSurrender.surrenderForm.controls.transaction_type.value == 'F' || this.confirmationObj.childSurrender.surrenderForm.controls.transaction_type.value == 'P') && this.confirmationObj.childSurrender.surrenderForm.controls.transaction_level.value == 'F'){
        this.getFilteredFunds();
        let funds = this.selectedFunds;
        for(let i=0; i<funds.length; i++){
          if(funds[i].surrender_percent > 100){
            this.disableProcessBtn = true;
            this.hoverMessage = 'Total Percentage can not be more than 100';
          }
        }
      } else{
      this.disableProcessBtn = true;
      this.hoverMessage = 'Total Percentage can not be more than 100';
      }
    }
    if(this.withholdingPerDisable) {
      this.disableProcessBtn = true;
      this.hoverMessage = 'You can not enter more than 2 digits';
    }
  }
  }

    enableProcessBtnSWP() {
        const paymentRequiredField = this.confirmationObj.childPayment.getPaymentValues();
        const surrenderRequiredField = this.confirmationObj.childSurrender.isStepComplete();
        const isPendingTransactionAction = this.withdrawalSurrenderAction.isPendingTransactionAction();

        let disableProcessBtn = (this.authsucess || this.otherRoleSucess)
            && surrenderRequiredField
            && (paymentRequiredField.required_payment_fields === 'Y')
            && (!isPendingTransactionAction);
        this.disableProcessBtn = !disableProcessBtn;
        if(this.WithdrawalPerDisable) {
          this.disableProcessBtn = disableProcessBtn;
          this.hoverMessage = 'Total Percentage can not be more than 100';
        }
        if(this.withholdingPerDisable) {
          this.disableProcessBtn = true;
          this.hoverMessage = 'You can not enter more than 2 digits';
        }
    }

  confirmationValues(paymentDetails, scripts) {
    this.paymentDetails = paymentDetails;
    this.enableProcessBtn();
    this.partialSurrenderConfirmationValues = [];
    surrenderConfirmationValues.forEach(element => {
      if (this.paymentDetails.hasOwnProperty(element.id)) {
        if (typeof this.paymentDetails[element.id] == 'object') {
          element.value = this.paymentDetails[element.id].title;
        } else {
          element.value = this.paymentDetails["_options"] && this.paymentDetails["_options"].properties[element.id] && this.paymentDetails["_options"].properties[element.id].format && this.paymentDetails["_options"].properties[element.id].format === 'currency' ? '$' + this.paymentDetails[element.id] : this.paymentDetails[element.id];
          element.value = this.withdrawlSurrenderType == 'DIVIDEND_WITHDRAWAL' && element.id == "net_gross_indicator" ? (element.value == 'G' ? 'Gross' : 'Net') : element.value;
      }
        let allowElement = this.filterOptionalConfirmationValues(element);
        allowElement ? this.partialSurrenderConfirmationValues.push(element) : null;

      } else {
        element.id == "effective_date" ? element.value = this.datePipe.transform(this.paymentDetails.effective_date, "MM/dd/yyyy") : null;
        element.id == "effective_date" ? this.partialSurrenderConfirmationValues.push(element) : null;
      }
    });
    if (this.withdrawlSurrenderType == 'DIVIDEND_WITHDRAWAL') {
      this.partialSurrenderConfirmationValues.splice(
        findIndex(this.partialSurrenderConfirmationValues, ['id', this.paymentDetails.transaction_type.title === 'Percent' ? 'surrender_amount' : 'surrender_percent']), 1)
    }
  
    if (this.authsucess || this.otherRoleSucess) {
      let withHoldOverrideInd = (this.paymentDetails && this.paymentDetails.federal_withholding_override == "Y" || this.paymentDetails && this.paymentDetails.state_withholding_override == "Y") ? "Y" : "N";
      let noWithHoldInd = (this.paymentDetails && this.paymentDetails.no_federal_withholding == 'Y' || this.paymentDetails && this.paymentDetails.no_state_withholding == 'Y') ? 'Y' : 'N';
      let url = scripts && scripts.split('?')[0] + "?callerRole=" + OcInfraModule.AppInjector.get(CommonService).authorizedRole + "&withHoldOverrideInd=" + withHoldOverrideInd + "&noWithHoldInd=" + noWithHoldInd + "&paymentMethod=" + this.paymentDetails.disbursement.enum;
      OcInfraModule.AppInjector.get(CommonService).getCallback(url, this.headers).subscribe(response => {
        let msg = response.surrenderDetails._links.scripts.href;
        if (withHoldOverrideInd) {
          msg = msg.replace('WITHHOLDING_AMOUNT%', this.paymentDetails.state_withholding_amount !== '$0.00' || this.paymentDetails.state_withholding_percentage === '0.0%' ? this.paymentDetails.state_withholding_amount : this.paymentDetails.state_withholding_percentage)
          msg = msg.replace('___%', this.paymentDetails.federal_withholding_amount !== '$0.00' || this.paymentDetails.federal_withholding_percentage === '0.0%' ? this.paymentDetails.federal_withholding_amount : this.paymentDetails.federal_withholding_percentage)

        }
        msg = this.withdrawlSurrenderType == 'PARTIAL_SURRENDER' && this.paymentDetails.transaction_type.title == 'Percent' ? msg.replace('$________', this.paymentDetails.totalAmountOfTransaction) : msg.replace('$________  of your value', 'in an amount of ' + this.paymentDetails.totalAmountOfTransaction);
        msg = msg.replace('_______', this.paymentDetails.effective_date);        
        this.confirmMessage = msg;
      })
    }
  }

  // Confirmation script get call and display for SWP
  swpConfirmationScript(paymentDetails, scripts) {
    this.paymentDetails = paymentDetails;
    this.enableProcessBtn();
    if (this.authsucess || this.otherRoleSucess) {
      let withHoldOverrideInd = (this.paymentDetails && this.getValueOfField(this.swpDataModal, 'federal_withholding_override') == "Y" || this.paymentDetails && this.getValueOfField(this.swpDataModal, 'state_withholding_override') == "Y") ? "Y" : "N";
      let noWithHoldInd = (this.paymentDetails && this.getValueOfField(this.swpDataModal, 'no_federal_withholding') == "Y" || this.paymentDetails && this.getValueOfField(this.swpDataModal, 'no_state_withholding') == "Y") ? "Y" : "N";
      let url = scripts && scripts.split('?')[0] + "?callerRole=" + OcInfraModule.AppInjector.get(CommonService).authorizedRole + "&noWithHoldInd=" + noWithHoldInd + "&withHoldOverrideInd=" + withHoldOverrideInd + "&paymentMethod=" + this.paymentDetails.disbursement.enum;
      OcInfraModule.AppInjector.get(CommonService).getCallback(url, this.headers).subscribe(response => {
        let msg = response.confirm_message;
        if (withHoldOverrideInd) {
          msg = msg.replace('____%', this.getValueOfField(this.swpDataModal, 'state_withholding_percentage'))
          msg = msg.replace('___%', this.getValueOfField(this.swpDataModal, 'federal_withholding_percentage'))
        }
            msg = msg.replace('$________  of your value', this.paymentDetails.totalAmountOfTransaction)
            msg = msg.replace('_______', this.getSWPConfirmationValue(this._swpDataModal, 'frequency'))
            msg = msg.replace('_______.', this.datePipe.transform(this.getSWPConfirmationValue(this._swpDataModal, 'next_date'), 'MM/dd/yyyy') + '.')
            msg = msg.replace('on _______', 'on ' + this.surrenderDetails.effective_date)
        this.confirmMessage = msg;
      })
    }
  }

  confirmationValuesFullSurrenderCV15 = (paymentDetails) => {
    this.paymentDetails = paymentDetails;
    // this.enableProcessBtn();
  }

  confirmationValuesFullSurrenderLife = (paymentDetails) => {
    this.paymentDetails = paymentDetails;
    // this.enableProcessBtn();
  }

  confirmationValuesPartialSurrender = (paymentDetails) => {
    this.paymentDetails = paymentDetails;
    // this.enableProcessBtn();
  }

  confirmationValuesSystematicWithdrawalCV15 = (paymentDetails) => {
    this.paymentDetails = paymentDetails;
    this.enableProcessBtnSWP()
  }

  filterOptionalConfirmationValues(element) {
    if (element.value == '$0.00') {
      const isValid = element.id === 'gmib_fee_amount' || element.id === 'edb_fee_amount' || element.id === 'gwb_lwg_fee_amount' || element.id === 'odb_fee_amount' || element.id === 'gmab_fee_amount' ? false : true;
      return isValid;
    } else if (element.value == undefined || element.value == "") {
      return false;
    } else {
      return true;
    }
  }

  createPayLoad() {
    this.payload = {};
    let header = _.filter(Configuration.config.headers["multiHostUrlHeader"], function (o) { return o.alias == 'workRequestHistory' });
    const authResponse = this.resourceService.getResourceFromAliasName('authRes');
    let policyDetails = this.resourceService.getResourceFromAliasName('policyDetails');
    const selectedRoleSummary = this.resourceService.getResourceFromAliasName('selectedRoles');
    let roleList = this.resourceService.getResourceFromAliasName('roles');
    let otherRoleAuthorize = _.isEmpty(selectedRoleSummary.data) ? _.filter(roleList._links.item, function (o) { return o.summary.role == 'Owner'; }) : false;
    let transactionFields = {
      "type": this.withdrawlSurrenderType == 'PARTIAL_SURRENDER' ? 'PARTIAL SURRENDER' : 'FULL SURRENDER',
      "hostKey": otherRoleAuthorize ? otherRoleAuthorize[0] && otherRoleAuthorize[0].summary.hostKey ? otherRoleAuthorize[0].summary.hostKey : '' : selectedRoleSummary && selectedRoleSummary.data.hostKey ? selectedRoleSummary.data.hostKey : '',
      "sessionId": authResponse && authResponse.data.contactSessionID ? authResponse.data.contactSessionID : '',
      "callStart": authResponse && authResponse.data.callStart ? authResponse.data.callStart : '',
      "clientId": otherRoleAuthorize ? otherRoleAuthorize[0] && otherRoleAuthorize[0].summary.clientId ? otherRoleAuthorize[0].summary.clientId : '' : selectedRoleSummary && selectedRoleSummary.data.clientId ? selectedRoleSummary.data.clientId : '',
      "userId": OcInfraModule.AppInjector.get(CommonService).incontactId,
      "policyNumber": this.otherRoleSucess ? policyDetails.data.policy_number : selectedRoleSummary.data.policyNumber,
      'company_code': policyDetails.data.company_code,
      'region_code': header[0].headers.region_code,
      'remote_user': header[0].headers.remote_user,
      "origin_app": "csr_portal",
    }
    for (const property in Payload) {
      if (this.paymentDetails.hasOwnProperty(property) && typeof this.paymentDetails[property] == 'object' ? true : this.paymentDetails[property] !== undefined) {
        this.payload[property] = typeof this.paymentDetails[property] == 'object' ? this.paymentDetails[property].enum : this.paymentDetails[property].replace(/,|\s/g, "").replace("%", "");
      } else {
        this.surrenderDetails.hasOwnProperty(property) ? typeof this.surrenderDetails[property] == 'object' ? null : this.payload[property] = this.surrenderDetails[property] : null;
      }
    }
    this.withdrawlSurrenderType == "FULL_SURRENDER" ? this.payload.agent_recommend_or_authorized = this.paymentDetails.agent_recommend_or_authorized : null;
    this.payload.payment_method = [];
    this.payload.distribution_code = this.surrenderDetails.distribution_code && this.surrenderDetails.distribution_code.substring(0, 1);
    this.paymentDetails && this.paymentDetails.fundValues.length ? this.paymentDetails.transaction_level && this.paymentDetails.transaction_level.enum == 'F' ? this.payload.fundValues = this.paymentDetails.fundValues : null : null;
    //  this.paymentDetails.transaction_level && this.paymentDetails.transaction_level.enum == 'F' ? this.paymentDetails.fundValues.length ? this.payload.fundValues = this.paymentDetails.fundValues : null : null;
    this.payload.effective_date = this.paymentDetails && this.datePipe.transform(this.paymentDetails.effective_date, "MM/dd/yyyy");
    this.paymentDetails && this.paymentDetails.disbursement && this.paymentDetails.disbursement.enum ? this.payload.payment_method.push(this.paymentDetails.disbursement.enum) : null;
    this.payload.confirm_override = "";
    this.payload.fedExAccountComment = this.paymentDetails.fedExAccountComment;
    this.payload.next_business_day = this.isNextBusinessDay === false ? '' : 'y';

    for (let propName in this.payload) {
      typeof this.payload[propName] === 'string' ? this.payload[propName] = this.payload[propName].replace('$', '') : null;
    }
    if (this.payload && this.payload.account_type) {
      this.payload.account_type = this.payload.account_type.split("-")[0];
    }
    Object.assign(this.payload, transactionFields);
    // as per OCCD-3002 net_gross_indicator is N it should be M on wMA. Thi sis for only Full and Partial
    if (this.payload.net_gross_indicator && this.payload.net_gross_indicator == "N") {
      this.payload.net_gross_indicator = "M";
    }
  }

  createAWDPayload() {
    const selectedRoleSummary = this.resourceService.getResourceFromAliasName('selectedRoles');
    const authResponse = this.resourceService.getResourceFromAliasName('authRes');
    let policyDetails = this.resourceService.getResourceFromAliasName('policyDetails');
    let commentsValue = '';
    if (this.partialSurrenderConfirmationValues) {
      for (let i = 0; i < this.partialSurrenderConfirmationValues.length; i++) {
        commentsValue += this.partialSurrenderConfirmationValues[i].label + ' ' + this.partialSurrenderConfirmationValues[i].value + ',';
      }
    }
    this.comments = commentsValue.slice(0, -1);
    const payload = {
      "status": "CREATED",
      "CLTY": this.withdrawlSurrenderType == 'PARTIAL_SURRENDER' ? 'WITHDRAW' : 'SURR',
      "POLN": selectedRoleSummary && selectedRoleSummary.data.policyNumber ? selectedRoleSummary.data.policyNumber : policyDetails.data.policy_number,
      "comments": this.comments,
      "hostKey": selectedRoleSummary && selectedRoleSummary.data.hostKey ? selectedRoleSummary.data.hostKey : '',
      "type": this.withdrawlSurrenderType == 'PARTIAL_SURRENDER' ? 'Withdrawal Request/Partial Surrender' : 'Surrender Request',
      "sessionId": authResponse && authResponse.data.contactSessionID ? authResponse.data.contactSessionID : '',
      "callStart": authResponse && authResponse.data.callStart ? authResponse.data.callStart : '',
      "clientId": selectedRoleSummary && selectedRoleSummary.data.clientId ? selectedRoleSummary.data.clientId : '',
      "userId": OcInfraModule.AppInjector.get(CommonService).incontactId,
      "origin_app": "csr_portal"
    };
    return payload;
  }

  // Makes a POST call to Create a WORK REQUEST
  createAWDWorkType(href, payload) {
    let headers = OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("workRequestHistory");
    OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false })
    OcInfraModule.AppInjector.get(CommonService).postCallback(href, payload, headers).subscribe(response => {
      if (response.success) {
        OcInfraModule.AppInjector.get(AdvGrowlService).createSuccessMessage(response.message, 'Success');
        document.getElementById("modal__cross").click();
      } else {
        OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage("Something went wrong", 'Error', 0);
      }
    }, error => {
      OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage("Something went wrong", 'Error', 0);
    });
  }

  refreshPendingTransactions() {
    const resourceService = OcInfraModule.AppInjector.get(ResourceService);
    let pendingTransactions = resourceService.getResourceFromAliasName('pendingTransactions');
    let pendingTransactionGETURL = "";
    let headers;
    if (!pendingTransactions) {
      let policyDetails = resourceService.getResourceFromAliasName('policyDetails') ? resourceService.getResourceFromAliasName('policyDetails').response : null;
      pendingTransactionGETURL = policyDetails._links.self.href + "/pendingTransactions";
      headers = OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("policyDetails");
    } else {
      pendingTransactionGETURL = pendingTransactions._links.self.href;
      headers = OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("pendingTransactions");
    }

    OcInfraModule.AppInjector.get(APICallerService).refresh(pendingTransactionGETURL, { 'alias': "pendingTransactions", 'headers': headers }).subscribe(refreshResponse => {

    });
  }

  //when modal is loaded from pending trnasaction edit click  and  user clicks on submit to update existing data collect record.
  updatePendingTransaction() {

    const resourceService = OcInfraModule.AppInjector.get(ResourceService);
    let pendingTransactions = resourceService.getResourceFromAliasName('pendingTransactions');
    let pendingTransactionItems = pendingTransactions._links.item;
    let selectedPend = pendingTransactionItems.find(item => item.summary.trx_key == this.withdrawalSurrenderAction.getTrxKey())
    let patchLink = this.surrenderDetails._options.links.find(link => link.method.toLowerCase() == "patch");

    if (this.withdrawalSurrenderAction.isSystematicWithdrawal())
      this.createSWPRequestBody()
    else
      this.createPayLoad();

    OcInfraModule.AppInjector.get(CommonService).patchCallback(patchLink.href, this.payload, { ...this.headers, "status": selectedPend.summary.status ? selectedPend.summary.status : '' }).subscribe(response => {
      // Success Scenario
      if (response.confirm_override === '') {
        // The stock market is closed will be completed on next business day- warning for Full/partial - If confirm_override === '' && embedded warning
        if ( response._embedded && response._embedded.status_report && response._embedded.status_report.messages && response._embedded.status_report.messages.length > 0) {
          response._embedded.status_report.messages.forEach(element => {
          let alertMsg = {
            title: element.severity,
            type: 'warning',
            body: element.message,
            showCloseButton: true,
            timeout: 0,
            tapToDismiss: false,
          }
          this.toasterService.pop(alertMsg);
          });
        }
        response.confirm_override == "" ? OcInfraModule.AppInjector.get(AdvGrowlService).createSuccessMessage('Pending Transaction Updated Successfully', 'Success') : null;
        document.getElementById("modal__cross").click();
        // on sucess refresh pending transactions tab under policyDetails so CSR can view latest datacollector record.
        this.refreshPendingTransactions();
      } else if (response._embedded && response._embedded.status_report && response._embedded.status_report.messages && response._embedded.status_report.messages.length > 0) {
          response._embedded.status_report.messages.forEach(element => {
            let alertMsg = {
              title: element.severity,
              type: "error",
              body: element.message,
              showCloseButton: true,
              timeout: 0
            }
            this.toasterService.pop(alertMsg);
          });
        }  
    }, error => {
      OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error._embedded && error._embedded.status_report && error._embedded.status_report.messages[0].message, 'Error', 0);
    });
  }




  createTransaction(clicked) {
    // Disables the button in case the amount is not within the allowable range
    if (this.withdrawlSurrenderType == 'FULL_SURRENDER' && !this.isAmountAllowable()) {
      this.disableProcessBtn = true;
      let alertMsg = [];
      alertMsg.push("Amount Exceeds Allowable by Telephone");
      let dialogRef = OcInfraModule.AppInjector.get(CommonService).showConfirmation({ content: alertMsg, custom: true }, 'CLOSE', '', 'ALERT', '393px');
      dialogRef.afterClosed().subscribe(res => {
        res;
      });
    } else {
      if (this.paymentDetails.agent_recommend_or_authorized && this.paymentDetails.agent_recommend_or_authorized == 'Y' || this.paymentDetails.overnight && this.paymentDetails.overnight == 'Y') {
        let workRequestHref = this.resourceService.getHrefByAliasName("workRequestHistory").split('?')[0];
        let workTypePayload = this.createAWDPayload();
        this.createAWDWorkType(workRequestHref, workTypePayload);
      } else if (this.enableAWDFlow) {
        this.surrenderWorkRequestPayload.comments = this.comments;
        this.createAWDWorkType(this.workRequestHref, this.surrenderWorkRequestPayload);
      } else {
        OcInfraModule.AppInjector.get(AdvGrowlService).clearMessages();
        clicked ? this.createPayLoad() : null;
        let url = this.policyDetails && this.policyDetails['_links'][this.type[this.withdrawlSurrenderType]].href;
        OcInfraModule.AppInjector.get(CommonService).postCallback(url, this.payload, this.headers).subscribe(response => {
          switch (response.confirm_override) {
            // AWD scenario
            case 'W': {
              this.displayAlerts(response, "error");
              for (let i = 0; i < response._options.links.length; i++) {
                if (response._options.links[i].method == 'POST') {
                  this.enableAWDFlow = true;
                  this.workRequestHref = response._options.links[i].href;
                  this.surrenderWorkRequestPayload = this.createAWDPayload();
                }
              }
              break;
            }
            // soft error scenario
            case 'Y': {
              this.displayAlerts(response, "error");
              let content = 'Are you sure?  You want to override ?';
              let title = 'Alert';
              let dialogRef = OcInfraModule.AppInjector.get(CommonService).showConfirmationPopup(content, 'No', 'Yes', title, '340px');
              dialogRef.afterClosed().subscribe(res => {
                if (res) {
                  this.payload.confirm_override = "Y"
                  this.payload.next_business_day = response.next_business_day;
                  this.payload.effective_date = response.effective_date;
                  this.createTransaction(false);
                }
              });
              break;
            }
            // hard error scenario
            case 'N': {
              this.displayAlerts(response, "error");
              this.disableProcessBtn = true;
              break;
            }
            // success scenario
            case '': {
              if (response._embedded && response._embedded.status_report && response._embedded.status_report.messages && response._embedded.status_report.messages.length > 0) {
                //clear existing alerts and then display the warning
                this.toasterService.clear();
                this.displayAlerts(response, "warning");
              }
              OcInfraModule.AppInjector.get(CommonService).confirmation = true;
              OcInfraModule.AppInjector.get(AdvGrowlService).createSuccessMessage('Transaction Processed Successfully', 'Success');
              document.getElementById("modal__cross").click();
              // on sucess refresh pending transactions tab under policyDetails so CSR can view latest datacollector record.
              this.refreshPendingTransactions();
              this.populateFullAndPartialCallHistoryDetails();
              break;
            }
          }
        }, error => {
          OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error._embedded && error._embedded.status_report && error._embedded.status_report.messages[0].message, 'Error', 0);
        })
      }
    }
  }

  displayAlerts(response, type) {
    if ( response._embedded && response._embedded.status_report && response._embedded.status_report.messages && response._embedded.status_report.messages.length > 0) {
      let fillteredData =response._embedded.status_report.messages.filter(x=>x.message !== "No mapping rule matched");
      fillteredData.forEach(element => {
        let alertMsg = {
          title: element.severity,
          type: type,
          body: element.message,
          showCloseButton: true,
          timeout: 0,
          tapToDismiss: false,
        }
        this.toasterService.pop(alertMsg);
      });
    }
  }

  isAmountAllowable() {
    for (let i = 0; i < this.partialSurrenderConfirmationValues.length; i++) {
      if (this.partialSurrenderConfirmationValues[i].label == 'Transaction Amount/Percent:') {
        return Number(this.partialSurrenderConfirmationValues[i].value.replace(/,|\s/g, "").replace("$", "")) > Number(29999.99) ? false : true;
      }
    }
    return true;
  }

  isAmountAllowableAlertFull() {
    if(this.withdrawlSurrenderType == 'FULL_SURRENDER') {
        if(!this.isLife && this.partySearchFactory.isOnlyGroupAnn() && this.isMrpsContract && Number(this.confirmationObj.childQuote.surrenderDetails.cash_surrender_value) > 29999.99) {
            return true;
        } else if(!this.isLife && Number(this.confirmationObj.childQuote.surrenderDetails.surrender_amount) > 29999.99) {
            return true;
        } else if (this.isLife) {
            let surrender_amount = this.surrenderDetails.net_check_amount ? Number(this.surrenderDetails.net_check_amount) : 0;
            if (surrender_amount > 24999.99) {
                return true
            } else {
                return false
            }
        } else {
            return false
        }
    }
    else if(this.withdrawlSurrenderType == 'PARTIAL_SURRENDER'){
      let surrender_amount = (this.confirmationObj.childSurrender.getSurrenderValues().transaction_amount.replace('$',"").replace(',',""));
      if(surrender_amount>29999.99){
        return true;
      } else {
        return false;
      }
    }
    else{
      return false;
    }
  }

  populateFullAndPartialCallHistoryDetails(){
    let callNoteObj = {};
    for (const property in this.ConfirmationObj[this.withdrawlSurrenderType]) {
      if (this.payload.hasOwnProperty(property)) {
        // IF payload property is a object
        if (typeof this.payload[property] == 'object') {
          switch (property) {
            case 'payment_method':
              callNoteObj[this.ConfirmationObj[this.withdrawlSurrenderType][property]] = this.paymentDetails.disbursement.title;
            break;
            case 'fundValues':
              const fundValues = this.payload[property];
              const formatedFundValues = [];
              if (fundValues && fundValues.length > 0) {
                fundValues.forEach(fund => {
                  const fundValue = {};
                  if(fund['surrender_percent'] && fund['surrender_percent'] !== '0.0' || fund['surrender_amt'] && fund['surrender_amt'] !== '0.00') {
                  fundValue['Fund Number'] = fund['fund_number']
                  fundValue['Fund Name'] = fund['fund_name'];
                  fundValue['Fund Value'] = fund['fund_value'] ? this.formatCurrency(fund['fund_value'].toString()) : '$0.00';
                    if(this.paymentDetails.transaction_type.title == 'Amount') {
                      fundValue['Fund Amount'] = fund['surrender_amt'] ? this.formatCurrency(fund['surrender_amt']) : '$0.00';
                    } else {
                      fundValue['Fund Percentage'] = fund['surrender_percent'] ? fund['surrender_percent'] + "%" : '0.0%';
                    }
                  formatedFundValues.push(fundValue);
                  }
                });
              }
              callNoteObj[this.ConfirmationObj[this.withdrawlSurrenderType][property]] = formatedFundValues;
            break;
          }
        } else {
          // if payload property is not an object
            switch (property) {
              case 'effective_date' :
                callNoteObj[this.ConfirmationObj[this.withdrawlSurrenderType][property]] = this.payload[property];
              break;
              case 'transaction_level':
                callNoteObj[this.ConfirmationObj[this.withdrawlSurrenderType][property]] = this.paymentDetails.transaction_level_view_value;
              break;
              case 'surrender_amount':
                callNoteObj[this.ConfirmationObj[this.withdrawlSurrenderType][property]] = this.formatCurrency(this.payload[property]);
              break;
              case 'surrender_percent':
                this.payload[property] != '0.0' ?
                (callNoteObj[this.ConfirmationObj[this.withdrawlSurrenderType][property]] = this.payload[property] + "%") : delete callNoteObj[this.ConfirmationObj[this.withdrawlSurrenderType][property]];
                break;
              case 'federal_withholding_override':
                if(this.payload[property] == 'Y') {
                  let percentValue = Number(this.paymentDetails.federal_withholding_percentage.replace(/,|\s/g, "").replace("%", ""));
                  callNoteObj[this.ConfirmationObj[this.withdrawlSurrenderType][property]] = percentValue > 0 ?  this.paymentDetails.federal_withholding_percentage : this.paymentDetails.federal_withholding_amount;
                } else {
                  callNoteObj[this.ConfirmationObj[this.withdrawlSurrenderType][property]] = 'None';
                }
              break;
              case 'state_withholding_override':
                if(this.payload[property] == 'Y') {
                  let percentValue = Number(this.paymentDetails.state_withholding_percentage.replace(/,|\s/g, "").replace("%", ""));
                  callNoteObj[this.ConfirmationObj[this.withdrawlSurrenderType][property]] = percentValue > 0 ?  this.paymentDetails.state_withholding_percentage : this.paymentDetails.state_withholding_amount;
                } else {
                  callNoteObj[this.ConfirmationObj[this.withdrawlSurrenderType][property]] = 'None';
                }
              break;
              case 'bank_name':
                callNoteObj['Payment Method'] === 'EFT' ?
                (callNoteObj[this.ConfirmationObj[this.withdrawlSurrenderType][property]] = this.surrenderDetails.bank_name) :
                delete callNoteObj[this.ConfirmationObj[this.withdrawlSurrenderType][property]];
              break;
              case 'account_type':
                callNoteObj['Payment Method'] === 'EFT' ?
                (callNoteObj[this.ConfirmationObj[this.withdrawlSurrenderType][property]] = this.surrenderDetails.account_type):
                delete callNoteObj[this.ConfirmationObj[this.withdrawlSurrenderType][property]];
              break;
            }
        }
      } else {
        // if the property is not in the payload
        switch (property) {
          case 'Full_Payee_Name':
            callNoteObj['Payment Method'] === 'Cash' ?
            (callNoteObj[this.ConfirmationObj[this.withdrawlSurrenderType][property]] = this.paymentDetails.payee_firstname + '' + this.paymentDetails.payee_lastname):
            delete callNoteObj[this.ConfirmationObj[this.withdrawlSurrenderType][property]];
          break;
          case 'Full_Mailing_Address':
            callNoteObj['Payment Method'] === 'Cash' ?
            (callNoteObj[this.ConfirmationObj[this.withdrawlSurrenderType][property]] =`${this.paymentDetails.payee_address1 ? this.paymentDetails.payee_address1 + ', ' : '' } 
            ${this.paymentDetails.payee_address2 ? this.paymentDetails.payee_address2 + ', ' : ''} 
            ${this.paymentDetails.payee_address3 ? this.paymentDetails.payee_address3 + ', ' : ''}
            ${this.paymentDetails.payee_city ? this.paymentDetails.payee_city + ', ' : ''}
            ${this.paymentDetails.payee_state ? this.paymentDetails.payee_state + ', ' : ''}
            ${this.paymentDetails.payee_country ? this.paymentDetails.payee_country + ', ' : ''}
            ${this.paymentDetails.payee_zip ? this.paymentDetails.payee_zip : ''}`):
            delete callNoteObj[this.ConfirmationObj[this.withdrawlSurrenderType][property]];
          break;
          case 'payee_name':
            callNoteObj['Payment Method'] === 'EFT' ?
            (callNoteObj[this.ConfirmationObj[this.withdrawlSurrenderType][property]] = this.surrenderDetails.payeeName):
            delete callNoteObj[this.ConfirmationObj[this.withdrawlSurrenderType][property]];
          break;
          case 'confirmationNumber':
            callNoteObj[this.ConfirmationObj[this.withdrawlSurrenderType][property]] = OcInfraModule.AppInjector.get(CommonService).authResponse.updateResponse.data.confirmationNumber;
          break;
          case 'Requested_Date':
            callNoteObj[this.ConfirmationObj[this.withdrawlSurrenderType][property]] = this.datePipe.transform(moment(this.requestedDate).tz('America/New_York'), 'MM/dd/yyyy HH:mm');
          break;
          case 'Confirmation_Date':
            callNoteObj[this.ConfirmationObj[this.withdrawlSurrenderType][property]] = this.datePipe.transform(moment(this.confirmationDate).tz('America/New_York'), 'MM/dd/yyyy HH:mm');
          break;
          case 'owner':
            callNoteObj[this.ConfirmationObj[this.withdrawlSurrenderType][property]] = this.ownerName;
          break;
          case 'type_of_transaction':
            callNoteObj[this.ConfirmationObj[this.withdrawlSurrenderType][property]] = this.withdrawlSurrenderType == 'FULL_SURRENDER' ? 'Full Surrender' : 'Partial Surrender';
          break;
          case 'gross_check_amount' :
            callNoteObj[this.ConfirmationObj[this.withdrawlSurrenderType][property]] = this.formatCurrency(this.paymentDetails.gross_check_amount);
          break;
          case 'administrative_fee':
          case 'surrender_penalty':
          case 'gmib_fee_amount':
          case 'edb_fee_amount':
          case 'gwb_lwg_fee_amount':
          case 'odb_fee_amount':
          case 'gmab_fee_amount':
          case 'total_mva_amount':
            for(let i=0; i< this.partialSurrenderConfirmationValues.length; i++) {
              if (this.partialSurrenderConfirmationValues[i].id == property) {
                let feeValue = Number(this.partialSurrenderConfirmationValues[i].value.replace(/,|\s/g, "").replace("$", ""));
                feeValue > 0 ? callNoteObj[this.ConfirmationObj[this.withdrawlSurrenderType][property]] = this.partialSurrenderConfirmationValues[i].value :
                delete callNoteObj[this.ConfirmationObj[this.withdrawlSurrenderType][property]];
              }
            }
          break;
        }
      }
    }
    if (this.withdrawlSurrenderType == "FULL_SURRENDER") {
      Object.assign(callNoteObj, OcInfraModule.AppInjector.get(CommonService).fullSurrenderFields);
      Object.assign(OcInfraModule.AppInjector.get(CommonService).allTransactions, { 'FULL SURRENDER': callNoteObj });
    }
    if (this.withdrawlSurrenderType == "PARTIAL_SURRENDER") {
      Object.assign(OcInfraModule.AppInjector.get(CommonService).allTransactions, { 'PARTIAL SURRENDER': callNoteObj });
    }
  }

  populateSwpCallHistorydetails(){
    let callNoteObj = {};
    if (this.withdrawlSurrenderType == "SYSTEMATIC_WITHDRAWL") {     
      for (const property in this.ConfirmationObj[this.withdrawlSurrenderType]) {     
        if (this.payload.hasOwnProperty(property)) {
          // If payload property is a object
          if (typeof this.payload[property] == 'object') {
            switch (property) {
              case 'payment_method':
                callNoteObj[this.ConfirmationObj[this.withdrawlSurrenderType][property]] = this.paymentDetails.disbursement.title;
                break;
              case 'fundValues':
                const fundValues = this.payload[property];
                const formatedFundValues = [];
                if (fundValues && fundValues.length > 0) {
                  fundValues.forEach(fund => {
                    const fundValue = {}; 
                    if(fund['surrender_unit'] && fund['surrender_unit'] !== '0.00' || fund['surrender_amt'] && fund['surrender_amt'] !== '0.00') {
                      fundValue['Fund Number'] = fund['fund_number']
                      fundValue['Fund Name'] = fund['fund_name'];
                      fundValue['Fund Value'] = fund['fund_value'] ? this.formatCurrency(fund['fund_value']): '$0.00';

                      if (this.payload["option"] === 'U' || this.payload["option"] === 'V') {
                        fundValue['Fund Unit'] = fund['surrender_unit'];
                      } else {
                        fundValue['Fund Amount'] = fund['surrender_amt'] ? this.formatCurrency(fund['surrender_amt']): '$0.00';
                      }
                      formatedFundValues.push(fundValue);
                    }
                  });
                }
                this.payload.transaction_level === 'F'? callNoteObj[this.ConfirmationObj[this.withdrawlSurrenderType][property]] = formatedFundValues
                : delete callNoteObj[this.ConfirmationObj[this.withdrawlSurrenderType][property]];
            }
          }
          else {
           // if payload property is not a object
            switch (property) {
              case 'transaction_level':
                callNoteObj[this.ConfirmationObj[this.withdrawlSurrenderType][property]] = this.getSWPConfirmationValue(this._swpDataModal, 'transaction_level');
                break;
              case 'frequency':
                callNoteObj[this.ConfirmationObj[this.withdrawlSurrenderType][property]] = this.getSWPConfirmationValue(this._swpDataModal, 'frequency');
                break;
              case 'payout_percent':
                this.payload[property] != '0.0' ?
                (callNoteObj[this.ConfirmationObj[this.withdrawlSurrenderType][property]] = this.payload[property] + "%") : delete callNoteObj[this.ConfirmationObj[this.withdrawlSurrenderType][property]];
                break;
              case 'withdrawal_amount':
                callNoteObj[this.ConfirmationObj[this.withdrawlSurrenderType][property]] = this.formatCurrency(this.payload[property]);
                break;
              case 'federal_withholding_percentage':
              case 'state_withholding_percentage':
                callNoteObj[this.ConfirmationObj[this.withdrawlSurrenderType][property]] = this.payload[property] != '0.0' ? this.payload[property] + "%" : 'None';
                break;
              default:
                callNoteObj[this.ConfirmationObj[this.withdrawlSurrenderType][property]]  = this.payload[property];
                break;
            }
          }

        }
        else {
          // if the property is not in the payload
          switch (property) {
            case 'confirmationNumber':
              callNoteObj[this.ConfirmationObj[this.withdrawlSurrenderType][property]] = OcInfraModule.AppInjector.get(CommonService).authResponse.updateResponse.data.confirmationNumber;
              break;
            case 'transactionType':
              callNoteObj[this.ConfirmationObj[this.withdrawlSurrenderType][property]] = this.surrenderDetails.wma_transaction_code === 'GK' ? 'Add SWP' : (this.surrenderDetails.wma_transaction_code === 'LPOI' ? 'SWP Modify' : this.surrenderDetails.wma_transaction_code);
              break;
            case 'Full_Payee_Name':
              callNoteObj['Payment Method'] === 'Cash' ?
              (callNoteObj[this.ConfirmationObj[this.withdrawlSurrenderType][property]] = this.paymentDetails.payee_firstname + '' + this.paymentDetails.payee_lastname):
              delete callNoteObj[this.ConfirmationObj[this.withdrawlSurrenderType][property]];
              break;
              case 'Full_Mailing_Address':
                callNoteObj['Payment Method'] === 'Cash' ?
                (callNoteObj[this.ConfirmationObj[this.withdrawlSurrenderType][property]] =`${this.paymentDetails.payee_address1 ? this.paymentDetails.payee_address1 + ', ' : '' } 
                ${this.paymentDetails.payee_address2 ? this.paymentDetails.payee_address2 + ', ' : ''} 
                ${this.paymentDetails.payee_address3 ? this.paymentDetails.payee_address3 + ', ' : ''}
                ${this.paymentDetails.payee_city ? this.paymentDetails.payee_city + ', ' : ''}
                ${this.paymentDetails.payee_state ? this.paymentDetails.payee_state + ', ' : ''}
                ${this.paymentDetails.payee_country ? this.paymentDetails.payee_country + ', ' : ''}
                ${this.paymentDetails.payee_zip ? this.paymentDetails.payee_zip : ''}`):
                delete callNoteObj[this.ConfirmationObj[this.withdrawlSurrenderType][property]];
                break;
            case 'Requested_Date':
              callNoteObj[this.ConfirmationObj[this.withdrawlSurrenderType][property]] = this.datePipe.transform(moment(this.requestedDate).tz('America/New_York'), 'MM/dd/yyyy HH:mm');
              break;
            case 'Confirmation_Date':
              callNoteObj[this.ConfirmationObj[this.withdrawlSurrenderType][property]] = this.datePipe.transform(moment(this.confirmationDate).tz('America/New_York'), 'MM/dd/yyyy HH:mm');
              break;
            case 'owner':
              callNoteObj[this.ConfirmationObj[this.withdrawlSurrenderType][property]] = this.ownerName;
              break;
            case 'payee_name':
              callNoteObj['Payment Method'] === 'EFT' ?
              callNoteObj[this.ConfirmationObj[this.withdrawlSurrenderType][property]] = this.surrenderDetails.payeeName :
              delete callNoteObj[this.ConfirmationObj[this.withdrawlSurrenderType][property]];
              break;
            case 'bank_name':
              callNoteObj['Payment Method'] === 'EFT' ?
              callNoteObj[this.ConfirmationObj[this.withdrawlSurrenderType][property]] = this.surrenderDetails.bank_name : delete callNoteObj[this.ConfirmationObj[this.withdrawlSurrenderType][property]];
              break;
            case 'account_type':
              callNoteObj['Payment Method'] === 'EFT' ?
              callNoteObj[this.ConfirmationObj[this.withdrawlSurrenderType][property]] = this.surrenderDetails.account_type : delete callNoteObj[this.ConfirmationObj[this.withdrawlSurrenderType][property]];
              break;
          
          }
        }
      }
      if (this.sysIndicator == "updateWithdrawl") {
       Object.assign(OcInfraModule.AppInjector.get(CommonService).allTransactions, { 'SYSTEMATIC WITHDRAWAL UPDATE': callNoteObj }); 
    }

      if (this.sysIndicator == 'Withdrawl') {
        Object.assign(OcInfraModule.AppInjector.get(CommonService).allTransactions, { 'SYSTEMATIC WITHDRAWAL': callNoteObj }); 
      }
      if (this.sysIndicator == 'deleteWithdrawl') {
        this.populateSwpTerminateCallHistoryDetails();
      }
    }
  }

  populateSwpTerminateCallHistoryDetails(){
    let callNoteObj = {};
      for (const property in this.deleteSystematic[this.withdrawlSurrenderType]) {
        if (this.payload.hasOwnProperty(property)) {
          if (typeof this.payload[property] == 'object') {
            callNoteObj[this.deleteSystematic[this.withdrawlSurrenderType][property]] = this.payload[property][0]
          }
          else {
            callNoteObj[this.deleteSystematic[this.withdrawlSurrenderType][property]] = this.payload[property]
          }
        }
          else {
             // if the property is not in the payload
            switch (property) {
              case 'confirmationNumber':
                callNoteObj[this.deleteSystematic[this.withdrawlSurrenderType][property]] = OcInfraModule.AppInjector.get(CommonService).authResponse.updateResponse.data.confirmationNumber;
                break;
              case 'Requested_Date':
                callNoteObj[this.deleteSystematic[this.withdrawlSurrenderType][property]] = this.datePipe.transform(moment(this.requestedDate).tz('America/New_York'), 'MM/dd/yyyy HH:mm');
                break;
              case 'Confirmation_Date':
                callNoteObj[this.deleteSystematic[this.withdrawlSurrenderType][property]] = this.datePipe.transform(moment(this.confirmationDate).tz('America/New_York'), 'MM/dd/yyyy HH:mm');
                break;
              case 'owner':
                callNoteObj[this.ConfirmationObj[this.withdrawlSurrenderType][property]] = this.ownerName;
                break;
              case 'transactionType':
                callNoteObj[this.ConfirmationObj[this.withdrawlSurrenderType][property]] = 'SWP Terminated'
                break;
              default:
                callNoteObj[this.deleteSystematic[this.withdrawlSurrenderType][property]] = this.payload[property]
            }
          }           
        }
      Object.assign(OcInfraModule.AppInjector.get(CommonService).allTransactions, { 'SYSTEMATIC WITHDRAWAL REMOVE': callNoteObj });   
  }

  
  getNested(obj, ...args) {
    return args.reduce((obj, level) => obj && obj[level], obj)
}
}
