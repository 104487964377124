import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import * as _ from 'lodash';
import { ItemAssetAccount, ItemProducts, RmdQuoteDetails } from '../constant';
import { DatePipe, CurrencyPipe } from '@angular/common';
import { CommonService, retryOperation } from '../../../services/common.service';
import { OcInfraModule, ResourceService } from '@diaas/ux-web';
import { HttpParams } from '@angular/common/http';
import { Item } from 'ionic-angular';
import * as moment from 'moment';
import { ToasterService } from 'angular2-toaster';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AdvGrowlService } from 'primeng-advanced-growl';
import { QuoteFormFieldModel, quoteRMDValuesID, quoteRMDModalValuesID, quoteRMDValuesLabels, quoteRMDModalValuesLabels,quoteRMDValues, quoteRMDModalValues } from './RMDQuoteconstant';

export interface SelectedFormValue {
    id: string
    label: string
    value: string
    checked?: string
    pipe?: string
    unique?: string
    key?: string
}

@Component({
    selector: 'app-rmd-quote',
    templateUrl: './RMDQuote.component.html',
    styleUrls: ['./RMDQuote.component.scss']
})


export class RMDQuoteComponent implements OnInit {

    @Input() quoteDetails: RmdQuoteDetails;
    @Output() getQuoteDetailsEmit = new EventEmitter<Object>();

    headers: any;

    effectiveDateCheck: boolean = true;
    effectiveDate: any;
    isReadonly: boolean = false;
    
    policyDetails: any;
    effectivedate: any;
    rmdQuoteValues: any;
    //   rmdQuoteDetails: any;
    dividendWithdrawalValue: any;
    effectiveMaxDate: any;
    checkedValues: any = [];
    alertMessages: any;

    distributionTypes: any[];
    beneRelList: any[];
    rmdRulesList: any[];
    calculationMethodList: any[];
    mvaOverrideList: any[];

    isGroupProduct: boolean = false;
    productCheck : boolean = false;
    clientProducts: ItemProducts[];
    assetAccounts: ItemAssetAccount[];

    clientProductItems: any;
    assetAccountItems: any;


    clientProductsUrl: string = '';
    assetAccountsUrl: string = '';
    rmdQuoteForm: FormGroup;
    rmdQuoteHref: string = '';

    retryDelay = 1000;
    retryAttempts = 3;
    maxlengthValue: string = "13";

    dobRequired = false;
    disableQuoteValues: boolean = false;
    //Dynamic
    quoteInfoFields!: QuoteFormFieldModel[];
    quoteValueFields!: QuoteFormFieldModel[];
    allFormValues: QuoteFormFieldModel[] = [];
    selectedFormValues: SelectedFormValue[] = [];
    formattedInfoFields: any = [];
    formattedValueFields: any = [];

    constructor(private datePipe: DatePipe, private currencyPipe: CurrencyPipe, private formBuilder: FormBuilder, private commonService: CommonService, private toasterService: ToasterService,) { }

    ngOnInit() {
        this.policyDetails = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyDetails') ? OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyDetails') : null;
        this.rmdQuoteFormInit();
        // localStorage.removeItem('rmd-quote-values');
        this.quoteInfoFields = quoteRMDValues();
        this.quoteValueFields = quoteRMDModalValues();
        this.allFormValues = [...this.quoteInfoFields, ...this.quoteValueFields];
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.quoteDetails.currentValue) {
            this.quoteDetails = changes.quoteDetails.currentValue;

            let hrefExist = this.quoteDetails._options.links.length && this.quoteDetails._options.links.find((item) => item.method == 'POST');
            this.disableQuoteValues = !hrefExist ? true : false;
            
            this.bindDataFromAPI();
            this.prepareInfoFormControls();
            this.prepareValueFormControls();
        }
    }


    rmdQuoteFormInit = () => {
        this.rmdQuoteForm = this.formBuilder.group({
            effective_date: ["", []],
            distribution_type: ["", []],
            bene_rel: ["", []],
            rmd_rules: ["", []],
            cal_method: ["", []],
            starting_lem: ["", []],
            mva_override: ["", []],
            rmd_override_amt: ["", []],
            bene_dob: ["", []]
        });

        if(this.quoteDetails) {
            this.effectivedate = new Date(this.quoteDetails.effective_date);
            this.effectiveMaxDate = new Date();
        }

        this.rmdQuoteForm.get('starting_lem').valueChanges.subscribe(value => {
            this.formatInput(value, 'input');
        });
    }

    get f() { return this.rmdQuoteForm.controls; }



    ngAfterViewInit() {
        this.headers = this.commonService.getHeaderByAliasName("rmdDetails");
        setTimeout(() => {
            this.isGroupProduct = this.isGroupProductType();
            if (this.isGroupProduct) {
                this.rmdQuoteForm.addControl("productType", new FormControl(""));
                this.rmdQuoteForm.addControl("accountType", new FormControl(""));
                this.processGroupProduct();
            }

        }, 0);
    }

    bindDataFromAPI() {
        let rmdQuoteResponseOptions = this.quoteDetails._options;

        this.distributionTypes = [];
        if (rmdQuoteResponseOptions && rmdQuoteResponseOptions.properties && rmdQuoteResponseOptions.properties.distribution_type.oneOf) {
            for (let i = 0; i < rmdQuoteResponseOptions.properties.distribution_type.oneOf.length; i++) {
                this.distributionTypes.push({ value: rmdQuoteResponseOptions.properties.distribution_type.oneOf[i].title, viewValue: rmdQuoteResponseOptions.properties.distribution_type.oneOf[i].title });
            }
        }
        this.beneRelList = [];
        if (rmdQuoteResponseOptions && rmdQuoteResponseOptions.properties && rmdQuoteResponseOptions.properties.bene_relation_code.oneOf) {
            for (let i = 0; i < rmdQuoteResponseOptions.properties.bene_relation_code.oneOf.length; i++) {
                this.beneRelList.push({ value: rmdQuoteResponseOptions.properties.bene_relation_code.oneOf[i].title, viewValue: rmdQuoteResponseOptions.properties.bene_relation_code.oneOf[i].title });
            }
        }
        this.rmdRulesList = [];
        if (rmdQuoteResponseOptions && rmdQuoteResponseOptions.properties && rmdQuoteResponseOptions.properties.rmd_rules.oneOf) {
            for (let i = 0; i < rmdQuoteResponseOptions.properties.rmd_rules.oneOf.length; i++) {
                this.rmdRulesList.push({ value: rmdQuoteResponseOptions.properties.rmd_rules.oneOf[i].title, viewValue: rmdQuoteResponseOptions.properties.rmd_rules.oneOf[i].title });
            }
        }
        this.calculationMethodList = [];
        if (rmdQuoteResponseOptions && rmdQuoteResponseOptions.properties && rmdQuoteResponseOptions.properties.cal_method.oneOf) {
            for (let i = 0; i < rmdQuoteResponseOptions.properties.cal_method.oneOf.length; i++) {
                this.calculationMethodList.push({ value: rmdQuoteResponseOptions.properties.cal_method.oneOf[i].title, viewValue: rmdQuoteResponseOptions.properties.cal_method.oneOf[i].title });
            }
        }
        this.mvaOverrideList = [];
        if (rmdQuoteResponseOptions && rmdQuoteResponseOptions.properties && rmdQuoteResponseOptions.properties.mva_override.oneOf) {
            for (let i = 0; i < rmdQuoteResponseOptions.properties.mva_override.oneOf.length; i++) {
                this.mvaOverrideList.push({ value: rmdQuoteResponseOptions.properties.mva_override.oneOf[i].title, viewValue: rmdQuoteResponseOptions.properties.mva_override.oneOf[i].title });
            }
        }


        this.effectivedate = new Date(this.quoteDetails.effective_date);
        this.effectiveMaxDate = new Date();
        this.rmdQuoteForm.get('effective_date').setValue(this.effectivedate);
        this.quoteDetails.distribution_type ? this.rmdQuoteForm.controls.distribution_type.setValue(this.quoteDetails.distribution_type) : this.rmdQuoteForm.controls.distribution_type.setValue('');
        this.quoteDetails.cal_method ? this.rmdQuoteForm.controls.cal_method.setValue(this.quoteDetails.cal_method) : this.rmdQuoteForm.controls.cal_method.setValue('');
        this.quoteDetails.rmd_rules ? this.rmdQuoteForm.controls.rmd_rules.setValue(this.quoteDetails.rmd_rules) : this.rmdQuoteForm.controls.rmd_rules.setValue('');
        this.quoteDetails.bene_rel ? this.rmdQuoteForm.controls.bene_rel.setValue(this.quoteDetails.bene_rel) : this.rmdQuoteForm.controls.bene_rel.setValue('');
        this.quoteDetails.mva_override ? this.rmdQuoteForm.controls.mva_override.setValue(this.quoteDetails.mva_override) : this.rmdQuoteForm.controls.mva_override.setValue('');
        this.quoteDetails.bene_dob ? this.rmdQuoteForm.controls.bene_dob.setValue(new Date(this.quoteDetails.bene_dob)) : this.rmdQuoteForm.controls.bene_dob.setValue('');
        this.quoteDetails.starting_lem ? this.rmdQuoteForm.controls.starting_lem.setValue(this.quoteDetails.starting_lem) : this.rmdQuoteForm.controls.starting_lem.setValue('');
        this.quoteDetails.rmd_override_amt ? this.rmdQuoteForm.controls.rmd_override_amt.setValue(this.quoteDetails.rmd_override_amt) : this.rmdQuoteForm.controls.rmd_override_amt.setValue('');
        // this.populateRequestedDetails();
    }

    changeEvent($event: MatCheckboxChange, id: string) {
        let effectiveDate = moment(this.effectivedate).format('MM/DD/YYYY');
        let productExists;
        let valueExists;
        const quoteData = this.allFormValues.filter((item) => item.id === id)[0];

        let unique = `${effectiveDate}-${quoteData.id}`;
        quoteData['unique'] = unique;
        quoteData['key'] = `${effectiveDate}`;

        let existsFormValues = JSON.parse(localStorage.getItem('rmd-quote-values'));


        existsFormValues =
            existsFormValues != null && existsFormValues != undefined
                ? existsFormValues
                : [];
        this.selectedFormValues = existsFormValues;

        if(this.isGroupProduct && this.selectedFormValues.length>0) {
            var productType = this.rmdQuoteForm.get('productType').value;
            let productValueExists = this.selectedFormValues.find(item => item.value == productType && item.key == effectiveDate);
            if(!productValueExists) {
                this.productCheck = true;
            }
        }

        if ($event.checked) {
            
            if(this.isGroupProduct ) {
                var selectedFormValueExists = this.selectedFormValues.find(item => item.unique == unique);
                productExists = this.selectedFormValues.find(item => item.value == productType && item.key==effectiveDate);
                if(!this.productCheck) {
                    let obj = {"effectiveDate":effectiveDate, "productType":productType, "id":`${quoteData.id}`}
                    let requiredValue = this.insertCheckedValue(this.selectedFormValues,obj).arr;
                    valueExists = requiredValue.find(item => item.unique == unique);
                }
            } else {
                productExists = true;
                valueExists = true;
                var selectedFormValueExists = this.selectedFormValues.find(item => item.unique == unique);
            }
            if (!(selectedFormValueExists && productExists && valueExists)) {

                if(this.isGroupProduct && this.productCheck) {
                    this.productCheck = false;
                    var indexToInsert = this.selectedFormValues.map((item) => item.key).lastIndexOf(productType); 
                } else {
                    var indexToInsert = this.selectedFormValues.map((item) => item.key).lastIndexOf(effectiveDate);    
                }
                
                if (indexToInsert !== -1) {
                    if(this.isGroupProduct) {
                        let obj = {"effectiveDate":effectiveDate, "productType":productType, "id":`${quoteData.id}`}
                        let index = this.insertCheckedValue(this.selectedFormValues,obj).index;
                        this.selectedFormValues.splice(index + 1, 0, quoteData);

                    } else {
                        this.selectedFormValues.splice(indexToInsert + 1, 0, quoteData);
                    }
                } else {
                    const effectiveDateData = {
                        id: 'effective_date',
                        label: 'Effective Date',
                        value: `${effectiveDate}`,
                        checked: '',
                        pipe: 'DATE',
                        unique: `${effectiveDate}-effective_date`,
                        key: `${effectiveDate}`,
                    };  
                    this.selectedFormValues.push(effectiveDateData);
                    if(this.isGroupProduct) {
                        const v1 = this.rmdQuoteForm.get('productType').value;
                        const v2 = this.rmdQuoteForm.get('accountType').value;
                        var e1 = {
                            "id": "product_type",
                            "label": "Product",
                            "value": v1,
                            "checked": "",
                            "pipe": "",
                            "unique": `${effectiveDate}-product_type`,
                            "key": `${effectiveDate}`,
                        }
                        var e2 = {
                            "id": "account_type",
                            "label": "Asset Account",
                            "value": v2,
                            "checked": "",
                            "pipe": "",
                            "unique":`${effectiveDate}-account_type`,
                            "key": `${effectiveDate}`,
                        }
                        this.selectedFormValues.push(e1,e2);  
                    }  
                    this.selectedFormValues.push(quoteData);
                }
            }
        } else {
            if(this.isGroupProduct) {
                let obj = {"effectiveDate":effectiveDate, "productType":productType, "id":`${quoteData.id}`}
                let returnValue = this.unCheckSelectedValues(this.selectedFormValues,obj);
                if(returnValue.count==3) {
                    this.unCheckProduct(this.selectedFormValues,{
                        ...obj,
                        ...returnValue
                    });
                }
            }
            else {
                this.selectedFormValues = this.selectedFormValues.filter(
                    (item) => item.unique !== unique
                );
                const count = this.selectedFormValues.filter((item) => item.key == effectiveDate).length;
                if (count == 1) {
                    unique = `${effectiveDate}-effective_date`;
                    this.selectedFormValues = this.selectedFormValues.filter((item) => item.unique !== unique);
                }
            }
            
        }

        localStorage.setItem(
            'rmd-quote-values',
            JSON.stringify(this.selectedFormValues)
        );
        this.showRequestedData();
    }

    showRequestedData() {
        let html = '';        
        const requestedDetails = this.selectedFormValues.reduce((acc, { key, label, value }) => {
            acc[key] = { ...acc[key], [label]: value };
            if(this.isGroupProduct) {
                value = value === "AP" ? "ALL PRODUCTS" : value;
            }
            html += `<dt class="pull-left">` + label + `:</dt><dd>&nbsp; ` + value + `</dd>`
            return acc;
          }, {});

        let quoteDetailsHeadHtml = (this.selectedFormValues.length > 0) ? `<span style="font-size:15px" class="pad-lAr-10px"> RMD QUOTE</span><dl class="pad-lAr-10px">` + html + `</dl>` : '';
        OcInfraModule.AppInjector.get(CommonService).rmdquoteDetailsHtml = quoteDetailsHeadHtml;
        OcInfraModule.AppInjector.get(CommonService).showRequestedDetails();
        OcInfraModule.AppInjector.get(CommonService).quoteValues = (this.selectedFormValues.length > 0) ? { "RMD QUOTE": requestedDetails }: {};
    }


    isGroupProductType = () => {
        let product_type = this.getNested(this.policyDetails, 'response', 'product_type');
        if (product_type && (product_type == "A3" || product_type == "A4")) {
            return true;
        } else {
            return false;
        }
    }

    processGroupProduct = () => {
        this.clientProductsUrl = this.policyDetails && this.policyDetails._links && this.policyDetails._links.clientproducts ? this.policyDetails._links.clientproducts.href : null;
        this.getClientProductsPromise();
    }

    getClientProductsPromise = async () => {
        await retryOperation(this.getClientProducts, this.retryDelay, this.retryAttempts)
            .then((res) => {
                this.commonService.clientProducts = res;
                this.clientProducts = res;
                this.processProductTypes(this.clientProducts);
            })
            .catch((err) => this.getClientProductsCall());
    }

    getClientProducts = () => {
        let clientProducts = this.commonService.getClientProducts();
        if (clientProducts) {
            return Promise.resolve(clientProducts);
        } else {
            return Promise.reject('No data');
        }
    }

    getClientProductsCall = () => {
        OcInfraModule.AppInjector.get(CommonService).getCallback(this.clientProductsUrl, this.headers).subscribe((response: any) => {
            if (response && response._links) {
                OcInfraModule.AppInjector.get(CommonService).clientProducts = response._links.item;
                this.clientProducts = response._links.item;
            }
        }, (error: any) => {
            if (error && error._embedded && error._embedded.status_report && error._embedded.status_report.consistent === false) {
                OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error._embedded.status_report.messages[0].message, 'Error', 0);
            }
        });
    }

    processProductTypes = (clientProducts: ItemProducts[]) => {
        if (clientProducts.length > 0) {
            this.clientProductItems = clientProducts.map((item: ItemProducts) => {
                return { label: item.summary.plan_code, value: item.summary.plan_code }
            });
            this.clientProductItems.unshift({ label: 'ALL PRODUCTS', value: 'AP' });
            this.rmdQuoteForm.get('productType').setValue('AP');

            this.getQuoteDetailsGroup();
        }
    }

    productTypeChange = (e: any) => {
        this.rmdQuoteForm.get('productType').setValue(e.value);
        this.rmdQuoteForm.get('accountType').setValue('ALL ASSET ACCOUNTS');

        let plan_code = this.rmdQuoteForm.controls.productType.value;
        this.headers.plan_id = plan_code;
        this.headers.client_id = this.commonService.productInfo.client;
        this.headers.product_selection = e.value.split('-')[0].trim();
        // this.getQuoteDetailsEmit.emit(this.headers);
    }

    getQuoteDetailsGroup = () => {
        this.headers.plan_id = 'AP';
        this.headers.client_id = this.commonService.productInfo.client;
        this.headers.product_selection = 'AP';
        this.getQuoteDetailsEmit.emit(this.headers);
    }


    setValidatorsForFormField(field) {
        this.rmdQuoteForm.controls[field].setValidators(null);
        this.rmdQuoteForm.controls[field].updateValueAndValidity();
    }

    onDateChange() {
        if (this.effectivedate != null)
            this.effectiveDateCheck = true;
    }

    relationshipEvent = ($event: any) => {
        if ($event.value.toLowerCase() == 'spouse' || $event.value.toLowerCase() == 'non-spouse') {
            this.rmdQuoteForm.controls['bene_dob'].setValidators([Validators.required]);
            this.dobRequired = true;
        } else {
            this.rmdQuoteForm.controls["bene_dob"].clearValidators();
            this.dobRequired = false;
        }
        this.rmdQuoteForm.controls['bene_dob'].updateValueAndValidity();
    }

    handleBlur(): void {
        const value = this.rmdQuoteForm.get('starting_lem').value;
        this.formatInput(value, 'blur');
    }

    formatInput(value: string, eventType: string): void {
        value = value.replace(/[^0-9.]/g, '');
        if (eventType === 'input' && value.length > 2 && !value.includes('.')) {
            value = value.slice(0, 2) + '.' + value.slice(2);
        }
        let [integer, decimal] = value.split('.');
        if (integer.length > 2) {
            integer = integer.substring(0, 2);
        }
        if (decimal && decimal.length > 1) {
            decimal = decimal.substring(0, 1);
        }

        if (eventType === 'blur' && value) {
            if (integer.length < 2) {
                integer = integer.padStart(2, '0');
            }
            if (!decimal) {
                decimal = '0';
            }
            value = integer + '.' + decimal;
        } else {
            value = integer + (decimal ? '.' + decimal : '');
        }
        this.rmdQuoteForm.get('starting_lem').setValue(value, { emitEvent: false });
    }


    onQuoteValuesClick = (event: any) => {
        let distribution_type = this.quoteDetails._options.properties.distribution_type.oneOf.find((item: any) => item.title == this.rmdQuoteForm.get("distribution_type").value);

        let rmd_rules = this.quoteDetails._options.properties.rmd_rules.oneOf.find((item: any) => item.title == this.rmdQuoteForm.get("rmd_rules").value);

        let bene_rel = this.quoteDetails._options.properties.bene_rel.oneOf.find((item: any) => item.title == this.rmdQuoteForm.get("bene_rel").value);

        let cal_method = this.quoteDetails._options.properties.cal_method.oneOf.find((item: any) => item.title == this.rmdQuoteForm.get("cal_method").value);

        let mva_override = this.quoteDetails._options.properties.mva_override.oneOf.find((item: any) => item.title == this.rmdQuoteForm.get("mva_override").value);

        let payload = {
            effective_date: moment(this.effectivedate).format('MM/DD/yyyy'),
            distribution_type: distribution_type.enum[0],
            rmd_rules: rmd_rules.enum[0],
            bene_rel: bene_rel.enum[0],
            cal_method: cal_method.enum[0],
            mva_override: mva_override.enum[0],
        };

        if (this.rmdQuoteForm.get('bene_rel').value != 'Individual') {
            payload = { ...payload, ...{ mrd_bene_dob: moment(this.rmdQuoteForm.get('bene_dob').value).format('MM/DD/yyyy') } }
        }

        if (this.validateQuoteForm()) {
            this.getQuoteValuesByClick(payload);
        }
        
        //this.effectivedate = moment(this.effectivedate).format('MM/DD/yyyy'),
        this.allFormValues = [...this.quoteInfoFields, ...this.quoteValueFields];
        this.prepareInfoFormControls();
        this.prepareValueFormControls();
        this.showRequestedData();
    }

    prepareInfoFormControls() {
        this.formattedInfoFields = [];
        this.quoteInfoFields.map((formItem, index) => {
            formItem.value = this.quoteDetails[formItem.id];

            'pipe' in formItem &&
                formItem.pipe == 'CURRENCY' &&
                (formItem.value = this.currencyPipe.transform(this.quoteDetails[formItem.id], 'USD'));

            'pipe' in formItem && formItem.pipe == 'PERCENTAGE' &&
                (formItem.value = this.quoteDetails[formItem.id] + '%');

            this.rmdQuoteForm.addControl(formItem.id, this.formBuilder.control(this.quoteDetails[formItem.id]));
        });
        for (var i = 0; i < this.quoteInfoFields.length; i += 2) {
            let part_slice = this.quoteInfoFields.slice(i, 2 + i);
            this.formattedInfoFields.push(part_slice);
        }
    }
    prepareValueFormControls() {
        this.formattedValueFields = [];
        this.quoteValueFields.map((formItem, index) => {
            formItem.value = this.quoteDetails[formItem.id];

            'pipe' in formItem &&
                formItem.pipe == 'CURRENCY' &&
                (formItem.value = this.currencyPipe.transform(this.quoteDetails[formItem.id], 'USD'));

            'pipe' in formItem && formItem.pipe == 'PERCENTAGE' &&
                (formItem.value = this.quoteDetails[formItem.id] + '%');

            this.rmdQuoteForm.addControl(formItem.id, this.formBuilder.control(this.quoteDetails[formItem.id]));
        });
        for (var i = 0; i < this.quoteValueFields.length; i += 2) {
            let part_slice = this.quoteValueFields.slice(i, 2 + i);
            this.formattedValueFields.push(part_slice);
        }
    }

    getQuoteValuesByClick = (payload: any) => {
        let quoteHref = this.policyDetails && this.policyDetails._links.rmdQuote.href;
        let quotePostHref = quoteHref + '/rmdQuote';

        if (this.isGroupProduct) {
            this.headers.plan_id = this.rmdQuoteForm.get('productType').value;
            this.headers.client_id = this.commonService.productInfo.client;
            this.headers.product_selection = this.rmdQuoteForm.get('productType').value;
        }

        OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false });

        OcInfraModule.AppInjector.get(CommonService).postCallback(quotePostHref, payload, this.headers).subscribe(response => {
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
            this.quoteDetails = response;

            this.allFormValues = [...this.quoteInfoFields, ...this.quoteValueFields];
            this.prepareInfoFormControls();
            this.prepareValueFormControls();
            this.showRequestedData();
        }, error => {
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
            if (error._embedded && error._embedded.status_report) {
                let alertMsg = [];
                error._embedded.status_report.messages.map(msg => alertMsg.push(msg.message))
                this.displayAlerts(error,'error');
            }
        });
    }

    insertCheckedValue = (list, obj) => {
        var requiredValues = [];
        
        let dateIndex = list.findIndex((item)=>item.value==obj.effectiveDate);
        if(dateIndex !== -1) {
          let indexOfAP = list.findIndex((item, ix)=> ix > dateIndex && item.value==obj.productType);
          let indexOfReqEffDate = indexOfAP - 1;
          let indexofNextEffDate = -1;
          var reqIdIndex = -1;
          
          
          for(let i=indexOfReqEffDate + 1;i<list.length;i++) {
            requiredValues[i] = list[i];
            if(list[i].id=="effective_date") {
                indexofNextEffDate = i
                reqIdIndex = indexofNextEffDate - 1;
                break;
            }
          }

          if(indexofNextEffDate==-1) {
            indexofNextEffDate = list.length;
            reqIdIndex = indexofNextEffDate - 1;
          for(let i=indexOfReqEffDate + 1;i<list.length;i++) {
              requiredValues[i] = list[i]
          }
          }
          return {"index":reqIdIndex,"arr":requiredValues=requiredValues.filter((item)=>item!==null)};
        }
        return {"index":reqIdIndex,"arr":requiredValues=requiredValues.filter((item)=>item!==null)};
       
    }

    unCheckSelectedValues = (list, obj) => {
 
        let dateIndex = list.findIndex((item)=>item.value==obj.effectiveDate);
        if(dateIndex !== -1) {
          let indexOfAP = list.findIndex((item, ix)=> ix > dateIndex && item.value==obj.productType);
          let indexOfReqEffDate = indexOfAP - 1;
          let indexofNextEffDate = -1;
          let reqIdIndex = -1;
          
          for(let i=indexOfReqEffDate + 1;i<list.length;i++) {
            if(list[i].id=="effective_date") {
                indexofNextEffDate = i
                break;
            }
            if(list[i].id == obj.id){
              reqIdIndex = i
            }
          }

          if(indexofNextEffDate==-1) {
            indexofNextEffDate = list.length;
          }

          let count = indexofNextEffDate - indexOfReqEffDate;
            if (reqIdIndex !== -1){
                list.splice(reqIdIndex,1);
            }
          return {"count":count-1,"arr":list, start: indexOfReqEffDate, end: indexofNextEffDate-1}
        }
        return {"count":-1,"arr":list}
       
    } 

    unCheckProduct = (list:any, obj:any) => {
        list.splice(obj.start, obj.count);
        return list;
    }

    displayAlerts(response, type) {
        if ( response._embedded && response._embedded.status_report && response._embedded.status_report.messages && response._embedded.status_report.messages.length > 0) {
          let fillteredData =response._embedded.status_report.messages.filter(x=>x.message !== "No mapping rule matched");
          fillteredData.forEach(element => {
            let alertMsg = {
              title: element.severity,
              type: type,
              body: element.message,
              showCloseButton: true,
              timeout: 0,
              tapToDismiss: false,
            }
            this.toasterService.pop(alertMsg);
          });
        }
      }


    validateQuoteForm() {
        return this.effectivedate;
    }

    isStepComplete() {
        return this.effectivedate;
    }

    /** AMOUNT AND PERCENT FORMATING  */
    formatNumber(n, type, flag) {
        if (flag) {
            n = n.charAt(0) == '0' ? n.replace(n.charAt(0), "") : n;
        }
        if (type == 'currency') {
            return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } else {
            return n.replace(/\D/g, "");
        }
    }

    //formats the value with commas and $ allowing 2 decimal values
    formatCurrency(input, fieldName, blur) {
        var input_val = input.target.value;
        if (input_val.indexOf(".") >= 0) {
            var decimal_pos = input_val.indexOf(".");
            decimal_pos = decimal_pos > 15 ? 15 : decimal_pos;
            var left_side = input_val.substring(0, decimal_pos);
            var right_side = input_val.substring(decimal_pos);
            left_side = this.formatNumber(left_side, 'currency', true);
            right_side = this.formatNumber(right_side, '', false);
            if (blur === "blur") {
                right_side += "00";
            }
            // Limit decimal to only 2 digits
            right_side = right_side.substring(0, 2);
            // join number by .
            input_val = "$" + left_side + "." + right_side;

        } else {
            // no decimal entered
            // add commas to number
            // remove all non-digits
            input_val = input_val.substring(0, 15);
            input_val = this.formatNumber(input_val, 'currency', true);
            input_val = "$" + input_val;
            // final formatting
            if (blur === "blur") {
                input_val += ".00";
            }
        }
        if (input_val.charAt(input_val.indexOf('$') + 1) == '.') {
            input_val = input_val.replace(input_val.charAt(input_val.indexOf('$') + 1), '0.');
        }
        if (fieldName) {
            this.rmdQuoteForm.controls[fieldName].setValue(input_val);
        }
        return input_val;

    }

    getQueryParams() {
        let params = new HttpParams();
        params = params.set('effectiveDate', this.datePipe.transform(this.effectivedate, "MM/dd/yyyy"));
        return params.toString();
    }

    getNested(obj, ...args) {
        return args.reduce((obj, level) => obj && obj[level], obj)
    }

}
