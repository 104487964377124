import { Component, OnInit, OnDestroy, AfterViewInit, ElementRef} from '@angular/core';
import { CommonService } from '../../services/common.service';
import { OcInfraModule, ResourceService } from '@diaas/ux-web';
import { Router } from '@angular/router';
import * as _ from 'lodash';
@Component({
  selector: 'banner-alert',
  templateUrl: './bannerAlert.component.html',
})

export class BannerAlertComponent implements OnInit, OnDestroy, AfterViewInit {
  
  public message: string;
  public type: string;
  public showMessage: boolean;
  public msgArray: any = [];
  public callerMsgArray: any = [];
  public subscription:any;
  elementData: any;

  constructor(private commonService: CommonService, private router: Router, public element: ElementRef) {
    this.elementData = element.nativeElement;
  }


  ngOnInit() {
    OcInfraModule.AppInjector.get(CommonService).agentPolicyDetailAlert = false;
    // this.commonService.bannerAlertArray = [];
    // subject subscribe
    this.subscription = this.commonService.alertMessaggeHideShow.subscribe(event => {
          let showAllStatusRpts = this.commonService.newBannerAlertAarray;
          let callerFraud = this.commonService.newBannerAlertAarray.filter(statusRpt => (statusRpt.id && statusRpt.id == 'policyCallHistory'));
          if(callerFraud){
            this.showCallerMessages(callerFraud);
          }else{
            this.showAllMessages(showAllStatusRpts);            
          }
      });
      // event emitter subscribe
      this.commonService.showHideBannerAlert
      .subscribe(event => {
        let showAllStatusRpts = this.commonService.bannerAlertArray.filter(statusRpt => (statusRpt.showAll && statusRpt.showAll == true));
        if (showAllStatusRpts && showAllStatusRpts.length > 0) {
          this.showAllMessages(showAllStatusRpts);
        } else {
          this.showHideMessageEvent();
        }
      });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  ngAfterViewInit(){
    //console.log('ngAfterViewInit', this.elementData.id);
    OcInfraModule.AppInjector.get(CommonService).agentPolicyDetailAlert = this.elementData.id == 'bannerAlertBobDetail';
    this.commonService.bannerAlertId.emit(this.elementData.id);
  }

  public showCallerMessages(showAllStatusRpts){
    this.callerMsgArray = [];
    showAllStatusRpts.forEach(statusRpt =>{
      statusRpt.messages.forEach(msg =>{
        this.callerMsgArray.push({"message":msg.message,"type":msg.severity,"showMessage":true});
    }) 
   })
  }

  /*
  * showAllMessages is plain method forces banner to show up without any conditions.
  * statusReport has falg showAll which forces to just show messages.
  */
  public showAllMessages(showAllStatusRpts){
    this.msgArray = [];
    this.callerMsgArray = [];
    showAllStatusRpts.forEach(statusRpt =>{
      statusRpt.messages.forEach(msg =>{
        this.msgArray.push({"message":msg.message,"type":msg.severity,"showMessage":true});
    }) 
   })
  }


  public showHideMessageEvent() {
    this.msgArray = [];
    const resourceService = OcInfraModule.AppInjector.get(ResourceService);
    if(!this.commonService.authsucess && !this.commonService.otherRoleAuthsucess){
      this.commonService.bannerAlertArray.forEach(messageEventArrayObj => {

        let statusReportAlias = resourceService.getAliasByHref(messageEventArrayObj.url.split('?')[0]);
        if(messageEventArrayObj.url.includes("notes")){
          var notesObj = {
            href: messageEventArrayObj.url,
            id: "policyNotes"
         };
         notesObj.id == 'policyNotes' ? statusReportAlias = notesObj : '';
        }   
        if(messageEventArrayObj.url.includes("claims")){
          var claimsObj = {
            href: messageEventArrayObj.url,
            id: "claimList"
         };
         claimsObj.id == 'claimList' ? statusReportAlias = claimsObj : '';
        }   

       // PANOCSR-1676 changes
        if(statusReportAlias == null){
          var revolvingObj = resourceService.getAliasByHref(messageEventArrayObj.url);
          if(revolvingObj){
            revolvingObj.id == 'policyRevolvingError' ? statusReportAlias = revolvingObj:'';
          }
        } 

        if((statusReportAlias && statusReportAlias.href && messageEventArrayObj.url.split('?')[0] === statusReportAlias.href) || (revolvingObj && revolvingObj.href) || notesObj){

          messageEventArrayObj.alias = statusReportAlias.id;
          if(messageEventArrayObj && messageEventArrayObj.messages && messageEventArrayObj.messages.length){   
            messageEventArrayObj.messages.forEach(messageObj => {
              if(messageObj.severity == 'ALERT' && _.findIndex(this.msgArray, ['message', messageObj.message]) == -1){
                if(statusReportAlias.id === 'searchContracts' && this.router.url !== '/screen/partySearch'){
                  return false;
                }else{
                  this.msgArray.push({"message":messageObj.message,"type":messageObj.severity,"showMessage":true});
                }                
              }  
            });
          }
        }      
      });
    }    
  }
}
