import { Component, ViewChild, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { CommonService, retryOperation } from '../../services/common.service';
import { OcInfraModule, APICallerService, ResourceService, Configuration, IAlias, ResourceDirectoryService } from '@diaas/ux-web';
import * as _ from 'lodash';
import {MatMenuTrigger, MatSelect} from '@angular/material';
import { NgModel } from '@angular/forms';


@Component({
  selector: 'app-table-filter',
  templateUrl: './customTableFilter.component.html',
  styleUrls: ['./customTableFilter.component.scss']
})


export class TableFilterComponent {
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  @ViewChild(MatSelect) matSelect: MatSelect;
  @ViewChildren('matSelectRef') matSelectRef: QueryList<ElementRef>;
  wrkType: boolean = false;
  assetAccountTypes: any[];
  accountType: string;
  accTypeItems: any;
  selectionChange: boolean = true;
  productType: any;
  productTypes: any[];
  showFinacialProducts: boolean;
  selectedProduct: any;
  displayValue: any;
  isGroupAnnuity: boolean = false;
  showSelectAsset: boolean = false;
  showFilter: boolean = false;

  constructor(private resourceService: ResourceService,  public element: ElementRef, public commonService: CommonService) {
    this.elementData = this.element.nativeElement
}

  optionList: any[] = [];
  appliedFilters: any;
  selectedValues: any;
   
  headers: any;
  elementData: any;
  filterElementsData: any;
  selectedMetamodel = ["incomingMoneyFilter", "outGoingMoneyFilter"];

  financialHistoryModels = ["paymentHistoryFilter", "incomingMoneyFilter", "programEnrollmentFilter", "historicalCoiFilter", "outGoingMoneyFilter", "financialFundsFilter","contractManagementFilter"]
  filterMetamodel: any[] = [
    {
      id:"workRequestTableFilter",
      alias: "workRequestHistory",
      field: [
        { 
          title: "Type",
          id: "worktype",
          type: "select",
          optionList: ""
        }
      ]
    },
    {
      id:"paymentHistoryFilter",
      alias: "paymenthistory",
      defaultParams: [
        {
          key: "history_type",
          value: "payment_history"
        }        
      ],
      field: [
        {
          title: "Transaction Type",
          id: "transaction_type",
          type : "multi-select",
          optionList: ""
        },
        {
          title: "Status",
          id: "status",
          type : "multi-select",
          optionList: ""
        }
      ]
    },
    {
      id:"incomingMoneyFilter",
      alias: "incomingmoney",
      defaultParams: [
        {
          key: "history_type",
          value: "incoming_money"
        }        
      ],
      field: [
        {
          title: "Transaction Type",
          id: "transaction_type",
          type : "multi-select",
          optionList: ""
        },
        {
          title: "Status",
          id: "status",
          type : "multi-select",
          optionList: ""
        }
      ]
    },
    {
      id:"programEnrollmentFilter",
      alias: "programenrollment",
      defaultParams: [
        {
          key: "history_type",
          value: "program_enrollment"
        }        
      ],
      field: [
        {
          title: "Transaction Type",
          id: "transaction_type",
          type : "multi-select",
          optionList: ""
        },
        {
          title: "Status",
          id: "status",
          type : "multi-select",
          optionList: ""
        }
      ]
    },
    {
      id:"historicalCoiFilter",
      alias: "historicalcoi",
      defaultParams: [
        {
          key: "history_type",
          value: "historical_coi"
        }        
      ],
      field: [
        {
          title: "Transaction Type",
          id: "transaction_type",
          type : "multi-select",
          optionList: ""
        } 
      ]
    },
    {
      id:"outGoingMoneyFilter",
      alias: "outgoingmoney",
      defaultParams: [
        {
          key: "history_type",
          value: "outgoing_money"
        }        
      ],
      field: [
        {
          title: "Transaction Type",
          id: "transaction_type",
          type : "multi-select",
          optionList: ""
        },
        {
          title: "Status",
          id: "status",
          type : "multi-select",
          optionList: ""
        }
      ]
    },
    {
      id:"financialFundsFilter",
      alias: "historyFunds",
      defaultParams: [
        {
          key: "history_type",
          value: "funds"
        }        
      ],
      field: [
        {
          title: "Transaction Type",
          id: "transaction_type",
          type : "multi-select",
          optionList: ""
        },
        {
          title: "Status",
          id: "status",
          type : "multi-select",
          optionList: ""
        }
      ]
    },
    {
      id:"contractManagementFilter",
      alias: "contract_management",
      defaultParams: [
        {
          key: "history_type",
          value: "contract_management"
        }        
      ],
      field: [
        {
          title: "Transaction Type",
          id: "transaction_type",
          type : "multi-select",
          optionList: ""
        },
        {
          title: "Status",
          id: "status",
          type : "multi-select",
          optionList: ""
        }
      ]
    },
    {
      id:"fundsFilter",
      alias: "historyFunds",
      defaultParams: [
        {
          key: "history_type",
          value: "funds"
        }        
      ],
      field: [
        {
          title: "Type",
          id: "transaction_type",
          type : "multi-select",
          optionList: ""
        }
      ]
    },

  ];
  retryDelay = 3500;
  retryAttempts = 7;

  retryDelayP = 3500;
  retryAttemptsP = 7;

  retryDelayC = 3500;
  retryAttemptsC = 7;

  ngOnInit() {
    this.selectedValues ={};
    this.appliedFilters={};
    let filteredElement = this.filterMetamodel.filter(obj => {
      return obj.id === this.elementData.id;
    })   
    let assetDataTabs = ["paymentHistoryFilter" , "programEnrollmentFilter", "outGoingMoneyFilter" , "financialFundsFilter", "incomingMoneyFilter", "historicalCoiFilter","contractManagementFilter"];
    this.showSelectAsset = assetDataTabs.includes(this.elementData.id) ? true : false;
    this.filterElementsData = filteredElement.length  ? filteredElement[0] : {}

    setTimeout(() => {
        this.getPolicyDetailsPromise();
    }, 3500);    
  }

    getPolicyDetailsPromise = async () => {
        await retryOperation(this.getPolicyDetails, this.retryDelayP, this.retryAttemptsP)
            .then((res) => {
                let details = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyDetails');
                let product_type = details.data.product_type;

                this.getFilteredItemsPromise();

                if (product_type == "A3" || product_type == "A4") {
                    this.isGroupAnnuity = true;
                    this.getCustomFilterItemsPromise();
                }   

            })
            .catch((err) => { });
    };

    getPolicyDetails = () => {
        let policyDetails = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyDetails');
        if (policyDetails) {
            return new Promise((resolve, reject) => resolve(policyDetails));
        } else {
            return new Promise((resolve, reject) => reject('No data'));
        }
    }

    ngAfterViewInit() {
        // if (this.isGroupAnnuity) {
        //     this.getCustomFilterItemsPromise();
        // }
    }

    getFilteredItemsPromise = async () => {
        await retryOperation(this.getFilteredItemsData, this.retryDelay, this.retryAttempts)
            .then((res) => this.mapDataToFilterItems(res))
            .catch((err) => err);
    };

    getFilteredItemsData = () => {
        let metadata = this.resourceService.getResourceFromAliasName(this.filterElementsData.alias);
        if (metadata) {
            return new Promise((resolve, reject) => resolve(metadata));
        } else {
            return new Promise((resolve, reject) => reject('No data'));
        }
    }

    mapDataToFilterItems = (metadata) => {
      this.showFilter = metadata && metadata.response && metadata.response._links && metadata.response._links.item.length > 0 ? true : false;
        this.filterElementsData.field.forEach(element => {
            element.optionList = metadata && metadata.response && metadata.response["_options"].properties[element.id] ? metadata.response["_options"].properties[element.id].oneOf : [];
        });
    }

    getCustomFilterItemsPromise = async () => {
        await retryOperation(this.getCustomFilterItemsData, this.retryDelayC, this.retryAttemptsC)
            .then((res) => res)
            .catch((err) => err);
    }

    getCustomFilterItemsData = async () => {
        let accTypeItems = [];
        if(this.financialHistoryModels.includes(this.elementData.id)) {            
            accTypeItems = await OcInfraModule.AppInjector.get(CommonService).getClientAssetAccountsAll();
        } else {
            accTypeItems = await OcInfraModule.AppInjector.get(CommonService).getClientAssetAccounts();
        }        
        let clientProducts = OcInfraModule.AppInjector.get(CommonService).getClientProducts();
        if (accTypeItems != null && accTypeItems != undefined) {
            this.accTypeItems = accTypeItems;
            this.processClientProducts(clientProducts);
            return new Promise((resolve, reject) => resolve(accTypeItems));
        } else {
            return new Promise((resolve, reject) => reject('No data'));
        }
    }


  checkSection = () => {
    return (this.elementData.id == 'incomingMoneyFilter') || (this.elementData.id == 'outGoingMoneyFilter');
  };

  processClientProducts(prodTypeItems){
    if (prodTypeItems) {
      this.productTypes = [];
      for (let i = 0; i < prodTypeItems.length; i++) {
        this.productTypes.push({ value: prodTypeItems[i].summary.plan_code, viewValue: prodTypeItems[i].summary.product_code });
      }
      this.productTypes.unshift({ value: "PARTICIPANT", viewValue: "PARTICIPANT HISTORY" });
      this.productType = this.productTypes[0].value;
    }
  }

  removeFilter(keyName, obj, matSelectRef: MatSelect) {  
    if(this.isArray(this.selectedValues[keyName])){
      const index = _.findIndex(this.selectedValues[keyName], ['title', obj.title]);    
      if (index >= 0) {
        this.selectedValues[keyName].splice(index, 1);  
        matSelectRef['_results'].forEach(element => {
          if(element.id === keyName){
            const matSelect: MatSelect = element;
            matSelect.writeValue(this.selectedValues[keyName]) 
          }       
        });
      }
    }else{
      delete this.selectedValues[keyName];
    }
    this.applyFilter();
  }

  applyFilter(){
    let filterHref = this.resourceService.getHrefByAliasName(this.filterElementsData.alias);
    let isFinancialHistory = this.filterElementsData.alias === 'paymenthistory' || this.filterElementsData.alias === 'outgoingMonney' || this.filterElementsData.alias === 'incomingmoney' ? true : false;
    const selectedFilters = [];
      if (this.isGroupAnnuity && this.productType) {
          let a = this.accountType;
          if (this.productType != 'PARTICIPANT') {
              for (let i = 0; i < this.productTypes.length; i++) {
                  if (this.productTypes[i].value === this.productType) {
                      this.selectedProduct = this.productTypes[i].viewValue;
                  }
              }
              if (this.accountType == "ALL ASSET ACCOUNTS") {
                  this.displayValue = "PLANCODE" + this.productType;
              } else {
                  this.displayValue = this.selectedProduct.split("-")[0].trim() + this.accountType.slice(0, 2);
              }

          } else {
              this.displayValue = this.productType;
          }
          selectedFilters.push({ key: "display_value", value: this.displayValue });
      }

    let selectedProperties = OcInfraModule.AppInjector.get(CommonService).filterProperties;
    selectedProperties[this.filterElementsData.alias] = {};
    Object.assign(selectedProperties[this.filterElementsData.alias], this.selectedValues)
    OcInfraModule.AppInjector.get(CommonService).filterProperties = selectedProperties;
    Object.keys(this.selectedValues).forEach(key => {
      if(Array.isArray(this.selectedValues[key]) && this.selectedValues[key].length){     
        let valueArray = []
        this.selectedValues[key].forEach(element => {
          valueArray.push(element.enum[0])
        });
        if(valueArray){
          selectedFilters.push({key: key, value: valueArray.join()});
        }        
      }else{
        if(this.selectedValues[key] && this.selectedValues[key].enum && this.selectedValues[key].enum[0]){
          selectedFilters.push({key: key, value: this.selectedValues[key].enum[0]});
        }        
      }
    });
    if(this.filterElementsData.defaultParams && this.filterElementsData.defaultParams.length){      
      selectedFilters.unshift(...this.filterElementsData.defaultParams)
      const alias = OcInfraModule.AppInjector.get(ResourceService).getAliasByAliasName(this.filterElementsData.alias);
      filterHref = alias.href ? alias.href.split("?")[0] : '';
    }  
    
    const optionParams = {
      'alias': this.filterElementsData.alias,
      'headers': OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName(this.filterElementsData.alias),
      'params': selectedFilters
    };
    OcInfraModule.AppInjector.get(CommonService).showHideSpinner({showSpinner: true, edit: true });
    for (let i = 0; i < optionParams.params.length; i++) {
      if (optionParams.params[i].key == "history_type") {
        this.wrkType = false;
      }
      else if(optionParams.params[i].key == "worktype"){
        this.wrkType = true;
      }
    }
    // this.wrkType
    if(this.wrkType){
      OcInfraModule.AppInjector.get(APICallerService).get(filterHref, optionParams).subscribe(refreshResponse => {
        this.wrkType ? this.commonService.wrkReqHistoryRes = refreshResponse :"";
        this.wrkType ? this.commonService.sendFilterEvent() : "";
        this.trigger.closeMenu();
        OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: true });
        this.appliedFilters = Object.assign({}, this.selectedValues);

      });
    }else {
      this.commonService.financialHistoryRes = optionParams;
      this.commonService.sendFinancialHistoryFilterEvent(this.filterElementsData.alias);
      this.trigger.closeMenu();
      this.appliedFilters = Object.assign({}, this.selectedValues);
      OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: true });
    }
  }

  resetAssetFilter(){
    this.productType = "";
    this.accountType = "";
    this.applyFilter();
  }

  isArray(value) {
    return Array.isArray(value) ? true : false;     
  } 

  resetFilter() {   
    this.selectedValues = {};
    let matSelectRef: QueryList<ElementRef>;
    this.applyFilter();
  }

  onProductTypeChange(e) {
    this.accountType = '';
    this.selectionChange = true;
    if (this.accTypeItems && this.productType != 'PARTICIPANT') {
      this.assetAccountTypes = [];
      for (let i = 0; i < this.accTypeItems.length; i++) {
        if (this.accTypeItems[i].summary.plan_code === e.value) {
          if (this.accTypeItems[i].summary.asset_account_names) {
            for (let j = 0; j < this.accTypeItems[i].summary.asset_account_names.length; j++) {
              this.assetAccountTypes.push({ value: this.accTypeItems[i].summary.asset_account_names[j], viewValue: this.accTypeItems[i].summary.asset_account_names[j] });
            }
          }
        }
      }
    } 
  }

  onAssetAccountChange(e) {
    let selectedAssetAccount;
    let drpdwnObj;
    this.selectionChange = false;
    if (this.accountType == "ALL ASSET ACCOUNTS") {
      selectedAssetAccount = '**';
    } else {
      selectedAssetAccount = this.accountType.substring(0, this.accountType.indexOf(' '));
    }
    drpdwnObj = { 'planCode': this.productType, 'assetAccount': selectedAssetAccount, 'accountType': this.accountType }; 
  }

    disableUpdate = () => {
        if (this.productType == 'PARTICIPANT') {
            return false;
        } else if(this.productType != 'PARTICIPANT' && this.accountType) {
            return false;
        } else {
            return true;
        }
    }

    disableReset = () => {
        if (this.productType || this.accountType) {
            return false;
        } else {
            return true;
        }
    }


}
