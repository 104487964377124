import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { Configuration, OcInfraModule, ResourceService, APICallerService} from '@diaas/ux-web';
import { CommonService } from '../../../services/common.service';
import { AdvGrowlService } from 'primeng-advanced-growl';
import * as _ from 'lodash';
import { CurrencyPipe } from '@angular/common';
import * as quoteConstant from '../quote-loan-initiation/quote-loan-initiation.constant';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ToastType, warningCodes } from '../quote-loan-initiation/quote-loan-initiation.constant';
import { ToasterService } from 'angular2-toaster';

@Component({
    selector: 'app-confirmation-loan-initiation',
    templateUrl: './confirmation-loan-initiation.component.html',
    styleUrls: ['./confirmation-loan-initiation.component.scss']
})
export class ConfirmationLoanInitiationComponent implements OnInit {
    @Input() loanInitiationData: any;

    loanInitiationHeader: any;
    warningMessags: any[] = [];

    confirmForm: FormGroup;
    formFields!: quoteConstant.DynamicFormFieldModel[];
    allForms$: Subscription;

    allFormValues: any;
    allFormValid: boolean;
    requiredMessage = 'Please fill required fields';

    randomCode: string;
    isOverride: boolean = true;
    overrideCall: boolean = true;

    ignorePayloadData: any[] = [
        'bank_account_verified',
        'change_account_verified',
        'enhanced_system',
        'premium_payment_verified'
    ];

    constructor(
        private currencyPipe: CurrencyPipe,
        private formBuilder: FormBuilder,
        private commonService: CommonService,
        private cdr: ChangeDetectorRef,
        private toasterService: ToasterService,
    ) { }

    ngOnInit() {
        this.formFields = <quoteConstant.DynamicFormFieldModel[]>(
            quoteConstant.confirmFormFields
        );
        if (this.loanInitiationData) {
            this.formInit();
            this.randomCode = this.commonService.generateRandomCode();
        }
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.allForms$ = this.commonService.getAllForms$.subscribe(
                (data: any) => {
                    if (Object.keys(data).length > 0) {
                        this.allFormValues = data.values;
                        this.allFormValid = data.valid;
                        this.loadFormData();
                    }
                }
            );
        }, 0);
    }

    ngAfterContentChecked(): void {
        this.cdr.detectChanges();
    }

    formInit() {
        this.confirmForm = this.formBuilder.group({});
        this.formFields.forEach((formItem) => {
            const formControl = this.formBuilder.control(
                formItem.value,
                formItem.validators
            );
            if (formItem.id !== 'hr') {
                this.confirmForm.addControl(formItem.id, formControl);
            }
        });
    }

    loadFormData() {
        this.formFields.forEach((formItem) => {
            if (formItem.id == 'confirmation_number') {
                formItem.value = this.randomCode;
            } else if (formItem.id == 'disbursementInd') {
                formItem.value =
                    this.allFormValues[formItem.id] === 'C' ? 'Cash' : 'EFT';
            } else if (formItem.id == 'loan_amount' || formItem.id == 'federal_withhold_amount' || formItem.id == 'state_withhold_amount') {
                formItem.value = this.currencyPipe.transform(
                    (this.allFormValues[formItem.id]) ? this.allFormValues[formItem.id] : 0
                );
            } else {
                formItem.value =
                    formItem.id in this.allFormValues
                        ? this.allFormValues[formItem.id]
                        : formItem.value;
            }

            const formControl = this.formBuilder.control(
                formItem.value,
                formItem.validators
            );
            if (formItem.id != 'hr') {
                this.confirmForm.addControl(formItem.id, formControl);
            }
        });
    }

    onSubmit = () => {
        const disbursementInd = this.allFormValues.disbursementInd;
        if (!Array.isArray(disbursementInd)) this.allFormValues.disbursementInd = [disbursementInd];
        if (this.isOverride && !this.overrideCall) {
            this.allFormValues.confirm_override = "Y"
        }

        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        this.loanInitiationHeader = _.filter(Configuration.config.headers["multiHostUrlHeader"], function (o) { return o.alias == 'loanConfirmation' });

        const selectedRoleSummary = resourceService.getResourceFromAliasName('selectedRoles');
        this.allFormValues.policyNumber = selectedRoleSummary && selectedRoleSummary.data.policyNumber ? selectedRoleSummary.data.policyNumber : '';

        this.ignorePayloadData.map((ele) => delete this.allFormValues[ele]);

        this.overrideCall = false;
        let policyDetails = resourceService.getResourceFromAliasName('policyDetails');
        this.loanInitiationHeader[0].headers.product_type = policyDetails.data.product_type;
        const workRequestHref = resourceService.getHrefByAliasName("workRequestHistory").split('?')[0];
        let workRequestheaders = _.filter(Configuration.config.headers["multiHostUrlHeader"], function (o) { return o.alias == 'workRequestHistory' });
        OcInfraModule.AppInjector.get(CommonService).postCallback(policyDetails._links['loanQuote/confirmation'].href, this.allFormValues, this.loanInitiationHeader[0].headers).subscribe(response => {
            OcInfraModule.AppInjector.get(AdvGrowlService).createSuccessMessage("Loan Initiation Processed successfully ", 'Success');
            if (response.success) {
                setTimeout(() => {
                 OcInfraModule.AppInjector.get(APICallerService).refresh(workRequestHref, { 'alias': "workRequestHistory", 'headers': workRequestheaders[0].headers}).subscribe(refreshResponse => {
                   OcInfraModule.AppInjector.get(CommonService).wrkReqHistoryRes = refreshResponse;
                   OcInfraModule.AppInjector.get(CommonService).sendFilterEvent();
                   OcInfraModule.AppInjector.get(CommonService).loanInitiation = false;   
                   document.getElementById("modal__cross").click();
                   OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
                   });
                }, 2500);
             } 
        }, error => {
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
            if (error._embedded && error._embedded.status_report) {
                this.warningMessags = error._embedded.status_report.messages;
                this.showWarningMessages();
            }
        });

    }

    isSubmitDisabled = () => {
        if (this.confirmForm.valid && this.allFormValid && this.isOverride) {
            return false;
        } else {
            return true;
        }
    };


    showWarningMessages = () => {
        const matches = this.warningMessags.filter((item, index) => {
            setTimeout(() => {
                return this.showToaster('error', 'ALERT', item.message);
            }, 500 * index);
            return warningCodes.every((code) => !item.message.includes(code));
        });
        this.isOverride = matches.length > 0 ? false : true;
        this.isOverride && this.showOverridePopup();
    }

    showOverridePopup = () => {
        let dialogRef = OcInfraModule.AppInjector.get(CommonService).showConfirmationPopup('Are you sure? You want to override?', 'No', 'yes', 'alert', '340px');
        dialogRef.afterClosed().subscribe(res => {
            if (res) {
                this.onSubmit();
            } else {
                return false;
            }
        })
    };

    showToaster = (type: ToastType, title: string, message: string) => {
        let alertMsg = {
            title: title,
            type: type,
            body: message,
            showCloseButton: true,
            timeout: 0,
            tapToDismiss: false,
        };
        this.toasterService.pop(alertMsg);
    };

}
