import { Component, Input, Output, EventEmitter } from '@angular/core';
@Component({
  selector: 'associated-policies',
  templateUrl: './associated-policies.component.html',
  styleUrls: ['associated-policies.component.scss'],
})

export class AssociatedPoliciesComponent {
  @Input() showHideAdditionalPolicyAssociate: boolean = true;
  @Input() showHideAdditionalPolicyBenefitQuote: boolean = false;
  @Input() portfolioDeathClaimInfo: any;
  @Output() setAssociatedPolicy: EventEmitter<AssociatedPolicy> = new EventEmitter<AssociatedPolicy>();
  showHideAdditionalPolicySearch: boolean = false;

  onSetAssociatedPolicy(e: AssociatedPolicy) {
    if (e.showHideAdditionalPolicySearch != undefined) {
      this.showHideAdditionalPolicySearch = e.showHideAdditionalPolicySearch;
    }

    if (e.associateClicked) {
      this.setAssociatedPolicy.emit({
        associateClicked: e.associateClicked
      });
    }

    if (e.benefitQuoteClicked) {
      this.setAssociatedPolicy.emit({
        benefitQuoteClicked: e.benefitQuoteClicked
      });
    }

    if (e.refreshClaims) {
      this.setAssociatedPolicy.emit({
        refreshClaims: e.refreshClaims
      });
    }
  }
}
