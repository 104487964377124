import { Component, OnInit, EventEmitter, Output, ElementRef } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { PartySearchFactory } from '../../factories/partySearchFactory';
import { CommonService } from '../../services/common.service';
import { ResourceService, OcInfraModule, APICallerService, OptionParams } from '@diaas/ux-web';
import { AdvGrowlService } from 'primeng-advanced-growl';
import { MatStepper } from '@angular/material';

@Component({
  selector: 'app-fund-table-annuity',
  templateUrl: './fund-table-annuity.component.html',
  styleUrls: ['./fund-table-annuity.component.scss']
})
export class FundTableAnnuityComponent implements OnInit {
  selectedDate: Object = {};
  fundList: any[] = [];
  noRecords: boolean = false;
  statusMsg: string = "";
  message: boolean = false;
  isAnnuity: boolean = false;
  fundsObj: any = {};
  olsPolicyfund: boolean = false;
  csrPolicyfund: boolean = false;
  isOlsAnnuity: boolean = false;
  isGroupAnnuity: boolean = false;
  groupAnnuityFund: boolean;
  policyDetails: any;
  resourceService: any;
  getPlanCodeUrl: any;
  getAssetAccountUrl: any;
  headers = { ...{ wma_user: window.sessionStorage.getItem('username'), client_id: OcInfraModule.AppInjector.get(CommonService).productInfo.client }, ...OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("search") };
  productTypes: any[];
  accTypeItems: any;
  productType: any;
  accountType: any;
  assetAccountTypes: any[];
  fundsData: boolean;
  noFundsData: boolean = false;
  isAnnuityOrUL: boolean = false;
  fundResponse: any[]=[];
  fundListCurrent: any[] = [];
  fundListFuture: any[] = [];

  constructor(public elementRef: ElementRef) { }

  ngOnInit() {
    //setTimeout(() => {
      let selectedPolicyInfo = OcInfraModule.AppInjector.get(CommonService).productInfo;
      if (selectedPolicyInfo && (selectedPolicyInfo.product_type == 'A1' || selectedPolicyInfo.product_type == 'A2')) {
        this.isAnnuity = true;
      }
      if (selectedPolicyInfo && (selectedPolicyInfo.product_type == 'A3' || selectedPolicyInfo.product_type == 'A4')) {
        this.isGroupAnnuity = true;
      }
      var loanHistoryURL = OcInfraModule.AppInjector.get(CommonService).getAllUrlOfPolicyDetails();
      if (!this.isGroupAnnuity && loanHistoryURL["funds"] && loanHistoryURL["funds"].href) {
        let optionParams = new OptionParams();
        optionParams.headers = OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("partyRoles");
        OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false });
        OcInfraModule.AppInjector.get(APICallerService).get(loanHistoryURL["funds"].href, optionParams).subscribe(response => {
          this.fundResponse = response;
          this.fundListCurrent = response.response._links.item.filter(item => (item.summary.fund_value !== "0.00"));
          this.fundListFuture = response.response._links.item.filter(item => (item.summary.allocation_percent !== "0.0"));
         
          this.fundListCurrent.splice(0, 0, JSON.parse(JSON.stringify(response.response._links.item[0])));
          this.fundListFuture.splice(0, 0, JSON.parse(JSON.stringify(response.response._links.item[0])));
          let fundIndex = 0;
          if (this.fundListCurrent.length > 0 || this.fundListFuture.length > 0) {
            response.response._links.item.forEach(element => {
              element.summary.nameFlag = false;
              element.summary.id = fundIndex;
              fundIndex++;
            });
          }

          OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
          this.noRecords = this.fundList && !this.fundList.length;
          if (response.response._embedded && response.response._embedded.status_report.messages) {
            response.response._embedded.status_report.messages.forEach(msgObj => {
              this.statusMsg += msgObj.message;
              this.message = true;
            });
          }
        });
      }
      if (this.isGroupAnnuity) {
        this.resourceService = OcInfraModule.AppInjector.get(ResourceService);
        this.policyDetails = this.resourceService.getResourceFromAliasName('policyDetails') ? this.resourceService.getResourceFromAliasName('policyDetails').response : null;
        this.getPlanCodeUrl = this.policyDetails && this.policyDetails._links && this.policyDetails._links.clientproducts ? this.policyDetails._links.clientproducts.href : null;
        this.getAssetAccountUrl = this.policyDetails && this.policyDetails._links && this.policyDetails._links.clientassetaccounts ? this.policyDetails._links.clientassetaccounts.href : null;
        
        if(  OcInfraModule.AppInjector.get(CommonService).getClientProducts() ==null ){
          OcInfraModule.AppInjector.get(CommonService).getCallback(this.getPlanCodeUrl, this.headers).subscribe(response => {
            this.processClientProducts(response._links.item);
          }, error => {
            if (error && error._embedded && error._embedded.status_report && error._embedded.status_report.consistent === false) {
              OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error._embedded.status_report.messages[0].message, 'Error', 0);
            }
          });
        }else{
          this.processClientProducts(OcInfraModule.AppInjector.get(CommonService).getClientProducts());
        }
        if(  OcInfraModule.AppInjector.get(CommonService).getClientAssetAccounts() ==null ){
          OcInfraModule.AppInjector.get(CommonService).getCallback(this.getAssetAccountUrl, this.headers).subscribe(response => {
            this.accTypeItems = response._links.item;
            OcInfraModule.AppInjector.get(CommonService).clientAssetAccounts = response._links.item;
          }, error => {
            if (error && error._embedded && error._embedded.status_report && error._embedded.status_report.consistent === false) {
              OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error._embedded.status_report.messages[0].message, 'Error', 0);
            }
          });
        }else{
          this.accTypeItems =  OcInfraModule.AppInjector.get(CommonService).getClientAssetAccounts();
        }
      

      
      }
      this.olsPolicyfund = this.elementRef.nativeElement.id == 'olsFundTable' ? true : false;
      this.csrPolicyfund = this.elementRef.nativeElement.id == 'fundTable' ? true : false;
      this.groupAnnuityFund = this.elementRef.nativeElement.id == 'fundTableGroupAnnuity' ? true : false;

  //},0);
  }

  processClientProducts(prodTypeItems){
    if (prodTypeItems) {
      this.productTypes = [];
      for (let i = 0; i < prodTypeItems.length; i++) {
        this.productTypes.push({ value: prodTypeItems[i].summary.plan_code, viewValue: prodTypeItems[i].summary.plan_code });
      }
    }
  }
  onProductTypeChange(e) {
    if (this.accTypeItems) {
      this.assetAccountTypes = [];
      for (let i = 0; i < this.accTypeItems.length; i++) {
        if (this.accTypeItems[i].summary.plan_code === e.value) {
          if (this.accTypeItems[i].summary.asset_account_names) {
            for (let j = 0; j < this.accTypeItems[i].summary.asset_account_names.length; j++) {
              this.assetAccountTypes.push({ value: this.accTypeItems[i].summary.asset_account_names[j], viewValue: this.accTypeItems[i].summary.asset_account_names[j] });
            }
          }
        }
      }
    }
  }
  getFundsInfo() {
    let optionParams = new OptionParams();
    var loanHistoryURL = OcInfraModule.AppInjector.get(CommonService).getAllUrlOfPolicyDetails();
    let selectedAssetAccount;
    if (this.accountType == "ALL ASSET ACCOUNTS") {
      selectedAssetAccount = '**';
    } else {
      selectedAssetAccount = this.accountType.substring(0, this.accountType.indexOf(' '));
    }
    optionParams.headers = OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("partyRoles");
    Object.assign(optionParams.headers, { client_id: OcInfraModule.AppInjector.get(CommonService).productInfo.client, plan_id: this.productType, asset_account: selectedAssetAccount });
    OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false });
    OcInfraModule.AppInjector.get(APICallerService).get(loanHistoryURL["funds"].href, optionParams).subscribe(response => {
      this.fundResponse = response;
      this.fundListCurrent = response.response._links.item.filter(item => (item.summary.fund_value !== "0.00"));
      this.fundListFuture = response.response._links.item.filter(item => (item.summary.allocation_percent !== "0.0"));
      let fundIndex = 0;
      if (this.fundListCurrent.length > 0 || this.fundListFuture.length > 0) {
        response.response._links.item.forEach(element => {
          element.summary.nameFlag = false;
          element.summary.id = fundIndex + 'F';
          fundIndex++;
        });
      }
      this.noFundsData = this.fundList && !this.fundList.length;
      OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
    }, error => {
      OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
      if (error && error._embedded && error._embedded.status_report && error._embedded.status_report.consistent === false) {
        OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error._embedded.status_report.messages[0].message, 'Error', 0);
      }
    });
}
}
