import { Component, OnInit, ViewChild } from '@angular/core';
import { APICallerService, OcInfraModule, OptionParams, ResourceService } from '@diaas/ux-web';
import { CommonService, retryOperation } from '../../services/common.service';
import { AdvGrowlService } from 'primeng-advanced-growl';
import { MatDialog, MatPaginator, MatTableDataSource, MatSort } from '@angular/material';
import { Subscription } from 'rxjs';
import { CustomDialogComponent } from '../custom-dialog/custom-dialog.component';
import * as outgoingMoneyConstant from './outgoing-money.constant';
import { FinancialHistoryDisplayComponent } from '../financial-history-display/financial-history-display.component';

@Component({
    selector: 'app-outgoing-money',
    templateUrl: './outgoing-money.component.html',
    styleUrls: ['./outgoing-money.component.scss']
})
export class OutgoingMoneyComponent implements OnInit {
    displayedColumns: string[]  = outgoingMoneyConstant.initDisplayedFields;
    AnnDisplayedColumns: string[] = outgoingMoneyConstant.initAnnDisplayedFields;
    outgoingMoneyData: outgoingMoneyConstant.TableData[];
    outgngMoneyData: MatTableDataSource<any>;
    // prgrmEnrlmntData: any;
    previousBlock: boolean;
    nextBlock: boolean;
    outgoingMoneyRes: any;
    filterParams: any;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    noRecords: boolean = true;
    isAnnuity: boolean = false;

    @ViewChild(MatSort) set matSort(ms: MatSort) {
        this.sort = ms;
        this.setDataSourceAttributes();
    }


    financialFilterSubscription$: Subscription;
    policyDetails: any

    retryDelayP = 3500;
    retryAttemptsP = 7;
    initCall: boolean = false;

    constructor(public commonService: CommonService, public dialog: MatDialog) {
        this.commonService.getFinancialHistoryFilterEvent().subscribe((alias) => {
            this.filterParams = this.commonService.financialHistoryRes;
            if (alias == "outgoingmoney") {
                this.applyFilter(alias, this.commonService.financialHistoryRes)
            }
        });
    }

    ngOnInit() {
        this.outgoingMoneyData = [];
        this.outgngMoneyData = new MatTableDataSource(this.outgoingMoneyData);
        this.policyDetails = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyDetails');
        let product_type = this.policyDetails.data.product_type;
        if (product_type.includes('A')) {
            this.isAnnuity = true;
        }
        this.getPolicyDetailsPromise();
    }

    getPolicyDetailsPromise = async () => {
        await retryOperation(this.getPolicyDetails, this.retryDelayP, this.retryAttemptsP)
            .then((res) => {
                this.getOutgoingMoneyData();
            })
            .catch((err) => { });
    };

    getPolicyDetails = () => {
        let policyDetails = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyDetails');
        if (policyDetails) {
            return Promise.resolve(policyDetails);
        } else {
            return Promise.reject('No data');
        }
    }


    getOutgoingMoneyData = async () => {
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let policyDetails = resourceService.getResourceFromAliasName('policyDetails');
        let [url, params] = this.commonService.getHrefUrlWithParams(policyDetails._links['outgoingmoney'].href);
        let optionParams = new OptionParams();
        optionParams.alias = "outgoingmoney";
        optionParams.headers = OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("outgoingmoney");
        optionParams.params = params;
        await OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false });
        await OcInfraModule.AppInjector.get(APICallerService).refresh(url, optionParams).subscribe(async response => {
            this.initCall = true;
            this.getOutgoingMoneyDetails(response);
            
            await OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
        }, async (err: any) => {
            await OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
        });
    }


    getOutgoingMoneyDetails(response) {
        if (response && response._links && response._links.item) {
            if (response._links.previous) {
                this.previousBlock = true;
            } else {
                this.previousBlock = false;
            }
            if (response._links.next) {
                this.nextBlock = true;
            } else {
                this.nextBlock = false;
            }
            this.outgoingMoneyRes = response;
            this.outgoingMoneyData = response._links.item;
            this.outgngMoneyData = new MatTableDataSource(this.outgoingMoneyData);
            this.outgngMoneyData.paginator = this.paginator;
            this.sortData({ active: "eff_date", direction: "desc" });
        }
        this.sortedData = response._embedded ? [] : response._links.item;
        this.noRecords = !this.outgoingMoneyData || !this.outgoingMoneyData.length;
        this.outgngMoneyData.sort = this.sort;
    }

    getBlock(block) {
        let optionParams = new OptionParams();
        optionParams.headers = this.commonService.getHeaderByAliasName("outgoingmoney");
        this.outgoingMoneyData = [];
        this.outgngMoneyData = new MatTableDataSource(this.outgoingMoneyData);
        let hrefUrl = this.outgoingMoneyRes._links[block].href;
        this.commonService.getCallback(hrefUrl, optionParams.headers).subscribe(response => {
            if (response && response._links && response._links.item) {
                if (response._links.previous) {
                    this.previousBlock = true;
                } else {
                    this.previousBlock = false;
                }
                if (response._links.next) {
                    this.nextBlock = true;
                } else {
                    this.nextBlock = false;
                }
                this.outgoingMoneyRes = response;
                this.outgoingMoneyData = response._links.item;
                this.outgngMoneyData = new MatTableDataSource(this.outgoingMoneyData);
                this.outgngMoneyData.paginator = this.paginator;
                this.sortData({ active: "eff_date", direction: "desc" });
            }
            this.sortedData = response._embedded ? [] : response._links.item;
            this.noRecords = !this.outgoingMoneyData || !this.outgoingMoneyData.length;
        });
        this.outgngMoneyData.sort = this.sort;
    }

    setDataSourceAttributes() {
        if (this.outgngMoneyData) {
            this.outgngMoneyData.paginator = this.paginator;
            this.outgngMoneyData.sort = this.sort;
        }
    }


    sortedData: outgoingMoneyConstant.TableData[];
    sortData(sort) {
        const data = this.outgngMoneyData.filteredData.slice();
        if (!sort.active || sort.direction === '') {
            this.sortedData = data;
            return;
        }
        this.sortedData = data.sort((a, b) => {
            const isAsc = sort.direction === 'asc';
            switch (sort.active) {
                case 'eff_date': return compare(new Date(a.summary.effective_date).getTime(), new Date(b.summary.effective_date).getTime(), isAsc);
                default: return 0;
            }
        });
        this.outgngMoneyData = new MatTableDataSource(this.sortedData);
    }

    applyFilter = async (alias: string, optionParams: any) => {
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let hrefUrl = resourceService.getHrefByAliasName(alias);
        let params = {};
        if (this.filterParams != undefined && Object.keys(this.filterParams).length >= 0 && 'params' in this.filterParams) {
            params = this.commonService.transformParams(this.filterParams['params'])
        }
        this.apiPaymenthistory(hrefUrl, optionParams.headers, params);
    }

    apiPaymenthistory = async (hrefUrl: string, headers, params: any) => {
        await this.commonService.showHideSpinner({ showSpinner: true, edit: false });
        this.commonService.getCallback(hrefUrl, headers, params).subscribe(async response => {
            this.getOutgoingMoneyDetails(response);
            await this.commonService.showHideSpinner({ showSpinner: false, edit: false });
        }, async error => {
            await this.commonService.showHideSpinner({ showSpinner: false, edit: false });
            if (error && error._embedded && error._embedded.status_report && error._embedded.status_report.consistent === false) {
                OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error._embedded.status_report.messages[0].message, 'Error', 0);
            }
        });
    }

    getDisplayedColumns() {
        if (this.isAnnuity) {
            return this.AnnDisplayedColumns;
        } else {
            return this.displayedColumns;
        }
    }

    openHistoryDetails = ($event: any) => {
        let params = { historyId: $event.summary.history_id, history_type: 'Outgoing_Money' };
        let headers = { client_id: '', display_value: '', effective_date: '' };
        let productInfo = this.commonService.productInfo;
        if (productInfo && (productInfo["product_type"] == "A3" || productInfo["product_type"] == "A4")) {
            headers = { client_id: this.policyDetails.response.client_id, display_value: 'PARTICIPANT', effective_date: $event.summary.effective_date };
        }
        if (this.filterParams && this.filterParams.headers && Object.keys(this.filterParams.headers).length > 0) {
            let displayValue = this.filterParams.params.find((ele: any) => ele.key === 'display_value')
            headers = { ...headers, ...{ display_value: displayValue ? displayValue.value: '' } };
        }

        let dialogRef = this.dialog.open(CustomDialogComponent, {
            width: '1000px',
            disableClose: false,
            data: { component: FinancialHistoryDisplayComponent, title: 'Outgoing Money', params: params, headers: headers }
        });
    }

    ngOnDestroy() {
        setTimeout(() => {
            this.financialFilterSubscription$.unsubscribe();
        }, 0);
    }


}

function compare(a: number | Date | string, b: number | Date | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
