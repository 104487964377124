export interface QuoteFormFieldModel {
    id: string;
    label: string;
    value: string;
    checked?: string;
    pipe?: string
}

export enum quoteRMDValuesID {
    EffectiveDate = 'effective_date',
    RMDMode = 'rmd_mode',
    RMDDay = 'rmd_day',
    AdjustedAmount = 'adjusted_amt',
    PriorYearRMDBase = 'prior_year_rmd_base',
    GrossAmount = 'gross_amount',
    AdditionalBenefits = 'apv_addl_ben',
    NetRmdAmount = 'net_rmd_amt',
    APVAdjustments = 'apv_adjustments',
    RMDVariance = 'rmd_variance',
    CurrentYearDistribution = 'current_year_dis',
    LemFactor = 'lem_factor',
    AnnuitantAge ='annuitant_Age',
    RequiredBeginDate = 'req_begin_date',
    BeneficiaryAgeUsed = 'bene_age_used',
    RemainingGWMDAmount = 'rm_gmwb_amt'    
}

export enum quoteRMDModalValuesID {
    AnnualAmount = 'annual_amt',
    SemiAnnualAmount = 'semi_annual_amt',
    QuaterlyAmount = 'quaterly_amt',
    MonthlyAmount = 'monthly_amt'
}

export enum quoteRMDValuesLabels {
    EffectiveDate = 'Effective Date',
    RMDMode = 'RMD Mode',
    RMDDay = 'RMD Day',
    AdjustedAmount = '12/31/86 Adjusted Amount',
    PriorYearRMDBase = 'Prior Year 12/31 RMD Base',
    GrossAmount = 'Gross RMD Amount',
    AdditionalBenefits = 'APV Of Additional Benefits',
    NetRmdAmount = 'Net RMD Amount',
    APVAdjustments = 'APV Adjustment',
    RMDVariance = 'RMD Variance',
    CurrentYearDistribution = 'Current Year Distribution',
    LemFactor = 'LEM Factor',
    AnnuitantAge ='Annuitant Age',
    RequiredBeginDate = 'Required Begin Date',
    BeneficiaryAgeUsed = 'Beneficiary Age Used',
    RemainingGWMDAmount = 'Remaining GWMB Amount'   
}

export enum quoteRMDModalValuesLabels {
    AnnualAmount = 'Annual Amount',
    SemiAnnualAmount = 'Semi Annual Amount',
    QuaterlyAmount = 'Quaterly Amount',
    MonthlyAmount = 'Monthly Amount'
}

export const quoteRMDValues = () => {

    return [
        {
            id: quoteRMDValuesID.RMDMode,
            label: quoteRMDValuesLabels.RMDMode,
            value: '',
            checked: '',
            pipe: ''
        },
        {
            id: quoteRMDValuesID.RMDDay,
            label: quoteRMDValuesLabels.RMDDay,
            value: '',
            checked: '',
            pipe: ''
        },
        {
            id: quoteRMDValuesID.AdjustedAmount,
            label: quoteRMDValuesLabels.AdjustedAmount,
            value: '',
            checked: '',
            pipe: 'CURRENCY'
        },
        {
            id: quoteRMDValuesID.PriorYearRMDBase,
            label: quoteRMDValuesLabels.PriorYearRMDBase,
            value: '',
            checked: '',
            pipe: 'CURRENCY'
        },
        {
            id: quoteRMDValuesID.GrossAmount,
            label: quoteRMDValuesLabels.GrossAmount,
            value: '',
            checked: '',
            pipe: 'CURRENCY'
        },
        {
            id: quoteRMDValuesID.NetRmdAmount,
            label: quoteRMDValuesLabels.NetRmdAmount,
            value: '',
            checked: '',
            pipe: 'CURRENCY'
        },
        {
            id: quoteRMDValuesID.RMDVariance,
            label: quoteRMDValuesLabels.RMDVariance,
            value: '',
            checked: '',
            pipe: 'CURRENCY'
        },
        {
            id: quoteRMDValuesID.CurrentYearDistribution,
            label: quoteRMDValuesLabels.CurrentYearDistribution,
            value: '',
            checked: '',
            pipe: 'CURRENCY'
        },
        {
            id: quoteRMDValuesID.LemFactor,
            label: quoteRMDValuesLabels.LemFactor,
            value: '',
            checked: '',
            pipe: ''
        },
        {
            id: quoteRMDValuesID.AnnuitantAge,
            label: quoteRMDValuesLabels.AnnuitantAge,
            value: '',
            checked: '',
            pipe: ''
        },
        {
            id: quoteRMDValuesID.RequiredBeginDate,
            label: quoteRMDValuesLabels.RequiredBeginDate,
            value: '',
            checked: '',
            pipe: ''
        },
        {
            id: quoteRMDValuesID.BeneficiaryAgeUsed,
            label: quoteRMDValuesLabels.BeneficiaryAgeUsed,
            value: '',
            checked: '',
            pipe: ''
        },
        {
            id: quoteRMDValuesID.RemainingGWMDAmount,
            label: quoteRMDValuesLabels.RemainingGWMDAmount,
            value: '',
            checked: '',
            pipe: 'CURRENCY'
        },
    ];

}

export const quoteRMDModalValues = () => {
    return [
    {
        id: quoteRMDModalValuesID.AnnualAmount,
        label: quoteRMDModalValuesLabels.AnnualAmount,
        value: '',
        checked: '',
        pipe: 'CURRENCY'
    },
    {
        id: quoteRMDModalValuesID.SemiAnnualAmount,
        label: quoteRMDModalValuesLabels.SemiAnnualAmount,
        value: '',
        checked: '',
        pipe: 'CURRENCY'
    },
    {
        id: quoteRMDModalValuesID.QuaterlyAmount,
        label: quoteRMDModalValuesLabels.QuaterlyAmount,
        value: '',
        checked: '',
        pipe: 'CURRENCY'
    },
    {
        id: quoteRMDModalValuesID.MonthlyAmount,
        label: quoteRMDModalValuesLabels.MonthlyAmount,
        value: '',
        checked: '',
        pipe: 'CURRENCY'
    },
    ];
}