import { Component, OnInit, EventEmitter, Output, ElementRef, ViewChild } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { MatPaginator, MatTableDataSource, MatSort } from '@angular/material';
import { OcInfraModule, OptionParams } from '@diaas/ux-web';
import * as assestColumnsConstant from './groupValuesAssetTable.constant'
import * as _ from 'lodash';


@Component({
  selector: 'group-values-asset-table',
  templateUrl: './groupValuesAssetTable.component.html',
})

export class GroupValuesAssetTableComponent implements OnInit {
  displayedColumns : string[] = assestColumnsConstant.DisplayedAssetsTableColumns
  total_accumulated_value:any;
  noRecords: boolean = true;
  assetData: assestColumnsConstant.ValueAssetData[];
  ValueAssetData:any;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }
  
  constructor(public elementRef: ElementRef, public commonService: CommonService){
    this.commonService.getClickEvent().subscribe(() =>{
      this.getValueAssetData(null);
    })

    this.commonService.getQuoteValueEvent().subscribe((quoteDate) =>{
      this.getValueAssetData(quoteDate);
    })

  }

  ngOnInit() {
    setTimeout(() => {
      this.assetData = [];
      this.ValueAssetData = new MatTableDataSource(this.assetData);
      this.getValueAssetData(null);
    },0);
  }

  getValueAssetData(quoteDate) {
    
    var valueAssetURL = OcInfraModule.AppInjector.get(CommonService).getAllUrlOfPolicyDetails();
    const assetAccountValuesResp = OcInfraModule.AppInjector.get(CommonService).getAssetAccountValuesResp();
    if ((assetAccountValuesResp==null || quoteDate!=null)&& valueAssetURL["assetaccountvalues"] && valueAssetURL["assetaccountvalues"].href) {
      let optionParams = new OptionParams();
      optionParams.headers = OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("groupAssetValue");
      this.assetData = [];
      this.ValueAssetData = new MatTableDataSource(this.assetData);
      let url = valueAssetURL["assetaccountvalues"].href;
      if(quoteDate!=null){
        url = url+ "?quoteEffectiveDate="+quoteDate ;
      }
      OcInfraModule.AppInjector.get(CommonService).getCallback(url, optionParams.headers).subscribe(response => {
        this.processResponse(response);
      });
    }else{
      this.processResponse(assetAccountValuesResp);
    }

  }


  processResponse(assetAccountValuesResp){
    if(assetAccountValuesResp && assetAccountValuesResp.asset_account_value){
      this.assetData = assetAccountValuesResp.asset_account_value;
      this.ValueAssetData = new MatTableDataSource(this.assetData);
      this.sortedData = assetAccountValuesResp._embedded ? [] : assetAccountValuesResp.asset_account_value;
      this.sortData({ active: "policy_number", direction: "asc" });
   }
   this.total_accumulated_value = assetAccountValuesResp.tot_accumulated_value;
   this.noRecords = !this.sortedData || !this.sortedData.length;
  }

  setDataSourceAttributes() {
    if (this.ValueAssetData) {
      this.ValueAssetData.sort = this.sort;
    }
  }

  
  sortedData: assestColumnsConstant.ValueAssetData[];
  sortData(sort) {
    const data = this.ValueAssetData.filteredData.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }
    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';

      switch (sort.active) {
        case 'policy_number': return compare(a.policy_number, b.policy_number, isAsc);
        default: return 0;
      }
    });
  }
}

function compare(a: number | Date | string, b: number | Date | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
