import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { OcInfraModule, ResourceService } from '@diaas/ux-web';
import { PartySearchFactory } from '../../../../factories/partySearchFactory';
import { CommonService } from '../../../../services/common.service';
import { SurrenderDetails, OneOf, OneOfData, SurrenderValues, FundValue } from './partial-surrender-surrender.constant';

import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { AppDateAdapter, APP_DATE_FORMATS } from '../../../format-datepicker';
import * as _ from 'lodash';

@Component({
    selector: 'app-partial-surrender-surrender',
    templateUrl: './partial-surrender-surrender.component.html',
    styleUrls: ['./partial-surrender-surrender.component.scss'],
    providers: [
        { provide: DateAdapter, useClass: AppDateAdapter },
        { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
    ]
})
export class PartialSurrenderSurrenderComponent implements OnInit {

    @Input() withdrawlSurrenderType: any;
    @Input() surrenderDetails: SurrenderDetails;
    @Input() isLife: boolean;
    @Output() passDistributionData: EventEmitter<any> = new EventEmitter<any>();

    alertMsg = [];
    surrenderForm: FormGroup;

    responseOptions: any;
    requiredFields: any;

    statePctOrBasis: any = [];
    transactionTypes: any = [];
    transactionLevels: any = [];

    federal_withholding_override: boolean = false;
    state_withholding_override: boolean = false;

    client_recommend_or_authorized: boolean = false;

    maskSeparator = 'separator.2';
    separatorLimit = '9999999999';
    prefix = '$';
    decimalPlaces = 2;

    minLoanAmount = '';
    maxLoanAmount = '';

    fundsInfo: boolean = false;
    funds: FundValue[] = [];

    totalFunds: string = ''
    isGroupProduct: boolean = false;
    policyDetails: any;
    isMrpsContract: boolean = false;
    isCv15Contract: boolean = false;

    tableSortStates: any;
    totalFundscalculated: string;
    effectiveMinDate = new Date(1900, 1, 1);


    constructor(
        private formBuilder: FormBuilder,
        private partySearchFactory: PartySearchFactory
    ) { }

    ngOnInit() {
        this.policyDetails = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyDetails');

        this.isCv15Contract = this.policyDetails.response.isCv15Contract ? true : false;
        this.isMrpsContract = this.policyDetails.response.isMrpsContract ? true : false;

        this.surrenderFormInit();
        this.surrenderForm.get('federal_withholding_amount').disable();
        this.surrenderForm.get('federal_withholding_percentage').disable();

        this.surrenderForm.get('state_withholding_amount').disable();
        this.surrenderForm.get('state_withholding_percentage').disable();
        this.surrenderForm.get('statePctOrBasis').disable();
    }


    surrenderFormInit() {
        let percentValue = this.formatPercent('', 'blur', '0.00');
        let currencyValue = this.formatCurrency('', 'blur', '0.00')

        this.surrenderForm = this.formBuilder.group({
            effective_date: [new Date(), [Validators.required]],

            statePctOrBasis: [''],

            no_federal_withholding: [false],
            federal_withholding_override: [false],
            federal_withholding_amount: [currencyValue],
            federal_withholding_percentage: [percentValue],

            no_state_withholding: [false],
            state_withholding_override: [false],
            state_withholding_amount: [currencyValue],
            state_withholding_percentage: [percentValue],

            transaction_type: ['', [Validators.required]],
            surrender_amount: [currencyValue, [Validators.required]],
            surrender_percent: ['', []],
            transaction_level: ['', [Validators.required]],
            net_gross_indicator: ['Gross', [Validators.required]],

        });
        const authorizedRole = OcInfraModule.AppInjector.get(CommonService).authorizedRole;

        if (this.isCv15Contract) { // CV15 policy
            if (authorizedRole === 'Agent' || authorizedRole === 'BOA Back Office Assistant (Advisor Assistant)') {
                this.surrenderForm.addControl("client_recommend_or_authorized", new FormControl(false, Validators.requiredTrue));
                this.client_recommend_or_authorized = true;
            }
        }
    }


    get federal_withholding_amount() {
        return this.surrenderForm.get('federal_withholding_amount').value;
    }

    set federal_withholding_amount(val) {
        this.surrenderForm.get('federal_withholding_amount').setValue(val);
    }

    get federal_withholding_percentage() {
        return this.surrenderForm.get('federal_withholding_percentage').value;
    }

    set federal_withholding_percentage(val) {
        this.surrenderForm.get('federal_withholding_percentage').setValue(val);
    }

    get state_withholding_amount() {
        return this.surrenderForm.get('state_withholding_amount').value;
    }

    set state_withholding_amount(val) {
        this.surrenderForm.get('state_withholding_amount').setValue(val);
    }

    get state_withholding_percentage() {
        return this.surrenderForm.get('state_withholding_percentage').value;
    }

    set state_withholding_percentage(val) {
        this.surrenderForm.get('state_withholding_percentage').setValue(val);
    }

    get surrender_amount() {
        return this.surrenderForm.get('surrender_amount').value;
    }

    set surrender_amount(val) {
        this.surrenderForm.get('surrender_amount').setValue(val);
    }

    get surrender_percent() {
        return this.surrenderForm.get('surrender_percent').value;
    }

    set surrender_percent(val) {
        this.surrenderForm.get('surrender_percent').setValue(val);
    }

    get transaction_type() {
        return this.surrenderForm.get('transaction_type').value;
    }

    set transaction_type(val) {
        this.surrenderForm.get('transaction_type').setValue(val);
    }

    get transaction_level() {
        return this.surrenderForm.get('transaction_level').value;
    }

    set transaction_level(val) {
        this.surrenderForm.get('transaction_level').setValue(val);
    }

    // integrates the API response 
    getSurrenderDetails() {
        let response = this.surrenderDetails;
        this.responseOptions = response._options;
        this.requiredFields = response._options && response._options.required || [];

        let fundValues = this.getNested(this.surrenderDetails, 'fundValues');
        //adding new property to calculate and store value of converted amount from percent entered
        if(fundValues) {
            this.funds = JSON.parse(JSON.stringify(fundValues));
            for(let i=0;i<this.funds.length;i++){
                this.funds[i]['surrender_amount_cal'] = "0.00";
            }
        }
        
        this.loadPermanentBasics();
        this.loadTransactionTypes();
        this.loadTransactionLevels();
        this.loadTableSortStates();


        if (this.surrenderDetails.effective_date != undefined) {
            this.surrenderForm.get('effective_date').setValue(new Date(this.surrenderDetails.effective_date));
            this.surrenderForm.get('effective_date').disable();
        }

        if (this.surrenderDetails.no_federal_withholding != undefined) {
            let federalWithHoldValue = this.surrenderDetails.no_federal_withholding == 'N' ? false : true;
            this.surrenderForm.get('no_federal_withholding').setValue(federalWithHoldValue);
        }

        if (this.surrenderDetails.federal_withholding_amount != undefined) {
            if (this.isLife) {
                let federal_withholding_amount = this.formatCurrency('', 'blur', this.surrenderDetails.federal_withholding_amount)
                this.federal_withholding_amount = federal_withholding_amount;
            }
        }
        if (this.surrenderDetails.federal_withholding_percentage != undefined) {
            if (this.isLife) {
                let federal_withholding_percentage = this.formatPercent('', 'blur', this.surrenderDetails.federal_withholding_percentage)
                this.federal_withholding_percentage = federal_withholding_percentage
            }
        }

        if (this.surrenderDetails.no_state_withholding != undefined) {
            let stateWithHoldValue = this.surrenderDetails.no_state_withholding == 'N' ? false : true;
            this.surrenderForm.get('no_state_withholding').setValue(stateWithHoldValue);
        }

        if (this.surrenderDetails.state_withholding_amount != undefined) {
            if (this.isLife) {
                let state_withholding_amount = this.formatCurrency('', 'blur', this.surrenderDetails.state_withholding_amount);
                this.state_withholding_amount = state_withholding_amount;
            }
        }
        if (this.surrenderDetails.state_withholding_percentage != undefined) {
            if (this.isLife) {
                let state_withholding_percentage = this.formatPercent('', 'blur', this.surrenderDetails.state_withholding_percentage);
                this.state_withholding_percentage = state_withholding_percentage;
            }
        }
        let currencyValue = this.formatCurrency('', 'blur', '0.00')
        
        this.surrenderForm.get('transaction_type').setValue('A');
        this.surrenderForm.get('transaction_level').setValue('P');
        this.surrenderForm.get('surrender_amount').setValue(currencyValue);
        this.surrenderForm.get('surrender_percent').disable();
        this.totalFunds = this.funds ? this.getTotalofFunds(this.funds, 'CURRENCY') : '$0.0';
    }

    loadPermanentBasics = () => {
        let statePctOrBasis = this.getNested(this.surrenderDetails._options.properties, 'statePctOrBasis');
        if (statePctOrBasis && statePctOrBasis.oneOf.length > 0) {
            this.statePctOrBasis = statePctOrBasis.oneOf.map((ele: OneOf): OneOfData => {
                return <OneOfData>{ value: ele.enum[0], label: ele.title };
            });
        }
    }

    loadTransactionTypes = () => {
        let transaction_type = this.getNested(this.surrenderDetails._options.properties, 'transaction_type');
        if (transaction_type && transaction_type.oneOf.length > 0) {
            this.transactionTypes = transaction_type.oneOf.map((ele: OneOf): OneOfData => {
                return <OneOfData>{ value: ele.enum[0], label: ele.title };
            });
        }
    }

    loadTransactionLevels = () => {
        const transactionLevels = ['P', 'F']; // CV15
        let transaction_level = this.getNested(this.surrenderDetails._options.properties, 'transaction_level');
        if (transaction_level && transaction_level.oneOf.length > 0) {
            this.transactionLevels = transaction_level.oneOf.filter((item: OneOf) => transactionLevels.includes(item.enum[0])).map((ele: OneOf): OneOfData => {
                return <OneOfData>{ value: ele.enum[0], label: ele.title };
            });
        }
    }

    loadTableSortStates = () => {
        this.tableSortStates = [
            { "column": "fund_number", "isAsc": false },
            { "column": "fund_name", "isAsc": false },
            { "column": "fund_value", "isAsc": true }
        ]
    }

    onTransactionTypeEvent = ($event: any) => {
        //to reset fund amounts and percentages when we change trasaction type
        for(let i=0;i<this.funds.length;i++){
            this.funds[i].surrender_amt = this.surrenderDetails.fundValues[i].surrender_amt;
            this.funds[i].surrender_percent = this.surrenderDetails.fundValues[i].surrender_percent;
            if(this.funds[i].surrender_amount_cal) {
                this.funds[i].surrender_amount_cal = '0.0'
            }
        }
        this.transactionTypeAndLevelValidators()
    }

    onTransactionLevelEvent = ($event: any) => {
            this.transactionTypeAndLevelValidators()
    }

    transactionTypeAndLevelValidators = () => {
        this.surrenderForm.get('surrender_amount').setValue('');
        this.surrenderForm.get('surrender_percent').setValue('');
        if (this.transaction_type == 'A' && this.transaction_level == 'P') {
            this.fundsInfo = false;
            this.surrenderForm.get('surrender_amount').enable();
            this.surrenderForm.get('surrender_percent').disable();
            this.surrenderForm.get('surrender_amount').setValidators(Validators.required);
            this.surrenderForm.get('surrender_percent').setValidators(null);
            this.surrenderForm.controls['net_gross_indicator'].enable();
            this.surrenderForm.controls['net_gross_indicator'].setValidators(Validators.required);
        } else if (this.transaction_type == 'P' && this.transaction_level == 'P') {
            this.fundsInfo = false;
            this.surrenderForm.get('surrender_amount').disable();
            this.surrenderForm.get('surrender_percent').enable();
            this.surrenderForm.get('surrender_amount').setValidators(null);
            this.surrenderForm.get('surrender_percent').setValidators(Validators.required);
            this.surrenderForm.controls['net_gross_indicator'].enable();
            this.surrenderForm.controls['net_gross_indicator'].setValidators(Validators.required);
        } else if ((this.transaction_type == 'A' || this.transaction_type == 'P') && this.transaction_level == 'F') {
            this.fundsInfo = true;
            this.surrenderForm.get('surrender_amount').disable();
            this.surrenderForm.get('surrender_percent').disable();
            this.surrenderForm.get('surrender_amount').setValidators(null);
            this.surrenderForm.get('surrender_percent').setValidators(null);
            this.surrenderForm.controls['net_gross_indicator'].disable();
            this.surrenderForm.controls['net_gross_indicator'].setValidators(null);
            
            if (this.transaction_type == 'A') {
                this.totalFunds = this.funds ? this.getTotalofFunds(this.funds, 'CURRENCY') : '$0.0';
            } else if (this.transaction_type == 'P') {
                this.totalFunds = this.funds ? this.getTotalofFunds(this.funds, 'PERCENT') : '0.0%';
            }
        }

        this.surrenderForm.get('surrender_amount').updateValueAndValidity();
        this.surrenderForm.get('surrender_percent').updateValueAndValidity();
    }

    checkLoanEligibleEvent = (value: any) => {
        this.surrender_amount = this.appendZerosIfNeeded(value);
    };

    appendZerosIfNeeded = (value) => {
        if (
            this.decimalPlaces != null &&
            value != null &&
            value !== '' &&
            value !== '0.00'
        ) {
            value = (+value).toFixed(this.decimalPlaces);
            return value;
        }
    }


    getNested(obj, ...args) {
        return args.reduce((obj, level) => obj && obj[level], obj)
    }

    isStepComplete = () => {
        return this.surrenderForm.valid;
    }


    noFederalEvent = ($event) => {
        if ($event.checked) {
            this.surrenderForm.get('federal_withholding_override').disable();
        } else {
            this.surrenderForm.get('federal_withholding_override').enable();
        }
    };

    noStateEvent = ($event) => {
        if ($event.checked) {
            this.surrenderForm.get('state_withholding_override').disable();
        } else {
            this.surrenderForm.get('state_withholding_override').enable();
        }
    };

    federalOverrideEvent = ($event: any) => {
        if ($event.checked) {
            this.surrenderForm.get('federal_withholding_amount').enable();
            this.surrenderForm.get('federal_withholding_percentage').enable();
            this.surrenderForm.get('no_federal_withholding').disable();

        } else if (this.isCv15Contract && !($event.checked)) {
            this.surrenderForm.get('federal_withholding_amount').disable();
            this.surrenderForm.get('federal_withholding_percentage').disable();
            this.surrenderForm.get('no_federal_withholding').enable();
            let federalWithHoldAValue = this.formatCurrency('', 'blur', '0.00')
            this.surrenderForm.get('federal_withholding_amount').setValue(federalWithHoldAValue);
            let federalWithHoldValue = this.formatPercent('', 'blur', '0.00')
            this.surrenderForm.get('federal_withholding_percentage').setValue(federalWithHoldValue);
            this.passDistributionData.emit(federalWithHoldValue);
        } else {
            this.surrenderForm.get('federal_withholding_amount').disable();
            this.surrenderForm.get('federal_withholding_percentage').disable();
            this.surrenderForm.get('no_federal_withholding').enable();
        }
    }

    stateOverrideEvent = ($event: any) => {
        if ($event.checked) {
            this.surrenderForm.get('state_withholding_amount').enable();
            this.surrenderForm.get('state_withholding_percentage').enable();
            this.surrenderForm.get('no_state_withholding').disable();
            this.surrenderForm.get('statePctOrBasis').enable();
            this.surrenderForm.get('statePctOrBasis').setValue('A');
        } else if (this.isCv15Contract) {
            this.surrenderForm.get('state_withholding_amount').disable();
            this.surrenderForm.get('state_withholding_percentage').disable();
            this.surrenderForm.get('no_state_withholding').enable();
            let stateWithHoldAValue = this.formatCurrency('', 'blur', '0.00')
            this.surrenderForm.get('state_withholding_amount').setValue(stateWithHoldAValue);
            let stateWithHoldValue = this.formatPercent('', 'blur', '0.00')
            this.surrenderForm.get('state_withholding_percentage').setValue(stateWithHoldValue);
            this.surrenderForm.get('statePctOrBasis').setValue('');
            this.surrenderForm.get('statePctOrBasis').disable();
        } else {
            this.surrenderForm.get('state_withholding_amount').disable();
            this.surrenderForm.get('state_withholding_percentage').disable();
            this.surrenderForm.get('no_state_withholding').enable();
        }
    }


    federalWithholdingEvent = ($event: any, type: string) => {
        if (type == 'AMOUNT') {
            this.federal_withholding_amount = this.formatCurrency('', 'blur', this.federal_withholding_amount);
            // this.surrenderForm.get('state_withholding_percentage').disable();
            this.surrenderForm.get('federal_withholding_percentage').disable();
        }

        if (type == 'PERCENT') {
            this.federal_withholding_percentage = this.formatPercent('', 'blur', this.federal_withholding_percentage);
            this.passDistributionData.emit(this.federal_withholding_percentage);
            this.surrenderForm.get('federal_withholding_amount').disable();
            this.percentageCheck(this.federal_withholding_percentage);
        }
    };

    stateWithholdingEvent = ($event: any, type: string) => {
        if (type == 'AMOUNT') {
            this.state_withholding_amount = this.formatCurrency('', 'blur', this.state_withholding_amount);
            this.surrenderForm.get('state_withholding_percentage').disable();
            this.surrenderForm.get('statePctOrBasis').setValue('');
        }

        if (type == 'PERCENT') {
            this.state_withholding_percentage = this.formatPercent('', 'blur', this.state_withholding_percentage);
            //this.surrenderForm.get('federal_withholding_amount').disable(); //As per request in 3469 they requested to change this to partial as well
            this.surrenderForm.get('state_withholding_amount').disable();
            this.percentageCheck(this.state_withholding_percentage);
        }
    };


    getSurrenderValues = (): SurrenderValues => {

        const { federal_withholding_amount, federal_withholding_percentage, state_withholding_amount, state_withholding_percentage, surrender_percent, calculated_surrender_amount } = this.surrenderForm.getRawValue();

        let surrenderValues: SurrenderValues = {
            effective_date: this.surrenderDetails.effective_date,
            withdrawal_type: this.withdrawlSurrenderType,

            statePctOrBasis: this.surrenderForm.get('statePctOrBasis').value,

            no_federal_withholding: this.surrenderForm.get('no_federal_withholding').value ? 'Y' : 'N',
            federal_withholding_amount: federal_withholding_amount,
            federal_withholding_percentage: federal_withholding_percentage,
            federal_withholding_override: this.surrenderForm.get('federal_withholding_override').value,

            no_state_withholding: this.surrenderForm.get('no_state_withholding').value ? 'Y' : 'N',
            state_withholding_amount: state_withholding_amount,
            state_withholding_percentage: state_withholding_percentage,
            state_withholding_override: this.surrenderForm.get('state_withholding_override').value,
            transaction_type: this.surrenderForm.get('transaction_type').value,
            transaction_level: this.surrenderForm.get('transaction_level').value,
            surrender_amount: this.surrenderForm.get('surrender_amount').value?this.surrenderForm.get('surrender_amount').value:"$0.00",
            surrender_percent: this.surrenderForm.get('surrender_percent').value?this.formatPercent('', 'blur', this.surrenderForm.get('surrender_percent').value):"0.00%",
            transaction_amount: this.totalFunds,
            calculated_surrender_amount: this.totalFundscalculated ? this.formatCurrency('', 'blur',this.totalFundscalculated):"$0.00"
        };
        return surrenderValues;
    }

    percentageCheck(value){
        if(this.surrenderForm.get('state_withholding_percentage').value == value){
            let totalWithPer = value.includes('%') ? value.slice(0,-1) : value;
            let totalInt = Math.trunc(totalWithPer);
            let finalVal = totalInt.toString();
            if(finalVal.length>2){
                let alertMsg = [];
                alertMsg.push("You can not enter more than 2 digits");
                let stateWithHoldValue = this.formatPercent('', 'blur', '0.00')
                this.surrenderForm.get('state_withholding_percentage').setValue(stateWithHoldValue);
                let dialogRef = OcInfraModule.AppInjector.get(CommonService).showConfirmation({ content: alertMsg, custom: true }, 'CLOSE', '', 'ALERT', '393px');
                dialogRef.afterClosed().subscribe(res => {
                    res;
                });
            }
        }
        else if(this.surrenderForm.get('federal_withholding_percentage').value == value){
            let totalWithPer = value.includes('%') ? value.slice(0,-1) : value;
            let totalInt = Math.trunc(totalWithPer);
            let finalVal = totalInt.toString();
            if(finalVal.length>2){
                let alertMsg = [];
                alertMsg.push("You can not enter more than 2 digits");
                let federalWithHoldValue = this.formatPercent('', 'blur', '0.00')
                this.surrenderForm.get('federal_withholding_percentage').setValue(federalWithHoldValue);
                let dialogRef = OcInfraModule.AppInjector.get(CommonService).showConfirmation({ content: alertMsg, custom: true }, 'CLOSE', '', 'ALERT', '393px');
                dialogRef.afterClosed().subscribe(res => {
                    res;
                });
            }
        } else {
            let totalWithPer = value.includes('%') ? value.slice(0,-1) : value;
            let totalInt = Math.trunc(totalWithPer);
            let finalVal = totalInt.toString();
            if(finalVal.length>2){
                let alertMsg = [];
                alertMsg.push("You can not enter more than 2 digits");
                let dialogRef = OcInfraModule.AppInjector.get(CommonService).showConfirmation({ content: alertMsg, custom: true }, 'CLOSE', '', 'ALERT', '393px');
                dialogRef.afterClosed().subscribe(res => {
                    res;
                });
            }
        }
    }

     // Sorts the fund table based on the column
     sortTable = (column: any) => {
        let isAsc: any;
        let _self = this;
        _.forEach(_self.tableSortStates, function (obj, index) {
            if (obj['column'] == column) {
                isAsc = !obj['isAsc'];
                _self.tableSortStates[index]['isAsc'] = isAsc;
            }
        })
        this.funds = isAsc ? _.orderBy(this.funds, this.byKey(column), 'asc') : _.orderBy(this.funds, this.byKey(column), 'desc');
    }

    // checks if it is a Number String
    byKey = (key: any) => {
        return function (o: any) {
            if (typeof o[key] === 'string') {
                return isNaN(+o[key].replace(/[^a-zA-Z0-9]/g, '')) ? o[key] : +o[key];
            }
            return o[key];
        };
    }



    ngAfterViewInit() {
        setTimeout(() => {
            this.getSurrenderDetails();
            this.getSurrenderValues();
        }, 0);
    }

    ngOnDestroy() {
        setTimeout(() => {
            // this.activeTab$.unsubscribe();
        }, 0);
    }



    //formats the value with commas and $ allowing 2 decimal values
    formatCurrency(input, blur, respValue) {
        var input_val = "";
        if (respValue == '' || respValue == undefined) {
            input_val = input.target.value;
        } else {
            input_val = respValue;
        }
        if (input_val === "") { return; }
        var original_len = input_val.length;
        if (input_val.indexOf(".") >= 0) {
            var decimal_pos = input_val.indexOf(".");
            decimal_pos = decimal_pos > 15 ? 15 : decimal_pos;
            var left_side = input_val.substring(0, decimal_pos);
            var right_side = input_val.substring(decimal_pos);
            left_side = this.formatNumber(left_side, 'currency', true);
            right_side = this.formatNumber(right_side, 'currency', false);
            if (blur === "blur") {
                right_side += "00";
            }
            // Limit decimal to only 2 digits
            right_side = right_side.substring(0, 2);
            // join number by .
            input_val = "$" + left_side + "." + right_side;

        } else {
            // no decimal entered
            // add commas to number
            // remove all non-digits
            input_val = input_val.substring(0, 15);
            input_val = this.formatNumber(input_val, 'currency', true);
            input_val = "$" + input_val;
            // final formatting
            if (blur === "blur") {
                input_val += ".00";
            }
        }
        if (input_val.charAt(input_val.indexOf('$') + 1) == '.') {
            input_val = input_val.replace(input_val.charAt(input_val.indexOf('$') + 1), '0.');
        }
        if (respValue == '' || respValue == undefined) {
            input.target["value"] = input_val;
        } else {
            return input_val;
        }
    }

    // formats the value with commas and percentage allowing 1 decimal value
    formatPercent(input, blur, respValue) {
        var input_val = "";
        if (respValue == '' || respValue == undefined) {
            input_val = input.target.value;
        } else {
            input_val = respValue;
        } if (input_val === "") { return; }
        var original_len = input_val.length;
        if (input_val.indexOf(".") >= 0) {
            var decimal_pos = input_val.indexOf(".");
            var left_side = input_val.substring(0, decimal_pos);
            var right_side = input_val.substring(decimal_pos);
            left_side = this.formatNumber(left_side, 'percent', true);
            right_side = this.formatNumber(right_side, 'percent', false);
            if (blur === "blur") {
                right_side += "00";
            }
            // Limit decimal to only 2 digits
            right_side = right_side.substring(0, 2);
            // join number by .
            input_val = left_side + "." + right_side + "%";
        } else {
            // no decimal entered
            // add commas to number
            // remove all non-digits
            input_val = this.formatNumber(input_val, 'percent', true);
            if (blur === "blur") {
                input_val += ".00";
            }
            input_val = input_val + "%";
        }
        if (input_val.charAt(input_val.indexOf('$') + 1) == '.') {
            input_val = input_val.replace(input_val.charAt(input_val.indexOf('$') + 1), "0.")
        }
        if (respValue == '' || respValue == undefined) {
            input.target["value"] = input_val;
        } else {
            return input_val;
        }
    }

    // input_val = this.formatNumber(input_val, 'currency', true);

    percentChange($event: any, key: any, index: any) {
        let input_val = $event.target.value.replace(/,|\s/g, "").replace("%", "").replace("$", "");
        if (key == 'surrender_percent') {
            this.surrender_percent = this.formatPercent('', 'blur', input_val);
            this.SurrPercentageCheck($event,this.surrender_percent);
        }
    }

    SurrPercentageCheck (event,inputVal) {
        let totalFundsWithPer = inputVal.includes('%') ? inputVal.slice(0,-1) : inputVal;
        let totalFundsInt = Number(totalFundsWithPer);
        if(totalFundsInt>100) {
            let alertMsg = [];
            alertMsg.push("Total Percentage can not be more than 100");
            let dialogRef = OcInfraModule.AppInjector.get(CommonService).showConfirmation({ content: alertMsg, custom: true }, 'CLOSE', '', 'ALERT', '393px');
            dialogRef.afterClosed().subscribe(res => {
                event.target.value = this.formatPercent('', 'blur', '0.0');
                this.surrender_percent = event.target.value;
                res;
            });
        } else {
            let grossCashValue = this.surrenderDetails.gross_cash_value;
            this.calculatePerToAmount(grossCashValue,inputVal,event);
        }
    }

    inputChange($event: any, key: any, index: any) {
        let input_val = $event.target.value.replace(/,|\s/g, "").replace("%", "").replace("$", "");
        if (key == 'surrender_percent') {
            this.surrender_percent = this.formatPercent('', 'blur', input_val);
        } else if (key == 'surrender_amount') {
            this.surrender_amount = this.formatCurrency('', 'blur', input_val);
            if (this.isAmountAllowableAlertFull(input_val)) {
                let content = 'Can not withdraw more than $29,999.99';
                input_val = "0.0";
                let dialogRef = OcInfraModule.AppInjector.get(CommonService).showConfirmation(content, 'ok', '', 'ALERT', '393px');
                dialogRef.afterClosed().subscribe(res => {
                    $event.target.value = this.formatCurrency('', 'blur', '0.0');
                    this.surrenderForm.controls.surrender_amount.setValue($event.target.value);
                    res;
                });
            }
        } else if (key == 'surrender_amt' || key == 'surrender_pct') {

            if (key == 'surrender_amt') {
                let surrender_amt = this.formatCurrency('', 'blur', input_val);
                this.funds[index].surrender_amt = input_val;
                this.totalFunds = this.funds ? this.getTotalofFunds(this.funds, 'CURRENCY') : '$0.0';
                let total = this.totalFunds.replace('$','').replace(',','');
                if (this.isAmountAllowableAlertFull(total)) {
                    let content = 'Can not withdraw more than $29,999.99';
                    input_val = "0.0";
                    this.funds[index].surrender_amt = input_val;
                    this.totalFunds = this.funds ? this.getTotalofFunds(this.funds, 'CURRENCY') : '$0.0';
                    let total = this.totalFunds.replace('$','').replace(',','');
                    let dialogRef = OcInfraModule.AppInjector.get(CommonService).showConfirmation(content, 'ok', '', 'ALERT', '393px');
                    dialogRef.afterClosed().subscribe(res => {
                        $event.target.value = this.formatCurrency('', 'blur', '0.0')
                        res;
                    });
                }
            } else if (key == 'surrender_pct') {

                this.funds[index].surrender_percent = input_val;
                let currentFundValue= this.funds[index];
                let totalFundsWithPer = input_val.includes('%') ? input_val.slice(0,-1) : input_val;
                let totalFundsInt = Number(totalFundsWithPer);
                if(totalFundsInt>100) {
                let alertMsg = [];
                input_val = '0.0';
                this.funds[index].surrender_percent = input_val;
                alertMsg.push("Total Percentage can not be more than 100");
                let dialogRef = OcInfraModule.AppInjector.get(CommonService).showConfirmation({ content: alertMsg, custom: true }, 'CLOSE', '', 'ALERT', '393px');
                dialogRef.afterClosed().subscribe(res => {
                    $event.target.value = this.formatPercent('', 'blur', '0.0');
                    this.surrender_percent = $event.target.value;
                    res;
                 });
                } else {
                    this.calculatePerToAmount(currentFundValue,input_val,$event);
                }
                this.totalFunds = this.funds ? this.getTotalofFunds(this.funds, 'PERCENT') : '0.0%';
            }
        }
    }

    calculatePerToAmount(funds,perEntered,event){
        if(this.surrenderForm.get('transaction_level').value == 'F') {
        let fundValuee = funds.fund_value; 
        this.totalFundscalculated = '';
        let perToAmountCal = ((fundValuee * perEntered)/100);
        perToAmountCal = parseFloat(perToAmountCal.toFixed(2));
        funds.surrender_amount_cal = perToAmountCal.toString();
        this.totalFundscalculated = this.funds ? this.getTotalofFunds(this.funds,'AmountCal') : '$0.0';
        //total = this.getTotalofFunds(, 'CURRENCY');
        this.totalFundscalculated = this.totalFundscalculated.replace('$','').replace(',','');
        if(this.isAmountAllowableAlertFull(this.totalFundscalculated)){
            perEntered = '0.0';
            funds.surrender_percent = perEntered;
            funds.surrender_amount_cal = '0.00'
            this.totalFundscalculated = this.funds ? this.getTotalofFunds(this.funds,'AmountCal') : '$0.0';
            let content = 'Can not withdraw more than $29,999.99';
            let dialogRef = OcInfraModule.AppInjector.get(CommonService).showConfirmation(content, 'ok', '', 'ALERT', '393px');
                    dialogRef.afterClosed().subscribe(res => {
                        event.target.value = this.formatPercent('', 'blur', '0.0');
                        res;
                    });
            }
        } else if(this.surrenderForm.get('transaction_level').value == 'P') {
            perEntered = perEntered.replace('%','');
            this.totalFundscalculated = ''; //using same variable used for fundspecific
            let perToAmountCal = ((funds * perEntered)/100);
            perToAmountCal = parseFloat(perToAmountCal.toFixed(2));
            if(this.isAmountAllowableAlertFull(perToAmountCal)){
                //funds.surrender_percent = perEntered;
                //funds.surrender_amount_cal = '$0.00'
                //this.totalFundscalculated = this.funds ? this.getTotalofFunds(this.funds,'AmountCal') : '$0.0';
                let content = 'Can not withdraw more than $29,999.99';
                let dialogRef = OcInfraModule.AppInjector.get(CommonService).showConfirmation(content, 'ok', '', 'ALERT', '393px'); 
                        dialogRef.afterClosed().subscribe(res => {
                            event.target.value = this.formatPercent('', 'blur', '0.0');
                            this.surrenderForm.controls['surrender_percent'].setValue(event.target.value);
                            this.surrenderForm.controls['surrender_amount'].setValue('');
                            res;
                        });
                } else{
                    //let formatedamount = this.formatCurrency('','blur',perToAmountCal);
                    //this.surrenderForm.controls['surrender_amount'].setValue(perToAmountCal);
                    let formattedCurrency = this.formatCurrency('','blur',perToAmountCal.toString());
                    this.surrenderForm.controls['surrender_amount'].setValue(formattedCurrency);
                }
        }
    }

    getTotalofFunds(funds: any, type: string) {
        let total = 0.0;
        let tot = '';
        if (type == 'PERCENT') {
            total = funds.reduce((n, { surrender_percent }) => n + Number(surrender_percent), 0);
            tot = this.formatPercent('', 'blur', total.toString());
        } else if (type == 'CURRENCY') {
            total = funds.reduce((n, { surrender_amt }) => n + Number(surrender_amt), 0);
            tot = this.formatCurrency('', 'blur', total.toString());
        } else if (type == 'AmountCal') {
            total = funds.reduce((n, { surrender_amount_cal }) => n + Number(surrender_amount_cal), 0);
            tot = this.formatCurrency('', 'blur', total.toString());
        }

        return tot;
    }

    /** AMOUNT AND PERCENT FORMATING  */
    formatNumber(n, type, flag) {
        if (flag) {
            n = n.charAt(0) == '0' ? n.replace(n.charAt(0), "") : n;
        }
        if (type == 'currency') {
            return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } else {
            return n.replace(/\D/g, "");
        }
    }

    isAmountAllowableAlertFull(input_val: any) {
        if (this.isCv15Contract && Number(input_val) > 29999.99) {
            return true
        } else {
            return false;
        }
    }

}
