import { Component, OnInit, EventEmitter, Output, ElementRef } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { PartySearchFactory } from '../../factories/partySearchFactory';
import { CommonService } from '../../services/common.service';
import {Sort} from '@angular/material';
import { ResourceService, OcInfraModule, APICallerService, OptionParams } from '@diaas/ux-web';
import * as _ from 'lodash';

export interface LoanData {
  effective_date:Date;
  amount: number;
  interest_rate:number;
}
@Component({
  selector: 'loan-history-table',
  templateUrl: './loanHistoryTable.component.html',
})

export class LoanHistoryTableComponent implements OnInit {
  displayedColumns: string[] = ['effective_date', 'processed_date', 'loan_interest_rate', 'capitalization', 'initiation'];
  selectedDateLoans: Object = {};
  noRecords: boolean = true;
  loanInterestRate: string = '';
  loanData: LoanData[];
  olsPolicyDetails: boolean = false;
  panoPolicyDetails: boolean = false;
  isUlPolicy: boolean = false;
  isNonULPolicy: boolean = false;
  constructor(public elementRef: ElementRef){

  }

  ngOnInit() {
    this.olsPolicyDetails = this.elementRef.nativeElement.id == 'olsLoanHistoryTable'?true:false;
    this.panoPolicyDetails = this.elementRef.nativeElement.id == 'loanHistoryTable'?true:false;
    var loanHistoryURL = OcInfraModule.AppInjector.get(CommonService).getAllUrlOfPolicyDetails();
    if (loanHistoryURL["loans/loantransactionhistory"] && loanHistoryURL["loans/loantransactionhistory"].href) {
      let selectedPolicyInfo = OcInfraModule.AppInjector.get(CommonService).productInfo;
      if (selectedPolicyInfo.product_type.includes('U')) {
        this.isUlPolicy = true;
      }else{
        this.isNonULPolicy=true;
      }
      let optionParams = new OptionParams();
      optionParams.headers = OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("loanTransactionHistory");
      OcInfraModule.AppInjector.get(APICallerService).get(loanHistoryURL["loans/loantransactionhistory"].href).subscribe(response => {
        OcInfraModule.AppInjector.get(CommonService).loanMessageId = response;
       OcInfraModule.AppInjector.get(CommonService).loanInterestRate = response.response.loan_interest_rate;
        if(response.response && response.response._links.item){
          response.response._links.item.forEach(obj => {
            obj.summary.effective_date = new Date(obj.summary.effective_date);
          let intreratRate = _.pickBy(obj.summary.interest_rate, function (value, key) {
            return key == 'interest_rate';
          });
          Object.assign(obj, intreratRate)
        });
        this.loanData = response.response._links.item;
        this.sortedData = response.response._embedded ? [] : response.response._links.item;
        this.noRecords = !this.sortedData || !this.sortedData.length;
        this.sortData({ active: "effective_date", direction: "asc" });
      }
      this.noRecords = !this.sortedData || !this.sortedData.length;
      });
    }
  }

  formatDateToMMDDYYYY(inputDate) {
    let date, month, year;
  
    date = inputDate.getDate();
    month = inputDate.getMonth() + 1;
    year = inputDate.getFullYear();
  
      date = date
          .toString()
          .padStart(2, '0');
  
      month = month
          .toString()
          .padStart(2, '0');
  
    return `${month}/${date}/${year}`;
  }

  testChange(event, data, selectedValue, selectedKey, columnName) {
    let apiName = "loans/loantransactionhistory";
    let loanInfo: any;
    loanInfo = localStorage.getItem('loanInfo');
    loanInfo == null ? this.selectedDateLoans = {} : this.selectedDateLoans = JSON.parse(loanInfo);
    let date = new Date(data.effective_date);
    let effectiveDate = this.formatDateToMMDDYYYY(date);
    let objID = effectiveDate +"_"+data.history_id +'_v';
    selectedValue = selectedKey == 'Capitalization' || selectedKey == 'Initiation' ? '$' + selectedValue.replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,') : selectedValue;
    if (event.checked) {
      let newObj: boolean = false;
      this.selectedDateLoans[objID] ? this.selectedDateLoans[objID][selectedKey] = selectedValue : newObj = true;;
      if (newObj) {
        this.selectedDateLoans[objID] = JSON.parse("{}");
        this.selectedDateLoans[objID]["Effective Date"] = effectiveDate;
        this.selectedDateLoans[objID][selectedKey] = selectedValue ? selectedValue : 'No data in admin system.';
      }
    } else {
      delete this.selectedDateLoans[objID][selectedKey]
      _.size(this.selectedDateLoans[objID]) > 1 ? '' : delete this.selectedDateLoans[objID];
    }
    let html = '';
    Object.keys(this.selectedDateLoans).forEach(keys => {
      Object.keys(this.selectedDateLoans[keys]).forEach(key => {
        html += '<dt class="pull-left">' + key + ':</dt><dd>&nbsp;' + this.selectedDateLoans[keys][key] + '</dd>';
      })
    })

    localStorage.setItem('loanInfo', JSON.stringify(this.selectedDateLoans));
    OcInfraModule.AppInjector.get(CommonService).loanHtml = html;
    OcInfraModule.AppInjector.get(CommonService).showLoanFinalDetails();
    OcInfraModule.AppInjector.get(CommonService).showRequestedDetails();
  }

  sortedData: LoanData[];
  sortData(sort) {
    sort.direction == '' ? sort.direction = 'asc' :'';
    const data = this.loanData.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }
    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'effective_date': return compare(a.effective_date, b.effective_date, isAsc);
        // case 'amount': return compare(a.amount, b.amount, isAsc);
        default: return 0;
      }
    });
  }
}

function compare(a: number | Date | string, b: number | Date | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
