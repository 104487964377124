import { Component, OnInit, EventEmitter, Output, ElementRef, ViewChild } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatPaginator, MatTableDataSource, MatSort } from '@angular/material';
import { APICallerService, Configuration, OcInfraModule, OptionParams, ResourceSchemaService, ResourceService } from '@diaas/ux-web';
import * as _ from 'lodash';
export interface ValueAssetData {
  eff_date: string;
  work_type: string;
  status: string;
}
@Component({
  selector: 'app-work-request-history',
  templateUrl: './work-request-history.component.html',
  styleUrls: ['./work-request-history.component.scss']
})
export class WorkRequestHistoryComponent implements OnInit {

  displayedColumns: string[] = ['eff_date', 'work_type', 'status'];
  noRecords: boolean = true;
  assetData: ValueAssetData[];
  ValueAssetData: any;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  WrkReqRes: any;
  nextBlock: boolean;
  previousBlock: boolean;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }

  constructor(public elementRef: ElementRef, public commonService: CommonService) {
    this.commonService.getFilterEvent().subscribe(() => {
      if (this.commonService.wrkReqHistoryRes && this.commonService.wrkReqHistoryRes._links && this.commonService.wrkReqHistoryRes._links.item) {
        if (this.commonService.wrkReqHistoryRes._links.previous) {
          this.previousBlock = true;
        } else {
          this.previousBlock = false;
        }
        if (this.commonService.wrkReqHistoryRes._links.next) {
          this.nextBlock = true;
        } else {
          this.nextBlock = false;
        }
        this.WrkReqRes = this.commonService.wrkReqHistoryRes.response._links;
        this.assetData = this.commonService.wrkReqHistoryRes.response._links.item;
        this.ValueAssetData = new MatTableDataSource(this.assetData);
        this.ValueAssetData.paginator = this.paginator;
        this.noRecords = !this.assetData || !this.assetData.length;
      }
      this.sortData({ active: "eff_date", direction: "desc" });
    })
  }

  ngOnInit() {
    this.assetData = [];
    this.ValueAssetData = new MatTableDataSource(this.assetData);
    setTimeout(() => {
      this.getValueAssetData();
    },5000);
  }

    getValueAssetData() {
        var valueAssetURL = OcInfraModule.AppInjector.get(CommonService).getAllUrlOfPolicyDetails();
        if (valueAssetURL["instances"] && valueAssetURL["instances"].href) {
            let optionParams = new OptionParams();
            optionParams.headers = OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("workRequestHistory");
            this.assetData = [];
            this.ValueAssetData = new MatTableDataSource(this.assetData);
            const resourceService = OcInfraModule.AppInjector.get(ResourceService);
            let response = resourceService.getResourceFromAliasName("workRequestHistory").response;
            if (response) {
                this.processWorkReq(response);
            } else {
                OcInfraModule.AppInjector.get(CommonService).getCallback(valueAssetURL["instances"].href, optionParams.headers).subscribe((response: any) => {
                    resourceService.updateCreateAliasName('workRequestHistory', valueAssetURL["instances"].href, true);
                    this.processWorkReq(response);
                }, (err: any) => {

                })
            }

        }
    }

  processWorkReq = (response: any) => {
    if (response && response._links && response._links.item) {
        if (response._links.previous) {
          this.previousBlock = true;
        } else {
          this.previousBlock = false;
        }
        if (response._links.next) {
          this.nextBlock = true;
        } else {
          this.nextBlock = false;
        }
        this.WrkReqRes = response._links;
        this.assetData = response._links.item;
        this.ValueAssetData = new MatTableDataSource(this.assetData);
        this.ValueAssetData.paginator = this.paginator;
        this.sortData({ active: "eff_date", direction: "desc" });
      }
      let isEmbedded = OcInfraModule.AppInjector.get(CommonService).getNested(response, '_embedded');    
      let isItem = OcInfraModule.AppInjector.get(CommonService).getNested(response, '_links',"item");  
      this.sortedData = isEmbedded ? [] :isItem;
      this.noRecords = !this.assetData || !this.assetData.length;
      this.ValueAssetData.sort = this.sort;
  }

  setDataSourceAttributes() {
    if (this.ValueAssetData) {
      this.ValueAssetData.paginator = this.paginator;
      this.ValueAssetData.sort = this.sort;
    }
  }


  sortedData: ValueAssetData[];
  sortData(sort) {
    const data = this.ValueAssetData.filteredData.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }
    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'eff_date': return compare(new Date(a.summary.datetime).getTime(), new Date(b.summary.datetime).getTime(), isAsc);
        default: return 0;
      }
    });
    this.ValueAssetData = new MatTableDataSource(this.sortedData);
  }

  getBlock(block) {
    let optionParams = new OptionParams();
    optionParams.headers = OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("workRequestHistory");
    this.assetData = [];
    this.ValueAssetData = new MatTableDataSource(this.assetData);
    OcInfraModule.AppInjector.get(CommonService).getCallback(this.WrkReqRes[block].href, optionParams.headers).subscribe(response => {
      if (response && response._links && response._links.item) {
        if (response._links.previous) {
          this.previousBlock = true;
        } else {
          this.previousBlock = false;
        }
        if (response._links.next) {
          this.nextBlock = true;
        } else {
          this.nextBlock = false;
        }
        this.WrkReqRes = response._links;
        this.assetData = response._links.item;
        this.ValueAssetData = new MatTableDataSource(this.assetData);
        this.ValueAssetData.paginator = this.paginator;
        this.sortData({ active: "eff_date", direction: "desc" });
      }
      this.sortedData = response._embedded ? [] : response._links.item;
      this.noRecords = !this.assetData || !this.assetData.length;
    });
    this.ValueAssetData.sort = this.sort;
  }

  onRowClick(displayedColumns, row) {
    OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false });
    const resourceService = OcInfraModule.AppInjector.get(ResourceService);
    const optionParams: Object = {
      'alias': "selectedWorkRequest",
      'headers': OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("selectedWorkRequest")
    }
    OcInfraModule.AppInjector.get(APICallerService).refresh(row.href, optionParams, 'RESET').subscribe(response => {
      OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
      resourceService.updateCreateAliasName('selectedWorkRequest', row.href, true);
      document.getElementById("work-request-history-open-work-request-details-anchor").click();
      OcInfraModule.AppInjector.get(CommonService).selectedSourceForFax = [];
      OcInfraModule.AppInjector.get(CommonService).attachDocWorkReq = false;
    });
  }
}

function compare(a: number | Date | string, b: number | Date | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}