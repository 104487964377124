import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ResourceService, OcInfraModule, APICallerService, Configuration } from '@diaas/ux-web';
import { CommonService } from '../../../services/common.service';
import { AdvGrowlService } from 'primeng-advanced-growl';
import { ToasterService } from 'angular2-toaster';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import "moment-timezone";
import * as _ from 'lodash';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-future-allocation-change',
  templateUrl: './future-allocation-change.component.html',
  styleUrls: ['./future-allocation-change.component.scss'],
})

export class FutureAllocationChangeComponent implements OnInit {

  futureAllocationChangeForm = new FormGroup({
    isChecked: new FormControl(false, [Validators.requiredTrue]),
    effectiveDate: new FormControl(null,  [Validators.required]),
    allocatedBy: new FormControl(null, Validators.required),
    accountType : new FormControl(null),
    productType : new FormControl(null)
  })

  resourceService: any;
  policyDetails: any;
  getFutureAllocationChangeURL: any;
  postAllocationChangeUrl: any;
  headers = { ...{ role: OcInfraModule.AppInjector.get(CommonService).authorizedRole, client_id: OcInfraModule.AppInjector.get(CommonService).productInfo.client, wma_user : window.sessionStorage.getItem('username')}, user_role:"csr", agent_code: OcInfraModule.AppInjector.get(CommonService).agentId, ...OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("search")};
  allocationChangeDetails: any;
  authorizedRole: any;
  lastAllocationChangeDate: any;
  isPortfolioPlatform: boolean = false;
  disableCopyFromFundTransfer: boolean;
  fundsList: any;
  fundsCopy: any;
  totalOfFundsPercent: any;
  filteredFunds: any[];
  payload: any;
  isNextBusinessDay: boolean = false;
  confirmationOverride: boolean = false;
  confirmationDate: Date;
  allocatedByOptions: any[];
  alerts: any;
  alertColor: any;
  isSuccess: boolean = false;
  disableFutureAllocationTab: boolean;
  alertMessages: any;
  disableCreate: boolean = false;
  tableSortStates: any;
  funds: any;
  groupedFunds: any;
  isReadonly: boolean = false;
  displayStockMaketDialog = true;
  productTypes: any[];
  accountType: string;
  assetAccountTypes: any[];
  accTypeItems: any;
  selectionChange: boolean = true;
  getPlanCodeUrl: any;
  getAssetAccountUrl: any;
  isGroupProduct: boolean = false;
  activeIndex: any;
  @Input() fundTransferDetails: any;
  @Output() alertsEvent = new EventEmitter<any>();
  @Output() allocationChangeValues = new EventEmitter<any>();
  @Output() scrollTop: EventEmitter<string> = new EventEmitter<string>();

 

  constructor(private toasterService: ToasterService, private datePipe: DatePipe, private commonService: CommonService) { }

  ngOnInit() {
    this.authorizedRole = OcInfraModule.AppInjector.get(CommonService).authorizedRole;
    this.futureAllocationChangeForm.controls['isChecked'].setValue(!this.checkIfAgentOrAgentAssistant());
   // this.disableCopyFromFundTransfer = true;
    this.resourceService = OcInfraModule.AppInjector.get(ResourceService);
    this.policyDetails = this.resourceService.getResourceFromAliasName('policyDetails')? this.resourceService.getResourceFromAliasName('policyDetails').response : null;
    this.getFutureAllocationChangeURL = this.policyDetails && this.policyDetails._links&&this.policyDetails._links['funds/allocations'] ? this.policyDetails._links['funds/allocations'].href : null;
    
    if(this.policyDetails && this.policyDetails.product_type  == 'A2'){
      this.getFutureAllocationChangeURL ? this.getFutureAllocationDetails(): this.disableFutureAllocationTab = true;
    }
    
    this.headers.role = this.authorizedRole;
    this.isGroupProduct = this.isGroupProductType();
    if (this.isGroupProduct) {
        this.processA4Product();
    } else {
        this.getFutureAllocationDetails();
    }

    this.tableSortStates = [
      {
        "column": "number",
        "isAsc": false
      },
      {
        "column": "name",
        "isAsc": false
      },
      {
        "column": "allocation_percent",
        "isAsc": true
      },
    ];
  }

    isGroupProductType = () => {
        let product_type = this.getNested(this.policyDetails, 'product_type');
        if (product_type && (product_type == "A4" || product_type== "A3")) {
            return true;
        } else {
            return false;
        }
    }

    processA4Product = () => {
        // COMMENTING FOR - PANOCSR-2590
        // if (this.authorizedRole === 'Authorized Third Party' || this.authorizedRole === 'Guardian' || this.authorizedRole === 'POA-Power of Attorney' || this.authorizedRole === 'Power of Attorney') {
        //     this.disableFutureAllocationTab = true;
        // } else {
        //     this.disableFutureAllocationTab = false;
        // }

        this.getPlanCodeUrl = this.policyDetails && this.policyDetails._links && this.policyDetails._links.clientproducts ? this.policyDetails._links.clientproducts.href : null;
        this.getAssetAccountUrl = this.policyDetails && this.policyDetails._links && this.policyDetails._links.clientassetaccounts ? this.policyDetails._links.clientassetaccounts.href : null;

        let clientProducts = OcInfraModule.AppInjector.get(CommonService).getClientProducts();
        if (!clientProducts) {
            this.getClientProductsCall()
        } else {
            this.processClientProducts(clientProducts);
        }

        let clientAssetAccounts = OcInfraModule.AppInjector.get(CommonService).getClientAssetAccountsAll();
        if (!clientAssetAccounts) {
            this.getClientAssetAccountsCall()
        } else {
            this.accTypeItems = OcInfraModule.AppInjector.get(CommonService).getClientAssetAccountsAll();
        }

    }


    getNested(obj, ...args) {
        return args.reduce((obj, level) => obj && obj[level], obj)
    }

    getClientProductsCall = () => {
        OcInfraModule.AppInjector.get(CommonService).getCallback(this.getPlanCodeUrl, this.headers).subscribe(response => {
            this.processClientProducts(response._links.item);
        }, error => {
            if (error && error._embedded && error._embedded.status_report && error._embedded.status_report.consistent === false) {
                OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error._embedded.status_report.messages[0].message, 'Error', 0);
            }
        });
    }

    getClientAssetAccountsCall = () => {
        OcInfraModule.AppInjector.get(CommonService).getCallback(this.getAssetAccountUrl, this.headers).subscribe(response => {
            this.accTypeItems = response._links.item;
            OcInfraModule.AppInjector.get(CommonService).getClientAssetAccountsAll = response._links.item;
        }, error => {
            if (error && error._embedded && error._embedded.status_report && error._embedded.status_report.consistent === false) {
                OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error._embedded.status_report.messages[0].message, 'Error', 0);
            }
        });
    }

processClientProducts(prodTypeItems){
    if (prodTypeItems) {
      this.productTypes = [];
      for (let i = 0; i < prodTypeItems.length; i++) {
        if(prodTypeItems[i].summary.product_type != "A3")
        this.productTypes.push({ value: prodTypeItems[i].summary.plan_code, viewValue: prodTypeItems[i].summary.plan_code });
      }
    }
  }

  getFutureAllocationDetails() {
      OcInfraModule.AppInjector.get(CommonService).getCallback(this.getFutureAllocationChangeURL, this.headers).subscribe(response => {
        this.allocationChangeDetails = response;
        this.funds = response ? cloneDeep(response.funds) : [];       
        this.postAllocationChangeUrl = this.allocationChangeDetails && this.allocationChangeDetails._options.links.find(link => link.method.toLowerCase() === 'post');
        if(this.postAllocationChangeUrl  ==null){
          this.isReadonly = true;
        }else{
          this.isReadonly = false;
          this.disableFutureAllocationTab = false;
        }
        if (this.allocationChangeDetails._embedded && this.allocationChangeDetails._embedded.status_report) {
          this.alertMessages = this.allocationChangeDetails._embedded.status_report.messages;         
          this.alertsEvent.emit({
            alertMessages : this.alertMessages,
            tabName: 'FUTUREALLOCATION',
            activeIndex: this.activeIndex,
            isDisabled: this.disableFutureAllocationTab    // OCCD-3622 emitting isDisabled for tab disabling functionality
          });
        }
        this.futureAllocationChangeForm.controls['effectiveDate'].setValue(this.allocationChangeDetails.effective_date ? moment(this.allocationChangeDetails.effective_date) : null);
        this.allocatedByOptions = [];
        if (this.allocationChangeDetails && this.allocationChangeDetails.allocated_by && this.allocationChangeDetails.allocated_by.length > 0) {
            const allocatedBy = this.allocationChangeDetails._options.properties.allocated_by.oneOf.find(opt => opt.enum.includes( this.allocationChangeDetails.allocated_by));
            if (allocatedBy) {
              this.allocatedByOptions.push({ value: allocatedBy.title, viewValue: allocatedBy.title })
            }
         
          const defaultAllocatedByOption = this.allocatedByOptions.find((item) => item.value === 'Percent');
          if (defaultAllocatedByOption) {
            this.futureAllocationChangeForm.controls['allocatedBy'].setValue(defaultAllocatedByOption.value);
          } else {
            this.futureAllocationChangeForm.controls['allocatedBy'].setValue(this.allocatedByOptions[0].value);
          }
          this.futureAllocationChangeForm.controls['allocatedBy'].updateValueAndValidity();
        }
        this.fundsCopy = _.cloneDeep( this.allocationChangeDetails.funds);
        this.lastAllocationChangeDate = this.allocationChangeDetails.last_allocation_change_date ? this.allocationChangeDetails.last_allocation_change_date : null ;
        this.fundsList = this.allocationChangeDetails && this.allocationChangeDetails.funds && this.allocationChangeDetails.funds.length > 0 ? this.allocationChangeDetails.funds : null;
        this.totalOfFundsPercent = this.fundsList ? this.getTotalofAllocationPercent(this.fundsList) : '0.0';
        this.sortTable('allocation_percent');
        this.enableSubmit();
      }, error => {
        this.disableCreate =true;
        OcInfraModule.AppInjector.get(CommonService).showHideSpinner({showSpinner: false, edit: false});
        if (error && error._embedded && error._embedded.status_report && error._embedded.status_report.consistent === false) {
          OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error._embedded.status_report.messages[0].message, 'Error', 0);
       }
      });
  }
 

  // Checks if The user is authorized as Agent/Agent Assistant
  checkIfAgentOrAgentAssistant(){
    return this.authorizedRole === 'Agent' || this.authorizedRole === 'Agent Assistant' || this.authorizedRole === 'BOA Back Office Assistant (Advisor Assistant)' ? true : false;
  }

  // Calculates total of allocation percent column
  getTotalofAllocationPercent(fundsList){
    let total = 0.0;
    for(let i = 0; i < fundsList.length; i++){
      fundsList[i].allocation_percent = fundsList[i].allocation_percent.indexOf('%')>-1 ? fundsList[i].allocation_percent.replace('%',''):fundsList[i].allocation_percent;
      total += Number(fundsList[i].allocation_percent);
    }
    return this.roundToTwo(total);
  }

  // handles input change
  inputChange(input, i, j,fund){   
    let input_value = input.target.value;
    if (fund) {
      fund.isChanged = input_value && input_value !== '';
    }   
    this.fundsList[i].allocation_percent = input_value;
    this.totalOfFundsPercent = this.fundsList ? this.getTotalofAllocationPercent(this.fundsList) : '0.0';    
    this.enableSubmit();
  }

  setAllocationPercent() {
    for(let i = 0; i < this.fundsList.length; i++) {
      const copiedFund = this.fundTransferDetails.funds.find((item) => item.number === this.fundsList[i].number);
      if (copiedFund.from_to_indicator == 'T' && copiedFund.percent) {
        this.fundsList[i].allocation_percent = copiedFund.percent;
        this.fundsList[i].fund_value = copiedFund.amount;
        this.fundsList[i].isChanged = true;
      }
      else {
        this.fundsList[i].allocation_percent = '0.0';
        this.fundsList[i].fund_value = '0.0';
        this.fundsList[i].isChanged = false;
      }
    }
    this.totalOfFundsPercent = this.fundsList ? this.getTotalofAllocationPercent(this.fundsList) : '0.0';
  }


  // Handles Submit
  onSubmit(e) {
    this.displayStockMaketDialog = (e !== false);
    this.scrollTop.emit('scroll');
    this.checkIsBusinessClosed();
  }

  // Displaying an alert on submit after 4pm est
  checkIsBusinessClosed() {
    let curr = moment(new Date()).tz('America/New_York');
    if (curr.hours() >= this.allocationChangeDetails.business_closehour && this.displayStockMaketDialog) {
      this.displayStockMaketDialog = false;
      let content = 'The stock market is closed and the transaction will be completed on next business day. Do you want to proceed ?';
      let title = 'Alert';
      let dialogRef = OcInfraModule.AppInjector.get(CommonService).showConfirmationPopup(content, 'No', 'Yes', title, '340px');
      dialogRef.afterClosed().subscribe(res => {
        if (res) {
          this.isNextBusinessDay = true; // isNextBusinessDay is to check if the user clicked yes
          this.processFutureAllocationChange(true);
        }
      });
    } else {
      this.processFutureAllocationChange(true);
    }
  }

  // POST API Integration
  processFutureAllocationChange(clicked) {
    OcInfraModule.AppInjector.get(AdvGrowlService).clearMessages();
    this.alerts =[];
    clicked ? this.createPayload() : null;
      OcInfraModule.AppInjector.get(CommonService).postCallback(this.postAllocationChangeUrl.href, this.payload, this.headers).subscribe(response => {
          (!response.confirm_override) && Object.assign(response, { confirm_override: '' }) 
    
      switch (response.confirm_override) {
                // soft error scenario
        case 'Y': {
          this.displayAlerts(response);
          this.scrollTop.emit('scroll');
          let content = 'Are you sure?  You want to override ?';
          let title = 'Alert';
          let dialogRef = OcInfraModule.AppInjector.get(CommonService).showConfirmationPopup(content, 'No', 'Yes', title, '340px');
          dialogRef.afterClosed().subscribe(res => {
            if (res) {
              this.payload.confirm_override = "Y"
              this.payload.next_business_day = response.next_business_day;
              this.payload.effective_date = response.effective_date;
              this.confirmationOverride = true;
              this.onSubmit(false);
              this.alerts = [];
              this.alertColor = 'orange';
            }
          });
          break;
        }
        // hard error scenario
        case 'N': {
          this.displayAlerts(response);
          this.alertColor = 'red';
          this.disableCreate = true;
          break;
        }
        // success scenario
        case '': {
          this.disableCreate = true;
          this.alerts = [];
          OcInfraModule.AppInjector.get(AdvGrowlService).createSuccessMessage(' Future Allocation Change Processed Successfully', 'Success');
          this.isSuccess = true;
          this.allocationChangeValues.emit({
            effectiveDate : this.datePipe.transform(this.futureAllocationChangeForm.controls['effectiveDate'].value, "MM/dd/yyyy"),
            allocatedBy: this.futureAllocationChangeForm.controls['allocatedBy'].value,
            selectedFunds: this.filteredFunds,
            funds: this.fundsList,
            totalSelectedFunds: this.getTotalofAllocationPercent(this.filteredFunds)
          });
          break;
        }
      }

    }, error => {
      OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error._embedded && error._embedded.status_report && error._embedded.status_report.messages[0].message, 'Error', 0);
    })
  }

  createPayload() {
    let header = _.filter(Configuration.config.headers["multiHostUrlHeader"], function (o) { return o.alias == 'workRequestHistory' });
    const authResponse = this.resourceService.getResourceFromAliasName('authRes');
    let policyDetails = this.resourceService.getResourceFromAliasName('policyDetails');
    Object.assign(this.headers, { product_type: policyDetails.data.product_type});
    const selectedRoleSummary = this.resourceService.getResourceFromAliasName('selectedRoles');
    let roleList = this.resourceService.getResourceFromAliasName('roles');
    let otherRoleAuthorize = _.isEmpty(selectedRoleSummary.data) ? _.filter(roleList._links.item, function (o) { return o.summary.role == 'Owner'; }) : false;
    let date = this.futureAllocationChangeForm.controls['effectiveDate'].value;
    this.getFilteredFunds();
    let changedFunds = [];
    this.fundsList.forEach(fund => 
      changedFunds.push({
        "fund_percent": fund.fund_percent,
        "fund_type": fund.fund_type,
        "fund_type_desc": fund.fund_type_desc,
        "units": fund.units,
        "allocation_percent": fund.allocation_percent,
        "number": fund.number,
        "fund_value": fund.fund_value,
        "name": fund.name,
        "unit_value": fund.unit_value,
        "interest_rate": fund.interest_rate,
        "air_unit_value": fund.air_unit_value,
        "percent_of_total_contract": fund.percent_of_total_contract
    }));


    this.payload = {
      "pending_record": false,
      "effective_date": this.datePipe.transform(date, "MM/dd/yyyy"),
      "allocated_by": 'N', // since it is always Percent
      "confirm_override": this.confirmationOverride === true ? 'Y' : '',
      "funds": changedFunds,
      "sessionId": authResponse && authResponse.data.contactSessionID ? authResponse.data.contactSessionID : '',
      "callStart": authResponse && authResponse.data.callStart ? authResponse.data.callStart : '',
      "clientId": otherRoleAuthorize ? otherRoleAuthorize[0] && otherRoleAuthorize[0].summary.clientId ? otherRoleAuthorize[0].summary.clientId : '' : selectedRoleSummary && selectedRoleSummary.data.clientId ? selectedRoleSummary.data.clientId : '',
      "userId": OcInfraModule.AppInjector.get(CommonService).incontactId,
      "policyNumber": policyDetails.data.policy_number,
      "company_code": policyDetails.data.company_code,
      "region_code": header[0].headers.region_code,
      "remote_user": header[0].headers.remote_user,
      "origin_app": "csr_portal",
      "incontactId": authResponse && authResponse.data.contactID ? authResponse.data.contactID : ''
    };
    
  }

  roundToTwo(num) {
    if(Math.abs(num % 1) > 0.01) {
      return num.toFixed(2);
    } else {
      return num;
    }
  }

  enableSubmit() {
    if( this.totalOfFundsPercent !=100){
      this.disableCreate = true;
      return;
    }else{
      this.disableCreate = false;
    }
     if (!this.isSuccess) {
      this.disableCreate = false;
    }
  }

  // Displays POST api Alerts
  displayAlerts(response) {
    if ( response._embedded && response._embedded.status_report && response._embedded.status_report.messages && response._embedded.status_report.messages.length > 0) {
      this.alerts = response._embedded.status_report.messages;
    }
  }

  onProductTypeChange(e) {
    this.accountType = '';
    this.selectionChange = true;
    if (this.accTypeItems) {
      this.assetAccountTypes = [];
      for (let i = 0; i < this.accTypeItems.length; i++) {
        if (this.accTypeItems[i].summary.plan_code === e.value) {
          if (this.accTypeItems[i].summary.asset_account_names) {
            for (let j = 0; j < this.accTypeItems[i].summary.asset_account_names.length; j++) {
              if(this.accTypeItems[i].summary.asset_account_names[j] !='ALL ASSET ACCOUNTS'){
              this.assetAccountTypes.push({ value: this.accTypeItems[i].summary.asset_account_names[j], viewValue: this.accTypeItems[i].summary.asset_account_names[j] });
            }
           }
          }
        }
      }
    }
  }

  onAssetAccountChange(e) {
    let selectedAssetAccount;
    let drpdwnObj;
    this.selectionChange = false;
    this.accountType = this.futureAllocationChangeForm.controls['accountType'].value;
    selectedAssetAccount = this.accountType.substring(0, this.accountType.indexOf(' '));
    drpdwnObj = { 'planCode': this.futureAllocationChangeForm.controls['productType'].value, 'assetAccount': selectedAssetAccount, 'accountType': this.accountType};
    this.headers.product =drpdwnObj.planCode; 
    this.headers.assetAccount = selectedAssetAccount;
    this.activeIndex = 1;
    this.getFutureAllocationDetails();
  }

  toggleAlertMsg(event) {
    event.target.classList.toggle("alertMsg-overflow");
  }

  // On successful submit, turns the tab to green
  isStepComplete(){
    return this.isSuccess;
  }

  // returns the list of funds that changed
  getFilteredFunds() {
    this.filteredFunds = this.fundsList.filter((item) => Number(item.allocation_percent) > 0);
    this.filteredFunds.forEach(fund => {
      fund.percent = fund.allocation_percent;
      delete fund.isChanged;
    });
    
  }

  // Amount and Percent Formatting
  formatNumber(n, type, flag) {
    if(flag) {
      n = n.charAt(0) == '0' ? n.replace(n.charAt(0), "") : n;
    }
    if (type == 'currency') {
      return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return n.replace(/\D/g, "");
    }
  }

  //formats the value with commas and $ allowing 2 decimal values
  formatCurrency(input, blur, respValue, fund = null, indicator = null) {
    var input_val = "";
    if (respValue == '' || respValue == undefined) {
      input_val = input.target.value;
    } else {
      input_val = respValue;
    }
    if (input_val === ""&& fund) {
      fund.allocation_amount= input_val;
      return;
    }
    var original_len = input_val.length;
    if (input_val.indexOf(".") >= 0) {
      var decimal_pos = input_val.indexOf(".");
      decimal_pos = decimal_pos > 15 ? 15 : decimal_pos;
      var left_side = input_val.substring(0, decimal_pos);
      var right_side = input_val.substring(decimal_pos);
      left_side = this.formatNumber(left_side, 'currency', true);
      right_side = this.formatNumber(right_side, '', false);
      if (blur === "blur") {
        right_side += "00";
      }
      // Limit decimal to only 2 digits
      right_side = right_side.substring(0, 2);
      // join number by .
      input_val = "$" + left_side + "." + right_side;

    } else {
      // no decimal entered
      // add commas to number
      // remove all non-digits
      input_val = input_val.substring(0, 15);
      input_val = this.formatNumber(input_val, 'currency', true);
      input_val = "$" + input_val;
      // final formatting
      if (blur === "blur") {
        input_val += ".00";
      }
    }
    if (input_val.charAt(input_val.indexOf('$') + 1) == '.') {
      input_val = input_val.replace(input_val.charAt(input_val.indexOf('$') + 1), '0.');
    }
    if (respValue == '' || respValue == undefined) {
      input.target["value"] = input_val;
    } else {
      return input_val;
    }

    if (fund) {
      fund.allocation_amount= input_val;
    }
  }


  // formats the value with percentage allowing 1 decimal value
  formatPercent(input, blur, respValue) {
    var input_val = "";
    if (respValue == '' || respValue == undefined) {
      input_val = input.target.value;
    } else {
      input_val = respValue;
    } if (input_val === "") { return; }
    var original_len = input_val.length;
    if (input_val.indexOf(".") >= 0) {
      var decimal_pos = input_val.indexOf(".");
      var left_side = input_val.substring(0, decimal_pos);
      var right_side = input_val.substring(decimal_pos);
      left_side = this.formatNumber(left_side, 'percent', true);
      right_side = this.formatNumber(right_side, 'percent', false);
      if (blur === "blur") {
        right_side += "0";
      }
      // Limit decimal to only 2 digits
      right_side = right_side.substring(0, 1);
      // join number by .
      input_val = left_side + "." + right_side + "%";
    } else {
      // no decimal entered
      // add commas to number
      // remove all non-digits
      input_val = this.formatNumber(input_val, 'percent', true);
      if (blur === "blur") {
        input_val += ".0";
      }
      input_val = input_val + "%";
    }
    if(input_val.charAt(input_val.indexOf('$')+1) == '.') {
      input_val = input_val.replace(input_val.charAt(input_val.indexOf('$')+1), "0.")
    }
    if (respValue == '' || respValue == undefined) {
      input.target["value"] = input_val;
    } else {
      return input_val;
    }
  }

  // Sorts the fund table based on the column
  sortTable(column) {
    let isAsc;
    let _self = this;
    _.forEach(_self.tableSortStates, function (obj, index) {
      if (obj['column'] == column) {
        isAsc = !obj['isAsc'];
        _self.tableSortStates[index]['isAsc'] = isAsc;
      }
    })

    this.fundsList = isAsc ? _.orderBy(this.fundsList, this.byKey(column), 'asc') : _.orderBy(this.fundsList, this.byKey(column), 'desc');

  }

  // checks if it is a Number String
  byKey(key) {
    return function (o) {
      if (typeof o[key] === 'string') {
        return isNaN(+o[key].replace(/[^a-zA-Z0-9]/g,''))? o[key] : +o[key];
      }
      return o[key];
    };
  }
}
