import { Component, OnInit, ViewChild } from "@angular/core";
import {
  APICallerService,
  OcInfraModule,
  OptionParams,
  ResourceService,
} from "@diaas/ux-web";
import { CommonService, retryOperation } from "../../services/common.service";
import { MatPaginator, MatTableDataSource, MatSort } from "@angular/material";
import * as _ from "lodash";
import { MatDialog } from "@angular/material";
import { PartySearchFactory } from '../../factories/partySearchFactory';

export interface TableData {
  claimType: string;
  claimNumber: string;
  status: string;
}

@Component({
  selector: "app-claim-table",
  templateUrl: "./claim-table.component.html",
  styleUrls: ["./claim-table.component.scss"],
})
export class ClaimsTableComponent implements OnInit {
  displayedColumns: string[] = ["claimType", "claimNumber", "status"];
  noRecords: boolean = true;
  claimTabData: TableData[];
  claimTableData: any;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  claimsRes: any;
  nextBlock: boolean;
  previousBlock: boolean;
  filterParams: any;
  private partySearchFactory = new PartySearchFactory();

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }

  policyDetails: any;
  initCall: boolean = false;
  constructor(public commonService: CommonService, public dialog: MatDialog) {
    this.commonService.getClaimsFilterEvent().subscribe(() => {
      let refreshClaimdata =OcInfraModule.AppInjector.get(CommonService).claimsTableRes;
      if (refreshClaimdata) {       
        this.getClaimData(refreshClaimdata);        
      }
    })
  }

  ngOnInit() {
    this.claimTabData = [];
    this.claimTableData = new MatTableDataSource(this.claimTabData);
    this.getClaimTableData();
  }

  getClaimTableData = async () => {
    const resourceService = OcInfraModule.AppInjector.get(ResourceService);
    let policyDetails = resourceService.getResourceFromAliasName("policyDetails");
    let optionParams = new OptionParams();
    optionParams.alias = "claimList";
    optionParams.headers =
      OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName(
        "claimList"
      );
    await OcInfraModule.AppInjector.get(CommonService).showHideSpinner({showSpinner: true, edit: false });
    OcInfraModule.AppInjector.get(APICallerService)
      .refresh((policyDetails && policyDetails._links && policyDetails._links["claims"] && policyDetails._links["claims"].href)?policyDetails._links["claims"].href:'', optionParams)
      .subscribe(
        async (response) => {
          this.initCall = true;
          this.getClaimData(response);
          await OcInfraModule.AppInjector.get(CommonService).showHideSpinner({
            showSpinner: false,
            edit: false,
          });
        },
        async (err: any) => {
          await OcInfraModule.AppInjector.get(CommonService).showHideSpinner({
            showSpinner: false,
            edit: false,
          });
        }
      );
  };

  getClaimData(response) {
    if (response && response._links && response._links.item) {
      if (response._links.previous) {
        this.previousBlock = true;
      } else {
        this.previousBlock = false;
      }
      if (response._links.next) {
        this.nextBlock = true;
      } else {
        this.nextBlock = false;
      }
      this.claimsRes = response;
      this.claimTabData = response._links.item;
      this.claimTableData = new MatTableDataSource(this.claimTabData);
      this.claimTableData.paginator = this.paginator;
    }
    this.sortedData = response._embedded ? [] : response._links.item;
    this.noRecords = !this.claimTabData || !this.claimTabData.length;
    this.claimTableData.sort = this.sort;
  }

  getClaimsDetail(rowData){
    this.partySearchFactory.openClaimDetails(rowData);
  }

  getBlock(block) {
    let optionParams = new OptionParams();
    optionParams.headers = this.commonService.getHeaderByAliasName("claimList");
    this.claimTabData = [];
    this.claimTableData = new MatTableDataSource(this.claimTabData);
    let hrefUrl = this.claimsRes._links[block].href;
    this.commonService
      .getCallback(hrefUrl, optionParams.headers)
      .subscribe((response) => {
        if (response && response._links && response._links.item) {
          if (response._links.previous) {
            this.previousBlock = true;
          } else {
            this.previousBlock = false;
          }
          if (response._links.next) {
            this.nextBlock = true;
          } else {
            this.nextBlock = false;
          }
          this.claimsRes = response;
          this.claimTabData = response._links.item;
          this.claimTableData = new MatTableDataSource(this.claimTabData);
          this.claimTableData.paginator = this.paginator;
        }
        this.sortedData = response._embedded ? [] : response._links.item;
        this.noRecords = !this.claimTabData || !this.claimTabData.length;
      });
    this.claimTableData.sort = this.sort;
  }

  setDataSourceAttributes() {
    if (this.claimTableData) {
      this.claimTableData.paginator = this.paginator;
      this.claimTableData.sort = this.sort;
    }
  }
  sortedData: TableData[];
}

function compare(
  a: number | Date | string,
  b: number | Date | string,
  isAsc: boolean
) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
