import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { AuthenticationService } from '../../services/authentication.service';
import { OcInfraModule, Configuration, NavigationService, StoreService, APICallerService, IAlias, ResourceService, ResourceDirectoryService } from '@diaas/ux-web';;
import { Router, NavigationEnd } from '@angular/router';
import { AdvGrowlService } from 'primeng-advanced-growl';

@Component({
  selector: 'agentbob-header',
  templateUrl: './agentBOBHeader.component.html',
  styleUrls: ['./agentBOBHeader.component.scss']
})
export class AgentBOBHeaderComponent implements OnInit {

  public visible: Boolean;
  public pageTitle: string = "Ols Customer service Portal - Policy Details";
  public olsTitle: boolean = false;
  public text: string;
  public isOlsRoute = false;
  constructor(private commonService: CommonService, private authenticationService: AuthenticationService, private router: Router) { }

  ngOnInit() {
    // localStorage.clear();
    this.versionNumber();
  }

  private versionNumber() {
    this.commonService.get('/assets/images/changelog.html').subscribe(res => {
      var element = document.createElement('html');
      element.innerHTML = res._body;
      const header = element.getElementsByTagName('h2');
      let splitDate = header[0].innerText.split(" ");
      const _date = new Date(splitDate[1].slice(1, -1));
      let dateFormat = (_date.getMonth() + 1) + '/' + _date.getDate() + '/' + _date.getFullYear();
      this.text = splitDate[0] + " (" + dateFormat + ")";
    });
  }

  goToLogout(): void {
    let resource: any = JSON.parse(window.sessionStorage.getItem("resourceDirectory"));
    if(resource){
    const resourceDirectoryService = OcInfraModule.AppInjector.get(ResourceDirectoryService);
    resource.forEach(alias => {
        resourceDirectoryService.deleteElementResourceDirectory(alias.href);
      });
    }
    OcInfraModule.AppInjector.get(StoreService).clearSession();    
    OcInfraModule.AppInjector.get(NavigationService).navigateTo(Configuration.config.logoutPage);
  }

  goToHome(): void {
    OcInfraModule.AppInjector.get(CommonService).resetPolicyDetails();
    OcInfraModule.AppInjector.get(CommonService).changeHeaders({ remote_user: null,company_code:null,region_code: null});
    OcInfraModule.AppInjector.get(CommonService).myCallHistory();
    OcInfraModule.AppInjector.get(NavigationService).navigateTo('/screen/home');
  }

  goBack(): void {
    OcInfraModule.AppInjector.get(CommonService).customerBanner = true;
    OcInfraModule.AppInjector.get(CommonService).agentBanner= true;
    OcInfraModule.AppInjector.get(CommonService).bannerAlertArray = [];

    let currentPage = OcInfraModule.AppInjector.get(CommonService).currentRoute;
    if(!(OcInfraModule.AppInjector.get(CommonService).authsucess || OcInfraModule.AppInjector.get(CommonService).otherRoleAuthsucess) ){
      if(OcInfraModule.AppInjector.get(CommonService).agentBannerAlertMessage && currentPage == "/screen/agentDetails"){
        OcInfraModule.AppInjector.get(CommonService).pushBannerAlertMessage(OcInfraModule.AppInjector.get(CommonService).agentBannerAlertMessage);
      }
    }

    OcInfraModule.AppInjector.get(CommonService).showHideBannerAlert.emit();
    document.getElementById("modal__ok") && document.getElementById("modal__ok").click()
  }
}