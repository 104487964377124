import { Component, ElementRef } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { OcInfraModule, NavigationService, ResourceService, APICallerService, Configuration, IAlias, ResourceSchemaService, OptionParams, ResourceDirectoryService } from '@diaas/ux-web';
import { AdvGrowlService } from 'primeng-advanced-growl';
import { DatePipe } from '@angular/common';
import { interval, Subscription } from 'rxjs';
import * as _ from 'lodash';
import {DateAdapter, MAT_DATE_FORMATS} from '@angular/material/core';
import { AppDateAdapter, APP_DATE_FORMATS } from '../format-datepicker';

@Component({
    selector: 'app-fields',
    templateUrl: './customFieldsComponent.html',
    styleUrls: ['./customFieldsComponent.scss'],
    providers: [
        {provide: DateAdapter, useClass: AppDateAdapter},
        {provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS}
      ]
})

export class CustomFieldComponent {
    elementData: any;
    accountresponse: any;
    selectedFormValues = [];
    constructor(public element: ElementRef, public commonService: CommonService, public datePipe: DatePipe) {
        this.elementData = this.element.nativeElement
    }
    agentSearchBar: boolean = false;
    // fincialDateField: boolean = false;
    searchValue: any;
    effectiveMaxDate = new Date();
    effectiveDate = new Date();
    highlightText: any = '';
    subscription: Subscription;
    dateField: any;
    isDateFieldStatus: boolean = false;
    ngOnInit() {
        this.agentSearchBar = (this.elementData.id == 'agentSearchBar') || (this.elementData.id == 'agentSearchBar1');
        this.isDateFieldStatus = (this.elementData.id == 'fincialDateField') ||
            (this.elementData.id == 'paid_to_date') || (this.elementData.id == 'reinstatement_date') ||
            (this.elementData.id == 'requirements_received_date');
        this.searchValue = OcInfraModule.AppInjector.get(CommonService).aSearchValue;

        // To Subscribe based on Policy Values and Policy Details for date
        this.dateField = this.isDateFieldStatus ? this.elementData.id : '';

        if (this.isDateFieldStatus) {
            if (this.dateField == 'fincialDateField') {
                this.getResourceByAliasName("policyValues", 'policyDetails');
            }

            if (this.dateField == 'paid_to_date' || this.dateField == 'reinstatement_date' ||
                this.dateField == 'requirements_received_date') {
                if (this.dateField == 'paid_to_date') {
                    this.effectiveDate = null;                    
                }
                this.getResourceByAliasName("billingInfo", 'policyDetails');
                this.onDateChange(this.effectiveMaxDate, this.dateField); 
            }   
        }
    }

    // To Subscribe based on Policy Values and Policy Details for date
    getResourceByAliasName(policyValuesAliasName, policyDetailsAliasName) {
        const source = interval(10000);
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        this.subscription = source.subscribe(val => {
            let policyValues = resourceService.getResourceFromAliasName(policyValuesAliasName);
            let policyDetails = resourceService.getResourceFromAliasName(policyDetailsAliasName);
            if (policyDetails && policyValues && policyValues.response && policyValues.response.quote_effective_date) {
                this.effectiveDate = new Date(policyValues.response.quote_effective_date);
                this.subscription.unsubscribe();
            }            
        });
    }

    agentSearch(e) {
        if (e.keyCode == 13) {
            this.searchBarClick(e)
        }
    }

    searchBarClick(e) {
        this.agentSearcBarHandler()
    }

    agentSearcBarHandler() {
        let optionParams = new OptionParams();
        const self = this;
        OcInfraModule.AppInjector.get(CommonService).newBannerAlertAarray = [];
        OcInfraModule.AppInjector.get(CommonService).alertMessaggeHideShow.next();
        let agentSearchValue = document.getElementById("agentSearchValue")["value"].trim();
        OcInfraModule.AppInjector.get(AdvGrowlService).clearMessages();
        OcInfraModule.AppInjector.get(CommonService).aSearchValue = agentSearchValue.trim();;
        this.highlightText = agentSearchValue;
        const customerUrlIndex = _.findLastIndex(Configuration.config.hostURL.multiHostUrl, ['alias', "searchClients"]);
        OcInfraModule.AppInjector.get(CommonService).changeHeaders({ statuaory_company_code: null, remote_user: null, role: null, incontact_user: null });
        const urlIndex = _.findLastIndex(Configuration.config.hostURL.multiHostUrl, ['alias', "searchAgents"]);
        optionParams.alias = "searchClients";
        optionParams.params = [{ 'key': 'customerQuery', value: agentSearchValue }]
        const warnMessage = "CURRENT SEARCH RESULTS ARE LIMITED TO 50. PLEASE ASK FOR ADDITIONAL CLIENT SPECIFIC INFORMATION REQUIRED TO NARROW SEARCH RESULTS"
        if (!agentSearchValue) {
            OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage("Please enter search criteria", 'Error', 0);
            return false;
        }
        optionParams.headers = OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("search");
        OcInfraModule.AppInjector.get(CommonService).changeHeaders({ statuaory_company_code: null });
        let customerUrl: any = Configuration.config.hostURL.multiHostUrl[customerUrlIndex]['url'] + 'searchClients';

        let optionAgentParams = new OptionParams();
        optionAgentParams.alias = "searchAgents";
        optionAgentParams.params = [{ 'key': 'agentQuery', value: agentSearchValue }]
        let url: any = Configuration.config.hostURL.multiHostUrl[urlIndex]['url'] + 'searchAgents';
        OcInfraModule.AppInjector.get(CommonService).searchAgentUrl = url;
        let agentResult = 0;

        OcInfraModule.AppInjector.get(APICallerService).refresh(url, optionAgentParams).subscribe(resp => {
            agentResult = resp._links.item ? resp._links.item.length : 0;

            OcInfraModule.AppInjector.get(APICallerService).refresh(customerUrl, optionParams, "RESET").subscribe(res => {
                let customerResults = res._links.item;
                if (customerResults && customerResults.length == 1 && self.isBHFPolicy(customerResults[0]) && agentResult == 0) {
                    let alertMsg = [];
                    let popUpMsg = customerResults[0].summary.msg.split(".");
                    alertMsg.push(customerResults[0].summary.msg.substring(customerResults[0].summary.msg.indexOf("."), 0) + '.', popUpMsg[1].substring(popUpMsg[1].indexOf(":"), 0) + ':');
                    alertMsg.push(customerResults[0].summary.contactLabel1 ? customerResults[0].summary.contactLabel1 + ": " + customerResults[0].summary.contactNumber1 : '', customerResults[0].summary.contactLabel2 ? customerResults[0].summary.contactLabel2 + ": " + customerResults[0].summary.contactNumber2 : '', customerResults[0].summary.contactLabel3 ? customerResults[0].summary.contactLabel3 + ": " + customerResults[0].summary.contactNumber3 : '');
                    let dialogRef = OcInfraModule.AppInjector.get(CommonService).showConfirmation({ content: alertMsg, custom: true }, 'CLOSE', '', 'ALERT', '393px');
                    dialogRef.afterClosed().subscribe(res => {
                        res;
                    });
                } else {
                    const router = OcInfraModule.AppInjector.get(CommonService).routerService()
                    router.url === '/screen/agentSearch' ? '' : OcInfraModule.AppInjector.get(NavigationService).navigateTo('/screen/agentSearch');
                }

                let observer = new MutationObserver(function (mutation) {
                    self.highlightBHFPolicyRows(customerResults)
                });
                res._embedded && res._embedded.status_report && this.showAlertMsg(res._embedded.status_report);
                var highLightRowInterval = setInterval(function () {
                    let table = document.querySelector(".customer-search-result table tbody");
                    if (table) {
                        self.highlightBHFPolicyRows(customerResults)
                        observer.observe(table, { attributes: true, childList: true, attributeOldValue: true });
                        clearInterval(highLightRowInterval);
                    }
                }, 500);

            })
            resp._embedded && resp._embedded.status_report && this.showAlertMsg(resp._embedded.status_report)
        });
        this.tableHighlight()
    }
    showAlertMsg(status_report) {
        setTimeout(() => (OcInfraModule.AppInjector.get(CommonService).pushAlertBannerMessage(status_report)), 500);
    }
    tableHighlight() {
        const self = this;
        let table = document.querySelector(".ols-search-results table tbody");
        let observer = new MutationObserver(function (mutation) {
        });
        if (table) {
            observer.observe(table, { attributes: true, childList: true, attributeOldValue: true });
        } else {
            var highLightInterval = setInterval(function () {
                let table = document.querySelector(".ols-search-results table tbody");
                if (table) {
                    observer.observe(table, { attributes: true, childList: true, attributeOldValue: true });
                }
                if (document.querySelector(".ols-search-results table")) {
                    self.highlightRowText();
                    clearInterval(highLightInterval);
                }
            }, 500);
            var highLightInterval = setInterval(function () {
                let table = document.querySelector(".ols-search-results table tbody");
                if (table) {
                    observer.observe(table, { attributes: true, childList: true, attributeOldValue: true });
                }
                if (document.querySelector(".ols-search-results table")) {
                    self.highlightRowText();
                    clearInterval(highLightInterval);
                }
            }, 1000);
        }
    }

    highlightBHFPolicyRows(searchResults) {
        if (!searchResults || searchResults.length == 0) {
            return;
        }
        const router = OcInfraModule.AppInjector.get(CommonService).routerService()
        if (searchResults && searchResults.length && router.url === "/screen/agentSearch") {
            let allCustomerTableRows = document.querySelectorAll(".customer-search-result table tbody tr");
            if (allCustomerTableRows && allCustomerTableRows.length) {
                allCustomerTableRows.forEach(row => {
                    let dataRow = searchResults[row.id.replace("_", "")]
                    if (this.isBHFPolicy(dataRow)) {
                        if (!row.className.includes("rowHighlight")) {
                            row.className = row.className + " rowHighlight";
                        }
                    } else {
                        row.className = row.className.replace("rowHighlight", "");
                    }
                })
            }

        }
    }


    highlightRowText() {
        const searchedColumnArray = document.getElementsByClassName('oc-label-2');
        let searchValue = OcInfraModule.AppInjector.get(CommonService).aSearchValue ? OcInfraModule.AppInjector.get(CommonService).aSearchValue : this.highlightText;
        const test = searchValue.split(" ").join("|");
        for (var i = 0; i < searchedColumnArray.length; i++) {
            if (searchedColumnArray[i]["innerText"] !== '') {
                let query = searchValue.startsWith('*') ? new RegExp("(\\b" + "[a-zA-Z0-9]*" + test.slice(1) + "\\b)", "gim") : (searchValue.endsWith("*") || searchValue.endsWith("?") ? new RegExp("(\\b" + test.slice(0, -1) + "[a-zA-Z0-9]*\\b)", "gim") : new RegExp("(\\b" + test + "\\b)", "gim"));
                var elemInnerText = searchedColumnArray[i]["innerText"];
                if (searchedColumnArray[i]["innerText"].indexOf("-") !== -1 && searchValue.replace(/-/g, '') === searchedColumnArray[i]["innerText"].replace(/-/g, '')) {
                    var newe = elemInnerText.replace(elemInnerText, "<span class='text-primary'>" + elemInnerText + "</span>");
                } else if (searchedColumnArray[i]["innerText"].indexOf("-") !== -1 && query.test(searchedColumnArray[i]["innerText"].replace(/-/g, ''))) {
                    var newe = elemInnerText.replace(elemInnerText, "<span class='text-primary'>" + elemInnerText + "</span>");
                } else {
                    if (searchValue.split(" ").length > 1 && !~searchValue.indexOf('?') && !~searchValue.indexOf('*')) {
                        const arr = searchValue.split(" ").sort((a, b) => b.length - a.length);
                        var newe = elemInnerText;
                        for (var j = 0; j < arr.length; j++) {
                            const sQuery = new RegExp("(\\b" + arr[j] + "\\b)", "gim");
                            newe = newe.replace(sQuery, "<span class='text-primary'>$1</span>");
                        }
                    } else {
                        var newe = elemInnerText.replace(query, "<span class='text-primary'>$1</span>");
                    }
                }
                searchedColumnArray[i].innerHTML = newe;
            };
        }
    }

    // Selected dates based on fileds it will 
    isBHFPolicy(policy) {
        return (!policy.summary.clientId && policy.summary.msg && policy.summary.msg != '');
    }


    // Available number of Date fileds 
    availableDateSelection = ['paid_to_date', "reinstatement_date", 'requirements_received_date'];

    onDateChange(date, selectedDateField) {
        //  Fincial Date Change
        if (selectedDateField == 'fincialDateField') {
            OcInfraModule.AppInjector.get(CommonService).quoteDate = this.datePipe.transform(date, "MM/dd/yyyy")
        }

        // Looping through available date fields and compare with selected if exist add in to Object called "selectedDateObj"
        for (let keyDate of this.availableDateSelection) {
            if (keyDate == selectedDateField) {
                OcInfraModule.AppInjector.get(CommonService).selectedDateObj[keyDate] = this.datePipe.transform(date, "MM/dd/yyyy");
            }            
        }

        // Paid to date selected then enabled the quote value button
        let getSelectedPaidToDate = (document.querySelector('.quoteValuesPaidToDate input') as HTMLInputElement).value;
        if(getSelectedPaidToDate.length){
            (<HTMLInputElement>document.getElementById('quote-values-quote-values-button')).disabled = false;
        }
    }

}