import { Component, OnInit, ViewChild } from '@angular/core';
import { APICallerService, OcInfraModule, OptionParams, ResourceService } from '@diaas/ux-web';
import { CommonService, retryOperation } from '../../services/common.service';
import { AdvGrowlService } from 'primeng-advanced-growl';
import { MatPaginator, MatTableDataSource, MatSort } from '@angular/material';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { CustomDialogComponent } from '../custom-dialog/custom-dialog.component';
import { FinancialHistoryDisplayComponent } from '../financial-history-display/financial-history-display.component';
import { MatDialog } from '@angular/material';

export interface TableData {
  eff_date: string;
  processed_date: string;
  payment_type: string;
  status: string;
  amount: string;
}

@Component({
  selector: 'app-payment-history',
  templateUrl: './payment-history.component.html',
  styleUrls: ['./payment-history.component.scss']
})
export class PaymentHistoryComponent implements OnInit {
  displayedColumns: string[] = ['eff_date', 'processed_date', 'payment_type', 'status', 'amount'];
  noRecords: boolean = true;
  PaymentTabData: TableData[];
  paymntTableData: any;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  paymentRes: any;
  nextBlock: boolean;
  previousBlock: boolean;
  filterParams: any;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }

  financialFilterSubscription$: Subscription;
  policyDetails: any;

  retryDelay = 3500;
  retryAttempts = 7;
  initCall: boolean = false;
  constructor(public commonService: CommonService, public dialog: MatDialog) {
    this.financialFilterSubscription$ = this.commonService.getFinancialHistoryFilterEvent().subscribe((alias) => {
      this.filterParams = this.commonService.financialHistoryRes;
      if (alias == "paymenthistory") {
        this.applyFilter(alias, this.filterParams)
      }
    });
  }

    ngOnInit() {
        this.PaymentTabData = [];
        this.paymntTableData = new MatTableDataSource(this.PaymentTabData);
        this.getPolicyDetailsPromise();
    }

    getPolicyDetailsPromise = async () => {
        await retryOperation(this.getPolicyDetails, this.retryDelay, this.retryAttempts)
            .then((res) => this.getPaymenthistoryData())
            .catch((err) => err);
    };

    getPolicyDetails = () => {
        this.policyDetails = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyDetails');
        if (this.policyDetails) {
            return Promise.resolve(this.policyDetails);
        } else {
            return Promise.reject('No data');
        }
    }

    getPaymenthistoryData = async () => {
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let policyDetails = resourceService.getResourceFromAliasName('policyDetails');
        let [url, params] = this.commonService.getHrefUrlWithParams(policyDetails._links['paymenthistory'].href);
        let optionParams = new OptionParams();
        optionParams.alias = "paymenthistory";
        optionParams.headers = OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("paymenthistory");
        optionParams.params = params;
        await OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false });
        OcInfraModule.AppInjector.get(APICallerService).refresh(url, optionParams).subscribe(async response => {
            this.initCall = true;
            this.getPaymentDetails(response);
            await OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
        }, async (err: any) => {
            await OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
        });
    }
  getPaymentDetails(response) {
    if (response && response._links && response._links.item) {
      if (response._links.previous) {
        this.previousBlock = true;
      } else {
        this.previousBlock = false;
      }
      if (response._links.next) {
        this.nextBlock = true;
      } else {
        this.nextBlock = false;
      }
      this.paymentRes = response;
      this.PaymentTabData = response._links.item;
      this.paymntTableData = new MatTableDataSource(this.PaymentTabData);
      this.paymntTableData.paginator = this.paginator;
      this.sortData({ active: "eff_date", direction: "desc" });
    }
    this.sortedData = response._embedded ? [] : response._links.item;
    this.noRecords = !this.PaymentTabData || !this.PaymentTabData.length;
    this.paymntTableData.sort = this.sort;
  }

  getBlock(block) {
    let optionParams = new OptionParams();
    optionParams.headers = this.commonService.getHeaderByAliasName("paymenthistory");
    this.PaymentTabData = [];
    this.paymntTableData = new MatTableDataSource(this.PaymentTabData);
    let hrefUrl = this.paymentRes._links[block].href;    
    this.commonService.getCallback(hrefUrl, optionParams.headers).subscribe(response => {
      if (response && response._links && response._links.item) {
        if (response._links.previous) {
          this.previousBlock = true;
        } else {
          this.previousBlock = false;
        }
        if (response._links.next) {
          this.nextBlock = true;
        } else {
          this.nextBlock = false;
        }
        this.paymentRes = response;
        this.PaymentTabData = response._links.item;
        this.paymntTableData = new MatTableDataSource(this.PaymentTabData);
        this.paymntTableData.paginator = this.paginator;
        this.sortData({ active: "eff_date", direction: "desc" });
      }
      this.sortedData = response._embedded ? [] : response._links.item;
      this.noRecords = !this.PaymentTabData || !this.PaymentTabData.length;
    });
    this.paymntTableData.sort = this.sort;
  }

  setDataSourceAttributes() {
    if (this.paymntTableData) {
      this.paymntTableData.paginator = this.paginator;
      this.paymntTableData.sort = this.sort;
    }
  }

  sortedData: TableData[];
  sortData(sort) {
    const data = this.paymntTableData.filteredData.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }
    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'eff_date': return compare(new Date(a.summary.effective_date).getTime(), new Date(b.summary.effective_date).getTime(), isAsc);
        default: return 0;
      }
    });
    this.paymntTableData = new MatTableDataSource(this.sortedData);
  }

  applyFilter = async (alias: string, optionParams: any) => {
    const resourceService = OcInfraModule.AppInjector.get(ResourceService);
    let hrefUrl = resourceService.getHrefByAliasName(alias);
    let params = {};
    if (this.filterParams != undefined && Object.keys(this.filterParams).length >= 0 && 'params' in this.filterParams) {
      params = this.commonService.transformParams(this.filterParams['params'])
    }
    this.apiPaymenthistory(hrefUrl, optionParams.headers, params);
  }

  apiPaymenthistory = async (hrefUrl: string, headers, params: any) => {
    await this.commonService.showHideSpinner({ showSpinner: true, edit: false });
    this.commonService.getCallback(hrefUrl, headers, params).subscribe(async response => {
      this.getPaymentDetails(response);
      await this.commonService.showHideSpinner({ showSpinner: false, edit: false });
    }, async error => {
      await this.commonService.showHideSpinner({ showSpinner: false, edit: false });
      if (error && error._embedded && error._embedded.status_report && error._embedded.status_report.consistent === false) {
        OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error._embedded.status_report.messages[0].message, 'Error', 0);
      }
    });
  }

    openHistoryDetails = ($event: any) => {
        let params = { historyId: $event.summary.history_id, history_type: 'Payment_History' };
        let headers = { client_id: '', display_value: '', effective_date: '' };
        let productInfo = this.commonService.productInfo;
        if (productInfo && (productInfo["product_type"] == "A3" || productInfo["product_type"] == "A4")) {
            headers = { client_id: this.policyDetails.response.client_id, display_value: 'PARTICIPANT', effective_date: $event.summary.effective_date };
        }
        if (this.filterParams && this.filterParams.headers && Object.keys(this.filterParams.headers).length > 0) {
            let displayValue = this.filterParams.params.find((ele: any) => ele.key === 'display_value')
            headers = { ...headers, ...{ display_value: displayValue ? displayValue.value: '' } };
        }
        let dialogRef = this.dialog.open(CustomDialogComponent, {
            width: '1000px',
            disableClose: false,
            data: { component: FinancialHistoryDisplayComponent, title: 'Payment History', params: params, headers: headers }
        });
    }

  ngOnDestroy() {
    setTimeout(() => {
      this.financialFilterSubscription$.unsubscribe();
    }, 0);
  }
}

function compare(a: number | Date | string, b: number | Date | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}