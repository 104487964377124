import { Component, OnInit, ViewChild } from '@angular/core';
import { APICallerService, OcInfraModule, OptionParams, ResourceService } from '@diaas/ux-web';
import { AdvGrowlService } from 'primeng-advanced-growl';
import { MatPaginator, MatTableDataSource, MatSort } from '@angular/material';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { CustomDialogComponent } from '../custom-dialog/custom-dialog.component';
import { CheckHistorydetailComponent } from '../check-historydetail/check-historydetail.component';
import { MatDialog } from '@angular/material';
import { CommonService, retryOperation } from '../../services/common.service';

export interface TableData {
  check_number: string;
  issue_date: string;
  check_amount: string;
  status: string;
}

@Component({
  selector: 'app-check-history-annuity',
  templateUrl: './check-history-annuity.component.html',
  styleUrls: ['./check-history-annuity.component.scss']
})
export class CheckHistoryAnnuityComponent implements OnInit {
  displayedColumns: string[] = ['check_number', 'issue_date', 'check_amount', 'status'];
  noRecords: boolean = true;
  checkHistoryTabData: TableData[];
  checkHistoryTableData: any;
  

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  nextBlock: boolean;
  previousBlock: boolean;
  checkHistoryRes: any;
  filterParams: any;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }

  financialFilterSubscription$: Subscription;
  policyDetails: any;

  retryDelayP = 3500;
  retryAttemptsP = 7;
  initCall: boolean = false;

  constructor(public commonService: CommonService, public dialog: MatDialog) {
  
    }

    ngOnInit() {
        this.checkHistoryTabData = [];
        this.checkHistoryTableData = new MatTableDataSource(this.checkHistoryTabData);
        this.policyDetails = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyDetails');
        this.getPolicyDetailsPromise();
    }
    getPolicyDetailsPromise = async () => {
        await retryOperation(this.getPolicyDetails, this.retryDelayP, this.retryAttemptsP)
            .then((res) => {
                this.getCheckhistoryData();
            })
            .catch((err) => { });
    };

    getPolicyDetails = () => {
        let policyDetails = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyDetails');
        if (policyDetails) {
            return Promise.resolve(policyDetails);
        } else {
            return Promise.reject('No data');
        }
    }

    getCheckhistoryData = async () => {
        var checkHistoryTableUrl = this.commonService.checkHistoryUrl.summary.links.filter(x=>x.rel=="search_CDS_System")[0].href?this.commonService.checkHistoryUrl.summary.links.filter(x=>x.rel=="search_CDS_System")[0].href:null;
        let product_type = this.policyDetails.data.product_type;
        if(product_type === 'A3' || product_type ==='A4'){
           checkHistoryTableUrl = this.commonService.checkHistoryUrl.summary.links.filter(x=>x.rel=="search_CDS_System")[0].href?this.commonService.checkHistoryUrl.summary.links.filter(x=>x.rel=="search_CDS_System")[0].href:null;
           checkHistoryTableUrl  = checkHistoryTableUrl.slice(0, -3);
        } 
        let [url, params] = this.commonService.getHrefUrlWithParams(checkHistoryTableUrl);
        let optionParams = new OptionParams();
        optionParams.alias = "checkHistory";
        optionParams.headers = OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("search");
        optionParams.params = params;
        
        await OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false });
        await OcInfraModule.AppInjector.get(APICallerService).refresh(url, optionParams).subscribe(async response => {
            this.initCall = true;
            this.getCheckHistoryDetails(response);
            await OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
        },async err=>{
            await OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });

          });
    }


  getCheckHistoryDetails(response) {
    if (response && response._links && response._links.item) {
      if (response._links.previous) {
        this.previousBlock = true;
      } else {
        this.previousBlock = false;
      }
      if (response._links.next) {
        this.nextBlock = true;
      } else {
        this.nextBlock = false;
      }
      this.checkHistoryRes = response;
      this.checkHistoryTabData = response._links.item;
      this.checkHistoryTableData = new MatTableDataSource(this.checkHistoryTabData);
      this.checkHistoryTableData.paginator = this.paginator;
      this.sortData({ active: "issue_date", direction: "desc" });
      this.sortData({ active: "status", direction: "desc" });
    }
    this.sortedData = response._embedded ? [] : response._links.item;
    this.noRecords = !this.checkHistoryTabData || !this.checkHistoryTabData.length;
    this.checkHistoryTableData.sort = this.sort;
  }

  getBlock(block) {
    let optionParams = new OptionParams();
    optionParams.headers = this.commonService.getHeaderByAliasName("search");
    this.checkHistoryTabData = [];
    this.checkHistoryTableData = new MatTableDataSource(this.checkHistoryTabData);
    let hrefUrl = this.checkHistoryRes._links[block].href;
    this.commonService.getCallback(hrefUrl, optionParams.headers).subscribe(response => {
      if (response && response._links && response._links.item) {
        if (response._links.previous) {
          this.previousBlock = true;
        } else {
          this.previousBlock = false;
        }
        if (response._links.next) {
          this.nextBlock = true;
        } else {
          this.nextBlock = false;
        }
        this.checkHistoryRes = response;
        this.checkHistoryTabData = response._links.item;
        this.checkHistoryTableData = new MatTableDataSource(this.checkHistoryTabData);
        this.checkHistoryTableData.paginator = this.paginator;
        this.sortData({ active: "issue_date", direction: "desc" });
        this.sortData({ active: "status", direction: "desc" });
      }
      this.sortedData = response._embedded ? [] : response._links.item;
      this.noRecords = !this.checkHistoryTabData || !this.checkHistoryTabData.length;
    });
    this.checkHistoryTableData.sort = this.sort;
  }


  setDataSourceAttributes() {
    if (this.checkHistoryTableData) {
      this.checkHistoryTableData.paginator = this.paginator;
      this.checkHistoryTableData.sort = this.sort;
    }
  }

  sortedData: TableData[];
  sortData(sort) {
    const data = this.checkHistoryTableData.filteredData.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }
    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'issue_date': return compare(new Date(a.issueDate).getTime(), new Date(b.issueDate).getTime(), isAsc);
        case 'status': return compare(new Date(a.status).getTime(), new Date(b.status).getTime(), isAsc);
        default: return 0;
      }
    });
    this.checkHistoryTableData = new MatTableDataSource(this.sortedData);
  }



  apiProgramEnrollment = async (hrefUrl: string, headers, params: any) => {
    await this.commonService.showHideSpinner({ showSpinner: true, edit: false });
    this.commonService.getCallback(hrefUrl, headers, params).subscribe(async response => {
      this.getCheckHistoryDetails(response);
      await this.commonService.showHideSpinner({ showSpinner: false, edit: false });
    }, async error => {
      await this.commonService.showHideSpinner({ showSpinner: false, edit: false });
      if (error && error._embedded && error._embedded.status_report && error._embedded.status_report.consistent === false) {
        OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error._embedded.status_report.messages[0].message, 'Error', 0);
      }
    });
  }

  openHistoryDetails = ($event: any) => {
    let params = { query: $event.checkNumber,policy: $event.policyNumber};
    let dialogRef = this.dialog.open(CustomDialogComponent, {
        width: '1000px',
        disableClose: false,
        data: { component: CheckHistorydetailComponent, title: 'Check Details', params: params }
      
      });
}

ngOnDestroy() {
  setTimeout(() => {
    this.financialFilterSubscription$.unsubscribe();
  }, 0);
}
}
function compare(a: number | Date | string, b: number | Date | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
