import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { APICallerService, OcInfraModule, OptionParams, ResourceService } from '@diaas/ux-web';
import { CommonService } from '../../../services/common.service';
import { RMDDetails } from '../rmdTab/RMDTab.constant';

@Component({
    selector: 'app-payment-rmd',
    templateUrl: './payment.component.html',
    styleUrls: ['./payment.component.scss'],
})

export class RMDPaymentComponent implements OnInit {
    @Input() rmdDetails: RMDDetails;
    headers = { ...{ role: OcInfraModule.AppInjector.get(CommonService).authorizedRole, wma_user: window.sessionStorage.getItem('username') }, user_role: "csr", agent_code: OcInfraModule.AppInjector.get(CommonService).agentId, ...OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("search"), client_id: OcInfraModule.AppInjector.get(CommonService).productInfo.client };
    disbursement_ind: any;
    responseOptions: any;
    disbursementIndM: any;
    distributionCode: any;
    relationship: any;
    relationshipCode: any;
    resourceService: any;
    policyDetails: any;
    RMDUrl: any;
    dob: any;
    paymentSSN: any = "";
    bankName: any;
    accountType: any;
    accountNumber: any;
    routingNumber: any;
    distribution_code: any;
    relationship_code: any;
    status_code: any;
    Status: any;
    
    dobRequired: boolean = false;
    statusRequired: boolean = false;
    paymentSSNRequired: boolean = false;

    bankAccountList:any;
    selectedBankAccount: any;
    bankAccount: any;
    eft_id: any;

    isHostorMrpsContract : boolean = false
    hostContractAlert: any;
    assetAccountValue: string = '';
    get isHostContractAlert(): any {
        return this.hostContractAlert;
    }
    @Input() set isHostContractAlert(value: any) {
        this.hostContractAlert = value;
        (this.hostContractAlert) && this.disableFormControls()   
    }

    mrpsContractAlert: any;
    get isMrpsContractAlert(): any {
        return this.mrpsContractAlert;
    }
    @Input() set isMrpsContractAlert(value: any) {
        this.mrpsContractAlert = value;
        (this.mrpsContractAlert) && this.disableFormControls() 
    }


    constructor() { }

    ngOnInit() {
        // this.getPaymentDetails();
        this.isHostorMrpsContract = false;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.rmdDetails.currentValue) {
            this.rmdDetails = changes.rmdDetails.currentValue;
            this.getPaymentDetails();
        }
    }

    disableFormControls = () => {
        // isHostorMrpsContract is to enable payment tab for rps and host under PANOCSR-3452
        this.isHostorMrpsContract = true;
    }

    getPaymentDetails() {
        this.responseOptions = this.rmdDetails._options;

        //Asign dropdown option based on response 
        this.disbursement_ind = [];
        if (this.responseOptions.properties.disbursement_ind && this.responseOptions.properties.disbursement_ind.oneOf && this.rmdDetails.disbursement_ind_options) {
            for (let i = 0; i < this.responseOptions.properties.disbursement_ind.oneOf.length; i++) {
                for (let j = 0; j < this.rmdDetails.disbursement_ind_options.length; j++) {
                    if (this.rmdDetails.disbursement_ind_options[j].title.includes(this.responseOptions.properties.disbursement_ind.oneOf[i].title)) {
                        this.disbursement_ind.push({ id: this.responseOptions.properties.disbursement_ind.oneOf[i].enum[0], title: this.responseOptions.properties.disbursement_ind.oneOf[i].title })
                    }
                }
            }
        }
        this.distribution_code = [];
        if (this.rmdDetails && this.rmdDetails.distribution_code_options) {
            for (let i = 0; i < this.rmdDetails.distribution_code_options.length; i++) {
                if (this.rmdDetails.distribution_code_options[i]) {
                    this.distribution_code.push({ id: this.rmdDetails.distribution_code_options[i].enum, title: this.rmdDetails.distribution_code_options[i].title })
                }
            }
        }
        this.relationship_code = [];
        if (this.responseOptions.properties.bene_relation_code && this.responseOptions.properties.bene_relation_code.oneOf) {
            for (let i = 0; i < this.responseOptions.properties.bene_relation_code.oneOf.length; i++) {
                //if (this.rmdDetails.bene_relation_code.includes(this.responseOptions.properties.bene_relation_code.oneOf[i].title)) {
                this.relationship_code.push({ id: this.responseOptions.properties.bene_relation_code.oneOf[i].enum[0], title: this.responseOptions.properties.bene_relation_code.oneOf[i].title })
            }
        }
        this.status_code = [];
        if (this.responseOptions.properties.bene_status_indicator && this.responseOptions.properties.bene_status_indicator.oneOf) {
            for (let i = 0; i < this.responseOptions.properties.bene_status_indicator.oneOf.length; i++) {
                //if (this.rmdDetails.bene_relation_code.includes(this.responseOptions.properties.bene_relation_code.oneOf[i].title)) {
                this.status_code.push({ id: this.responseOptions.properties.bene_status_indicator.oneOf[i].enum[0], title: this.responseOptions.properties.bene_status_indicator.oneOf[i].title })
            }
        }

        //Assigning Default value
        this.disbursementIndM = "C";
        let disIndicators = this.rmdDetails.disbursement_ind_options ? this.rmdDetails.disbursement_ind_options : [];
        disIndicators.forEach(element => {
            if(element.title == 'EFT') {
                this.disbursementIndM = 'E'
            }
        })

        if(this.rmdDetails.distribution_code != undefined) {
            this.distributionCode = this.rmdDetails.distribution_code
        } else {
            this.distributionCode = this.distribution_code[0].id
        }

        this.relationshipCode = (this.rmdDetails.bene_relation_code) ? (this.rmdDetails.bene_relation_code) : "";
        this.Status = (this.rmdDetails.bene_status_indicator) ? (this.rmdDetails.bene_status_indicator) : "";
        this.paymentSSN = (this.rmdDetails.mrd_bene_ssn) ? (this.rmdDetails.mrd_bene_ssn) : "";

        if (this.rmdDetails && this.rmdDetails.bank_account) {
            this.bankAccountList = this.sortBankAccountsByDate(this.rmdDetails.bank_account);
            this.selectedBankAccount = this.getSelectedBankAccount();
            this.splitBankaccount(this.selectedBankAccount);
            this.bankAccount = this.selectedBankAccount.id;
        }
    }

    getRmdValues() {

        let distribution_code_label = '';
        if (this.distribution_code && this.distribution_code.length > 0) {
            distribution_code_label = this.distribution_code.find((item: any) => item.id == this.distributionCode).title;
        }

        return {
            disbursement_ind: this.disbursementIndM.toString(),
            distribution_code: this.distributionCode.toString(),
            bene_relation_code: this.relationshipCode.toString(),
            dob: this.dob,
            bene_status_indicator: this.Status.toString(),
            mrd_bene_ssn: this.paymentSSN,
            mrd_bene_dob: this.dob,
            bankname: this.bankName,
            accounttype: this.accountType,
            accountnumber: this.accountNumber,
            transitnumber: this.routingNumber,
            distribution_code_label,
            eft_id: this.eft_id
        };
    }

    isStepComplete() {
        let stepComplete = this.checkRequiredFieldsRmd();
        return stepComplete;
    }

    checkRequiredFieldsRmd() {
        const disbursementFilled = !!this.disbursementIndM;
        const dobFilled = !this.dobRequired || (this.dobRequired && !!this.dob);
        const statusFilled = !this.statusRequired || (this.statusRequired && !!this.Status);
        const paymentSSNFilled = !this.paymentSSNRequired || (this.paymentSSNRequired && !!this.paymentSSN);
    
        return disbursementFilled && dobFilled && statusFilled && paymentSSNFilled;
    }

    onRelationshipChange = ($event: any) => {
        if($event.value == 'S') {
            this.dobRequired = true;
            this.statusRequired = true;
            this.paymentSSNRequired = true;
        } else {
            this.dobRequired = false;
            this.statusRequired = false;
            this.paymentSSNRequired = false;
        }
    }

    extractDate(str) {
        let dateStr = str.split(" - ")[4];
        let date = new Date(dateStr);
        return date.getTime()
    }

    sortBankAccountsByDate = (bankAccounts: any) => {
        return bankAccounts.sort((a: any, b: any) => {
           return (this.extractDate(b)) - (this.extractDate(a));
        }).map((account: any) => {
            const [bank_name, account_type, account_number, transit, dateString,eft_id] = account.split(' - ');
            return {
                id: account,
                title: account,
                bank_name,
                account_type,
                account_number,
                transit_number: transit,
                date: new Date(dateString),
                eft_id
            };
        });
    }

    splitBankaccount(data: any){
        this.bankName = data.bank_name;
        this.accountNumber = data.account_number;
        this.routingNumber = data.transit_number;
        let accountType = data.account_type;
        this.accountType = this.responseOptions.properties.account_type.oneOf.filter(x=>x.enum == accountType)[0].title;
        this.eft_id = data.eft_id;
    }

    onBankAccountChange(event: any){
        const bankData = this.bankAccountList.find((item: any) => item.id == event.value);
        this.splitBankaccount(bankData);
    }
    
    getSelectedBankAccount(): any {
        if (this.rmdDetails.eft_id && this.rmdDetails.eft_id !== 0 && this.rmdDetails.existing_record == 'Y') {
          return this.bankAccountList.find(account => account.eft_id === this.rmdDetails.eft_id) || this.bankAccountList[0];
        } else {
            this.bankAccountList[0];
        }
        return this.bankAccountList[0];
      }

    

}
