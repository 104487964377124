// import { NgxMatDateFormats,NGX_MAT_DATE_FORMATS } from '@angular-material-components/datetime-picker';
import { MatStepper } from '@angular/material';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { Component, OnInit, ViewEncapsulation, EventEmitter, Output, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import * as _ from 'lodash';
import { RMDQuoteComponent } from './quote/RMDQuote.component';
import { RMDTabComponent } from './rmdTab/RMDTab.component';
import { RMDPaymentComponent } from "./payment/payment.component";
import { OcInfraModule, ResourceService } from '@diaas/ux-web';
import { CommonService } from '../../services/common.service';
import { AdvGrowlService } from 'primeng-advanced-growl';
import { RmdQuoteDetails } from './constant';
import { RMDDetails } from './rmdTab/RMDTab.constant';
import { RMDConfirmationComponent } from './confirmation/confirmation.component';
import * as moment from 'moment';
import "moment-timezone";

export const FUNDS_DATE_FORMATS = {
    parse: {
        dateInput: 'MM/DD/YYYY',
    },
    display: {
        dateInput: 'MM/DD/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@Component({
    selector: 'app-rmd',
    templateUrl: './RMDComponent.html',
    styleUrls: ['RMDComponent.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        },
        { provide: MAT_DATE_FORMATS, useValue: FUNDS_DATE_FORMATS }
    ]
})

export class RMDComponent implements OnInit, AfterViewInit {

    stepCompletionIndicator: object = { "RMDQuote": false, "RMDTab": false, "RMDPayment": false };
    alertMessages: any = [];
    receivedFundTransferDetails: any;
    allocationChangeValues: any;
    enabledTabs: string[] = [];
    dcaValues: any;
    rebalancingValues: any;
    fundTransactions: any = {};
    performanceLock: any;
    @ViewChild('stepper') stepper: MatStepper;
    @ViewChild('stepperDiv') stepperElementRef: ElementRef;

    @ViewChild(RMDQuoteComponent) childRmdQuote: RMDQuoteComponent;
    @ViewChild(RMDTabComponent) childRmdTab: RMDTabComponent;
    @ViewChild(RMDPaymentComponent) childPayment: RMDPaymentComponent;
    @ViewChild(RMDConfirmationComponent) childConfirm: RMDConfirmationComponent;

    headers = { ...{ client_id: OcInfraModule.AppInjector.get(CommonService).productInfo.client, wma_user: window.sessionStorage.getItem('username') }, ...OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("search") };

    rmdQuoteHref: string = '';
    policyDetails: any;
    quoteDetails: any;

    rmdHref: string = '';
    rmdDetails: RMDDetails;
    isGroupProduct: boolean = false;
    isHostContract: boolean = false;
    isMrpsContract: boolean = false;
    isExistingRMD: boolean = false;

    isHostContractAlert: boolean = false;
    isMrpsContractAlert: boolean = false;

    requestedDate: string;
    scriptRes: any;
    confirmMessage: string;
    quoteDetailsCheck : boolean = false;
    reqQuoteDetails : any;
    isMfaValidationDone: boolean = false;
    constructor(
        private commonService: CommonService
    ) { }

    ngOnInit() {
        this.isMfaValidationDone = OcInfraModule.AppInjector.get(CommonService).mfaAuthorized;
        this.policyDetails = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyDetails');
        this.isHostContract = this.policyDetails.response.isHostContract;
        this.isMrpsContract = this.policyDetails.response.isMrpsContract;

        this.requestedDate = moment().tz('America/New_York').format('MM/DD/YYYY HH:mm');

        this.headers = this.commonService.getHeaderByAliasName("rmdDetails");
        this.headers.role = this.commonService.authorizedRole;
        this.isGroupProduct = this.isGroupProductType();
        if (!this.isGroupProduct) {
            this.getQuoteDetails()
            this.getRMDDetails();
        }
    }

    ngAfterViewInit() {
        this.isMfaValidationDone = OcInfraModule.AppInjector.get(CommonService).mfaAuthorized;
        // this.headers = this.commonService.getHeaderByAliasName("rmdDetails");
    }

    isGroupProductType = () => {
        let product_type = this.getNested(this.policyDetails, 'response', 'product_type');
        if (product_type && (product_type == "A3" || product_type == "A4")) {
            return true;
        } else {
            return false;
        }
    }

    scrollTop(e) {
        this.stepperElementRef.nativeElement.scrollIntoView();
    }

    // Handles the Green tick on tabs on successful submit
    tabClick(e) {
        this.stepCompletionIndicator["quote"] = this.childRmdQuote ? this.childRmdQuote.isStepComplete() : false;
        this.stepCompletionIndicator["rmd"] = this.childRmdTab ? this.childRmdTab.isStepComplete() : false;
        this.stepCompletionIndicator["payment"] = this.childPayment ? this.childPayment.isStepComplete() : false;
        this.stepCompletionIndicator["confirm"] = this.childConfirm ? this.childConfirm.isStepComplete() : false;

        let allFormsValue = {
            requestedDate: this.requestedDate
        };

        if (this.rmdDetails) {
            allFormsValue = { ...allFormsValue, ...this.childRmdTab.getRmdValues() }
            allFormsValue = { ...allFormsValue, ...this.childPayment.getRmdValues() }
        }

        let formValid = false;
        if (this.childRmdQuote.isStepComplete() && this.childRmdTab.isStepComplete() && this.childPayment.isStepComplete()) {
            formValid = true;
        }
        this.commonService.allRMDFormsEmit({ values: allFormsValue, valid: formValid });


        if(e.selectedIndex == '3') {
            let roles = OcInfraModule.AppInjector.get(CommonService).selectedRole;
            let href = this.rmdDetails._links.scripts_url.href;
            let no_federal_withholding = this.childRmdTab.rmdForm.get('no_federal_withholding').value;
            let no_state_withholding = this.childRmdTab.rmdForm.get('no_state_withholding').value;
            let state_withholding_override = this.childRmdTab.rmdForm.get('state_withholding_override').value;
            let federal_withholding_override = this.childRmdTab.rmdForm.get('federal_withholding_override').value;
            let withholdingIndicator = (no_federal_withholding == true || no_state_withholding == true) ? "Y" : "N";
            let withholdingOverrideInd = (state_withholding_override || federal_withholding_override) ? "Y" : "N";
            let stateWthdAmount = this.childRmdTab.rmdForm.get('state_withholding_amount').value;
            let stateWthdPercent = this.childRmdTab.rmdForm.get('state_withholding_percentage').value;
            let fedaralWthdAmount = this.childRmdTab.rmdForm.get('federal_withholding_amount').value;;
            let fedaralWthdPercent = this.childRmdTab.rmdForm.get('federal_withholding_percentage').value;
            var overrideValueInd:any = "N"
            if(stateWthdAmount == "$0.00" && fedaralWthdAmount == "$0.00" && stateWthdPercent == "0.00%" && fedaralWthdPercent=="0.00%") {
                overrideValueInd = "N"
            } else {
                overrideValueInd = "Y"
            }
            let payment_method = this.childPayment.disbursementIndM;
            let rmd_mode = this.childRmdTab.getRmdValues().rmd_mode_label;

            let reqAmount:any;
            reqAmount = rmd_mode=='Annual' ? this.reqQuoteDetails.annual_amt : 
                  rmd_mode=='Monthly' ?  this.reqQuoteDetails.monthly_amt :
                  rmd_mode=='Quarterly' ? this.reqQuoteDetails.quaterly_amt :
                  rmd_mode=='Semi-Annual' ? this.reqQuoteDetails.semi_annual_amt : 0.00;

            let callerRole = (roles).charAt(0).toLocaleUpperCase() + roles.slice(1);
            const resourceService = OcInfraModule.AppInjector.get(ResourceService);
            let policyDetails = resourceService.getResourceFromAliasName('policyDetails');
            let ownerName = policyDetails.data.policy_owner_name
            let hrefWithParam = `${href}?callerRole=${callerRole}&withholdingIndicator=${withholdingIndicator}&paymentMethod=${payment_method}&rmdMode=${rmd_mode}&withholdingOverrideInd=${withholdingOverrideInd}&overrideValueInd=${overrideValueInd}`;  
            var rmddDetails = this.rmdDetails;
            var childQuote = this.childRmdQuote;
            let accountNumber = this.childPayment.accountNumber;
            let transitNumber = this.childPayment.routingNumber;
            OcInfraModule.AppInjector.get(CommonService).getCallback(hrefWithParam, this.headers).subscribe(response => {
                this.scriptRes = response.confirm_message;
                if(payment_method== "C"){
                    let rmdAmount = reqAmount;
                    this.scriptRes = this.scriptRes.replace('_________',rmd_mode);
                    this.scriptRes = this.scriptRes.replace('_______', rmdAmount);
                }

                if(payment_method== "E"){
                    this.scriptRes = this.scriptRes.replace('_________',rmd_mode);
                    let rmdAmount = reqAmount;
                    this.scriptRes = this.scriptRes.replace('______', rmdAmount);
                    this.scriptRes = this.scriptRes.replace('________',transitNumber);
                    this.scriptRes = this.scriptRes.replace('_______', accountNumber);
                    this.scriptRes = this.scriptRes.replace('_________________', ownerName);

                }
                    
                this.confirmMessage = this.scriptRes;
                }, error => {
                OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
                });          

        }
    }

    async getQuoteDetails() {
        this.rmdQuoteHref = this.policyDetails && this.policyDetails._links.rmdQuote.href;
        if (!this.rmdQuoteHref) return;
        OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false });
        await OcInfraModule.AppInjector.get(CommonService).getCallback(this.rmdQuoteHref, this.headers).subscribe((response: any) => {
            this.quoteDetails = response;
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });

            if(!this.quoteDetailsCheck){
                this.quoteDetailsCheck = true
                this.reqQuoteDetails = this.quoteDetails;
            }

            //For alert message which are specific for tab   
            let alertMessages = this.commonService.getNested(response, '_embedded', 'status_report', 'messages');
            if (alertMessages) {
                this.alertMessages = alertMessages;
                this.alertMessages = alertMessages.filter(item => item.message != 'No mapping rule matched');
            }
            this.isExistingRMD = (response._options.links.length > 0 && response._options.links.filter((item) => item.rel == 'Update')) ? true : false;
            this.isHostContractAlert = this.isHostContract && this.alertMessages.length > 0 && this.isExistingRMD ? true : false;
            let isMrpsContractMsg = []
            if(this.alertMessages.length > 0) {
                isMrpsContractMsg = this.alertMessages.filter(item => item.message == 'RPS Contract - Invalid Tax Market. Submit Work Request');
            }
            this.isMrpsContractAlert = this.isMrpsContract && this.isExistingRMD && isMrpsContractMsg.length > 0 ? true : false;
        }, (error: any) => {
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
            if (error && error._embedded && error._embedded.status_report && error._embedded.status_report.consistent === false) {
                OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error._embedded.status_report.messages[0].message, 'Error', 0);
            }
        });
    }


    getRMDDetails() {
        this.rmdHref = this.policyDetails && this.policyDetails._links.rmd.href;
        if (!this.rmdHref) return;

        let effective_date = this.getNested(this.policyDetails, 'response', 'aart_date');
        let params = { quoteEffectiveDate: effective_date };
        OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false });
        OcInfraModule.AppInjector.get(CommonService).getCallback(this.rmdHref, this.headers, params).subscribe(response => {
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
            this.rmdDetails = response;


        }, error => {
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
            if (error && error._embedded && error._embedded.status_report && error._embedded.status_report.consistent === false) {
                OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error._embedded.status_report.messages[0].message, 'Error', 0);
            }
        });
    }

    getRMDDetailsEvent = ($event: any) => {
        this.getRMDDetailsForGroup($event);
    }

    getRMDDetailsForGroup = (headers: any) => {
        this.headers.plan_id = headers.plan_id;;
        this.headers.client_id = headers.client_id;
        this.headers.product_selection = headers.product_selection;
        this.getRMDDetails();
    }


    getQuoteDetailsEvent = (headers: any) => {
        this.headers.plan_id = headers.plan_id;;
        this.headers.client_id = headers.client_id;
        this.headers.product_selection = headers.product_selection;
        this.getQuoteDetails();
    }


    getNested(obj, ...args) {
        return args.reduce((obj, level) => obj && obj[level], obj)
    }



}


