import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '../../../services/common.service';
import { OcInfraModule, ResourceService } from '@diaas/ux-web';
import { AppDateAdapter, APP_DATE_FORMATS } from '../../format-datepicker';
import {DateAdapter, MAT_DATE_FORMATS} from '@angular/material/core';
import * as $ from 'jquery';
@Component({
  selector: 'additional-claim-information',
  templateUrl: './additional-claim-information.component.html',
  styleUrls: ['additional-claim-information.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: AppDateAdapter},
    {provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS}
  ]
})

export class AdditionalClaimInformationComponent {

  @Input() claimsData: any;

  // Data of claim notification form valid or not
  @Input() claimNotificationStepValid: any;
  @Input() claimNotificationForm: any;
  @Output() onSubmitSuccess: EventEmitter<any> = new EventEmitter<any>();

  funeralHomeRequired: boolean = false;
  spouseLivingRequired: boolean = false;
  requiredBenficiary: boolean = false;

  funeralHomeAssignment: any;

  beneficiaryPerson: any;
  beneficiaryUSResident :any;
  beneficiaryEachReceiveMinimumAmt :any;

  beneficiaryPersonRequired: boolean =false;
  beneficiaryResidentRequired :boolean = false;
  beneficiaryAmountRequired : boolean = false;

  maritalStatus: any;
  spouseLiving: any;
  claimCategory: any;
  effectiveMaxDate = new Date();
  effectiveMinDate = new Date(1900, 1, 1);
  requiredProperties: any;
  authorizationCheck: boolean = false;
  selectedRole :any;
  validRole :boolean = false; 
  authorizedRolesForTCA = ["beneficiary", "agent", "agent assistant"];
  // Form
  additionalclaiminfo: FormGroup;
  message: string;
  isAnnuity: boolean = false;
  details:any;  

  constructor(private _formBuilder: FormBuilder) {
  }

  ngOnInit() {
    this.details = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyDetails');
    this.isAnnuity = (this.details.data.product_type.includes('A') ? true : false);    
    this.formDropdownValues();
    this.formInit();
    if (OcInfraModule.AppInjector.get(CommonService).authsucess || OcInfraModule.AppInjector.get(CommonService).otherRoleAuthsucess) {
      this.message = "Enable button by completing required items."
      this.authorizationCheck = true;
    }
    else {
      this.authorizationCheck = false;
      this.message = "Caller not authorized."
    }
    this.selectedRole = OcInfraModule.AppInjector.get(CommonService).selectedRole;
    let productInfo =OcInfraModule.AppInjector.get(CommonService).productInfo;
    if((productInfo.company_code == 'MET' || productInfo.company_code == 'MTL') && (!this.isAnnuity) && (this.selectedRole.toLowerCase().includes('beneficiary') || this.selectedRole.toLowerCase() == 'agent' || this.selectedRole.toLowerCase() == 'agent/advisor assistant')){
      this.validRole = true;
    }else{
      this.validRole = false;
    }
  }

  formDropdownValues() {
    // For form field validation
    this.requiredProperties = this.claimsData._options.required;
    // Required condition for mat toggle button group
    for (let i = 0; i < this.requiredProperties.length; i++) {
      if (this.requiredProperties[i] == 'funeralHomeAssignment') {
        this.funeralHomeRequired = true;
      }
      if (this.requiredProperties[i] == 'spouseLiving') {
        this.spouseLivingRequired = true;
      }
      if (this.requiredProperties[i] == 'beneficiaryPerson') {
        this.beneficiaryPersonRequired = true;
      }
      if (this.requiredProperties[i] == 'beneficiaryUSResident') {
        this.beneficiaryResidentRequired = true;
      }
      if (this.requiredProperties[i] == 'beneficiaryEachReceiveMinimumAmt') {
        this.beneficiaryAmountRequired = true;
      }
    }

    // Form select option list
    this.funeralHomeAssignment = this.claimsData._options.properties.funeralHomeAssignment.oneOf;
    this.beneficiaryPerson= this.claimsData._options.properties.funeralHomeAssignment.oneOf
    this.beneficiaryUSResident = this.claimsData._options.properties.funeralHomeAssignment.oneOf
    this.beneficiaryEachReceiveMinimumAmt = this.claimsData._options.properties.funeralHomeAssignment.oneOf;
    this.maritalStatus = this.claimsData._options.properties.maritalStatus.oneOf;
    this.spouseLiving = this.claimsData._options.properties.spouseLiving.oneOf;
    this.claimCategory = this.claimsData._options.properties.claimCategory.oneOf;
  }

  formInit() {
    this.additionalclaiminfo = this._formBuilder.group({
      beneficiaryKnownbyCaller: new FormControl(''),
      funeralHomeAssignment: new FormControl('0'),
      beneficiaryPerson: new FormControl('Unknown'),
      beneficiaryUSResident: new FormControl('Unknown'),
      beneficiaryEachReceiveMinimumAmt: new FormControl('Unknown'),
      beneficiaryName: new FormControl(''),
      estateProbated: new FormControl(''),
      maritalStatus: new FormControl(this.isAnnuity ? '' : '0'),
      spouseLiving: new FormControl(this.isAnnuity ? '' : '0'),
      spouseName: new FormControl({ value: '', disabled: true }),
      spouseDateOfDeath: new FormControl(''),
      claimCategory: new FormControl('-1'),
      childrenNames: new FormControl('')
    })
  }


  changeBenficiaryField(e) {
    if (e.checked == true) {
      this.requiredBenficiary = true;
      this.additionalclaiminfo.controls['beneficiaryName'].setValidators([Validators.required]);
    } else {
      this.requiredBenficiary = false;
      this.additionalclaiminfo.controls['beneficiaryName'].clearValidators();
    }
  }

  maritalStatusChange(e) {
    let maritalStatusOption = ["0", "2"];
    if (maritalStatusOption.includes(e.value) || e.value == undefined){
      this.additionalclaiminfo.controls["spouseName"].setValue("");
      this.additionalclaiminfo.get("spouseName").disable();
    } else {
      this.additionalclaiminfo.get("spouseName").enable();
    }
  }

  charCountChildName(){
    var text_max = 256;
    $('#count_message_childName').html('0 / ' + text_max);
    var text_length = $('#childName').val().length;
    var text_remaining = text_max - text_length;
    $('#count_message_childName').html(text_length + ' / ' + text_max);
  }

  nextButton() {
    if(new Date(this.details.data.issue_date) >= this.claimNotificationForm.dateOfDeath){
      OcInfraModule.AppInjector.get(CommonService).showConfirmationPopup('Date Of Death must be greater than Policy Effective Date ', ' ', 'ok', 'alert', '300px');
    }else{
      this.additionalclaiminfo.disable();
      this.onSubmitSuccess.emit();
    }
  }

  getAdditionalClaimValues() {
    return this.additionalclaiminfo.value;
  }

  isStepComplete() {
    return this.additionalclaiminfo.valid;
  }

}
