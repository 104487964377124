import filter from 'lodash-es/filter';
import { Sort, MatTableDataSource, MatPaginator } from '@angular/material';
import { Table, Property, ResourceDirectoryService, ResourceService, OcInfraModule, OptionParams,APICallerService } from '@diaas/ux-web';
import { HAL_Constants } from '@diaas/ux-web/util/API-configuration/HAL-constants'; 
import * as _ from 'lodash'; 
export class CustomTable extends Table {
    viewVaule: any;

    constructor(input: Table) {
        super(input);
    }
    resource : any;
    /**
     * Custom method to perform sorting on table's header.
     * {Sort} sort
     */
    public sortData(sort: Sort) {
        if (sort.active && sort.direction) {
            this.inputSort = sort;
        }
        let sortedData;
        const data = this.data.body.items.slice();
        if (!sort.active || sort.direction === '') {
            sortedData = data;
            return;
        }
        sortedData = data.sort((a, b) => {
            const isAsc = sort.direction === 'asc';
            const firstItem: Property[] = filter(a.fields, function (item) {
                return item.label === sort.active;
            });
            const secondItem: Property[] = filter(b.fields, function (item) {
                return item.label === sort.active;
            });
            if (firstItem && secondItem) {
                return this.compare(firstItem[0].value, secondItem[0].value, isAsc);
            }
        });
        const DATE_COLUMN_LABELS = ['Begin Date/Time','_EFFECTIVE_DATE','_DATE_TIME','Date']
        if (DATE_COLUMN_LABELS.includes(sort.active)) {
            sortedData = data.sort((a, b) => {
                const isAsc = sort.direction === 'asc';
                const firstItem: Property[] = filter(a.fields, function (item) {
                    return item.label === sort.active;
                });
                const secondItem: Property[] = filter(b.fields, function (item) {
                    return item.label === sort.active;
                });
                if (firstItem && secondItem) {
                    return this.compare(new Date(firstItem[0].value), new Date(secondItem[0].value), isAsc);
                }
            });
        }
        super.prepareSortedData(sortedData);
    }

  public setPaginator(mp: MatPaginator) {
    if (mp) {
      mp.disabled = this.data && this.data.body && this.data.body.items.length ? this.data.body.items.length <= 5 : true;
    }
  }
  public updateTableConsistency(): void {
    if (this.data && this.data.body) {
      if (this.data.body.items.length) {
        this.resource ? this.pagination = true : this.pagination = this.data.body.items.length > 0;
      } else {
        this.pagination = this.data.body.items.length > 0;
      }
      if (this.dataSource && this.data.body.items.length) {
        this.sortData(this.inputSort)
      }
      this.consistent = this.data.body.items.every(item => {
        return item.consistent === true;
      });
    }
  }

  public getBlock(block: string): void {
    const alias = OcInfraModule.AppInjector.get(ResourceService).getAliasByAliasName(this.alias);
    const headers = OcInfraModule.AppInjector.get(ResourceService).getHeaders(this.alias)
    if (alias) {
      const collectionResource = OcInfraModule.AppInjector.get(ResourceDirectoryService).getElementResourceDirectory(
        alias.href,
        alias.params
      );
      if (
        collectionResource &&
        collectionResource[HAL_Constants._links] &&
        collectionResource[HAL_Constants._links][HAL_Constants[block]]
      ) {
        
        const params = new OptionParams();
        params.alias = alias.id;
        params.headers = headers;
        this.data.body.items = [];
        this.dataSource = new MatTableDataSource([]);
        params.params = [];
       
        const pairs = collectionResource[HAL_Constants._links][HAL_Constants[block]][HAL_Constants.href]
          .split('?')[1]
          .split('&');

        pairs.forEach(pair => {
          const queryParam = pair.split('=');
          params.params.push({ key: queryParam[0], value: queryParam[1] });
        });
        OcInfraModule.AppInjector.get(APICallerService)
          .refresh(alias.href,params)
          .subscribe(res =>{
            this.resource = res.response[HAL_Constants._links][HAL_Constants.previous];
          }
          );
      }
    }
  }
}