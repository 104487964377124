import { Component, OnInit, ViewChild } from '@angular/core';
import { APICallerService, OcInfraModule, OptionParams, ResourceService } from '@diaas/ux-web';
import { MatPaginator, MatTableDataSource, MatSort } from '@angular/material';
import * as _ from 'lodash';
import { CommonService, retryOperation } from '../../services/common.service';

export interface TableData {
  div_year: string;
  primary_option: string;
  secondary_option: string;
  declared_date: string;
  declared_amount: string;
}

@Component({
  selector: 'app-dividend-history',
  templateUrl: './dividend-history.component.html',
  styleUrls: ['./dividend-history.component.scss']
})
export class DividendHistoryComponent implements OnInit {
  displayedColumns: string[] = ['div_year', 'primary_option', 'secondary_option', 'declared_date', 'declared_amount'];
  noRecords: boolean = true;
  DivTabData: TableData[];
  dividendTableData: any;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  nextBlock: boolean;
  previousBlock: boolean;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }
    policyDetails: any;

    retryDelayP = 3500;
    retryAttemptsP = 7;
  initCall: boolean = false;

  constructor(private commonService: CommonService) { }

    ngOnInit() {
        this.DivTabData = [];
        this.dividendTableData = new MatTableDataSource(this.DivTabData);
        this.policyDetails = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyDetails');
        this.getPolicyDetailsPromise();
    }

    getPolicyDetailsPromise = async () => {
        await retryOperation(this.getPolicyDetails, this.retryDelayP, this.retryAttemptsP)
            .then((res) => {
                this.getDividendHistoryData();
            })
            .catch((err) => { });
    };

    getPolicyDetails = () => {
        let policyDetails = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyDetails');
        if (policyDetails) {
            return Promise.resolve(policyDetails);
        } else {
            return Promise.reject('No data');
        }
    }

    getDividendHistoryData = async () => {
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let policyDetails = resourceService.getResourceFromAliasName('policyDetails');
        let [url, params] = this.commonService.getHrefUrlWithParams(policyDetails._links['dividendHistory'].href);
        let optionParams = new OptionParams();
        optionParams.alias = "dividendHistory";
        optionParams.headers = OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("dividendHistory");
        let sortParams = { key: "_sort", value: "-dividend_year" };
        params.push(sortParams);
        optionParams.params = params;
        await OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false });
        await OcInfraModule.AppInjector.get(APICallerService).refresh(url, optionParams).subscribe(async response => {
            this.initCall = true;
            this.getDividendDetails(response);
            await OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
        }, async (err: any) => {
            await OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
        });
    }

  getDividendDetails(response) {
    if (response && response._links && response._links.item) {
      if (response._links.previous) {
        this.previousBlock = true;
      } else {
        this.previousBlock = false;
      }
      if (response._links.next) {
        this.nextBlock = true;
      } else {
        this.nextBlock = false;
      }
      this.DivTabData = response._links.item;
      this.dividendTableData = new MatTableDataSource(this.DivTabData);
      this.dividendTableData.paginator = this.paginator;
      this.sortData({ active: "div_year", direction: "desc" });
    }
    this.sortedData = response._embedded ? [] : response._links.item;
    this.noRecords = !this.DivTabData || !this.DivTabData.length;
    this.dividendTableData.sort = this.sort;
  }

  setDataSourceAttributes() {
    if (this.dividendTableData) {
      this.dividendTableData.paginator = this.paginator;
      this.dividendTableData.sort = this.sort;
    }
  }

  sortedData: TableData[];
  sortData(sort) {
    const data = this.dividendTableData.filteredData.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }
    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'div_year': return compare(new Date(a.summary.dividend_year).getTime(), new Date(b.summary.dividend_year).getTime(), isAsc);
        default: return 0;
      }
    });
    this.dividendTableData = new MatTableDataSource(this.sortedData);
  }

}
function compare(a: number | Date | string, b: number | Date | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}