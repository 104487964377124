import { CommonService } from './../../../../services/common.service';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { PartySearchFactory } from '../../../../factories/partySearchFactory';
import { SurrenderDetails, FundValue, OneOf, OneOfData, SurrenderValues, ItemProducts, ItemAssetAccount, distributionIndicator } from './systematic-withdrawal-withdrawal.constant';
import * as moment from 'moment';

import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { AppDateAdapter, APP_DATE_FORMATS } from '../../../format-datepicker';
import { OcInfraModule, ResourceService } from '@diaas/ux-web';
@Component({
  selector: 'app-systematic-withdrawal-withdrawal',
  templateUrl: './systematic-withdrawal-withdrawal.component.html',
  styleUrls: ['./systematic-withdrawal-withdrawal.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  ]
  
})
export class SystematicWithdrawalWithdrawalComponent implements OnInit, OnChanges {

    @Input() withdrawlSurrenderType: any;
    @Input() surrenderDetails: SurrenderDetails | any;
    @Input() isLife: boolean;
    @Input() headers: Object;
    @Output() systematicWithdrawalsEvent = new EventEmitter<Object>();
    @Output() passDistributionData: EventEmitter<any> = new EventEmitter<any>();

    alertMsg = [];
    surrenderForm: FormGroup;

    responseOptions: any;
    requiredFields: any;

    options: OneOfData[] = [];
    modes: OneOfData[] = [];
    transactionLevels: OneOfData[] = [];

    federal_withholding_override: boolean = false;
    state_withholding_override: boolean = false;

    client_recommend_or_authorized: boolean = false;
    agent_recommend_or_authorized: boolean = false;

    maskSeparator = 'separator.2';
    separatorLimit = '9999999999';
    prefix = '$';
    decimalPlaces = 2;

    minLoanAmount = '';
    maxLoanAmount = '';

    fundsInfo: boolean = false;
    funds: FundValue[] = [];

    totalFunds: string = ''

    isGroupProduct: boolean = false;
    policyDetails: any;

    isMrpsContract: boolean = false;
    isCv15Contract: boolean = false;
    isHostContract: boolean = false;
    isRetailAnnuity: boolean = false;
    isGroupAnnuity: boolean = false;

    clientProducts: ItemProducts[];
    assetAccounts: ItemAssetAccount[];

    clientProductItems: any;
    assetAccountItems: any;

    clientProductsUrl: string = '';
    assetAccountsUrl: string = '';
    isGroupMrpsContract: boolean = false;

    hostContractAlert: any;
    isExistingSWP: boolean;
    get isHostContractAlert(): any {
        return this.hostContractAlert;
    }
    @Input() set isHostContractAlert(value: any) {
        this.hostContractAlert = value;
        (this.hostContractAlert) && this.disableFormControls()   
    }

    mrpsContractAlert: any;
    get isMrpsContractAlert(): any {
        return this.mrpsContractAlert;
    }
    @Input() set isMrpsContractAlert(value: any) {
        this.mrpsContractAlert = value;
        (this.mrpsContractAlert) && this.disableFormControls() 
    }

    percentOverrideBasics: any[] = [];

    constructor(
        private formBuilder: FormBuilder,
        private partySearchFactory: PartySearchFactory,
        private commonService: CommonService
    ) { }

    ngOnInit() {
        this.policyDetails = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyDetails');
        this.isGroupProduct = this.isGroupProductType();
        this.isRetailAnnuity = this.partySearchFactory.isRetailAnnuity();
        this.isGroupAnnuity = this.partySearchFactory.isOnlyGroupAnn();

        this.isGroupMrpsContract = (this.policyDetails.response.product_type == 'A3' || this.policyDetails.response.product_type == 'A4') ? true :false;
        this.isCv15Contract = this.policyDetails.response.isCv15Contract ? true : false;
        this.isMrpsContract = this.policyDetails.response.isMrpsContract ? true : false;
        this.isHostContract = this.policyDetails.response.isHostContract ? true : false;

        this.surrenderFormInit();
        this.surrenderForm.get('effective_date').setValue(new Date(this.policyDetails.response.aart_date));
        
        //code for disable the fed and state withholding fields
        this.surrenderForm.get('federal_withholding_amount').disable();
        this.surrenderForm.get('federal_withholding_percentage').disable();
        this.surrenderForm.get('state_withholding_amount').disable();
        this.surrenderForm.get('state_withholding_percentage').disable();
        this.surrenderForm.get('statePctOrBasis').disable(); 

        
        if ((this.isCv15Contract || this.isMrpsContract  || this.isHostContract) && this.isRetailAnnuity) {  
            this.getSurrenderDetails();
            this.getSurrenderValues();
        } else if((this.isMrpsContract || this.isHostContract) && this.isGroupAnnuity) {
            this.headers = {...this.headers, ...{client_id: this.commonService.productInfo.client}};
            this.processA4Product();
            this.surrenderForm.addControl("productType", new FormControl("", []));
            this.surrenderForm.addControl("accountType", new FormControl("", [Validators.required]));
        }

    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.surrenderDetails.currentValue) {
            if (this.isCv15Contract || this.isMrpsContract || this.isHostContract) { // MRPS
                this.surrenderDetails = changes.surrenderDetails.currentValue;
                this.getSurrenderDetails();
                this.getSurrenderValues();
            }
        }
    }


    surrenderFormInit() {
        let percentValue = this.formatPercent('', 'blur', '0.00');
        let currencyValue = this.formatCurrency('', 'blur', '0.00')

        this.surrenderForm = this.formBuilder.group({
            effective_date: ['', [Validators.required]],
            start_next_date: ['', [Validators.required]],
            
            option: ['', [Validators.required]],
            mode: ['', [Validators.required]],
            end_date: ['', [Validators.required]],
            
            payout_amount: ['', [Validators.required]],
            payout_percent: [''],
            transaction_level: ['',[Validators.required]],

            no_federal_withholding: [false],
            federal_withholding_override: [false],
            federal_withholding_amount: [currencyValue],
            federal_withholding_percentage: [percentValue],

            no_state_withholding: [false],
            state_withholding_override: [false],
            state_withholding_amount: [currencyValue],
            state_withholding_percentage: [percentValue],

            distribution: ['G',[Validators.required]],
            statePctOrBasis: [' '], // PANOCSR-3425 Percent Override Basis default should be BLANK 

        });        
    }

    get federal_withholding_amount() {
        return this.surrenderForm.get('federal_withholding_amount').value;
    }

    set federal_withholding_amount(val) {
        this.surrenderForm.get('federal_withholding_amount').setValue(val);
    }

    get federal_withholding_percentage() {
        return this.surrenderForm.get('federal_withholding_percentage').value;
    }

    set federal_withholding_percentage(val) {
        this.surrenderForm.get('federal_withholding_percentage').setValue(val);
    }

    get state_withholding_amount() {
        return this.surrenderForm.get('state_withholding_amount').value;
    }

    set state_withholding_amount(val) {
        this.surrenderForm.get('state_withholding_amount').setValue(val);
    }

    get state_withholding_percentage() {
        return this.surrenderForm.get('state_withholding_percentage').value;
    }

    set state_withholding_percentage(val) {
        this.surrenderForm.get('state_withholding_percentage').setValue(val);
    }

    get transaction_level() {
        return this.surrenderForm.get('transaction_level').value;
    }

    set transaction_level(val) {
        this.surrenderForm.get('transaction_level').setValue(val);
    }

    get option() {
        return this.surrenderForm.get('option').value;
    }

    set option(val) {
        this.surrenderForm.get('option').setValue(val);
    }

    get mode() {
        return this.surrenderForm.get('mode').value;
    }

    set mode(val) {
        this.surrenderForm.get('mode').setValue(val);
    }

    get payout_amount() {
        return this.surrenderForm.get('payout_amount').value;
    }

    set payout_amount(val) {
        this.surrenderForm.get('payout_amount').setValue(val);
    }

    get payout_percent() {
        return this.surrenderForm.get('payout_percent').value;
    }

    set payout_percent(val) {
        this.surrenderForm.get('payout_percent').setValue(val);
    }

    disableFormControls = () => {
        // Disable all form controls
        Object.keys(this.surrenderForm.controls).forEach(key => {
            this.surrenderForm.controls[key].disable();
        });
    }

    isGroupProductType = () => {
        let product_type = this.getNested(this.policyDetails, 'response', 'product_type');
        if (product_type && (product_type == "A3" || product_type == "A4")) {
            return true;
        } else {
            return false;
        }
    }

    getSurrenderDetails() {
        let response = this.surrenderDetails;
        this.responseOptions = response._options;
        this.requiredFields = response._options && response._options.required || [];

        this.funds = JSON.parse(JSON.stringify(this.surrenderDetails.fundValues));

        this.loadOptions();
        this.loadModes();
        this.loadTransactionLevels()
        this.loadPercentOverrideBasics()

        if (this.surrenderDetails.effective_date != undefined) {
            // this.surrenderForm.get('effective_date').setValue(new Date(this.surrenderDetails.effective_date));
            this.surrenderForm.get('effective_date').disable();
        }   

        this.surrenderForm.get('end_date').setValue(new Date('12/31/9999'));
        this.surrenderForm.get('option').setValue('A');
        this.surrenderForm.get('payout_percent').disable();
        this.totalFunds = this.funds ? this.getTotalofFunds(this.funds, 'CURRENCY') : '$0.0';
        
        this.surrenderForm.get('statePctOrBasis').setValue(" ", {onlySelf: true});
        
        this.surrenderForm.get('transaction_level').setValue('P');
        //const isExistingSWP = response.existing_record == 'Y' ? true : false;
        this.isExistingSWP = response.existing_record == 'Y' ? true : false;
        if(this.isExistingSWP) {
            this.loadExistingSWP()
        }

    }

    loadExistingSWP = () => {

        if(this.surrenderDetails.next_date != undefined) {
            this.surrenderForm.get('start_next_date').setValue(new Date(this.surrenderDetails.next_date));
        }
        if(this.surrenderDetails.option != undefined && this.surrenderDetails.option != '') {
            this.surrenderForm.get('option').setValue(this.surrenderDetails.option);
        } else {
            this.surrenderForm.get('option').setValue('A');
        }
        if(this.surrenderDetails.state_withholding_amount) {
            let currencyValue = this.formatCurrency('', 'blur', this.surrenderDetails.state_withholding_amount)
            this.surrenderForm.get('state_withholding_amount').setValue(currencyValue);
        }
        if(this.surrenderDetails.federal_withholding_amount) {
            let currencyValue = this.formatCurrency('', 'blur', this.surrenderDetails.federal_withholding_amount)
            this.surrenderForm.get('federal_withholding_amount').setValue(currencyValue);
        }
        if(this.surrenderDetails.state_withholding_percentage) {
            let percentValue = this.formatPercent('', 'blur', this.surrenderDetails.state_withholding_percentage);
            this.surrenderForm.get('state_withholding_percentage').setValue(percentValue);
        }
        if(this.surrenderDetails.federal_withholding_percentage) {
            let percentValue = this.formatPercent('', 'blur', this.surrenderDetails.federal_withholding_percentage);
            this.surrenderForm.get('federal_withholding_percentage').setValue(percentValue);
        }
        if(this.surrenderDetails.transaction_level != undefined && this.surrenderDetails.transaction_level != '') {
            this.surrenderForm.get('transaction_level').setValue(this.surrenderDetails.transaction_level);
        } else {
            this.surrenderForm.get('transaction_level').setValue('P');
        }

        if(this.surrenderDetails.frequency != undefined) {
            this.surrenderForm.get('mode').setValue(this.surrenderDetails.frequency);
        }


        if(this.surrenderDetails.payout_amount != undefined) {
            let payoutAmount = this.formatCurrency('', 'blur', this.surrenderDetails.payout_amount);
            this.surrenderForm.get('payout_amount').setValue(payoutAmount);
        }
        if(this.surrenderDetails.payout_percent != undefined) {
            let payoutPercent = this.formatPercent('', 'blur', this.surrenderDetails.payout_percent)
            this.surrenderForm.get('payout_percent').setValue(payoutPercent);
        }

        if (this.surrenderDetails.statePctOrBasis != undefined && this.surrenderDetails.statePctOrBasis != '') {
            this.surrenderForm.get('statePctOrBasis').setValue(this.surrenderDetails.statePctOrBasis, {onlySelf: true});
        } else {
            this.surrenderForm.get('statePctOrBasis').setValue('', {onlySelf: true});
        }

        if(this.surrenderDetails.federal_withholding_override == 'Y') {
            this.surrenderForm.get('federal_withholding_override').setValue(true);
            this.surrenderForm.get('federal_withholding_amount').enable();
            this.surrenderForm.get('federal_withholding_percentage').enable();
        }

        if(this.surrenderDetails.state_withholding_override == 'Y') {
            this.surrenderForm.get('state_withholding_override').setValue(true);
            this.surrenderForm.get('state_withholding_amount').enable();
            this.surrenderForm.get('state_withholding_percentage').enable();
            this.surrenderForm.get('statePctOrBasis').setValue('A');
            this.surrenderForm.get('statePctOrBasis').enable();
        }

        this.optionValidators(true)
    }

    
    loadOptions = () => {
        let options = this.getNested(this.surrenderDetails._options.properties, 'option');
        if (options && options.oneOf.length > 0) {
            this.options = options.oneOf.map((ele: OneOf): OneOfData => {
                return <OneOfData>{ value: ele.enum[0], label: ele.title };
            });

            this.surrenderForm.get('option').setValue('A')
        }
    }

    loadModes = () => {
        let modes = this.getNested(this.surrenderDetails._options.properties, 'frequency');
        if (modes && modes.oneOf.length > 0) {
            this.modes = modes.oneOf.map((ele: OneOf): OneOfData => {
                return <OneOfData>{ value: ele.enum[0], label: ele.title };
            });
        }
    }

    loadTransactionLevels = () => {
        let transactionLevels = this.getNested(this.surrenderDetails._options.properties, 'transaction_level');
        if (transactionLevels && transactionLevels.oneOf.length > 0) {
            this.transactionLevels = transactionLevels.oneOf.map((ele: OneOf): OneOfData => {
                return <OneOfData>{ value: ele.enum[0], label: ele.title };
            });
        }
    }

    loadPercentOverrideBasics = () => {
        let percentOverrideBasics = this.getNested(this.surrenderDetails._options.properties, 'statePctOrBasis');
        if (percentOverrideBasics && percentOverrideBasics.oneOf.length > 0) {
            this.percentOverrideBasics = percentOverrideBasics.oneOf.map((ele: OneOf): OneOfData => {
                return <OneOfData>{ value: ele.enum[0], label: ele.title };
            });
        }
    }


    onOptionChangeEvent = ($event: any) => {     
        this.surrenderForm.get('payout_amount').setValue('');
        this.surrenderForm.get('payout_percent').setValue('');
        // if(this.transaction_level == 'F') return null;
        this.optionValidators()
    }

    onTransactionLevelEvent = ($event: any) => {
        this.optionValidators()
    }

    optionValidators = (existing?: boolean) => {

        if(!existing) {
            let percentValue = this.formatPercent('', 'blur', '0.00');
            let currencyValue = this.formatCurrency('', 'blur', '0.00')
            this.surrenderForm.get('payout_amount').setValue(currencyValue);
            this.surrenderForm.get('payout_percent').setValue(percentValue);
        }

        if (this.option == 'G') {
            this.transaction_level = 'P';
        }
       
        if (this.option == 'A' && this.transaction_level == 'P') {
            this.fundsInfo = false;
            this.isHostContractAlert ? this.surrenderForm.get('payout_amount').disable() : this.surrenderForm.get('payout_amount').enable();
            this.surrenderForm.get('payout_percent').disable();
            this.surrenderForm.get('payout_amount').setValidators(Validators.required);
            this.surrenderForm.get('payout_percent').setValidators(null);
        } else if (this.option == 'P' && this.transaction_level == 'P') {
            this.fundsInfo = false;
            this.surrenderForm.get('payout_amount').disable();
            this.surrenderForm.get('payout_percent').enable();
            this.surrenderForm.get('payout_amount').setValidators(null);
            this.surrenderForm.get('payout_percent').setValidators(Validators.required);
        } else if (this.option == 'G' && this.transaction_level == 'P') {
            this.fundsInfo = false;
            this.surrenderForm.get('payout_amount').disable();
            this.surrenderForm.get('payout_percent').disable();
            this.surrenderForm.get('payout_amount').setValidators(null);
            this.surrenderForm.get('payout_percent').setValidators(null);
        } else if ((this.option == 'A' || this.option == 'P' || this.option == 'G') && this.transaction_level == 'F') {
            this.fundsInfo = true;
            this.surrenderForm.get('payout_amount').disable();
            this.surrenderForm.get('payout_percent').disable();
            this.surrenderForm.get('payout_amount').setValidators(null);
            this.surrenderForm.get('payout_percent').setValidators(null);
            if (this.option == 'A') {
                this.totalFunds = this.funds ? this.getTotalofFunds(this.funds, 'CURRENCY') : '$0.0';
            } else if (this.option == 'P') {
                this.totalFunds = this.funds ? this.getTotalofFunds(this.funds, 'PERCENT') : '0.0%';
            }
        }
        this.surrenderForm.get('payout_amount').updateValueAndValidity();
        this.surrenderForm.get('payout_percent').updateValueAndValidity();
    }


    noFederalEvent = ($event) => {
        let percentValue = this.formatPercent('', 'blur', '0.00');
        let currencyValue = this.formatCurrency('', 'blur', '0.00')
        if ($event.checked) {
            if(this.isExistingSWP) {
                let fedaralCheck = this.surrenderForm.get('federal_withholding_override').value;
                if(fedaralCheck) {
                    this.surrenderForm.get('federal_withholding_override').setValue(false);
                    this.surrenderForm.get('federal_withholding_override').enable();
                    this.federal_withholding_amount = currencyValue;
                    this.federal_withholding_percentage = percentValue;
                    this.surrenderForm.get('federal_withholding_amount').disable();
                    this.surrenderForm.get('federal_withholding_percentage').disable();
                } else {
                    this.surrenderForm.get('federal_withholding_override').enable();
                    this.federal_withholding_amount = currencyValue;
                    this.federal_withholding_percentage = percentValue;  
                    this.surrenderForm.get('federal_withholding_amount').disable();
                    this.surrenderForm.get('federal_withholding_percentage').disable(); 
                }   
            } else {
                this.surrenderForm.get('federal_withholding_override').disable();
                this.federal_withholding_amount = currencyValue;
                this.federal_withholding_percentage = percentValue;  
                this.surrenderForm.get('federal_withholding_amount').disable();
                this.surrenderForm.get('federal_withholding_percentage').disable();    
            }
        } else {
            this.surrenderForm.get('federal_withholding_override').enable();
        }
    };

    noStateEvent = ($event) => {
        let percentValue = this.formatPercent('', 'blur', '0.00');
        let currencyValue = this.formatCurrency('', 'blur', '0.00')
        if ($event.checked) {
            if(this.isExistingSWP){
                let stateCheck = this.surrenderForm.get('state_withholding_override').value;
                if(stateCheck) {
                    this.surrenderForm.get('state_withholding_override').setValue(false);
                    this.surrenderForm.get('state_withholding_override').enable();
                    this.state_withholding_amount = currencyValue;
                    this.state_withholding_percentage = percentValue;
                    this.surrenderForm.get('statePctOrBasis').setValue(' ');
                    this.surrenderForm.get('statePctOrBasis').disable();
                    this.surrenderForm.get('state_withholding_amount').disable();
                    this.surrenderForm.get('state_withholding_percentage').disable();
                } else {
                    this.surrenderForm.get('state_withholding_override').enable();
                    this.state_withholding_amount = currencyValue;
                    this.state_withholding_percentage = percentValue;
                    this.surrenderForm.get('statePctOrBasis').setValue(' ');
                    this.surrenderForm.get('statePctOrBasis').disable(); 
                    this.surrenderForm.get('state_withholding_amount').disable();
                    this.surrenderForm.get('state_withholding_percentage').disable();  
                }
            } else {
                this.surrenderForm.get('state_withholding_override').disable();
                this.state_withholding_amount = currencyValue;
                this.state_withholding_percentage = percentValue;
                this.surrenderForm.get('statePctOrBasis').setValue(' ');
                this.surrenderForm.get('statePctOrBasis').disable(); 
                this.surrenderForm.get('state_withholding_amount').disable();
                this.surrenderForm.get('state_withholding_percentage').disable();     
            }
        } else {
            this.surrenderForm.get('state_withholding_override').enable();
        }
    };

    federalOverrideEvent = ($event: any) => {
        let percentValue = this.formatPercent('', 'blur', '0.00');
        let currencyValue = this.formatCurrency('', 'blur', '0.00')
        if ($event.checked) {
            if(this.isExistingSWP){
                let noFedaralCheck = this.surrenderForm.get('no_federal_withholding').value
                if(noFedaralCheck) {
                    this.surrenderForm.get('no_federal_withholding').setValue(false);
                    this.surrenderForm.get('no_federal_withholding').enable();
                    this.surrenderForm.get('federal_withholding_amount').enable();
                    this.surrenderForm.get('federal_withholding_percentage').enable();
                } else {
                    this.surrenderForm.get('federal_withholding_amount').enable();
                    this.surrenderForm.get('federal_withholding_percentage').enable();
                    this.surrenderForm.get('no_federal_withholding').enable();   
                }
            } else {
                this.surrenderForm.get('federal_withholding_amount').enable();
                this.surrenderForm.get('federal_withholding_percentage').enable();
                this.surrenderForm.get('no_federal_withholding').disable();     
            }

        }  else {
            this.federal_withholding_amount = currencyValue;
            this.federal_withholding_percentage = percentValue;

            this.surrenderForm.get('federal_withholding_amount').disable();
            this.surrenderForm.get('federal_withholding_percentage').disable();
            this.surrenderForm.get('no_federal_withholding').enable();
            this.passDistributionData.emit(percentValue);
        }
    }

    stateOverrideEvent = ($event: any) => {
        let percentValue = this.formatPercent('', 'blur', '0.00');
        let currencyValue = this.formatCurrency('', 'blur', '0.00')
        if ($event.checked) {
            if(this.isExistingSWP){
                let noStateCheck = this.surrenderForm.get('no_state_withholding').value;
                if(noStateCheck) {
                    this.surrenderForm.get('no_state_withholding').setValue(false);
                    this.surrenderForm.get('no_state_withholding').enable();
                    this.surrenderForm.get('state_withholding_amount').enable();
                    this.surrenderForm.get('state_withholding_percentage').enable();
                    this.surrenderForm.get('statePctOrBasis').setValue('A');
                    this.surrenderForm.get('statePctOrBasis').enable();
                } else {
                    this.surrenderForm.get('state_withholding_amount').enable();
                    this.surrenderForm.get('state_withholding_percentage').enable();
                    this.surrenderForm.get('statePctOrBasis').setValue('A');
                    this.surrenderForm.get('statePctOrBasis').enable();
                    this.surrenderForm.get('no_state_withholding').enable();
                }   
            } else {
                this.surrenderForm.get('state_withholding_amount').enable();
                this.surrenderForm.get('state_withholding_percentage').enable();
                this.surrenderForm.get('statePctOrBasis').setValue('A');
                this.surrenderForm.get('statePctOrBasis').enable();
                this.surrenderForm.get('no_state_withholding').disable();   
            }

        } else {
            this.state_withholding_amount = currencyValue;
            this.state_withholding_percentage = percentValue;

            this.surrenderForm.get('state_withholding_amount').disable();
            this.surrenderForm.get('state_withholding_percentage').disable();
            this.surrenderForm.get('no_state_withholding').enable();
            this.surrenderForm.get('statePctOrBasis').setValue(' ');
            this.surrenderForm.get('statePctOrBasis').disable();
        }
    }


    federalWithholdingEvent = ($event: any, type: string) => {
        if (type == 'AMOUNT') {
            this.federal_withholding_amount = this.formatCurrency('', 'blur', this.federal_withholding_amount);
            // this.surrenderForm.get('state_withholding_percentage').disable();
            this.surrenderForm.get('federal_withholding_percentage').disable();
            let percentValue = this.formatPercent('', 'blur', '0.00'); //PANOCSR-3429 When we are entering amount, percent should be disabled and blank or vice versa for SWP and RMD
            this.federal_withholding_percentage = percentValue;
        }

        if (type == 'PERCENT') {
            this.federal_withholding_percentage = this.formatPercent('', 'blur', this.federal_withholding_percentage);
            this.passDistributionData.emit(this.federal_withholding_percentage);
            this.surrenderForm.get('federal_withholding_amount').disable();
            let currencyValue = this.formatCurrency('', 'blur', '0.00'); //PANOCSR-3429 When we are entering percent, amount should be disabled and blank or vice versa for SWP and RMD
            this.federal_withholding_amount = currencyValue;
            this.withholdingPercentageCheck(this.federal_withholding_percentage);
        }
    };

    stateWithholdingEvent = ($event: any, type: string) => {
        if (type == 'AMOUNT') {
            this.state_withholding_amount = this.formatCurrency('', 'blur', this.state_withholding_amount);
            this.surrenderForm.get('state_withholding_percentage').disable();
            let percentValue = this.formatPercent('', 'blur', '0.00');
            this.state_withholding_percentage = percentValue;
        }

        if (type == 'PERCENT') {
            this.state_withholding_percentage = this.formatPercent('', 'blur', this.state_withholding_percentage);
            // this.surrenderForm.get('federal_withholding_amount').disable();
            this.surrenderForm.get('state_withholding_amount').disable();
            let currencyValue = this.formatCurrency('', 'blur', '0.00')
            this.state_withholding_amount = currencyValue;
            this.withholdingPercentageCheck(this.state_withholding_percentage);
        }
    };

    withholdingPercentageCheck(value){
        let totalWithPer = value.includes('%') ? value.slice(0,-1) : value;
        let totalInt = Math.trunc(totalWithPer);
        let finalVal = totalInt.toString();
        if(finalVal.length>2){
            let alertMsg = [];
            alertMsg.push("You can not enter more than 2 digits");
            let dialogRef = OcInfraModule.AppInjector.get(CommonService).showConfirmation({ content: alertMsg, custom: true }, 'CLOSE', '', 'ALERT', '393px');
            dialogRef.afterClosed().subscribe(res => {
                res;
            });
        }
    }

    getNested(obj, ...args) {
        return args.reduce((obj, level) => obj && obj[level], obj)
    }

    isStepComplete = () => {
        return this.surrenderForm.valid;
    }


    getSurrenderValues = (): SurrenderValues => {

        let { no_federal_withholding, federal_withholding_amount, federal_withholding_percentage, federal_withholding_override, no_state_withholding, state_withholding_amount, state_withholding_percentage, state_withholding_override, option, mode, distribution, transaction_level, end_date, start_next_date, payout_amount, payout_percent, statePctOrBasis } = this.surrenderForm.getRawValue();

        const optionItem = this.options.find((item: any) => item.value === option)
        const modeItem = this.modes.find((item: any) => item.value === mode)
        const transactionLevelItem = this.transactionLevels.find((item: OneOfData) => item.value == transaction_level)

        let optionLabel = optionItem ? optionItem.label : '';
        let modeLabel = modeItem ? modeItem.label : '';
        let transactionLevelLabel = transactionLevelItem ? transactionLevelItem.label : ''        
        let distributionLabel = (distributionIndicator[distribution] || distributionIndicator['G'])();
        
        if(this.transaction_level == 'F') {
            if (this.option == 'A') {
                payout_amount = this.funds ? this.getTotalofFunds(this.funds, 'CURRENCY') : '$0.0';
            } else if (this.option == 'P') {
                payout_percent = this.funds ? this.getTotalofFunds(this.funds, 'PERCENT') : '0.0%';
            }
        }
        payout_percent = payout_percent == '' ? '0.00' : payout_percent
        payout_amount = payout_amount == '' ? '0.00' : payout_amount    

        let surrenderValues: SurrenderValues = {
            effective_date: this.surrenderDetails.effective_date,
            option: option,
            optionLabel: optionLabel,
            mode: mode,
            modeLabel: modeLabel,
            distribution: distribution,
            distributionLabel: distributionLabel,
            end_date: end_date,
            start_next_date: start_next_date,

            no_federal_withholding: no_federal_withholding ? 'Y' : 'N',
            federal_withholding_amount: federal_withholding_amount,
            federal_withholding_percentage: federal_withholding_percentage,
            federal_withholding_override: federal_withholding_override,

            no_state_withholding: no_state_withholding ? 'Y' : 'N',
            state_withholding_amount: state_withholding_amount,
            state_withholding_percentage: state_withholding_percentage,
            state_withholding_override: state_withholding_override,

            payout_amount: payout_amount,
            payout_percent: payout_percent,

            transaction_level: transaction_level,
            transactionLevelLabel: transactionLevelLabel,
            alt_pay1_rem_ind: this.surrenderDetails.alt_pay1_rem_ind,
            statePctOrBasis: statePctOrBasis

        };
        return surrenderValues;
    }

    processA4Product = async () => {
        this.clientProductsUrl = this.policyDetails && this.policyDetails._links && this.policyDetails._links.clientproducts ? this.policyDetails._links.clientproducts.href : null;
        this.assetAccountsUrl = this.policyDetails && this.policyDetails._links && this.policyDetails._links.clientassetaccounts ? this.policyDetails._links.clientassetaccounts.href : null;

        try {
            let assetAccounts = await this.commonService.getClientAssetAccountsAll();
            if (!assetAccounts) {
                this.assetAccounts = assetAccounts;
            } else {
                assetAccounts = await this.commonService.getAssetAccountsApi(this.assetAccountsUrl, this.headers)
                this.assetAccounts = assetAccounts;
            }

            let clientProducts = await this.commonService.getClientProducts();
            if (!clientProducts) {
                this.commonService.clientProducts = clientProducts;
                this.clientProducts = clientProducts;
                this.processProductTypes(this.clientProducts);
            } else {
                let clientProducts = await this.commonService.getClientProductsApi(this.clientProductsUrl, this.headers)
                this.clientProducts = clientProducts;
                this.processProductTypes(this.clientProducts);
            }

        } catch (error) {
            console.log('processA4Product= ~ error:', error);
        }
    }

    processProductTypes = (clientProducts: ItemProducts[]) => {
        if (clientProducts.length > 0) {
            this.clientProductItems = clientProducts.map((item: any) => {
                return { label: item.summary.plan_code, value: item.summary.plan_code }
            });
        }
        if (this.clientProductItems.length > 0) {
            this.productTypeChange(this.clientProductItems[0].value)
        }
    }

    productTypeChange = (value: any) => {
        this.surrenderForm.get('productType').setValue(value);
        let assetAccounts = this.assetAccounts.find((item: ItemAssetAccount) => item.summary.plan_code == value);
        this.assetAccountItems = assetAccounts.summary.asset_account_names.filter((item: any) => {
            return (item != '- null') && { value: item, label: item }
        }).map((item: any) => ({
            value: item === 'ALL ASSET ACCOUNTS' ? '**' : item,
            label: item
        }));
    }

    accountTypeChange = (value: any) => {
        this.surrenderForm.get('accountType').setValue(value);
        let plan_code = this.surrenderForm.controls.productType.value;
        this.headers = { ...this.headers, ...{ plan_id: plan_code, client_id: this.commonService.productInfo.client, asset_account: value.split('-')[0].trim(), product_type: this.policyDetails.response.product_type, user_role: OcInfraModule.AppInjector.get(CommonService).userRole.toLowerCase() } }

        let params = {headers: this.headers }
        this.systematicWithdrawalsEvent.emit(params)
    }


    ngAfterViewInit() {
        setTimeout(() => {
            
        }, 0);
    }

    ngOnDestroy() {
        setTimeout(() => {
            // this.activeTab$.unsubscribe();
        }, 0);
    }

    inputChange($event: any, key: any, index: any) {
        let input_val = $event.target.value.replace(/,|\s/g, "").replace("%", "").replace("$", "");
        if (key == 'payout_percent') {
            this.payout_percent = this.formatPercent('', 'blur', input_val);
            this.percentageCheck(this.payout_percent);
        } else if (key == 'payout_amount') {
            this.payout_amount = this.formatCurrency('', 'blur', input_val);
        } else if (key == 'surrender_amt' || key == 'surrender_pct') {
            if (key == 'surrender_amt') {
                this.funds[index].surrender_amt = input_val;
                this.totalFunds = this.funds ? this.getTotalofFunds(this.funds, 'CURRENCY') : '$0.0';
            } else if (key == 'surrender_pct') {
                this.funds[index].surrender_percent = input_val;
                this.totalFunds = this.funds ? this.getTotalofFunds(this.funds, 'PERCENT') : '0.0%';
                this.percentageCheck(this.totalFunds);
            }
        }
    }

    percentageCheck (inputVal) {
        let totalFundsWithPer = inputVal.includes('%') ? inputVal.slice(0,-1) : inputVal;
        let totalFundsInt = Number(totalFundsWithPer);
        if(totalFundsInt>100) {
            let alertMsg = [];
            alertMsg.push("Total Percentage can not be more than 100");
            let dialogRef = OcInfraModule.AppInjector.get(CommonService).showConfirmation({ content: alertMsg, custom: true }, 'CLOSE', '', 'ALERT', '393px');
            dialogRef.afterClosed().subscribe(res => {
                res;
            });
        }
    }


    //formats the value with commas and $ allowing 2 decimal values
    formatCurrency(input, blur, respValue) {
        var input_val = "";
        if (respValue == '' || respValue == undefined) {
            input_val = input.target.value;
        } else {
            input_val = respValue;
        }
        if (input_val === "") { return; }
        var original_len = input_val.length;
        if (input_val.indexOf(".") >= 0) {
            var decimal_pos = input_val.indexOf(".");
            decimal_pos = decimal_pos > 15 ? 15 : decimal_pos;
            var left_side = input_val.substring(0, decimal_pos);
            var right_side = input_val.substring(decimal_pos);
            left_side = this.formatNumber(left_side, 'currency', true);
            right_side = this.formatNumber(right_side, 'currency', false);
            if (blur === "blur") {
                right_side += "00";
            }
            // Limit decimal to only 2 digits
            right_side = right_side.substring(0, 2);
            // join number by .
            input_val = "$" + left_side + "." + right_side;

        } else {
            // no decimal entered
            // add commas to number
            // remove all non-digits
            input_val = input_val.substring(0, 15);
            input_val = this.formatNumber(input_val, 'currency', true);
            input_val = "$" + input_val;
            // final formatting
            if (blur === "blur") {
                input_val += ".00";
            }
        }
        if (input_val.charAt(input_val.indexOf('$') + 1) == '.') {
            input_val = input_val.replace(input_val.charAt(input_val.indexOf('$') + 1), '0.');
        }
        if (respValue == '' || respValue == undefined) {
            input.target["value"] = input_val;
        } else {
            return input_val;
        }
    }

    // formats the value with commas and percentage allowing 1 decimal value
    formatPercent(input, blur, respValue) {
        var input_val = "";
        if (respValue == '' || respValue == undefined) {
            input_val = input.target.value;
        } else {
            input_val = respValue;
        } if (input_val === "") { return; }
        var original_len = input_val.length;
        if (input_val.indexOf(".") >= 0) {
            var decimal_pos = input_val.indexOf(".");
            var left_side = input_val.substring(0, decimal_pos);
            var right_side = input_val.substring(decimal_pos);
            left_side = this.formatNumber(left_side, 'percent', true);
            right_side = this.formatNumber(right_side, 'percent', false);
            if (blur === "blur") {
                right_side += "00";
            }
            // Limit decimal to only 2 digits
            right_side = right_side.substring(0, 2);
            // join number by .
            input_val = left_side + "." + right_side + "%";
        } else {
            // no decimal entered
            // add commas to number
            // remove all non-digits
            input_val = this.formatNumber(input_val, 'percent', true);
            if (blur === "blur") {
                input_val += ".00";
            }
            input_val = input_val + "%";
        }
        if (input_val.charAt(input_val.indexOf('$') + 1) == '.') {
            input_val = input_val.replace(input_val.charAt(input_val.indexOf('$') + 1), "0.")
        }
        if (respValue == '' || respValue == undefined) {
            input.target["value"] = input_val;
        } else {
            return input_val;
        }
    }

    getTotalofFunds(funds: any, type: string) {
        let total = 0.0;
        let tot = '';
        if (type == 'PERCENT') {
            total = funds.reduce((n, { surrender_percent }) => n + Number(surrender_percent), 0);
            tot = this.formatPercent('', 'blur', total.toString());
        } else if (type == 'CURRENCY') {
            total = funds.reduce((n, { surrender_amt }) => n + Number(surrender_amt), 0);
            tot = this.formatCurrency('', 'blur', total.toString());
        }

        return tot;
    }

    /** AMOUNT AND PERCENT FORMATING  */
    formatNumber(n, type, flag) {
        if (flag) {
            n = n.charAt(0) == '0' ? n.replace(n.charAt(0), "") : n;
        }
        if (type == 'currency') {
            return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } else {
            return n.replace(/\D/g, "");
        }
    }

    isAmountAllowableAlertFull(input_val: any) {
        if (!this.isLife && Number(input_val) > 29999.99) {
            return true
        } else {
            return false;
        }
    }

}
