
export interface ValueAssetData {
    policy_number:string;
    plan_code:string;
    lob:string;
    asset_account_code:string;
    money_source:string;
    status_code: string;
    product_code:string;
    accumulated_value:string;
    loan_payoff_value:string;
  }

  export const DisplayedAssetsTableColumns = [
    'policy_number', 
    'plan_code', 
    'lob', 
    'asset_account_code',
    'money_source',
    'status_code',
    'product_code',
    'accumulated_value',
    'loan_payoff_value'
];