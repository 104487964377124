import { Component, OnInit, ElementRef, Input } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { OcInfraModule } from '@diaas/ux-web';

@Component({
  selector: 'app-current-fund-table-component',
  templateUrl: './current-fund-table-component.component.html',
  styleUrls: ['./current-fund-table-component.component.scss']
})


export class CurrentFundTableComponentComponent implements OnInit {

  
    displayedColumns: string[] = ['name', 'fund_type_desc', 'allocation_percent', 'interest_rate', 'fund_value'];
    selectedDate: Object = {};

    private fundList: any;
    @Input() set funds(value: any[]) {
    this.fundList = value;
    this.updateFundList();
    }
    get funds(): any [] {
    return this.fundList;
    }

    @Input() productType: string;
    @Input() accountType: string;
    @Input() statusMsg: string = "";
    @Input() isAnnuity: boolean;

    noRecords: boolean = false;
    message: boolean = false;
    isGroupAnnuity: boolean = false;
    fundsObj: any = {};

    constructor(public elementRef: ElementRef) {

    }

    ngOnInit() {        
        if(!this.isAnnuity) {
            this.isGroupAnnuity = true;
        }
    }

    updateFundList = () => {
        this.noRecords = this.fundList && !this.fundList.length;
    }

  testChange(event, indexValue, data, selectedValue, selectedKey, columnName, valueType, flag) {
    data.id = indexValue;
    let apiName = 'funds';
    let currencyFields = OcInfraModule.AppInjector.get(CommonService).selectdCurrencyFields;
    selectedValue = valueType == 'currency' ? '$' + selectedValue.replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,') : selectedValue;

    let selectedVal: any;
    if (this.selectedDate[data.id] && columnName !== 'name') {
      selectedVal = selectedValue ? selectedValue : 'No data in admin system';
      event.checked ? this.selectedDate[data.id].push(selectedKey + ':' + selectedVal) : this.selectedDate[data.id].splice(this.selectedDate[data.id].indexOf(selectedKey + ':' + selectedVal), 1)
    } else {
      if (!this.selectedDate[data.id]) {
        selectedVal = selectedValue ? selectedValue : 'No data in admin system';
        this.selectedDate[data.id] = new Array(selectedKey + ':' + selectedVal);
      }
    }

    //Added a flag to check whether "Name" is checked or not.
    if (this.selectedDate[data.id] && columnName == 'name' && event.checked) {
      data.nameFlag = true;
    } else if ((this.selectedDate[data.id] && columnName == 'name' && !event.checked)) {
      data.nameFlag = false;
    }


    if ((this.selectedDate[data.id].length == 1 && this.selectedDate[data.id][0].indexOf('Name') !== -1 && selectedKey == "Name" && !event.checked && flag == "true") || (this.selectedDate[data.id].length == 1 && this.selectedDate[data.id][0].indexOf('Name') !== -1 && selectedKey != "Name" && !event.checked && flag != "true" && data.nameFlag == false && flag != "true")) {
      delete this.selectedDate[data.id];
    } else if(!this.selectedDate[data.id].length){
      delete this.selectedDate[data.id];
    } 
    else {
      if (event.checked ? this.selectedDate[data.id][0].indexOf('Name') === -1 && indexValue != 0 : false) {
        this.selectedDate[data.id].unshift("Name:" + data.name);
      }
    }

    let html =  '';
    if (this.selectedDate[data.id]) {
      for (var i = 0; i < this.selectedDate[data.id].length; i++) {
        var split = this.selectedDate[data.id][i].split(':');
        this.fundsObj[split[0].trim()] = split[1].trim();
      }
    }

    if (Object.keys(this.selectedDate).length > 0) {
      html += '<dl class="pad-lAr-10px">';
      Object.keys(this.selectedDate).forEach(key => {
        this.selectedDate[key].forEach(element => {
          if (currencyFields.includes(element.split(":")[0])) {
            html += '<dt class="pull-left">' + element.split(":")[0] + ':</dt><dd style = "font-weight: 200;"  >&nbsp;' + OcInfraModule.AppInjector.get(CommonService).convertToCurrency(element.split(":")[1]) + '</dd>';
          } else {
            html += '<dt class="pull-left">' + element.split(":")[0] + ':</dt><dd style = "font-weight: 200;"  >&nbsp;' + element.split(":")[1] + '</dd>';
          }
        });
      });
      html += '</dl>';
    }
    //let storeData = localStorage.getItem('fundInfo');
    let selectedData = [];
    if (this.fundList.length > 0) {
      this.fundList.forEach(element => {
        if (this.selectedDate.hasOwnProperty(element.summary.id)) {
          let currentField = {};
          currentField[data.fund_type_desc] = this.selectedDate[element.summary.id];
          selectedData.push(currentField);
        }
      });
    }
    localStorage.setItem('fundInfo', JSON.stringify(this.selectedDate));
    OcInfraModule.AppInjector.get(CommonService).fundHtml = html;
    OcInfraModule.AppInjector.get(CommonService).showCurrentfundDetails();
    let finalHtml = OcInfraModule.AppInjector.get(CommonService).getFinalHtml();
    let requestedDetailSectionBilling = document.getElementsByClassName("requested_details_section_billing")[0];
    requestedDetailSectionBilling.innerHTML = finalHtml;
  }

  groupAnnuityCheckboxChange(event, data, selectedValue, selectedKey, columnName, valueType, flag) {
    let currencyFields = OcInfraModule.AppInjector.get(CommonService).selectdCurrencyFields;
    selectedValue = valueType == 'currency' ? '$' + selectedValue.replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,') : selectedValue;

    let selectedVal: any;
    const key =this.productType+'_'+this.accountType;

    if(!this.selectedDate || !this.selectedDate[key])
      this.selectedDate[key]={};
    
    let planCodeAssetAccountCheckData = this.selectedDate[key];

    if (planCodeAssetAccountCheckData[data.id]&& columnName !== 'name') {
      selectedVal = selectedValue ? selectedValue : 'No data in admin system';
      event.checked ?planCodeAssetAccountCheckData[data.id].push(selectedKey + ':' + selectedVal) : planCodeAssetAccountCheckData[data.id].splice(planCodeAssetAccountCheckData[data.id].indexOf(selectedKey + ':' + selectedVal), 1)
    } else {
      if (!planCodeAssetAccountCheckData[data.id]) {
        selectedVal = selectedValue ? selectedValue : 'No data in admin system';
        planCodeAssetAccountCheckData[data.id] = new Array(selectedKey + ':' + selectedVal);
      }
    }

    //Added a flag to check whether "Name" is checked or not.
    if   (planCodeAssetAccountCheckData[data.id] && columnName == 'name' && event.checked) {
      data.nameFlag = true;
    } else if ((planCodeAssetAccountCheckData[data.id] && columnName == 'name' && !event.checked)) {
      data.nameFlag = false;
    }

    // check to remove the object if only "Name" is present in it.
    if ((planCodeAssetAccountCheckData[data.id].length == 1 && planCodeAssetAccountCheckData[data.id][0].indexOf('Name') !== -1 && selectedKey == "Name" && !event.checked && flag == "true") || (planCodeAssetAccountCheckData[data.id].length == 1 && planCodeAssetAccountCheckData[data.id][0].indexOf('Name') !== -1 && selectedKey != "Name" && !event.checked && flag != "true" && data.nameFlag == false && flag != "true")){
      delete planCodeAssetAccountCheckData[data.id];
    }
    else {
      if (planCodeAssetAccountCheckData[data.id][0].indexOf('Name') === -1) {
        planCodeAssetAccountCheckData[data.id].unshift("Name:" + data.name);
      }
    }

    let html = '';
    if (planCodeAssetAccountCheckData[data.id]) {
      for (var i = 0; i < planCodeAssetAccountCheckData[data.id].length; i++) {
        var split = planCodeAssetAccountCheckData[data.id][i].split(':');
        this.fundsObj[split[0].trim()] = split[1].trim();//TODO
      }
    }

    let selectedData = [];
    if (this.fundList.length > 0) {
      this.fundList.forEach(element => {
        if (planCodeAssetAccountCheckData.hasOwnProperty(element.summary.id)) {
          let currentField = {};
          currentField[element.summary.id] = planCodeAssetAccountCheckData[element.summary.id];
          selectedData.push(currentField);
        }
      });
    }
    this.selectedDate[key]=planCodeAssetAccountCheckData;
    if(Object.entries(planCodeAssetAccountCheckData).length==0){
      delete this.selectedDate[key];
    }
    let currentSelectedState= {};
    if('fundInfo' in localStorage)
      currentSelectedState=JSON.parse( localStorage.getItem('fundInfo'));
    delete currentSelectedState[key]
    currentSelectedState[key] = selectedData;
    localStorage.setItem('fundInfo', JSON.stringify(currentSelectedState));

    for (const [planCodeAssetKey, selectedValues] of Object.entries(currentSelectedState)) {
      const keySplit=planCodeAssetKey.split("_")
      const tempKeys=Object.keys(selectedValues);
      if ( tempKeys && tempKeys.length> 0) {
        html += '<dl class="pad-lAr-10px"><dt class="pull-left"><span style="font-weight:600">&nbsp;Product:</span><span style="font-weight:400">&nbsp;'+keySplit[0] +'&nbsp;</span> <span style="font-weight:600">Asset Account:&nbsp;</span><span style="font-weight:400">' +keySplit[1] +"</span></dt><dd><dl>";
        tempKeys.forEach(tempKey => {
          const slecedtObj= selectedValues[tempKey];
          Object.keys(slecedtObj).forEach(key => {
            slecedtObj[key].forEach(element => {
              if (currencyFields.includes(element.split(":")[0])) {
                html += '<dt class="pull-left">' + element.split(":")[0] + ':</dt><dd style = "font-weight: 200;"  >&nbsp;' + OcInfraModule.AppInjector.get(CommonService).convertToCurrency(element.split(":")[1]) + '</dd>';
              } else {
                html += '<dt class="pull-left">' + element.split(":")[0] + ':</dt><dd style = "font-weight: 200;"  >&nbsp;' + element.split(":")[1] + '</dd>';
              }
            });
          });
        });
        html += '</dl></dd></dl>';
      }
      
    }
    OcInfraModule.AppInjector.get(CommonService).fundHtml = html;
    OcInfraModule.AppInjector.get(CommonService).showCurrentfundDetails();
    let finalHtml = OcInfraModule.AppInjector.get(CommonService).getFinalHtml();
    let requestedDetailSectionBilling = document.getElementsByClassName("requested_details_section_billing")[0];
    requestedDetailSectionBilling.innerHTML = finalHtml;
  }

  

  checkedMesseage(event, selectedValue, selectedKey) {
    let html = '';
    if (event.checked) {
      this.selectedDate["annuity"] = new Array(selectedKey + ':' + selectedValue);
      localStorage.setItem('fundInfo', JSON.stringify(this.selectedDate));
      html = '<dl class=""> <dt class="pull-left">' + selectedKey + ':</dt><dd style="font-weight: 200;">&nbsp;' + selectedValue + '</dd>' + '</dl>';
      OcInfraModule.AppInjector.get(CommonService).fundMessageHtml = html;
    } else {
      localStorage.removeItem('fundInfo');
      OcInfraModule.AppInjector.get(CommonService).fundMessageHtml = '';
    }
    OcInfraModule.AppInjector.get(CommonService).showCurrentfundDetails();
    OcInfraModule.AppInjector.get(CommonService).showRequestedDetails();
  }

}