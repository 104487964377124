import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { AppDateAdapter, APP_DATE_FORMATS } from '../../format-datepicker';
import { MatCheckboxChange } from '@angular/material/checkbox';
import * as quoteConstant from './quote-loan-initiation.constant';
import { OcInfraModule } from '@diaas/ux-web';
import { CommonService } from '../../../services/common.service';

@Component({
  selector: 'app-quote-loan-initiation',
  templateUrl: './quote-loan-initiation.component.html',
  styleUrls: ['./quote-loan-initiation.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  ]
})
export class QuoteLoanInitiationComponent implements OnInit {
  @Input() loanInitiationData: quoteConstant.LoanInitiation;
  quoteForm: FormGroup;
  currentDate: any = new Date();
  currentDateValue: any;
  quoteInfoFields!: quoteConstant.QuoteInitiationFormFieldModel[];
  quoteValueFields!: quoteConstant.QuoteInitiationFormFieldModel[];
  quoteInfo: quoteConstant.LoanInformationInitiation;
  quoteValue: quoteConstant.LoanValuationInitiation;
  allFormValues: quoteConstant.QuoteInitiationFormFieldModel[] = [];
  selectedFormValues = [];
  formattedInfoFields: any = [];
  formattedValueFields: any = [];
  requestedDetailsHtml: string;
  loanFields!: quoteConstant.QuoteInitiationFormFieldModel[];
  paidToDate: any;
  
  constructor(private formBuilder: FormBuilder, private datePipe: DatePipe, private currencyPipe: CurrencyPipe) { }

  ngOnInit() {
    this.quoteFormInit();
    this.quoteInfoFields = quoteConstant.initInfoFormFields();
    this.quoteValueFields = quoteConstant.initValueFormFields();
    this.prepareInfoFormControls();
    this.prepareValueFormControls();
    this.allFormValues = [...this.quoteInfoFields, ...this.quoteValueFields];
    this.initDefaultCheckedFields();
  }

  quoteFormInit() {
    this.quoteForm = this.formBuilder.group({
      effectiveDate: new FormControl({value: new Date(this.loanInitiationData.effective_date), disabled: true}),
      paidDate: new FormControl({ value: new Date(this.loanInitiationData.adjusted_paid_to_date), disabled: true}),
      effective_date: new FormControl(this.loanInitiationData.effective_date),
      adjusted_paid_to_date: new FormControl(
        this.loanInitiationData.adjusted_paid_to_date
      ),
    });
  }

  changeEvent($event: MatCheckboxChange, id: string) {
    const quoteData = this.allFormValues.filter((item) => item.id === id)[0];
    ($event.checked === true) ? this.selectedFormValues.push(quoteData) : this.selectedFormValues = this.selectedFormValues.filter(item => item.id !== id);
    this.showRequestedData();
  }

  initDefaultCheckedFields() {
    this.selectedFormValues = [...this.allFormValues.filter(element => element.checked)];
    const datePickerValues = <quoteConstant.QuoteInitiationFormFieldModel[]>quoteConstant.datePickerValues(); 
    datePickerValues[0].value = this.loanInitiationData.effective_date;
    datePickerValues[1].value = this.loanInitiationData.adjusted_paid_to_date;
    this.selectedFormValues = [...this.selectedFormValues, ...datePickerValues];
    this.showRequestedData();
  }

  showRequestedData() {
    let html = '';
    let requestedDetails = {};
    this.selectedFormValues.forEach((element) => {
      let value = element.value ? element.value: `No data in admin system`;
      requestedDetails[element.label] = value;
      html += `<dt class="pull-left">` + element.label + `:</dt><dd>&nbsp; ` + value  + `</dd>`
    });
    let quoteInitiationHeadHtml = (this.selectedFormValues.length > 0) ? `<span style="font-size:15px" class="pad-lAr-10px">LOAN INITIATION</span><dl class="pad-lAr-10px">` + html + `</dl>` : '';
    OcInfraModule.AppInjector.get(CommonService).quoteInitiationHtml = quoteInitiationHeadHtml;
    OcInfraModule.AppInjector.get(CommonService).showRequestedDetails();
    OcInfraModule.AppInjector.get(CommonService).quoteInitiation = { ['LOAN INITIATION QUOTE']: requestedDetails };
  }

  prepareInfoFormControls() {
    this.quoteInfoFields.map((formItem, index) => {
        formItem.value = this.loanInitiationData[formItem.id];

        'pipe' in formItem &&
        formItem.pipe == 'CURRENCY' &&
        (formItem.value = this.currencyPipe.transform(this.loanInitiationData[formItem.id], 'USD'));

        'pipe' in formItem&& formItem.pipe == 'PERCENTAGE' &&
        (formItem.value = this.loanInitiationData[formItem.id] + '%');

      this.quoteForm.addControl(formItem.id, this.formBuilder.control(this.loanInitiationData[formItem.id]));
    });
    for (var i = 0; i < this.quoteInfoFields.length; i += 3) {
      let part_slice = this.quoteInfoFields.slice(i, 3 + i);
      this.formattedInfoFields.push(part_slice);
    }
  }
  prepareValueFormControls() {
    this.quoteValueFields.map((formItem, index) => {
        formItem.value = this.loanInitiationData[formItem.id];

        'pipe' in formItem &&
        formItem.pipe == 'CURRENCY' &&
        (formItem.value = this.currencyPipe.transform(this.loanInitiationData[formItem.id], 'USD'));

        'pipe' in formItem&& formItem.pipe == 'PERCENTAGE' &&
        (formItem.value = this.loanInitiationData[formItem.id] + '%');

      this.quoteForm.addControl(formItem.id, this.formBuilder.control(this.loanInitiationData[formItem.id]));
    });
    for (var i = 0; i < this.quoteValueFields.length; i += 3) {
      let part_slice = this.quoteValueFields.slice(i, 3 + i);
      this.formattedValueFields.push(part_slice);
    }
  }
}
