import { Component, Input, Output, EventEmitter } from '@angular/core';
import { OcInfraModule, ResourceService } from '@diaas/ux-web';
@Component({
  selector: 'additional-policies',
  templateUrl: './additional-policies.component.html',
  styleUrls: ['additional-policies.component.scss'],
})

export class AdditionalPoliciesComponent {
  @Input() showHideAdditionalPolicyAssociate: boolean;
  @Input() showHideAdditionalPolicyBenefitQuote: boolean;
  @Output() setAssociatedPolicy: EventEmitter<AssociatedPolicy> = new EventEmitter<AssociatedPolicy>();
  isAnnuity: boolean;

  ngOnInit() {
    let details = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyDetails');
    this.isAnnuity = (details.data.product_type.includes('A') ? true : false);
  }
  
  onSearch() {
    this.setAssociatedPolicy.emit({
      showHideAdditionalPolicySearch: true
    });
  }

  onAssociate() {
    this.setAssociatedPolicy.emit({
      associateClicked: true
    });
  }

  onBenefitQuote() {
    this.setAssociatedPolicy.emit({
      benefitQuoteClicked: true
    });
  }
}
