import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ResourceService, OcInfraModule, APICallerService, Configuration } from '@diaas/ux-web';
import { CommonService } from '../../../services/common.service';
import { AdvGrowlService } from 'primeng-advanced-growl';
import { ToasterService } from 'angular2-toaster';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import * as _ from 'lodash';

export interface OneOfData {
    value: string,
    label: string
}

export type OneOf = {
    title: string
    enum: string[]
}
@Component({
  selector: 'app-dollar-cost-averaging',
  templateUrl: './dollar-cost-averaging.component.html',
  styleUrls: ['./dollar-cost-averaging.component.scss']
})
export class DollarCostAveragingComponent implements OnInit {

  dcaForm = new FormGroup({
    isChecked: new FormControl(false, [Validators.requiredTrue]),
    dateTime: new FormControl(null),
    effectiveDate: new FormControl(null, [Validators.required]),
    transferFrequency: new FormControl(null, [Validators.required]),
    noOfTransfers: new FormControl(null),
    beginDate: new FormControl(null, [Validators.required]),
    endDate: new FormControl(null),
    transferType: new FormControl(null, Validators.required),
    nextTransferDate: new FormControl(null),
    transferDay :new FormControl(null, [Validators.required]),
    accountType : new FormControl(null, [Validators.required]),
    productType : new FormControl(null),
  })
  isSubmit:boolean;
  isNextTransferDate: boolean = true;
  resourceService: any;
  policyDetails: any;
  getDcaURL: any;
  postDcaUrl: any;
  headers = { ...{ role: OcInfraModule.AppInjector.get(CommonService).authorizedRole, wma_user: window.sessionStorage.getItem('username') }, user_role: "csr", agent_code: OcInfraModule.AppInjector.get(CommonService).agentId, ...OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("search"), client_id: OcInfraModule.AppInjector.get(CommonService).productInfo.client };
  dcaDetails: any;
  authorizedRole: any;
  isPortfolioPlatform: boolean;
  showDateTime: boolean;
  fundsList: any;
  groupedFundsList: any;
  transferTypes: any[];
  transferDay: any;
  transferDays: any[];
  transferFrequencies: any[];
  costAverageTypes: any[];
  disableDcaTab: boolean;
  alertMessages: any;
  noOfTransfers: any;
  isReadonly: boolean = false;
  modifyDcaUrl: any;
  terminateDcaUrl: any;
  totalFromFunds: string;
  totalToFunds: string;
  isNextBusinessDay: boolean;
  confirmationOverride: boolean = false;
  alerts: any;
  isSuccess: boolean = false;
  payload: any;
  alertColor: string;
  disableCreate: boolean;
  selectedFunds: any = [];
  fundTransferType: any;
  costAverageType: any;
  transferFrequency: any;
  methodIndicator: string;
  disableNoOfTransfers: boolean = false;
  disableEndDate: boolean = false;
  tableSortStates: any;
  displayStockMaketDialog = true;
  productTypes: any[];
  accountType: string;
  assetAccountTypes: any[];
  accTypeItems: any;
  selectionChange: boolean = true;
  getPlanCodeUrl: any;
  getAssetAccountUrl: any;
  isGroupProduct: boolean = false;
  activeIndex: any;
  strategyTableReadonly: boolean = false;
  strategyDisableCreate: boolean;
  

  @Output() dcaValues: EventEmitter<any> = new EventEmitter<any>();
  @Output() dcaAlertsEvent = new EventEmitter<any>();
  @Output() scrollTop: EventEmitter<string> = new EventEmitter<string>();
  isFromfield: boolean;
  isTofield: boolean;
  isMRPS: boolean = false;
  dcaStrategy: OneOfData[] = [];
  strategydcaDetails: any;



  constructor(private toasterService: ToasterService, private datePipe: DatePipe) { }

  ngOnInit() {
    this.isNextTransferDate = true;
    this.authorizedRole = OcInfraModule.AppInjector.get(CommonService).authorizedRole;
    this.dcaForm.controls['isChecked'].setValue(!this.checkIfAgentOrAgentAssistant());
    this.resourceService = OcInfraModule.AppInjector.get(ResourceService);
    this.policyDetails = this.resourceService.getResourceFromAliasName('policyDetails') ? this.resourceService.getResourceFromAliasName('policyDetails').response : null;
    this.headers.planCode = this.policyDetails.plan_code;
    this.getDcaURL = this.policyDetails && this.policyDetails._links && this.policyDetails._links['/funds/costaveraging'] ? this.policyDetails._links['/funds/costaveraging'].href : null;
    
    this.disableDcaTab = this.getDcaURL == null ? true : false;
    this.policyDetails && this.policyDetails.product_type  == 'A2' && this.getDcaURL ? this.getDcaDetails() : '';
    
    if(this.policyDetails.product_type == 'A4') {
        this.isMRPS = this.policyDetails.client_id.length == 9 ? true : false;
    }
    if(this.policyDetails.product_type == 'A2') {
        this.isMRPS = this.policyDetails.policy_number.length == 9 ? true : false;
    }
    
    if (this.isMRPS) {
        this.dcaForm.addControl("dcaStrategy", new FormControl("", [Validators.required]));
    }

    if ((this.policyDetails.product_type  == 'A4' || this.policyDetails.product_type  == 'A3') && this.getDcaURL) {
     
      this.isGroupProduct =true;
      this.resourceService = OcInfraModule.AppInjector.get(ResourceService);
      this.policyDetails = this.resourceService.getResourceFromAliasName('policyDetails') ? this.resourceService.getResourceFromAliasName('policyDetails').response : null;
      this.getPlanCodeUrl = this.policyDetails && this.policyDetails._links && this.policyDetails._links.clientproducts ? this.policyDetails._links.clientproducts.href : null;
      this.getAssetAccountUrl = this.policyDetails && this.policyDetails._links && this.policyDetails._links.clientassetaccounts ? this.policyDetails._links.clientassetaccounts.href : null;
      
      if(OcInfraModule.AppInjector.get(CommonService).getClientProducts() ==null){
        OcInfraModule.AppInjector.get(CommonService).getCallback(this.getPlanCodeUrl, this.headers).subscribe(response => {
          this.processClientProducts(response._links.item);
        }, error => {
          if (error && error._embedded && error._embedded.status_report && error._embedded.status_report.consistent === false) {
            OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error._embedded.status_report.messages[0].message, 'Error', 0);
          }
        });
      }else{
        this.processClientProducts(OcInfraModule.AppInjector.get(CommonService).getClientProducts());
      }
      if(OcInfraModule.AppInjector.get(CommonService).getClientAssetAccountsAll() ==null ){
        OcInfraModule.AppInjector.get(CommonService).getCallback(this.getAssetAccountUrl, this.headers).subscribe(response => {
         
          this.accTypeItems = response._links.item;
         this.processClientAssetAccount(this.accTypeItems);
          OcInfraModule.AppInjector.get(CommonService).clientAssetAccountsAll = response._links.item;
        }, error => {
          if (error && error._embedded && error._embedded.status_report && error._embedded.status_report.consistent === false) {
            OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error._embedded.status_report.messages[0].message, 'Error', 0);
          }
        });
      }else{
        this.accTypeItems =  OcInfraModule.AppInjector.get(CommonService).getClientAssetAccountsAll();
        this.processClientAssetAccount(this.accTypeItems);
        if(!_.isNull(this.accTypeItems[0].summary.asset_account[0])){
          this.headers.assetAccount=this.accTypeItems[0].summary.asset_account[0];
        }
      }

        // COMMENTING FOR - PANOCSR-2590
        //   if(this.authorizedRole === 'Authorized Third Party' ||this.authorizedRole === 'Guardian'||this.authorizedRole === 'POA-Power of Attorney'||this.authorizedRole === 'Power of Attorney'){
        //     this.disableDcaTab = true;
        //   }else{
        //     this.disableDcaTab = false;
        //   }  
    }
    
    this.tableSortStates = [
      {
        "column": "number",
        "isAsc": false
      },
      {
        "column": "name",
        "isAsc": false
      },
      {
        "column": "fund_value",
        "isAsc": true
      }
    ]
  }

  processClientProducts(prodTypeItems){
    if (prodTypeItems) {
      this.productTypes = [];
      for (let i = 0; i < prodTypeItems.length; i++) {
        if(prodTypeItems[i].summary.product_type != "A3")
        this.productTypes.push({ value: prodTypeItems[i].summary.plan_code, viewValue: prodTypeItems[i].summary.plan_code });
      }
    }
  }

  
  processClientAssetAccount(assetAccountItems){
    if (assetAccountItems) {
      this.assetAccountTypes= [];
      if (this.accTypeItems) {
        this.assetAccountTypes = [];
        for (let i = 0; i < this.accTypeItems.length; i++) {
            if (this.accTypeItems[i].summary.asset_account_names) {
              for (let j = 0; j < this.accTypeItems[i].summary.asset_account_names.length; j++) {
                if(this.accTypeItems[i].summary.asset_account_names[j] !='ALL ASSET ACCOUNTS'){
                this.assetAccountTypes.push({ value: this.accTypeItems[i].summary.asset_account_names[j], viewValue: this.accTypeItems[i].summary.asset_account_names[j] });
              }
            }
          }
        }
      }
    }
  }

  getDcaDetails() {
    OcInfraModule.AppInjector.get(CommonService).getCallback(this.getDcaURL, this.headers).subscribe(response => {
      this.dcaDetails = response;
      let dcaResponseOptions = this.dcaDetails._options;
      for (let i = 0; i < dcaResponseOptions.links.length; i++) {
        if (dcaResponseOptions.links[i].method == 'POST') {
          this.postDcaUrl = dcaResponseOptions.links[i].href;
        }
        if (dcaResponseOptions.links[i].method == 'PATCH') {
          if (dcaResponseOptions.links[i].rel == 'update') {
            this.modifyDcaUrl = dcaResponseOptions.links[i].href;
          } else {
            this.terminateDcaUrl = dcaResponseOptions.links[i].href;
          }
        }
      }
      this.isReadonly = this.checkPortfolioPlatform(this.dcaDetails.funding_option) ? true : (this.postDcaUrl || this.modifyDcaUrl || this.terminateDcaUrl) ? false : true;

      if (this.dcaDetails._embedded && this.dcaDetails._embedded.status_report) {
        this.alertMessages = this.dcaDetails._embedded.status_report.messages;
          //disable DCA when the policy number is host contract
          if(this.dcaDetails.host_contract === "true"){
            this.isReadonly = true;
          } 
          else{
            this.isReadonly = false;
          }
          
        if (dcaResponseOptions.links.length 
          && dcaResponseOptions.links.find((item) => item.method == 'POST')) {
          this.disableDcaTab = false;
        } else {
          // Leaving the DisableTab logic as is (made it false for now) till we get to know if there is a scenario for which we disable a tab
          // this.dcaDetails.existing_segment === 'Y' ? this.isReadonly = true : this.disableDcaTab = true;
          if (this.dcaDetails.existing_segment === 'Y') {
            this.isReadonly =true;
          } else {
            this.disableDcaTab = false;
            this.isReadonly = true
          }
        }
        this.dcaAlertsEvent.emit({
          alertMessages: this.alertMessages,
          tabName: 'DOLLARCOSTAVERAGING',
          isDisabled: this.disableDcaTab     // OCCD-3622 emitting isDisabled for tab disabling functionality
        });
      }
      this.isPortfolioPlatform = this.checkPortfolioPlatform(this.dcaDetails.funding_option);
      this.showDateTime = this.checkIfAgentOrAgentAssistant() && this.dcaDetails && (this.dcaDetails.firm_code === 'DWI' || this.dcaDetails.firm_code === 'SBH');
      (this.policyDetails.product_type  == 'A4' && this.getDcaURL) ? this.dcaForm.controls['accountType'].setValidators(Validators.required) : this.dcaForm.controls['accountType'].setValidators(null);
      this.dcaForm.controls['accountType'].updateValueAndValidity();
      this.showDateTime ? this.dcaForm.controls['dateTime'].setValidators(Validators.required) : this.dcaForm.controls['dateTime'].setValidators(null);
      this.dcaForm.controls['noOfTransfers'].setValue(this.dcaDetails.number_of_transfers ? this.dcaDetails.number_of_transfers : null);
      this.dcaForm.controls['effectiveDate'].setValue(this.dcaDetails.effective_date ? moment(this.dcaDetails.effective_date) : null);
      this.dcaForm.controls['beginDate'].setValue(this.dcaDetails.begin_date ? moment(this.dcaDetails.begin_date) : null);
      this.dcaForm.controls['nextTransferDate'].setValue(this.dcaDetails.next_transfer_date ? this.dcaDetails.next_transfer_date : null)
      this.setValidatorsForFormField('beginDate');
      this.dcaForm.controls['endDate'].setValue(this.dcaDetails.end_date ? moment(this.dcaDetails.end_date) : null);
      if (this.dcaForm.controls['endDate'].value && this.dcaForm.controls['noOfTransfers'].value) {
        this.disableEndDate = false;
        this.disableNoOfTransfers = false;
      } else {
        this.dcaForm.controls['endDate'].value ? this.disableNoOfTransfers = true : null;
        this.dcaForm.controls['noOfTransfers'].value ? this.disableEndDate = true : null;
      }
      this.transferFrequencies = [];
      if (dcaResponseOptions && dcaResponseOptions.properties && dcaResponseOptions.properties.transfer_frequency.oneOf) {
        for (let i = 0; i < dcaResponseOptions.properties.transfer_frequency.oneOf.length; i++) {
          this.transferFrequencies.push({ value: dcaResponseOptions.properties.transfer_frequency.oneOf[i].title, viewValue: dcaResponseOptions.properties.transfer_frequency.oneOf[i].title });
        }
        if (this.dcaDetails.transfer_frequency) {
          const defaultTransferFrequency = dcaResponseOptions.properties.transfer_frequency.oneOf.find(opt => opt.enum.includes(this.dcaDetails.transfer_frequency.toString()));
          defaultTransferFrequency ? this.dcaForm.controls['transferFrequency'].setValue(defaultTransferFrequency.title) : this.dcaForm.controls['transferFrequency'].setValue('');
        }
        this.setValidatorsForFormField('transferFrequency');
      }
      this.transferTypes = [];
      if (dcaResponseOptions && dcaResponseOptions.properties && dcaResponseOptions.properties.transfer_type.oneOf) {
        for (let i = 0; i < dcaResponseOptions.properties.transfer_type.oneOf.length; i++) {
          this.transferTypes.push({ value: dcaResponseOptions.properties.transfer_type.oneOf[i].title, viewValue: dcaResponseOptions.properties.transfer_type.oneOf[i].title });
        }
        if (this.dcaDetails.transfer_type) {
          const defaultTransferType = dcaResponseOptions.properties.transfer_type.oneOf.find(opt => opt.enum.includes(this.dcaDetails.transfer_type[0]));
          defaultTransferType ? this.dcaForm.controls['transferType'].setValue(defaultTransferType.title) : this.dcaForm.controls['transferType'].setValue('');
        }
        this.setValidatorsForFormField('transferType');
      }

      this.transferDays = [];
      if (dcaResponseOptions && dcaResponseOptions.properties && dcaResponseOptions.properties.transfer_day.oneOf) {
        for (let i = 0; i < dcaResponseOptions.properties.transfer_day.oneOf.length; i++) {
          this.transferDays.push({ value: dcaResponseOptions.properties.transfer_day.oneOf[i].title, viewValue: dcaResponseOptions.properties.transfer_day.oneOf[i].title });
        }
        if (this.dcaDetails.transfer_day) {
          const defaultTransferDay = dcaResponseOptions.properties.transfer_day.oneOf.find(opt => opt.enum.includes( this.dcaDetails.transfer_day.toString()));
          defaultTransferDay ? this.dcaForm.controls['transferDay'].setValue(defaultTransferDay.title) : this.dcaForm.controls['transferDay'].setValue('');
        }
        this.setValidatorsForFormField('transferDay');
      }

      if(this.isMRPS) {
        this.loadDcaStrategy();
      }

      this.setDefaultFunds();
      this.sortTable('fund_value');
      this.dcaForm.controls['transferType'].setValue("Percent", {onlySelf: true});
      this.dcaForm.controls['transferDay'].setValue("01", {onlySelf: true});
      this.dcaForm.controls['transferFrequency'].setValue("Annual", {onlySelf: true});
      this.isMRPS?this.dcaForm.controls['dcaStrategy'].setValue(" ", {onlySelf: true}):null;
    }, error => {
      OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
      if (error && error._embedded && error._embedded.status_report && error._embedded.status_report.consistent === false) {
        OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error._embedded.status_report.messages[0].message, 'Error', 0);
      }
    });
  }

  loadDcaStrategy = () => {
    let dcaStrategy = this.getNested(this.dcaDetails._options.properties, 'cost_average_type');
    if (dcaStrategy && dcaStrategy.oneOf.length > 0) {
        this.dcaStrategy = dcaStrategy.oneOf.map((ele: OneOf): OneOfData => {
            return <OneOfData>{ value: ele.enum[0], label: ele.title };
        });
    }
}

getNested(obj, ...args) {
    return args.reduce((obj, level) => obj && obj[level], obj)
}

  setValidatorsForFormField(field) {
    if (!this.isReadonly) {
      this.dcaForm.controls[field].setValidators(Validators.required);
    } else {
      this.dcaForm.controls[field].setValidators(null);
    }
    this.dcaForm.controls[field].updateValueAndValidity();
  }

  // Checks if Funding option on contract is Portfolio Platform
  checkPortfolioPlatform(fundingOption) {
    return fundingOption === 'PP' || fundingOption === 'PF' || fundingOption === 'LP' ? true : false;
  }

  // Checks if The user is authorized as Agent/Agent Assistant
  checkIfAgentOrAgentAssistant() {
    return this.authorizedRole === 'Agent' || this.authorizedRole === 'Agent Assistant' || this.authorizedRole === 'BOA Back Office Assistant (Advisor Assistant)'? true : false;
  }

  // Handles Transfer Type change
  onTransferTypeChange(e) {
    this.setDefaultFunds();
    //this.enableSubmit();
    this.disableCreate = true;
    this.isFromfield = false;
    this.isTofield =  false;
  }

  onTransferDayChange(e) {
    this.setDefaultFunds();
    this.enableSubmit();
  }

  setDefaultFunds() {
    // Default to funds empty
    if (this.isPortfolioPlatform) {
      if (this.dcaDetails.groupedFunds.length > 0) {
        this.dcaDetails.groupedFunds.forEach((groupedFund, index) => {
          if (groupedFund.funds.length > 0) {
            groupedFund.funds.forEach((fund) => {
              fund.toAmount = '';
              fund.toPercent = '';
              fund.fromUnits = '';
              fund.fromAmount = '';
              fund.fromPercent = '';
              fund.isChanged = false;
            });
            if (this.dcaForm.value.transferType === 'Amount to Percent' || this.dcaForm.value.transferType === 'Dollar Transfer') {
              groupedFund.totalFromFunds = '$0.00';
            }
            if (!this.dcaForm.value.transferType || this.dcaForm.value.transferType === 'Percent') {
              groupedFund.totalFromFunds = '0.0%'
            }
            if(!this.dcaForm.value.transferType || this.dcaForm.value.transferType === 'Units') {
              groupedFund.totalFromFunds = '0.00000'
            }

            if (this.dcaForm.value.transferType === 'Amount to Percent' || this.dcaForm.value.transferType === 'Dollar Transfer') {
              groupedFund.totalToFunds = '$0.00';
            }
            if (!this.dcaForm.value.transferType || this.dcaForm.value.transferType === 'Amount to Percent' || this.dcaForm.value.transferType === 'Percent' || this.dcaForm.value.transferType === 'Units') {
              groupedFund.totalToFunds = '0.0%';
            }
          }
        });
      }
    } else {
      if (this.dcaDetails.funds.length > 0) {
        this.dcaDetails.existing_segment == 'Y' ? this.getFundValues() : '';
        this.dcaDetails.funds.forEach((fund) => {
          if (this.dcaDetails.existing_segment == 'Y') {
            fund.toAmountHasValue = fund.toAmount ? this.validatefundValue(fund.toAmount) : false;
            fund.fromAmountHasValue = fund.fromAmount ? this.validatefundValue(fund.fromAmount) : false;
            fund.fromUnitHasValue = fund.fromUnits ? this.validatefundValue(fund.fromUnits) : false;
            fund.toPercentHasValue = fund.toPercent ? this.validatefundValue(fund.toPercent) : false;
            fund.fromPercentHasValue = fund.fromPercent ? this.validatefundValue(fund.fromPercent) : false;
          } else {
            fund.toAmount = '';
            fund.toPercent = '';
            fund.fromAmount = '';
            fund.fromPercent = '';
            fund.fromUnits = '';
            fund.amount_unit_indicator = '';
            fund.isChanged = false;
            fund.fromUnitHasValue = false;
            fund.toAmountHasValue = false;
            fund.fromAmountHasValue = false;
            fund.toPercentHasValue = false;
            fund.fromPercentHasValue = false;
          }
        });
        if (this.dcaForm.value.transferType === 'Amount to Percent' || this.dcaForm.value.transferType === 'Dollar Transfer') {
          this.totalFromFunds = this.getTotalofAmount(this.dcaDetails.funds, 'fromAmount');
        }
        if (!this.dcaForm.value.transferType || this.dcaForm.value.transferType === 'Units') {
          this.totalFromFunds = this.getTotalofunits(this.dcaDetails.funds, 'fromUnits');
        }
        if (!this.dcaForm.value.transferType || this.dcaForm.value.transferType === 'Percent') {
          this.totalFromFunds = this.getTotalofPercent(this.dcaDetails.funds, 'fromPercent');
        }
        if (this.dcaForm.value.transferType === 'Amount to Percent' || this.dcaForm.value.transferType === 'Dollar Transfer') {
          this.totalToFunds = this.getTotalofAmount(this.dcaDetails.funds, 'toAmount');
        }
        if (!this.dcaForm.value.transferType || this.dcaForm.value.transferType === 'Amount to Percent' || this.dcaForm.value.transferType === 'Percent' || this.dcaForm.value.transferType === 'Units') {
          this.totalToFunds = this.getTotalofPercent(this.dcaDetails.funds, 'toPercent');
        }
      }
    }
  }

  onProductTypeChange(e) {
    this.accountType = '';
    this.selectionChange = true;
    if (this.accTypeItems) {
      this.assetAccountTypes = [];
      for (let i = 0; i < this.accTypeItems.length; i++) {
        if (this.accTypeItems[i].summary.plan_code === e.value) {
          if (this.accTypeItems[i].summary.asset_account_names) {
            for (let j = 0; j < this.accTypeItems[i].summary.asset_account_names.length; j++) {
              if(this.accTypeItems[i].summary.asset_account_names[j] !='ALL ASSET ACCOUNTS'){
              this.assetAccountTypes.push({ value: this.accTypeItems[i].summary.asset_account_names[j], viewValue: this.accTypeItems[i].summary.asset_account_names[j] });
            }
           }
          }
        }
      }
    }
  }
  

  onAssetAccountChange(e) {
    this.dcaForm.controls['transferFrequency'].reset();
    this.dcaForm.controls['transferDay'].reset();
    this.dcaForm.controls['transferType'].reset();
    let selectedAssetAccount;
    let drpdwnObj;
    this.selectionChange = false;
    this.accountType = this.dcaForm.controls['accountType'].value;
    selectedAssetAccount = this.accountType.substring(0, this.accountType.indexOf(' '));
    drpdwnObj = { 'planCode': this.dcaForm.controls['productType'].value, 'assetAccount': selectedAssetAccount, 'accountType': this.accountType};
    this.headers.product =drpdwnObj.planCode; 
    this.headers.assetAccount = selectedAssetAccount;
    this.activeIndex = 1;
    this.getDcaDetails();
  }

  getFundValues() {
    this.dcaDetails.funds.forEach((fund) => {
      if (fund.amount_unit_indicator == 'P') {
        fund.from_to_indicator == 'T' ? fund.toPercent = fund.percent ? fund.percent : '0.0' : fund.fromPercent = fund.percent ? fund.percent : '0.0';
      } else if (fund.amount_unit_indicator == 'A') {
        fund.from_to_indicator == 'T' ? fund.toAmount = fund.amount ? fund.amount : '0.00' : fund.fromAmount = fund.amount ? fund.amount : '0.00';
      } else if (fund.amount_unit_indicator == 'U') {
        fund.from_to_indicator == 'T' ? fund.toPercent = fund.percent ? fund.percent : '0.0' : fund.fromUnits = fund.units ? fund.units : '0.00000';
      }else if (fund.amount_unit_indicator == 'B') {
        fund.from_to_indicator == 'T' ? fund.toPercent = fund.percent ? fund.percent : '0.0' : fund.fromAmount = fund.amount ? fund.amount : '0.00';
      }
    });
  }

  // Calculates total percent
  getTotalofPercent(fundsList, propertyName) {
    let total = 0.0;
    for (let i = 0; i < fundsList.length; i++) {
      if (fundsList[i][propertyName]) {
        total += Number(fundsList[i][propertyName].replace(/[^0-9.-]+/g, ''));
      }
    }
    return this.formatPercent('', 'blur', total.toString());
  }

  //calculates total units
  getTotalofunits(fundsList, propertyName) {
    let total = 0.00000;
    for (let i = 0; i < fundsList.length; i++) {
      if (fundsList[i][propertyName]) {
        total += Number(fundsList[i][propertyName].replace(/[^0-9.-]+/g, ''));
      }
    }
    return this.formatUnits('', 'blur', total.toString());
  }

  // Calculates total amount
  getTotalofAmount(fundsList, propertyName) {
    let total = 0.0;
    for (let i = 0; i < fundsList.length; i++) {
      if (fundsList[i][propertyName]) {
        total += Number(fundsList[i][propertyName].replace(/[^0-9.-]+/g, ''));
      }
    }
    return this.formatCurrency('', 'blur', total.toString());
  }

  // handles input change
  inputChange(input, i, j, propertyName, formatType, indicator,val) {
    let input_val = input.target.value;
    this.dcaDetails.funds[i][propertyName] = input_val;
    if (indicator === 'T') {
      if (formatType == 'percent') {
        this.dcaDetails.funds[i].toPercentHasValue = this.validatefundValue(input_val);
      } else {
        this.dcaDetails.funds[i].toAmountHasValue = this.validatefundValue(input_val);
      }
      this.totalToFunds = this.dcaDetails.funds ? formatType === 'percent' ? this.getTotalofPercent(this.dcaDetails.funds, propertyName) :
        this.getTotalofAmount(this.dcaDetails.funds, propertyName) : '0.0';
    } else if(indicator === 'F')  {
      if (formatType == 'percent') {
        this.dcaDetails.funds[i].fromPercentHasValue = this.validatefundValue(input_val);
      } else {
        this.dcaDetails.funds[i].fromAmountHasValue = this.validatefundValue(input_val);
      }
      this.totalFromFunds = this.dcaDetails.funds ? formatType === 'percent' ? this.getTotalofPercent(this.dcaDetails.funds, propertyName) :
        this.getTotalofAmount(this.dcaDetails.funds, propertyName) : '0.0';
    } else if(indicator === 'U') {
      if (formatType == 'units') {
        this.dcaDetails.funds[i].fromUnitHasValue = this.validatefundValue(input_val);
      } 
      this.totalFromFunds = this.dcaDetails.funds ? formatType === 'units' ? this.getTotalofunits(this.dcaDetails.funds, propertyName) :
        this.getTotalofunits(this.dcaDetails.funds, propertyName) : '0.00000';
    }
    if((val.fromAmount !=="" || val.fromPercent !=="" || val.fromUnits!=="") && (indicator == "F" || indicator =="U")){
      this.isFromfield = true;
    } else if((val.fromAmount ==="" || val.fromPercent==="" || val.fromUnits==="") && (indicator == "F" || indicator =="U")) {
      this.isFromfield = false;
      this.disableCreate = true;
    }

    if((val.toAmount !=="" || val.toPercent !=="") && indicator == "T"){
      this.isTofield = true;
    } 
    else if((val.toAmount ==="" || val.toPercent==="") && indicator == "T") {
      this.isTofield = false;
      this.disableCreate = true;
    }
      if(this.isTofield === true && this.isFromfield ===true){
        this.enableSubmit();
        this.isSubmit=true;
        this.strategyDisableCreate = true; 
      }
  }

  /** AMOUNT AND PERCENT FORMATING  */
  formatNumber(n, type, flag) {
    if (flag) {
      n = n.charAt(0) == '0' ? n.replace(n.charAt(0), "") : n;
    }
    if (type == 'currency') {
      return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return n.replace(/\D/g, "");
    }
  }

  //formats the value with commas and $ allowing 2 decimal values
  formatCurrency(input, blur, respValue, fund = null, indicator = null, formatType = null) {
    var input_val = "";
    if (respValue == '' || respValue == undefined) {
      input_val = input.target.value;
    } else {
      input_val = respValue;
    }
    if (input_val === "") {
      this.setFundValue(fund, input_val, indicator, 'amount');
      return;
    }
    var original_len = input_val.length;
    if (input_val.indexOf(".") >= 0) {
      var decimal_pos = input_val.indexOf(".");
      decimal_pos = decimal_pos > 15 ? 15 : decimal_pos;
      var left_side = input_val.substring(0, decimal_pos);
      var right_side = input_val.substring(decimal_pos);
      left_side = this.formatNumber(left_side, 'currency', true);
      right_side = this.formatNumber(right_side, 'currency', false);
      if (blur === "blur") {
        right_side += "00";
      }
      // Limit decimal to only 2 digits
      right_side = right_side.substring(0, 2);
      // join number by .
      input_val = "$" + left_side + "." + right_side;

    } else {
      // no decimal entered
      // add commas to number
      // remove all non-digits
      input_val = input_val.substring(0, 15);
      input_val = this.formatNumber(input_val, 'currency', true);
      input_val = "$" + input_val;
      // final formatting
      if (blur === "blur") {
        input_val += ".00";
      }
    }
    if (input_val.charAt(input_val.indexOf('$') + 1) == '.') {
      input_val = input_val.replace(input_val.charAt(input_val.indexOf('$') + 1), '0.');
    }
    if (respValue == '' || respValue == undefined) {
      input.target["value"] = input_val;
    } else {
      return input_val;
    }

    if (fund) {
      this.setFundValue(fund, input_val, indicator, 'amount');
    }
  }
 

  //To format Units
  formatUnits(input, blur, respValue, fund = null, indicator = null, formatType = null) {
    var input_val = "";
    if (respValue == '' || respValue == undefined) {
      input_val = input.target.value;
    } else {
      input_val = respValue;
    }
    if (input_val === "") {
      this.setFundValue(fund, input_val, indicator, 'units');
      return;
    }
    var original_len = input_val.length;
    if (input_val.indexOf(".") >= 0) {
      var decimal_pos = input_val.indexOf(".");
      decimal_pos = decimal_pos > 15 ? 15 : decimal_pos;
      var left_side = input_val.substring(0, decimal_pos);
      var right_side = input_val.substring(decimal_pos);
      left_side = this.formatNumber(left_side, 'units', true);
      right_side = this.formatNumber(right_side, 'units', false);
      if (blur === "blur") {
        right_side += "00000";
      }
      // Limit decimal to only 5 digits
      right_side = right_side.substring(0, 5);
      // join number by .
      input_val = left_side + "." + right_side;

    } else {
      // no decimal entered
      // add commas to number
      // remove all non-digits
      input_val = input_val.substring(0, 15);
      input_val = this.formatNumber(input_val, 'units', true);
      input_val = input_val;
      // final formatting
      if (blur === "blur") {
        input_val += ".00000";
      }
    }
    if (input_val.charAt(input_val.indexOf('$') + 1) == '.') {
      input_val = input_val.replace(input_val.charAt(input_val.indexOf('$') + 1), '0.');
    }
    if (respValue == '' || respValue == undefined) {
      input.target["value"] = input_val;
    } else {
      return input_val;
    }

    if (fund) {
      this.setFundValue(fund, input_val, indicator, 'units');
    }
  }
  // formats the value with commas and percentage allowing 1 decimal value
  formatPercent(input, blur, respValue, fund = null, indicator = null, formatType = null) {
    var input_val = "";
    if (respValue == '' || respValue == undefined) {
      input_val = input.target.value;
    } else {
      input_val = respValue;
    } if (input_val === "") {
      this.setFundValue(fund, input_val, indicator, 'percent');
      return;
    }
    var original_len = input_val.length;
    if (input_val.indexOf(".") >= 0) {
      var decimal_pos = input_val.indexOf(".");
      var left_side = input_val.substring(0, decimal_pos);
      var right_side = input_val.substring(decimal_pos);
      left_side = this.formatNumber(left_side, 'percent', true);
      right_side = this.formatNumber(right_side, 'percent', false);
      if (blur === "blur") {
        right_side += "0";
      }
      // Limit decimal to only 2 digits
      right_side = right_side.substring(0, 1);
      // join number by .
      input_val = left_side + "." + right_side + "%";
    } else {
      // no decimal entered
      // add commas to number
      // remove all non-digits
      input_val = this.formatNumber(input_val, 'percent', true);
      if (blur === "blur") {
        input_val += ".0";
      }
      input_val = input_val + "%";
    }
    if (input_val.charAt(input_val.indexOf('$') + 1) == '.') {
      input_val = input_val.replace(input_val.charAt(input_val.indexOf('$') + 1), "0.")
    }
    if (respValue == '' || respValue == undefined) {
      input.target["value"] = input_val;
    } else {
      return input_val;
    }

    if (fund) {
      this.setFundValue(fund, input_val, indicator, 'percent');
    }
  }

  handleValueChange(input, type) {
    var input_val = "";
    if (type === 'endDate') {
      input_val = input.target.value;
      if (input_val) {
        this.disableNoOfTransfers = true;
        this.dcaForm.addControl("noOfTransfers", new FormControl(false, Validators.requiredTrue));
      } else {
        if (this.dcaForm.controls['noOfTransfers'].value) {
          this.disableEndDate = true;
          this.disableNoOfTransfers = false;
          this.dcaForm.addControl("endDate", new FormControl(false, Validators.requiredTrue));
        } else {
          this.disableEndDate = false;
          this.disableNoOfTransfers = false;
        }
      }
    } else {
      input_val = input;
      if (input_val) {
        this.disableEndDate = true;
        this.dcaForm.addControl("endDate", new FormControl(false, Validators.requiredTrue));
      } else {
        if (this.dcaForm.controls['endDate'].value) {
          this.disableNoOfTransfers = true;
          this.disableEndDate = false;
          this.dcaForm.addControl("noOfTransfers", new FormControl(false, Validators.requiredTrue));
        } else {
          this.disableEndDate = false;
          this.disableNoOfTransfers = false;
        }
      }
    }
    this.enableSubmit();
  }

  setFundValue(fund, value, indicator, formatType) {
    if (formatType === 'amount') {
      if (indicator === 'F') {
        fund.fromAmount = value;
        fund.toAmount = '';
        fund.toPercent = '';
      } else {
        fund.toAmount = value;
        fund.fromAmount = '';
        fund.fromPercent = '';
      }
    } else if (formatType === 'percent'){
      if (indicator === 'F') {
        fund.fromPercent = value;
        fund.toPercent = '';
        fund.toAmount = '';
      } else {
        fund.toPercent = value;
        fund.fromAmount = '';
        fund.fromPercent = '';
      }
    } else if(formatType === 'units'){
      if (indicator === 'F'){
      fund.fromUnits = value;
      fund.toPercent = '';
      } else {
        fund.fromUnits = '';
        fund.toPercent = value;
      }
    }
    
    fund.from_to_indicator = indicator;
    fund.isChanged = value && value !== '';
  }

  enableSubmit() {
    if (!this.isSuccess) {
      this.disableCreate = false;
    }
  }

  // Handles Submit
  onSubmit(e) {
    this.displayStockMaketDialog = (e !== false);
    this.scrollTop.emit('scroll');
    this.checkIsBusinessClosed();
  }

  checkIsBusinessClosed() {
    let curr = moment(new Date()).tz('America/New_York');
    if (curr.hours() >= this.dcaDetails.business_closehour && this.displayStockMaketDialog) {
      this.displayStockMaketDialog = false;
      let content = 'The stock market is closed and the transaction will be completed on next business day. Do you want to proceed ?';
      let title = 'Alert';
      let dialogRef = OcInfraModule.AppInjector.get(CommonService).showConfirmationPopup(content, 'No', 'Yes', title, '340px');
      dialogRef.afterClosed().subscribe(res => {
        if (res) {
          this.isNextBusinessDay = true; // isNextBusinessDay is to check if the user clicked yes
          this.processDCA()
        }
      });
    } else {
      this.processDCA()
    }
  }

  processDCA() {
    OcInfraModule.AppInjector.get(AdvGrowlService).clearMessages();
    this.createPayload();
    let isPost = this.dcaDetails && this.dcaDetails._options.links.findIndex((link) => link.method.toLowerCase() === 'post') > -1
    if (isPost) {
      this.methodIndicator = 'post';
      OcInfraModule.AppInjector.get(CommonService).postCallback(this.postDcaUrl, this.payload, this.headers).subscribe(response => {
        this.processDcaResponse(response, 'POST', false);
      }, error => {
        OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error._embedded && error._embedded.status_report && error._embedded.status_report.messages[0].message, 'Error', 0);
      });
    } else if (this.dcaDetails.existing_segment == 'Y') {
      let beginDate = this.dcaForm.controls['beginDate'].value;
      let endDate = this.dcaForm.controls['endDate'].value;
      if (endDate - beginDate === 0) {
        this.methodIndicator = 'terminate';
        OcInfraModule.AppInjector.get(CommonService).patchCallback(this.terminateDcaUrl, this.payload, this.headers).subscribe(response => {
          this.processDcaResponse(response, 'PATCH', true);
        }, error => {
          OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error._embedded && error._embedded.status_report && error._embedded.status_report.messages[0].message, 'Error', 0);
        });
      } else {
        this.methodIndicator = 'update';
        OcInfraModule.AppInjector.get(CommonService).patchCallback(this.modifyDcaUrl, this.payload, this.headers).subscribe(response => {
          this.processDcaResponse(response, 'PATCH', false);
        }, error => {
          OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error._embedded && error._embedded.status_report && error._embedded.status_report.messages[0].message, 'Error', 0);
        });
      }
    }
  }

  processDcaResponse(response, httpVerb, isTerminate) {
    if(response.confirm_override === undefined && response.code == "200"){
      response.confirm_override = '';
    }  
    switch (response.confirm_override) {
      // soft error scenario
      case 'Y': {
        this.displayAlerts(response);
        this.scrollTop.emit('scroll');
        let content = 'Are you sure?  You want to override ?';
        let title = 'Alert';
        let dialogRef = OcInfraModule.AppInjector.get(CommonService).showConfirmationPopup(content, 'No', 'Yes', title, '340px');
        dialogRef.afterClosed().subscribe(res => {
          if (res) {
            this.payload.confirm_override = "Y"
            this.payload.next_business_day = response.next_business_day;
            this.payload.effective_date = response.effective_date;
            this.confirmationOverride = true;
            this.onSubmit(false);
            this.alerts = [];
            this.alertColor = 'orange';
          }
        });
        break;
      }
      // hard error scenario
      case 'N': {
        this.displayAlerts(response);
        this.alertColor = 'red';
        this.disableCreate = true;
        break;
      }
      // success scenario
      case '': {
        this.disableCreate = true;
        this.alerts = [];
        let message = isTerminate ? 'Success Terminate DCA Processed Successfully' :
          (httpVerb === 'POST' ? 'Success Addition of DCA Processed Successfully' : 'Success Modify DCA Processed Successfully');
        OcInfraModule.AppInjector.get(AdvGrowlService).createSuccessMessage(message, 'Success');
        this.isSuccess = true;
        this.dcaValues.emit({
          effectiveDate: this.datePipe.transform(this.dcaForm.controls['effectiveDate'].value, "MM/dd/yyyy"),
          transferFrequency: this.dcaForm.controls['transferFrequency'].value,
          noOfTransfers: this.dcaForm.controls['noOfTransfers'].value,
          beginDate: this.datePipe.transform(this.dcaForm.controls['beginDate'].value, "MM/dd/yyyy"),
          nextTransferDate: this.dcaDetails.next_transfer_date ? this.dcaDetails.next_transfer_date : null,
          endDate: this.datePipe.transform(this.dcaForm.controls['endDate'].value, "MM/dd/yyyy"),
          transferType: this.dcaForm.controls['transferType'].value,
          transferDay: this.dcaForm.controls['transferDay'].value,
          fundsData: this.dcaDetails.funds ? this.dcaDetails.funds : null,
          selectedFunds: this.selectedFunds,
          dateTime: this.dcaForm.controls['dateTime'].value,
          totalFromFunds: this.totalFromFunds,
          totalToFunds: this.totalToFunds
        });
        break;
      }
    }
    // this.setDefaultFunds();
  }

  createPayload() {
    let header = _.filter(Configuration.config.headers["multiHostUrlHeader"], function (o) { return o.alias == 'workRequestHistory' });
    const authResponse = this.resourceService.getResourceFromAliasName('authRes');
    let policyDetails = this.resourceService.getResourceFromAliasName('policyDetails');
    Object.assign(this.headers, { product_type: policyDetails.data.product_type});
    const selectedRoleSummary = this.resourceService.getResourceFromAliasName('selectedRoles');
    let roleList = this.resourceService.getResourceFromAliasName('roles');
    let otherRoleAuthorize = _.isEmpty(selectedRoleSummary.data) ? _.filter(roleList._links.item, function (o) { return o.summary.role == 'Owner'; }) : false;
    let effectiveDate = this.dcaForm.controls['effectiveDate'].value;
    let beginDate = this.dcaForm.controls['beginDate'].value;
    let endDate = new Date(this.dcaForm.controls['endDate'].value);
    let receivedEndDate = new Date(this.dcaDetails.end_date);
    let cost_average_type = this.isMRPS ? this.dcaForm.controls.dcaStrategy.value : 'CA';

    this.payload = {
      "next_business_day": this.isNextBusinessDay === false ? '' : 'Y',
      "effective_date": this.datePipe.transform(effectiveDate, "MM/dd/yyyy"),
      "begin_date": this.datePipe.transform(beginDate, "MM/dd/yyyy"),
      "confirm_override": this.confirmationOverride === true ? 'Y' : '',
      //"next_transfer_date": this.dcaDetails.next_transfer_date,
      //"type": "DOLLAR COST AVERAGING",
      "cost_average_type": cost_average_type,
      //"hostKey": otherRoleAuthorize ? otherRoleAuthorize[0] && otherRoleAuthorize[0].summary.hostKey ? otherRoleAuthorize[0].summary.hostKey : '' : selectedRoleSummary && selectedRoleSummary.data.hostKey ? selectedRoleSummary.data.hostKey : '',
      "sessionId": authResponse && authResponse.data.contactSessionID ? authResponse.data.contactSessionID : '',
      "callStart": authResponse && authResponse.data.callStart ? authResponse.data.callStart : '',
      "clientId": otherRoleAuthorize ? otherRoleAuthorize[0] && otherRoleAuthorize[0].summary.clientId ? otherRoleAuthorize[0].summary.clientId : '' : selectedRoleSummary && selectedRoleSummary.data.clientId ? selectedRoleSummary.data.clientId : '',
      "userId": OcInfraModule.AppInjector.get(CommonService).incontactId,
      "policyNumber": policyDetails.data.policy_number,
      "company_code": policyDetails.data.company_code,
      "region_code": header[0].headers.region_code,
      "remote_user": header[0].headers.remote_user,
      "origin_app": "csr_portal",
      "incontactId": authResponse && authResponse.data.contactId ? authResponse.data.contactId : ''      
    };

    (endDate && !(endDate.getTime() === receivedEndDate.getTime())) ? this.payload["end_date"] = this.datePipe.transform(this.dcaForm.controls['endDate'].value, "MM/dd/yyyy") : null;
    (this.dcaForm.controls['noOfTransfers'].value && (this.dcaForm.controls['noOfTransfers'].value != this.dcaDetails.number_of_transfers)) ? this.payload["number_of_transfers"] = this.dcaForm.controls['noOfTransfers'].value : null;
    if (this.dcaDetails._options.properties.transfer_type && this.dcaDetails._options.properties.transfer_type.oneOf) {
      for (let i = 0; i < this.dcaDetails._options.properties.transfer_type.oneOf.length; i++) {
        if (this.dcaDetails._options.properties.transfer_type.oneOf[i].title == this.dcaForm.controls['transferType'].value) {
          this.fundTransferType = this.dcaDetails._options.properties.transfer_type.oneOf[i].enum[0];
        }
      }
      //this.payload["transfer_type"] = this.fundTransferType;
    }
    if (this.dcaDetails._options.properties.transfer_frequency && this.dcaDetails._options.properties.transfer_frequency.oneOf) {
      for (let i = 0; i < this.dcaDetails._options.properties.transfer_frequency.oneOf.length; i++) {
        if (this.dcaDetails._options.properties.transfer_frequency.oneOf[i].title == this.dcaForm.controls['transferFrequency'].value) {
          this.transferFrequency = this.dcaDetails._options.properties.transfer_frequency.oneOf[i].enum[0];
        }
      }
      this.payload["transfer_frequency"] = this.transferFrequency;
    }

    if (this.dcaDetails._options.properties.transfer_day && this.dcaDetails._options.properties.transfer_day.oneOf) {
      for (let i = 0; i < this.dcaDetails._options.properties.transfer_day.oneOf.length; i++) {
        if (this.dcaDetails._options.properties.transfer_day.oneOf[i].title == this.dcaForm.controls['transferDay'].value) {
          this.transferDay = this.dcaDetails._options.properties.transfer_day.oneOf[i].enum[0];
        }
      }
      this.payload["transfer_day"] = this.transferDay;
    }

    this.getFilteredFunds();

    this.payload["funds"] = this.selectedFunds;
  }


  getFilteredFunds() {
    const filteredFunds = this.dcaDetails.funds.filter((item) =>
      (item.fromAmount ? this.validatefundValue(item.fromAmount) : false || item.toAmount ? this.validatefundValue(item.toAmount) : false || item.fromPercent ? this.validatefundValue(item.fromPercent) : false || item.toPercent ? this.validatefundValue(item.toPercent) : false || item.fromUnits ? this.validatefundValue(item.fromUnits) : false));
    filteredFunds.forEach((fund,i)=> {
      fund.amount = (fund.toAmount && this.validatefundValue(fund.toAmount)) ? fund.toAmount.replace(/[^0-9.-]+/g, '') : (fund.fromAmount && this.validatefundValue(fund.fromAmount)) ? fund.fromAmount.replace(/[^0-9.-]+/g, '') : '0.00';
      fund.percent = (fund.toPercent && this.validatefundValue(fund.toPercent)) ? fund.toPercent.replace(/[^0-9.-]+/g, '') : (fund.fromPercent && this.validatefundValue(fund.fromPercent)) ? fund.fromPercent.replace(/[^0-9.-]+/g, '') : '0.0';
      fund.units = (fund.fromUnits && this.validatefundValue(fund.fromUnits)) ? fund.fromUnits.replace(/[^0-9.-]+/g, '') : '0.00000';
      if(this.fundTransferType === 'U' && i===0){
        this.fundTransferType = this.dcaDetails._options.properties.transfer_type.oneOf[2].enum[0];
      } else if(this.fundTransferType === 'U' && i===1)
       {
        this.fundTransferType = this.dcaDetails._options.properties.transfer_type.oneOf[1].enum[0];
      }
      fund.amount_unit_indicator = this.fundTransferType;
      delete fund.isChanged;
      delete fund.fromAmountHasValue;
      delete fund.toAmountHasValue;
      delete fund.fromPercentHasValue;
      delete fund.toPercentHasValue;
      delete fund.fromUnitHasValue;
      delete fund._links;
    });
    this.selectedFunds = filteredFunds;
  }

  validatefundValue(value) {
    let fundValue = value.replace(/,|\s/g, "").replace("%", "").replace("$", "");
    return Number(fundValue) > 0 ? true : false;
  }

  // Displays POST api Alerts
  displayAlerts(response) {
    if (response._embedded && response._embedded.status_report && response._embedded.status_report.messages && response._embedded.status_report.messages.length > 0) {
      this.alerts = response._embedded.status_report.messages;
      console.log('this.alerts', this.alerts)
    }
  }

  toggleAlertMsg(event) {
    event.target.classList.toggle("alertMsg-overflow");
  }

  // On successful submit, turns the tab to green
  isStepComplete() {
    return this.isSuccess;
  }

  //This event will be called when we select dcaStrategy dropdown
  onchangeStrategyPopulateFields(e){
    this.headers.cost_average_type = e.value;
    let params = { effectiveDate: this.dcaDetails.effective_date? this.dcaDetails.effective_date : ""};
    if(e.value && e.value !==" " && e.value !==undefined){
    OcInfraModule.AppInjector.get(CommonService).getCallback(this.getDcaURL, this.headers, params).subscribe(response => {
      this.strategyDisableCreate = false;
      this.isSubmit = false;
      OcInfraModule.AppInjector.get(AdvGrowlService).clearMessages();
      this.dcaDetails = response;
      let dcaResponseOptions = response._options;
    if(e.value==="AL"){
      const strategyTransferFrequency = dcaResponseOptions.properties.transfer_frequency.oneOf.find(opt => opt.enum.includes(this.dcaDetails.transfer_frequency.toString()));
      strategyTransferFrequency ? this.dcaForm.controls['transferFrequency'].setValue(strategyTransferFrequency.title) : this.dcaForm.controls['transferFrequency'].setValue('');
      this.dcaForm.controls['transferFrequency'].disable();
      this.dcaForm.controls['endDate'].setValue(this.dcaDetails.end_date?moment(this.dcaDetails.end_date):"");
      this.dcaForm.controls['endDate'].disable();
      this.dcaForm.controls['transferType'].setValue("Percent", {onlySelf: true});
      this.dcaForm.controls['transferType'].enable();
      this.dcaForm.controls['transferDay'].setValue("01", {onlySelf: true});
      this.dcaForm.controls['transferDay'].enable();
      this.dcaForm.controls['beginDate'].setValue(this.dcaDetails.begin_date?moment(this.dcaDetails.begin_date):"");
      this.dcaForm.controls['beginDate'].enable();
      this.strategyTableReadonly = false;
      if (this.dcaForm.value.transferType === 'Amount to Percent' || this.dcaForm.value.transferType === 'Dollar Transfer') {
        this.totalFromFunds = this.getTotalofAmount(this.dcaDetails.funds, 'fromAmount');
      }
      if (!this.dcaForm.value.transferType || this.dcaForm.value.transferType === 'Units') {
        this.totalFromFunds = this.getTotalofunits(this.dcaDetails.funds, 'fromUnits');
      }
      if (!this.dcaForm.value.transferType || this.dcaForm.value.transferType === 'Percent') {
        this.totalFromFunds = this.getTotalofPercent(this.dcaDetails.funds, 'fromPercent');
      }
      if (this.dcaForm.value.transferType === 'Amount to Percent' || this.dcaForm.value.transferType === 'Dollar Transfer') {
        this.totalToFunds = this.getTotalofAmount(this.dcaDetails.funds, 'toAmount');
      }
      if (!this.dcaForm.value.transferType || this.dcaForm.value.transferType === 'Amount to Percent' || this.dcaForm.value.transferType === 'Percent' || this.dcaForm.value.transferType === 'Units') {
        this.totalToFunds = this.getTotalofPercent(this.dcaDetails.funds, 'toPercent');
      }

    }else if(e.value === "EG"){
      const strategyTransferFrequency = dcaResponseOptions.properties.transfer_frequency.oneOf.find(opt => opt.enum.includes(this.dcaDetails.transfer_frequency.toString()));
      strategyTransferFrequency ? this.dcaForm.controls['transferFrequency'].setValue(strategyTransferFrequency.title) : this.dcaForm.controls['transferFrequency'].setValue('');
      this.dcaForm.controls['transferFrequency'].disable();
      this.dcaForm.controls['endDate'].setValue(this.dcaDetails.end_date?moment(this.dcaDetails.end_date):"");
      this.dcaForm.controls['endDate'].disable();
      const strategyTransferDay = dcaResponseOptions.properties.transfer_day.oneOf.find(opt => opt.enum.includes(this.dcaDetails.transfer_day.toString()));
      strategyTransferDay ? this.dcaForm.controls['transferDay'].setValue(strategyTransferDay.title) : this.dcaForm.controls['transferDay'].setValue('');
      this.dcaForm.controls['transferDay'].disable();
      this.dcaForm.controls['beginDate'].setValue(this.dcaDetails.begin_date?moment(this.dcaDetails.begin_date):"");
      this.dcaForm.controls['beginDate'].disable();
      const strategyTransferType = dcaResponseOptions.properties.transfer_type.oneOf.find(opt => opt.enum.includes(this.dcaDetails.transfer_type.toString()));
      strategyTransferType ? this.dcaForm.controls['transferType'].setValue(strategyTransferType.title) : this.dcaForm.controls['transferDay'].setValue('');
      this.dcaForm.controls['transferType'].disable();
      this.strategyTableReadonly = false;
      if (this.dcaForm.value.transferType === 'Amount to Percent' || this.dcaForm.value.transferType === 'Dollar Transfer') {
        this.totalFromFunds = this.getTotalofAmount(this.dcaDetails.funds, 'fromAmount');
      }
      if (!this.dcaForm.value.transferType || this.dcaForm.value.transferType === 'Units') {
        this.totalFromFunds = this.getTotalofunits(this.dcaDetails.funds, 'fromUnits');
      }
      if (!this.dcaForm.value.transferType || this.dcaForm.value.transferType === 'Percent') {
        this.totalFromFunds = this.getTotalofPercent(this.dcaDetails.funds, 'fromPercent');
      }
      if (this.dcaForm.value.transferType === 'Amount to Percent' || this.dcaForm.value.transferType === 'Dollar Transfer') {
        this.totalToFunds = this.getTotalofAmount(this.dcaDetails.funds, 'toAmount');
      }
      if (!this.dcaForm.value.transferType || this.dcaForm.value.transferType === 'Amount to Percent' || this.dcaForm.value.transferType === 'Percent' || this.dcaForm.value.transferType === 'Units') {
        this.totalToFunds = this.getTotalofPercent(this.dcaDetails.funds, 'toPercent');
      }

    }else if(e.value === "AG" || e.value === "SI"){
      this.strategyDisableCreate = true;
      const strategyTransferFrequency = dcaResponseOptions.properties.transfer_frequency.oneOf.find(opt => opt.enum.includes(this.dcaDetails.transfer_frequency.toString()));
      strategyTransferFrequency ? this.dcaForm.controls['transferFrequency'].setValue(strategyTransferFrequency.title) : this.dcaForm.controls['transferFrequency'].setValue('');
      this.dcaForm.controls['transferFrequency'].disable();
      this.dcaForm.controls['endDate'].setValue(this.dcaDetails.end_date?moment(this.dcaDetails.end_date):"");
      this.dcaForm.controls['endDate'].disable();
      const strategyTransferDay = dcaResponseOptions.properties.transfer_day.oneOf.find(opt => opt.enum.includes(this.dcaDetails.transfer_day.toString()));
      strategyTransferDay ? this.dcaForm.controls['transferDay'].setValue(strategyTransferDay.title) : this.dcaForm.controls['transferDay'].setValue('');
      this.dcaForm.controls['transferDay'].disable();
      this.dcaForm.controls['beginDate'].setValue(this.dcaDetails.begin_date?moment(this.dcaDetails.begin_date):"");
      this.dcaForm.controls['beginDate'].disable();
      const strategyTransferType = dcaResponseOptions.properties.transfer_type.oneOf.find(opt => opt.enum.includes(this.dcaDetails.transfer_type.toString()));
      strategyTransferType ? this.dcaForm.controls['transferType'].setValue(strategyTransferType.title) : this.dcaForm.controls['transferDay'].setValue('');
      this.dcaForm.controls['transferType'].disable();
      this.dcaDetails.funds.forEach((fund) => {
        fund.toAmountHasValue = fund.toAmount ? this.validatefundValue(fund.toAmount) : false;
        fund.fromAmountHasValue = fund.fromAmount ? this.validatefundValue(fund.fromAmount) : false;
        fund.fromUnitHasValue = fund.fromUnits ? this.validatefundValue(fund.fromUnits) : false;
        fund.toPercentHasValue = fund.toPercent ? this.validatefundValue(fund.toPercent) : false; 
        fund.fromPercentHasValue = fund.fromPercent ? this.validatefundValue(fund.fromPercent) : false;
        this.strategyTableReadonly = true;
        this.isSubmit = true;
        if (this.dcaForm.value.transferType === 'Amount to Percent' || this.dcaForm.value.transferType === 'Dollar Transfer') {
          this.totalFromFunds = this.getTotalofAmount(this.dcaDetails.funds, 'fromAmount');
        }
        if (!this.dcaForm.value.transferType || this.dcaForm.value.transferType === 'Units') {
          this.totalFromFunds = this.getTotalofunits(this.dcaDetails.funds, 'fromUnits');
        }
        if (!this.dcaForm.value.transferType || this.dcaForm.value.transferType === 'Percent') {
          this.totalFromFunds = this.getTotalofPercent(this.dcaDetails.funds, 'fromPercent');
        }
        if (this.dcaForm.value.transferType === 'Amount to Percent' || this.dcaForm.value.transferType === 'Dollar Transfer') {
          this.totalToFunds = this.getTotalofAmount(this.dcaDetails.funds, 'toAmount');
        }
        if (!this.dcaForm.value.transferType || this.dcaForm.value.transferType === 'Amount to Percent' || this.dcaForm.value.transferType === 'Percent' || this.dcaForm.value.transferType === 'Units') {
          this.totalToFunds = this.getTotalofPercent(this.dcaDetails.funds, 'toPercent');
        }
      });
    }
  }, error => {
    this.strategyDisableCreate = false;
    OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
    if (error && error._embedded && error._embedded.status_report && error._embedded.status_report.consistent === false) {
      OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error._embedded.status_report.messages[0].message, 'Error', 0);
    }
  });
} else{
  this.dcaForm.controls['transferFrequency'].enable();
  this.dcaForm.controls['endDate'].enable();
  this.dcaForm.controls['transferDay'].enable();
  this.dcaForm.controls['beginDate'].enable();
  this.dcaForm.controls['transferType'].enable();
  this.strategyTableReadonly = false;
  this.strategyDisableCreate = false;
  this.getDcaDetails();
}
}

  // Sorts the fund table based on the column
  sortTable(column) {
    let isAsc;
    let _self = this;
    _.forEach(_self.tableSortStates, function (obj, index) {
      if (obj['column'] == column) {
        isAsc = !obj['isAsc'];
        _self.tableSortStates[index]['isAsc'] = isAsc;
      }
    })
    this.dcaDetails.funds = isAsc ? _.orderBy(this.dcaDetails.funds, this.byKey(column), 'asc') : _.orderBy(this.dcaDetails.funds, this.byKey(column), 'desc');
  }

  // checks if it is a Number String
  byKey(key) {
    return function (o) {
      if (typeof o[key] === 'string') {
        return isNaN(+o[key].replace(/[^a-zA-Z0-9]/g, '')) ? o[key] : +o[key];
      }
      return o[key];
    };
  }
}
