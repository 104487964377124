import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appNumberMask]',
})
export class NumberMaskDirective {
  private regex: RegExp = new RegExp(/^\d*(\.\d{0,2})?$/g);
  private specialKeys: Array<string> = [
    'Backspace',
    'Tab',
    'End',
    'Home',
    'ArrowLeft',
    'ArrowRight',
    'Del',
    'Delete',
  ];
  constructor(private el: ElementRef) {}

  @HostListener('keypress', ['$event'])
  onKeyPress(e) {
    if (
      this.specialKeys.indexOf(e.key) !== -1 ||
      (e.key === 'v' && e.ctrlKey === true) ||
      (e.key === 'v' && e.metaKey === true)
    ) {
      return;
    }
    let current: string = this.el.nativeElement.value;
    const startPosition = this.el.nativeElement.selectionStart;
    const endPosition = this.el.nativeElement.selectionEnd;
    const next: string = [
      current.slice(0, startPosition),
      e.key == 'Decimal' ? '.' : e.key,
      current.slice(endPosition),
    ].join('');
    console.log('next', next);
    if (next && !String(next).match(this.regex)) {
      e.preventDefault();
    }
  }

  @HostListener('paste', ['$event']) blockPaste(e: KeyboardEvent) {
    e.preventDefault();
  }

  @HostListener('copy', ['$event']) blockCopy(e: KeyboardEvent) {
    e.preventDefault();
  }

  @HostListener('cut', ['$event']) blockCut(e: KeyboardEvent) {
    e.preventDefault();
  }
}
