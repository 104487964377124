import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { ResourceService, OcInfraModule } from '@diaas/ux-web';
import { CommonService } from '../../services/common.service';
import * as _ from 'lodash';
import { WithdrawalSurrenderAction } from './withdrawalSurrenderAction';
import { cloneDeep } from 'lodash';
import { withdrawlFormModal } from './constants';
interface Type {
  value: String;
  viewValue: String;
}

interface Level {
  value: String;
  viewValue: String;
}

@Component({
  selector: 'withdrawl-surrender-surrender',
  templateUrl: './surrenderComponent.html',
  styleUrls: ['./surrenderComponent.scss']
})

export class WithdrawlSurrenderSurrender implements OnInit {
  [x: string]: any;  
  systematicWithdralFormModal: any;
  withdrawalEffectiveDatePicker: any;
  withdrawalEffectiveMaxDate: any;
  netGrossIndicator: any;
  withdrawlNetGrossIndicator: any;
  withdrawalTransactionType: any= 'M';
  withdrawalTransactionLevel: any;
  federalWithHolding: any;
  noFederalWithHolding: any;
  noStateWithHolding: any;
  disableFederalOverdueAmount: any;
  disableFederalOverduePercent: any;
  disableStateWithHoldingAmount: any;
  disableStateWithHoldingPercent: any;
  surrenderOrRemainingAmount: any;
  surrenderPercent: string;
  withdrawalEffectiveDate: any;
  // withdrawalTransactionTypeM: any = 'M';
  costBasis: any;
  taxableGain: any;
  transactionGain: any;
  stateWithHoldingPer: any;
  federalWithHoldingPer: any;
  transactionTypes: Type[] = [];
  transactionLevels: Level[] = [];
  fundTableData: any;
  stateWithHolding: any;
  zeroWithHolding: any;
  federalOverrideAmount: string;
  federalOverridePercent: string;
  stateOverrideAmount: string;
  stateOverridePercent: string;
  amountRequested: any;
  dividendIntWith: any;
  requiredFields: any[];
  stateEnabled: any;
  responseOptions: any;
  transactionType: any;
  transactionLevel: any;
  fundValues: any;
  specialConfirm: any;
  passedEnhancedSecurity:boolean=false;
  agentRecommend: any;
  overrideChcked: boolean = false;
  isOwner: boolean = false;
  amntRequested: boolean = false;
  isAgentandAssiant: boolean =false;
  isNetRadio: boolean = false;
  ssn_facta_verify: any;
  withdrawalAmt: any;
  callerOwner:boolean=false;
  verifyAddress:boolean=false;
 


  @Input() withdrawlSurrenderType: any;
  @Input() policyDetails: Object;
  @Input() surrenderDetails: any;
  @Input() quoteDetails: any;
  @Input() withdrawalSurrenderAction: WithdrawalSurrenderAction;
  @Input() withdrawToPayPremiumM: any;
  @Output() onUpdateGainWithold: EventEmitter<object> = new EventEmitter<object>();

  alertMsg = [];
  @Output() withdrawlSurrenderModel = new EventEmitter<any>();
constructor(private commonService: CommonService) {  }


  ngOnInit() {
    this.resetData();
    this.systematicWithdralFormModal = cloneDeep(withdrawlFormModal); 
    this.commonService.drpDownStatus.subscribe((isdrpDownStatus: boolean) => {
      this.amntRequested = isdrpDownStatus;
      if (isdrpDownStatus) {
        this.withdrawalAmt = this.formatCurrency('', 'blur', this.surrenderDetails.withdraw_amt.toString());
      }
    });
    const resourceService = OcInfraModule.AppInjector.get(ResourceService);
    const selectedRoleSummary = resourceService.getResourceFromAliasName('selectedRoles');
    const otherRoleSummary = OcInfraModule.AppInjector.get(CommonService).otherRole; 
    this.getSurrenderDetails();
    if (selectedRoleSummary.data.role == 'Owner')  {
    this.isOwner = true;
   }
   if(selectedRoleSummary.data.role=='Agent' || selectedRoleSummary.data.role=='Agent Assistant'|| otherRoleSummary=='Agent/Advisor Assistant'){
   this.isAgentandAssiant = true;
 }
}

  updateAmountRequest(e){
    if(e.checked){
      this.withdrawalAmt = this.surrenderDetails.max_withdrawal_amt != undefined ? this.formatCurrency('', 'blur', this.surrenderDetails.max_withdrawal_amt.toString()) : '';
      this.amntRequested= true;
    }else{
      this.withdrawalAmt = this.surrenderDetails.withdraw_amt != undefined ? this.formatCurrency('', 'blur', this.surrenderDetails.withdraw_amt.toString()) : '';
      this.amntRequested= false;
    }
  }

  onRadioChange(value) {
   if (value == "N") {
      this.isNetRadio = true;
  }
  else {
      this.isNetRadio = false;
   }
  }

  resetData() {
    this.disableFederalOverdueAmount = true;
    this.disableFederalOverduePercent = true;
    this.disableStateWithHoldingAmount = true;
    this.disableStateWithHoldingPercent = true;
    this.withdrawalEffectiveDatePicker = new Date();
    this.surrenderOrRemainingAmount = "";
  }

  handleCopyFromQuote(event) {
    if (event.checked && this.quoteDetails) {

      let quoteAmount = this.quoteDetails.amountRequested.replace(/,|\s/g, "").replace("$", "");
      this.surrenderOrRemainingAmount = this.formatCurrency('', 'blur', quoteAmount);
      this.withdrawalTransactionType = "Amount";
    }
  }

  handleHoldings(type) {
    this.overrideChcked = true;
    if (type == 'federalHoldings') {
      if (this.federalWithHolding) {
        this.disableFederalOverdueAmount = false;
        this.disableFederalOverduePercent = false;
        if (this.stateOverrideAmount || this.stateOverridePercent) {
          this.disableFederalOverdueAmount = this.stateOverrideAmount ? false : true;
          this.disableFederalOverduePercent = this.stateOverridePercent ? false : true;
        }
      } else {
        this.resetFederalHoldings();
      }
      if(this.zeroWithHolding){
        this.stateOverrideAmount = this.formatCurrency('', 'blur', '0');
        this.federalOverrideAmount = this.formatCurrency('', 'blur', '0');
        this.federalWithHoldingPer = this.formatPercent('', 'blur', '0');
        this.stateWithHoldingPer = this.formatPercent('', 'blur', '0');
      }
    } else {
      if (this.stateWithHolding) {
        this.disableStateWithHoldingAmount = false;
        this.disableStateWithHoldingPercent = true;
        if (this.federalOverrideAmount || this.federalOverridePercent) {
          this.disableStateWithHoldingAmount = this.federalOverrideAmount ? false : true;
          this.disableStateWithHoldingPercent = this.federalOverridePercent ? false : true;
        }
      } else {
        this.resetStateWithHoldings();
      }
    }
  }

  resetFederalHoldings() {
    this.disableFederalOverdueAmount = true;
    this.disableFederalOverduePercent = true;
    this.federalOverrideAmount = this.formatCurrency('', 'blur', this.federalOverrideAmount);
    this.federalOverridePercent = "";
    this.zeroWithHolding = false;    
    this.overrideChcked = false;
  }

  resetStateWithHoldings() {
    this.disableStateWithHoldingAmount = true;
    this.disableStateWithHoldingPercent = true;
    this.stateOverrideAmount = this.formatCurrency('', 'blur', this.stateOverrideAmount);
    this.stateOverridePercent = "";
  }

  onFederalOverrideChange(type) {
    if (type == 'amount') {
      this.disableFederalOverduePercent = true;
      this.handleChange('federal');
    } else {
      this.disableFederalOverdueAmount = this.federalOverridePercent ? true : false;
      this.handleChange('federal');
    }
  }

  onStateOverrideChange(type) {
    if (type == 'amount') {
      this.disableStateWithHoldingPercent = true;
      this.handleChange('state');
    } else {
      this.disableStateWithHoldingAmount = this.stateOverridePercent ? true : false;
      this.handleChange('state');
    }
  }

  handleChange(type) {
    if (type == 'federal') {
      if (this.stateOverrideAmount || this.stateOverridePercent) {
        this.disableFederalOverdueAmount = this.stateOverrideAmount ? false : true;
        this.disableFederalOverduePercent = true;
      } else if (this.stateWithHolding) {
        if (this.federalOverrideAmount || this.federalOverridePercent) {
          this.disableStateWithHoldingAmount = this.federalOverrideAmount ? false : true;
          this.disableStateWithHoldingPercent = true;
        } else {
          this.disableStateWithHoldingAmount = false;
          this.disableStateWithHoldingPercent = true;
        }
      }
    } else {
      if (this.federalOverrideAmount || this.federalOverridePercent) {
        this.disableStateWithHoldingAmount = this.federalOverrideAmount ? false : true;
        this.disableStateWithHoldingPercent = true;
      } else if (this.federalWithHolding) {
        if (this.stateOverrideAmount || this.stateOverridePercent) {
          this.disableFederalOverdueAmount = this.stateOverrideAmount ? false : true;
          this.disableFederalOverduePercent = true
        } else {
          this.disableFederalOverdueAmount = false;
          this.disableFederalOverduePercent = true;
        }
      }
    }
  }

  handleTransactionChange() {
    if (this.withdrawalTransactionLevel == 'Removed Pro Rata basis') {
      if (this.withdrawalTransactionType == 'Amount') {
        this.surrenderPercent = this.formatPercent('', 'blur', this.surrenderDetails.surrender_percent);
      } else {
        this.surrenderOrRemainingAmount = this.formatCurrency('', 'blur', this.surrenderDetails.surrender_amount);
      }
    } else {
      this.surrenderOrRemainingAmount = this.formatCurrency('', 'blur', this.surrenderDetails.surrender_amount);
      this.surrenderPercent = this.formatPercent('', 'blur', this.surrenderDetails.surrender_percent);
    }
  }

  /** AMOUNT AND PERCENT FORMATING  */
  formatNumber(n, type, flag) {
    if (flag) {
      n = n.charAt(0) == '0' ? n.replace(n.charAt(0), "") : n;
    }
    if (type == 'currency') {
      return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return n.replace(/\D/g, "");
    }
  }

  //formats the value with commas and $ allowing 2 decimal values
  formatCurrency(input, blur, respValue) {
    var input_val = "";
    if (respValue == '' || respValue == undefined) {
      input_val = input.target.value;
    } else {
      input_val = respValue;
    }
    if (input_val === "") { return; }
    var original_len = input_val.length;
    if (input_val.indexOf(".") >= 0) {
      var decimal_pos = input_val.indexOf(".");
      decimal_pos = decimal_pos > 15 ? 15 : decimal_pos;
      var left_side = input_val.substring(0, decimal_pos);
      var right_side = input_val.substring(decimal_pos);
      left_side = this.formatNumber(left_side, 'currency', true);
      right_side = this.formatNumber(right_side, 'currency', false);
      if (blur === "blur") {
        right_side += "00";
      }
      // Limit decimal to only 2 digits
      right_side = right_side.substring(0, 2);
      // join number by .
      input_val = "$" + left_side + "." + right_side;

    } else {
      // no decimal entered
      // add commas to number
      // remove all non-digits
      input_val = input_val.substring(0, 15);
      input_val = this.formatNumber(input_val, 'currency', true);
      input_val = "$" + input_val;
      // final formatting
      if (blur === "blur") {
        input_val += ".00";
      }
    }
    if (input_val.charAt(input_val.indexOf('$') + 1) == '.') {
      input_val = input_val.replace(input_val.charAt(input_val.indexOf('$') + 1), '0.');
    }
    if (respValue == '' || respValue == undefined) {
      input.target["value"] = input_val;
    } else {     
      return input_val;
    }
  }

  // formats the value with commas and percentage allowing 1 decimal value
  formatPercent(input, blur, respValue) {
    var input_val = "";
    if (respValue == '' || respValue == undefined) {
      input_val = input.target.value;
    } else {
      input_val = respValue;
    } if (input_val === "") { return; }
    var original_len = input_val.length;
    if (input_val.indexOf(".") >= 0) {
      var decimal_pos = input_val.indexOf(".");
      var left_side = input_val.substring(0, decimal_pos);
      var right_side = input_val.substring(decimal_pos);
      left_side = this.formatNumber(left_side, 'percent', true);
      right_side = this.formatNumber(right_side, 'percent', false);
      if (blur === "blur") {
        right_side += "00";
      }
      // Limit decimal to only 2 digits
      right_side = right_side.substring(0, 2);
      // join number by .
      input_val = left_side + "." + right_side + "%";
    } else {
      // no decimal entered
      // add commas to number
      // remove all non-digits
      input_val = this.formatNumber(input_val, 'percent', true);
      if (blur === "blur") {
        input_val += ".00";
      }
      input_val = input_val + "%";
    }
    if (input_val.charAt(input_val.indexOf('$') + 1) == '.') {
      input_val = input_val.replace(input_val.charAt(input_val.indexOf('$') + 1), "0.")
    }
    if (respValue == '' || respValue == undefined) {
      input.target["value"] = input_val;
    } else {
      return input_val;
    } 
  }

  inputChange(input, i, isAmount) {
    var input_val = "";
    input_val = input.target.value.replace(/,|\s/g, "").replace("%", "").replace("$", "");
    if (i == 'surrenderPercent') {
      this.surrenderPercent = this.formatPercent('', 'blur', input_val);
    } else if (i == 'surrenderAmount') {
      this.withdrawalAmt = this.formatCurrency('', 'blur', input_val);
      if (!this.isAmountAllowable(input_val)) {
        let alertMsg = [];
        alertMsg.push("Amount Exceeds Allowable by Telephone");
        input_val ="0.0";
        let dialogRef = OcInfraModule.AppInjector.get(CommonService).showConfirmation({ content: alertMsg, custom: true }, 'CLOSE', '', 'ALERT', '393px');
        dialogRef.afterClosed().subscribe(res => {
          res;
        });
      }
      this.surrenderOrRemainingAmount = this.formatCurrency('', 'blur', input_val);    

    } else if (i == 'federalAmount') {
      this.federalOverrideAmount = this.formatCurrency('', 'blur', input_val);
    } else if (i == 'federalPercent') {
      this.federalOverridePercent = this.formatPercent('', 'blur', input_val);
    } else if (i == 'stateAmount') {
      this.stateOverrideAmount = this.formatCurrency('', 'blur', input_val);
    } else if (i == 'statePercent') {
      this.stateOverridePercent = this.formatPercent('', 'blur', input_val);
    } else {
      // hands fund specific scenario
      isAmount ?
        this.fundTableData[i].surrender_amt = input_val == "" ? input_val : this.formatCurrency('', 'blur', input_val) :
        this.fundTableData[i].surrender_percent = input_val == "" ? input_val : this.formatPercent('', 'blur', input_val);
    }
  }

  // Checks if the amount is with in the allowable limit
  isAmountAllowable(amount) {
    return Number(amount) > Number(24999.99) ? false : true;
  }

  // integrates the API response 
  getSurrenderDetails() {
    let response = this.surrenderDetails;
    this.responseOptions = response._options;
    this.requiredFields = response._options && response._options.required || [];
    this.costBasis = response.cost_basis != undefined ? this.formatCurrency('', 'blur', response.cost_basis.toString()) : '';
    this.taxableGain = response.trx_gain != undefined ? this.formatCurrency('', 'blur', response.trx_gain.toString()) : '';
    this.transactionGain = response.transaction_gain != undefined ? this.formatCurrency('', 'blur', response.transaction_gain.toString()) : '';
    this.stateWithHoldingPer = response.state_withh_percent != undefined ? this.formatPercent('', 'blur', response.state_withh_percent.toString()) : '';
    this.federalWithHoldingPer = response.fed_withh_percent != undefined ? this.formatPercent('', 'blur', response.fed_withh_percent.toString()) : '';
    this.federalOverrideAmount = response.fed_withh_amount != undefined ? this.formatCurrency('', 'blur', response.fed_withh_amount.toString()) : '';
    this.stateOverrideAmount = response.state_withh_amount != undefined ? this.formatCurrency('', 'blur', response.state_withh_amount.toString()) : '';
    this.dividendIntWith = response.div_int_withholding != undefined ? this.formatCurrency('', 'blur', response.div_int_withholding.toString()) : '';
    this.withdrawalAmt = response.withdraw_amt != undefined ? this.formatCurrency('', 'blur', response.withdraw_amt.toString()) : '';




    // Filter ssn_facta_verify from drools response
    if (this.responseOptions && this.responseOptions.properties.ssn_facta_verify && this.responseOptions.properties.ssn_facta_verify.oneOf) {
      for (let i = 0; i < this.responseOptions.properties.ssn_facta_verify.oneOf.length; i++) {
        if (response.ssn_facta_verify == this.responseOptions.properties.ssn_facta_verify.oneOf[i].enum[0]) {
          this.ssn_facta_verify = this.responseOptions.properties.ssn_facta_verify.oneOf[i].title;
        }
      }
    }

    this.withdrawalEffectiveDate = response.effective_date ? new Date(response.effective_date) : '';
    this.withdrawalEffectiveMaxDate = this.withdrawalEffectiveDate;
    this.noFederalWithHolding = response.no_federal_withholding == 'Y' ? true : false;
    this.noStateWithHolding = response.no_state_withholding == 'Y' ? true : false;
    this.specialConfirm = response.special_confirm;
    if (this.withdrawlSurrenderType == 'DIVIDEND_WITHDRAWAL') {
      this.transactionLevels = [];
      if (this.responseOptions.properties.transaction_level && this.responseOptions.properties.transaction_level.oneOf) {
        for (let i = 0; i < this.responseOptions.properties.transaction_level.oneOf.length; i++) {
          let viewValue = this.responseOptions.properties.transaction_level.oneOf[i].title;
          viewValue = viewValue == 'Removed from specified funds' ? 'Fund Specific' : viewValue == 'Removed Pro Rata basis' ? 'Prorata' : viewValue;
          this.transactionLevels.push({ value: this.responseOptions.properties.transaction_level.oneOf[i].title, viewValue: viewValue })
        }
      }

      this.transactionTypes = [];
      if (this.responseOptions.properties.withdrawal_types && this.responseOptions.properties.withdrawal_types.oneOf) {
        for (let i = 0; i < this.responseOptions.properties.withdrawal_types.oneOf.length; i++) {
          if (this.surrenderDetails.withdrawal_types.includes(this.responseOptions.properties.withdrawal_types.oneOf[i].enum[0])) {
            this.transactionTypes.push({ value: this.responseOptions.properties.withdrawal_types.oneOf[i].enum[0], viewValue: this.responseOptions.properties.withdrawal_types.oneOf[i].title })
          }
        }
      }

      //this.surrenderOrRemainingAmount = this.formatCurrency('', 'blur', response && response.hasOwnProperty('surrender_amount') && response);
      // this.surrenderPercent = this.formatPercent('', 'blur', response.surrender_percent);
      this.stateEnabled = response.state_enabled;
    }
    const schema = this.surrenderDetails._options.links[0].schema.properties;
    this.systematicWithdralFormModal.forEach((item) => {
      if (this.surrenderDetails[item.id] && typeof this.surrenderDetails[item.id] !== 'object') {     
        item.value = (schema[item.id] && schema[item.id].format === 'currency')
        ? `$${parseFloat(this.surrenderDetails[item.id]).toFixed(2).toLocaleString()}`
          : this.surrenderDetails[item.id];
          if (schema[item.id] && schema[item.id].format === 'date') {
            item.value = new Date(item.value);
          };
      } 

        // populate Select values
        if (item.type == 'select' && schema[item.id] && schema[item.id].oneOf ) {
          let selectOptions = [];
          schema[item.id].oneOf.map(option =>{           
              selectOptions.push({"label":option.title, "value":option.enum.join(",")}) 
          });
          item.values = selectOptions;
        }  
        if (['state_withholding_percent', 'federal_withholding_percent'].includes(item.id)) {
          item.value = item.value ? `${item.value}%`: '0.0%';
        }  
    });
    
  }

  // turns tab into green or red depending on required fields
  isStepComplete() {
    return this.checkRequiredFields() == 'Y' ? true : false;
  }

  updateGainWitholdValues(updatedGainWitholdDtls){
    console.log('updating the dtls')
    this.transactionGain = updatedGainWitholdDtls.transaction_gain != undefined ? this.formatCurrency('', 'blur', updatedGainWitholdDtls.transaction_gain.toString()) : '';
    this.taxableGain = updatedGainWitholdDtls.trx_gain != undefined ? this.formatCurrency('', 'blur', updatedGainWitholdDtls.trx_gain.toString()) : '';
    this.federalOverrideAmount = updatedGainWitholdDtls.fed_withh_amount != undefined ? this.formatCurrency('', 'blur', updatedGainWitholdDtls.fed_withh_amount.toString()) : '';
    this.federalOverridePercent =this.federalWithHoldingPer= updatedGainWitholdDtls.fed_withh_percent != undefined ? this.formatPercent('', 'blur',  updatedGainWitholdDtls.fed_withh_percent.toString()) : '';
    this.stateOverrideAmount = updatedGainWitholdDtls.state_withh_amount != undefined ? this.formatCurrency('', 'blur', updatedGainWitholdDtls.state_withh_amount.toString()) : '';
    this.stateOverridePercent = this.stateWithHoldingPer= updatedGainWitholdDtls.state_withh_percent != undefined ? this.formatPercent('', 'blur',  updatedGainWitholdDtls.state_withh_percent.toString()) : '';
  }

  // To Pass the ser selected values to the master component
  getSurrenderValues() {
    if (this.responseOptions && this.responseOptions.properties
        && this.responseOptions.properties.transaction_level && this.responseOptions.properties.transaction_level.oneOf) {
      for (let i = 0; i < this.responseOptions.properties.transaction_level.oneOf.length; i++) {
        if (this.responseOptions.properties.transaction_level.oneOf[i].title == this.withdrawalTransactionLevel) {
          this.transactionLevel = Object.assign({ title: this.withdrawalTransactionLevel, enum: this.responseOptions.properties.transaction_level.oneOf[i].enum[0] });
        }
      }
    }
    if (this.responseOptions && this.responseOptions.properties 
        && this.responseOptions.properties.withdrawal_types && this.responseOptions.properties.withdrawal_types.oneOf) {
      for (let i = 0; i < this.responseOptions.properties.withdrawal_types.oneOf.length; i++) {
        this.responseOptions.properties.withdrawal_types.oneOf[0].enum[0]
        if (this.responseOptions.properties.withdrawal_types.oneOf[i].enum[0] == this.withdrawalTransactionType) {
          this.transactionType = Object.assign({ title: this.responseOptions.properties.withdrawal_types.oneOf[i].title, enum: this.responseOptions.properties.withdrawal_types.oneOf[i].enum });
        }
      }
    }


    return {
      effective_date: this.quoteDetails.effective_date,
      withdrawal_type: this.transactionType.enum,
      transaction_type: this.transactionType,
      surrender_amount: this.surrenderOrRemainingAmount,
      cost_basis: this.costBasis,
      trx_gain: this.taxableGain,
      transaction_gain: this.transactionGain,
      div_int_withholding: this.dividendIntWith,
      withdraw_amt:this.withdrawalAmt,
      fed_withh_amount: this.federalOverrideAmount ? this.federalOverrideAmount : '0',
      federal_withholding_percentage: this.federalWithHoldingPer ? this.federalWithHoldingPer : '0.00%',
      state_withholding_amount: this.stateOverrideAmount ? this.stateOverrideAmount : '$0.00',
      state_withholding_percent: this.stateWithHoldingPer ? this.stateWithHoldingPer : '0.00%',
      net_gross_indicator: this.withdrawlNetGrossIndicator,
      federal_withholding_override: this.federalWithHolding ? 'Y' : 'N',
      no_federal_withholding: this.noFederalWithHolding ? 'Y' : 'N',
      zero_withhold: this.zeroWithHolding?"Y":"N",
      passed_enhanced_security: this.passedEnhancedSecurity ? 'Y' : 'N',
      agent_recommend_or_authorized: this.agentRecommend ? 'Y' : 'N',
      required_fields: this.checkRequiredFields()
    };
  }

  getViewValue() {
    return this.withdrawalTransactionLevel && this.transactionLevels ?
      this.transactionLevels.find(obj => obj.value == this.withdrawalTransactionLevel).viewValue.toUpperCase():null;
  }

  checkRequiredFields() {
    const withdrawalAmt =  parseFloat(this.withdrawalAmt.replace("$", "").replace(/,/g, ''));
    if(this.withdrawlNetGrossIndicator && this.withdrawalTransactionType && (this.withdrawalAmt && this.isAmountAllowable(withdrawalAmt)) && this.verifyAddress && (!this.isOwner ? this.passedEnhancedSecurity : "true")) {
      return 'Y';
    } else {
      return 'N';
    }
  }

  updateGainWitholdClick(evt){
    this.onUpdateGainWithold.emit(evt);
  }
}