import { AfterViewInit, Component} from '@angular/core';
import { Configuration, OcInfraModule, ResourceService } from '@diaas/ux-web';
import { CommonService } from '../../services/common.service';
import { AdvGrowlService } from 'primeng-advanced-growl';
import * as _ from 'lodash';
import { AssetAccountData, columnsDef } from './asset-account-details.constant';
import { PartySearchFactory } from '../../factories/partySearchFactory';

@Component({
    selector: 'app-asset-account-details',
    templateUrl: './asset-account-details.component.html',
    styleUrls: ['./asset-account-details.component.scss']
})

export class AssetAccountDetailsComponent implements AfterViewInit {
    assetAccountData: AssetAccountData[] = [];
    assetAccountHeader: any;    
    customClass = 'asset-account-table';
    columnsDef = columnsDef;
    assetAccountUrl = '';
    isGroupAnnuity: boolean = false;
    private partySearchFactory = new PartySearchFactory()

    constructor() {
    }


    async ngAfterViewInit() { 
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let policyDetails = resourceService.getResourceFromAliasName('policyDetails');
        this.isGroupAnnuity = await this.partySearchFactory.isOnlyGroupAnn();
        this.assetAccountUrl = policyDetails._links['assetaccountdetails'].href;
        if(this.isGroupAnnuity) {
            this.getAssetAccountDetails();
        }   
    }


    getAssetAccountDetails = async () => {
        this.assetAccountHeader = _.filter(Configuration.config.headers["multiHostUrlHeader"], function (o) { return o.alias == 'policyAssetAccounts' });
        await OcInfraModule.AppInjector.get(CommonService).getCallback(this.assetAccountUrl, this.assetAccountHeader[0].headers).subscribe(response => {
            let responseData = response['_links']['item'];
            this.mapDataToTable(responseData);
            OcInfraModule.AppInjector.get(ResourceService).updateCreateAliasName('policyAssetAccounts', this.assetAccountUrl, true);
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
        }, error => {
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
            OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage("Something went wrong please try again", 'Error', 0);
        });
    }

    mapDataToTable = (data: any) => {
        this.assetAccountData = data.map(item => {
            return item.summary;
        });
    }

}

