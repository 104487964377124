// import { NgxMatDateFormats,NGX_MAT_DATE_FORMATS } from '@angular-material-components/datetime-picker';
import { MatStepper } from '@angular/material';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { Component, OnInit, ViewEncapsulation, EventEmitter, Output, ViewChild, ElementRef } from '@angular/core';
import * as _ from 'lodash';
import { DollarCostAveragingComponent } from './dollar-cost-averaging/dollar-cost-averaging.component';
import { FundTransferInformationComponent } from './fund-transfer-information/fund-transfer-information.component';
import { FutureAllocationChangeComponent } from './future-allocation-change/future-allocation-change.component';
import { RebalancingComponent } from './rebalancing/rebalancing.component';
// import { RebalancingComponent } from './rebalancing/rebalancing.component';
// import {ShieldPerformanceLockComponent} from './shield-performance-lock/shield-performance-lock.component'


const ALIAS_KEY: String = 'policyDetails'

export const FUNDS_DATE_FORMATS = {
  parse: {
    dateInput: 'MM/DD/YYYY',
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};


@Component({
  selector: 'app-fundtransactions',
  templateUrl: './fundTransactionsComponent.html',
  styleUrls: ['fundTransactionsComponent.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: FUNDS_DATE_FORMATS },
    // { provide: NGX_MAT_DATE_FORMATS, useValue: FUNDS_DATETIME_FORMATS }
  ]
})

export class FundTransaction implements OnInit {

  stepCompletionIndicator: object = { "fundTransferInformation": false, "futureAllocationChange": false, "dollarCostAveraging": false };// "rebalancing": false, "dollarCostAveraging": false, "confirmation": false };
  alertMessages: any = [];
  receivedFundTransferDetails: any;
  allocationChangeValues: any;
  enabledTabs: string[] = [];
  dcaValues: any;
  rebalancingValues: any;
  fundTransactions: any = {};
  performanceLock: any;
  @ViewChild('stepper') stepper: MatStepper;
  @ViewChild('stepperDiv') stepperElementRef: ElementRef;

  @ViewChild(FundTransferInformationComponent) childFundTransfer: FundTransferInformationComponent;
  @ViewChild(FutureAllocationChangeComponent) childFutureAllocation: FutureAllocationChangeComponent;
  @ViewChild(RebalancingComponent) childRebalancing: RebalancingComponent;
  @ViewChild(DollarCostAveragingComponent) childDollarCostAveraging: DollarCostAveragingComponent;
  // @ViewChild(ShieldPerformanceLockComponent) childshieldperfromancelock: ShieldPerformanceLockComponent;



  ngOnInit() {    
  }

  scrollTop(e) {
    this.stepperElementRef.nativeElement.scrollIntoView();
  }

  // Handles the Green tick on tabs on successful submit
  tabClick(e) {
    this.stepCompletionIndicator["fundTransferInformation"] = this.childFundTransfer? this.childFundTransfer.isStepComplete(): false;
    this.stepCompletionIndicator["futureAllocationChange"]  = this.childFutureAllocation? this.childFutureAllocation.isStepComplete(): false;
    this.stepCompletionIndicator["rebalancing"] = this.childRebalancing? this.childRebalancing.isStepComplete() : false;
    this.stepCompletionIndicator["dollarCostAveraging"] = this.childDollarCostAveraging? this.childDollarCostAveraging.isStepComplete() : false;
    // this.stepCompletionIndicator["shieldperformancelock"] = this.childshieldperfromancelock? this.childshieldperfromancelock.isStepComplete() : false;
    //e.selectedIndex == 1 ? this.childFutureAllocation ? this.childFutureAllocation.isFundTransferSubmitted(): null : null;
   // e.selectedIndex == 2 ? this.childRebalancing ? this.childRebalancing.isAllocationChangeSubmitted() : null : null;
    // e.selectedIndex == 4 ? this.checkIfTransactionsSubmitted() : null;
  }

  checkIfTransactionsSubmitted() {
    let disableScript;
    disableScript = this.fundTransactions.fundTransfer || this.fundTransactions.allocationChanges || this.fundTransactions.rebalancing || 
    this.fundTransactions.dcaValues || this.fundTransactions.performanceLock ? false : true;
    this.fundTransactions.disableScript = disableScript;
    this.fundTransactions = Object.assign({}, this.fundTransactions);
  }

  //Displays GET api alerts
  addAlertsEvent(alertData) {
    if (alertData && alertData.alertMessages && alertData.alertMessages.length > 0) {
      let msgs = this.alertMessages.concat(alertData.alertMessages);
      this.alertMessages = _.uniqBy(msgs, 'message');
      if(this.alertMessages.length > 0)
      this.alertMessages = this.alertMessages.filter(item => item.message != 'No mapping rule matched');
    }
    let selectedTabIndex;
    if (alertData.isDisabled !== undefined && alertData.isDisabled !== null && alertData.isDisabled === false) {
      this.enabledTabs.push(alertData.tabName);
    }
    if(alertData && alertData.activeIndex)
    selectedTabIndex = alertData.activeIndex; 
  }

  toggleAlertMsg(event) {
    event.target.classList.toggle("alertMsg-overflow");
  }

  //Handles Data copy from Funds transfer tab
  handleCopyOfFundTransfer(fundTrasferData) {
    this.receivedFundTransferDetails = fundTrasferData;
    this.fundTransactions.fundTransfer = this.receivedFundTransferDetails;
    this.fundTransactions = Object.assign({}, this.fundTransactions);
  }

  getAllocationChangeValues(allocationChangeValues) {
    this.allocationChangeValues = allocationChangeValues;
    this.fundTransactions.allocationChanges = this.allocationChangeValues;
    this.fundTransactions = Object.assign({}, this.fundTransactions);
  }

  getRebalancingValues(rebalancingValues) {
    this.rebalancingValues = rebalancingValues;
    this.fundTransactions.rebalancing = this.rebalancingValues;
    this.fundTransactions = Object.assign({}, this.fundTransactions);
  }

   getDcaValues(dcaValues) {
     this.dcaValues = dcaValues;
     this.fundTransactions.dcaValues = this.dcaValues;
     this.fundTransactions = Object.assign({}, this.fundTransactions);
   }
}