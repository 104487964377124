import { Headers } from '@angular/http';
import { Component, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { ResourceService, OcInfraModule, Configuration } from '@diaas/ux-web';
import * as _ from 'lodash';
import { DatePipe } from '@angular/common';
import { AdvGrowlService } from 'primeng-advanced-growl';
import { CommonService } from '../../services/common.service';
import { ClaimNotificationDeceasedInformationComponent } from './claimNotificationDeceasedInformation/claim-notification-deceased-information.component';
import { AdditionalClaimInformationComponent } from './additionalClaimInformation/additional-claim-information.component';
import { PolicyPortfolioComponent } from './policyPortfolio/policy-portfolio.component';
import { ClaimPartyInformationComponent } from './claimPartyInformation/claim-party-information.component';
import { CreateDeathClaimSharedService } from '../../components/createDeathClaim/create-death-claim-shared.service';
@Component({
  selector: 'app-create-death-claims',
  templateUrl: './create-death-claim.component.html',
  styleUrls: ['create-death-claim.component.scss'],
})

export class CreateDeathClaimComponent implements OnInit {

  @ViewChild(ClaimNotificationDeceasedInformationComponent) childClaimNotification: ClaimNotificationDeceasedInformationComponent;
  @ViewChild(AdditionalClaimInformationComponent) childAdditionalClaimInformation: AdditionalClaimInformationComponent;
  @ViewChild(PolicyPortfolioComponent) childPolicyPortfolio: PolicyPortfolioComponent;
  @ViewChild(ClaimPartyInformationComponent) ClaimPartyInformationComponent: ClaimPartyInformationComponent;

  stepCompletionIndicator = {
    claimNotificationDeceased: false,
    additionalClaimInformation: false,
    policyPortfolio: false,
    claimPartyInformation: false
  }
  claimNotificationForm: any;
  claimNotificationStepValid: any;
  claimsData: any;
  claim_Category: any;
  payload: any;
  portfolioDeathClaimInfo: any;
  deathClaimNumber: any;
  personDetail: any;
  associateButtonDisable: boolean = false;
  isAnnuity: boolean;
  deathClaimCreated: boolean = false;
  tcaInterestRate:any;

  constructor(private datePipe: DatePipe, private commonService: CommonService,private createDeathClaimSharedSerice: CreateDeathClaimSharedService) {
  }

  ngOnInit() {
    this.getClaimsData();
    let details = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyDetails');
    this.isAnnuity = (details.data.product_type.includes('A') ? true : false);
    // Associate Call Status
    this.commonService.associateButtonStatus.subscribe((isAssociateButtonStatus: boolean) => {
      this.associateButtonDisable = isAssociateButtonStatus;
    });
  }

  ngOnDestroy() {
    OcInfraModule.AppInjector.get(CommonService).updateAssociateButtonStatus(false);
    this.createDeathClaimSharedSerice.associatedPolicies = [];
  }

  getClaimsData() {
    const resourceService = OcInfraModule.AppInjector.get(ResourceService);
    let claimListHeader = _.filter(Configuration.config.headers["multiHostUrlHeader"], function (o) { return o.alias == 'createClaim' });
    let policyDetails = resourceService.getResourceFromAliasName('policyDetails');
    delete claimListHeader[0].headers.role;
    delete claimListHeader[0].headers.statuaory_company_code;
    claimListHeader[0].headers.plan_code = policyDetails.data.plan_code;
    OcInfraModule.AppInjector.get(CommonService).optionCallback(policyDetails._links.claims.href, claimListHeader[0].headers).subscribe(response => {
      this.claimsData = response;
      OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
    }, error => {
      OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
      OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage("Something went wrong please try again", 'Error', 0);
    });
  }

  goForward(stepper: MatStepper) {
    this.stepCompletionIndicator.claimNotificationDeceased = true;
    stepper.next();
  }

  createDeathClaim(stepper: MatStepper) {
    OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false });
    let claimListHeader = _.filter(Configuration.config.headers["multiHostUrlHeader"], function (o) { return o.alias == 'createClaim' });
    let claimNotificationForm = this.childClaimNotification.claimNotificationForm;
    let additionalClaimInfoForm = this.childAdditionalClaimInformation.additionalclaiminfo;
    const resourceService = OcInfraModule.AppInjector.get(ResourceService);
    let policyDetails = resourceService.getResourceFromAliasName('policyDetails');
    const selectedRoleSummary = resourceService.getResourceFromAliasName('selectedRoles');
    const authResponse = resourceService.getResourceFromAliasName('authRes');
    let productInfo =OcInfraModule.AppInjector.get(CommonService).productInfo;
    claimListHeader[0].headers.plan_code = policyDetails.data.plan_code;
    claimListHeader[0].headers.company_code = productInfo.company_code;

    this.payload = {
      notificationMethod: claimNotificationForm.controls['notificationMethod'].value,
      claimType: claimNotificationForm.controls['claimType'].value,
      deceasedParty: claimNotificationForm.controls['deceasedParty'].value,
      deceasedFirstName: claimNotificationForm.controls['deceasedFirstName'].value,
      deceasedMiddleName: claimNotificationForm.controls['deceasedMiddleName'].value,
      deceasedLastName: claimNotificationForm.controls['deceasedLastName'].value,
      deceasedInsuredType: claimNotificationForm.controls['deceasedInsuredType'].value,
      deceasedGovtID: claimNotificationForm.controls['govtId'].value,
      deceasedDOB: this.datePipe.transform(claimNotificationForm.controls['dateOfBirth'].value, "MM/dd/yyyy"),
      dateOfDeath: this.datePipe.transform(claimNotificationForm.controls['dateOfDeath'].value, "MM/dd/yyyy"),
      mannerOfDeath: claimNotificationForm.controls['mannerOfDeath'].value,
      deathInUSA: claimNotificationForm.controls['deathInUSA'].value,
      funeralHomeAssignment: additionalClaimInfoForm.controls['funeralHomeAssignment'].value,
      beneficiaryPerson: additionalClaimInfoForm.controls['beneficiaryPerson'].value,
      beneficiaryUSResident: additionalClaimInfoForm.controls['beneficiaryUSResident'].value,
      beneficiaryEachReceiveMinimumAmt: additionalClaimInfoForm.controls['beneficiaryEachReceiveMinimumAmt'].value,
      countryOfDeath: claimNotificationForm.controls['countryOfDeath'].value,
      beneficiaryKnown: additionalClaimInfoForm.controls['beneficiaryKnownbyCaller'].value,
      beneficiaryName: additionalClaimInfoForm.controls['beneficiaryName'].value,
      estateProbated: additionalClaimInfoForm.controls['estateProbated'].value,
      maritalStatus: additionalClaimInfoForm.controls['maritalStatus'].value,
      spouseLiving: additionalClaimInfoForm.controls['spouseLiving'].value,
      spouseName: additionalClaimInfoForm.controls['spouseName'].value,
      spouseDateOfDeath: this.datePipe.transform(additionalClaimInfoForm.controls['spouseDateOfDeath'].value, "MM/dd/yyyy"),
      childrenNames: additionalClaimInfoForm.controls['childrenNames'].value,
      type: "Claims Notification",
      hostKey: selectedRoleSummary && selectedRoleSummary.data.hostKey ? selectedRoleSummary.data.hostKey : '',
      policyNumber: selectedRoleSummary && selectedRoleSummary.data.policyNumber ? selectedRoleSummary.data.policyNumber : policyDetails.data.policy_number,
      region_code: claimListHeader[0].headers.region_code,
      remote_user: claimListHeader[0].headers.remote_user,
      sessionId: authResponse && authResponse.data.contactSessionID ? authResponse.data.contactSessionID : '',
      callStart: authResponse && authResponse.data.callStart ? authResponse.data.callStart : '',
      clientId: selectedRoleSummary && selectedRoleSummary.data.clientId ? selectedRoleSummary.data.clientId : '',
      userId: OcInfraModule.AppInjector.get(CommonService).incontactId,
      issueDate: policyDetails.data.issue_date
    }
    if (this.claimsData._options.properties.claimCategory && this.claimsData._options.properties.claimCategory.oneOf) {
      for (let i = 0; i < this.claimsData._options.properties.claimCategory.oneOf.length; i++) {
        if (this.claimsData._options.properties.claimCategory.oneOf[i].enum[0] == additionalClaimInfoForm.controls['claimCategory'].value) {
          this.claim_Category = this.claimsData._options.properties.claimCategory.oneOf[i].enum[0];
        }
      }
      this.payload["claimCategory"] = this.claim_Category;
    }
    OcInfraModule.AppInjector.get(CommonService).postCallback(policyDetails._links.claims.href, this.payload, claimListHeader[0].headers).subscribe(response => {
      // Api call for getting portfolio death claim info
      this.tcaInterestRate = response;
      delete claimListHeader[0].headers.company_code;
      OcInfraModule.AppInjector.get(CommonService).getCallback(response._options.links[0].href, claimListHeader[0].headers).subscribe(response => {
        this.portfolioDeathClaimInfo = response;
        this.deathClaimNumber = this.portfolioDeathClaimInfo._links.item.claimSummary.claimNumber;
        OcInfraModule.AppInjector.get(CommonService).claimAssociateSuccess = false;
        OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
        this.childClaimNotification.disableForm();
        this.stepCompletionIndicator.additionalClaimInformation = true;
        this.deathClaimCreated = true;
        stepper.next();
      }, error => {
        OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
        this.childAdditionalClaimInformation.additionalclaiminfo.enable();
        this.stepCompletionIndicator.additionalClaimInformation = false;
        OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage("Something went wrong please try again", 'Error', 0);
      });
      OcInfraModule.AppInjector.get(AdvGrowlService).createSuccessMessage('Death claim created sucessfully', 'Success');
      this.stepCompletionIndicator.additionalClaimInformation = true;
    }, error => {
      OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
      this.childAdditionalClaimInformation.additionalclaiminfo.enable();
      error && error.message ? OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error.message, 'Error', 0) : OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage("Failed to submit request for Initiating Death Claim. Please try again.", 'Error', 0);
    });
  }

  updateDeathClaim() {
    OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false });
    let claimListHeader = _.filter(Configuration.config.headers["multiHostUrlHeader"], function (o) { return o.alias == 'createClaim' });
    let policyPortfolioForm = this.childPolicyPortfolio.policyPortfolioForm;
    const resourceService = OcInfraModule.AppInjector.get(ResourceService);
    let policyDetails = resourceService.getResourceFromAliasName('policyDetails');
    const selectedRoleSummary = resourceService.getResourceFromAliasName('selectedRoles');
    const authResponse = resourceService.getResourceFromAliasName('authRes');

    this.payload = {
      claimStatus: policyPortfolioForm.controls['claimStatus'].value,
      dateOfDeath: this.datePipe.transform(policyPortfolioForm.controls['dateOfDeath'].value, "MM/dd/yyyy"),
      mannerOfDeath: policyPortfolioForm.controls['mannerOfDeath'].value,
      countryOfDeath: policyPortfolioForm.controls['countryOfDeath'].value,
      claimCategory: policyPortfolioForm.controls['claimType'].value,
      hostKey: selectedRoleSummary && selectedRoleSummary.data.hostKey ? selectedRoleSummary.data.hostKey : '',
      policyNumber: selectedRoleSummary && selectedRoleSummary.data.policyNumber ? selectedRoleSummary.data.policyNumber : policyDetails.data.policy_number,
      region_code: claimListHeader[0].headers.region_code,
      remote_user: claimListHeader[0].headers.remote_user,
      sessionId: authResponse && authResponse.data.contactSessionID ? authResponse.data.contactSessionID : '',
      callStart: authResponse && authResponse.data.callStart ? authResponse.data.callStart : '',
      clientId: selectedRoleSummary && selectedRoleSummary.data.clientId ? selectedRoleSummary.data.clientId : '',
      userId: OcInfraModule.AppInjector.get(CommonService).incontactId
    }
    // Api call for updating portfolio death claim info
    OcInfraModule.AppInjector.get(CommonService).patchCallback(this.portfolioDeathClaimInfo._options.links[0].href, this.payload, claimListHeader[0].headers).subscribe(response => {
      OcInfraModule.AppInjector.get(CommonService).updateSaveButtonStatus(true);
      OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
      OcInfraModule.AppInjector.get(AdvGrowlService).createSuccessMessage(response.message, 'Success');
    }, error => {
      OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
      OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage("Failed to update Death Claim Info. Please try again.", 'Error', 0);
    });
  }

  claimNotificationData(data) {
    // in data we have notification/Deceased form info
    this.claimNotificationForm = data;
  }

  tabClick(e) {
    if (!this.deathClaimCreated) {
      this.claimNotificationStepValid = this.childClaimNotification.isStepComplete();
      this.stepCompletionIndicator["claimNotificationDeceased"] = this.claimNotificationStepValid;
    }
  }

  personDetails(data) {
    this.personDetail = data;
  }

  getClaimPartylist() {
    this.ClaimPartyInformationComponent.getClaimPartylist();
  }

}
