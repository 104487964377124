
export interface TableData {
    eff_date: string;
    process_date: string;
    transaction_code: string;
    transaction_type: string;
    status: string;
    amount: string;
};

export const DisplayedAnnuityContractColumns = [
    'eff_date', 
    'process_date',
    'transaction_code',
    'transaction_type',
    'status', 
    'amount'
];
