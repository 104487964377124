export type Rebalancing = {
    effective_date: string
    trans_type: string
    rebalancing_frequency_desc: string
    rebalance_start_date: string
    next_rebalance_date: string
    rebalancing_status: string
    rb_unscheduled_funds: ScheduledFund[]
    rb_scheduled_funds: ScheduledFund[]

    previous_rebalance_date: string
    business_closehour: number
    funding_option: string
    rebalancing_strategy: string
    previous_change_date: string
    final_rebalance_date: string

    next_business_day: string
    rebalancing_frequency: string 
    action_code: string

    host_contract: boolean

    _embedded: Embedded
    _links: any
    _options: Options
    retail_multi_product_indicator: boolean
    model_code_options: OneOfData[]

    model_code: string
    existing_segment: any
}

export type UnscheduledFund = {
    allocation_percent: string
    fund_number: number
    amount: string
}


export type Embedded = {
    status_report: StatusReport
}

export type StatusReport = {
    messages: Message[]
}

export type Message = {
    message: string
    severity: string
}

export type Options = {
    properties: Properties;
    links: any[]
}

export type Properties = {
    action_code: ActionCode
    rebalancing_frequency: any
}

export type ActionCode = {
    type: string
    oneOf: OneOf[]
}

export type OneOf = {
    title: string
    enum: string[]
}
export interface ScheduledFund {
    fund_percent: string
    fund_type: string
    fund_type_desc: string
    units: string
    allocation_percent: string
    number: number
    fund_value: string
    name: string
    unit_value: string
    interest_rate: string
    air_unit_value: number
    percent_of_total_contract: string
}


export interface OneOfData {
    value: string,
    label: string
}


export interface RelabancingPayload extends RelabancingPayloadAWD {
    action_code: string
    effective_date: any
    rebalance_start_date: any
    final_rebalance_date: any
    next_rebalance_date: any
    rebalancing_frequency: string
    rebalancing_frequency_desc: string
    rebalancing_status: string
    previous_change_date: any
    next_business_day: string
    confirm_override: string
    trans_type: string

    previous_rebalance_date: any

    funding_option: string
    business_closehour: number
    rebalancing_strategy: string


    rb_unscheduled_funds: ScheduledFund[]
    rb_scheduled_funds: ScheduledFund[]
    retail_multi_product_indicator: boolean

    model_code?: string
}

export const businessClosedContent = 'The stock market is closed and the transaction will be completed on next business day. Do you want to proceed ?'

export const terminateAlertContent = 'Success Terminate Rebalancing Processed Successfully';
export const createAlertContent = 'Rebalancing Change Processed Successfully';
export const modifyAlertContent = 'Success Modify Rebalancing Processed Successfully';


export interface AssetAccounts {
    _options: {}
    _links: LinksAssetAccount
}
export interface LinksAssetAccount {
    self: Self
    item: ItemAssetAccount[]
    up: Up
}

export interface Self {
    href: string
}

export interface ItemAssetAccount {
    summary: SummaryAsset
    href: string
}

export interface SummaryAsset {
    asset_account: string[]
    plan_code: string
    _links: any
    asset_account_names: string[]
}
export interface Up {
    href: string
}



export interface ClientProducts {
    _options: {}
    _links: LinksProducts
}

export interface OptionsClientProducts { }

export interface LinksProducts {
    self: Self
    item: ItemProducts[]
    up: Up
}

export interface ItemProducts {
    summary: SummaryProducts
    href: string
}

export interface SummaryProducts {
    plan_code: string
    product_code: string
    _links: any
    quote_effective_date: string
    product_type: string
}
export interface RelabancingPayloadAWD {
    sessionId: string;
    callStart: string;
    clientId: string;
    userId: string;
    policyNumber: string;
    company_code: string;
    region_code: string;
    remote_user: string;
    origin_app: string;
    incontactId: string;
}