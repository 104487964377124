import { Component, ElementRef, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { ResourceService, OcInfraModule, APICallerService, Configuration, OptionParams } from '@diaas/ux-web';
import { CommonService } from '../../services/common.service';
import { AdvGrowlService } from 'primeng-advanced-growl';
import { DatePipe } from '@angular/common';
import { AppDateAdapter, APP_DATE_FORMATS } from '../format-datepicker';
import {DateAdapter, MAT_DATE_FORMATS} from '@angular/material/core';

@Component({
  selector: 'app-loantabannuity',
  templateUrl: './loantabannuity.component.html',
  styleUrls: ['./loantabannuity.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  ]
})
export class LoantabannuityComponent implements OnInit {
  resourceService: any;
  policyDetails: any;
  effectiveDate: any;
  elementData: any;
  dateStatusRetail: boolean = false;
  dateStatusItems: any;
  dateStatus:any[];
  selectionChange: boolean = false;
  loan_status: any;
  loan_status_desc: any;
  loan_initiate_date: any;
  loansinfoRes: any;
  html: string;
  noRecords: boolean = true;
  effectiveMaxDate: any;
  loan_amount: any;
  effectiveMinDate = new Date(1900, 1, 1);

  constructor(public element: ElementRef,public commonService: CommonService,public datePipe: DatePipe) {
    this.elementData = this.element.nativeElement;
   }
   headers = { ...{wma_user: window.sessionStorage.getItem('username'), ...OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("search") }};

  ngOnInit() {
    this.dateStatusRetail = this.elementData.id == 'dateStatus' ? true : false;
    this.resourceService = OcInfraModule.AppInjector.get(ResourceService);
    this.policyDetails = this.resourceService.getResourceFromAliasName('policyDetails') ? this.resourceService.getResourceFromAliasName('policyDetails').response : null;
    this.dateStatusDropdown();
  }

  dateStatusDropdown() {
    let selfHref = this.policyDetails._links.self.href;
    let dateStatusHref = selfHref + '/loans/loanDateStatus';
    this.effectiveDate = new Date(this.policyDetails.aart_date);
    OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false });
    OcInfraModule.AppInjector.get(CommonService).getCallback(dateStatusHref, this.headers).subscribe(response => {
      this.dateStatusItems = response._links.item;
      if (this.dateStatusItems) {
        this.dateStatus = [];
        for (let i = 0; i < this.dateStatusItems.length; i++) {
          let loanInitiateDate=this.dateStatusItems[i].summary.loan_initiate_date;
          let loanStatusDesc=this.dateStatusItems[i].summary.loan_status_desc;
          let loanDateStatus=loanInitiateDate+'-'+loanStatusDesc;
          let loanAmount = this.dateStatusItems[i].summary.loan_amount;
          this.dateStatus.push({ value: loanDateStatus, viewValue: loanDateStatus+'-'+loanAmount});
        }
      }
    }, error => {
    if (error && error._embedded && error._embedded.status_report && error._embedded.status_report.consistent === false) {
        OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error._embedded.status_report.messages[0].message, 'Error', 0);
    }
  });
  }

  onDateStatusChange(e){
    let drpdwnObj;
        this.selectionChange = true;
        if (this.dateStatusItems) {
            for (let i = 0; i < this.dateStatusItems.length; i++) {
              let initialDatestatus = this.dateStatusItems[i].summary.loan_initiate_date + '-'+ this.dateStatusItems[i].summary.loan_status_desc;
              let initialLoanAmount = initialDatestatus+'-'+ this.dateStatusItems[i].summary.loan_amount;
                if (initialLoanAmount === e.value) {
                    this.effectiveDate = new Date(this.policyDetails.aart_date);
                    this.datePipe.transform(this.effectiveDate, "MM/dd/yyyy");
                    this.loan_status = this.dateStatusItems[i].summary.loan_status;
                    this.loan_status_desc = this.dateStatusItems[i].summary.loan_status_desc;
                    this.loan_initiate_date = this.dateStatusItems[i].summary.loan_initiate_date;
                    this.loan_amount = this.dateStatusItems[i].summary.loan_amount;
                }
            }
            drpdwnObj = { 'loanAmount': this.loan_amount, 'loanStatus': this.loan_status, 'loanDate':this.loan_initiate_date, 'loanStatusDesc':this.loan_status_desc,'loanInitiateDate':this.loan_initiate_date};
            if (this.dateStatusRetail) {
                Object.assign(OcInfraModule.AppInjector.get(CommonService).selectedGroupDropdownValues, { 'LOANS': drpdwnObj });
            }
        }
  }
  getLoansInfo() {
    this.selectionChange = false;
    const resourceService = OcInfraModule.AppInjector.get(ResourceService);
    let headers = { ...{ wma_user: window.sessionStorage.getItem('username'), client_id: OcInfraModule.AppInjector.get(CommonService).productInfo.client }, ...OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("search") };
    let policyDetails = resourceService.getResourceFromAliasName('policyDetails');
    let loanInfoUrl = policyDetails && policyDetails._links && policyDetails._links["loans/info"] ? policyDetails._links["loans/info"].href : null;
   let loanHistoryUrl = policyDetails && policyDetails._links && policyDetails._links["loans/loanhistoryAnnuites"] ? policyDetails._links["loans/loanhistoryAnnuites"].href : null;
    let optionParams = new OptionParams();
    optionParams.headers = OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("loanHistory");
    optionParams.alias = "loanTransactionHistory";
    let obj = OcInfraModule.AppInjector.get(CommonService).selectedGroupDropdownValues;
    this.headers['loanDate']= obj.LOANS.loanDate;
    this.headers['loanstatus']= obj.LOANS.loanStatus;
    this.headers['loanAmount']=obj.LOANS.loanAmount;
    OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false });
    OcInfraModule.AppInjector.get(CommonService).getCallback(loanInfoUrl, this.headers).subscribe(response => {
        this.loansinfoRes = response;
        Array.from(document.querySelectorAll('.loanschckbx input[type="checkbox"]:checked')).forEach(loansCheckBox => {
            const checkBox = loansCheckBox as HTMLInputElement;
            checkBox.click();
        });
        this.noRecords = !response || !Object.keys(response).length;
        OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
    }, error => {
        if (error && error._embedded && error._embedded.status_report && error._embedded.status_report.consistent === false) {
            OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error._embedded.status_report.messages[0].message, 'Error', 0);
        }
        OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
    });
    this.commonService.sendClickEvent();
   OcInfraModule.AppInjector.get(APICallerService).refresh(loanHistoryUrl, { 'alias': "loanHistory", 'headers': headers }).subscribe(refreshResponse => { });
  }

  testChange(e, selectedValue, selectedKey, columnName, valueType) {
    let checkedLoansObj = OcInfraModule.AppInjector.get(CommonService).checkedLoansObject;
    selectedValue = selectedValue === undefined ? "No data in admin system." : selectedValue;
    selectedValue = valueType == 'currency' ? '$' + selectedValue.replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,') : selectedValue;
    selectedValue = valueType == 'percentage' ? selectedValue + '%' : selectedValue;
    let groupLoansHtml = '', plancodeHtml = '', msgFields = ['Message'];
    let grpLoansObj = OcInfraModule.AppInjector.get(CommonService).selectedGroupDropdownValues;
    grpLoansObj['LOANS']['effectiveDate'] = this.datePipe.transform(this.effectiveDate, "MM/dd/yyyy");
    OcInfraModule.AppInjector.get(CommonService).selectedGroupDropdownValues = grpLoansObj;
    let selectedDrpdwnObj = OcInfraModule.AppInjector.get(CommonService).selectedGroupDropdownValues;
    let selectedDateStatus: any, loanStatus: any, effectiveDate: any, loanAmount: any;
    if (selectedDrpdwnObj['LOANS']) {
      selectedDateStatus = selectedDrpdwnObj['LOANS']['loanInitiateDate']+'_'+selectedDrpdwnObj['LOANS']['loanStatusDesc'];
       effectiveDate = selectedDrpdwnObj['LOANS']['effectiveDate'];
       loanAmount = selectedDrpdwnObj['LOANS']['loanAmount'];
    }
    let keyString = selectedDateStatus + '_'+ effectiveDate + '_' + loanAmount;
    if (e.checked) {
        let quoteLoansObj = {};
         if (this.loansinfoRes && (this.loansinfoRes[columnName] || this.loansinfoRes[columnName] == 0) && this.loansinfoRes[columnName] !== "") {
            if (!msgFields.includes(selectedKey)) {
                quoteLoansObj[selectedKey] = selectedValue;
            }
            else {
              quoteLoansObj[selectedKey] = 'No data in admin system.';
            }
        }
        else {
            quoteLoansObj[selectedKey] = 'No data in admin system.';
        }
        if (_.isEmpty(checkedLoansObj)) {
            checkedLoansObj['LOANS'] = {};
            checkedLoansObj['LOANS'][keyString] = quoteLoansObj;
        }
        else {
            if (checkedLoansObj['LOANS'].hasOwnProperty(keyString)) {
                checkedLoansObj['LOANS'][keyString] = { ...checkedLoansObj['LOANS'][keyString], ...quoteLoansObj };
            } else {
                let newPlanCodeObj = {};
                newPlanCodeObj[keyString] = quoteLoansObj;
                checkedLoansObj['LOANS'] = { ...checkedLoansObj['LOANS'], ...newPlanCodeObj };
            }
        }
    }
    else {
        if (Object.keys(checkedLoansObj['LOANS']).length) {
            if (checkedLoansObj['LOANS'][keyString] && Object.keys(checkedLoansObj['LOANS'][keyString]).length > 1) {
                delete checkedLoansObj['LOANS'][keyString][selectedKey];
            } else {
                delete checkedLoansObj['LOANS'][keyString];
                (Object.keys(checkedLoansObj['LOANS']).length) ? null : delete checkedLoansObj['LOANS'];
            }
        }
    }
    OcInfraModule.AppInjector.get(CommonService).checkedLoansObject = checkedLoansObj;
    if (checkedLoansObj['LOANS'] && Object.keys(checkedLoansObj).length) {
        let subKeysList = [];
        Object.keys(checkedLoansObj['LOANS']).forEach(key => {
            plancodeHtml += '<dt class="pull-left">Loan Initial Date:</dt><dd>&nbsp;' + key.split('_')[0] + '</dd>' + '<dt class="pull-left"> Date Status:</dt><dd>&nbsp;' + key.split('_')[1] + '</dd>' +'<dt class="pull-left"> Effective Date:</dt><dd>&nbsp;' + key.split('_')[2] + '</dd>';
            Object.keys(checkedLoansObj['LOANS'][key]).forEach(subKey => {
                if (subKeysList.includes(key)) {
                    groupLoansHtml += '<dl class="pad-lAr-10px mb-0"><dt class="pull-left">' + subKey + ':</dt><dd>&nbsp;' + checkedLoansObj['LOANS'][key][subKey] + '</dd></dl>';
                } else {
                    groupLoansHtml = '';
                    groupLoansHtml += '<dl class="pad-lAr-10px mb-0"><dt class="pull-left">' + subKey + ':</dt><dd>&nbsp;' + checkedLoansObj['LOANS'][key][subKey] + '</dd></dl>';
                    subKeysList.push(key);
                }
            })
            plancodeHtml += groupLoansHtml;
        });
    }
    this.html = plancodeHtml;
    let finalHtml = '';
    Object.keys(checkedLoansObj).length ? finalHtml = ' <span style="font-size:15px" class="pad-lAr-10px">POLICY LOANS</span><dl class="pad-lAr-10px">' + this.html + '</dl>' : finalHtml = '';
    OcInfraModule.AppInjector.get(CommonService).groupLoansHtml = finalHtml;
    OcInfraModule.AppInjector.get(CommonService).showRequestedDetails();
    localStorage.setItem("loanInfo", JSON.stringify(checkedLoansObj['LOANS']));
  }
}
