import { Component, OnInit, ViewChild } from '@angular/core';
import { Configuration, OcInfraModule, ResourceService } from '@diaas/ux-web';
import * as _ from 'lodash';
import { AdvGrowlService } from 'primeng-advanced-growl';
import { CommonService } from '../../services/common.service';
import { MatStepper } from '@angular/material';
import { LoanInfoLoanInitiationComponent } from './loan-info-loan-initiation/loan-info-loan-initiation.component';
import { QuoteLoanInitiationComponent } from './quote-loan-initiation/quote-loan-initiation.component';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { PaymentLoanInitiationComponent } from './payment-loan-initiation/payment-loan-initiation.component';
import { ConfirmationLoanInitiationComponent } from './confirmation-loan-initiation/confirmation-loan-initiation.component';

@Component({
    selector: 'app-loan-initiation',
    templateUrl: './loan-initiation.component.html',
    styleUrls: ['./loan-initiation.component.scss']
})
export class LoanInitiationComponent implements OnInit {
    loanInitiationData: any;
    loanInitiationPaymentData: any;
    quoteTabCompleted = true;

    authorizedRoles = ['owner', 'agent', 'agent assistant', 'Agent/Advisor Assistant'];
    isAuthorizedRole: boolean = false;

    @ViewChild('stepper') stepper: MatStepper;

    @ViewChild(QuoteLoanInitiationComponent)
    quoteLoan: QuoteLoanInitiationComponent;

    @ViewChild(LoanInfoLoanInitiationComponent)
    infoLoan: LoanInfoLoanInitiationComponent;

    @ViewChild(PaymentLoanInitiationComponent)
    paymentLoan: PaymentLoanInitiationComponent;

    @ViewChild(ConfirmationLoanInitiationComponent)
    confirmLoan: ConfirmationLoanInitiationComponent;

    quoteFormValid: boolean = false;
    infoFormValid: boolean = false;
    paymentFormValid: boolean = false;
    confirmFormValid: boolean = false;

    loanInitiationHeader: any;
    alertMessags: any[] = [];
    isMfaValidationDone: boolean = false;

    allForm: any[] = [];

    constructor(private commonService: CommonService) {
    }

    ngOnInit() {
        this.isMfaValidationDone = OcInfraModule.AppInjector.get(CommonService).mfaAuthorized;
        setTimeout(() => {

            this.getLoanInitiationData();

            let selectedRole = OcInfraModule.AppInjector.get(CommonService).selectedRole;
            this.isAuthorizedRole = this.authorizedRoles.includes(selectedRole);

            if (this.isAuthorizedRole) {
                this.getLoanInitiationPaymentData();
            }
        }, 0)

    }

    ngAfterViewInit() {
        this.isMfaValidationDone = OcInfraModule.AppInjector.get(CommonService).mfaAuthorized;
        setTimeout(() => {
            this.stepper.selectedIndex = 0;
            this.initFormValid();
        }, 0);
    }

    getLoanInitiationData() {
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        this.loanInitiationHeader = _.filter(Configuration.config.headers["multiHostUrlHeader"], function (o) { return o.alias == 'loanInitiation' });
        let policyDetails = resourceService.getResourceFromAliasName('policyDetails');
        OcInfraModule.AppInjector.get(CommonService).getCallback(policyDetails._links['loanQuote/information'].href, this.loanInitiationHeader[0].headers).subscribe(response => {
            this.loanInitiationData = response;
            const mecIndicator = { mec_indicator: policyDetails.data.mec_indicator };
            Object.assign(this.loanInitiationData, mecIndicator);

            if (response._embedded && response._embedded.status_report) {
                this.alertMessags = response._embedded.status_report.messages;
                this.alertMessags.length > 0 ? this.isAuthorizedRole = false : this.isAuthorizedRole = true;
            }
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
        }, error => {

            if (error && error._embedded && error._embedded.status_report && error._embedded.status_report.consistent === false) {
                OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error._embedded.status_report.messages[0].message, 'Error', 0);
            }

            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
            OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage("Something went wrong please try again", 'Error', 0);
        });
    }

    getLoanInitiationPaymentData() {
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let policyDetails = resourceService.getResourceFromAliasName('policyDetails');
        let billing = resourceService.getResourceFromAliasName('billingInfo');
        let bill_option = (billing.response.bill_option).toLowerCase();
        let url = policyDetails._links['loanQuote/payment'].href;
        OcInfraModule.AppInjector.get(CommonService).getCallback(url, this.loanInitiationHeader[0].headers).subscribe(response => {
            this.loanInitiationPaymentData = response;
            Object.assign(this.loanInitiationPaymentData, { bill_option: bill_option });
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
        }, error => {
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
            OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage("Something went wrong please try again", 'Error', 0);
        });
    }

    initFormValid = () => {
        this.quoteFormValid = this.quoteLoan.quoteForm.valid;
        this.infoFormValid = this.infoLoan.infoForm.valid;
        this.paymentFormValid = this.paymentLoan.paymentForm.valid;
        this.confirmFormValid = this.confirmLoan.confirmForm.valid;
    };

    onSelectionChange = ($event: StepperSelectionEvent) => {
        this.commonService.stepperEmit($event.selectedIndex);
        this.initFormValid();

        let allFormsValue = {
            ...this.quoteLoan.quoteForm.value,
            ...this.infoLoan.infoForm.value,
            ...this.paymentLoan.paymentForm.value,
          };
          let formValid = false;

          if(this.quoteLoan.quoteForm.valid && this.infoLoan.infoForm.valid && this.paymentLoan.paymentForm.valid) {
            formValid = true;
          }
          this.commonService.allFormsEmit({values: allFormsValue, valid: formValid});
    };

    
  toggleAlertMsg = (event: any) => {
    event.target.classList.toggle("alertMsg-overflow");
  }

    ngOnDestroy() {
        setTimeout(() => {
            this.commonService.stepperEmit(0);
        }, 0);
    }
}