import { Component, Inject, OnInit } from '@angular/core';
import { APICallerService, OcInfraModule, OptionParams, ResourceService } from '@diaas/ux-web';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import * as _ from 'lodash';
import { CommonService } from '../../services/common.service';

@Component({
  selector: 'app-check-historydetail',
  templateUrl: './check-historydetail.component.html',
  styleUrls: ['./check-historydetail.component.scss']
})
export class CheckHistorydetailComponent implements OnInit {
  checkHistoryDetailsData:any;
  checkHistoryDetailsRes: any;
  isDisable:boolean=true

  constructor(public commonService: CommonService,public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any) { 
    
  }

  ngOnInit() {
      this.getCheckhistoryDetailsData(this.data.params);
  
      
    }

  ngAfterViewInit() {
    
  }

  getCheckhistoryDetailsData = async (params: any) => {
    const resourceService = OcInfraModule.AppInjector.get(ResourceService);
    let policyDetails = resourceService.getResourceFromAliasName('policyDetails');
    let checkHistoryDetailsUrl = this.commonService.checkHistoryUrl.summary.links.filter(x=>x.rel=="search_CDS_Details")[0].href;
    
    if(policyDetails.data.product_type === 'A3' || policyDetails.data.product_type ==='A4'){
      checkHistoryDetailsUrl = this.commonService.checkHistoryUrl.summary.links.filter(x=>x.rel=="search_CDS_Details")[0].href;
      checkHistoryDetailsUrl = checkHistoryDetailsUrl.slice(0, -3);
    } 
    let [url] = this.commonService.getHrefUrlWithParams(checkHistoryDetailsUrl);
    let optionParams = new OptionParams();
    optionParams.alias = "checkHistory";
    optionParams.headers = OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("search");

    optionParams.params = params;
     

    this.commonService.getCallback(url, optionParams.headers, params).subscribe(response => {
          this.getCheckHistoryDetails(response,params);
      },err=>{
  
        });
    }


  getCheckHistoryDetails(response, params) {
    let isItem = OcInfraModule.AppInjector.get(CommonService).getNested(response, '_links', 'item');
    if (isItem && !_.isEmpty(isItem)) {
      this.checkHistoryDetailsRes = response;
      let accountNumber = response._links.item[0].eftBankAccount;
      let maskedAccountNumber=(this.MaskCharacter(accountNumber, 'X', 4));
      response._links.item[0].eftBankAccount= maskedAccountNumber;
      this.checkHistoryDetailsData = response._links.item[0];
    }
}
// Function for masking the character 
MaskCharacter(str, mask, n) {
  return ('' + str).slice(0, -n)
      .replace(/./g, mask)
      + ('' + str).slice(-n);
}
}