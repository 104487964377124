import { TableColumn } from "../custom-table/custom-table.constant";

export interface HistoricalCoiData {
    effective_date: string;
    processed_date: string;
    transaction_type_description: string;
    amount: string;
}

export const historicalCoicolumnsDef = (currencyPipe): TableColumn[] => {

    return [
        {
            columnDef: 'effective_date',
            header: 'Effective Date',
            cell: (element: any) => `${element.effective_date}`,
            show: true,
            isSortable: true
        },
        {
            columnDef: 'processed_date',
            header: 'Processed Date',
            cell: (element: any) => `${element.processed_date}`,
            show: true,
        },
        {
            columnDef: 'transaction_type_description',
            header: 'Transaction Type',
            cell: (element: any) => `${element.transaction_type_description}`,
            show: true,
        },
        {
            columnDef: 'amount',
            header: 'Amount',
            cell: (element: any) => `${currencyPipe.transform(element.amount)}`,
            show: true,
            
        }
    ]
}
