import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CommonService, retryOperation } from '../../services/common.service';
import { OcInfraModule, ResourceService } from '@diaas/ux-web';
import { PartySearchFactory } from '../../factories/partySearchFactory';

@Component({
    selector: 'app-lazy-load',
    templateUrl: './lazy-load.component.html',
    styleUrls: ['./lazy-load.component.scss'],
})
export class LazyLoadComponent implements OnInit, AfterViewInit {

    retryDelay = 5000;
    retryAttempts = 5;
    private partySearchFactory = new PartySearchFactory()

    incomingMoneyTabId: string = '';
    constructor() {}

    ngOnInit() {
        // this.getPolicyDetailsPromise();
    }

    ngAfterViewInit() {
        
    }

    // This code is for only Lazy load Implementation - START
    getPolicyDetailsPromise = async () => {
        await OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false })
        await retryOperation(this.getPolicyDetails, this.retryDelay, this.retryAttempts)
            .then((res) => {
                    this.lazyloadFinancialHistoryTabs();
            })
            .catch((err) => {});
    };

    getPolicyDetails = () => {
        let policyDetails = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyDetails');
        let billingInfo = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('billingInfo');
        let policyCoverage = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyCoverage');
        let financialHistoryTab = document.querySelectorAll(".financialHistoryTab .mat-tab-labels > div.mat-tab-label").length;
        if (policyDetails && financialHistoryTab > 0 && (billingInfo || policyCoverage)) {
            return Promise.resolve(policyDetails);
        } else {
            return Promise.reject('No data');
        }
    }

    // If there are any changes in Policyinformation tabs - we need to check here also
    lazyloadPolicyDetailsTabs = async () => {
        Array.from(document.querySelectorAll(".policyDetailsTab .mat-tab-labels > div.mat-tab-label")).forEach(async (policyDetailsTab, index) => {
            let tabElement = <HTMLElement>policyDetailsTab;
            const tabId = tabElement.getAttribute("id");
            if ((index == 1 && !this.partySearchFactory.isAnnuity()) || (index == 2 && !this.partySearchFactory.showTraditionalAndAnnutiesNonGroup()) || (index == 3 && !this.partySearchFactory.exceptGroupAnn_AllOthers()) || (index == 4 && !this.partySearchFactory.isOnlyGroupAnn()) || (index == 5 && !this.partySearchFactory.isNotAnnuity()) || (index == 6 && !this.partySearchFactory.isAnnuity()) || (index == 7 && !this.partySearchFactory.isOnlyGroupAnn()) || (index == 11 && !this.partySearchFactory.isNotAnnuityorUL()) || (index == 12 && !this.partySearchFactory.isAnnuityorUL())) {
                (<HTMLElement>policyDetailsTab).style.display = 'none';
                tabElement.setAttribute("tab-display", "none");
            } else {
                (<HTMLElement>policyDetailsTab).style.display = 'block';
                tabElement.setAttribute("tab-display", "block");
            }
        });
    }

    // If there are any changes in Financial History tabs - we need to check here also
    lazyloadFinancialHistoryTabs = async () => {
        Array.from(document.querySelectorAll(".financialHistoryTab .mat-tab-labels > div.mat-tab-label")).forEach(async (financialHistoryTab, index) => {
            let tabElement = <HTMLElement>financialHistoryTab;
            const tabId = tabElement.getAttribute("id");
            if ((index == 1 && !this.partySearchFactory.isAnnuity()) || (index == 0 && !this.partySearchFactory.hideNonAnnuityFields()) || (index == 2 && !this.partySearchFactory.isAnnuity()) || (index == 5 && !this.partySearchFactory.isAnnuity()) || (index == 6 && !this.partySearchFactory.hideNonAnnuityAndUlFields()) || (index == 7 && !this.partySearchFactory.isAnnuityorUL()) || (index == 8 && !this.partySearchFactory.isNotUL()) || (index == 9 && !this.partySearchFactory.isAnnuity())) {
                (<HTMLElement>financialHistoryTab).style.display = 'none';
                tabElement.setAttribute("tab-display", "none");
            } else {
                (<HTMLElement>financialHistoryTab).style.display = 'block';
                tabElement.setAttribute("tab-display", "block");
                if (index == 1 && tabElement.getAttribute("tab-display") == 'block') {
                    this.incomingMoneyTabId = tabId;
                    document.getElementById(this.incomingMoneyTabId).click();
                }
            }
        });

        await OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false })
    }

}
