import { Component, ElementRef, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { ResourceService, OcInfraModule, APICallerService, Configuration, OptionParams } from '@diaas/ux-web';
import { CommonService } from '../../services/common.service';
import { AdvGrowlService } from 'primeng-advanced-growl';
import { DatePipe } from '@angular/common';
import { AppDateAdapter, APP_DATE_FORMATS } from '../format-datepicker';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';


@Component({
  selector: 'app-group-values',
  templateUrl: './groupPolicyValuesTab.component.html',
  styleUrls: ['./groupPolicyValuesTab.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  ]
})
export class GroupPolicyValuesTab implements OnInit {
  isValuesTab: boolean;
  policyDetails: any;
  resourceService: any;
  getPlanCodeUrl: any;
  getAssetAccountUrl: any;
  productTypes: any[];
  effectiveDate: Date;
  elementData: any;
  accTypeItems: any;
  headers = { ...{ wma_user: window.sessionStorage.getItem('username'), client_id: OcInfraModule.AppInjector.get(CommonService).productInfo.client }, ...OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("search") };
  accountType: string;
  assetAccountTypes: any[];
  productType: any;
  policyValues: any;
  effectiveMaxDate = new Date();
  statusMsg: string = "";
  html: string;
  selectionChange: boolean = true;
  isLoading: boolean = false;

  constructor(public element: ElementRef, public commonService: CommonService, public datePipe: DatePipe) {
    this.elementData = this.element.nativeElement
  }

  ngOnInit() {
    setTimeout(() => {
 
      this.isValuesTab = (this.elementData.id == 'valuesDropdowns') ? true : false;
      this.resourceService = OcInfraModule.AppInjector.get(ResourceService);
      this.policyDetails = this.resourceService.getResourceFromAliasName('policyDetails') ? this.resourceService.getResourceFromAliasName('policyDetails').response : null;
      this.getPlanCodeUrl = this.policyDetails && this.policyDetails._links && this.policyDetails._links.clientproducts ? this.policyDetails._links.clientproducts.href : null;
      this.getAssetAccountUrl = this.policyDetails && this.policyDetails._links && this.policyDetails._links.clientassetaccounts ? this.policyDetails._links.clientassetaccounts.href : null;
      this.initializeClientProducts();
      this.initializeClientAssetAccounts();
      this.selectionChange = true;
          
    },0);
  }

  initializeClientProducts(){

    if(OcInfraModule.AppInjector.get(CommonService).getClientProducts() ==null){

      OcInfraModule.AppInjector.get(CommonService).getCallback(this.getPlanCodeUrl, this.headers).subscribe(response => {
        let prodTypeItems = response._links.item;
        if (prodTypeItems) {
          this.productTypes = [];
          for (let i = 0; i < prodTypeItems.length; i++) {
            this.productTypes.push({ value: prodTypeItems[i].summary.plan_code, viewValue: prodTypeItems[i].summary.plan_code });
          }
          this.effectiveDate = new Date(prodTypeItems[0].summary.quote_effective_date);
        }
        this.onDateChange(this.effectiveDate);
        }, error => {
          if (error && error._embedded && error._embedded.status_report && error._embedded.status_report.consistent === false) {
            OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error._embedded.status_report.messages[0].message, 'Error', 0);
          }
      });
    }else{
      this.processClientProducts(OcInfraModule.AppInjector.get(CommonService).getClientProducts());
    }
  }


  initializeClientAssetAccounts(){
    if(OcInfraModule.AppInjector.get(CommonService).getClientAssetAccounts() ==null){
      OcInfraModule.AppInjector.get(CommonService).getCallback(this.getAssetAccountUrl, this.headers).subscribe(response => {
        this.accTypeItems = response._links.item;
        OcInfraModule.AppInjector.get(CommonService).clientAssetAccounts = response._links.item;
      }, error => {
        if (error && error._embedded && error._embedded.status_report && error._embedded.status_report.consistent === false) {
          OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error._embedded.status_report.messages[0].message, 'Error', 0);
        }
      });
      
    }else{
      this.accTypeItems = OcInfraModule.AppInjector.get(CommonService).getClientAssetAccounts();
    }
  }
  
  processClientProducts(prodTypeItems){
    if (prodTypeItems) {
      this.productTypes = [];
      for (let i = 0; i < prodTypeItems.length; i++) {
        this.productTypes.push({ value: prodTypeItems[i].summary.plan_code, viewValue: prodTypeItems[i].summary.plan_code });
      }
      this.effectiveDate = new Date(prodTypeItems[0].summary.quote_effective_date);
    }
    this.onDateChange(this.effectiveDate);
  }

  onDateChange(date) {
    if(this.accountType){
      this.selectionChange = false;
    }
    OcInfraModule.AppInjector.get(CommonService).quoteDate = this.datePipe.transform(date, "MM/dd/yyyy");
  }

  onProductTypeChange(e) {
    this.accountType = '';
    this.selectionChange = true;
    if (this.accTypeItems) {
      this.assetAccountTypes = [];
      for (let i = 0; i < this.accTypeItems.length; i++) {
        if (this.accTypeItems[i].summary.plan_code === e.value) {
          if (this.accTypeItems[i].summary.asset_account_names) {
            for (let j = 0; j < this.accTypeItems[i].summary.asset_account_names.length; j++) {
              this.assetAccountTypes.push({ value: this.accTypeItems[i].summary.asset_account_names[j], viewValue: this.accTypeItems[i].summary.asset_account_names[j] });
            }
          }
        }
      }
    }
  }

  onAssetAccountChange(e) {
    let selectedAssetAccount;
    let drpdwnObj;
    this.selectionChange = false;
    if (this.accountType == "ALL ASSET ACCOUNTS") {
      selectedAssetAccount = '**';
    } else {
      selectedAssetAccount = this.accountType.substring(0, this.accountType.indexOf(' '));
    }
    drpdwnObj = { 'planCode': this.productType, 'assetAccount': selectedAssetAccount, 'accountType': this.accountType };
    if (this.isValuesTab) {
      Object.assign(OcInfraModule.AppInjector.get(CommonService).selectedGroupDropdownValues, { 'VALUES': drpdwnObj });
    }
  }

  quoteGroupValues() {
    const resourceService = OcInfraModule.AppInjector.get(ResourceService);
    let policyDetails = resourceService.getResourceFromAliasName('policyDetails');
    let quoteDate = OcInfraModule.AppInjector.get(CommonService).quoteDate;
    let selectedDrpdwnObj = OcInfraModule.AppInjector.get(CommonService).selectedGroupDropdownValues;
    let selectedPlanCode;
    let selectedAssetAccount;
    this.isLoading = true;
    if (selectedDrpdwnObj['VALUES']) {
      selectedPlanCode = selectedDrpdwnObj['VALUES']['planCode'];
      selectedAssetAccount = selectedDrpdwnObj['VALUES']['assetAccount'];
    }
    if (quoteDate == "") {
      quoteDate = new Date().toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' });
    }
    let isDateCorrect = this.validateDate(quoteDate);
    if (isDateCorrect) {
      let grpValuesObj = OcInfraModule.AppInjector.get(CommonService).selectedGroupDropdownValues;
      grpValuesObj['VALUES']['effectiveDate'] = quoteDate;
      OcInfraModule.AppInjector.get(CommonService).selectedGroupDropdownValues = grpValuesObj;
      let url = policyDetails && policyDetails._links && policyDetails._links.financialvalues && policyDetails._links.financialvalues.href;
      let optionParams = new OptionParams();
      optionParams.headers = { ...{ plan_id: selectedPlanCode, asset_account: selectedAssetAccount }, ...OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("policyValues") };
      optionParams.params = [{ 'key': "quoteEffectiveDate", value: quoteDate }];
      this.commonService.sendQuoteValueClickEvent(quoteDate);
      OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false });
      OcInfraModule.AppInjector.get(APICallerService).refresh(url, optionParams).subscribe(res => {
        this.selectionChange = true;
        this.policyValues = res.response;
        this.statusMsg = "";
        if (res && res.response._embedded && res.response._embedded.status_report) {
          this.policyValues._embedded.status_report.messages.forEach(msgObj => {
            this.statusMsg += msgObj.message + "<br/>";
          });
        } else {
          this.statusMsg = "";
        }
        Array.from(document.querySelectorAll('.valueschckbx input[type="checkbox"]:checked')).forEach(valuesCheckBox => {
          const checkBox = valuesCheckBox as HTMLInputElement;
          this.isLoading = false;
          checkBox.click();
        });
        this.isLoading = true;
        OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
      }, error => {
        if (error && error._embedded && error._embedded.status_report && error._embedded.status_report.consistent === false) {
          OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error._embedded.status_report.messages[0].message, 'Error', 0);
        }
        OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
      });
    }
    else {
      this.selectionChange = true;
      OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage("Incorrect date selected for quote.", 'Error', 0);
      document.getElementById("policy-values-quote-effective-date-date_input")["value"] = quoteDate;
    }
  }

  validateDate(testdate) {
    let date_regex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
    return date_regex.test(testdate);
  }

  getCheckedValues(e, selectedValue, selectedKey, columnName, valueType) {
    if (!this.isLoading) {
      e.preventDefault();
      return;
    } else {
      let checkedValuesObj = OcInfraModule.AppInjector.get(CommonService).checkedValuesObject;
      selectedValue = selectedValue === undefined ? "No data in admin system." : selectedValue;
      selectedValue = valueType == 'currency' ? '$' + selectedValue.replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,') : selectedValue;
      selectedValue = valueType == 'percentage' ? selectedValue + '%' : selectedValue;
      let groupValuesHtml = '', plancodeHtml = '', msgFields = ['Message'];
      let grpValuesObj = OcInfraModule.AppInjector.get(CommonService).selectedGroupDropdownValues;
      let quoteDate = OcInfraModule.AppInjector.get(CommonService).quoteDate;
      grpValuesObj['VALUES']['effectiveDate'] = this.datePipe.transform(quoteDate, "MM/dd/yyyy");
      OcInfraModule.AppInjector.get(CommonService).selectedGroupDropdownValues = grpValuesObj;
      let selectedDrpdwnObj = OcInfraModule.AppInjector.get(CommonService).selectedGroupDropdownValues;
      let selectedPlanCode: any, selectedAssetAccount: any, effectiveDate: any;
      if (selectedDrpdwnObj['VALUES']) {
        selectedPlanCode = selectedDrpdwnObj['VALUES']['planCode'];
        selectedAssetAccount = selectedDrpdwnObj['VALUES']['accountType'];
        effectiveDate = selectedDrpdwnObj['VALUES']['effectiveDate'];
      }
      selectedAssetAccount = selectedAssetAccount == '' ? 'No data in admin system.' : selectedAssetAccount;
      let keyString = selectedPlanCode + '_' + selectedAssetAccount + '_' + effectiveDate;
      if (e.checked) {
        let quoteValuesObj = {};
        if (this.policyValues && (this.policyValues[columnName] || this.policyValues[columnName] == 0) && this.policyValues[columnName] !== "") {
          if (!msgFields.includes(selectedKey)) {
            quoteValuesObj[selectedKey] = selectedValue;
          }
          else {
            quoteValuesObj[selectedKey] = 'No data in admin system.';
          }
        }
        else {
          if (msgFields.includes(selectedKey)) {
            quoteValuesObj[selectedKey] = this.statusMsg ? this.statusMsg : 'No data in admin system.';
          }
          else {
            quoteValuesObj[selectedKey] = 'No data in admin system.';
          }
        }
        if (_.isEmpty(checkedValuesObj)) {
          checkedValuesObj['VALUES'] = {};
          checkedValuesObj['VALUES'][keyString] = quoteValuesObj;
        }
        else {
          if (checkedValuesObj['VALUES'].hasOwnProperty(keyString)) {
            checkedValuesObj['VALUES'][keyString] = { ...checkedValuesObj['VALUES'][keyString], ...quoteValuesObj };
          } else {
            let newPlanCodeObj = {};
            newPlanCodeObj[keyString] = quoteValuesObj;
            checkedValuesObj['VALUES'] = { ...checkedValuesObj['VALUES'], ...newPlanCodeObj };
          }
        }
      }
      else {
        if (Object.keys(checkedValuesObj['VALUES']).length) {
          if (checkedValuesObj['VALUES'][keyString] && Object.keys(checkedValuesObj['VALUES'][keyString]).length > 1) {
            delete checkedValuesObj['VALUES'][keyString][selectedKey];
          } else {
            delete checkedValuesObj['VALUES'][keyString];
            (Object.keys(checkedValuesObj['VALUES']).length) ? null : delete checkedValuesObj['VALUES'];
          }
        }
      }
      OcInfraModule.AppInjector.get(CommonService).checkedValuesObject = checkedValuesObj;
      if (checkedValuesObj['VALUES'] && Object.keys(checkedValuesObj).length) {
        let subKeysList = [];
        Object.keys(checkedValuesObj['VALUES']).forEach(key => {
          plancodeHtml += '<dt class="pull-left"> Product:</dt><dd>&nbsp;' + key.split('_')[0] + '</dd>' + '<dt class="pull-left"> Asset Account:</dt><dd>&nbsp;' + key.split('_')[1] + '</dd>' + '<dt class="pull-left"> Effective Date:</dt><dd>&nbsp;' + key.split('_')[2] + '</dd>';
          Object.keys(checkedValuesObj['VALUES'][key]).forEach(subKey => {
            if (subKeysList.includes(key)) {
              groupValuesHtml += '<dl class="pad-lAr-10px mb-0"><dt class="pull-left">' + subKey + ':</dt><dd>&nbsp;' + checkedValuesObj['VALUES'][key][subKey] + '</dd></dl>';
            } else {
              groupValuesHtml = '';
              groupValuesHtml += '<dl class="pad-lAr-10px mb-0"><dt class="pull-left">' + subKey + ':</dt><dd>&nbsp;' + checkedValuesObj['VALUES'][key][subKey] + '</dd></dl>';
              subKeysList.push(key);
            }
          })
          plancodeHtml += groupValuesHtml;
        });
      }
      this.html = plancodeHtml;
      let finalHtml = '';
      Object.keys(checkedValuesObj).length ? finalHtml = ' <span style="font-size:15px" class="pad-lAr-10px">POLICY VALUES</span><dl class="pad-lAr-10px">' + this.html + '</dl>' : finalHtml = '';
      OcInfraModule.AppInjector.get(CommonService).groupValuesHtml = finalHtml;
      OcInfraModule.AppInjector.get(CommonService).showRequestedDetails();
      localStorage.setItem("policyValues", JSON.stringify(checkedValuesObj['VALUES']));
    }
  }
}