import { Component, OnInit } from '@angular/core';
import { OcInfraModule } from '@diaas/ux-web';
import { CommonService } from '../../services/common.service';

@Component({
    selector: 'app-eobDetails',
    templateUrl: './eobDetails.component.html',
    styleUrls: ['./eobDetails.component.scss']
})
export class EobDetailsComponent implements OnInit {
    eobMessages: any;
    netBenefitPayment: any;
    showRecords: boolean = false;

    constructor() { }

    ngOnInit() {
        let response = OcInfraModule.AppInjector.get(CommonService).eobDetails;
        this.eobMessages = response && response.data && response.data.eobMessages || [];
        this.netBenefitPayment = OcInfraModule.AppInjector.get(CommonService).convertToCurrency(response && response.data && response.data.netBenefitPayment || 0);
    }

    onShowRecords() {
        this.showRecords = !this.showRecords;
    }

    isLetter(str) {
        return str.length === 1 && str.match(/[a-z]/i);
    }

    trackByIndex = (index: number): number => {
        return index;
    }

} 