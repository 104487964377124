import { MatCheckboxChange } from '@angular/material/checkbox';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { OcInfraModule, ResourceService } from '@diaas/ux-web';
import { CommonService } from '../../../../services/common.service';
import { HttpParams } from '@angular/common/http';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ItemAssetAccount,QuoteFormFieldModel, ItemProducts, sectionOneFormFields, sectionOneLifeFormFields, sectionTwoFormFields, sectionTwoLifeFormFields,quoteSectionOneId, sectionOneFormMRPSFields, sectionTwoFormMRPSFields} from './full-surrender-quote.constant';
import * as moment from 'moment';
import { PartySearchFactory } from '../../../../factories/partySearchFactory';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { AppDateAdapter, APP_DATE_FORMATS } from '../../../format-datepicker';

export enum SurrenderModelEnum {
    EffectiveDate = 'Effective Date',
    AccumulatedCashValue = 'Accumulated Cash Value',
    SurrenderValue = 'Surrender Value',
    SurrenderPenalty = 'Surrender Penalty',
    SurrenderFreeAmount = 'Surrender Free Amount',
    MinimumSurrenderBenefit = 'Minimum Surrender Benefit',
    MVAAmount = 'MVA Amount',
    RedemptionFee = 'Redemption Fee',
    TotalAnnuityValue = 'Total Annuity Value',
    ABAFundValue = 'ABA Fund Value',
    LoanPayoffValue = 'Loan Payoff Value',
    StateWithholding = 'State Withholding',
    MunicipalWithholding = 'Municipal Withholding',
    FederalWithholding = 'Federal Withholding',
    PremiumTax = 'Premium Tax'
}

export interface SelectedFormValue {
    id: string
    label: string
    value: string
    checked?: string
    pipe?: string
    unique?: string
    key?: string
}


@Component({
    selector: 'app-full-surrender-quote',
    templateUrl: './full-surrender-quote.component.html',
    styleUrls: ['./full-surrender-quote.component.scss'],
    providers: [
        { provide: DateAdapter, useClass: AppDateAdapter },
        { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
      ]
})
export class FullSurrenderQuoteComponent implements OnInit {

    @Input() withdrawlSurrenderType: any;
    @Input() currentPolicyResourceURL: String;
    @Input() headers: Object;
    @Output() quoteValuesToEmit = new EventEmitter<Object>();
    @Output() planIDToEmit = new EventEmitter<Object>();

    @Input() surrenderDetails: any;
    @Input() isLife: boolean;

    quotesAPISource: Object = { "PARTIAL_SURRENDER": "/values/partialSurrender", "FULL_SURRENDER": "/surrender/full", "DIVIDEND_WITHDRAWAL": "/values/diviendWithdrawal" }

    effectivedate: any;
    effectiveMaxDate: any;

    quoteForm: FormGroup;
    quoteInfoFields!: QuoteFormFieldModel[];
    quoteValueFields!: QuoteFormFieldModel[];
    allFormValues: QuoteFormFieldModel[] = [];
    selectedFormValues: SelectedFormValue[] = [];
    formattedInfoFields: any = [];
    formattedValueFields: any = [];
    isTerm: boolean;
    policyDetails: any;
    isGroupMrpsContract: boolean;    
    clientProductsUrl: any;
    clientProducts: any;
    clientProductItems: { label: any; value: any; }[];
    plan_id: any;
    isHostContract: boolean = false;
    assetAccounts: ItemAssetAccount[];
    assetAccountItems: any;
    assetAccountsUrl: string = '';
    isMrpsContract: boolean = false;
    isGroupProduct: boolean;
    isHostGroupProduct: boolean = false;
    effectiveMinDate = new Date(1900, 1, 1);


    constructor(private datePipe: DatePipe, private currencyPipe: CurrencyPipe, private formBuilder: FormBuilder, private commonService: CommonService, private partySearchFactory: PartySearchFactory) { }

    ngOnInit() {
        this.policyDetails = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyDetails') ? OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyDetails').response : null;
        this.isHostContract = this.policyDetails.isHostContract;
        this.isMrpsContract = this.policyDetails.isMrpsContract;
        this.isGroupMrpsContract = ((this.policyDetails.product_type == 'A3' || this.policyDetails.product_type == 'A4') && (this.isMrpsContract || this.isHostContract )) ? true :false;
        let product_type = this.policyDetails.product_type;
        if (product_type && (product_type == "A3" || product_type == "A4")) {
            this.isGroupProduct = true;
        } else {
            this.isGroupProduct = false;
        }
        this.quoteFormInit();
        if(this.isHostContract && this.isGroupProduct){
            this.isHostGroupProduct = true;
        } else {
            this.isHostGroupProduct = false;
        }
        if(this.isGroupMrpsContract) {
            this.processA4Product();
            if(this.isHostContract && this.isGroupMrpsContract){
            this.quoteForm.addControl("productType", new FormControl("", [Validators.required]));
            this.quoteForm.addControl("accountType", new FormControl("", [Validators.required]));
            }
        }
        localStorage.removeItem('surrender-quote-values');
        if (!this.isLife && !this.isGroupMrpsContract) {
            this.quoteInfoFields = sectionOneFormFields();
            this.quoteValueFields = sectionTwoFormFields();
        }  else {
            if(!this.isGroupMrpsContract){
            this.isTerm= this.isaTerm();
            this.quoteInfoFields = sectionOneLifeFormFields();
            this.quoteValueFields = sectionTwoLifeFormFields();
            }
        }

        this.allFormValues = [...this.quoteInfoFields, ...this.quoteValueFields];
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.surrenderDetails.currentValue) {

            if (this.policyDetails && (this.policyDetails.isMrpsContract || this.policyDetails.isHostContract)) { // MRPS
                this.surrenderDetails = changes.surrenderDetails.currentValue;
                if(this.partySearchFactory.isOnlyGroupAnn()){
                    this.quoteInfoFields = sectionOneFormMRPSFields();
                    this.quoteValueFields = sectionTwoFormMRPSFields();
                } else if(this.partySearchFactory.isRetailAnnuity()){
                    this.quoteInfoFields = sectionOneFormFields();
                    this.quoteValueFields = sectionTwoFormFields();
                }
                this.allFormValues = [...this.quoteInfoFields, ...this.quoteValueFields];
            }
        }
    }

    isaTerm(){
        let details = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyDetails');
        if (details.data.product_type.includes('T')) {
            return true;
        }
        else {
            return false;
        }
    }


    quoteFormInit() {
        if(!this.partySearchFactory.isAnnuity()){
        this.quoteForm = this.formBuilder.group({
            effectiveDate: new FormControl(new Date(this.surrenderDetails.effective_date), [Validators.required]),
        });
        this.effectivedate = this.quoteForm.get('effectiveDate').value;
        this.effectiveMaxDate = new Date();
        } else {
            this.quoteForm = this.formBuilder.group({
                effectiveDate: new FormControl(new Date(this.policyDetails.aart_date), [Validators.required]),
            });
            this.effectivedate = this.quoteForm.get('effectiveDate').value;
            this.effectiveMaxDate = new Date();
        }
    }

    changeEvent($event: MatCheckboxChange, id: string) {
        let effectiveDate = moment(this.effectivedate).format('MM/DD/YYYY');

        const quoteData = this.allFormValues.filter((item) => item.id === id)[0];

        let unique = `${effectiveDate}-${quoteData.id}`;
        quoteData['unique'] = unique;
        quoteData['key'] = `${effectiveDate}`;

        let existsFormValues = JSON.parse(localStorage.getItem('surrender-quote-values'));


        existsFormValues =
            existsFormValues != null && existsFormValues != undefined
                ? existsFormValues
                : [];
        this.selectedFormValues = existsFormValues;

        if ($event.checked) {
            let selectedFormValueExists = this.selectedFormValues.find(item => item.unique == unique);
            if (!selectedFormValueExists) {
                const indexToInsert = this.selectedFormValues.map((item) => item.key).lastIndexOf(effectiveDate);
                if (indexToInsert !== -1) {
                    this.selectedFormValues.splice(indexToInsert + 1, 0, quoteData);
                } else {
                    const effectiveDateData = {
                        id: 'effective_date',
                        label: 'Effective Date',
                        value: `${effectiveDate}`,
                        checked: '',
                        pipe: 'DATE',
                        unique: `${effectiveDate}-effective_date`,
                        key: `${effectiveDate}`,
                    };
                    this.selectedFormValues.push(effectiveDateData);
                    this.selectedFormValues.push(quoteData);
                }
            }
        } else {
            this.selectedFormValues = this.selectedFormValues.filter(
                (item) => item.unique !== unique
            );
            const count = this.selectedFormValues.filter((item) => item.key == effectiveDate).length;

            if (count == 1) {
                unique = `${effectiveDate}-effective_date`;
                this.selectedFormValues = this.selectedFormValues.filter((item) => item.unique !== unique);
            }
        }

        localStorage.setItem(
            'surrender-quote-values',
            JSON.stringify(this.selectedFormValues)
        );
        this.showRequestedData();
    }

    prepareInfoFormControls() {
        this.formattedInfoFields = [];
        this.quoteInfoFields.map((formItem, index) => {
            formItem.value = this.surrenderDetails[formItem.id];

            'pipe' in formItem &&
                formItem.pipe == 'CURRENCY' &&
                (formItem.value = this.currencyPipe.transform(this.surrenderDetails[formItem.id], 'USD'));

            'pipe' in formItem && formItem.pipe == 'PERCENTAGE' &&
                (formItem.value = this.surrenderDetails[formItem.id] + '%');

            this.quoteForm.addControl(formItem.id, this.formBuilder.control(this.surrenderDetails[formItem.id]));
        });

        if (!this.isLife) {
            for (var i = 0; i < this.quoteInfoFields.length; i += 2) {
                let part_slice = this.quoteInfoFields.slice(i, 2 + i);
                this.formattedInfoFields.push(part_slice);
            }
        } else if (this.isLife) {
            this.quoteInfoFields = this.quoteInfoFields.filter(el => el.id != quoteSectionOneId.AdminFee);
            if(this.isTerm) {
                this.quoteInfoFields = this.quoteInfoFields.filter(el => el.id != quoteSectionOneId.SurrenderPenalty);
            }
            for (var i = 0; i < this.quoteInfoFields.length; i += 3) {
                let part_slice = this.quoteInfoFields.slice(i, 3 + i);
                this.formattedInfoFields.push(part_slice);
            }
        }
    }
    prepareValueFormControls() {
        this.formattedValueFields = [];
        this.quoteValueFields.map((formItem, index) => {
            formItem.value = this.surrenderDetails[formItem.id];

            'pipe' in formItem &&
                formItem.pipe == 'CURRENCY' &&
                (formItem.value = this.currencyPipe.transform(this.surrenderDetails[formItem.id], 'USD'));

            'pipe' in formItem && formItem.pipe == 'PERCENTAGE' &&
                (formItem.value = this.surrenderDetails[formItem.id] + '%');

            this.quoteForm.addControl(formItem.id, this.formBuilder.control(this.surrenderDetails[formItem.id]));
        });
        if (!this.isLife) {
            for (var i = 0; i < this.quoteValueFields.length; i += 2) {
                let part_slice = this.quoteValueFields.slice(i, 2 + i);
                this.formattedValueFields.push(part_slice);
            }
        } else if (this.isLife) {
            for (var i = 0; i < this.quoteValueFields.length; i += 3) {
                let part_slice = this.quoteValueFields.slice(i, 3 + i);
                this.formattedValueFields.push(part_slice);
            }
        }
    }


    showRequestedData() {
        let html = '';
        
        const requestedDetails = this.selectedFormValues.reduce((acc, { key, label, value }) => {
            acc[key] = { ...acc[key], [label]: value };
            html += `<dt class="pull-left">` + label + `:</dt><dd>&nbsp; ` + value + `</dd>`
            return acc;
          }, {});

        let quoteDetailsHeadHtml = (this.selectedFormValues.length > 0) ? `<span style="font-size:15px" class="pad-lAr-10px">QUOTE</span><dl class="pad-lAr-10px">` + html + `</dl>` : '';
        OcInfraModule.AppInjector.get(CommonService).quoteDetailsHtml = quoteDetailsHeadHtml;
        OcInfraModule.AppInjector.get(CommonService).showRequestedDetails();
        OcInfraModule.AppInjector.get(CommonService).quoteValues = (this.selectedFormValues.length > 0) ? { ['QUOTE WITHDRAWAL']: requestedDetails }: {};
    }


    onQuoteValuesClick(event) {
        if (this.validateQuoteForm()) {
            let isMrpsContract = this.policyDetails.isMrpsContract; 
            let isHostContract = this.policyDetails.isHostContract;
            if(isMrpsContract && this.isGroupMrpsContract){
                let valuesHref = this.policyDetails._links['financialvalues']?this.policyDetails._links['financialvalues'].href:null;
                var quoteEndpoint = valuesHref + "?" + this.getQueryParams();
                    this.headers['asset_account']="**";
                    this.headers['plan_id']= this.plan_id;
            } else if(isHostContract && this.isGroupMrpsContract) {
                let valuesHref = this.policyDetails._links['financialvalues']?this.policyDetails._links['financialvalues'].href:null;
                var quoteEndpoint = valuesHref + "?" + this.getQueryParams();
                let asset_account = this.quoteForm.get('accountType').value?this.quoteForm.get('accountType').value:"**";
                if (asset_account == "**") {
                    asset_account = '**';
                } else {
                    asset_account = (asset_account == '**')?'**':asset_account.substring(0, asset_account.indexOf(' '));
                }
                let plan_code = this.quoteForm.get('productType').value;
                this.headers['asset_account'] = asset_account;
                this.headers['plan_id'] = plan_code;
                this.headers['plan_code'] = plan_code;
            } else {
                var quoteEndpoint = this.currentPolicyResourceURL + this.quotesAPISource[this.withdrawlSurrenderType] + "?" + this.getQueryParams();
            }
            this.headers
            OcInfraModule.AppInjector.get(CommonService).getCallback(quoteEndpoint, this.headers).subscribe(response => {
                this.surrenderDetails = response;
                
                this.effectivedate = this.quoteForm.get('effectiveDate').value;
                
                this.prepareInfoFormControls();
                this.prepareValueFormControls();

                this.allFormValues = [...this.quoteInfoFields, ...this.quoteValueFields];

                this.showRequestedData();

                this.sendQuoteValuesToParent(response);

            }, error => {
                if (error._embedded && error._embedded.status_report) {
                    let alertMsg = [];
                    error._embedded.status_report.messages.map(msg => alertMsg.push(msg.message))
                    let dialogRef = OcInfraModule.AppInjector.get(CommonService).showConfirmation({ content: alertMsg, custom: true }, 'CLOSE', '', 'ALERT', '393px');
                    dialogRef.afterClosed().subscribe(res => {
                        res;
                    });
                }
            });
        }
    }

    validateQuoteForm() {
        let effectiveDate = this.quoteForm.get('effectiveDate').value;
        return effectiveDate
    }

    processA4Product= async () => {
        this.clientProductsUrl = this.policyDetails && this.policyDetails._links && this.policyDetails._links.clientproducts ? this.policyDetails._links.clientproducts.href : null;
        this.assetAccountsUrl = this.policyDetails && this.policyDetails._links && this.policyDetails._links.clientassetaccounts ? this.policyDetails._links.clientassetaccounts.href : null;
        try {
            let assetAccounts = await this.commonService.getClientAssetAccountsAll();
            if (!assetAccounts) {
                this.assetAccounts = assetAccounts;
            } else {
                assetAccounts = await this.commonService.getAssetAccountsApi(this.assetAccountsUrl, this.headers)
                this.assetAccounts = assetAccounts;
            }

            let clientProducts = await this.commonService.getClientProducts();
            if (!clientProducts) {
                this.commonService.clientProducts = clientProducts;
                this.clientProducts = clientProducts;
                this.processProductTypes(this.clientProducts);
            } else {
                this.headers['asset_account']="";
                this.headers['plan_id']="";
                this.headers['client_id']= this.policyDetails.client_id;
                let clientProducts = await this.commonService.getClientProductsApi(this.clientProductsUrl, this.headers)
                this.clientProducts = clientProducts;
                this.processProductTypes(this.clientProducts);
            }

        } catch (error) {
        }
    }

    processProductTypes = (clientProducts: ItemProducts[]) => {
        if (clientProducts.length > 0) {
            this.clientProductItems = clientProducts.map((item: any) => {
                return { label: item.summary.plan_code, value: item.summary.plan_code }
            });
        }
        this.plan_id = this.clientProductItems[0].value;
        this.planIDToEmit.emit(this.plan_id);
        if (this.clientProductItems.length === 1) {
            this.productTypeChange(this.clientProductItems[0].value)
        }
    }

    productTypeChange = (value: any) => {
        this.quoteForm.get('productType').setValue(value);
        let assetAccounts = this.assetAccounts.find((item: ItemAssetAccount) => item.summary.plan_code == value);
        this.assetAccountItems = assetAccounts.summary.asset_account_names.filter((item: any) => {
            return (item != '- null') && { value: item, label: item }
        }).map((item: any) => ({
            value: item === 'ALL ASSET ACCOUNTS' ? '**' : item,
            label: item
        }));
    }

    isStepComplete() {
        return this.effectivedate ? true : false;
    }

    /** AMOUNT AND PERCENT FORMATING  */
    formatNumber(n, type) {
        if (type == 'currency') {
            // format number 1000000 to 1,234,567
            return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } else {
            return n.replace(/\D/g, "");
        }
    }

    getQueryParams() {
        let params = new HttpParams();
        let effectiveDate = this.quoteForm.get('effectiveDate').value;
        params = params.set('quoteEffectiveDate', this.datePipe.transform(effectiveDate, "MM/dd/yyyy"));
        return params.toString();
    }

    sendQuoteValuesToParent(res) {
        let quoteDetails = {};
        Object.assign(quoteDetails, res)
        this.quoteValuesToEmit.emit(quoteDetails)
    }

}
