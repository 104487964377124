import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { ResourceService, OcInfraModule, APICallerService, Configuration, OptionParams } from '@diaas/ux-web';
import { CommonService } from '../../services/common.service';
import { AdvGrowlService } from 'primeng-advanced-growl';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AppDateAdapter, APP_DATE_FORMATS } from '../format-datepicker';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';

const NUMBER_REGEX = /^[1-5]$/;
@Component({
  selector: 'group-loan-quote-details',
  templateUrl: './groupPolicyLoanQuoteDetails.component.html',
  styleUrls: ['./groupPolicyLoanQuoteDetails.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  ]
})
export class GroupPolicyLoanQuoteDetailsComponent implements OnInit {
  
  policyDetails: any;
  resourceService: any;
  getPlanCodeUrl: any;
  getAssetAccountUrl: any;
  productTypes: any[];
  loanRequestTypes: any[];
  loanRepaymentFrequencies: any[];
  residentialLoanTypes: any[];
  effectiveDate: Date = new Date(); 
  accTypeItems: any;
  headers = { ...{ wma_user: window.sessionStorage.getItem('username'), client_id: OcInfraModule.AppInjector.get(CommonService).productInfo.client }, ...OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("search") };
  accountType: string;
  assetAccountTypes: any[];
  productType: any;
  policyLoanQuoteRulesData: any ={};
  loanRules: any;
  loanQuoteRequest: any ={};
  loanTypes: any[];

  loanQuoteDetails: any;
  loanQuoteDetailsForm: FormGroup;

  planCodeStatusUrl: any;
  planCodeStatus: any[];
  planCodeItems: any;
  loan_status: any;
  selectedPlanCode: any;

  maxlengthValue: string = "13";
  statusMsg: string = "";
  html: string;
  selectionChange: boolean = true;
  anyCallPending: boolean = false;
  fetchingLoanQuote: boolean = false;
  fetchingLoanRequestOptions: boolean = false;
  fetchingLoanQuoteDetails: boolean = false;
  mailQuoteInProgress: boolean =false;  
  initialLoanQuoteDone: boolean = false;
  canPerformGroupLoanQuote: boolean = false;

  constructor(private _formBuilder: FormBuilder, public commonService: CommonService, public datePipe: DatePipe) { }


  ngOnInit() {
    this.formInit();
    setTimeout(() => {
      this.resourceService = OcInfraModule.AppInjector.get(ResourceService);
      this.policyDetails = this.resourceService.getResourceFromAliasName('policyDetails') ? this.resourceService.getResourceFromAliasName('policyDetails').response : null;
      this.getPlanCodeUrl = this.policyDetails && this.policyDetails._links && this.policyDetails._links.clientproducts ? this.policyDetails._links.clientproducts.href : null;

      this.getPlanCodeItems();
      this.initializeClientProducts();
      this.selectionChange = true;        
    },1000);
  }


  formInit() {
    this.loanQuoteDetailsForm = this._formBuilder.group({
      loanRequestType: new FormControl(''),
      residentialLoanType: new FormControl(''),
      loanAmountRequested: new FormControl(''),
      loanDurationYears: new FormControl(0, Validators.required),
      loanInterestRateOverride: new FormControl(''),
      loanRepaymentFrequency: new FormControl(''),
      grossLoanAmount:[{value: '$0.00', disabled: true}],
      netLoanAmount: [{value: '$0.00', disabled: true}],
      firstQuarterInterestDue: [{value: '$0.00', disabled: true}],
      loanRepaymentAmount: [{value: '$0.00', disabled: true}],
      mailQuoteWithForm: new FormControl('Y'),
    })
  }

  async initializeClientProducts(){
    if(OcInfraModule.AppInjector.get(CommonService).getClientProducts() ==null){
     await OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false });  
      OcInfraModule.AppInjector.get(CommonService).getCallback(this.getPlanCodeUrl, this.headers).subscribe(async response => {
        await OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
        let prodTypeItems = response._links.item;
        if (prodTypeItems) {
          this.productTypes = [];
          for (let i = 0; i < prodTypeItems.length; i++) {
            this.productTypes.push({ value: prodTypeItems[i].summary.plan_code, viewValue: prodTypeItems[i].summary.plan_code });
          }
          this.effectiveDate = this.policyDetails && this.policyDetails.aart_date ? new Date(this.policyDetails.aart_date) :new Date();

        //   OcInfraModule.AppInjector.get(CommonService).canPerformGroupLoanQuote = prodTypeItems.length>0;

        
          this.canPerformGroupLoanQuote = OcInfraModule.AppInjector.get(CommonService).getCanPerformGroupLoanQuote();
        }
        
        }, async error => {
            await OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
          if (error && error._embedded && error._embedded.status_report && error._embedded.status_report.consistent === false) {
            OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error._embedded.status_report.messages[0].message, 'Error', 0);
          }
          
      });
    }else{
      this.processClientProducts(OcInfraModule.AppInjector.get(CommonService).getClientProducts());
    }
  }

  getPlanCodeItems = async () => {
    this.planCodeStatusUrl = this.policyDetails && this.policyDetails._links && this.policyDetails._links.clientloanstatus ? this.policyDetails._links.clientloanstatus.href : null;
    await OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false });  
        OcInfraModule.AppInjector.get(CommonService).getCallback(this.planCodeStatusUrl, this.headers).subscribe(async response => {
            await OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
            this.planCodeItems = response._links.item;
            if (this.planCodeItems) {
                OcInfraModule.AppInjector.get(CommonService).canPerformGroupLoanQuote = this.planCodeItems.length >0;
                this.canPerformGroupLoanQuote = this.planCodeItems.length >0;
            }
              
        }, async error => {
            await OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });  
            if (error && error._embedded && error._embedded.status_report && error._embedded.status_report.consistent === false) {
                OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error._embedded.status_report.messages[0].message, 'Error', 0);
            }
           
        });
  }

  processClientProducts(prodTypeItems){
    if (prodTypeItems) {
      this.productTypes = [];
      for (let i = 0; i < prodTypeItems.length; i++) {
        this.productTypes.push({ value: prodTypeItems[i].summary.plan_code, viewValue: prodTypeItems[i].summary.plan_code });
      }
    //   OcInfraModule.AppInjector.get(CommonService).canPerformGroupLoanQuote = prodTypeItems.length>0;

      this.canPerformGroupLoanQuote = OcInfraModule.AppInjector.get(CommonService).getCanPerformGroupLoanQuote();
      this.effectiveDate = this.policyDetails && this.policyDetails.aart_date ? new Date(this.policyDetails.aart_date) :new Date();
    }
  }

  isAnyCallPending(){
    return this.fetchingLoanQuote || this.fetchingLoanRequestOptions || this.fetchingLoanQuoteDetails || this.mailQuoteInProgress
  }
  hideSpinner(){
    if(!this.isAnyCallPending()){
      OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
    }
  }
  
  onProductTypeChange(e) {
    this.selectionChange = false;
    this.initialLoanQuoteDone = false;
  }

  emailLoanQuote() {
    const resourceService = OcInfraModule.AppInjector.get(ResourceService);
    let policyDetails = resourceService.getResourceFromAliasName('policyDetails');
    let mailUrl = policyDetails && policyDetails._links && policyDetails._links["loans/loanQuoteRules"] && policyDetails._links["loans/loanQuoteRules"] .href;
    mailUrl=mailUrl.split("loanQuoteRules")[0]+"emailannloanquote";
    // if(!this.validateForm() && this.initialLoanQuoteDone  ){
    //   this.statusMsg = "Loan Duration allowed only in between 1-5." + "<br/>";
    //   return;
    // }
    if (mailUrl) {
        OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: true });
        let header = _.filter(Configuration.config.headers["multiHostUrlHeader"], function (o) { return o.alias == 'policyValues' });
        header[0].headers['role'] = OcInfraModule.AppInjector.get(CommonService).selectedRole;
        header[0].headers['user_role'] = OcInfraModule.AppInjector.get(CommonService).userRole.toUpperCase();

        header[0].headers['residentialLoanType'] = this.loanQuoteDetailsForm.controls["residentialLoanType"].value;
        header[0].headers['grossNetInd'] = this.loanQuoteDetailsForm.controls["loanRequestType"].value
        header[0].headers['loanAmount'] = this.loanQuoteDetailsForm.controls["loanAmountRequested"].value?  this.loanQuoteDetailsForm.controls["loanAmountRequested"].value.replace('$','').replace(',',''):'0';
        header[0].headers['loanDurationYr'] = this.loanQuoteDetailsForm.controls["loanDurationYears"].value ?""+this.loanQuoteDetailsForm.controls["loanDurationYears"].value+"":"0";
        header[0].headers['intRateOverride'] = this.loanQuoteDetailsForm.controls["loanInterestRateOverride"].value ?
                                                  this.loanQuoteDetailsForm.controls["loanInterestRateOverride"].value.replace('%',''):'0';
        header[0].headers['repayFreq'] = this.loanQuoteDetailsForm.controls["loanRepaymentFrequency"].value? ""+this.loanQuoteDetailsForm.controls["loanRepaymentFrequency"].value+"" :"0";
        header[0].headers['loanStatus'] ='A';
        header[0].headers['planCode'] = this.productType;
        header[0].headers['formIndicator'] = this.loanQuoteDetailsForm.controls["mailQuoteWithForm"].value;

      
        OcInfraModule.AppInjector.get(APICallerService).changeHeaders(header[0].headers);
        OcInfraModule.AppInjector.get(CommonService).getCallback(mailUrl, header[0].headers).subscribe(response => {
            OcInfraModule.AppInjector.get(AdvGrowlService).createSuccessMessage(response.messages[0].message, 'Success');
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: true });
        }, error => {
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: true });
            if (error && error._embedded && error._embedded.status_report && error._embedded.status_report.consistent === false) {
              OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error._embedded.status_report.messages[0].message, 'Error', 0);
            } else {
              OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage("Request to mail quote failed.", 'Error', 0);
            }
        });
    }
 }

  processStatusMsg(res){
    if (res && res._embedded && res._embedded.status_report) {
      let messages = res._embedded.status_report.messages;
      const msgs = messages.map(({ message }) => message);
      const uniqueMessages = messages.filter(({ message }, index) => !msgs.includes(message, index + 1));
      uniqueMessages.forEach(msgObj => {
        this.statusMsg += msgObj.message + "<br/>";
      });
    }
  }

  showHideSpinner(){
    if(this.fetchingLoanQuote || this.fetchingLoanQuoteDetails)
      OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false });
    else if(!this.fetchingLoanQuote && !this.fetchingLoanQuoteDetails)
     OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
      
  }

  /** AMOUNT AND PERCENT FORMATING  */
  formatNumber(n, type, flag) {
    if (flag) {
      n = n.charAt(0) == '0' ? n.replace(n.charAt(0), "") : n;
    }
    if (type == 'currency') {
      return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return n.replace(/\D/g, "");
    }
  }

  onLoanDurationChange(event: any) {
    const newValue = event.target.value;
    if (!NUMBER_REGEX.test(newValue)) {
      this.loanQuoteDetailsForm.controls["loanDurationYears"].setValue(Math.trunc(newValue));
    }
  }

  //formats the value with commas and $ allowing 2 decimal values
  formatCurrency(input,fieldName, blur) {
    var input_val = input.target.value;
    if (input_val.indexOf(".") >= 0) {
      var decimal_pos = input_val.indexOf(".");
      decimal_pos = decimal_pos > 15 ? 15 : decimal_pos;
      var left_side = input_val.substring(0, decimal_pos);
      var right_side = input_val.substring(decimal_pos);
      left_side = this.formatNumber(left_side, 'currency', true);
      right_side = this.formatNumber(right_side, '', false);
      if (blur === "blur") {
        right_side += "00";
      }
      // Limit decimal to only 2 digits
      right_side = right_side.substring(0, 2);
      // join number by .
      input_val = "$" + left_side + "." + right_side;

    } else {
      // no decimal entered
      // add commas to number
      // remove all non-digits
      input_val = input_val.substring(0, 15);
      input_val = this.formatNumber(input_val, 'currency', true);
      input_val = "$" + input_val;
      // final formatting
      if (blur === "blur") {
        input_val += ".00";
      }
    }
    if (input_val.charAt(input_val.indexOf('$') + 1) == '.') {
      input_val = input_val.replace(input_val.charAt(input_val.indexOf('$') + 1), '0.');
    }
    if(fieldName){
      this.loanQuoteDetailsForm.controls[fieldName].setValue(input_val);
    }
    return input_val;
    
  }


  formatPercent(evt,fieldName, blur) {
    var input_val = evt.target.value;
    
    if (input_val.indexOf(".") >= 0) {
      var decimal_pos = input_val.indexOf(".");
      var left_side = input_val.substring(0, decimal_pos);
      var right_side = input_val.substring(decimal_pos);
      left_side = this.formatNumber(left_side, 'percent', true);
      right_side = this.formatNumber(right_side, 'percent', false);
      if (blur === "blur") {
        right_side += "000";
      }
      // Limit decimal to only 2 digits
      right_side = right_side.substring(0, 3);
      // join number by .
      input_val = left_side + "." + right_side + "%";
    } else {
      // no decimal entered
      // add commas to number
      // remove all non-digits
      input_val = this.formatNumber(input_val, 'percent', true);
      if (blur === "blur") {
        input_val += ".000";
      }
      input_val = input_val + "%";
    }
      if(fieldName){
        this.loanQuoteDetailsForm.controls[fieldName].setValue(input_val);
      }
      return input_val;
  }

  formatAmount(e) {
    if(e.keyCode == 46 && e.target.value.includes(".")){
       e.preventDefault();
       return false;
    }
    var character = String.fromCharCode(e.keyCode)
    var newValue = e.target.value + character;
      if (isNaN(newValue as any) || this.hasDecimalPlace(newValue, 3)) {
          e.preventDefault();
          return false;
      }
    e.keyCode != 46 ? e.keyCode > 31 && (e.keyCode < 48 || e.keyCode > 57) ? e.preventDefault() : '' : '';
  }

  hasDecimalPlace(value, x) {
      var pointIndex = value.indexOf('.');
      return  pointIndex >= 0 && pointIndex < value.length - x;
  }
  restrictPaste(event: ClipboardEvent) {
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData('text');
    return /^\d+(\.\d+)?$/.test(pastedText);
  }

  handleError(error){
    if (error && error._embedded && error._embedded.status_report && error._embedded.status_report.consistent === false) {
      OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error._embedded.status_report.messages[0].message, 'Error', 0);
    }
    this.showHideSpinner();
  }
  getLoanQuoteValues() {
    const resourceService = OcInfraModule.AppInjector.get(ResourceService);
    let policyDetails = resourceService.getResourceFromAliasName('policyDetails');
    let quoteDate =  this.datePipe.transform(this.effectiveDate, "MM/dd/yyyy");
    let selectedPlanCode = this.productType;
    
    // if(!this.validateForm() && this.initialLoanQuoteDone  ){
    //   // OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage("Loan Duration Years needs to be between 1 and 5.", 'Error', 0);
    //   this.statusMsg = "Loan Duration allowed only in between 1-5." + "<br/>";
    //   return;
    // }

    this.fetchingLoanQuote = false;
    Array.from(document.querySelectorAll('.loanQuoteChckbx input[type="checkbox"]:checked')).forEach(valuesCheckBox => {
      const checkBox = valuesCheckBox as HTMLInputElement;
      checkBox.click();
    });

    let isDateCorrect = this.validateDate(quoteDate);
    let loanQuoteRulesURL =null;
    let optionParams = new OptionParams();
    optionParams.headers = { ...{ planCode: selectedPlanCode,loanStatus: "A",loanRepaymentFrequency:"4"}, ...OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("groupLoanQuote") };
 
    if(!this.initialLoanQuoteDone){
      loanQuoteRulesURL = policyDetails && policyDetails._links && policyDetails._links["loans/loanQuoteRules"] && policyDetails._links["loans/loanQuoteRules"] .href;    
    }else{
      loanQuoteRulesURL = policyDetails && policyDetails._links && policyDetails._links["loans/loanQuoteDetails"] && policyDetails._links["loans/loanQuoteDetails"] .href;
      optionParams.headers['residentialLoanType'] = this.loanQuoteDetailsForm.controls["residentialLoanType"].value;
      optionParams.headers['grossNetInd'] = this.loanQuoteDetailsForm.controls["loanRequestType"].value?this.loanQuoteDetailsForm.controls["loanRequestType"].value:'G';
      optionParams.headers['loanAmount'] = this.loanQuoteDetailsForm.controls["loanAmountRequested"].value?  this.loanQuoteDetailsForm.controls["loanAmountRequested"].value.replace('$','').replace(',',''):"0";
      optionParams.headers['loanDurationYr'] = this.loanQuoteDetailsForm.controls["loanDurationYears"].value? ""+this.loanQuoteDetailsForm.controls["loanDurationYears"].value+"" :"0";
      optionParams.headers['repayFreq'] = this.loanQuoteDetailsForm.controls["loanRepaymentFrequency"].value? ""+this.loanQuoteDetailsForm.controls["loanRepaymentFrequency"].value+"" :"0";

      optionParams.headers['intRateOverride'] = this.loanQuoteDetailsForm.controls["loanInterestRateOverride"].value ?
                                                this.loanQuoteDetailsForm.controls["loanInterestRateOverride"].value.replace('%',''):'0';
    }
   
    let loanQuoteRequestUrl = policyDetails && policyDetails._links && policyDetails._links["loans/loanQuoteRequest"] && policyDetails._links["loans/loanQuoteRequest"] .href;
    if (isDateCorrect && loanQuoteRulesURL && loanQuoteRequestUrl) { 
      this.statusMsg = "";
      OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false });
      this.fetchingLoanQuote = true;
      this.fetchingLoanQuoteDetails = true;
       OcInfraModule.AppInjector.get(APICallerService).refresh(loanQuoteRulesURL, optionParams).subscribe(res => {
      
        this.policyLoanQuoteRulesData = {...this.policyLoanQuoteRulesData, ...res.response};
        this.setLoanQuoteDetailsForm( this.policyLoanQuoteRulesData,this.initialLoanQuoteDone);
        this.initialLoanQuoteDone = true;
          
          this.loanRepaymentFrequencies = res.response.loan_repayment_frequency_options;

          this.loanRequestTypes = res._options.properties.loan_request_type.oneOf;
          this.residentialLoanTypes = res._options.properties.residential_loan_type.oneOf;
          this.processStatusMsg(res.response);
        
        // this.setLoanQuoteRequestForm( this.policyLoanQuoteRulesData);
        this.fetchingLoanQuote = false;
        this.fetchingLoanQuoteDetails = false;
        this.showHideSpinner();
      }, error => {
        this.fetchingLoanQuote = false;
        this.fetchingLoanQuoteDetails = false;
        // this.handleError(error);
        this.processStatusMsg(error.error);
      });
    }
    else {
      OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage("Incorrect date selected for quote.", 'Error', 0);
    }
  }

  setLoanQuoteDetailsForm(detailsData,initialLoanQuoteDone){
    if(!initialLoanQuoteDone){
      this.loanQuoteDetailsForm.controls["mailQuoteWithForm"].setValue( "Y");
      this.loanQuoteDetailsForm.controls["loanRequestType"].setValue("");
      this.loanQuoteDetailsForm.controls["residentialLoanType"].setValue( "");
      this.loanQuoteDetailsForm.controls["loanAmountRequested"].setValue("$0.00");
      this.loanQuoteDetailsForm.controls["loanDurationYears"].setValue("");
      this.loanQuoteDetailsForm.controls["loanInterestRateOverride"].setValue("0.000%");
      this.loanQuoteDetailsForm.controls["loanRepaymentFrequency"].setValue("");
    }

    if(detailsData){
     
      this.loanQuoteDetailsForm.controls["grossLoanAmount"].setValue(detailsData.gross_loan_amount && detailsData.gross_loan_amount>0?"$"+ detailsData.gross_loan_amount: "$0.00");
      this.loanQuoteDetailsForm.controls["netLoanAmount"].setValue( detailsData.net_loan_amount && detailsData.net_loan_amount ?"$"+detailsData.net_loan_amount:"$0.00");
      this.loanQuoteDetailsForm.controls["firstQuarterInterestDue"].setValue(detailsData.ist_quarter_interest_due && detailsData.ist_quarter_interest_due>0? "$"+detailsData.ist_quarter_interest_due  :"$0.00");
      this.loanQuoteDetailsForm.controls["loanRepaymentAmount"].setValue(detailsData.loan_repayment_amount && detailsData.loan_repayment_amount>0 ?"$"+detailsData.loan_repayment_amount:"$0.00");
      //PANOCSR-2704 making loanrepaymentfrequency dropdown blank by default if loantype is ARL
      if(this.policyLoanQuoteRulesData.loan_type_description !== "Account Reduction Loan"){
        this.loanQuoteDetailsForm.controls["loanRepaymentFrequency"].setValue(detailsData.loan_repayment_frequency? detailsData.loan_repayment_frequency+"" :"");
      }
    }else{
  
      this.loanQuoteDetailsForm.controls["grossLoanAmount"].setValue("$0.00");
      this.loanQuoteDetailsForm.controls["netLoanAmount"].setValue( "$0.00");
      this.loanQuoteDetailsForm.controls["firstQuarterInterestDue"].setValue( "$0.00");
      this.loanQuoteDetailsForm.controls["loanRepaymentAmount"].setValue( "$0.00");
    }
    
  }

//   validateForm(){
//     return this.loanQuoteDetailsForm.controls["loanDurationYears"].value && this.loanQuoteDetailsForm.controls["loanDurationYears"].value>=1 && this.loanQuoteDetailsForm.controls["loanDurationYears"].value<=5
//   }

  validateDate(testdate) {
    let date_regex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
    return date_regex.test(testdate);
  }

  getCheckedValues(e, selectedValue, selectedKey, columnName, valueType, dataType) {
    if (this.isAnyCallPending()) {
      e.preventDefault();
      return;
    } else {
      let checkedLoanQuoteObject = OcInfraModule.AppInjector.get(CommonService).checkedLoanQuoteObject;
      if(dataType ==='FORM_DATA'){
        selectedValue = this.loanQuoteDetailsForm.controls[ columnName].value;
        if(selectedValue) {
            selectedValue = (typeof selectedValue == 'number') ? selectedValue.toString() : selectedValue;
            selectedValue= selectedValue.replace('$','').replace('%','')
        }
      }
      else 
        selectedValue = selectedValue === undefined ? "No data in admin system." : selectedValue;
      
      selectedValue = valueType == 'currency' ? '$' + selectedValue.toString().replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,') : selectedValue;
      selectedValue = valueType == 'percentage' ? selectedValue + '%' : selectedValue;
      let groupLoansQuoteHtml = '', plancodeHtml = '', msgFields = ['Message'];
      let quoteDate  = this.datePipe.transform(this.effectiveDate, "MM/dd/yyyy");
      let selectedPlanCode = this.productType;
      let keyString = selectedPlanCode +  '_' + quoteDate;
      if (e.checked) {
        let quoteValuesObj = {};
        if(dataType ==='FORM_DATA'){
          quoteValuesObj[selectedKey] = selectedValue;
        } else if (this.policyLoanQuoteRulesData && (this.policyLoanQuoteRulesData[columnName] || this.policyLoanQuoteRulesData[columnName] == 0) 
              && this.policyLoanQuoteRulesData[columnName] !== "") {
          if (!msgFields.includes(selectedKey)) {
            quoteValuesObj[selectedKey] = selectedValue;
          }
          else {
            quoteValuesObj[selectedKey] = 'No data in admin system.';
          }
        }
        else {
          if (msgFields.includes(selectedKey)) {
            quoteValuesObj[selectedKey] = this.statusMsg ? this.statusMsg : 'No data in admin system.';
          }
          else {
            quoteValuesObj[selectedKey] = 'No data in admin system.';
          }
        }
        if (_.isEmpty(checkedLoanQuoteObject)) {
          checkedLoanQuoteObject['LOAN_QUOTE'] = {};
          checkedLoanQuoteObject['LOAN_QUOTE'][keyString] = quoteValuesObj;
        }
        else {
          if (checkedLoanQuoteObject['LOAN_QUOTE'].hasOwnProperty(keyString)) {
            checkedLoanQuoteObject['LOAN_QUOTE'][keyString] = { ...checkedLoanQuoteObject['LOAN_QUOTE'][keyString], ...quoteValuesObj };
          } else {
            let newPlanCodeObj = {};
            newPlanCodeObj[keyString] = quoteValuesObj;
            checkedLoanQuoteObject['LOAN_QUOTE'] = { ...checkedLoanQuoteObject['LOAN_QUOTE'], ...newPlanCodeObj };
          }
        }
      }
      else {
        if (Object.keys(checkedLoanQuoteObject['LOAN_QUOTE']).length) {
          if (checkedLoanQuoteObject['LOAN_QUOTE'][keyString] && Object.keys(checkedLoanQuoteObject['LOAN_QUOTE'][keyString]).length > 1) {
            delete checkedLoanQuoteObject['LOAN_QUOTE'][keyString][selectedKey];
          } else {
            delete checkedLoanQuoteObject['LOAN_QUOTE'][keyString];
            (Object.keys(checkedLoanQuoteObject['LOAN_QUOTE']).length) ? null : delete checkedLoanQuoteObject['LOAN_QUOTE'];
          }
        }
      }
      OcInfraModule.AppInjector.get(CommonService).checkedLoanQuoteObject = checkedLoanQuoteObject;
      if (checkedLoanQuoteObject['LOAN_QUOTE'] && Object.keys(checkedLoanQuoteObject).length) {
        let subKeysList = [];
        Object.keys(checkedLoanQuoteObject['LOAN_QUOTE']).forEach(key => {
          plancodeHtml += '<dt class="pull-left"> Product:</dt><dd>&nbsp;' + key.split('_')[0] + '</dd>' + '<dt class="pull-left"> Effective Date:</dt><dd>&nbsp;' + key.split('_')[1] + '</dd>';
          Object.keys(checkedLoanQuoteObject['LOAN_QUOTE'][key]).forEach(subKey => {
            if (subKeysList.includes(key)) {
              groupLoansQuoteHtml += '<dl class="pad-lAr-10px mb-0"><dt class="pull-left">' + subKey + ':</dt><dd>&nbsp;' + checkedLoanQuoteObject['LOAN_QUOTE'][key][subKey] + '</dd></dl>';
            } else {
              groupLoansQuoteHtml = '';
              groupLoansQuoteHtml += '<dl class="pad-lAr-10px mb-0"><dt class="pull-left">' + subKey + ':</dt><dd>&nbsp;' + checkedLoanQuoteObject['LOAN_QUOTE'][key][subKey] + '</dd></dl>';
              subKeysList.push(key);
            }
          })
          plancodeHtml += groupLoansQuoteHtml;
        });
      }
      this.html = plancodeHtml;
      let finalHtml = '';
      Object.keys(checkedLoanQuoteObject).length ? finalHtml = ' <span style="font-size:15px" class="pad-lAr-10px">LOAN QUOTE</span><dl class="pad-lAr-10px">' + this.html + '</dl>' : finalHtml = '';
      OcInfraModule.AppInjector.get(CommonService).groupLoansQuoteHtml = finalHtml;
      OcInfraModule.AppInjector.get(CommonService).showRequestedDetails();
      localStorage.setItem("loanQuote", JSON.stringify(checkedLoanQuoteObject['LOAN_QUOTE']));
    }
  }
}