import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    AfterContentInit,
    ViewChild,
    AfterViewInit,
    ElementRef,
    ViewChildren,
    QueryList,
  } from '@angular/core';
  import { FormGroup, FormGroupDirective } from '@angular/forms';
  import { fromEvent, pipe, Subject } from 'rxjs';
  import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
  import { DynamicFormFieldModel } from '../../quote-loan-initiation/quote-loan-initiation.constant';
  
  @Component({
    selector: 'app-dynamic-form-field',
    templateUrl: './dynamic-form-field.component.html',
    styleUrls: ['./dynamic-form-field.component.scss'],
  })
  export class DynamicFormFieldComponent implements OnInit {
    @Input() formItem!: DynamicFormFieldModel;
  
    form!: FormGroup;
    @Output() changeEvent = new EventEmitter();
    @Output() keypressEvent = new EventEmitter();
  
    private keypress$: Subject<string> = new Subject();
    @ViewChildren('input') inputElems: QueryList<ElementRef>;

    constructor(private rootFormGroup: FormGroupDirective) {
      this.form = this.rootFormGroup.control;
    }
  
    ngOnInit() {
      this.keypress$.pipe(debounceTime(500)).subscribe(($event) => {
        console.log('keypressEventEmit t');
        this.keypressEvent.emit($event);
      });
    }
  
    changeEventEmit = ($event) => {
      console.log('changeEventEmit $event');
      this.changeEvent.emit($event);
    };
  
    keypressEventEmit = ($event) => {
      this.keypress$.next($event);
    };

    ngAfterViewInit() {
        this.inputElems.map(
          (e) =>
            this.formItem.id === e.nativeElement.id &&
            this.formItem.disable === true &&
            e.nativeElement.setAttribute('disabled', 'true')
        );
      }
  }
  