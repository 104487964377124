import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { CommonService } from '../../../services/common.service';
import { OcInfraModule, ResourceService, APICallerService, Configuration } from '@diaas/ux-web';
import * as moment from 'moment';
import { MatDialog } from '@angular/material';
import { AdvGrowlService } from 'primeng-advanced-growl';
import { CurrencyPipe } from '@angular/common';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToasterService } from 'angular2-toaster';
import { ConfirmPayload, DynamicFormFieldModel, confirmFormFields } from './confirmation.constant';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';

@Component({
    selector: 'app-confirmation-rmd',
    templateUrl: './confirmation.component.html',
    styleUrls: ['./confirmation.component.scss']
})
export class RMDConfirmationComponent implements OnInit {

    @Input() rmdDetails: any;
    @Input() scriptMessage: string;

    rmdHeader: any;
    withholdingPerDisable: boolean = false;
    warningMessags: any[] = [];

    confirmForm: FormGroup;
    formFields!: DynamicFormFieldModel[];
    allRMDForms$: Subscription;

    allFormValues: any;
    allFormValid: boolean;
    requiredMessage = 'Please fill required fields';

    confirmationNumber: string;
    isOverride: boolean = true;
    overrideCall: boolean = true;

    policyDetails: any;
    submitDisable = true;
    confirmationDate: string;
    hoverMessage: string = '';

    constructor(
        private currencyPipe: CurrencyPipe,
        private formBuilder: FormBuilder,
        private commonService: CommonService,
        private cdr: ChangeDetectorRef,
        private toasterService: ToasterService,
    ) { }

    ngOnInit() {
        this.formFields = <DynamicFormFieldModel[]>(
            confirmFormFields
        );
        if (this.rmdDetails) {
            this.formInit();
            this.confirmForm.addControl("confirmScript", new FormControl(false, [Validators.requiredTrue]));
            this.confirmationNumber = OcInfraModule.AppInjector.get(CommonService).authResponse.updateResponse.data.confirmationNumber;
        }
        this.policyDetails = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyDetails') ? OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyDetails') : null;
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.allRMDForms$ = this.commonService.getAllRMDForms$.subscribe(
                (data: any) => {
                    if (Object.keys(data).length > 0) {
                        this.allFormValues = data.values;
                        this.allFormValid = data.valid;
                        this.loadFormData();
                        this.isSubmitDisabled();
                    }
                }
            );
        }, 0);
    }

    ngAfterContentChecked(): void {
        this.cdr.detectChanges();
    }

    formInit() {
        this.confirmForm = this.formBuilder.group({});
        this.formFields.forEach((formItem) => {
            const formControl = this.formBuilder.control(
                formItem.value,
                formItem.validators
            );
            if (formItem.id !== 'hr') {
                this.confirmForm.addControl(formItem.id, formControl);
            }
        });
    }

    loadFormData() {
        let toggle : boolean =  false;
        var withdrawalPercent;
        this.formFields.forEach((formItem) => {
            if (formItem.id == 'confirmation_number') {
                formItem.value = this.confirmationNumber;
            } else {
                formItem.value =
                    formItem.id in this.allFormValues
                        ? this.allFormValues[formItem.id]
                        : formItem.value;
            }

            const formControl = this.formBuilder.control(
                formItem.value,
                formItem.validators
            );
            if (formItem.id != 'hr') {
                this.confirmForm.addControl(formItem.id, formControl);
            }
            if(formItem.id == "wthldPctFed" || formItem.id == "wthldPctSt") {
                withdrawalPercent = formItem.value;
                let withdrawalPerStr = withdrawalPercent.includes('%') ? withdrawalPercent.slice(0,-1) : withdrawalPercent;
                let withdrawalPerInt = Math.trunc(withdrawalPerStr);
                let withdrawalString = withdrawalPerInt.toString();
                if(withdrawalString.length>2) {
                    toggle = true;
                }
            }
        });
        this.withholdingPerDisable = toggle;
    }

    onSubmit = () => {
        const disbursementInd = this.allFormValues.disbursement_ind;
        if (!Array.isArray(disbursementInd)) this.allFormValues.disbursementInd = [disbursementInd];

        this.rmdHeader = _.filter(Configuration.config.headers["multiHostUrlHeader"], function (o) { return o.alias == 'rmdDetails' });
        let rmdHref = this.policyDetails && this.policyDetails._links.rmdQuote.href;
        let href = rmdHref + '/modifyRmd';
        let payload = this.getConfirmPayload();

        OcInfraModule.AppInjector.get(CommonService).patchCallback(href, payload, this.rmdHeader[0].headers).subscribe(response => {
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
            let msg = 'Success Addition Processed Successfully'
            // msg = (response.message) ? response.message : msg;
            OcInfraModule.AppInjector.get(AdvGrowlService).createSuccessMessage(msg, 'Success');
            this.confirmationDate = moment().tz('America/New_York').format('MM/DD/YYYY HH:mm');

            this.showRequestedData();
            const resourceService = OcInfraModule.AppInjector.get(ResourceService);
            const workRequestHref = resourceService.getHrefByAliasName("workRequestHistory").split('?')[0];
            let workRequestheaders = _.filter(Configuration.config.headers["multiHostUrlHeader"], function (o) { return o.alias == 'workRequestHistory' });
            setTimeout(() => {
                document.getElementById("modal__cross").click();    
            }, 300);
            if (response.success) {
                setTimeout(() =>{
                    OcInfraModule.AppInjector.get(APICallerService).refresh(workRequestHref, { 'alias': "workRequestHistory", 'headers': workRequestheaders[0].headers}).subscribe(refreshResponse => {
                    OcInfraModule.AppInjector.get(CommonService).wrkReqHistoryRes = refreshResponse;
                    OcInfraModule.AppInjector.get(CommonService).sendFilterEvent();
            });
          },2500); 
        }            
        }, error => {
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
            let errorMessages = OcInfraModule.AppInjector.get(CommonService).getNested(error, '_embedded', 'status_report', 'messages');

            if (errorMessages && errorMessages.length > 0) {
                this.warningMessags = error._embedded.status_report.messages;
                this.displayAlerts(error,'error');
            }
        });

    }

    displayAlerts(response, type) {
        if ( response._embedded && response._embedded.status_report && response._embedded.status_report.messages && response._embedded.status_report.messages.length > 0) {
          let fillteredData =response._embedded.status_report.messages.filter(x=>x.message !== "No mapping rule matched");
          fillteredData.forEach(element => {
            let alertMsg = {
              title: element.severity,
              type: type,
              body: element.message,
              showCloseButton: true,
              timeout: 0,
              tapToDismiss: false,
            }
            this.toasterService.pop(alertMsg);
          });
        }
      }

    getConfirmPayload = (): ConfirmPayload => {
        const mrd_bene_dob = moment(this.allFormValues.mrd_bene_dob).tz('America/New_York').format('MM/DD/yyyy');
        this.allFormValues.policyNumber = this.policyDetails.data.policy_number;
        // policy number added for retail and group  policy - AWD 
        const payload: ConfirmPayload = {
            mrd_initial_start_date: this.allFormValues.mrd_initial_start_date,
            next_payout_date: this.allFormValues.next_payout_date,
            trx_level_ind: this.allFormValues.trx_level_ind,
            effective_date: this.allFormValues.effective_date,
            rmd_mode: this.allFormValues.rmd_mode,
            business_closehour: this.allFormValues.business_closehour,
            rmd_type: this.allFormValues.rmd_type,
            rmd_day: this.allFormValues.rmd_day,
            rmd_rules: this.allFormValues.rmd_rules,
            fed_Wthor_ind: this.allFormValues.fed_Wthor_ind,
            state_wthor_ind: this.allFormValues.state_wthor_ind,
            state_pctOr_basis: this.allFormValues.state_pctOr_basis,
            eft_id : this.allFormValues.disbursement_ind == 'E' ? this.allFormValues.eft_id : '',
            wthldPctSt: this.allFormValues.wthldPctSt ? this.allFormValues.wthldPctSt.replace("%", "") : '0.00',
            wthldPctFed: this.allFormValues.wthldPctFed ? this.allFormValues.wthldPctFed.replace("%", "") : '0.00',

            wthldAmtSt: this.allFormValues.wthldAmtSt ? this.allFormValues.wthldAmtSt.replace("$", "") : '0.00',
            wthldAmtFed: this.allFormValues.wthldAmtFed ? this.allFormValues.wthldAmtFed.replace("$", "") : '0.00',

            disbursement_ind: this.allFormValues.disbursement_ind,
            distribution_code: this.allFormValues.distribution_code,
            bene_relation_code: this.allFormValues.bene_relation_code,
            bene_status_indicator: this.allFormValues.bene_status_indicator,
            mrd_bene_ssn: this.allFormValues.mrd_bene_ssn,
            mrd_bene_dob: mrd_bene_dob,
            policyNumber: this.allFormValues.policyNumber,
            incontactId: this.commonService.incontactId
        }
        return payload;
    }

    isSubmitDisabled = () => {
        if (this.confirmForm.valid && this.allFormValid) {
            this.submitDisable = false
        } else {
            this.submitDisable = true;
        }
        if(this.withholdingPerDisable){
            this.submitDisable = true;
        }
    };

    isStepComplete() {
        return this.confirmForm.valid;
    }
    
    showRequestedData() {
        
        let html = '';
        let requestedDetails = {};
        
        requestedDetails['Confirmation Number'] = this.confirmationNumber;
        html += `<dt class="pull-left"> Confirmation Number : </dt><dd>&nbsp; ` + this.confirmationNumber  + `</dd>`
        
        requestedDetails['Requested Date and Time'] = this.allFormValues.requestedDate;
        html += `<dt class="pull-left"> Requested Date and Time : </dt><dd>&nbsp; ` + this.allFormValues.requestedDate  + `</dd>`
        
        requestedDetails['Confirmation date and time'] = this.confirmationDate;
        html += `<dt class="pull-left"> Confirmation date and time : </dt><dd>&nbsp; ` + this.confirmationDate  + `</dd>`
        
        requestedDetails['Effective Date of Transaction'] = this.allFormValues.effective_date;
        html += `<dt class="pull-left"> Effective Date of Transaction : </dt><dd>&nbsp; ` + this.allFormValues.effective_date  + `</dd>`
        
        requestedDetails['Type of Transaction'] = 'Pending';
        html += `<dt class="pull-left"> Type of Transaction : </dt><dd>&nbsp; Pending </dd>`
        
        let rmdHtml = `<span style="font-size:15px" class="pad-lAr-10px">RMD</span><dl class="pad-lAr-10px">` + html + `</dl>`;
        OcInfraModule.AppInjector.get(CommonService).rmdHtml = rmdHtml;
        OcInfraModule.AppInjector.get(CommonService).showRequestedDetails();
        OcInfraModule.AppInjector.get(CommonService).rmdDetails = { ['RMD']: requestedDetails };
      }

    ngOnDestroy() {
        setTimeout(() => {
            this.allRMDForms$.unsubscribe();
        }, 0);
    }




}
