import { Component, OnInit, ElementRef } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { OcInfraModule, APICallerService, OptionParams, ResourceService } from '@diaas/ux-web';
import { AdvGrowlService } from 'primeng-advanced-growl';
import * as _ from 'lodash';


@Component({
  selector: 'app-sponser-contract-holder-info-tab',
  templateUrl: './sponser-contract-holder-info-tab.component.html',
  styleUrls: ['./sponser-contract-holder-info-tab.component.scss']
})
export class SponserContractHolderInfoTabComponent implements OnInit {
  contractHolderList: any;
  noRecords: boolean = true;
  statusMsg: string = "";
  message: boolean = false;
  checkedData: Object = {};
  contractholderObj: any = {};
  contractholderList: any[] = [];
  selectdValues: any;
  currentChecked: {};
  checkedProperties: any = {};
  savedProperties: string;
  html = '';
  deliverStatementsTo: any;

  constructor() { }

  ngOnInit() {
    let policySponsor = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('Hcontractsdetails');
    this.contractHolderList = policySponsor.data.hClientContractHolderInfo;
    this.noRecords = this.contractHolderList && !(Object.keys(this.contractHolderList).length != 0);
    if (policySponsor.response._embedded && policySponsor.response._embedded.status_report.messages) {
      policySponsor.response._embedded.status_report.messages.forEach(msgObj => {
        this.statusMsg += msgObj.message;
        this.message = true;
        
      });
    }
    this.deliverStatementsTo = policySponsor._options.properties.deliver_statements_to.oneOf.find(opt => opt.enum.includes(policySponsor.data.deliver_statements_to)).title;
  }

    testChange(event, data, selectedValue, selectedKey, columnName, valueType, flag) {
      let checkedSponsorDetailsProperties = OcInfraModule.AppInjector.get(CommonService).checkedcontractHolderDetails;
              let selectedLang = OcInfraModule.AppInjector.get(CommonService).selectedLanguage;
              let checkedId = selectedValue;
              this.currentChecked = {};
              let contractholderHtml = '';
      
              let policySponser = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policySponsor');
              let isMrpsContract = OcInfraModule.AppInjector.get(CommonService).getNested(policySponser, 'response', 'is_mrps_contract');
      
              //let mrpsFields = ['coll_loans_allowed_action', 'tpa_loans_allowed_action', 'acc_redctn_loans_allowed_action', 'pre78_loans_allowed_action'];
              let hClientInfoFields = ['client_type_action','superior_client_action','grp_plan_issue_date_action','grp_plan_eff_date_action','tpa_exists_action','contract_holder_action','clientStatus_action','payroll_end_date_action','client_other_mail_code_action','state_of_sale_action','plan_termination_date_action','depository_code_action','client_first_pmt_date_action','highly_compensated_action','tax_market_action','legacy_system_action'];
              let directoryIdFields = ['directory_id_action','name_type_action','gender_action','tax_id_action','tax_id_suffix_action'];
              let nameOrAddressFields = ['first_name_action','last_name_action','corporate_action','address1_action','address2_action','address3_action','address4_action','city_action','state_action','zip_action','country_action'];
              if (event.checked) {
                  this.savedProperties = localStorage.getItem('contract_holder');
                  if (this.savedProperties == null) {
                      localStorage.setItem('contract_holder', JSON.stringify(this.checkedProperties));
                      this.savedProperties = localStorage.getItem('contract_holder');
                  }
                  this.checkedProperties = JSON.parse(this.savedProperties)
                  this.checkedProperties[selectedKey] = []
                  const resourceService = OcInfraModule.AppInjector.get(ResourceService);
                  let getResourceByHref;
                  getResourceByHref = resourceService.getResourceFromAliasName('Hcontractsdetails');
                  if((checkedId == true || checkedId == false) && valueType == "boolean"){
                      this.currentChecked[selectedKey] = (checkedId == true) ? 'Yes':'No';
                  }else{
                      if(selectedValue == ""){
                          this.currentChecked[selectedKey] = "No data in admin system";
                      }else{
                          this.currentChecked[selectedKey] = selectedValue;
                      }
                  }
                  this.checkedProperties[selectedKey] = this.currentChecked;
                  _.assign(checkedSponsorDetailsProperties, this.checkedProperties);
                  let subKeys = Object.keys(checkedSponsorDetailsProperties);
                  for (var j = 0; j < subKeys.length; j++) {
                      Object.keys(checkedSponsorDetailsProperties[subKeys[j]]).forEach(key => {
                        contractholderHtml += '<dt class="pull-left">' + key + ':</dt><dd>&nbsp;' + checkedSponsorDetailsProperties[subKeys[j]][key] + '</dd>';
                      })
                  }
                  this.html = contractholderHtml;
                  OcInfraModule.AppInjector.get(CommonService).contractholderHtml = ' <span style="font-size:15px" class="pad-lAr-10px">SPONSOR-CONTRACT HOLDER INFO</span><dl class="pad-lAr-10px">' + this.html + '</dl>'
                  OcInfraModule.AppInjector.get(CommonService).showRequestedDetails()
                  OcInfraModule.AppInjector.get(CommonService).checkedcontractHolderDetails = checkedSponsorDetailsProperties;
                  localStorage.setItem('contract_holder', JSON.stringify(this.checkedProperties));
              } else {
                  this.checkedProperties = {};
                  delete checkedSponsorDetailsProperties[selectedKey];
                  _.assign(this.checkedProperties, checkedSponsorDetailsProperties);
                  localStorage.setItem('contract_holder', JSON.stringify(this.checkedProperties));
                  let subKeys = Object.keys(checkedSponsorDetailsProperties);
                  for (var j = 0; j < subKeys.length; j++) {
                      Object.keys(checkedSponsorDetailsProperties[subKeys[j]]).forEach(key => {
                          if (key == selectedLang._SUSPENSE_AMOUNT) {
                            contractholderHtml += '<dt class="pull-left">' + key + ':</dt><dd>&nbsp;' + OcInfraModule.AppInjector.get(CommonService).convertToCurrency(checkedSponsorDetailsProperties[subKeys[j]][key]) + '</dd>';
                          }
                          else {
                            contractholderHtml += '<dt class="pull-left">' + key + ':</dt><dd>&nbsp;' + checkedSponsorDetailsProperties[subKeys[j]][key] + '</dd>';
                          }
                      })
                  }
                  this.html = contractholderHtml;
                  let finalHtml = '';
                  Object.keys(checkedSponsorDetailsProperties).length ? finalHtml = ' <span style="font-size:15px" class="pad-lAr-10px">SPONSOR-CONTRACT HOLDER INFO</span><dl class="pad-lAr-10px">' + this.html + '</dl>' : finalHtml = '';
                  OcInfraModule.AppInjector.get(CommonService).contractholderHtml = finalHtml;
                  OcInfraModule.AppInjector.get(CommonService).showRequestedDetails();            
                  OcInfraModule.AppInjector.get(CommonService).checkedcontractHolderDetails = checkedSponsorDetailsProperties;
                  localStorage.setItem('contract_holder', JSON.stringify(this.checkedProperties));
              }
    }

    covrageChecked(event, data, selectedValue, selectedKey, columnName, valueType, flag) {
        let selctedCoverage = [];
        selctedCoverage = OcInfraModule.AppInjector.get(CommonService).checkedcontractHolderDetails;
        if (event.checked) {
          let coverageChecked = []
          this.currentChecked = {}
          selectedValue = selectedValue ? selectedValue : 'No data in admin system.';
          if (selectedValue) {
            this.currentChecked[selectedKey] = selectedValue;
            if (selctedCoverage.length > 0) {
              if (selctedCoverage[0].hasOwnProperty(["selectedNote"])) {
                for (let i = 0; i < selctedCoverage.length; i++) {
                  let subKeys = Object.keys(selctedCoverage[0]["selectedNote"]);
                  let existed: boolean = true;
                  if (subKeys.length > 0) {
                    for (let j = 0; j < subKeys.length; j++) {
                      if (subKeys[j] == "contractHolderInfo") {
                        Object.assign(selctedCoverage[0]["selectedNote"]["contractHolderInfo"], this.currentChecked);
                        OcInfraModule.AppInjector.get(CommonService).checkedcontractHolderDetails = selctedCoverage;
                        existed = false;
                      }
                    }
                  } else {
                    this.checkedProperties["selectedNote"] = ["contractHolderInfo"]
                    this.checkedProperties["selectedNote"][""] = this.currentChecked;
                    Object.assign(selctedCoverage[0]["selectedNote"], this.checkedProperties["selectedNote"]);
                    OcInfraModule.AppInjector.get(CommonService).checkedcontractHolderDetails = selctedCoverage;
                  }
                }
              } else {
                this.checkedProperties["selectedNote"] = []
                this.checkedProperties["selectedNote"]["contractHolderInfo"] = this.currentChecked;
                Object.assign(selctedCoverage[0], this.checkedProperties)
                OcInfraModule.AppInjector.get(CommonService).checkedcontractHolderDetails = selctedCoverage;
              }
            } else {
              this.checkedProperties["selectedNote"] = []
              this.checkedProperties["selectedNote"]["contractHolderInfo"] = this.currentChecked;
              selctedCoverage.push(this.checkedProperties);
              OcInfraModule.AppInjector.get(CommonService).checkedcontractHolderDetails = selctedCoverage;
            }
          }
        }
        else {
          let unCheckedId = selectedKey;
          delete selctedCoverage[0]["selectedNote"]["contractHolderInfo"][unCheckedId]
          let subKeys = Object.keys(selctedCoverage[0]["selectedNote"]);
          let checked = [];
          for (let i = 0; i < subKeys.length; i++) {
            if (_.isEmpty(selctedCoverage[0]["selectedNote"][subKeys[i]])) {
              delete selctedCoverage[0]["selectedNote"]["contractHolderInfo"]
              checked.push(false)
            } else {
              checked.push(true)
            }
          }
          localStorage.setItem("contractHolderInfo", JSON.stringify(this.checkedProperties));
          OcInfraModule.AppInjector.get(CommonService).checkedcontractHolderDetails = selctedCoverage;
        }
      }


}
