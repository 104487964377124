import { Component, OnInit, Input, Output, EventEmitter,ChangeDetectionStrategy } from '@angular/core';
import { CommonService } from '../../../services/common.service';
import { APICallerService, Configuration, OcInfraModule, ResourceService } from '@diaas/ux-web';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { DatePipe } from '@angular/common';
import { AdvGrowlService } from 'primeng-advanced-growl';
import {DateAdapter, MAT_DATE_FORMATS} from '@angular/material/core';
import { AppDateAdapter, APP_DATE_FORMATS } from '../../format-datepicker';
export interface dropdown {
  tooltip: any;
  id: string;
  vewValue: string;
  partyName?: string;
}
@Component({
  selector: 'claim-notification-deceased-information',
  templateUrl: './claim-notification-deceased-information.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['claim-notification-deceased-information.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: AppDateAdapter},
    {provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS}
  ]
})

export class ClaimNotificationDeceasedInformationComponent implements OnInit {

  notificationMethod: dropdown[];
  claimType: dropdown[];
  deceasedParty: dropdown[];
  deceasedInsuredType: dropdown[];
  mannerOfDeath: dropdown[];
  countryOfDeath: dropdown[];
  deathInUSA: dropdown[];
  requiredProperties: any;
  effectiveMaxDate = new Date();
  effectiveMinDate = new Date(1900, 1, 1);
  nextButtonDisabled: boolean;

  @Output() nextButtonEvent = new EventEmitter<string>();

  // Form
  claimNotificationForm: FormGroup;
  message: string;
  @Output() claimNotificationDataEvent = new EventEmitter<string>();
  @Output() personDetails = new EventEmitter<object>();

  // Claims data getting from parent component
  @Input() claimsData: any;

  authorizationCheck: boolean = false;
  roleInfo: dropdown[];
  isAnnuity: boolean = false;
  details:any;
  constructor(private datePipe: DatePipe, private _formBuilder: FormBuilder) {
  }

  ngOnInit() {
    this.details = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyDetails');
    this.isAnnuity = (this.details.data.product_type.includes('A') ? true : false);
    this.formDropdownValues();
    this.formInit();
    if (OcInfraModule.AppInjector.get(CommonService).authsucess || OcInfraModule.AppInjector.get(CommonService).otherRoleAuthsucess) {
      this.message = "Enable button by completing required items."
      this.authorizationCheck = true;
    }
    else {
      this.authorizationCheck = false;
      this.message = "Caller not authorized."
    }
  }

  formDropdownValues() {
    // For form field validation
    this.requiredProperties = this.claimsData._options.required;
    // Form select option list
    this.notificationMethod = this.claimsData._options.properties.notificationMethod.oneOf;
    this.claimType = this.claimsData._options.properties.claimType.oneOf;
    this.deceasedParty = this.claimsData._options.properties.deceasedParty.oneOf;
    this.mannerOfDeath = this.claimsData._options.properties.mannerOfDeath.oneOf;
    this.countryOfDeath = this.claimsData._options.properties.countryOfDeath.oneOf;
    this.deathInUSA = this.claimsData._options.properties.deathInUSA.oneOf;
    this.deceasedInsuredType = this.claimsData._options.properties.deceasedInsuredType.oneOf;
  }

  formInit() {
    this.claimNotificationForm = this._formBuilder.group({
      notificationMethod: new FormControl('1'),
      claimType: new FormControl('1'),
      deceasedParty: new FormControl(''),
      deceasedInsuredType: new FormControl(this.isAnnuity ? '0' : ''),
      deceasedFirstName: new FormControl(''),
      deceasedMiddleName: new FormControl(''),
      deceasedLastName: new FormControl(''),
      govtId: new FormControl(''),
      dateOfBirth: new FormControl(''),
      dateOfDeath: new FormControl(''),
      mannerOfDeath: new FormControl('0'),
      countryOfDeath: new FormControl('1'),
      deathInUSA: new FormControl('1')
    })
  }

  insuredInfo(e) {
    let rolesArray = ["Owner", "Joint Owner", "Participant", "Joint Participant", "Insured", "Joint Insured", "Annuitant", "Joint Annuitant","Additional Insured - Spouse"]
    if (e == undefined) {
      !this.isAnnuity ? this.claimNotificationForm.controls["deceasedInsuredType"].setValue("") : "";
      this.claimNotificationForm.get("deceasedInsuredType").enable();
      this.claimNotificationForm.controls["deceasedFirstName"].setValue("");
      this.claimNotificationForm.get("deceasedFirstName").enable();
      this.claimNotificationForm.controls["deceasedLastName"].setValue("");
      this.claimNotificationForm.get("deceasedLastName").enable();
      this.claimNotificationForm.controls["govtId"].setValue("");
      this.claimNotificationForm.controls["dateOfBirth"].setValue("");
    }
    else {
      this.roleInfo = _.filter(this.deceasedParty, function (o) { return o['enum'][0] == e });
      Configuration.config.headers["multiHostUrlHeader"].forEach(headerObj => {
        if (headerObj.headers.hasOwnProperty("remote_user") && headerObj.alias == 'person') {
          headerObj.headers.remote_user = null;
          headerObj.headers.role = null;
        }
      });
      let header = _.filter(Configuration.config.headers["multiHostUrlHeader"], function (o) { return o.alias == 'person' });
      OcInfraModule.AppInjector.get(CommonService).changeHeaders(header[0].headers);
      const resourceService = OcInfraModule.AppInjector.get(ResourceService);
      let roles = resourceService.getResourceFromAliasName('partyRoles');
      let roleInfo = this.roleInfo[0];
      let selectedRoleHref = _.filter(roles._links.item, function (o) { return o.summary.role_name_desc == roleInfo.tooltip; });
      if((selectedRoleHref == null ||selectedRoleHref.length==0) && (this.checkULType("U1") ||this.checkULType("U2") )
        &&  roleInfo.tooltip =="Insured"){
        selectedRoleHref = _.filter(roles._links.item, function (o) { return o.summary.role_name_desc == "Joint Insured"; });
      }
      if(selectedRoleHref .length  >1){
        let selectedRole =  _.filter(selectedRoleHref, function (o) { return o.summary.party_name === roleInfo.partyName.replace(/,/g,", ")});
        selectedRoleHref =selectedRole;
      }
      OcInfraModule.AppInjector.get(APICallerService).changeHeaders(header[0].headers);
      // Loader start when href has value
      if (selectedRoleHref[0].href) {
        OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: true });
      }
      OcInfraModule.AppInjector.get(APICallerService).get(selectedRoleHref[0].href).subscribe(response => {
        OcInfraModule.AppInjector.get(APICallerService).get(response.response._links.person.href).subscribe(response => {
          this.personDetails.emit(response.response);
          if (rolesArray.includes(this.roleInfo[0].tooltip)) {
            if((this.checkULType("U1")|| this.checkULType("U2") )&&  roleInfo.tooltip =="Additional Insured - Spouse"){
              this.claimNotificationForm.controls["deceasedInsuredType"].setValue('3');
            }
            else 
              this.claimNotificationForm.controls["deceasedInsuredType"].setValue('0');
            this.claimNotificationForm.get("deceasedInsuredType").disable();
          }
          else {
            this.claimNotificationForm.controls["deceasedInsuredType"].setValue("");
            this.claimNotificationForm.get("deceasedInsuredType").enable();
          }
          this.claimNotificationForm.controls["deceasedFirstName"].setValue(response.response.first_name);
          this.claimNotificationForm.get("deceasedFirstName").disable();
          this.claimNotificationForm.controls["deceasedLastName"].setValue(response.response.last_name);
          this.claimNotificationForm.get("deceasedLastName").disable();
          this.claimNotificationForm.controls["govtId"].setValue(response.response.ssn.replace(/(\d{3})(\d{2})(\d{3})/g, '$1-$2-$3'));
          this.claimNotificationForm.controls["dateOfBirth"].setValue(new Date(response.response.date_of_birth));
          OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: true });
        }),
          error => {
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: true });
            this.handleErrors(error);
          }
      }),
        error => {
          OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: true });
          this.handleErrors(error);
        }
    }
  }

  checkULType(productType) {
    return OcInfraModule.AppInjector.get(ResourceService)
        .getResourceFromAliasName('policyDetails').data.product_type
            .includes(productType);
}

  handleErrors(error) {
    OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error.error.message, 'Error', 0);
  }

  nextButton() {
    if(new Date(this.details.data.issue_date) >= this.claimNotificationForm.controls['dateOfDeath'].value){
      OcInfraModule.AppInjector.get(CommonService).showConfirmationPopup('Date Of Death must be greater than Policy Effective Date ', ' ', 'ok', 'alert', '300px');
    }else{
    // For navigating to next tab
    this.nextButtonEvent.emit();
    // Passing child data to parent   
    }
    this.claimNotificationDataEvent.emit(this.claimNotificationForm.value);
  }

  govtIdFormat(e): boolean {
    {
      if (e.target["value"].match(/[a-z]/i)) {
        e.target["value"] = '';
      }
      // Removing extra char when pasting
      if (e.keyCode == 86 || e.keyCode == 17) {
        e.target["value"] = e.target["value"].substring(0, 9);
      }
      if (e.keyCode > 31 && (e.keyCode < 48 || e.keyCode > 57)) {
        e.preventDefault();
      } else {
        let str = new String(e.target["value"]);
        str = str.replace(/[A-Za-z$-()\.]/g, '');
        str = str.replace(/[^0-9]/g, '');
        str = str.replace(/[^\d]/g, '');
        e.target["value"] = str.replace(/(\d{3})(\d{2})(\d{3})/g, '$1-$2-$3');
        return true;
      }
    }
  }

  isStepComplete() {
    return this.claimNotificationForm.valid;
  }

  disableForm() {
    this.claimNotificationForm.disable();
  }

}
