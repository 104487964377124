import {
    AfterViewInit,
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as quoteConstant from '../quote-loan-initiation/quote-loan-initiation.constant';
import { OcInfraModule } from '@diaas/ux-web';
import { CommonService } from '../../../services/common.service';

import { Subscription } from 'rxjs';

@Component({
    selector: 'app-loan-info-loan-initiation',
    templateUrl: './loan-info-loan-initiation.component.html',
    styleUrls: ['./loan-info-loan-initiation.component.scss'],
})
export class LoanInfoLoanInitiationComponent
    implements OnInit, AfterViewInit, OnDestroy {
        @Input() loanInitiationData: quoteConstant.LoanInfoInitiation;

        infoForm: FormGroup;
        apiLoanAmount: any;
        manualLoanAmount: any;
      
        private activeTab$: Subscription;
      
        maskSeparator = 'separator.2';
        separatorLimit = '9999999999';
        prefix = '$';
        decimalPlaces = 2;
      
        minLoanAmount = quoteConstant.allowableAmount.allowableMinAmount;
        maxLoanAmount = quoteConstant.allowableAmount.allowableMaxAmount;
      
        @ViewChild('federalWithholding') federalElement: ElementRef;
        @ViewChild('stateWithholding') stateElement: ElementRef;
        @ViewChild('maximumLoanAmt') maximumLoanElement: ElementRef;
        @ViewChild('loanInterestBeginDate') loanInterestBeginDateEle: ElementRef;
        @ViewChild('taxableGain') taxableGainEle: ElementRef;
      
        constructor(
          private formBuilder: FormBuilder,
          private commonService: CommonService
        ) {}
      
        ngOnInit() {
          this.infoFormInit();
          this.apiLoanAmount = this.loanInitiationData.maximum_loan_amount;
        }
      
        withholdingDisabled = (flag: boolean) => {
          this.disabled(this.federalElement, flag);
          this.disabled(this.stateElement, flag);
        };
      
        infoFormInit() {
          this.infoForm = this.formBuilder.group({
            enhanced_system: [false, [Validators.required]],
            full_amount: [false],
            loan_amount: [
              '',
              [
                Validators.required,
                Validators.min(this.minLoanAmount),
                Validators.max(this.maxLoanAmount),
              ],
            ],
            maximum_loan_amount: [this.loanInitiationData.maximum_loan_amount],
            taxable_gain: [this.loanInitiationData.taxable_gain],
            loan_interest_begin_date: [
              this.loanInitiationData.loan_interest_begin_date,
            ],
            net_gross_indicator: [''],
            override: [false],
            federal_withhold_amount: [this.loanInitiationData.federal_withhold_amount],
            state_withhold_amount: [this.loanInitiationData.state_withhold_amount],
          });
        }
      
        changeLoanAmountEvent = ($event: any) => {
          if ($event.checked === true) {
            this.disabled(this.maximumLoanElement, true);
            this.loan_amount = this.loanInitiationData.maximum_loan_amount;
            this.apiLoanAmount = this.loanInitiationData.maximum_loan_amount;
            this.loanAmountCalculate();
          } else {
            this.disabled(this.maximumLoanElement, false);
            this.loan_amount = '';
          }
        };
      
        deleteLoanAmountEvent = ($event: any) => {
          let value = $event.target.value;
          if (
            $event.key === 'Backspace' ||
            $event.key === 'Delete' ||
            ($event.key === 'x' && $event.ctrlKey === true)
          ) {
            this.manualLoanAmount = value.replace('$', '');
          }
        };
      
        get loan_amount() {
          return this.infoForm.get('loan_amount').value;
        }
      
        set loan_amount(val) {
          this.infoForm.get('loan_amount').setValue(val);
        }
      
        get override() {
          return this.infoForm.get('override').value;
        }
      
        get withHoldingAmount(): number {
          let federal = this.federal_withhold_amount
            ? this.federal_withhold_amount
            : 0;
          let state = this.state_withhold_amount ? this.state_withhold_amount : 0;
          let withHoldAmt = +federal + +state;
          return withHoldAmt;
        }
      
        get net_gross_indicator() {
          return this.infoForm.get('net_gross_indicator').value;
        }
      
        get fullAmountCheckbox() {
          return this.infoForm.get('full_amount').value;
        }
      
        get federal_withhold_amount() {
          return this.infoForm.get('federal_withhold_amount').value;
        }
      
        set federal_withhold_amount(val) {
          this.infoForm.get('federal_withhold_amount').setValue(val);
        }
      
        get state_withhold_amount() {
          return this.infoForm.get('state_withhold_amount').value;
        }
      
        set state_withhold_amount(val) {
          this.infoForm.get('state_withhold_amount').setValue(val);
        }
      
        disabled = (inputElement: ElementRef, flag: boolean) => {
          if (flag) {
            inputElement.nativeElement.setAttribute('disabled', 'true');
          } else {
            inputElement.nativeElement.removeAttribute('disabled');
          }
        };
      
        selectNetGrossIndicator() {
          this.loanAmountCalculate();
        }
      
        overrideCheckboxChange = ($event: any) => {
          if ($event.checked === true) {
            this.withholdingDisabled(false);
          } else {
            this.federal_withhold_amount = '';
            this.state_withhold_amount = '';
            this.withholdingDisabled(true);
          }
          this.loanAmountCalculate();
        };
      
        federalWithholdingEvent = () => {
          this.federal_withhold_amount = this.appendZerosIfNeeded(
            this.federal_withhold_amount
          );
          this.loanAmountCalculate();
        };
      
        stateWithholdingEvent = () => {
          this.state_withhold_amount = this.appendZerosIfNeeded(
            this.state_withhold_amount
          );
          this.loanAmountCalculate();
        };
      
        loanAmountCalculate = () => {
          let amount: any = '';
          if (this.loanInitiationData.mec_indicator == 'Y') {
            if (this.fullAmountCheckbox) {
              if (this.net_gross_indicator == 'Net') {
                amount =
                  this.apiLoanAmount -
                  +this.loanInitiationData.taxable_gain -
                  +this.withHoldingAmount;
              } else {
                amount = this.apiLoanAmount - +this.withHoldingAmount;
              }
            } else {
              if (this.net_gross_indicator == 'Net') {
                amount =
                  this.manualLoanAmount - +this.loanInitiationData.taxable_gain;
              } else {
                amount = this.manualLoanAmount;
              }
            }
          } else {
            if (this.fullAmountCheckbox) {
              this.manualLoanAmount = '';
              amount = this.apiLoanAmount - +this.withHoldingAmount;
            } else {
              this.manualLoanAmount = this.appendZerosIfNeeded(this.manualLoanAmount);
              amount = this.manualLoanAmount;
            }
          }
          this.loan_amount = this.appendZerosIfNeeded(amount);
        };
      
        checkLoanEligibleEvent = (value: any) => {
          this.loan_amount = this.appendZerosIfNeeded(this.loan_amount);
          if (this.loan_amount) {
            this.checkLoanEligibleListener(this.loan_amount, 200, true);
          }
        };
      
        private appendZerosIfNeeded(value) {
          if (
            this.decimalPlaces != null &&
            value != null &&
            value !== '' &&
            value !== '0.00'
          ) {
            value = (+value).toFixed(this.decimalPlaces);
            return value;
          }
        }
      
        checkLoanEligibleListener = (
          value: string,
          timeout: number,
          listener = false
        ) => {
          if (listener) {
            this.manualLoanAmount = +value;
            if (+value > quoteConstant.allowableAmount.allowableMaxAmount) {
              setTimeout(() => {
                OcInfraModule.AppInjector.get(CommonService).showConfirmationPopup(
                  'Amount Exceeds Allowable by Telephone',
                  'Close',
                  '',
                  'Alert',
                  '340px'
                );
              }, timeout);
            } else if (+value < quoteConstant.allowableAmount.allowableMinAmount) {
              setTimeout(() => {
                OcInfraModule.AppInjector.get(CommonService).showConfirmationPopup(
                  'Amount below allowable by Telephone',
                  'Close',
                  '',
                  'Alert',
                  '340px'
                );
              }, timeout);
            }
          }
        };
      
        ngAfterViewInit() {
          setTimeout(() => {
            this.activeTab$ = this.commonService.activeTab$.subscribe(
              (tab: number) => {
                if (tab === 1 && this.loan_amount) {
                  this.checkLoanEligibleListener(this.loan_amount, 500, true);
                }
              }
            );
            this.withholdingDisabled(true);
            this.disabled(this.loanInterestBeginDateEle, true);
            this.disabled(this.taxableGainEle, true);
          }, 0);
        }
      
        ngOnDestroy() {
          setTimeout(() => {
            // this.activeTab$.unsubscribe();
          }, 0);
        }
}
