import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { OcInfraModule, ResourceService, APICallerService } from '@diaas/ux-web';
import * as _ from 'lodash';
import { AdvGrowlService } from 'primeng-advanced-growl';

@Component({
  selector: 'correspondence-email',
  templateUrl: './correspondenceEmail.component.html',
})

export class CorrespondenceEmailComponent implements OnInit {

  constructor(private commonService: CommonService) { }
  emailAddress: string = '';
  authsucess: any = OcInfraModule.AppInjector.get(CommonService).authsucess;
  otherRoleSucess: any = OcInfraModule.AppInjector.get(CommonService).otherRoleAuthsucess;
  disableSendEmail: boolean = true;
  hoverMessage: string = '';
  emailClass: boolean = true;
  ngOnInit() {
    this.authsucess || this.otherRoleSucess ? this.hoverMessage = 'Enable button by completing required items' : this.hoverMessage = 'Caller was not authorized';
   setTimeout(() =>{
    OcInfraModule.AppInjector.get(CommonService).showHideSpinner({showSpinner: false, edit: false});
      }, 1000);
  }

 

  emailAddressChange(e) {
    let isEmailAddressValid = false;
    if( e.target.value.trim() != '' && 
      /^(([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25}(($)|( *;+ *$)|( *;+ *(?=[a-zA-Z0-9_\-\.]))))*$/.test(e.target.value.trim())){
        isEmailAddressValid = true;
    }
    isEmailAddressValid && (this.authsucess || this.otherRoleSucess) ? this.disableSendEmail = false : this.disableSendEmail = true;
    isEmailAddressValid ? this.emailClass = false : this.emailClass = true;
    (this.authsucess || this.otherRoleSucess ) && this.disableSendEmail ? this.hoverMessage = 'Enable button by entering semi-colon separated valid email address(es)':  this.hoverMessage = '';
  }

  sendEmail(e) {
    OcInfraModule.AppInjector.get(AdvGrowlService).clearMessages();
    const resourceService = OcInfraModule.AppInjector.get(ResourceService);
    let policyDetails = resourceService.getResourceFromAliasName('policyDetails');
    const selectedRoleSummary = resourceService.getResourceFromAliasName('selectedRoles'); 
    let workRequestDetails = resourceService.getResourceFromAliasName('workRequestHistory');
    let headers = this.commonService.getHeaderByAliasName("selectedWorkRequest");
    const workRequestHref = resourceService.getHrefByAliasName("workRequestHistory").split('?')[0];
    headers.statuaory_company_code = policyDetails.data.statutory_company_code;
    headers.company_code = policyDetails.data.company_code;
    delete headers.faxType;
    headers.emailType = "correspondence"; 
    headers.role = OcInfraModule.AppInjector.get(CommonService).selectedRole;
    headers.product_type = policyDetails.data.product_type;
    let payload =
    {
      "CLTY": "REPRINT",
      "POLN":(this.authsucess || this.otherRoleSucess ) ? policyDetails.data.policy_number : selectedRoleSummary.data.policyNumber,
      "attachedForms": [
        "CSRP-REPRINT"
      ],
      "letterType": "EFORMS",
      "batchValue":  this.emailAddress,
      "departmentName": "EFORMS",
      "lob": policyDetails.data.line_of_business_code,
      "additionalInfo": "AWDAutoClaim",
      "documentKeys": OcInfraModule.AppInjector.get(CommonService).correspondenceDoc,
    }
    OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false });
    OcInfraModule.AppInjector.get(CommonService).postCallback(workRequestDetails._links.email.href, payload, headers).subscribe(response => {
      if (response && response.message) {
        OcInfraModule.AppInjector.get(AdvGrowlService).createSuccessMessage(response.message, 'Success');
        let correspondenceCheckWrapper = document.querySelectorAll('#correspondenceTable .mat-checkbox input[type=checkbox]:checked');
        let correspondenceBtn = document.querySelectorAll('.correspondence-btn');
       
        Array.from(correspondenceBtn).forEach(btn => {
          btn['classList'].add('disabled')
          btn['disabled'] = true;
          btn[' ng-reflect-disabled'] = true;
        });
        document.getElementById("modal__cross").click();
        OcInfraModule.AppInjector.get(CommonService).correspondenceDoc = [];
        OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false });
        setTimeout(() => {
          OcInfraModule.AppInjector.get(APICallerService).refresh(workRequestHref, { 'alias': "workRequestHistory", 'headers': headers }).subscribe(refreshResponse => {
            OcInfraModule.AppInjector.get(CommonService).wrkReqHistoryRes = refreshResponse;
            OcInfraModule.AppInjector.get(CommonService).sendFilterEvent();
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
          });
        }, 5000);
        Array.from(correspondenceCheckWrapper).forEach(corres => {
          corres.dispatchEvent( new Event('click'));
        });
      } else {
        console.log('Error occurred while sending Email to Email Address(es)',response);
        OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage("Something went wrong", 'Error', 0);
        OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
        document.getElementById("modal__cross").click();
      }
    }, error => {
      OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
      error && error.message ? OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error.message, 'Error', 0) : OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage("Email was not sent successfully to Email Address(es)", 'Error', 0);
    })
  }
}
