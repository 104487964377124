import { Component, OnInit, Inject, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { OcInfraModule, Configuration, ResourceService } from '@diaas/ux-web';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatPaginator, MatTableDataSource, MatSort } from '@angular/material';
import { AdvGrowlService } from 'primeng-advanced-growl';
import { DatePipe } from '@angular/common';
import * as _ from 'lodash';
import { Sort } from '@angular/material';

export interface DialogData {
  fileSize: string;
  fileName: string;
}
export interface correspondenceListData {
  createDate: Date;
  description: string;
  comments: string;
}
@Component({
  selector: 'correspondence-table',
  templateUrl: './correspondenceTable.component.html',
  styleUrls: ['./correspondenceTable.component.scss']
})

export class CorrespondenceTableComponent implements OnInit {
  displayedColumns: string[] = ['date', 'type', 'status'];
  selectedDate: Object = {};
  correspondenceList: any[] = [];
  correspondenceListData: any;
  loanInterestRate: string = '';
  displayingDocumentType: string ='';
  fileSize: string;
  fileName: string;
  idToken: string;
  documentRes: Blob;
  date: any;
  value: any;
  selectedDocuments: any[] = [];
  disableCorrespondence: boolean = true;
  hoverMessage:string;
  correspondenceURL:any;
  loadMessage:boolean = true;
  isAnnuity: boolean = false;
  //private sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;



  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }

  constructor(private http: HttpClient, private dialog: MatDialog, private commonService: CommonService, public datePipe: DatePipe,
    public elementRef: ElementRef
    ) { };

  ngOnInit() {
    this.correspondenceURL = OcInfraModule.AppInjector.get(CommonService).getAllUrlOfPolicyDetails();
    const headers = Configuration.config.headers.defaultHeader;
    let resourceService = OcInfraModule.AppInjector.get(ResourceService);
    let policyDetails = resourceService.getResourceFromAliasName('policyDetails');
    this.isAnnuity = policyDetails.data.product_type.includes('A')? true : false ;
    headers.region_code = OcInfraModule.AppInjector.get(CommonService).productInfo.REGION;
  // headers.company_code = policyDetails.data.statutory_company_code;
    headers.statuaory_company_code = policyDetails.data.statutory_company_code;
    let businessArea = _.filter(Configuration.config.hostURL.multiHostUrl, function (o) { return o.alias == 'businessArea' });
    OcInfraModule.AppInjector.get(CommonService).getCallback(businessArea[0].url, headers).subscribe(response => {
      OcInfraModule.AppInjector.get(CommonService).businessArea = response.businessarea;
    })
    const isAuthorized = OcInfraModule.AppInjector.get(CommonService).authsucess||
    OcInfraModule.AppInjector.get(CommonService).otherRoleAuthsucess;
    this.hoverMessage =isAuthorized?'':'Caller not authorized';
    this.loadMessage= true;
   }

  getCorrespondence(){
    this.loadMessage= false;
    if (this.correspondenceURL["correspondence"] && this.correspondenceURL["correspondence"].href) {
      this.correspondenceList = [];
      this.correspondenceListData = new MatTableDataSource(this.correspondenceList);
      const headers = Configuration.config.headers.defaultHeader;
      OcInfraModule.AppInjector.get(CommonService).getCallback(this.correspondenceURL["correspondence"].href, headers).subscribe(response => {
        this.correspondenceList = response._embedded && response._embedded.docPreviews && response._embedded.docPreviews.length ? response._embedded.docPreviews : [];
        this.displayingDocumentType ='A';
        OcInfraModule.AppInjector.get(CommonService).correspondenceDoc =null;
        this.correspondenceListData = new MatTableDataSource(this.correspondenceList);
        this.correspondenceListData.paginator = this.paginator;
        this.sortData({ active: "date", direction: "asc" });
      });
    }
  }
  
  getArchiveCorrespondence(){
    this.loadMessage = false;
    if (this.correspondenceURL["correspondence/archive"] && this.correspondenceURL["correspondence/archive"].href) {
      this.correspondenceList = [];
      this.correspondenceListData = new MatTableDataSource(this.correspondenceList);
      const headers = Configuration.config.headers.defaultHeader;
      OcInfraModule.AppInjector.get(CommonService).getCallback(this.correspondenceURL["correspondence/archive"].href, headers).subscribe(response => {
        this.displayingDocumentType ='H';
        OcInfraModule.AppInjector.get(CommonService).correspondenceDoc =null;
        this.correspondenceList = response._embedded && response._embedded.docPreviews && response._embedded.docPreviews.length ? response._embedded.docPreviews : [];
        this.correspondenceListData = new MatTableDataSource(this.correspondenceList);
        this.correspondenceListData.paginator = this.paginator;
        this.sortData({ active: "date", direction: "asc" });
      });
    }
  }


  openFaxModel(e) {
    document.getElementById("correspondence-fax-open-correspondence-fax-anchor").click();
  }
  openEmailModal(e) {
    document.getElementById("correspondence-email-open-correspondence-email-anchor").click();
  }
  openMailModal(e) {
    document.getElementById("correspondence-mail-open-correspondence-mail-anchor").click();
  }

  abc(element){
    if(element.didNotMail==="Y" || element.didNotMail==="Y" ){
      return {'text-align':'left'};
    } else {
      return {'text-align':'center'}
    }
  }
  onCheckBoxChange(event, element) {
    const isAuthorized = OcInfraModule.AppInjector.get(CommonService).authsucess||
               OcInfraModule.AppInjector.get(CommonService).otherRoleAuthsucess;
    
      this.hoverMessage = !isAuthorized?'Caller not authorized':'';
    this.selectedDocuments = OcInfraModule.AppInjector.get(CommonService).correspondenceDoc ? OcInfraModule.AppInjector.get(CommonService).correspondenceDoc : [];
    if (event.checked) {
      this.selectedDocuments.push(element.docId+";"+this.displayingDocumentType);
      OcInfraModule.AppInjector.get(CommonService).correspondenceDoc = this.selectedDocuments;
      
      this.disableCorrespondence = isAuthorized && this.selectedDocuments.length ? false : true;
     
    } else {
      let selectedItem = this.selectedDocuments.filter(selectedDocumentId => {
        return selectedDocumentId == element.docId;
      })
      this.selectedDocuments.splice(this.selectedDocuments.indexOf(selectedItem), 1);
      OcInfraModule.AppInjector.get(CommonService).correspondenceDoc = this.selectedDocuments;
      this.disableCorrespondence = !isAuthorized? true: isAuthorized && this.selectedDocuments.length ? false: true;
    }
    Array.from(document.querySelectorAll("#correspondenceTable .correspondence-btn")).forEach(correspondenceButton => {
      var corrBtn= correspondenceButton as  HTMLInputElement;
      corrBtn.disabled = this.disableCorrespondence;
      if(this.elementRef.nativeElement.id == 'correspondenceTable' && this.commonService.isDuplicatePoliciesAction()){
        corrBtn.disabled = true;
        this.disableCorrespondence = true;
      } 
    });
  }

  onRowClick(data, rowData) {
    var documentURL = rowData._links.self.href.replace("http://", "https://");
    this.commonService.showHideSpinner({ showSpinner: true, edit: true });
    const headers = Configuration.config.headers.defaultHeader;
    this.http.get(documentURL, { headers, responseType: 'json' }).subscribe(response => {
      this.documentRes = this.b64toBlob(response["contents"]);
      this.fileSize = (this.documentRes.size / 1024).toFixed(2) + ' KB';
      this.fileName = response["title"];
      this.openConfirmationDialog();
     this.commonService.showHideSpinner({ showSpinner: false, edit: false });
    },
    error =>{
      this.commonService.showHideSpinner({ showSpinner: false, edit: false });
    });
  }

   b64toBlob(b64Data) {
    let contentType = 'application/pdf';
    let sliceSize = 512;
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
  
    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }



  openConfirmationDialog() {
    const dialogRef = this.dialog.open(CorrespondenceConfirmationDialog, {
      data: { fileName: this.fileName, fileSize: this.fileSize }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (this.documentRes) {
        this.downloadDocument(result);
      } else {
        OcInfraModule.AppInjector.get(AdvGrowlService).createErrorMessage("Something went wrong", 'Error');
      }
    });
  }

  downloadDocument(action) {
    var a = document.createElement("a");
    a.href = URL.createObjectURL(this.documentRes);
    if (action === 'open') {
      a.target = 'blank';
      a.click();
      a.remove();
    } else if (action === 'save') {
      a.download = this.fileName;
      a.click();
      a.remove();
    } else {
      a.remove()
    }
  }

  setDataSourceAttributes() {
    if (this.correspondenceListData) {
      this.correspondenceListData.paginator = this.paginator;
      this.correspondenceListData.sort = this.sort;
    }
  }

  sortedData: correspondenceListData[];
  sortData(sort) {
    const data = this.correspondenceListData.filteredData.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }
    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';

      switch (sort.active) {
        case 'date': return compare(a.date, b.date, isAsc);
        case 'type': return compare(a.type, b.type, isAsc);
        default: return 0;
      }
    });
  }

}


@Component({
  selector: 'correspondence-confirmation-dialog',
  templateUrl: 'correspondence-confirmation-dialog.html',
})
export class CorrespondenceConfirmationDialog {

  constructor(public dialogRef: MatDialogRef<CorrespondenceConfirmationDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private http: HttpClient) {

  }

  onOpen() {
    this.dialogRef.close('open');
  }

  onSave() {
    this.dialogRef.close('save');
  }

  closeDialog() {
    this.dialogRef.close('cancel');
  }

}
function compare(a: number | Date | string, b: number | Date | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
