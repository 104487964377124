import { ValidatorFn, Validators } from "@angular/forms";

export interface QuoteInitiationFormFieldModel {
    id: string;
    label: string;
    value: string;
    checked?: string;
    pipe?: string
}

export type datePicker = {
    effective_date: string;
    adjusted_paid_to_date: string;
}

export type LoanInformationInitiation = {
    modified_endowment_contact: string;
    pure_endowment_amt: number;
    guaranteed_cash_value: string;

    loan_interest_rate: number;
    loan_interest_from_date: string;
    loan_interest_to_date: string;

    loan_interest_type: string;
    death_benefit_amt: string;
    dwi_interest_amt: string;

    aibalance: number;
    rider_face_amt: number;
    yt_dividend_amt: string;
};

export type LoanValuationInitiation = {
    discounted_cash_value: string
    cv_of_eti_rpu: string
    cv_of_ai: string

    dwi_balance: string
    rider_cash_values: string
    annual_dividend_due: string

    loan_interest_refund: string
    maximum_loan_amount: string
    loan_principal: string

    loan_interest_due: string
    premium_due: string
    number_of_model_premiums: number

    dividend_interest_withholding: string
    cost_basis: string
};

export type LoanInfoInitiation = {
    maximum_loan_amount: number;
    taxable_gain: number;
    state_withhold_amount: string;
    federal_withhold_amount: string;
    loan_interest_begin_date: string;
    mec_indicator: string;
};

export type LoanPaymentInitiation = {
    first_name: string;
    last_name: string;
    address_line1: string;
    address_line2: string;
    address_line3: string;
    city: string;
    state: string;
    postal_code: string;
    country: string;

    role: string;
    eft_status: string;
    account_type: string;
    bank_account: string;
    bank_name: string;
    account_number: string;
    transit_number: string;

    eft_premium_verified: boolean;
    bank_account_verified: boolean;
    change_account_verified: boolean;
    bill_option: string;

    disbursementInd: string[];
    withhold_premium_indicator: string;
    eft_id: number;

    _options: Options

};

export type Options = {
    properties: Properties
}

export type Properties = {
    disbursementInd: DisbursementInd
    withhold_premium_indicator: WithholdPremiumIndicator
}

export type DisbursementInd = {
    type: string
    oneOf: OneOf[]
}
export type WithholdPremiumIndicator = {
    type: string
    oneOf: OneOf[]
}

export type OneOf = {
    title: string
    enum: string[]
}

export type LoanInitiation = LoanInformationInitiation & LoanValuationInitiation & datePicker & LoanPaymentInitiation;


export enum LoanInfo {
    modifiedEndowmentContact = 'modified_endowment_contact',
    pureEndowmentAmt = 'pure_endowment_amt',
    guaranteedCashValue = 'guaranteed_cash_value',

    loanInterestRate = 'loan_interest_rate',
    loanInterestFromDate = 'loan_interest_from_date',
    loanInterestToDate = 'loan_interest_to_date',

    loanInterestType = 'loan_interest_type',
    deathBenefitAmt = 'death_benefit_amt',
    dwiInterestAmt = 'dwi_interest_amt',

    aibalance = 'aibalance',
    riderFaceAmt = 'rider_face_amt',
    ytDividendAmt = 'yt_dividend_amt',
}


export enum LoanValue {
    discountedCashValue = 'discounted_cash_value',
    cvOfEtiRpu = 'cv_of_eti_rpu',
    cvOfAi = 'cv_of_ai',

    dwiBalance = 'dwi_balance',
    riderCashValues = 'rider_cash_values',
    annualDividendDue = 'annual_dividend_due',

    loanInterestRefund = 'loan_interest_refund',
    maximumLoanAmount = 'maximum_loan_amount',
    loanPrincipal = 'loan_principal',

    loanInterestDue = 'loan_interest_due',
    premiumDue = 'premium_due',
    numberOfModelPremiums = 'number_of_model_premiums',

    dividendInterestWithholding = 'dividend_interest_withholding',
    costBasis = 'cost_basis',
}




export const initInfoFormFields = () => {

    return [
        {
            id: LoanInfo.modifiedEndowmentContact,
            label: 'Modified Endowment Contract',
            value: '',
            checked: '',
        },
        {
            id: LoanInfo.pureEndowmentAmt,
            label: 'Pure Endowment Amount',
            value: '',
            checked: '',
            pipe: 'CURRENCY'
        },
        {
            id: LoanInfo.guaranteedCashValue,
            label: 'Guaranteed Cash Value',
            value: '',
            checked: '',
            pipe: 'CURRENCY'
        },
        {
            id: LoanInfo.loanInterestRate,
            label: 'Loan Interest Rate',
            value: '',
            checked: 'checked',
            pipe: 'PERCENTAGE'
        },
        {
            id: LoanInfo.loanInterestFromDate,
            label: 'Loan Interest From Date',
            value: '',
            checked: '',
        },
        {
            id: LoanInfo.loanInterestToDate,
            label: 'Loan Interest To Date',
            value: '',
            checked: 'checked',
        },
        {
            id: LoanInfo.loanInterestType,
            label: 'Loan Interest Type',
            value: '',
            checked: '',
        },
        {
            id: LoanInfo.deathBenefitAmt,
            label: 'Death Benefit Amount',
            value: '',
            checked: '',
            pipe: 'CURRENCY'
        },
        {
            id: LoanInfo.dwiInterestAmt,
            label: 'DWI Interest Amount',
            value: '',
            checked: '',
            pipe: 'CURRENCY'
        },
        {
            id: LoanInfo.aibalance,
            label: 'AI Balance',
            value: '',
            checked: '',
            pipe: 'CURRENCY'
        },
        {
            id: LoanInfo.riderFaceAmt,
            label: 'Rider Face Amount',
            value: '',
            checked: '',
            pipe: 'CURRENCY'
        },
        {
            id: LoanInfo.ytDividendAmt,
            label: '1YT Dividend Amount',
            value: '',
            checked: '',
            pipe: 'CURRENCY'
        }
    ];

}

export const initValueFormFields = () => {

    return [
        {
            id: LoanValue.discountedCashValue,
            label: 'Discounted Cash Value',
            value: '',
            checked: '',
            pipe: 'CURRENCY'
        },
        {
            id: LoanValue.cvOfEtiRpu,
            label: 'CV of ETI/RPU',
            value: '',
            checked: '',
            pipe: 'CURRENCY'
        },
        {
            id: LoanValue.cvOfAi,
            label: 'CV Of AI',
            value: '',
            checked: '',
            pipe: 'CURRENCY'
        },
        {
            id: LoanValue.dwiBalance,
            label: 'DWI Balance',
            value: '',
            checked: '',
            pipe: 'CURRENCY'
        },
        {
            id: LoanValue.riderCashValues,
            label: 'Rider Cash Values',
            value: '',
            checked: '',
            pipe: 'CURRENCY'
        },
        {
            id: LoanValue.annualDividendDue,
            label: 'Annual Dividend Due',
            value: '',
            checked: '',
            pipe: 'CURRENCY'
        },
        {
            id: LoanValue.loanInterestRefund,
            label: 'Loan Interest Refund',
            value: '',
            checked: '',
            pipe: 'CURRENCY'
        },
        {
            id: LoanValue.maximumLoanAmount,
            label: 'Maximum Loan Amount',
            value: '',
            checked: 'checked',
            pipe: 'CURRENCY'
        },
        {
            id: LoanValue.loanPrincipal,
            label: 'Loan Principal',
            value: '',
            checked: '',
            pipe: 'CURRENCY'
        },
        {
            id: LoanValue.loanInterestDue,
            label: 'Loan Interest Due',
            value: '',
            checked: '',
            pipe: 'CURRENCY'
        },
        {
            id: LoanValue.premiumDue,
            label: 'Premium Due',
            value: '',
            checked: '',
            pipe: 'CURRENCY'
        },
        {
            id: LoanValue.numberOfModelPremiums,
            label: 'Number of Modal Premiums',
            value: '',
            checked: '',
        },
        {
            id: LoanValue.dividendInterestWithholding,
            label: 'Dividend Interest Withholding',
            value: '',
            checked: '',
            pipe: 'CURRENCY'
        },
        {
            id: LoanValue.costBasis,
            label: 'Cost Basis',
            value: '',
            checked: '',
            pipe: 'CURRENCY'
        },
    ];

}



export const datePickerValues = () => {

    return [{
        id: 'effective_date',
        label: 'Effective Date',
        value: '',
        checked: 'checked',
    },
    {
        id: 'adjusted_paid_to_date',
        label: 'Adjusted Paid to Date',
        value: '',
        checked: 'checked',
    }]
}

export const allowableAmount = {
    allowableMaxAmount: 24999.99,
    allowableMinAmount: 20
}
// disbursement
export const disbursementList: { label: string; value: string }[] = [
    { label: 'Cash', value: 'C' },
    { label: 'EFT', value: 'E' },
];

// withhold_premium_indicator
export const withholdList: { label: string; value: string }[] = [
    { label: 'N-Do Not Withhold Premiums', value: 'n' },
    { label: 'Y-Pay Premiums To Next Modal Due Date', value: 'y' },
];

interface MenuItems {
    label: string;
    value: string;
}

export interface DynamicFormFieldModel {
    id: string;
    type: 'text' | 'select' | 'checkbox' | 'hr' | 'label';
    value?: any;
    label?: string;
    validators?: ValidatorFn[];
    change?: boolean;
    keypress?: string;
    selectMenuOptions?: MenuItems[];
    hide?: boolean;
    disable?: boolean;
    required?: boolean;
    class?: string
}

export const cashFormFields = [
    {
        id: 'role',
        value: '',
        type: 'text',
        label: 'Role',
        disable: true,
        hide: true
    },
    {
        id: 'first_name',
        value: '',
        type: 'text',
        label: 'First Name',
        change: true,
        disable: true
    },
    {
        id: 'last_name',
        value: '',
        type: 'text',
        label: 'Last Name',
        disable: true
    },
    {
        id: 'address_line1',
        value: '',
        type: 'text',
        label: 'Address Line 1',
        disable: true
    },
    {
        id: 'address_line2',
        value: '',
        type: 'text',
        label: 'Address Line 2',
        disable: true
    },
    {
        id: 'address_line3',
        value: '',
        type: 'text',
        label: 'Address Line 3',
        disable: true
    },
    {
        id: 'city',
        value: '',
        type: 'text',
        label: 'City',
        disable: true
    },
    {
        id: 'state',
        value: '',
        type: 'text',
        label: 'State',
        disable: true
    },
    {
        id: 'postal_code',
        value: '',
        type: 'text',
        label: 'Postal Code',
        disable: true
    },
    {
        id: 'country',
        value: '',
        type: 'text',
        label: 'Country',
        disable: true
    },
    {
        id: 'address_line4',
        value: '',
        type: 'text',
        label: 'Address Line 4',
        disable: true,
        hide: true,
      },
];

export const eftFormFields = [
    
    {
        id: 'eft_status_desc',
        value: '',
        type: 'text',
        label: 'EFT Status',
        disable: true
    },
    {
        id: 'account_type_desc',
        value: '',
        type: 'text',
        label: 'Account Type',
        disable: true
    },
    {
        id: 'role',
        value: '',
        type: 'text',
        label: 'Role',
        disable: true
    },
    
    {
        id: 'bank_name',
        value: '',
        type: 'text',
        label: 'Bank Name',
        disable: true
    },
    {
        id: 'account_number',
        value: '',
        type: 'text',
        label: 'Account Number',
        disable: true
    },
    {
        id: 'transit_number',
        value: '',
        type: 'text',
        label: 'Routing / Transit',
        disable: true
    },
    {
        id: 'premium_payment_verified',
        value: false,
        type: 'checkbox',
        label: ' EFT-Two Premium Payments Verified',
        validators: [Validators.required],
        required: true,
    },
    {
        id: 'bank_account_verified',
        value: false,
        type: 'checkbox',
        label: ' Bank Account Verified',
        validators: [Validators.required],
        required: true,
    },
    {
        id: 'change_account_verified',
        value: true,
        type: 'checkbox',
        label: ' Change Account Verified for 60 days',
        validators: [Validators.required],
        required: true,
    },
    {
        id: 'account_type',
        value: '',
        type: 'text',
        label: '',
        disable: true,
        hide: true,
      },
      {
        id: 'eft_status',
        value: '',
        type: 'text',
        label: '',
        disable: true,
        hide: true
    },
    {
        id: 'eft_id',
        value: '',
        type: 'text',
        label: '',
        disable: true,
        hide: true
    },
];


export const confirmFormFields: DynamicFormFieldModel[] = [
    {
      id: 'effective_date',
      label: 'Effective Date:',
      value: '',
      type: 'label',
    },
    {
      id: 'confirmation_number',
      label: 'Confirmation number:',
      value: '',
      type: 'label',
    },
    {
      id: 'disbursementInd',
      label: 'Disbursement:',
      value: '',
      type: 'label',
    },
    {
      id: 'loan_amount',
      label: 'Amount Requested:',
      value: '',
      type: 'label',
    },
    {
      id: 'federal_withhold_amount',
      label: 'Federal Witholding Amount:',
      value: '',
      type: 'label',
    },
    {
      id: 'state_withhold_amount',
      label: 'State Witholding Amount:',
      value: '',
      type: 'label',
      class: 'state_withhold',
    },
    {
      id: 'hr',
      label: '',
      value: '',
      type: 'hr',
      class: '',
    },
    {
      id: 'impact',
      label: 'Discussed Impact of loan',
      value: false,
      type: 'checkbox',
      validators: [Validators.required],
      required: true,
    },
    {
      id: 'repayment_option',
      label: 'Discussed Repayment Options',
      value: false,
      type: 'checkbox',
      validators: [Validators.required],
      required: true,
    },
    {
      id: 'time_expectation',
      label: 'Provided Time Expectancies',
      value: false,
      type: 'checkbox',
      validators: [Validators.required],
      required: true,
    },
  ];


  export const warningCodes = ["WR05", "WR06", "WR31", "WR33", "WR53", "W688", "WR50", "W371", "WL11", "WL22", "WX94"];
  export type ToastType = 'success' | 'info' | 'warning' | 'wait' | 'error';
