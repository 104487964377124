  
import { Directive, HostListener } from '@angular/core';
import { OcInfraModule } from '@diaas/ux-web';
import { CommonService } from '../../services/common.service';

@Directive({
  selector: 'backButtonPropagation'
})
export class BackButtonDirective {

    @HostListener('window:popstate', ['$event'])
    onBrowserBackBtnClose(event: Event) {
      event.stopPropagation();
      event.preventDefault(); 
      OcInfraModule.AppInjector.get(CommonService).backButton = true;
    }
}