import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../../services/common.service';
import * as _ from 'lodash';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MustMatch, greaterThan } from '../../helpers/_fieldsMustMatch';
import { APP_DATE_FORMATS, AppDateAdapter } from '../../format-datepicker';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { AdvGrowlService } from 'primeng-advanced-growl';
import { APICallerService, Configuration, OcInfraModule, ResourceService } from '@diaas/ux-web';
import { DatePipe } from '@angular/common';

export class RegularExpressionConstant {
    static EMAIL: RegExp = /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
}

@Component({
  selector: 'app-plan-report',
  templateUrl: './plan-report.component.html',
  styleUrls: ['./plan-report.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  ]
})
export class PlanReportComponent implements OnInit {
  planReportForm: FormGroup;
  showErrorMessageEmail: boolean = false;
  showErrorMessageConfirmEmail: boolean = false;
  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  minDate: object = {};
  isDisabled: boolean = true;
  resourceService = OcInfraModule.AppInjector.get(ResourceService);
  selectedIdentity = this.resourceService.getResourceFromAliasName('selectedRoles');
  reportsPayload: Object = {
    "role": this.selectedIdentity.data.role ? this.selectedIdentity.data.role : OcInfraModule.AppInjector.get(CommonService).otherRole ? OcInfraModule.AppInjector.get(CommonService).otherRole  : "",
    "businessArea": "METLIFE",
    "productType": "A3",
    "userRole": ["CSR", "OLSCSR"],
    "csrRole": "CSR"
  }
  // legacySystem : this.commonService.productInfo.legacy_system_ind ? this.commonService.productInfo.legacy_system_ind : "",
    postPayload: Object = { role: this.selectedIdentity.data.role ? this.selectedIdentity.data.role : OcInfraModule.AppInjector.get(CommonService).otherRole ? OcInfraModule.AppInjector.get(CommonService).otherRole : "", "businessArea": "METLIFE", "productType": "A3", "userRole": ["CSR", "OLSCSR"], "csrRole": "CSR" };
  reportItems: any[] = [];
  headers = this.commonService.getHeaderByAliasName("planReporting");
  selectedItemRes: any;
  isPlanReportSelected: string = "";
  productInfo = this.commonService.productInfo;
  postCallPayload: {};
  companyCodes: any;
  getCallRes: any;
  planReportName: any;
  selectedDate: string;

  isTPAAvailable :boolean = false;
  
  
  
  constructor(public commonService: CommonService, private datePipe: DatePipe, private _formBuilder: FormBuilder) { }

  ngOnInit() {
    this.formInit();
    const resourceService = OcInfraModule.AppInjector.get(ResourceService);
    const policyDetails = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyDetails');
    if( policyDetails && policyDetails.data.product_type.includes('A') && this.selectedIdentity.data.role =='Agent' ){
      this.getCallRes ={};
      this.getCallRes.company_code = policyDetails.data.company_code;
      this.planReportForm.controls['reportDate'].value == "" ? this.planReportForm.controls["reportDate"].setValue(policyDetails.data.aart_date ? new Date(policyDetails.data.aart_date) : "") : "";
      this.companyCodes = [{ id: policyDetails.data.company_code.toLowerCase(), title:policyDetails.data.company_code }];
      // this.commonService.postCallback("https://pmlsitcsroth003.pano-dxc.com/csrPortal-rules/v1/rules/requestForms/types/execute", this.postPayload, this.headers).subscribe(response => {
      //   response.links.forEach(obj => {
      this.commonService.postCallback(policyDetails._links.planReportingDroolsExecute.href, this.postPayload, this.headers).subscribe(response => {
          response._links.item.forEach(obj => {
          obj.summary ? this.reportItems.push({ label: obj.summary.label, name: obj.summary.name, href: obj.href }) : "";
        });
        this.getCallRes.client_no = this.selectedIdentity.data.groupNumber? this.selectedIdentity.data.groupNumber :'';
      }, error => {
        this.commonService.showHideSpinner({ showSpinner: false, edit: false });
        console.log(error)
        OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage("Failed to create Plan Report. Please try again.", 'Error', 0);
      });
      
    }else{
      let clientUrl = null;
      if(this.productInfo)
        clientUrl =_.filter(this.productInfo.links, function (o) { return o.rel == "client" })[0];

      const otherRole = OcInfraModule.AppInjector.get(CommonService).otherRoleSummary ;
      
       if(policyDetails && 
          (OcInfraModule.AppInjector.get(CommonService).authsucess &&this.selectedIdentity.data.groupNumber !=null && this.selectedIdentity.data.groupNumber.length>0 )
          ||( OcInfraModule.AppInjector.get(CommonService).otherRoleAuthsucess && otherRole && otherRole.groupNumber!=null && otherRole.groupNumber.length>0 )){
            let roleList = resourceService.getResourceFromAliasName('roles');
            if(OcInfraModule.AppInjector.get(CommonService).authsucess){
              const selectedRoleString= OcInfraModule.AppInjector.get(CommonService).selectedRole 
              let selectedRoleSummary = _.filter(roleList._links.item, function (o) { return o.summary.role.toLowerCase() == selectedRoleString.toLowerCase() });
              clientUrl =selectedRoleSummary[0].summary._links.client;
            }else if(OcInfraModule.AppInjector.get(CommonService).otherRoleAuthsucess){
              clientUrl = otherRole._links.client;
            }
      }
      if(clientUrl){
        this.commonService.getCallback(clientUrl.href, this.headers).subscribe(response => {
          this.getCallRes = response;
          this.planReportForm.controls['reportDate'].value == "" ? this.planReportForm.controls["reportDate"].setValue(this.getCallRes.effective_date ? new Date(this.getCallRes.effective_date) : "") : "";
          this.companyCodes = response._options.properties.company_code.oneOf.map(item => { return { id: item.enum[0], title: item.title } });
          
          Object.assign(this.postPayload, { legacySystemInd: this.getCallRes.legacy_ind });
          this.commonService.postCallback(response._links.reportURI.href, this.postPayload, this.headers).subscribe(response => {
            response._links.item.forEach(obj => {
              obj.summary ? this.reportItems.push({ label: obj.summary.label, name: obj.summary.name, href: obj.href }) : "";
            });
          }, error => {
            this.commonService.showHideSpinner({ showSpinner: false, edit: false });
            console.log(error)
            OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage("Failed to create Plan Report. Please try again.", 'Error', 0);
          });
        }, error => {
          let errMsg = this.getNested(error._embedded, 'status_report', 'messages');
          this.commonService.showHideSpinner({ showSpinner: false, edit: false });
          errMsg[0].message ? OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(errMsg[0].message, 'Error', 0) : OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage("Failed to create Plan Report. Please try again.", 'Error', 0);
        });
      }
    }
  }

  formInit() {
    this.planReportForm = this._formBuilder.group({
      planReporting: ['', Validators.required],
      mailId: ['', [Validators.required, Validators.pattern(RegularExpressionConstant.EMAIL)]],
      confirmMailId: ['', [Validators.required, Validators.pattern(RegularExpressionConstant.EMAIL)]],
      date: [''],
      fromDate: [''],
      toDate: [{ value: '', disabled: this.isDisabled }],
      reportDate: [""],
      companyCode: ['', Validators.required],
    }, {
      validator: [MustMatch('mailId', 'confirmMailId'), greaterThan('fromDate', 'toDate')]
    });
  }

  get f() { return this.planReportForm.controls; }

  emailValidation(formControl, formControlValue) {
    formControl.controls[formControlValue].touched = true;
    formControl.controls[formControlValue].touched = true;
  }

  fromDateChanged(e) {
    let toDateField = this.planReportForm.controls["toDate"];
    toDateField.setValue("");
    this.planReportForm.controls["fromDate"].errors ? toDateField.disable() : toDateField.enable();
    let fromDate = this.planReportForm.controls["fromDate"].value;
    this.minDate = new Date(
      fromDate.getFullYear(),
      fromDate.getMonth(),
      fromDate.getDate()
    );
  }

  dateFormat(e) {
    this.fromDateChanged(e);
  }

  planReportChange(e) {
    this.isPlanReportSelected = e.value.label;
    this.planReportName = e.value.name;
    e.value.label ? this.commonService.postCallback(e.value.href, this.reportsPayload, this.headers).subscribe(response => {
      this.selectedItemRes = response ? response[this.planReportName] ? response[this.planReportName] ? response[this.planReportName].properties : "" : "" : "";
      const resourceService = OcInfraModule.AppInjector.get(ResourceService);
      let roleDetails = resourceService.getResourceFromAliasName('selectedRoles');
      if(roleDetails && roleDetails.data && roleDetails.data.agentNIPR)
      this.getCallRes.agentNIPRId = roleDetails.data.agentNIPR ? roleDetails.data.agentNIPR : '';
      this.selectedFormInit();

        if (this.selectedIdentity && this.selectedIdentity.data && this.selectedIdentity.data.tpaCode && this.planReportName == "TPAReport") {
            this.getCallRes.tpaCode = this.selectedIdentity.data.tpaCode;
            this.isTPAAvailable = true;
        } else {
            this.isTPAAvailable = false;
        }

    }, error => {
      this.commonService.showHideSpinner({ showSpinner: false, edit: false });
      OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage("Failed to create plan report. Please try again.", 'Error', 0);
    }) : "";
  }


  selectedFormInit() {
    this.planReportForm.get('mailId').setValue('');
    this.planReportForm.get('confirmMailId').setValue('');

    if (this.selectedItemRes.reportDate && this.selectedItemRes.reportDate.isReadonly == true) {
      this.planReportForm.get('reportDate').disable({ onlySelf: true });
    } else {
      this.planReportForm.get('reportDate').enable();
    }
    if(this.selectedItemRes.date){
      this.planReportForm.controls['date'].setValidators([Validators.required]);
    } else {
        this.planReportForm.controls['date'].setValidators(null);
    }
    if (this.companyCodes && this.companyCodes.length == 1) {
        this.planReportForm.get('companyCode').setValue(this.getCallRes.company_code.toLowerCase());
        this.planReportForm.get('companyCode').disable({ onlySelf: true });
    }
    this.planReportForm.get('date').setValue('');
    this.selectedItemRes.reportDate ? this.planReportForm.controls["reportDate"].setErrors({ required: true }) : this.planReportForm.controls["reportDate"].setErrors(null);

    this.planReportForm.get('fromDate').setValue('');
    this.selectedItemRes.fromDate ? this.planReportForm.controls["fromDate"].setErrors({ required: true }) : this.planReportForm.controls["fromDate"].setErrors(null);

    this.planReportForm.get('toDate').setValue('');
    this.planReportForm.get('toDate').disable({ onlySelf: true });
    this.selectedItemRes.fromDate ? this.planReportForm.controls["toDate"].setErrors({ required: true }) : this.planReportForm.controls["toDate"].setErrors(null);    
  }

  getNested(obj, ...args) {
    return args.reduce((obj, level) => obj && obj[level], obj)
  }

  onRadioChange(e) {
    this.selectedDate = e;
  }

  onSubmit = () => {
    let alertMsgUrl = _.filter(this.productInfo.links, function (o) { return o.rel == "planReport" });
    if(!alertMsgUrl || alertMsgUrl.length==0){
      const policyDetails = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyDetails');
      alertMsgUrl = policyDetails ? [policyDetails._links.planReporting]:null;
      if(!alertMsgUrl){
        OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage("Failed to create Plan Report. Missing URL", 'Error', 0)
        return;
      }
    }
    
    this.postCallPayload = {
      "groupNumber": this.getCallRes.client_no,
      "companyCode": this.getCallRes.company_code,
      "reportName": this.planReportName,
      "legacySystemIndicator": this.getCallRes.legacy_ind,
      "addrLine": this.planReportForm.controls["confirmMailId"].value,
      "remote_user": this.headers.remote_user
    }
    this.selectedItemRes.reportDate && Object.assign(this.postCallPayload, { reportDate: this.datePipe.transform(this.planReportForm.controls['reportDate'].value, "MM/dd/yyyy") });
    this.selectedItemRes.fromDate && Object.assign(this.postCallPayload, {
      startDate: this.datePipe.transform(this.planReportForm.controls['toDate'].value, "MM/dd/yyyy"),
      endDate: this.datePipe.transform(this.planReportForm.controls['fromDate'].value, "MM/dd/yyyy"),
    })
    if( this.planReportName == "TPAReport" && this.selectedIdentity.data.tpaCode ){
      Object.assign(this.postCallPayload, { tpaName: this.selectedIdentity.data.thirdPartyFullName, tpaCode :this.selectedIdentity.data.tpaCode })  
    }    
    this.selectedItemRes.date && Object.assign(this.postCallPayload, { [this.planReportForm.controls.date.value]: "yes", });
    if(this.getCallRes.agentNIPRId) {
        this.postCallPayload = {...this.postCallPayload,  ...{ agentNIPRId: this.getCallRes.agentNIPRId }};
    }
    OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false });
    this.commonService.postCallback(alertMsgUrl[0].href, this.postCallPayload, this.headers).subscribe(response => {
      OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
      OcInfraModule.AppInjector.get(AdvGrowlService).createSuccessMessage(response.message ? response.message : "Plan Report Request created successfully.", 'Success');
      document.getElementById('modal__cross').click();
    }, error => {
      this.commonService.showHideSpinner({ showSpinner: false, edit: false });
      error && error.messages[0] && error.messages[0] && error.messages[0] ? OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error.messages[0].message, 'Error', 0) : OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage("Failed to create Plan Report. Please try again.", 'Error', 0);
    });
  }

  ngOnDestroy() {
    this.isPlanReportSelected = "";
  }

}
