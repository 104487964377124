export const hostURL = {
    defaultHostUrl: 'https://dev.pano.csr-portal.us.insurance.dxc.com/contracts/',

    multiHostUrl: [
        {
            alias: 'types',
            url: 'https://dev.pano.csr-portal.us.insurance.dxc.com/casemanagement/'
        },
        {
            alias: 'authorize',
            url: 'https://dev.pano.csr-portal.us.insurance.dxc.com/contacts-call/bpocs/contact'
        },
        {
            alias: 'workitemsSchema',
            url: 'https://dev.pano.csr-portal.us.insurance.dxc.com/casemanagement/'
        },
        {
            alias: 'PPLK102U01870684',
            url: 'https://dev.pano.csr-portal.us.insurance.dxc.com/contacts-call/bpocs/contact/hostkey/'
        },
        {
            alias: 'searchContracts',
            url: 'https://dev.pano.csr-portal.us.insurance.dxc.com/contacts-call/bpowex/'
        },
        {
            alias: 'contractsCollection',
            url: 'https://dev.pano.csr-portal.us.insurance.dxc.com/contacts-call/bpowex/'
        },
        {
            alias: 'searchAgents',
            url: 'https://dev.pano.csr-portal.us.insurance.dxc.com/contacts-call/bpowex/'
        },
        {
            alias: 'agentCollection',
            url: 'https://dev.pano.csr-portal.us.insurance.dxc.com/contacts-call/bpowex/'
        },
        {
            alias: 'searchClients',
            url: 'https://dev.pano.csr-portal.us.insurance.dxc.com/contacts-call/bpowex/'
        },
        {
            alias: 'clientCollection',
            url: 'https://dev.pano.csr-portal.us.insurance.dxc.com/contacts-call/bpowex/'
        },
        {
            alias: 'callHistory',
            url: 'https://dev.pano.csr-portal.us.insurance.dxc.com/contacts-call/bpocs/contacts?user_id='
        },
        {
            alias: 'instances',
            url: 'https://dev.pano.csr-portal.us.insurance.dxc.com/casemanagement/user/'
        },
        {
            alias: 'authentication',
            url: 'https://dev.gateway.api.dxc.technology/csc-insurance-api-development/omnichannel-dev/ocdevel/authenticate'
        },
        {
            alias: 'logout',
            url: 'https://dev.gateway.api.dxc.technology/csc-insurance-api-development/omnichannel-dev/ocdevel/sessions?_action=logout'
        },
        {
            alias: 'userProfile',
            url: 'https://dev.gateway.api.dxc.technology/csc-insurance-api-development/omnichannel-dev/ocdevel/users/'
        },
        {
            alias: 'pmaAuthenticate',
            url: 'https://dev.gateway.api.dxc.technology/csc-insurance-api-development/omnichannel-dev/omnichannel/dev/contract-documents/pma-service-dev/authenticate'
        },
        {
            alias: 'csrDocuments',
            url: 'https://dev.pano.csr-portal.us.insurance.dxc.com/help-documents/csr.pano.helpdocuments/'
        },
        {
            alias: 'olsCsrDocuments',
            url: 'https://dev.pano.csr-portal.us.insurance.dxc.com/help-documents/ols.csr.helpdocuments/'
        },
        {
            alias: 's3bucket',
            url: 'https://dev.pano.csr-portal.us.insurance.dxc.com/help-documents/csr.pano.helpdocuments/'
        },
        {
            alias: 'businessArea',
            url: 'https://dev.pano.csr-portal.us.insurance.dxc.com/casemanagement/businessarea'
        },
        {
            alias: 'refreshtoken',
            url: 'https://cognito-idp.us-east-1.amazonaws.com'
        },
        {
            alias: 'mfaSendToken',
            url: 'https://dev.pano.csr-portal.us.insurance.dxc.com/contracts/mfa-send'
        },
        {
            alias: 'mfaValidateToken',
            url: 'https://dev.pano.csr-portal.us.insurance.dxc.com/contracts/mfa-validate'
        },
        {
            alias: 'mfaOverrideDropDownValues',
            url: 'https://dev.pano.csr-portal.us.insurance.dxc.com/casemanagement/mfaOverride'
        }
    ]
}
