import { Component, OnInit, EventEmitter, Output, ElementRef, ViewChild } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { PartySearchFactory } from '../../factories/partySearchFactory';
import { CommonService } from '../../services/common.service';
import {Sort} from '@angular/material';
import { MatPaginator, MatTableDataSource, MatSort } from '@angular/material';
import * as historyColumnsConstant from './groupLoanHistoryTable.constant'
import { ResourceService, OcInfraModule, APICallerService, OptionParams } from '@diaas/ux-web';
import * as _ from 'lodash';


@Component({
  selector: 'group-loan-history-table',
  templateUrl: './groupLoanHistoryTable.component.html',
})

export class GroupLoanHistoryTableComponent implements OnInit {
  displayedColumns: string[] = historyColumnsConstant.DisplayedHistoryTableColumns;
  selectedDate: Object = {};
  noRecords: boolean = true;
  loanData: historyColumnsConstant.LoanHistoryData[];
  panoPolicyDetails: boolean = false;
  LoanHistoryData:any;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }
  
  constructor(public elementRef: ElementRef, public commonService: CommonService){
    this.commonService.getClickEvent().subscribe(() =>{
      this.getLoanHistoryData();
    })
  }

  ngOnInit() {
    this.loanData = [];
    this.LoanHistoryData = new MatTableDataSource(this.loanData);
  }

  getLoanHistoryData() {
    this.panoPolicyDetails = true;
    var loanHistoryURL = OcInfraModule.AppInjector.get(CommonService).getAllUrlOfPolicyDetails();
    if (loanHistoryURL["loans/loanhistoryAnnuites"] && loanHistoryURL["loans/loanhistoryAnnuites"].href) {
      let optionParams = new OptionParams();
      optionParams.headers = OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("loanHistory");
      this.loanData = [];
      this.LoanHistoryData = new MatTableDataSource(this.loanData);
      OcInfraModule.AppInjector.get(CommonService).getCallback(loanHistoryURL["loans/loanhistoryAnnuites"].href, optionParams.headers).subscribe(response => {
         if(response && response._links.item){
          response._links.item.forEach(headerObj => {
          headerObj.trnsaction_date = new Date(headerObj.summary.trnsaction_date);
            });
        this.loanData = response._links.item;
        this.LoanHistoryData = new MatTableDataSource(this.loanData);
        this.LoanHistoryData.paginator = this.paginator;
        this.sortedData = response._embedded ? [] : response._links.item;
        this.sortData({ active: "trnsaction_date", direction: "asc" });
      }
      this.noRecords = !this.sortedData || !this.sortedData.length;
      });
    }
  }

  setDataSourceAttributes() {
    if (this.LoanHistoryData) {
      this.LoanHistoryData.paginator = this.paginator;
      this.LoanHistoryData.sort = this.sort;
    }
  }

  
  sortedData: historyColumnsConstant.LoanHistoryData[];
  sortData(sort) {
    const data = this.LoanHistoryData.filteredData.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }
    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';

      switch (sort.active) {
        case 'trnsaction_date': return compare(a.summary.trnsaction_date, b.summary.trnsaction_date, isAsc);
        case 'loan_type': return compare(a.summary.loan_type, b.summary.loan_type, isAsc);
        case 'loan_id': return compare(a.summary.loan_id, b.summary.loan_id, isAsc);
        default: return 0;
      }
    });

    this.LoanHistoryData = new MatTableDataSource(this.sortedData);

    //sort.direction == '' ? sort.direction = 'asc' :'';
    // const data = this.loanData.slice();
    // if (!sort.active || sort.direction === '') {
    //   this.LoanHistoryData = data;
    //   return 0;
    // }
    // this.LoanHistoryData = data.sort((a, b) => {
    //   const isAsc = sort.direction === 'asc';
    //   switch (sort.active) {
    //     case 'trnsaction_date': return compare(a.summary.trnsaction_date, b.summary.trnsaction_date, isAsc);
    //     default: return sort.active;
    //   }
    // });
  }
}

function compare(a: number | Date | string, b: number | Date | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
