import { getTestBed } from '@angular/core/testing';
import { throwError as observableThrowError, Observable, Subject, BehaviorSubject } from 'rxjs';
import { share, map, catchError, finalize } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Http } from "@angular/http";
import { HttpClient } from '@angular/common/http';
import { EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { OcInfraModule, APICallerService, Configuration, ResourceService, IAlias, ResourceDirectoryService, OptionParams } from '@diaas/ux-web';
import { MatDialog } from '@angular/material';
import { DialogComponent, DialogConfig } from '../components/dialog.component/dialog-component';
import { ConfirmdialogComponent, confirmDialogConfig } from './../components/confirmdialog/confirmdialog.component';
import { WithdrawalSurrenderAction } from '../components/withdrawlsSurrender/withdrawalSurrenderAction';
import * as _ from 'lodash';
import { AdvGrowlService } from 'primeng-advanced-growl';

@Injectable()
export class CommonService {
    public selectedWorkItemType: string;
    public selectedWorkItemValue: string;
    public selectedOther: boolean;
    public selectedChangeRole: boolean;
    public selectedRole: string;
    public selectedRoleObj = [];
    public authorizedRole: string;
    public workRequestList: string;
    public showBilling: boolean;
    public showLoan: boolean;
    public authsucess: boolean = false;
    public mfaAuthorized: boolean = false;
    public mfaApplicable: boolean = false;
    public notesMsg: boolean;
    public corporateOwner: boolean;
    public selectedRoleFirst : any;
    public requestedParams : any;
    public otherRoleAuthsucess: boolean = false;
    public otherRoleSummary: any;
    public changeotherRoleAuthsucess: boolean = false;
    public changeRoleAuthsucess: boolean = false;
    public selectedLanguage: object;
    public selectedReason: string = "";
    public otherRole: "";
    public policyHistoryUrl: string;
    public selectdFilter: string;
    public deathClaimProperties: object;
    public selectedCallHistoryDetails: object;
    public historicalCoiTransactionType: any;
    public outGoingMoneyStatus: any;
    public outGoingMoneyTransactionType: any;
    public paymentHistoryTransactionType: any;
    public paymentHistoryStatus: any;
    public showHistoricalCoiFilter: boolean = true;
    public agentPolicyDetailAlert: boolean = false;
    public showPaymentHistoryFilter: boolean = true;
    public showOutGoingMoneyFilter: boolean = true;
    public showFundsFilter: boolean = true;
    public historicalCoiTransactionTypeFilter: any;
    public outGoingMoneyTransactionTypeFilter: any;
    public outGoingMoneystatusFilter: any;
    public fundsType: any;
    public fundsTypeFilter: any;
    public paymentHistoryTransactionTypeFilter: any;
    public paymentHistorystatusFilter: any;
    public hostKey: any;
    public showOtherRoleSection: boolean;
    public showChangeRoleButton: boolean;
    public authResponse: any;
    public deathClaimRequiredProperties: Array<String>;
    public selectedCoverages = [];
    public billingHtml: string = "";
    public nfoHtml: string = "";
    public detailsHtml: string = "";
    public quoteInitiationHtml: string = "";
    public quoteInitiation: any;
    public quoteValuesHtml: string = "";
    public coverageHtml: string = "";
    public notesHtml: string = "";
    public contractholderHtml: string = "";
    public generalLoanHtml: string = "";
    public planAdminHtml: string = "";
    public assetAccountHtml: string = "";
    public beneficiaryHtml: string = "";
    public quoteDate: string = "";
    public loansDate: string = "";
    public loanDate: string = "";
    public billing: boolean;
    public billingAcceleratedPayment: boolean = false;
    public funds: boolean;
    public incontactId: string = "";
    public incontactEmailId: string = "";
    public fullUserName: string = "";
    public awdUserId: string = "";
    public selectdCurrencyFields = [];
    public suspenseMsg: boolean;
    public suspenseHtml: string = "";
    public sponsorHtml: string = "";
    public sponsorHclientHtml: string = "";
    public sponsorDirectoryIdHtml: string = "";
    public sponsorNameAddressHtml: string = "";
    public revolvingHtml: string = "";
    public groupValuesHtml: string = "";
    public groupLoansHtml: string = "";
    public groupLoansQuoteHtml: string = "";
    public checkedRevolvingError = [];
    public checkedSuspenseHistory = [];
    public checkedSponsorDetails = [];
    public checkedcontractHolderDetails = [];
    public checkedAssetAccountDetails = [];
    public checkedPlanAdminDetails = [];
    public checkedloanRulesDetails = [];
    public checkedDirectoryIdDetails = [];
    public checkedNameOrAddressSponsorDetails = [];
    public loanHtml: string = "";
    public loanFinalHtml: string = "";
    public fundHtml: string = "";
    public fundFutureHtml: string = "";
    public productInfo: any;
    public bannerAlertArray = [];
    public newBannerAlertAarray = [];
    public partyEmailInformation: any;
    public partyPhoneNumberInformation: any;
    public emailHref: string = "";
    public addressHref: string = "";
    public phoneNumberhref: string = "";
    public billingMethod: boolean = false;
    public result123: boolean;
    public personURL: string = "";
    public quoteValuesFinalHtml: string = "";
    public loanMesseageHtml: string = "";
    public valuesMesseage: any;
    public loanMesseage: any = "";
    public loanMessageId: any = "";
    public quoteMesseageHtml: any = "";
    public fundMessageHtml: string = "";
    public fundFinalHtml: string = "";
    public futureFundFinalHtml: string = "";
    public quoteDetailsHtml: string = "";
    public rmdquoteDetailsHtml: string = "";
    public paramDetails: any;
    public selectedWorkItem: string = "";
    public partyResponse: any;
    public claimDetails: any;
    public isAnnuity: boolean;
    public callBoadData: boolean = true;
    public backButton: boolean = false;
    public additionalPolicies: any;
    public selectedSourceForFax = [];
    public formsList: any;
    public filterProperties = [];
    public role: string = "";
    public correspondenceDoc: [];
    public fundResponse:any;
    public businessArea: string = "";
    public escalateWorkRequest: boolean = false;
    public confirmationNo: boolean = false;
    public selectedWorkItemParams: any;
    public currentPolicy: any;
    public oAmount: any;
    public otherKey: boolean = false;
    public deliveryMethod: boolean = false;
    public disableTermandFia: boolean = false;
    public csrUser: boolean = false;
    public olsUser: boolean = false;
    public agentTable: boolean = true;
    public customerTable: boolean = true;
    public termAndFia = ["A2", "A5"];
    public selectedAgentSummary: any;
    public aSearchValue: any;
    public searchAgentUrl: any;
    public customerDetails: any = {};
    public agentRoleDetails: any = {};
    public customerRoleDetails: any;
    public searchResults: any;
    public searchContractsValue: any = "";
    public aobVisible: boolean = false;
    public userRole: any;
    public attachedFiles: any;
    public loanInterestRate: any;
    public currentRoute: any;
    public tir2Ticket: boolean = false;
    public isOlsAnnuity: boolean = false;
    public isOlsIul: boolean = false;
    public customerBanner: boolean = true;
    public agentBanner: boolean = true;
    public confirmation: boolean = false;
    public agentBannerAlertMessage: any;
    public otherRoleCallerName: string = "";
    public instanceParams: any;
    public policyNumber: any;
    public allTransactions: any = {};
    public checkHistoryUrl: any;
    
    public fullSurrenderFields: {};
    public preFillIndicator: boolean = false;
    public quoteValues: any;
    public rmdquoteValues: any;
    public withdrawalSurrenderAction: WithdrawalSurrenderAction =
        new WithdrawalSurrenderAction();
    public selectedWithdrawlSurrenderType: string = "";
    public selectedScreen : string='';
    public selectedScreenAction : any = {};
    public isMfaClosedManuallyWithoutVerification : boolean = false;
    public mfaCallNotesMessage : string = '';
    public notesAdditionHtml: string = '';
    public createClaim: boolean = false;
    public claimAssociateSuccess: boolean = false;
    public claimBenefitSuccess: boolean = false;
    public claimPartyInformation :any = [];
    public claimAlert: boolean = true;
    public dividendAlert: boolean = true;
    commentsUrl: any;
    public eobDetails: any;
    public dividendAlertbanner: boolean = false;
    public attachDocWorkReq: boolean = false;
    public attachSourcesUrl: any;
    public postalCodeLength: boolean = false;
    public otherRoleNameText: string = "";
    public withdrawalSurrenderType: string = "";
    public checkedFields = {
        IN: [],
        TR: [],
        DA: [],
        ST: [],
        RB: [],
    };
    public selectedGroupDropdownValues: any = {};
    public checkedValuesObject: any = {};
    public checkedLoansObject: any = {};
    public checkedLoanQuoteObject: any = {};

    public assetAccountValuesResp: any = null;
    public clientProducts: any = null;
    public clientAssetAccounts: any = null;
    public clientAssetAccountsAll: any = null;
    public canPerformGroupLoanQuote: boolean =false;
    public wrkReqHistoryRes: any;
    public claimsTableRes: any;
    public financialHistoryRes: any;

    public fundDetails : any;

    //status to keep track of phone email changes
    public rolePhoneChanged: boolean = false;
    public roleEmailChanged: boolean = false;
    public roleAddressUpdated: boolean = false;

    public isAttachDoc: boolean = false;    
    public isWorkNotCompleted: boolean = false;
    public _AnnuityProduct = ['A1', 'A2', 'A3', 'A4'];
    public _LifeProduct = ['U1', 'U2', 'T1'];
    public _Owner = 'Owner';
    public _Annuity = 'Annuitant';
    public _Insured = 'Insured';
    public resetFlag: boolean = false;  
  
    public resetSelectedReason() {
        OcInfraModule.AppInjector.get(CommonService).selectedReason = '';
        OcInfraModule.AppInjector.get(CommonService).resetFlag = true;  
    }
  
    public clearResetFlag() {
        OcInfraModule.AppInjector.get(CommonService).resetFlag = false;  
    }

    //update Save Button Status
    public saveButtonStatus = new BehaviorSubject<boolean>(false);
    updateSaveButtonStatus = (status: boolean) => {
        this.saveButtonStatus.next(status);
    };
    // Updating dropdown in Payments Tab
    public drpDownStatus = new BehaviorSubject<boolean>(false);
    updateWithdrawalChngeStatus = (status: boolean) => {
        this.drpDownStatus.next(status);
    };

    // Confirmation tab click
    public widthDrawSurrender = new BehaviorSubject<boolean>(false);
    isConfirmationClick = (obj) => {
        this.widthDrawSurrender.next(obj);
    };

    public associateButtonStatus = new BehaviorSubject<boolean>(false);
    updateAssociateButtonStatus = (status: boolean) => {
        this.associateButtonStatus.next(status);
    };

    // Re instatement quote section starts here
    public selectedDateObj = {};
    public total_remit_due;
    disableReinstatementCheckbox() {
        let reIntatementQuote = document.getElementById(
            "billing-info-total-remit-due-action-checkbox-input"
        );
        if (typeof reIntatementQuote != "undefined" && reIntatementQuote != null) {
            (reIntatementQuote as HTMLInputElement).disabled = true;
        }
    }

    private notes = new Subject<any>();
    refreshResponse$ = this.notes.asObservable();
    updateRefreshResponse(response: any) {
        this.notes.next(response);  // Notify subscribers
      }

    // Re instatement quote section ends here
    public loanInitiation: boolean = true;

    confirm$: Observable<boolean>;
    private currentTab = new BehaviorSubject<number>(0);
    activeTab$ = this.currentTab.asObservable();

    private allForms = new BehaviorSubject<any>({});
    getAllForms$ = this.allForms.asObservable();

    private allRMDForms = new BehaviorSubject<any>({});
    getAllRMDForms$ = this.allRMDForms.asObservable();

    public rmdHtml: string = "";
    public rmdDetails: any;
    public fullSurrHtml: string ="";
    public fullSurrDetails: any;
    public systematicWithdrawalHtml: string = "";
    public mfaMessageHtml: string = "";
    public notesAddHtml: string = "";
    public systematicWithdrawalDetails: any;


    financialClickId: string = '';
    financialClick: boolean = false;
    withdrawlTypes = ["DIVIDEND_WITHDRAWAL", "FULL_SURRENDER", "SYSTEMATIC_WITHDRAWL", "PARTIAL_SURRENDER"];

    public updateClaimPartyUrl: string = '';

    constructor(
        private http: Http,
        private httpClient: HttpClient,
        private router: Router,
        private dialog: MatDialog
    ) {
        this.getSelectedLangJson("en");
        this.withdrawalSurrenderAction = new WithdrawalSurrenderAction();
    }

    showConfirmation(content, closeLabel, okLabel, title, width) {
        const dialog: DialogConfig = {
            title: title,
            content: content,
            close: closeLabel,
            ok: okLabel ? okLabel : "",
            width: width,
        };
        const dialogRef = this.dialog.open(DialogComponent, {
            width: dialog.width,
            data: dialog,
            disableClose: true,
        });
        return dialogRef;
    }

    public showConfirmationPopup(content, closeLabel, okLabel, title, width) {
        const dialog: confirmDialogConfig = {
            content: content,
            close: closeLabel,
            ok: okLabel,
            title: title,
            width: width,
        };
        const dialogRef = this.dialog.open(ConfirmdialogComponent, {
            width: dialog.width,
            data: dialog,
        });
        return dialogRef;
    }

    @Output() change: EventEmitter<boolean> = new EventEmitter();
    @Output() showHideBannerAlert: EventEmitter<object> = new EventEmitter();
    @Output() showHideCustomSpinner: EventEmitter<object> = new EventEmitter();
    public alertMessaggeHideShow = new BehaviorSubject([]);
    @Output() bannerAlertId: EventEmitter<any> = new EventEmitter();

    public postCallback(url: string, body: Object, headers: any) {
        this.showHideSpinner({ showSpinner: true, edit: true });
        return this.http.post(url, body ? body : {}, { headers: headers }).pipe(
            map((response) => {
                return response.json();
            }),
            share(),
            catchError((error) => observableThrowError(error.json())),
            finalize(() =>
                setTimeout(() => {
                    this.showHideSpinner({ showSpinner: false, edit: true });
                }, 5000)
            )
        );
    }

    public getCallback(url: string, headers: any, params?: any) {
        this.showHideSpinner({ showSpinner: true, edit: true });
        return this.http.get(url, {headers: headers, params: params}).pipe(
            map((response) => {
                return response.json();
            }),
            share(),
            catchError((error) => observableThrowError(error.json())),
            finalize(() =>
                setTimeout(() => {
                    this.showHideSpinner({ showSpinner: false, edit: true });
                }, 5000)
            )
        );
    }

    public deleteCallback(url: string, headers: any) {
        this.showHideSpinner({ showSpinner: true, edit: true });
        return this.http.delete(url, { headers: headers }).pipe(
            map((response) => {
                return response.json();
            }),
            share(),
            catchError((error) => observableThrowError(error.json())),
            finalize(() =>
                setTimeout(() => {
                    this.showHideSpinner({ showSpinner: false, edit: true });
                }, 1000)
            )
        );
    }

    public patchCallback(url: string, body: Object, headers: any): Observable<any> {
        this.showHideSpinner({ showSpinner: true, edit: true });
        return this.http.patch(url, body ? body : {}, { headers: headers }).pipe(
          map((response: any) => {
            return response.json(); 
          }),
          share(),
          catchError((error) => {
            let errorResponse: any;
            try {
              errorResponse = error.json(); 
            } catch (e) {
              errorResponse = error; 
            }
            return observableThrowError({ ...errorResponse, statusCode: error.status });
          }),
          finalize(() =>
            setTimeout(() => {
              this.showHideSpinner({ showSpinner: false, edit: true });
            }, 5000)
          )
        );
      }

    public optionCallback(url: string, headers: any) {
        this.showHideSpinner({ showSpinner: true, edit: true });
        return this.http.options(url, { headers: headers }).pipe(
            map((response) => {
                return response.json();
            }),
            share(),
            catchError((error) => observableThrowError(error.json())),
            finalize(() =>
                setTimeout(() => {
                    this.showHideSpinner({ showSpinner: false, edit: true });
                }, 5000)
            )
        );
    }

    startCall() {
        this.change.emit(true);
    }

    getSelectedLangJson(lang) {
        this.http
            .get("../../assets/i18n/" + lang + ".json")
            .subscribe((response) => {
                this.selectedLanguage = response.json();
            });
    }

    routerService() {
        return this.router;
    }

    resetPolicyDetails() {
        OcInfraModule.AppInjector.get(CommonService).csrUser
            ? this.delteResources(["policyDetails", "selectedRoles"])
            : null;
        // this.delteResources(['policyDetails','selectedRoles'])
        OcInfraModule.AppInjector.get(CommonService).changeHeaders({
            role: null,
            remote_user: null,
            incontact_user: null,
        });
        this.authsucess = false;
        this.mfaAuthorized= false;
        this.mfaApplicable = false;
        this.otherRoleAuthsucess = false;
        this.otherRoleSummary = null;
        this.changeotherRoleAuthsucess = false;
        this.changeRoleAuthsucess = false;
        this.selectedOther = false;
        this.selectedChangeRole = false;
        this.notesMsg = false;
        this.suspenseMsg = false;
        this.showOtherRoleSection = false;
        this.showChangeRoleButton = false;
        this.selectedReason = "";
        this.billingHtml = "";
        this.nfoHtml = "";
        this.quoteValuesHtml = "";
        this.detailsHtml = "";
        this.quoteInitiationHtml = "";
        this.coverageHtml = "";
        this.loanHtml = "";
        this.loanFinalHtml = "";
        this.fundHtml = "";
        this.fundFutureHtml = "";
        this.notesHtml = "";
        this.contractholderHtml = "";
        this.generalLoanHtml = "";
        this.planAdminHtml = "";
        this.assetAccountHtml = "";
        this.selectedCoverages = [];
        this.selectdCurrencyFields = [];
        this.quoteDate = "";
        this.loansDate = "";
        this.loanDate = "";
        this.suspenseHtml = "";
        this.sponsorHtml = "";
        this.sponsorHclientHtml = "";
        this.sponsorDirectoryIdHtml = "";
        this.sponsorNameAddressHtml = "";
        this.groupValuesHtml = "";
        this.groupLoansHtml = "";
        this.groupLoansQuoteHtml ="";
        this.billing = false;
        this.funds = false;
        this.selectedRole = "";
        this.authorizedRole = "";
        this.checkedRevolvingError = [];
        this.checkedSuspenseHistory = [];
        this.checkedSponsorDetails = [];
        this.checkedcontractHolderDetails = [];
        this.checkedAssetAccountDetails = [];
        this.checkedPlanAdminDetails = [];
        this.checkedloanRulesDetails = [];
        this.checkedDirectoryIdDetails = [];
        this.checkedNameOrAddressSponsorDetails = [];
        this.revolvingHtml = "";
        this.productInfo = [];
        this.partyEmailInformation = [];
        this.partyPhoneNumberInformation = [];
        this.emailHref = "";
        this.addressHref = "";
        this.phoneNumberhref = "";
        this.billingMethod = false;
        this.valuesMesseage = "";
        this.quoteValuesFinalHtml = "";
        this.quoteMesseageHtml = "";
        this.loanMesseageHtml = "";
        this.fundMessageHtml = "";
        this.fundFinalHtml = "";
        this.futureFundFinalHtml = "";
        this.beneficiaryHtml = "";
        this.selectedWorkItem = "";
        this.filterProperties = [];
        this.role = "";
        this.correspondenceDoc = [];
        this.businessArea = "";
        this.escalateWorkRequest = false;
        this.confirmationNo = false;
        this.deliveryMethod = false;
        this.oAmount = "";
        this.otherKey = false;
        this.agentRoleDetails = {};
        this.otherRole = "";
        this.attachedFiles = [];
        this.tir2Ticket = false;
        this.customerBanner = true;
        this.agentBanner = true;
        this.otherRoleCallerName = "";
        this.instanceParams = [];
        this.authResponse = {};
        this.commentsUrl = "";
        this.agentBannerAlertMessage = "";
        this.quoteValues = {};
        this.rmdquoteValues = {};
        this.quoteDetailsHtml = "";
        this.rmdquoteDetailsHtml = "";
        this.eobDetails = [];
        this.attachDocWorkReq = false;
        this.attachSourcesUrl = "";
        this.selectedGroupDropdownValues = {};
        this.checkedValuesObject = {};
        this.checkedLoansObject = {};
        this.checkedLoanQuoteObject = {};

        this.assetAccountValuesResp = null;
        this.clientProducts = null;
        this.clientAssetAccounts = null;
        this.clientAssetAccountsAll = null;
        this.canPerformGroupLoanQuote = false;

        this.rmdHtml = '';
        this.fullSurrHtml = '';
        this.systematicWithdrawalHtml = '';
        this.mfaMessageHtml = '',
        this.notesAddHtml = '',
        this.quoteInitiation = {};
        
        localStorage.clear();
        this.resetSelectdRoles();
        this.checkedFields = {
            IN: [],
            TR: [],
            DA: [],
            ST: [],
            RB: [],
        };

        this.updateClaimPartyUrl = '';
        this.rmdDetails = {};
        this.systematicWithdrawalDetails = {};
        this.mfaDataReset();
    }

    deleteResources(aliasList) {
        let resource: any = JSON.parse(
            window.sessionStorage.getItem("resourceDirectory")
        );
        if (resource) {
            const resourceDirectoryService = OcInfraModule.AppInjector.get(
                ResourceDirectoryService
            );
            resource.forEach((alias) => {
                for (let i = 0; i <= aliasList.length; i++) {
                    alias.alias == aliasList[i]
                        ? resourceDirectoryService.deleteElementResourceDirectory(
                            alias.href
                        )
                        : "";
                }
            });
        }
    }
    setHeaderByAliasName(alias, newHeader) {
        Configuration.config.headers["multiHostUrlHeader"].forEach(
            (currentHeaders) => {
                if (currentHeaders.alias === alias) {
                    currentHeaders.headers = Object.assign(
                        currentHeaders.headers,
                        newHeader
                    );
                }
            }
        );
    }

    getHeaderByAliasName(alias) {
        const filteredHeader = Configuration.config.headers[
            "multiHostUrlHeader"
        ].filter((headerObj) => {
            return headerObj.alias === alias;
        });
        return filteredHeader[0]
            ? filteredHeader[0].headers
            : Configuration.config.headers.defaultHeader;
    }

    public changeHeaders(newHeader) {
        const configHeader: any = Configuration.config.headers;
        let localHeaders = configHeader.defaultHeader;
        Object.keys(newHeader).forEach(function (key, value) {
            if (newHeader[key] === null) {
                delete localHeaders[key];
            } else {
                localHeaders[key] == newHeader["key"];
            }
        });
    }


    public showEditIcon(alias, visibility) {
        this[alias] = visibility;
    }

    public getAllUrlOfPolicyDetails() {
        let policyDetails =
            OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName(
                "policyDetails"
            );
        if (policyDetails && policyDetails._links) {
            return policyDetails._links;
        } else {
            return false;
        }
    }

    public getFinalHtml() {
        return (
            this.detailsHtml +
            this.sponsorHtml +
            this.sponsorHclientHtml +
            this.sponsorDirectoryIdHtml +
            this.sponsorNameAddressHtml +
            this.contractholderHtml +
            this.assetAccountHtml +
            this.planAdminHtml +
            this.generalLoanHtml +
            this.groupValuesHtml +
            this.groupLoansHtml +
            this.groupLoansQuoteHtml +
            this.coverageHtml +
            this.billingHtml +
            this.nfoHtml +
            this.quoteValuesHtml +
            this.loanFinalHtml +
            this.fundFinalHtml +
            this.futureFundFinalHtml +
            this.suspenseHtml +
            this.revolvingHtml +
            this.notesHtml +
            this.quoteDetailsHtml +
            this.rmdquoteDetailsHtml +
            this.beneficiaryHtml +
            this.quoteInitiationHtml +
            this.rmdHtml +
            this.fullSurrHtml +
            this.systematicWithdrawalHtml +
            this.mfaMessageHtml+
            this.notesAddHtml
        );
    }

    public showLoanFinalDetails() {
        if (OcInfraModule.AppInjector.get(CommonService).loanHtml != "") {
            OcInfraModule.AppInjector.get(CommonService).loanFinalHtml =
                '<dl class="pad-lAr-10px">' +
                ' <span style="font-size:15px">LOAN</span><dt class="pad-lAr-10px">' +
                OcInfraModule.AppInjector.get(CommonService).loanHtml +
                "<dt>" +
                "</dl>";
        } else {
            OcInfraModule.AppInjector.get(CommonService).loanFinalHtml = "";
        }
    }

    public showSponsorFinalDetails() {
        if (OcInfraModule.AppInjector.get(CommonService).sponsorHtml != "") {
            OcInfraModule.AppInjector.get(CommonService).sponsorHtml =
                '<dl class="pad-lAr-10px">' +
                ' <span style="font-size:15px">SPONSOR</span><dt class="pad-lAr-10px">' +
                OcInfraModule.AppInjector.get(CommonService).sponsorHtml +
                "<dt>" +
                "</dl>";
        } else {
            OcInfraModule.AppInjector.get(CommonService).sponsorHtml = "";
        }
    }

    public showfundFinalDetails() {
        if (
            OcInfraModule.AppInjector.get(CommonService).fundHtml != "" ||
            this.fundMessageHtml
        ) {
            OcInfraModule.AppInjector.get(CommonService).fundFinalHtml =
                ' <span class="pad-lAr-10px" style="font-size:15px;font-weight: 200;" class="">FUNDS</span><br>' +
                OcInfraModule.AppInjector.get(CommonService).fundMessageHtml +
                OcInfraModule.AppInjector.get(CommonService).fundHtml;
        }
        else {
            OcInfraModule.AppInjector.get(CommonService).fundFinalHtml = "";
        }
    }

    public showCurrentfundDetails() {
        if (
            OcInfraModule.AppInjector.get(CommonService).fundHtml != "" ||
            this.fundMessageHtml
        ) {
            OcInfraModule.AppInjector.get(CommonService).fundFinalHtml =
                ' <span class="pad-lAr-10px" style="font-size:15px;font-weight: 200;" class="">FUNDS</span><br>' +
                ' <span class="pad-lAr-10px" style="font-size:15px;font-weight: 200;" class="">CURRENT FUNDS</span>' +
                OcInfraModule.AppInjector.get(CommonService).fundMessageHtml +
                OcInfraModule.AppInjector.get(CommonService).fundHtml;
        }
        else {
            OcInfraModule.AppInjector.get(CommonService).fundFinalHtml = "";
        }
    }


    public showFutureFundDetails() {
        if(OcInfraModule.AppInjector.get(CommonService).fundFutureHtml != "" || this.fundMessageHtml) {
            OcInfraModule.AppInjector.get(CommonService).futureFundFinalHtml =
            ' <span class="pad-lAr-10px" style="font-size:15px;font-weight: 200;" class="">FUTURE FUNDS</span>' +
            OcInfraModule.AppInjector.get(CommonService).fundMessageHtml +
            OcInfraModule.AppInjector.get(CommonService).fundFutureHtml;
        } else {
            OcInfraModule.AppInjector.get(CommonService).futureFundFinalHtml = "";
        }
    }

    public showRequestedDetails() {
        let finalHtml = OcInfraModule.AppInjector.get(CommonService).getFinalHtml();
        let requestedDetailSectionBilling = document.getElementsByClassName(
            "requested_details_section_billing"
        )[0];
        requestedDetailSectionBilling.innerHTML = finalHtml;
    }

    public convertToCurrency(amount) {
        return new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 2,
        }).format(amount);
    }

    public checkFormatCustomComponent(event, field, apiName, columnName) {
        let check =
            OcInfraModule.AppInjector.get(CommonService).selectdCurrencyFields;
        var URL =
            OcInfraModule.AppInjector.get(CommonService).getAllUrlOfPolicyDetails();
        let getResourceByHref = OcInfraModule.AppInjector.get(APICallerService).get(
            URL[apiName].href
        );
        if (event.checked) {
            if (
                getResourceByHref.value.response._options.properties[columnName] &&
                getResourceByHref.value.response._options.properties[columnName]
                    .format &&
                getResourceByHref.value.response._options.properties[columnName]
                    .format == "currency"
            ) {
                check.push(field);
                OcInfraModule.AppInjector.get(CommonService).selectdCurrencyFields =
                    check;
            }
        }
    }

    openSourceDocument(sourceURL, selectedWorkRequestHeaders) {
        if (sourceURL && selectedWorkRequestHeaders) {
            this.httpClient
                .get(sourceURL, {
                    headers: selectedWorkRequestHeaders,
                    responseType: "blob",
                })
                .subscribe((response) => {
                    var a = document.createElement("a");
                    a.href = URL.createObjectURL(response);
                    a.download = "sourceDocument";
                    a.click();
                    a.remove();
                });
        }
    }

    myCallHistory() {
        OcInfraModule.AppInjector.get(CommonService).loanMesseage = " ";
        const resourceDirectoryService = OcInfraModule.AppInjector.get(
            ResourceDirectoryService
        );
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        const aliasToDelete: IAlias =
            resourceService.getAliasByAliasName("callHistory");
        if (aliasToDelete) {
            resourceDirectoryService.deleteElementResourceDirectory(
                aliasToDelete.href
            );
        }
        resourceService.updateCreateAliasName(aliasToDelete.id, "", true);
        let callHistoryUrl = _.filter(
            Configuration.config.hostURL.multiHostUrl,
            function (o) {
                return o.alias == "callHistory";
            }
        );
      let myCallHistoryUrl = callHistoryUrl[0].url.substr(0, callHistoryUrl[0].url.lastIndexOf('?'));
      let optionParams = new OptionParams();
      optionParams.params = [];
      const pairs = callHistoryUrl[0].url.split('?')[1].split('&');
      const queryParam = pairs[0].split('=');
      let value = OcInfraModule.AppInjector.get(CommonService).incontactId;
      optionParams.params.push({ key: queryParam[0], value: value });
      optionParams.alias='callHistory';
      optionParams.headers = OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("search");
      OcInfraModule.AppInjector.get(APICallerService).refresh(myCallHistoryUrl, optionParams).subscribe(refreshResponse => {
                });
    }

    resetSelectdRoles() {
        const resourceDirectoryService = OcInfraModule.AppInjector.get(
            ResourceDirectoryService
        );
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        const aliasToDelete: IAlias =
            resourceService.getAliasByAliasName("selectedRoles");
        if (aliasToDelete) {
            resourceDirectoryService.deleteElementResourceDirectory(
                aliasToDelete.href
            );
        }
    }

    pushBannerAlertMessage(statusReport) {
        if (statusReport.messages && statusReport.messages.length) {
            this.bannerAlertArray.push(statusReport);
            this.showHideBannerAlert.emit(this.bannerAlertArray);
        }
    }

    pushAlertBannerMessage(statusReport) {
        if (statusReport.messages && statusReport.messages.length) {
            this.newBannerAlertAarray.push(statusReport);
            this.alertMessaggeHideShow.next(this.newBannerAlertAarray);
        }
    }

    getMessages() {
        return this.alertMessaggeHideShow.asObservable();
    }

    public formatDate(date) {
        let newdate = new Date(date);
        return (
            newdate.getMonth() +
            1 +
            "/" +
            newdate.getDate() +
            "/" +
            newdate.getFullYear()
        );
    }

    public showHideSpinner(params) {
        this.showHideCustomSpinner.emit(params);
    }

    public get(url): Observable<any> {
        return this.http.get(url);
    }

    public delteResources(alias: Array<string>) {
        const resourceDirectoryService = OcInfraModule.AppInjector.get(
            ResourceDirectoryService
        );
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        alias.forEach((alias) => {
            const workRequestHref = resourceService.getHrefByAliasName(alias);
            resourceDirectoryService.deleteElementResourceDirectory(workRequestHref);
        });
    }

    public clearWithdrawalSurrenderAction() {
        this.withdrawalSurrenderAction = new WithdrawalSurrenderAction();
    }

    public getWithdrawalSurrenderAction() {
        return this.withdrawalSurrenderAction;
    }


    // For getting loan history details for group
    private getLoanHistory = new Subject<any>();
    sendClickEvent() {
        this.getLoanHistory.next();
    }
    getClickEvent(): Observable<any> {
        return this.getLoanHistory.asObservable();
    }

    // For getting policy loan  details for group annuities
    public quoteValuesEvent = new Subject<any>();
    sendQuoteValueClickEvent(quoteDate) {
        this.quoteValuesEvent.next(quoteDate);
    }

    getQuoteValueEvent(): Observable<any> {
        return this.quoteValuesEvent.asObservable();
    }
    getAssetAccountValuesResp() {
        return this.assetAccountValuesResp;
    }

    getClientProducts() {
        return this.clientProducts;
    }

    getClientAssetAccounts() {
        return this.clientAssetAccounts;
    }

    getClientAssetAccountsAll() {
        return this.clientAssetAccountsAll;
    }
    getCanPerformGroupLoanQuote() {
        return this.canPerformGroupLoanQuote;
    }

    private WrkReqHistory = new Subject<any>();
    sendFilterEvent() {
        this.WrkReqHistory.next();
    }
    getFilterEvent(): Observable<any> {
        return this.WrkReqHistory.asObservable();
    }


    private claimsTable = new Subject<any>();
    sendClaimsFilterEvent() {
        this.claimsTable.next();
    }
    getClaimsFilterEvent(): Observable<any> {
        return this.claimsTable.asObservable();
    }



    private financialHistry = new Subject<any>();
    sendFinancialHistoryFilterEvent(id){
        this.financialHistry.next(id);
    }

    getFinancialHistoryFilterEvent(){
        return this.financialHistry.asObservable();
    }

    stepperEmit(value: number) {
        this.currentTab.next(value);
    }

    allFormsEmit(value: any) {
        this.allForms.next(value);
    }

    allRMDFormsEmit(value: any) {
        this.allRMDForms.next(value);
    }


    generateRandomCode = () => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        const numeric = '0123456789';
        let randomString = ' ';
        let randomNumber = ' ';
        const charactersLength = characters.length;
        for (let i = 0; i < 2; i++) {
            randomString += characters.charAt(
                Math.floor(Math.random() * charactersLength)
            );
        }
        const numericLength = numeric.length;
        for (let i = 0; i < 8; i++) {
            randomNumber += numeric.charAt(Math.floor(Math.random() * numericLength));
        }
        return randomString.trim() + randomNumber.trim();
    };

    getHrefUrlWithParams = (url: string): any => {
        let urlArr = url.split('?');
        let params = [];
        let pieces = urlArr[1].split("&");
        pieces.forEach(piece => {
            let tmp = piece.split("=");
            let obj = {
                "key": tmp[0],
                "value": tmp[1]
            };
            params.push(obj);
        });
        return [urlArr[0], params];
    }

    transformParams = (data: any[]) => {
        return data.reduce((acc, curr) => {
            return {
                ...acc,
                [curr.key]: curr.value,
            };
        }, {});
    }

    getNested(obj, ...args) {
        return args.reduce((obj, level) => obj && obj[level], obj)
    }
    mfaDataReset(){
        this.mfaAuthorized = false;
        this.mfaApplicable = false;
        this.selectedScreen = '';
        this.selectedScreenAction = {};
        this.isMfaClosedManuallyWithoutVerification = false;
        this.mfaMessageHtml = '';
        this.mfaCallNotesMessage = '';
        OcInfraModule.AppInjector.get(CommonService).showRequestedDetails();
    }
    getClientProductsApi = async (url: string, headers: any): Promise<any> => {
        try {
            const response = await OcInfraModule.AppInjector.get(CommonService).getCallback(url, headers).toPromise();
            if (response && response._links) {
                OcInfraModule.AppInjector.get(CommonService).clientProducts = response._links.item;
                return response._links.item;;
            }
        } catch (error) {
            if (error && error._embedded && error._embedded.status_report && error._embedded.status_report.consistent === false) {
                OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error._embedded.status_report.messages[0].message, 'Error', 0);
            }
            throw error;
        }
    };

    getAssetAccountsApi = async (url: string, headers: any): Promise<any> => {
        try {
            const response = await OcInfraModule.AppInjector.get(CommonService).getCallback(url, headers).toPromise();
            if (response && response._links) {
                OcInfraModule.AppInjector.get(CommonService).clientAssetAccountsAll = response._links.item;
                return response._links.item;;
            }
        } catch (error) {
            if (error && error._embedded && error._embedded.status_report && error._embedded.status_report.consistent === false) {
                OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error._embedded.status_report.messages[0].message, 'Error', 0);
            }
            throw error;
        }
    };

    public onActionItemClick(action) {
        Configuration.config.headers["multiHostUrlHeader"].forEach(headerObj => {
            if (headerObj.headers.hasOwnProperty("remote_user") && headerObj.alias == 'createClaim') {
                headerObj.headers.role = null;
            }
        });
        // PANOCSR-3491 changes
        let productInfo =OcInfraModule.AppInjector.get(CommonService).productInfo;
        if(productInfo.company_code == 'MET' || productInfo.company_code == 'MTL'){
            OcInfraModule.AppInjector.get(CommonService).mfaApplicable= true;
        }else{
            OcInfraModule.AppInjector.get(CommonService).mfaApplicable= false;
            OcInfraModule.AppInjector.get(CommonService).mfaAuthorized = true;
        }

        if (action.id === "PLAN_REPORTING" || action.id === "FUND_TRANSACTIONS" || action.id == "createDeathClaim") {
            if (action.id === "PLAN_REPORTING") {
                document.getElementById("plan-report-plan-report-anchor").click();
            }
            if (action.id == "FUND_TRANSACTIONS") {
                // Launch Fund Transfer modal with all types ..
                // create user withdrawl flow type pendingTransactions or Ellipsis menu
                OcInfraModule.AppInjector.get(CommonService).clearWithdrawalSurrenderAction();
                var withdrawlAction: WithdrawalSurrenderAction = OcInfraModule.AppInjector.get(CommonService).getWithdrawalSurrenderAction();
                withdrawlAction.setWithdrawlType(action.id);
                document.getElementById("funds-transactions-create-funds-transaction-anchor").click();
            }
            if (action.id === "createDeathClaim") {
                OcInfraModule.AppInjector.get(CommonService).createClaim = false;
                OcInfraModule.AppInjector.get(CommonService).claimAlert = true;
                OcInfraModule.AppInjector.get(CommonService).claimAssociateSuccess = true;
                OcInfraModule.AppInjector.get(CommonService).claimBenefitSuccess = true;
                let header = _.filter(Configuration.config.headers["multiHostUrlHeader"], function (o) { return o.alias == 'createClaim' });
                const resourceService = OcInfraModule.AppInjector.get(ResourceService);
                OcInfraModule.AppInjector.get(APICallerService).changeHeaders(header[0].headers);
                let claims = resourceService.getResourceFromAliasName('claimList');
                if (claims && claims._links && (claims._links.item).length) {
                    document.getElementById("create-claim-popup-show-anchor").click();
                } else {
                    document.getElementById("create-claim-create-claim-item-anchor").click();
                }
            }
        }
        else {
            // if(!this.mfaApplicable){
            //     OcInfraModule.AppInjector.get(CommonService).mfaAuthorized= true;
            //     if (action.id === "LOAN_INITIATION") {
            //         document.getElementById("loan-initiation-create-loan-initiation-anchor").click();
            //         OcInfraModule.AppInjector.get(CommonService).loanInitiation = true;
            //     }
            //     if (action.id === "RMD") {
            //         document.getElementById("rmd-rmd-modal-anchor").click();
            //     }
            //     if (this.withdrawlTypes.includes(action.id)) {
            //         // create user withdrawl flow type pendingTransactions or Ellipsis menu
            //         OcInfraModule.AppInjector.get(CommonService).clearWithdrawalSurrenderAction();
            //         var withdrawlAction: WithdrawalSurrenderAction = OcInfraModule.AppInjector.get(CommonService).getWithdrawalSurrenderAction();
            //         withdrawlAction.setWithdrawlType(this.withdrawlTypes.find(actionType => actionType == action.id));
            //         if (action.id == "SYSTEMATIC_WITHDRAWL") {
            //             if ((OcInfraModule.AppInjector.get(CommonService).mfaAuthorized)) {
            //                 document.getElementById("create-withdrawals-create-withdrawals-item-anchor").click();
            //             }
            //         }
            //         else {
            //             document.getElementById("create-withdrawals-create-withdrawals-item-anchor").click();
            //         }
            //         OcInfraModule.AppInjector.get(CommonService).dividendAlert = true;
            //     }
            // }else if(OcInfraModule.AppInjector.get(CommonService).mfaAuthorized || OcInfraModule.AppInjector.get(CommonService).isMfaClosedManuallyWithoutVerification){
            //     OcInfraModule.AppInjector.get(CommonService).isMfaClosedManuallyWithoutVerification = false;
            //     if (action.id === "LOAN_INITIATION") {
            //         document.getElementById("loan-initiation-create-loan-initiation-anchor").click();
            //         OcInfraModule.AppInjector.get(CommonService).loanInitiation = true;
            //     }
            //     if (action.id === "RMD") {
            //         document.getElementById("rmd-rmd-modal-anchor").click();
            //     }
            //     if (this.withdrawlTypes.includes(action.id)) {
            //         // create user withdrawl flow type pendingTransactions or Ellipsis menu
            //         OcInfraModule.AppInjector.get(CommonService).clearWithdrawalSurrenderAction();
            //         var withdrawlAction: WithdrawalSurrenderAction = OcInfraModule.AppInjector.get(CommonService).getWithdrawalSurrenderAction();
            //         withdrawlAction.setWithdrawlType(this.withdrawlTypes.find(actionType => actionType == action.id));
            //         if (action.id == "SYSTEMATIC_WITHDRAWL") {
            //             if ((OcInfraModule.AppInjector.get(CommonService).mfaAuthorized)) {
            //                 document.getElementById("create-withdrawals-create-withdrawals-item-anchor").click();
            //             }
            //         }
            //         else {
            //             document.getElementById("create-withdrawals-create-withdrawals-item-anchor").click();
            //         }
            //         OcInfraModule.AppInjector.get(CommonService).dividendAlert = true;
            //     }
            // }else{
            //     document.getElementById("mfa-mfa-modal-anchor").click();
            //     OcInfraModule.AppInjector.get(CommonService).selectedScreen = action.id;
            //     OcInfraModule.AppInjector.get(CommonService).selectedScreenAction = action;
            // }


            if ((OcInfraModule.AppInjector.get(CommonService).mfaAuthorized || OcInfraModule.AppInjector.get(CommonService).isMfaClosedManuallyWithoutVerification)) {
                OcInfraModule.AppInjector.get(CommonService).isMfaClosedManuallyWithoutVerification = false;
                if (action.id === "LOAN_INITIATION") {
                    document.getElementById("loan-initiation-create-loan-initiation-anchor").click();
                    OcInfraModule.AppInjector.get(CommonService).loanInitiation = true;
                }
                if (action.id === "RMD") {
                    document.getElementById("rmd-rmd-modal-anchor").click();
                }
                if (this.withdrawlTypes.includes(action.id)) {
                    // create user withdrawl flow type pendingTransactions or Ellipsis menu
                    OcInfraModule.AppInjector.get(CommonService).clearWithdrawalSurrenderAction();
                    var withdrawlAction: WithdrawalSurrenderAction = OcInfraModule.AppInjector.get(CommonService).getWithdrawalSurrenderAction();
                    withdrawlAction.setWithdrawlType(this.withdrawlTypes.find(actionType => actionType == action.id));
                    if (action.id == "SYSTEMATIC_WITHDRAWL") {
                        if ((OcInfraModule.AppInjector.get(CommonService).mfaAuthorized)) {
                            document.getElementById("create-withdrawals-create-withdrawals-item-anchor").click();
                        }
                    }
                    else {
                        document.getElementById("create-withdrawals-create-withdrawals-item-anchor").click();
                    }
                    OcInfraModule.AppInjector.get(CommonService).dividendAlert = true;
                }
            } else {
                document.getElementById("mfa-mfa-modal-anchor").click();
                OcInfraModule.AppInjector.get(CommonService).selectedScreen = action.id;
                OcInfraModule.AppInjector.get(CommonService).selectedScreenAction = action;
            }
        }
    }

    // Duplicate policies in Milepost and Annuities Host Policies
    isDuplicatePoliciesAction(){
        if(this.productInfo && this.productInfo.duplicate_policy_ind){
            return true;
        } else false;
    }

}

export async function retryOperation<T>(
    operation: (times) => Promise<T> | T,
    delay: number,
    times: number
): Promise<T> {
    try {
        return await operation(times);
    } catch (ex) {
        if (times > 1) {
            await new Promise((resolve) => setTimeout(resolve, delay)).then((res: any) => res);
            return retryOperation(operation, delay, times - 1);
        } else {
            throw ex;
        }
    }
}