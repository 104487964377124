import { Component, OnInit, Input, Output, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { ResourceService, Configuration, ServerResponse, UpdateMethodResponse, OcInfraModule, APICallerService, ResourceSchemaService, OptionParams } from '@diaas/ux-web';
import { AdvGrowlService } from 'primeng-advanced-growl';
import { CommonService } from '../../../../../services/common.service';
import * as _ from 'lodash';
import { DatePipe } from '@angular/common';
import { CreateDeathClaimSharedService } from '../../../create-death-claim-shared.service';

@Component({
  selector: 'policy-search',
  templateUrl: './policy-search.component.html',
  styleUrls: ['./policy-search.component.scss'],
})

export class PolicySearchComponent implements OnInit, AfterViewInit {
  @Input() showHideAdditionalPolicySearch: boolean;
  @Input() portfolioDeathClaimInfo: any;
  @Output() setAssociatedPolicy: EventEmitter<AssociatedPolicy> = new EventEmitter<AssociatedPolicy>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public searchClicked: boolean = false;
  public enableOk: boolean = false;
  public lastName: string;
  public firstName: string;
  public policyNumber: string;
  public govtId: string;
  public dateOfBirth: any;
  public selectedRow: any;
  public displayedColumns = ['policy_no', 'REGION', 'COMPANY', 'role',
    'name',
    'birth_date',
    'gov_id',
    'address'
  ];
  public policyDatasource: MatTableDataSource<any> = new MatTableDataSource([]);
  public showErrorMessage: boolean = false;
  public showTableWarningMessage: boolean = false;
  constructor(public datePipe: DatePipe, private createDeathClaimSharedService: CreateDeathClaimSharedService) { }

  ngOnInit() {
    this.createDeathClaimSharedService.getData().subscribe(wrapper => {
      this.portfolioDeathClaimInfo._links.item.claimPolicyList = wrapper;
    });
  }
  ngAfterViewInit() {
    this.policyDatasource.paginator = this.paginator;
  }
  onSearchPolicies() {
    this.showErrorMessage = false;
    if (this.validateSearch() === false) {
      return;
    }
    this.enableOk = false;
    this.searchClicked = true;
    const urlIndex = _.findLastIndex(Configuration.config.hostURL.multiHostUrl, ['alias', "searchContracts"]);
    let optionParams: OptionParams = {};
    optionParams.alias = 'searchContracts';
    optionParams.params = [];
    this.addParams('firstName', this.firstName, optionParams.params);
    this.addParams('lastName', this.lastName, optionParams.params);
    this.addParams('policyNumber', this.policyNumber, optionParams.params);
    this.addParams('dob', this.datePipe.transform(this.dateOfBirth, 'MM/dd/yyyy'), optionParams.params);
    this.addParams('govId', this.govtId, optionParams.params);
    this.addParams('role', 'Insured', optionParams.params);
    let url: any = Configuration.config.hostURL.multiHostUrl[urlIndex]['url'] + 'searchContractsByField';
    optionParams.headers = OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("search");
    OcInfraModule.AppInjector.get(CommonService).changeHeaders({ statuaory_company_code: null });
    OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: true })
    OcInfraModule.AppInjector.get(APICallerService).refresh(url, optionParams, "RESET").subscribe((response) => {
      let items = response._links.item.map(item => item.summary);
      const aboveTableMatch = items.filter((obj) => {
        return this.portfolioDeathClaimInfo._links.item.claimPolicyList.some((obj2) => {
          return obj.policy_no == obj2.policyNumber;
        });
      });
      if (aboveTableMatch.length > 0) {
        this.policyDatasource.data = items.filter( x => !aboveTableMatch.filter( y => y.policy_no === x.policy_no).length);
        this.showTableWarningMessage = false;
      }
      else {
        this.policyDatasource.data = items;
        this.showTableWarningMessage = true;
      }
      this.policyDatasource.paginator = this.paginator;
      OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: true });
    });
  }

  addParams(paramKey, paramValue, params = []) {
    if (paramValue) {
      params.push({ key: paramKey, value: paramValue })
    }
  }

  onReset() {
    this.lastName = '';
    this.firstName = '';
    this.policyNumber = '';
    this.govtId = '';
    this.dateOfBirth = '';
    this.searchClicked = false;
    this.enableOk = false;
    this.policyDatasource = new MatTableDataSource([]);
    this.policyDatasource.paginator = this.paginator;
    this.showTableWarningMessage = false;
    this.showErrorMessage = false;
  }

  onRowSelection(e) {
    this.selectedRow = e;
    this.enableOk = (this.selectedRow.role && this.selectedRow.role === 'Insured') || (this.selectedRow.role && this.selectedRow.product_type.includes("U"));
  }

  onOk() {
    let claimListHeader = _.filter(Configuration.config.headers["multiHostUrlHeader"], function (o) { return o.alias == 'createClaim' });
    const productInfo = OcInfraModule.AppInjector.get(CommonService).productInfo;
    const payload = {
      policyNumber: this.selectedRow.policy_no,
      companyCode: this.selectedRow.company_code,
      productType: productInfo.product_type,
      role: this.selectedRow.role
    };
    OcInfraModule.AppInjector.get(CommonService).postCallback(this.portfolioDeathClaimInfo._options.links[4].href, payload, claimListHeader[0].headers).subscribe(response => {
      this.setAssociatedPolicy.emit({
        refreshClaims: true
      });
      this.searchClicked = false;
      this.onReset();
      OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
      OcInfraModule.AppInjector.get(AdvGrowlService).createSuccessMessage(response.message, 'Success');
    }, error => {
      this.setAssociatedPolicy.emit({
        refreshClaims: true
      });
      OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
      error && error.message ? OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error.message, 'Error', 0) : OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage("Something went wrong please try again", 'Error', 0);
    });
  }

  onCancel() {
    this.setAssociatedPolicy.emit({
      showHideAdditionalPolicySearch: false
    });
  }

  hideMessage() {
    this.showTableWarningMessage = false;
  }

  private validateSearch() {
    if (!this.firstName && !this.lastName && !this.policyNumber && !this.dateOfBirth && !this.govtId) {
      this.showErrorMessage = true;
      return false;
    }
    if (!this.lastName && !this.policyNumber && !this.govtId) {
      let content = '';
      if (this.firstName && !this.dateOfBirth) {
        content = 'If First Name is provided, Last Name is required';
      }
      if (this.dateOfBirth && !this.lastName) {
        content = 'If Date of birth is provided, Last Name is required';
      }
      if (content) {
        let title = 'Alert';
        OcInfraModule.AppInjector.get(CommonService).showConfirmationPopup(content, 'OK', '', title, '340px')
        return false;
      }
    }
    return true;
  }
}
