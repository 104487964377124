
export interface QuoteFormFieldModel {
    id: string;
    label: string;
    value: string;
    checked?: string;
    pipe?: string
}

export enum quoteSectionOneId {
    EffectiveDate = 'effective_date',
    AccumulatedCashValue = 'accumulated_cashvalue',
    SurrenderValue = 'surrender_amount',
    SurrenderPenalty = 'surrender_penalty',
    SurrenderFreeAmount = 'surrender_free_amount',
    MinimumSurrenderBenefit = 'minimum_surrender_benefit',
    MVAAmount = 'mva_amt',
    RedemptionFee = 'redemption_fee',
    TotalAnnuityValue = 'total_ann_value',
    ABAFundValue = 'aba_fund_val',
    LoanPayoffValue = 'loan_payoff_value',
    AdminFee = 'admin_fee'    
}

export enum quoteSectionMrpsOneId {
    EffectiveDate = 'effective_date',
    AccumulatedCashValue = 'accumulated_cash_value',
    SurrenderValue = 'cash_surrender_value',
    SurrenderPenalty = 'surrender_penalty',
    SurrenderFreeAmount = 'max_partial_surr_value',
    MinimumSurrenderBenefit = 'minimum_surrender_benefit',
    MVAAmount = 'total_mva_amount',
    RedemptionFee = 'redemption_fee',
    TotalAnnuityValue = 'total_annuity_value',
    ABAFundValue = 'aba_fund_val',
    LoanPayoffValue = 'loan_payoff_value',
    AdminFee = 'admin_fee'
}


export enum quoteSectionTwoId {
    StateWithholding = 'state_withholding_amount',
    MunicipalWithholding = 'municipal_withholding_amount',
    FederalWithholding = 'federal_withholding_amount',
    PremiumTax = 'premiumTax',
    OutstandingLoanBalance = 'outstanding_loan_bal',
    NetCheck = 'net_check_amount'
}

export enum quoteSectionMrpsTwoId {
    StateWithholding = 'state_withhold_amount',
    MunicipalWithholding = 'municipal_withholding_amount', 
    FederalWithholding = 'federal_withholding_amount',
    PremiumTax = 'premiumTax',
    OutstandingLoanBalance = 'outstanding_loan_bal',
    NetCheck = 'net_check_amount'
}


export enum quoteSectionOneLabel {
    EffectiveDate = 'Effective Date',
    AccumulatedCashValue = 'Accumulated Cash Value',
    SurrenderValue = 'Surrender Value',
    SurrenderPenalty = 'Surrender Penalty',
    SurrenderFreeAmount = 'Surrender Free Amount',
    MinimumSurrenderBenefit = 'Minimum Surrender Benefit',
    MVAAmount = 'MVA Amount',
    RedemptionFee = 'Redemption Fee',
    TotalAnnuityValue = 'Total Annuity Value',
    ABAFundValue = 'ABA Fund Value',
    LoanPayoffValue = 'Loan Payoff Value',
    AdminFee = 'Admin Fee'    
}


export enum quoteSectionTwoLabel {
    StateWithholding = 'State Withholding',
    MunicipalWithholding = 'Municipal Withholding',
    FederalWithholding = 'Federal Withholding',
    PremiumTax = 'Premium Tax',
    OutstandingLoanBalance = 'Outstanding Loan Balance',
    NetCheck = 'Net Check'
}




export const sectionOneFormFields = () => {

    return [
        {
            id: quoteSectionOneId.AccumulatedCashValue,
            label: quoteSectionOneLabel.AccumulatedCashValue,
            value: '',
            checked: '',
            pipe: 'CURRENCY'
        },
        {
            id: quoteSectionOneId.SurrenderValue,
            label: quoteSectionOneLabel.SurrenderValue,
            value: '',
            checked: '',
            pipe: 'CURRENCY'
        },
        {
            id: quoteSectionOneId.SurrenderPenalty,
            label: quoteSectionOneLabel.SurrenderPenalty,
            value: '',
            checked: '',
            pipe: 'CURRENCY'
        },
        {
            id: quoteSectionOneId.SurrenderFreeAmount,
            label: quoteSectionOneLabel.SurrenderFreeAmount,
            value: '',
            checked: '',
            pipe: 'CURRENCY'
        },
        {
            id: quoteSectionOneId.MinimumSurrenderBenefit,
            label: quoteSectionOneLabel.MinimumSurrenderBenefit,
            value: '',
            checked: '',
            pipe: 'CURRENCY'
        },
        {
            id: quoteSectionOneId.MVAAmount,
            label: quoteSectionOneLabel.MVAAmount,
            value: '',
            checked: '',
            pipe: 'CURRENCY'
        },
        {
            id: quoteSectionOneId.RedemptionFee,
            label: quoteSectionOneLabel.RedemptionFee,
            value: '',
            checked: '',
            pipe: 'CURRENCY'
        },
        {
            id: quoteSectionOneId.TotalAnnuityValue,
            label: quoteSectionOneLabel.TotalAnnuityValue,
            value: '',
            checked: '',
            pipe: 'CURRENCY'
        },
        {
            id: quoteSectionOneId.ABAFundValue,
            label: quoteSectionOneLabel.ABAFundValue,
            value: '',
            checked: '',
            pipe: 'CURRENCY'
        },
        {
            id: quoteSectionOneId.LoanPayoffValue,
            label: quoteSectionOneLabel.LoanPayoffValue,
            value: '',
            checked: '',
            pipe: 'CURRENCY'
        }
    ];

}

export const sectionTwoFormFields = () => {

    return [
        {
            id: quoteSectionTwoId.StateWithholding,
            label: quoteSectionTwoLabel.StateWithholding,
            value: '',
            checked: '',
            pipe: 'CURRENCY'
        },
        {
            id: quoteSectionTwoId.MunicipalWithholding,
            label: quoteSectionTwoLabel.MunicipalWithholding,
            value: '',
            checked: '',
            pipe: 'CURRENCY'
        },
        {
            id: quoteSectionTwoId.FederalWithholding,
            label: quoteSectionTwoLabel.FederalWithholding,
            value: '',
            checked: '',
            pipe: 'CURRENCY'
        },
        {
            id: quoteSectionTwoId.PremiumTax,
            label: quoteSectionTwoLabel.PremiumTax,
            value: '',
            checked: '',
            pipe: 'CURRENCY'
        },
        {
            id: quoteSectionTwoId.OutstandingLoanBalance,
            label: quoteSectionTwoLabel.OutstandingLoanBalance,
            value: '',
            checked: '',
            pipe: 'CURRENCY'
        }
    ];

}

export const sectionOneFormMRPSFields = () => {

    return [
        {
            id: quoteSectionMrpsOneId.AccumulatedCashValue,
            label: quoteSectionOneLabel.AccumulatedCashValue,
            value: '',
            checked: '',
            pipe: 'CURRENCY'
        },
        {
            id: quoteSectionMrpsOneId.SurrenderValue,
            label: quoteSectionOneLabel.SurrenderValue,
            value: '',
            checked: '',
            pipe: 'CURRENCY'
        },
        {
            id: quoteSectionMrpsOneId.SurrenderPenalty,
            label: quoteSectionOneLabel.SurrenderPenalty,
            value: '',
            checked: '',
            pipe: 'CURRENCY'
        },
        {
            id: quoteSectionMrpsOneId.SurrenderFreeAmount,
            label: quoteSectionOneLabel.SurrenderFreeAmount,
            value: '',
            checked: '',
            pipe: 'CURRENCY'
        },
        {
            id: quoteSectionMrpsOneId.MinimumSurrenderBenefit,
            label: quoteSectionOneLabel.MinimumSurrenderBenefit,
            value: '',
            checked: '',
            pipe: 'CURRENCY'
        },
        {
            id: quoteSectionMrpsOneId.MVAAmount,
            label: quoteSectionOneLabel.MVAAmount,
            value: '',
            checked: '',
            pipe: 'CURRENCY'
        },
        {
            id: quoteSectionMrpsOneId.RedemptionFee,
            label: quoteSectionOneLabel.RedemptionFee,
            value: '',
            checked: '',
            pipe: 'CURRENCY'
        },
        {
            id: quoteSectionMrpsOneId.TotalAnnuityValue,
            label: quoteSectionOneLabel.TotalAnnuityValue,
            value: '',
            checked: '',
            pipe: 'CURRENCY'
        },
        {
            id: quoteSectionMrpsOneId.ABAFundValue,
            label: quoteSectionOneLabel.ABAFundValue,
            value: '',
            checked: '',
            pipe: 'CURRENCY'
        },
        {
            id: quoteSectionMrpsOneId.LoanPayoffValue,
            label: quoteSectionOneLabel.LoanPayoffValue,
            value: '',
            checked: '',
            pipe: 'CURRENCY'
        }
    ];

}

export const sectionTwoFormMRPSFields = () => {

    return [
        {
            id: quoteSectionMrpsTwoId.StateWithholding,
            label: quoteSectionTwoLabel.StateWithholding,
            value: '',
            checked: '',
            pipe: 'CURRENCY'
        },
        {
            id: quoteSectionMrpsTwoId.MunicipalWithholding,
            label: quoteSectionTwoLabel.MunicipalWithholding,
            value: '',
            checked: '',
            pipe: 'CURRENCY'
        },
        {
            id: quoteSectionMrpsTwoId.FederalWithholding,
            label: quoteSectionTwoLabel.FederalWithholding,
            value: '',
            checked: '',
            pipe: 'CURRENCY'
        },
        {
            id: quoteSectionMrpsTwoId.PremiumTax,
            label: quoteSectionTwoLabel.PremiumTax,
            value: '',
            checked: '',
            pipe: 'CURRENCY'
        },
        {
            id: quoteSectionMrpsTwoId.OutstandingLoanBalance,
            label: quoteSectionTwoLabel.OutstandingLoanBalance,
            value: '',
            checked: '',
            pipe: 'CURRENCY'
        }
    ];

}

export interface ItemProducts {
    summary: SummaryProducts
    href: string
}

export interface SummaryProducts {
    plan_code: string
    product_code: string
    _links: any
    quote_effective_date: string
    product_type: string
}



export const sectionOneLifeFormFields = () => {

    return [
        {
            id: quoteSectionOneId.AccumulatedCashValue,
            label: quoteSectionOneLabel.AccumulatedCashValue,
            value: '',
            checked: '',
            pipe: 'CURRENCY'
        },
        {
            id: quoteSectionOneId.AdminFee,
            label: quoteSectionOneLabel.AdminFee,
            value: '',
            checked: '',
            pipe: 'CURRENCY'
        },
        {
            id: quoteSectionOneId.SurrenderPenalty,
            label: quoteSectionOneLabel.SurrenderPenalty,
            value: '',
            checked: '',
            pipe: 'CURRENCY'
        },
    ];

}

export const sectionTwoLifeFormFields = () => {

    return [
        {
            id: quoteSectionTwoId.NetCheck,
            label: quoteSectionTwoLabel.NetCheck,
            value: '',
            checked: '',
            pipe: 'CURRENCY'
        },
        {
            id: quoteSectionTwoId.FederalWithholding,
            label: quoteSectionTwoLabel.FederalWithholding,
            value: '',
            checked: '',
            pipe: 'CURRENCY'
        },
        {
            id: quoteSectionTwoId.StateWithholding,
            label: quoteSectionTwoLabel.StateWithholding,
            value: '',
            checked: '',
            pipe: 'CURRENCY'
        }       
        
    ];

}
