import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Configuration, OcInfraModule, ResourceService, APICallerService } from '@diaas/ux-web';
import { CommonService, retryOperation } from '../../../services/common.service';
import { ToasterService } from 'angular2-toaster';
import { AdvGrowlService } from 'primeng-advanced-growl';
import * as moment from 'moment';
import * as _ from 'lodash';
import { ItemAssetAccount, ItemProducts } from './fund-transfer-information.constant';

@Component({
  selector: 'app-fund-transfer-information',
  templateUrl: './fund-transfer-information.component.html',
  styleUrls: ['./fund-transfer-information.component.scss'],
})
export class FundTransferInformationComponent implements OnInit {

  @Output() onSubmitChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() alertsEvent = new EventEmitter<any>();
  @Output() scrollTop: EventEmitter<string> = new EventEmitter<string>();
  headers = { ...{ role: OcInfraModule.AppInjector.get(CommonService).authorizedRole, client_id: OcInfraModule.AppInjector.get(CommonService).productInfo.client, wma_user: window.sessionStorage.getItem('username') }, user_role: "csr", agent_code: OcInfraModule.AppInjector.get(CommonService).agentId, ...OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("search") };
  policyDetails: any;
  fundTransferForm = new FormGroup({
    isAgentOrAgentAssistant: new FormControl(false, [Validators.requiredTrue]),
    dateTime: new FormControl(null),
    effectiveDate: new FormControl(null, [Validators.required]),
    transferType: new FormControl(null, Validators.required)
  });
  resourceService = OcInfraModule.AppInjector.get(ResourceService);
  isAgentOrAgentAssistant: boolean;
  authorizedRole: any;
  fundsData: any;
  allowDateTime: boolean;
  totalToFunds: any;
  totalFromFunds: any;
  transferTypes: any[] = [];
  isNextBusinessDay: boolean;
  confirmationOverride: boolean = false;
  isSubmitSuccessful: boolean = false;
  fundTransferType: any;
  alerts: any;
  alertColor: any;
  alertMessages: any;
  disableFundTransferTab: boolean;
  disableCreate: boolean = false;
  disableSubmit: boolean = true;
  selectedGroupedFunds = [];
  selectedFunds = [];
  tableSortStates: any;
  isReadonly: boolean = false;
  displayStockMaketDialog = true;

    clientProducts: ItemProducts[];
    assetAccounts: ItemAssetAccount[];

    clientProductItems: any;
    assetAccountItems: any;

    clientProductsUrl: string = '';
    assetAccountsUrl: string = '';

    retryDelay = 3000;
    retryAttempts = 3;
    isGroupProduct: boolean = false;

    isTransferTypeDisabled: boolean = true;

  constructor(private toasterService: ToasterService, private commonService: CommonService) { }

  ngOnInit() {
    this.policyDetails = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyDetails') ? OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyDetails').response : null;

      this.authorizedRole = this.commonService.authorizedRole;
      this.isGroupProduct = this.isGroupProductType();
      if (this.isGroupProduct) {
          this.fundTransferForm.addControl("productType", new FormControl(""));
          this.fundTransferForm.addControl("accountType", new FormControl(""));
          this.processA4Product();
      } else {
          this.isTransferTypeDisabled = false;
          this.getCurrentFundChangeDetails();
      }

      this.tableSortStates = [
          {
              "column": "number",
              "isAsc": false
          },
          {
              "column": "name",
              "isAsc": false
          },
          {
              "column": "fund_value",
              "isAsc": true
          }
      ]
  }

    getCurrentFundChangeDetails = () => {
        this.authorizedRole = OcInfraModule.AppInjector.get(CommonService).authorizedRole;
        this.fundTransferForm.controls['isAgentOrAgentAssistant'].setValue(!(this.authorizedRole === 'Agent' || this.authorizedRole === 'Agent Assistant' || this.authorizedRole === 'BOA Back Office Assistant (Advisor Assistant)'));
        let href = this.policyDetails && this.policyDetails._links['funds/fundtransfer'].href;
        if (!href) {
            this.disableFundTransferTab = true;
            return;
        }
        OcInfraModule.AppInjector.get(CommonService).getCallback(href, this.headers).subscribe(response => {
            this.fundsData = response;
            if (this.fundsData && this.fundsData._options && this.fundsData._options.links.length && this.fundsData._options.links.find((item) => item.method == 'POST')) {
                this.isReadonly = false;
                this.disableFundTransferTab = false;
            } else {
                this.isReadonly = false;
            }
            if (this.fundsData._embedded && this.fundsData._embedded.status_report) {
                this.alertMessages = this.fundsData._embedded.status_report.messages;
                this.alertsEvent.emit({
                    alertMessages: this.alertMessages,
                    tabName: 'Fund Transfer',
                    isDisabled: this.disableFundTransferTab   // OCCD-3622 emitting isDisabled for tab disabling functionality
                });
            }
            this.setDefaultFunds();

            this.allowDateTime = this.fundsData && (this.fundsData.firm_code === 'DWI' || this.fundsData.firm_code === 'SBH')
                && (this.authorizedRole === 'Agent' || this.authorizedRole === 'Agent Assistant' || this.authorizedRole === 'BOA Back Office Assistant (Advisor Assistant)');
            if (this.allowDateTime) {
                this.fundTransferForm.controls['dateTime'].setValidators(Validators.required);
            } else {
                this.fundTransferForm.controls['dateTime'].setValidators(null);
            }

            this.fundTransferForm.controls['effectiveDate'].setValue(this.fundsData.effective_date ? moment(this.fundsData.effective_date) : null);

            this.transferTypes = [];
            if (this.fundsData && this.fundsData.transfer_type && this.fundsData.transfer_type.length > 0) {
                this.fundsData.transfer_type.forEach((type) => {
                    const transferType = this.fundsData._options.properties.transfer_type.oneOf.find(opt => opt.enum.includes(type));
                    if (transferType) {
                        this.transferTypes.push({ value: transferType.title, viewValue: transferType.title })
                    }
                });
                const defaultTransferType = this.transferTypes.find((item) => item.value === 'Percent');
                if (defaultTransferType) {
                    this.fundTransferForm.controls['transferType'].setValue(defaultTransferType.value);
                } else {
                    this.fundTransferForm.controls['transferType'].setValue(this.transferTypes[0].value);
                }
                this.fundTransferForm.controls['transferType'].updateValueAndValidity();
            }
            this.sortTable('fund_value', undefined);
        }, error => {
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
            if (error && error._embedded && error._embedded.status_report && error._embedded.status_report.consistent === false) {
                OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error._embedded.status_report.messages[0].message, 'Error', 0);
            }
        });

    }

    processA4Product = async () => {
        // COMMENTING FOR - PANOCSR-2590
        // if (this.authorizedRole === 'Authorized Third Party' || this.authorizedRole === 'Guardian' || this.authorizedRole === 'POA-Power of Attorney' || this.authorizedRole === 'Power of Attorney') {
        //     this.disableFundTransferTab = true;
        // } else {
        //     this.disableFundTransferTab = false;
        // }
        
        this.clientProductsUrl = this.policyDetails && this.policyDetails._links && this.policyDetails._links.clientproducts ? this.policyDetails._links.clientproducts.href : null;
        this.assetAccountsUrl = this.policyDetails && this.policyDetails._links && this.policyDetails._links.clientassetaccounts ? this.policyDetails._links.clientassetaccounts.href : null;

        this.getClientProductsPromise();
        this.getAssetAccountsPromise();

    }

    getClientProductsPromise = async () => {
        await retryOperation(this.getClientProducts, this.retryDelay, this.retryAttempts)
            .then((res) => {
                this.commonService.clientProducts = res;
                this.clientProducts = res;
                this.processProductTypes(this.clientProducts);
            })
            .catch((err) => this.getClientProductsCall());
    }

    getAssetAccountsPromise = async () => {
        await retryOperation(this.getAssetAccounts, this.retryDelay, this.retryAttempts)
            .then((res) => {
                this.commonService.clientAssetAccountsAll = res;
                this.assetAccounts = res;
            })
            .catch((err) => this.getAssetAccountsCall());
    }

    getClientProducts = async () => {
        let clientProducts = await this.commonService.getClientProducts();
        if (clientProducts) {
            return Promise.resolve(clientProducts);
        } else {
            return Promise.reject('No data');
        }
    }

    getAssetAccounts = async () => {
        let assetAccounts = await this.commonService.getClientAssetAccountsAll();
        if (assetAccounts) {
            return Promise.resolve(assetAccounts);
        } else {
            return Promise.reject('No data');
        }
    }

    getClientProductsCall = () => {
        OcInfraModule.AppInjector.get(CommonService).getCallback(this.clientProductsUrl, this.headers).subscribe((response: any) => {
            if (response && response._links) {
                OcInfraModule.AppInjector.get(CommonService).clientProducts = response._links.item;
                this.clientProducts = response._links.item;
            }
        }, (error: any) => {
            if (error && error._embedded && error._embedded.status_report && error._embedded.status_report.consistent === false) {
                OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error._embedded.status_report.messages[0].message, 'Error', 0);
            }
        });
    }

    getAssetAccountsCall = () => {
        OcInfraModule.AppInjector.get(CommonService).getCallback(this.assetAccountsUrl, this.headers).subscribe((response: any) => {
            if (response && response._links) {
                OcInfraModule.AppInjector.get(CommonService).getClientAssetAccountsAll = response._links.item;
                this.assetAccounts = response._links.item;
            }
        }, (error: any) => {
            if (error && error._embedded && error._embedded.status_report && error._embedded.status_report.consistent === false) {
                OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error._embedded.status_report.messages[0].message, 'Error', 0);
            }
        });
    }

    processProductTypes = (clientProducts: ItemProducts[]) => {      
        if (clientProducts.length > 0) {
          this.clientProductItems = clientProducts.filter((item: any) => {
              return (item.summary.product_type == 'A4') && { label: item.summary.plan_code, value: item.summary.plan_code }
          }).map((item: any) => {
              return { label: item.summary.plan_code, value: item.summary.plan_code}
          });
        }
    }

    productTypeChange = (e: any) => {
        this.fundTransferForm.get('productType').setValue(e.value);
        let assetAccounts = this.assetAccounts.find((item: ItemAssetAccount) => item.summary.plan_code == e.value);
        this.assetAccountItems = assetAccounts.summary.asset_account_names.filter((item: any) => {
            return (item != 'ALL ASSET ACCOUNTS' && item != '- null') && { value: item, label: item }
        }).map((item: any) => {
            return { value: item, label: item }
        });
    }

    accountTypeChange = (e: any) => {
        this.fundTransferForm.get('accountType').setValue(e.value);
        let plan_code = this.fundTransferForm.controls.productType.value;
        this.headers.plan_code = plan_code;
        this.headers.client_id = this.commonService.productInfo.client;
        this.headers.asset_account = e.value.split('-')[0].trim();
        this.getCurrentFundChangeDetails();
    }

    isGroupProductType = () => {
        let product_type = this.getNested(this.policyDetails, 'product_type');
        if (product_type && (product_type == "A4" ||product_type == "A3")) {
            return true;
        } else {
            return false;
        }
    }


  // Calculates total percent
  getTotalofPercent(fundsList, propertyName) {
    let total = 0.0;
    for (let i = 0; i < fundsList.length; i++) {
      total += Number(fundsList[i][propertyName].replace(/[^0-9.-]+/g, ''));
    }
    return this.formatPercent('', 'blur', total.toString());
  }

  // Calculates total amount
  getTotalofAmount(fundsList, propertyName) {
    let total = 0.0;
    for (let i = 0; i < fundsList.length; i++) {
      total += Number(fundsList[i][propertyName].replace(/[^0-9.-]+/g, ''));
    }
    return this.formatCurrency('', 'blur', total.toString());
  }

  // handles input change
  inputChange(input, i, j, propertyName, formatType, indicator) {
    let input_val = input.target.value;
    this.fundsData.groupedFunds ? this.fundsData.groupedFunds[i].funds[j][propertyName] = input_val : this.fundsData.funds[i][propertyName] = input_val;
    if (formatType === 'percent') {
      this.fundsData.groupedFunds ? this.fundsData.groupedFunds[i].funds[j]['percent'] = input_val : this.fundsData.funds[i]['percent'] = input_val;
    } else {
      this.fundsData.groupedFunds ? this.fundsData.groupedFunds[i].funds[j]['amount'] = input_val : this.fundsData.funds[i]['amount'] = input_val;
    }
    if (j !== undefined && j !== null) {
      if (formatType === 'percent') {
        if (indicator === 'T') {
          for (let i = 0; i < this.fundsData.groupedFunds.length; i++) {
            this.fundsData.groupedFunds[i].totalToFunds = this.getTotalofPercent(this.fundsData.groupedFunds[i].funds, propertyName);
          }
        } else {
          for (let i = 0; i < this.fundsData.groupedFunds.length; i++) {
            this.fundsData.groupedFunds[i].totalFromFunds = this.getTotalofPercent(this.fundsData.groupedFunds[i].funds, propertyName);
          }
        }
      } else {
        if (indicator === 'T') {
          for (let i = 0; i < this.fundsData.groupedFunds.length; i++) {
            this.fundsData.groupedFunds[i].totalToFunds = this.getTotalofAmount(this.fundsData.groupedFunds[i].funds, propertyName);
          }
        } else {
          for (let i = 0; i < this.fundsData.groupedFunds.length; i++) {
            this.fundsData.groupedFunds[i].totalFromFunds = this.getTotalofAmount(this.fundsData.groupedFunds[i].funds, propertyName);
          }
        }
      }
    } else {
      if (indicator === 'T') {
        this.totalToFunds = this.fundsData.funds ? formatType === 'percent' ? this.getTotalofPercent(this.fundsData.funds, propertyName) :
          this.getTotalofAmount(this.fundsData.funds, propertyName) : '0.0';
      } else {
        this.totalFromFunds = this.fundsData.funds ? formatType === 'percent' ? this.getTotalofPercent(this.fundsData.funds, propertyName) :
          this.getTotalofAmount(this.fundsData.funds, propertyName) : '0.0';
      }

    }     

    if (!this.isSubmitSuccessful) {
      this.disableCreate = false;
    }
    if(formatType == 'percent') {
        if(this.totalFromFunds == "0.0%" || this.totalToFunds == "0.0%" ){
            this.disableSubmit= true;
          }else {
            this.disableSubmit = false;
          }
    }

    if(formatType == 'amount') {
        if(this.totalFromFunds == "$0.00" || this.totalToFunds == "$0.00" ){
            this.disableSubmit= true;
          }else {
            this.disableSubmit = false;
          }
    }
    
  }

  processFundTransferResponse(response, payload) {
     if(response.effective_date){
      this.fundTransferForm.controls['effectiveDate'].setValue(response.effective_date ? moment(response.effective_date) : null);
    }            
    switch (response.confirm_override) {
      // soft error scenario
      case 'Y': {
        this.displayAlerts(response);
        this.scrollTop.emit('scroll');
        let content = 'Are you sure?  You want to override ?';
        let title = 'Alert';
        let dialogRef = OcInfraModule.AppInjector.get(CommonService).showConfirmationPopup(content, 'No', 'Yes', title, '340px');
        dialogRef.afterClosed().subscribe(res => {
          if (res) {
            payload.confirm_override = "Y"
            payload.next_business_day = response.next_business_day;
            payload.effective_date = response.effective_date;
            this.confirmationOverride = true;
            this.onSubmit(false);
            this.alerts = [];
            this.alertColor = 'orange';
          }
        });
        break;
      }
      // hard error scenario
      case 'N': {
        this.displayAlerts(response);
        this.alertColor = 'red';
        this.disableCreate = true;
        break;
      }
      // success scenario
      case '': {
        this.disableCreate = true;
        this.isSubmitSuccessful = true;
        this.alerts = [];
        OcInfraModule.AppInjector.get(AdvGrowlService).createSuccessMessage('Fund Transfer Processed Successfully', 'Success');
        this.onSubmitChange.emit({
          transferType: this.fundTransferForm.controls['transferType'].value,
          effectiveDate: this.fundTransferForm.controls['effectiveDate'].value,
          DateTime: this.fundTransferForm.controls['dateTime'].value,
          funds: this.fundsData.funds ? this.fundsData.funds : null,
          groupedFunds: this.fundsData.groupedFunds ? this.fundsData.groupedFunds : null,
          selectedGroupedFunds: this.selectedGroupedFunds,
          selectedFunds: this.selectedFunds,
          totalFromFunds: this.totalFromFunds,
          totalToFunds: this.totalToFunds,
          isPortfolioPlatform: (this.fundsData.funding_option === 'PP' || this.fundsData.funding_option === 'PF' || this.fundsData.funding_option === 'LP') ? true : false
        });
        const headers = OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("pendingTransactions");
        OcInfraModule.AppInjector.get(APICallerService).refresh(this.policyDetails._links.pendingTransactions.href, { 'alias': "pendingTransactions", 'headers': headers }, 'RESET').subscribe(refreshResponse => {
              
        });
        break;
      }
    }
  }

  processFundTransfer() {
    this.toasterService.clear();
    let link = this.fundsData && this.fundsData._options.links.find(link => link.method.toLowerCase() === 'post');
    if (link) {
      let header = _.filter(Configuration.config.headers["multiHostUrlHeader"], function (o) { return o.alias == 'workRequestHistory' });
      Object.assign(this.headers, { remote_user: header[0].headers.remote_user });      
      const authResponse = this.resourceService.getResourceFromAliasName('authRes');
      let policyDetails = this.resourceService.getResourceFromAliasName('policyDetails');
      Object.assign(this.headers, { product_type: policyDetails.data.product_type});
      const selectedRoleSummary = this.resourceService.getResourceFromAliasName('selectedRoles');
      let roleList = this.resourceService.getResourceFromAliasName('roles');
      let otherRoleAuthorize = _.isEmpty(selectedRoleSummary.data) ? _.filter(roleList._links.item, function (o) { return o.summary.role == 'Owner'; }) : false;
      const payload = {
        "effective_date": this.fundsData.effective_date,
        "confirm_override": this.confirmationOverride === true ? 'Y' : '',
        "next_business_day": this.isNextBusinessDay === false ? '' : 'Y',
         "sessionId": authResponse && authResponse.data.contactSessionID ? authResponse.data.contactSessionID : '',
         "callStart": authResponse && authResponse.data.callStart ? authResponse.data.callStart : '',
         "clientId": otherRoleAuthorize ? otherRoleAuthorize[0] && otherRoleAuthorize[0].summary.clientId ? otherRoleAuthorize[0].summary.clientId : '' : selectedRoleSummary && selectedRoleSummary.data.clientId ? selectedRoleSummary.data.clientId : '',
         "userId": OcInfraModule.AppInjector.get(CommonService).incontactId,
         "policyNumber": policyDetails.data.policy_number,
         "company_code": policyDetails.data.company_code,
         "region_code": header[0].headers.region_code,
         "remote_user": header[0].headers.remote_user,
         "origin_app": "csr_portal",
         "incontactId": authResponse && authResponse.data.contactID ? authResponse.data.contactID : ''
      };
     
      if (this.fundsData._options.properties.transfer_type && this.fundsData._options.properties.transfer_type.oneOf) {
        for (let i = 0; i < this.fundsData._options.properties.transfer_type.oneOf.length; i++) {
          if (this.fundsData._options.properties.transfer_type.oneOf[i].title == this.fundTransferForm.controls['transferType'].value) {
            this.fundTransferType = Object.assign([this.fundsData._options.properties.transfer_type.oneOf[i].enum[0]]);
          }
        }
        payload["transfer_type"] = this.fundTransferType;
      }

      if (this.fundsData && (this.fundsData.funding_option === 'PP' || this.fundsData.funding_option === 'PF' || this.fundsData.funding_option === 'LP')) {
        this.selectedGroupedFunds = [];
        this.fundsData.groupedFunds.forEach((groupedFund, index) => {
          if (groupedFund.funds.length > 0) {
            const filteredFunds = groupedFund.funds.filter((item) =>
            (item.toAmount !== '' && item.toAmount !== '0.00')
            || (item.fromAmount !== '' && item.fromAmount !== '0.00')
            || (item.toPercent !== '' && item.toPercent !== '0.00')
            || (item.fromPercent !== '' && item.fromPercent !== '0.00'));
            if (filteredFunds.length > 0) {
              const grouped = Object.assign({}, groupedFund);
              filteredFunds.forEach(fund => {
                fund.amount = fund.toAmount !== '' ? fund.toAmount.replace(/[^0-9.-]+/g, '') : fund.fromAmount !== '' ? fund.fromAmount.replace(/[^0-9.-]+/g, '') : '0.00';
                fund.percent = fund.toPercent !== '' ? fund.toPercent.replace(/[^0-9.-]+/g, '') : fund.fromPercent !== '' ? fund.fromPercent.replace(/[^0-9.-]+/g, '') : '0.0';
              });
              grouped.funds = filteredFunds;
              this.selectedGroupedFunds.push(grouped);
            }
          }
        });
        payload['groupedFunds'] = this.selectedGroupedFunds;
      } else {
        const filteredFunds = this.fundsData.funds.filter((item) =>
        (item.toAmount !== '' && item.toAmount !== '0.00')
            || (item.fromAmount !== '' && item.fromAmount !== '0.00')
            || (item.toPercent !== '' && item.toPercent !== '0.00')
            || (item.fromPercent !== '' && item.fromPercent !== '0.00'));
        filteredFunds.forEach(fund => {
          delete fund._links;      

          fund.amount = fund.toAmount !== '' ? fund.toAmount.replace(/[^0-9.-]+/g, '') : fund.fromAmount !== '' ? fund.fromAmount.replace(/[^0-9.-]+/g, '') : '0.00';
          fund.percent = fund.toPercent !== '' ? fund.toPercent.replace(/[^0-9.-]+/g, '') : fund.fromPercent !== '' ? fund.fromPercent.replace(/[^0-9.-]+/g, '') : '0.0';
        });
        this.selectedFunds = filteredFunds;
        payload['funds'] = filteredFunds;
      }
      OcInfraModule.AppInjector.get(CommonService).postCallback(link.href, payload, this.headers).subscribe(response => {
        this.processFundTransferResponse(response, payload);

      }, error => {
        OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error._embedded && error._embedded.status_report && error._embedded.status_report.messages[0].message, 'Error', 0);
      });
    }

  }

  onTransferTypeChange(e) {
    this.setDefaultFunds();
    this.enableSubmit();
  }

  enableSubmit() {
    if (!this.isSubmitSuccessful) {
      this.disableCreate = false;
    }
  }

  setDefaultFunds() {
    // Default to funds empty
    if (this.fundsData && (this.fundsData.funding_option === 'PP' || this.fundsData.funding_option === 'PF' || this.fundsData.funding_option === 'LP')) {
      if (this.fundsData.groupedFunds.length > 0) {
        this.fundsData.groupedFunds.forEach((groupedFund, index) => {
          if (groupedFund.funds.length > 0) {
            groupedFund.funds.forEach((fund) => {
              fund.toAmount = '';
              fund.toPercent = '';
              fund.fromAmount = '';
              fund.fromPercent = '';
              fund.isChanged = false;
            });
            if (!this.fundTransferForm.value.transferType || this.fundTransferForm.value.transferType === 'Percent') {
              groupedFund.totalFromFunds = '0.0%'
            }
            if (!this.fundTransferForm.value.transferType || this.fundTransferForm.value.transferType === 'Amount to Percent' || this.fundTransferForm.value.transferType === 'Percent') {
              groupedFund.totalToFunds = '0.0%';
            }
          }
        });
      }
    } else {
      if (this.fundsData.funds.length > 0) {
        this.fundsData.funds.forEach((fund) => {
          fund.toAmount = '';
          fund.toPercent = '';
          fund.fromAmount = '';
          fund.fromPercent = '';
          fund.isChanged = false;
        });

        if (this.fundTransferForm.value.transferType === 'Dollar Transfer') {
            this.totalFromFunds = '$0.00';
        }

        if (this.fundTransferForm.value.transferType === 'Dollar Transfer') {
            this.totalToFunds = '$0.00';
        }

        if (!this.fundTransferForm.value.transferType || this.fundTransferForm.value.transferType === 'Percent') {
          this.totalFromFunds = '0.0%'
        }
        if (!this.fundTransferForm.value.transferType || this.fundTransferForm.value.transferType === 'Amount to Percent' || this.fundTransferForm.value.transferType === 'Percent') {
          this.totalToFunds = '0.0%';
        }
        if(!this.fundTransferForm.value.transferType || this.fundTransferForm.value.transferType == 'Percent') {
            if(this.totalFromFunds =='0.0%'|| this.totalToFunds == '0.0%' ){
                this.disableSubmit= true;
              }else {
                this.disableSubmit = false;
              }
        }
        
        if(this.fundTransferForm.value.transferType == 'Dollar Transfer') {
            if(this.totalFromFunds =='$0.00'|| this.totalToFunds == '$0.00' ){
                this.disableSubmit= true;
              }else {
                this.disableSubmit = false;
              }
        }
        
      }
    }
  }

  onSubmit(e) {
    this.displayStockMaketDialog = (e !== false);
    this.scrollTop.emit('scroll');
    this.checkIsBusinessClosed();
  }

  checkIsBusinessClosed() {
    let curr = moment(new Date()).tz('America/New_York');
    if (curr.hours() >= this.fundsData.business_closehour && this.displayStockMaketDialog) {
      this.displayStockMaketDialog = false;
      let content = 'The stock market is closed and the transaction will be completed on next business day. Do you want to proceed ?';
      let title = 'Alert';
      let dialogRef = OcInfraModule.AppInjector.get(CommonService).showConfirmationPopup(content, 'No', 'Yes', title, '340px');
      dialogRef.afterClosed().subscribe(res => {
        if (res) {
          this.isNextBusinessDay = true; // isNextBusinessDay is to check if the user clicked yes
          this.processFundTransfer()
        }
      });
    } else {
      this.processFundTransfer()
    }
  }

  displayAlerts(response) {
    if (response._embedded && response._embedded.status_report && response._embedded.status_report.messages && response._embedded.status_report.messages.length > 0) {
      this.alerts = response._embedded.status_report.messages;
      }
  }

  toggleAlertMsg(event) {
    event.target.classList.toggle("alertMsg-overflow");
  }

  // On successful submit, turns the tab to green
  isStepComplete() {
    return this.isSubmitSuccessful;
  }

  /** AMOUNT AND PERCENT FORMATING  */
  formatNumber(n, type, flag) {
    if (flag) {
      n = n.charAt(0) == '0' ? n.replace(n.charAt(0), "") : n;
    }
    if (type == 'currency') {
      return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return n.replace(/\D/g, "");
    }
  }

  //formats the value with commas and $ allowing 2 decimal values
  formatCurrency(input, blur, respValue, fund = null, indicator = null, formatType = null) {
    var input_val = "";
    if (respValue == '' || respValue == undefined) {
      input_val = input.target.value;
    } else {
      input_val = respValue;
    }
    if (input_val === "") {
      this.setFundValue(fund, input_val, indicator, 'amount');
      return;
    }
    var original_len = input_val.length;
    if (input_val.indexOf(".") >= 0) {
      var decimal_pos = input_val.indexOf(".");
      decimal_pos = decimal_pos > 15 ? 15 : decimal_pos;
      var left_side = input_val.substring(0, decimal_pos);
      var right_side = input_val.substring(decimal_pos);
      left_side = this.formatNumber(left_side, 'currency', true);
      right_side = this.formatNumber(right_side, '', false);
      if (blur === "blur") {
        right_side += "00";
      }
      // Limit decimal to only 2 digits
      right_side = right_side.substring(0, 2);
      // join number by .
      input_val = "$" + left_side + "." + right_side;

    } else {
      // no decimal entered
      // add commas to number
      // remove all non-digits
      input_val = input_val.substring(0, 15);
      input_val = this.formatNumber(input_val, 'currency', true);
      input_val = "$" + input_val;
      // final formatting
      if (blur === "blur") {
        input_val += ".00";
      }
    }
    if (input_val.charAt(input_val.indexOf('$') + 1) == '.') {
      input_val = input_val.replace(input_val.charAt(input_val.indexOf('$') + 1), '0.');
    }
    if (respValue == '' || respValue == undefined) {
      input.target["value"] = input_val;
    } else {
      return input_val;
    }

    if (fund) {
      this.setFundValue(fund, input_val, indicator, 'amount');
    }
  }

  // formats the value with commas and percentage allowing 1 decimal value
  formatPercent(input, blur, respValue, fund = null, indicator = null, formatType = null) {
    var input_val = "";
    if (respValue == '' || respValue == undefined) {
      input_val = input.target.value;
    } else {
      input_val = respValue;
    } if (input_val === "") {
      this.setFundValue(fund, input_val, indicator, 'percent');
      return;
    }
    var original_len = input_val.length;
    if (input_val.indexOf(".") >= 0) {
      var decimal_pos = input_val.indexOf(".");
      var left_side = input_val.substring(0, decimal_pos);
      var right_side = input_val.substring(decimal_pos);
      left_side = this.formatNumber(left_side, 'percent', true);
      right_side = this.formatNumber(right_side, 'percent', false);
      if (blur === "blur") {
        right_side += "0";
      }
      // Limit decimal to only 2 digits
      right_side = right_side.substring(0, 1);
      // join number by .
      input_val = left_side + "." + right_side + "%";
    } else {
      // no decimal entered
      // add commas to number
      // remove all non-digits
      input_val = this.formatNumber(input_val, 'percent', true);
      if (blur === "blur") {
        input_val += ".0";
      }
      input_val = input_val + "%";
    }
    if (input_val.charAt(input_val.indexOf('$') + 1) == '.') {
      input_val = input_val.replace(input_val.charAt(input_val.indexOf('$') + 1), "0.")
    }
    if (respValue == '' || respValue == undefined) {
      input.target["value"] = input_val;
    } else {
      return input_val;
    }

    if (fund) {
      this.setFundValue(fund, input_val, indicator, 'percent');
    }
  }

  setFundValue(fund, value, indicator, formatType) {
    if (formatType === 'amount') {
      if (indicator === 'F') {
        fund.fromAmount = value;
        fund.toAmount = '';
        fund.toPercent = '';
      } else {
        fund.toAmount = value;
        fund.fromAmount = '';
        fund.fromPercent = '';
      }
    } else {
      if (indicator === 'F') {
        fund.fromPercent = value;
        fund.toPercent = '';
        fund.toAmount = '';
      } else {
        fund.toPercent = value;
        fund.fromAmount = '';
        fund.fromPercent = '';
      }
    }
    fund.from_to_indicator = indicator;
    fund.isChanged = value && value !== '';
  }

// Sorts the fund table based on the column
  sortTable(column, i) {
    let isAsc;
    let _self = this;
    _.forEach(_self.tableSortStates, function (obj, index) {
      if (obj['column'] == column) {
        isAsc = !obj['isAsc'];
        _self.tableSortStates[index]['isAsc'] = isAsc;
      }
    })
    if (this.fundsData.funding_option === 'PP' || this.fundsData.funding_option === 'PF' || this.fundsData.funding_option === 'LP') {
      if (i >= 0) {
        this.fundsData.groupedFunds[i].funds = isAsc ? _.orderBy(this.fundsData.groupedFunds[i].funds, this.byKey(column), 'asc') : _.orderBy(this.fundsData.groupedFunds[i].funds, this.byKey(column), 'desc');
        } else  {
          this.fundsData.groupedFunds.forEach(fund => {
             fund.funds  = isAsc ? _.orderBy(fund.funds, this.byKey(column), 'asc') : _.orderBy(fund.funds, this.byKey(column), 'desc');
          });
        }
    } else {
      this.fundsData.funds = isAsc ? _.orderBy(this.fundsData.funds, this.byKey(column), 'asc') : _.orderBy(this.fundsData.funds, this.byKey(column), 'desc');
    }
  }

  // checks if it is a Number String
  byKey(key) {
    return function (o) {
      if (typeof o[key] === 'string') {
        return isNaN(+o[key].replace(/[^a-zA-Z0-9]/g,''))? o[key] : +o[key];
      }
      return o[key];
    };
  }

    getNested(obj, ...args) {
        return args.reduce((obj, level) => obj && obj[level], obj)
    }
}
