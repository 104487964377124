import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core'
import {
    MatInputModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatIconModule,
    MatMenuModule,
    MatDialogModule,
    MatSortModule,
    MatCheckboxModule,
    MatCardModule
} from '@angular/material';
import { FormsModule } from '@angular/forms';
import { OcInfraModule, OcInfraWebModule } from '@diaas/ux-web';
import { HttpModule } from '@angular/http';
import { AngularFontAwesomeModule } from 'angular-font-awesome';

import { AdvGrowlModule, AdvGrowlService } from 'primeng-advanced-growl';
import { AppRoutingModule } from './app-routing.module';
import { ToasterModule, ToasterService } from 'angular2-toaster';

import { ocInfraConfig } from './../../ocInfraConfig/ocinfra-config';
import { SharedModule } from './../../ocInfraConfig/ocinfra-shared.module';

import { AppComponent } from '../components/app.component/app.component';
import { LoginComponent } from './../components/login.component/login.component';

import { CommonService } from './../services/common.service';
import { AuthenticationService } from './../services/authentication.service';
import { CanDeactivateGuard } from './../services/canDeactivateGuard';
import { IonicModule } from 'ionic-angular';
import { HeaderComponent } from './../components/header.component/header.component';
import { LogoutComponent } from './../components/logout.component/logout.component';
import { CorrespondenceConfirmationDialog } from './../components/correspondenceTable.component/correspondenceTable.component';
import { DialogComponent } from './../components/dialog.component/dialog-component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpConfigInterceptor } from '../interceptor/httpconfig.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SpinnerComponent } from './../components/customSpinner/customSpinner.component';
import { ConfirmdialogComponent } from './../components/confirmdialog/confirmdialog.component';
import { ClaimKitIndicatorComponent } from './../components/createDeathClaim/claimPartyInformation/claim-party-information.component';


import { BackButtonDirective } from '../components/app.component/backButtonDirective';
import { NgxMaskModule } from 'ngx-mask';
import { FinancialHistoryDisplayComponent } from '../components/financial-history-display/financial-history-display.component';
import { CustomDialogComponent } from '../components/custom-dialog/custom-dialog.component';
import { CheckHistorydetailComponent } from '../components/check-historydetail/check-historydetail.component';


@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        LogoutComponent,
        HeaderComponent,
        CorrespondenceConfirmationDialog,
        DialogComponent,
        SpinnerComponent,
        ConfirmdialogComponent,
        BackButtonDirective,
        ClaimKitIndicatorComponent,
        CustomDialogComponent,
        FinancialHistoryDisplayComponent,
        CheckHistorydetailComponent
    ],
    imports: [
        FormsModule,
        BrowserModule,
        HttpModule,
        OcInfraWebModule,
        IonicModule.forRoot(AppComponent),
        OcInfraModule.forRoot(ocInfraConfig, SharedModule),
        ToasterModule.forRoot(),
        AppRoutingModule,
        AdvGrowlModule,
        IonicModule,
        AngularFontAwesomeModule,
        MatInputModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatIconModule,
        MatMenuModule,
        MatDialogModule,
        MatSortModule,
        MatCardModule,
        MatCheckboxModule,
        BrowserAnimationsModule,
        NgxMaskModule.forRoot()
    ],
    providers: [
        CommonService,
        AuthenticationService,
        ToasterService,
        CanDeactivateGuard,
        { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true }
    ],
    entryComponents: [
        CorrespondenceConfirmationDialog, DialogComponent, ConfirmdialogComponent,
        ClaimKitIndicatorComponent, CustomDialogComponent, FinancialHistoryDisplayComponent,CheckHistorydetailComponent
    ],

    bootstrap: [AppComponent]
})
export class AppModule { }
