
export interface LoanHistoryData {
    trnsaction_date:Date;
    loan_type:string;
    loan_id:number;
    start_date:Date;
    end_date:Date;
    beginning_principal: string;
    billed_repayment:string;
    billed_interest:string;
    billing_principal:string;
    ending_principal:string;
    cumulative_interest: string;
    transaction_amount:string;
    actual_interest:string;
    actual_principal:string;
    unearned_interest:string;
    late_interest:string;
  }

  export const DisplayedHistoryTableColumns = [
    'trnsaction_date',
    'loan_type',
    'loan_id', 
    'start_date', 
    'end_date',
    'beginning_principal',
    'billed_repayment',
    'billed_interest',
    'billing_principal',
    'ending_principal',
    'cumulative_interest',
    'transaction_amount',
    'actual_interest',
    'actual_principal',
    'unearned_interest',
    'late_interest'
];