
export interface AssetAccountData {
    policy_number: string;
    plan_code: string;
    asset_account_code: string;
    policy_status: string;
    product_type: string;
    effective_date: string;
    init_prem_eff_date: string;
    asset_restriction_code: string;
    multi_product: string;
    service_code: string;
    commission_option_code: string;
}

export const columnsDef = [
    {
        columnDef: 'policy_number',
        header: 'Policy Number',
        cell: (element: any) => `${element.policy_number}`,
        show: true,
    },
    {
        columnDef: 'plan_code',
        header: 'Plan Code',
        cell: (element: any) => `${element.plan_code}`,
        show: true,
    },
    {
        columnDef: 'asset_account_code',
        header: 'Asset Account',
        cell: (element: any) => `${element.asset_account_code}`,
        show: true,
    },
    {
        columnDef: 'policy_status',
        header: 'Status',
        cell: (element: any) => `${element.policy_status}`,
        show: true,
    },
    {
        columnDef: 'product_type',
        header: 'Product',
        cell: (element: any) => `${element.product_type}`,
        show: true,
    },
    {
        columnDef: 'effective_date',
        header: 'Account Effective',
        cell: (element: any) => `${element.effective_date}`,
        show: true,
    },
    {
        columnDef: 'init_prem_eff_date',
        header: 'Initial Payment',
        cell: (element: any) => `${element.init_prem_eff_date}`,
        show: true,
    },
    {
        columnDef: 'asset_restriction_code',
        header: 'Restrict Code',
        cell: (element: any) => `${element.asset_restriction_code}`,
        show: true,
    },
    {
        columnDef: 'multi_product',
        header: 'Multi-Product',
        cell: (element: any) => `${element.multi_product}`,
        show: true,
    },
    {
        columnDef: 'service_code',
        header: 'Service Code',
        cell: (element: any) => `${element.service_code}`,
        show: true,
    },
    {
        columnDef: 'commission_option_code',
        header: 'Commission Option Code',
        cell: (element: any) => `${element.commission_option_code}`,
        show: true,
    },
];