import { Component, Input, Output, OnInit, EventEmitter, AfterViewInit, ViewChild } from '@angular/core';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { APICallerService, Configuration, OcInfraModule, ResourceService } from '@diaas/ux-web';
import { AdvGrowlService } from 'primeng-advanced-growl';
import { CommonService } from '../../../../services/common.service';
import * as _ from 'lodash';
import { CreateDeathClaimSharedService } from '../../create-death-claim-shared.service';
import { ClaimPartyInformationComponent } from '../../claimPartyInformation/claim-party-information.component';

const AWAITING_INITIAL_PREMIUM="awaiting initial premium";

@Component({
  selector: 'death-claim-information',
  templateUrl: './death-claim-information.component.html',
  styleUrls: ['death-claim-information.component.scss'],
})

export class DeathClaimInformationComponent implements OnInit, AfterViewInit {
  // Policy portfolio data getting from parent component
  @Input() portfolioDeathClaimInfo: any;
  @Input() deathDateFromParent:any;
  @Input() stepCompletionIndicator: any;
  @Input() submitButtonDisable: any;
  @Output() setAssociatedPolicy: EventEmitter<AssociatedPolicy> = new EventEmitter<AssociatedPolicy>();
  @Output() onReset: EventEmitter<any> = new EventEmitter<any>();
  @Output() getClaimPartylist: EventEmitter<any> = new EventEmitter<any>();

  public displayedColumns = ['check', 'policyNumber', 'region', 'companyCode', 'faceAmount', 'issueDate', 'adminStatus', 'reportingStatus'];
  @ViewChild(MatPaginator) paginator: MatPaginator;

  public policyDatasource: MatTableDataSource<any> = new MatTableDataSource([]);
  public selectedRow: any;
  public selectedForBenefit;
  public showErrorMessage: boolean = false;
  public showBenefitErrorMessage: boolean =false;
  public wMAMessage = "Status change in wmA failed.Manual update needed.Error:"
  public errMsg: any;
  public benefitErrMsg: any;
  successMsg: boolean= true;
  isAnnuity: boolean;
  isGroupAnnuity: boolean = false;
  accountNumberResponse : any;
  updatedFaceamount:any;

  constructor(private createDeathClaimSharedService: CreateDeathClaimSharedService) {
  }
  ngOnInit() {
    const data = this.portfolioDeathClaimInfo._links.item.claimPolicyList.map(item => ({ ...item, isChecked: false, canAssociate: (item.adminStatus ==null || item.adminStatus.length==0) ? true:  item.adminStatus.trim().toLowerCase()!==AWAITING_INITIAL_PREMIUM }));
    this.policyDatasource.data = data;
    let details = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyDetails');
    this.isAnnuity = (details.data.product_type.includes('A') ? true : false);
    let product_type = details.data.product_type;
    if (product_type == "A3" || product_type == "A4") {
      this.isGroupAnnuity = true;
    }
    if(this.isAnnuity && this.areAllSelectedPoliciesWithAwaitingPremium(data)){
      OcInfraModule.AppInjector.get(CommonService).claimAssociateSuccess = true;
      OcInfraModule.AppInjector.get(CommonService).claimBenefitSuccess = true;
    }
    this.createDeathClaimSharedService.getData().subscribe(wrapper => {
      this.portfolioDeathClaimInfo._links.item.claimPolicyList = wrapper;
    });
  }

  ngAfterViewInit() {
    this.policyDatasource.paginator = this.paginator;
  }

  setAssociate(element, isChecked) {
    this.policyDatasource.data.map(i => i.isChecked = false);
    element.isChecked = isChecked;
    const checked = this.policyDatasource.data.some(i => i.isChecked);
    this.setAssociatedPolicy.emit({
      showHideAdditionalPolicyAssociate: checked
    });
  }

  onAssociate() {
    if (!this.submitButtonDisable) {
      let dialogRef = OcInfraModule.AppInjector.get(CommonService).showConfirmationPopup('Please make sure you save your changes or the information will be lost.', 'Cancel', 'Continue', 'alert', '400px');
      dialogRef.afterClosed().subscribe(dialogResponse => {
        if (dialogResponse) {
          this.associateCall();
          this.onReset.emit();
        }
      });
    }
    else {
      this.associateCall();
    }
  }

  associateCall() {
    let details = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyDetails');
    let claimListHeader = _.filter(Configuration.config.headers["multiHostUrlHeader"], function (o) { return o.alias == 'createClaim' });
    // claimListHeader[0].headers.role = OcInfraModule.AppInjector.get(CommonService).selectedRole;
    claimListHeader[0].headers.role = "owner";
    claimListHeader[0].headers.wma_user = window.sessionStorage.getItem('username')
    const checkedRows = this.policyDatasource.data.filter(i => i.isChecked);
    OcInfraModule.AppInjector.get(CommonService).claimPartyInformation =this.policyDatasource.data.filter(i => i.isBenefitQuoted == false);
    const productInfo = OcInfraModule.AppInjector.get(CommonService).productInfo;
    if (checkedRows.length < 1) {
      return;
    }
    let payload = {
      policyNumber: checkedRows[0].policyNumber,
      companyCode: checkedRows[0].companyCode,
      productType: productInfo.product_type
    };

    if (this.isGroupAnnuity) {
      if(checkedRows[0].multiProductIndicator){
        let policyAssetAccounts = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyAssetAccounts');
        let assetAccountDtls =policyAssetAccounts.response['_links'].item;
        let selectedPolicyAssetAccount = assetAccountDtls.find(item =>item.summary.policy_number.startsWith( checkedRows[0].policyNumber))
        claimListHeader[0].headers.plan_code = selectedPolicyAssetAccount? selectedPolicyAssetAccount.summary.plan_code: details.data.plan_code  ;
        Object.assign(payload, { wmaPolicyNumber: selectedPolicyAssetAccount? selectedPolicyAssetAccount.summary.policy_number: details.data.policy_number });
      }else{
        claimListHeader[0].headers.plan_code = details.data.plan_code ;
        Object.assign(payload, { wmaPolicyNumber: details.data.policy_number });
      }
    }
  
   
    let associateSpinnerInterval = setInterval(function () {
      OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false });
    }, 0);
    OcInfraModule.AppInjector.get(CommonService).postCallback(this.portfolioDeathClaimInfo._options.links[3].href, payload, claimListHeader[0].headers).subscribe(response => {

      clearInterval(associateSpinnerInterval);
      this.refreshPolicyClaims();
      // Suppressing the Parties API if it is Annuity policy
      !this.isAnnuity ? this.getClaimPartylist.emit() : "";
      OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
      // OcInfraModule.AppInjector.get(CommonService).claimAssociateSuccess = true;
      // OcInfraModule.AppInjector.get(CommonService).claimBenefitSuccess = false;
      !this.isGroupAnnuity ? OcInfraModule.AppInjector.get(AdvGrowlService).createSuccessMessage(response.message, 'Success') : "";
      if(!this.isAnnuity && response && response._links && response._links.accountNumber){
        let claimListHeader = _.filter(Configuration.config.headers["multiHostUrlHeader"], function (o) { return o.alias == 'createClaim' });
        OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false });
        OcInfraModule.AppInjector.get(CommonService).getCallback(response._links.accountNumber.href,  claimListHeader[0].headers).subscribe(response => {
          console.log(response);
          this.accountNumberResponse = response;          
        });
        OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });

      }
    
      if (response && response._embedded && response._embedded.status_report && response && response._embedded.status_report && response._embedded.status_report.messages) {
        for (let i = 0; i < response._embedded.status_report.messages.length; i++) {
          if (response._embedded.status_report.messages[i].severity == "INFO" && this.isGroupAnnuity) {
            OcInfraModule.AppInjector.get(AdvGrowlService).createSuccessMessage(response._embedded.status_report.messages[i].message + " " + response.message, 'Success');
            this.successMsg = false;
          }         
          if (response._embedded.status_report.messages[i].severity == "ERROR") {
            this.errMsg = response._embedded.status_report.messages[0].message;
            this.showErrorMessage = true;
          }
          else {
            this.showErrorMessage = false;
          }
        }
      }
      (this.isGroupAnnuity && this.successMsg) ? OcInfraModule.AppInjector.get(AdvGrowlService).createSuccessMessage(response.message, 'Success'):'';
      const resourceService = OcInfraModule.AppInjector.get(ResourceService);
      const workRequestHref = resourceService.getHrefByAliasName("workRequestHistory").split('?')[0];
      let workRequestheaders = _.filter(Configuration.config.headers["multiHostUrlHeader"], function (o) { return o.alias == 'workRequestHistory' });
      let policyDetails = resourceService.getResourceFromAliasName('policyDetails');
      let claimListHeader = _.filter(Configuration.config.headers["multiHostUrlHeader"], function (o) { return o.alias == 'claimList' });
      if (response.success) {
        setTimeout(() =>{
        OcInfraModule.AppInjector.get(APICallerService).refresh(workRequestHref, { 'alias': "workRequestHistory", 'headers': workRequestheaders[0].headers}).subscribe(refreshResponse => {
          OcInfraModule.AppInjector.get(CommonService).wrkReqHistoryRes = refreshResponse;
          OcInfraModule.AppInjector.get(CommonService).sendFilterEvent();
          OcInfraModule.AppInjector.get(APICallerService).refresh(policyDetails._links.claims.href, { 'alias': "claimList", 'headers':claimListHeader[0].headers}).subscribe(refreshResponse => {
            OcInfraModule.AppInjector.get(CommonService).claimsTableRes = refreshResponse;
            OcInfraModule.AppInjector.get(CommonService).sendClaimsFilterEvent();
          });
        });
      },2500); 

      let claimPartiesUrl = OcInfraModule.AppInjector.get(CommonService).getNested(response._links, 'updateAWDComments');
      if(claimPartiesUrl && claimPartiesUrl.href){
        OcInfraModule.AppInjector.get(CommonService).updateClaimPartyUrl = claimPartiesUrl.href;  
      }
    }
    }, error => {
      clearInterval(associateSpinnerInterval);
      this.refreshPolicyClaims();
      // Suppressing the Parties API if it is Annuity policy
      !this.isAnnuity ? this.getClaimPartylist.emit() : "";
      OcInfraModule.AppInjector.get(CommonService).claimAssociateSuccess = true;
      OcInfraModule.AppInjector.get(CommonService).claimBenefitSuccess = false;
      OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
      error && error.message ? OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error.message, 'Error', 0) : OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage("Something went wrong please try again", 'Error', 0);
    });
  }

  onBenefitQuote() {
    const checkedRows = this.selectedForBenefit;
    let duplicateBenefit = false;
    for (let i = 0; i < this.portfolioDeathClaimInfo._links.item.claimPolicyList.length; i++) {
      if ((checkedRows[0].policyNumber == this.portfolioDeathClaimInfo._links.item.claimPolicyList[i].policyNumber) && (this.portfolioDeathClaimInfo._links.item.claimPolicyList[i].benefitQuoted == true)) {
        duplicateBenefit = true;
      }
    }
    if (duplicateBenefit == true) {
      let dialogRef = OcInfraModule.AppInjector.get(CommonService).showConfirmationPopup('Death Benefit Quote Request may have already been processed for this Policy. All prior adjustments will be lost. Do you want to continue?', 'NO', 'YES', 'Benefit Quote Warning', '400px');
      dialogRef.afterClosed().subscribe(dialogResponse => {
        if (dialogResponse) {
          this.benefitCall();
        }
      });
    }
    else {
      this.benefitCall();
    }

  }

  benefitCall() {
    let claimListHeader = _.filter(Configuration.config.headers["multiHostUrlHeader"], function (o) { return o.alias == 'createClaim' });
    claimListHeader[0].headers.role = OcInfraModule.AppInjector.get(CommonService).selectedRole;
    const checkedRows = this.selectedForBenefit;// this.policyDatasource.data.filter(i => i.isChecked);
    const productInfo = OcInfraModule.AppInjector.get(CommonService).productInfo;
    if (checkedRows.length < 1) {
      return;
    }
    const payload = {
      policyNumber: checkedRows[0].policyNumber,
      companyCode: checkedRows[0].companyCode,
      productType: productInfo.product_type,
      accountNumber :this.accountNumberResponse.accountNumber,
      dateOfDeath: this.deathDateFromParent
    };

    
    let benefitSpinnerInterval = setInterval(function () {
      OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false });
    }, 0);
    OcInfraModule.AppInjector.get(CommonService).postCallback(this.portfolioDeathClaimInfo._options.links[5].href, payload, claimListHeader[0].headers).subscribe(response => {
      clearInterval(benefitSpinnerInterval);   
      this.updatedFaceamount = response.faceAmount;   
      if (response && response._embedded && response._embedded.status_report && response._embedded.status_report.messages) {

        for (let i = 0; i < response._embedded.status_report.messages.length; i++) {            
          if (response._embedded.status_report.messages[i].severity == "ERROR") {
            this.benefitErrMsg = response._embedded.status_report.messages[0].message;
            this.showBenefitErrorMessage = true;
          }
          else {
            this.showBenefitErrorMessage = false;
          }
        }
      }
      this.refreshPolicyClaims();
      OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
      OcInfraModule.AppInjector.get(AdvGrowlService).createSuccessMessage(response.message, 'Success');
    }, error => {
      clearInterval(benefitSpinnerInterval);
      this.refreshPolicyClaims();
      OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
      error && error.message ? OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error.message, 'Error', 0) : OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage("Failed to trigger Benefit Quote. Please try again.", 'Error', 5000);
    });
  }

  refreshPolicyClaims() {
    let claimListHeader = _.filter(Configuration.config.headers["multiHostUrlHeader"], function (o) { return o.alias == 'createClaim' });
    OcInfraModule.AppInjector.get(CommonService).getCallback(this.portfolioDeathClaimInfo._options.links[1].href, claimListHeader[0].headers).subscribe(response => {
      const data = response._links.item.claimPolicyList.map(item => ({ ...item, isChecked: false, canAssociate: (item.adminStatus ==null || item.adminStatus.length==0) ? true:  item.adminStatus.trim().toLowerCase()!==AWAITING_INITIAL_PREMIUM}));
      const benefitQuotePolicy = data.find(claimPolicy => claimPolicy.benefitQuoted == true);
      OcInfraModule.AppInjector.get(CommonService).claimPartyInformation =data.filter(i => i.isBenefitQuoted == false);
      let count = 0;
      let benefitQuotedPolicy = false;
      for(let i=0; i<data.length; i++){
        if(data[i].benefitQuoted == true){
          count++;
        }
      }
      if(count == data.length){
         benefitQuotedPolicy = true;
      } else{
        benefitQuotedPolicy = false;
      }
      if (!!benefitQuotedPolicy) {
        OcInfraModule.AppInjector.get(CommonService).updateAssociateButtonStatus(true);
        this.stepCompletionIndicator["policyPortfolio"] = true;
        this.stepCompletionIndicator["claimPartyInformation"] = true;
        OcInfraModule.AppInjector.get(CommonService).claimBenefitSuccess = true;
      } else if(!!benefitQuotePolicy){
        OcInfraModule.AppInjector.get(CommonService).updateAssociateButtonStatus(true);
        OcInfraModule.AppInjector.get(CommonService).claimBenefitSuccess = false;
        this.stepCompletionIndicator["policyPortfolio"] = true;
        this.stepCompletionIndicator["claimPartyInformation"] = true;
      }else{
        OcInfraModule.AppInjector.get(CommonService).claimBenefitSuccess = false;
      }
      // const atleastOneIsassociated = data.find(claimPolicy => claimPolicy.associatedToClaim == true);
     OcInfraModule.AppInjector.get(CommonService).claimAssociateSuccess = this.areAllSelectedPoliciesAssociated(data);      
      if (this.isAnnuity && (this.areAllSelectedPoliciesAssociated(data) || this.areAllSelectedPoliciesWithAwaitingPremium(data))) {
        OcInfraModule.AppInjector.get(CommonService).claimBenefitSuccess = true;
        this.stepCompletionIndicator["policyPortfolio"] = true;
      }
      this.createDeathClaimSharedService.associatedPolicies = [];
      this.createDeathClaimSharedService.associatedPolicies = response._links.item.claimPolicyList.filter(item => (item.associatedToClaim == true));
      this.policyDatasource.data = data;
      this.createDeathClaimSharedService.sendData(data);
      this.setAssociatedPolicy.emit({
        showHideAdditionalPolicyAssociate: false
      });

      //updating face amount after benefit quote api response.
      if(this.updatedFaceamount && this.selectedRow && this.selectedRow.policyNumber){
        for (let i = 0; i < this.portfolioDeathClaimInfo._links.item.claimPolicyList.length; i++) {
          if ((this.selectedRow.policyNumber == this.policyDatasource.data[i].policyNumber)) {
            this.policyDatasource.data[i].faceAmount = this.updatedFaceamount;
          }
        }
      }
      OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
    }, error => {
      OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
      OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage("Failed to get Claims Information", 'Error', 0);
    });
  }

  areAllSelectedPoliciesWithAwaitingPremium( selectedPolicies){
     const awaitingPremiumPolicies = selectedPolicies.filter(policy => policy.adminStatus && policy.adminStatus.toLowerCase().trim() === AWAITING_INITIAL_PREMIUM);
      return awaitingPremiumPolicies.length ===selectedPolicies.length;
  }
  
  areAllSelectedPoliciesAssociated( selectedPolicies){
    const associatedPolicies = selectedPolicies.filter(policy => policy.associatedToClaim);
    return associatedPolicies.length === selectedPolicies.filter(policy =>( policy.canAssociate)).length;
 }

  onRowSelection(row) {
    this.selectedRow = row;
    const dataSource = JSON.parse(JSON.stringify(this.policyDatasource.data))
    const selectedForBenifit = dataSource.filter(i => i.policyNumber == row.policyNumber);
    selectedForBenifit[0].isChecked = true;
    this.selectedForBenefit = JSON.parse(JSON.stringify(selectedForBenifit));
    //   this.policyDatasource.data.map(policy => policy.isChecked = policy.policyNumber == row.policyNumber);
    this.checkBenefitQuote();
  }

  checkBenefitQuote() {
    const checked = this.selectedRow && this.selectedRow.isAssociatedToClaim;
    this.setAssociatedPolicy.emit({
      showHideAdditionalPolicyBenefitQuote: checked
    });
  }
}